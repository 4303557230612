export enum JobStatusModel {
  NotYetStarted = 0,
  InProgress = 1,
  Completed = 2,
}

export enum EndReccuringType {
  After = 0,
  On = 1,
  Never = 2,
}
