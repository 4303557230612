import { Guid } from 'guid-typescript';

export class Note {
  universalId?: Guid;
  note?: string;
  name?: string;
  hasAttachments?: boolean;
  attachments?: Array<NoteAttachmentModel>;
  createdDate?: string;
  createdTime?: string;
  createdOn?: Date;
}

export class NoteAttachmentModel {
  attachment?: string;
  fileSize?: string;
}
