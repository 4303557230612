import { Injectable, Injector } from '@angular/core';
import { ActionModel } from '@app/core/models/common/grid-action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Modules } from '../../enums';
import {
  CompanyModel,
  Country,
  Currency,
  FileUploadRequestModel,
  ImportModel,
  ProviderAccountModel,
  SideListModel,
  TitleModule,
} from '../../models';
import {
  ClientService,
  CommonService,
  EstimatesService,
  ExpenseTypeService,
  ExpensesService,
  InvoicesService,
  JobService,
  RecurringInvoicesService,
  TaskService,
  UserService,
} from '../../services';
import {
  Copy,
  DownloadFile,
  Export,
  FileUpload,
  GetAllIntegratedMailIds,
  GetCompanyList,
  GetCountryList,
  GetCurrencyList,
  GetFileAsByte,
  GetSideList,
  GetTitleList,
  IsDataFound,
  IsEmailExist,
  IsNameExist,
  MultipleFileDownload,
  SaveModuleRequest,
  SendEmail,
  UpdateThemeColor,
  UploadFileAttachements,
} from './common.action';

export class CommonStateInfo {
  sideListModel: Array<SideListModel>;
  isLastPage?: boolean;
  //-------------
  title: Array<TitleModule>;
  files: Array<ImportModel>;
  allEmails: Array<ProviderAccountModel>;
  currency: Array<Currency>;
  fileUploadRequestModel: Array<FileUploadRequestModel>;
  primaryColor: string;
  isDataFound: any;
  isNameExist?: boolean;
  isEmailExist?: boolean;
  responseId?: string;
  country: Country[];
  company?: CompanyModel[];
  fileByteUrl?: string;
  //----
  isExport: boolean;
  isCopied: boolean;
  gridActions?: ActionModel[];
}

@State<CommonStateInfo>({
  name: 'common',
  defaults: {
    title: [],
    files: [],
    allEmails: [],
    currency: [],
    fileUploadRequestModel: [],
    primaryColor: '',
    isDataFound: '',
    sideListModel: [],
    country: [],
    //----
    isExport: false,
    isCopied: false,
  },
})
@Injectable()
export class CommonState {
  commonService: CommonService;
  clientService: ClientService;
  jobService: JobService;
  expenseTypeService: ExpenseTypeService;
  taskService: TaskService;
  userService: UserService;
  expenseService: ExpensesService;
  invoicesService: InvoicesService;
  estimatesService: EstimatesService;
  recurringInvoicesService: RecurringInvoicesService;

  constructor(public injector: Injector) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.clientService = this.injector.get<ClientService>(ClientService);
    this.jobService = this.injector.get<JobService>(JobService);
    this.expenseTypeService =
      this.injector.get<ExpenseTypeService>(ExpenseTypeService);
    this.taskService = this.injector.get<TaskService>(TaskService);
    this.userService = this.injector.get<UserService>(UserService);
    this.expenseService = this.injector.get<ExpensesService>(ExpensesService);
    this.invoicesService = this.injector.get<InvoicesService>(InvoicesService);
    this.estimatesService =
      this.injector.get<EstimatesService>(EstimatesService);
    this.recurringInvoicesService = this.injector.get<RecurringInvoicesService>(
      RecurringInvoicesService
    );
  }

  @Selector()
  static getTitle(state: CommonStateInfo) {
    return state.title;
  }

  @Selector()
  static getFiles(state: CommonStateInfo) {
    return state.files;
  }

  @Selector()
  static getSideList(state: CommonStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getCurrency(state: CommonStateInfo) {
    return state.currency;
  }

  @Selector()
  static getFileUploaded(state: CommonStateInfo) {
    return state.fileUploadRequestModel;
  }

  @Selector()
  static IsDataFound(state: CommonStateInfo) {
    return state.isDataFound;
  }

  @Selector()
  static IsNameExist(state: CommonStateInfo) {
    return state.isNameExist;
  }

  @Selector()
  static IsEmailExist(state: CommonStateInfo) {
    return state.isEmailExist;
  }

  @Selector()
  static getCountry(state: CommonStateInfo) {
    return state.country;
  }

  @Selector()
  static getGridActions(state: CommonStateInfo) {
    return state.gridActions;
  }

  @Action(GetTitleList)
  getTitleList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetTitleList
  ) {
    return this.commonService.getTitleList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          title: res,
        });
      })
    );
  }

  @Action(GetAllIntegratedMailIds)
  GetAllIntegratedMailIds(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetAllIntegratedMailIds
  ) {
    return this.commonService.GetAllIntegratedMailIds().pipe(
      tap((res) => {
        patchState({
          allEmails: res,
        });
      })
    );
  }

  @Action(GetCurrencyList)
  getCurrencyList({ getState, setState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCurrencyList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          currency: res,
        });
      })
    );
  }

  @Action(FileUpload)
  fileUpload(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: FileUpload
  ) {
    return this.commonService.fileUpload(action.fileUploadRequestModel).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fileUploadRequestModel: res,
        });
      })
    );
  }

  @Action(UploadFileAttachements)
  uploadFileAttachements(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: UploadFileAttachements
  ) {
    return this.commonService
      .uploadFileAttachements(action.fileUploadRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            fileUploadRequestModel: res,
          });
        })
      );
  }

  @Action(SendEmail)
  sendEmail(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SendEmail
  ) {
    return this.commonService.sendEmail(action.id, action.message);
  }

  @Action(UpdateThemeColor)
  updateThemeColor(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: UpdateThemeColor
  ) {
    return this.commonService.updateThemeColor(action.colorCode).pipe(
      tap((res) => {
        patchState({
          primaryColor: res,
        });
      })
    );
  }

  @Action(IsDataFound)
  IsDataFound(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: IsDataFound
  ) {
    return this.commonService.IsDataFound(action.moduleId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          isDataFound: res,
        });
      })
    );
  }

  @Action(IsNameExist)
  isNameExist(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: IsNameExist
  ) {
    return this.commonService.isNameExist(action.NameExistModel).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          isNameExist: res,
        });
      })
    );
  }

  @Action(IsEmailExist)
  isEmailExist(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: IsEmailExist
  ) {
    return this.commonService.isEmailExist(action.EmailExistModel).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          isEmailExist: res,
        });
      })
    );
  }

  @Action(SaveModuleRequest)
  saveModuleRequest(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: SaveModuleRequest
  ) {
    return this.commonService
      .saveModuleRequest(action.SaveImportRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            responseId: res,
          });
        })
      );
  }

  //--------
  @Action(Export)
  export({ patchState }: StateContext<CommonStateInfo>, action: Export) {
    let isExport = false;

    switch (action.moduleId) {
      case Modules.Clients:
        return this.clientService.export(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.ExpenseType:
        return this.expenseTypeService.export(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Tasks:
        return this.taskService.exportTask(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Users:
        return this.userService.exportUser(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Expenses:
        return this.expenseService.exportExpense(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Jobs:
        return this.jobService.exportJob(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Invoice:
        return this.invoicesService.exportInvoice(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.Estimate:
        return this.estimatesService.exportEstimate(action.queryParams).pipe(
          tap((res) => {
            isExport = true;
            patchState({
              isExport,
            });
          })
        );

      case Modules.RecurringInvoice:
        return this.recurringInvoicesService
          .exportRecurringInvoice(action.queryParams)
          .pipe(
            tap((res) => {
              isExport = true;
              patchState({
                isExport,
              });
            })
          );
    }
    return isExport;
  }

  @Action(Copy)
  copy({ patchState }: StateContext<CommonStateInfo>, action: Copy) {
    let isCopied = false;

    switch (action.moduleId) {
      case Modules.Clients:
        return this.clientService.copy(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.ExpenseType:
        return this.expenseTypeService.copy(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Tasks:
        return this.taskService.copy(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
      case Modules.Users:
        return this.userService.copy(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
      case Modules.Invoice:
        return this.invoicesService.copy(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );

      case Modules.Estimate:
        return this.estimatesService.copyEstimates(action.ids).pipe(
          tap((res) => {
            isCopied = true;
            patchState({
              isCopied,
            });
          })
        );
    }
    return isCopied;
  }

  @Action(GetSideList, { cancelUncompleted: true })
  getSideList(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetSideList
  ) {
    return this.commonService
      .getSideList(action.queryParams, action.moduleId)
      .pipe(
        tap((res) => {
          const state = getState();
          let common: SideListModel[] | null = [];
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isLastPage = headers.IsLastPage;
          if (
            action.queryParams.pageNumber &&
            action.queryParams.pageNumber > 1
          ) {
            common = state.sideListModel;
            common = common.concat(res.body!.data);
          } else {
            common = res.body!.data;
          }

          setState({
            ...state,
            sideListModel: common,
            gridActions: res.body!.actions,
            isLastPage,
          });
        })
      );
  }

  @Action(GetCompanyList, { cancelUncompleted: true })
  getCompanyList(
    { getState, patchState }: StateContext<CommonStateInfo>,
    action: GetCompanyList
  ) {
    return this.commonService.getCompanyList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let common: CompanyModel[] | null = [];
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          common = state.company!;
          common = common.concat(res);
        } else {
          common = res;
        }

        patchState({
          company: common,
        });
      })
    );
  }

  @Action(GetCountryList)
  getCountryList({ getState, patchState }: StateContext<CommonStateInfo>) {
    return this.commonService.getCountryList().pipe(
      tap((res) => {
        patchState({
          country: res,
        });
      })
    );
  }

  @Action(DownloadFile, { cancelUncompleted: true })
  downloadFile(
    { patchState }: StateContext<CommonStateInfo>,
    action: DownloadFile
  ) {
    return this.commonService.downloadFile(action.fileDownloadRequest);
  }

  @Action(MultipleFileDownload, { cancelUncompleted: true })
  multipleFileDownload(
    { patchState }: StateContext<CommonStateInfo>,
    action: MultipleFileDownload
  ) {
    return this.commonService.multipleFileDownload(
      action.multiFileDownloadRequest
    );
  }

  @Action(GetFileAsByte)
  getFileAsByte(
    { getState, setState }: StateContext<CommonStateInfo>,
    action: GetFileAsByte
  ) {
    return this.commonService.getFileAsByte(action.fileUrl).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          fileByteUrl: res.data,
        });
      })
    );
  }
}
