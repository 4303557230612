import { Modules } from '@app/core/enums';
import { Guid } from 'guid-typescript';
import {
  BusinessModel,
  DuplicateExistModel,
  DynamicColumnDetailModel,
  DynamicColumnModel,
  EmailTemplateModel,
  MenuItem,
  StandardPermission,
  UserStandardPermission,
} from '../../models';

export class SaveBasicInfo {
  static readonly type = '[SETTINGS] Save Business Basic Info';
  constructor(public businessModel: BusinessModel) {}
}

export class SavePreferences {
  static readonly type = '[SETTINGS] Save Business Preferences';
  constructor(public businessModel: BusinessModel) {}
}

export class SaveBusinessInvoiceInfo {
  static readonly type = '[SETTINGS] Save Business Invoice Info';
  constructor(public businessModel: BusinessModel) {}
}

export class SaveBusinessEstimateInfo {
  static readonly type = '[SETTINGS] Save Business Estimate Info';
  constructor(public businessModel: BusinessModel) {}
}

export class GetBusinessInvoice {
  static readonly type = '[SETTINGS] Get Business Invoice';
}

export class GetBusinessEstimate {
  static readonly type = '[SETTINGS] Get Business Estimate';
}

export class GetSettingsData {
  static readonly type = '[SETTINGS] Get';
}

export class GetSettingsTemplateData {
  static readonly type = '[SETTINGS] Get Email Template';
}

export class SaveBusiness {
  static readonly type = '[SETTINGS] Save Business';
  constructor(public businessModel: BusinessModel) {}
}

export class UpdateSettingsTemplate {
  static readonly type = '[SETTINGS] Update Template';
  constructor(public settingsData: Array<EmailTemplateModel>) {}
}

export class GetStartWeekAndEndWeek {
  static readonly type = '[SETTINGS] Get Week Start And End Date';
}

export class SaveDynamicColumns {
  static readonly type = '[SETTINGS] Save Dynamic Columns';
  constructor(public dynamicColumns: DynamicColumnModel[]) {}
}

export class GetBusinessFeesSetting {
  static readonly type = '[SETTINGS] Get Business Fees Setting';
}

export class SaveBusinessFeesSettingByModuleId {
  static readonly type = '[SETTINGS] Save Business Fees Setting By Module ID';
  constructor(public dynamicColumnDetailModel: DynamicColumnDetailModel) {}
}

export class SaveDynamicColumnsData {
  static readonly type = '[SETTINGS] Save Dynamic Columns Data';
  constructor(public dynamicColumns: DynamicColumnModel[]) {}
}

export class AddRemoveDemoData {
  static readonly type = '[SETTINGS] Add Remove Demo Data';
  constructor(public isAddDemoData: boolean) {}
}

export class GetPermissions {
  static readonly type = '[SETTINGS] Get Permissions';
  constructor(public searchText?: string) {}
}

export class UpdatePermissions {
  static readonly type = '[SETTINGS] Update Permissions';
  constructor(public standardPermission: StandardPermission[]) {}
}

export class GetMenus {
  static readonly type = '[SETTINGS] Get Menus';
}

export class SetSubMenuData {
  static readonly type = '[SETTINGS] Set Sub Menu Data';
  constructor(public subMenu: MenuItem[], public selectedMenu?: MenuItem) {}
}

export class SaveBusinessDynamicColumn {
  static readonly type = '[SETTINGS] Save Business Dynamic Column';

  constructor(public moduleId: Modules, public dynamicColumns: any[]) {}
}

export class GetBusinessTax {
  static readonly type = '[SETTINGS] Get Business Tax';
}

export class SetSubMenuChildrenData {
  static readonly type = '[SETTINGS] Set Sub Menu Children Data';
  constructor(public subMenuChildren: MenuItem[]) {}
}

export class GetUserBusinessPermission {
  static readonly type = '[SETTINGS] Get User Business Permission';
  constructor(public userId: Guid) {}
}

export class SaveUserPermission {
  static readonly type = '[SETTINGS] Save User Permission';
  constructor(
    public standardPermission: UserStandardPermission[],
    public userId: Guid
  ) {}
}

export class UpdateSettingsConfig {
  static readonly type = '[SETTINGS] Update Config';
  constructor(public isEmailSendFromCapium: boolean) {}
}

export class GetClientTypes {
  static readonly type = '[SETTINGS] Get Client Types';
}

export class GetVatScheme {
  static readonly type = '[SETTINGS] Get Vat Scheme';
}

export class GetVatSubmitType {
  static readonly type = '[SETTINGS] Get Vat Submit Type';
}

export class GetNextCodeByModuleId {
  static readonly type = '[SETTINGS] Get Next Code By ModuleId';
  constructor(public moduleId: number) {}
}

export class IsDuplicateExist {
  static readonly type = '[SETTINGS] Is Duplicate Exist';

  constructor(public duplicateExistModel: DuplicateExistModel) {}
}
