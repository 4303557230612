import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  InvoiceAmountByInvoiceMonthDataModel,
  InvoiceOverviewRequestModel,
} from '@app/core/models';
import { GetInvoiceIncomeTrend } from '@app/core/store';
import { Store } from '@ngxs/store';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-fees-income-trend',
  templateUrl: './fees-income-trend.component.html',
  styleUrls: ['./fees-income-trend.component.scss'],
})
export class FeesIncomeTrendComponent {
  public invoicedData: Object[] = [];
  public receivedData: Object[] = [];

  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    lineStyle: { width: 0 },
    valueType: 'Category',
    labelRotation: Browser.isDevice ? -45 : 0,
    labelIntersectAction: Browser.isDevice ? 'None' : 'Rotate45',
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    lineStyle: { width: 0 },
    minimum: 0,
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '£ {value}',
  };
  public tooltip: Object = {
    enable: true,
  };
  public legend: Object = {
    visible: true,
    enableHighlight: true,
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }
  // custom code end
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  public width: string = '100%';
  public marker: Object = {
    visible: true,
    height: 7,
    width: 7,
    shape: 'Circle',
    isFilled: true,
  };
  public marker1: Object = {
    visible: true,
    height: 7,
    width: 7,
    shape: 'Diamond',
    isFilled: true,
  };

  filter = 1;
  start: string = '';
  end: string = '';
  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  overview: any;
  invoiceBalanceAmountByDueMonthData: InvoiceAmountByInvoiceMonthDataModel;

  @Input()
  triggerFilterData: any;

  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<span>${point.tooltip}</span>',
      header: '',
    };
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getInvoiceIncomeTrend();
    });
  }

  getInvoiceIncomeTrend(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(new GetInvoiceIncomeTrend(this.invoiceOverviewRequestModel))
      .pipe()
      .subscribe(
        (res) => {
          this.overview = res.overview.invoiceIncomeTrend;
          if (this.overview) {
            this.invoicedData = this.overview.data1;
            this.receivedData = this.overview.data2;
          } else {
            this.invoicedData = [];
            this.receivedData = [];
          }
        },
        (error) => {
          this.invoicedData = [];
          this.receivedData = [];
        }
      );
  }
}
