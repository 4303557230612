import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { PaymentMethod } from '../../models';
import { PaymentMethodService } from '../../services';
import { GetPaymentMethodList } from './payment-method.action';

export class PaymentMethodStateInfo {
  paymentMethod: Array<PaymentMethod>;
  paymentMethodId?: number;
}

@State<PaymentMethodStateInfo>({
  name: 'paymentMethod',
  defaults: {
    paymentMethod: [],
  },
})
@Injectable()
export class PaymentMethodState {
  constructor(private paymentMethodService: PaymentMethodService) {}

  @Selector()
  static getPaymentMethod(state: PaymentMethodStateInfo) {
    return state.paymentMethod;
  }

  @Action(GetPaymentMethodList)
  getPaymentMethodList({
    getState,
    setState,
  }: StateContext<PaymentMethodStateInfo>) {
    return this.paymentMethodService.getPaymentMethods().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          paymentMethod: res,
        });
      })
    );
  }
}
