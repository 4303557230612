import { Guid } from 'guid-typescript';

export class TitleModule {
  id: number;
  name: string;
}

export class NameExistModel {
  name: string;
  moduleId: number;
  entityId?: Guid;
}

export class EmailExistModel {
  userId: string;
  email: string;
}

export class DuplicateExistModel {
  moduleId: number;
  entityId?: Guid;
  entityName: string;
  checkBy?: number;
}
