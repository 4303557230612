export enum RecurringType {
  Never = 1,
  Daily = 2,
  Weekly = 3,
  Monthly = 4,
  Yearly = 5,
  Custom = 6,
}

export enum ScheduleSubPeriod {
  Day = 1,
  Week = 2,
  Month = 3,
  Year = 4,
}

export enum ScheduleType {
  After = 0,
  On = 1,
  Never = 2,
}
