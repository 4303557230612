import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { ModuleName } from '../../enums';
import {
  GlobalComponent,
  InvoiceDynamicDetailGrid,
  InvoiceEmailModel,
  InvoiceHistoryModel,
  InvoicePaymentModel,
  Invoices,
  Note,
  RecurringSchedule,
  ReminderTypeModel,
  ServiceExpenseModel,
  SideListModel,
  TemplateDetail,
} from '../../models';
import { InvoicesService } from '../../services';
import {
  CalculateInvoiceByClientId,
  CopyInvoices,
  DeleteAllInvoicePayment,
  DeleteInvoicePayment,
  DeleteSelectedInvoice,
  ExportInvoice,
  ExportInvoicePaymentToPDF,
  ExportInvoiceToPDF,
  ExportInvoicesToPDF,
  GetAmountDue,
  GetInvoiceByUniversalId,
  GetInvoiceDetailList,
  GetInvoiceList,
  GetInvoiceNoteListById,
  GetInvoicePaymentsDetailList,
  GetInvoiceReceiptDetails,
  GetInvoiceRecentHistory,
  GetInvoiceTemplate,
  GetPaymentByPaymentId,
  GetReminderType,
  GetScheduleSettingByInvoiceId,
  GetSelectedReminderType,
  GetServiceExpenses,
  GetUserInvoiceSetting,
  SaveInvoice,
  SaveInvoiceDyanamicGird,
  SaveInvoiceNotes,
  SaveInvoicePayment,
  SaveRecurringSchedule,
  SaveReminder,
  SendAsLinkInvoice,
  SendInvoice,
  SendInvoiceEmail,
  SendPaymentReceipt,
  SetDefaultInvoiceId,
  SetInvoiceDefaultState,
  SetInvoiceId,
  UpdateInvoiceStatus,
} from './invoice.action';

export class InvoiceStateInfo {
  sideListModel?: Array<SideListModel>;
  isLastPage?: boolean;
  invoiceList: Invoices[];
  totalRecord?: number;
  isDataAvailable?: boolean;
  exported?: boolean;
  invoiceId?: Guid;
  paymentId?: number;
  schedule: RecurringSchedule;
  invoiceData?: Invoices;
  invoicePayments?: Array<InvoicePaymentModel>;
  invoicePaymentData?: InvoicePaymentModel;
  invoiceNotes?: Array<Note>;
  dueAmount: number;
  isSendEmail: boolean;
  isSendReminder: boolean;
  isRecordPaymentDeleted: boolean;
  emailTemplateData: InvoiceEmailModel;
  invoiceHistoryModel: InvoiceHistoryModel;
  reminderTypeModel: Array<ReminderTypeModel>;
  invoiceDynamicDetailGrid: InvoiceDynamicDetailGrid;
  isSendPayment: boolean;
  isDynamicGridSaved: boolean;
  isSaveReminder: boolean;
  highlightIDs?: Array<string>;
  serviceExpenseData?: Array<ServiceExpenseModel>;
  previewData?: any;
  isStatusUpdated?: boolean;
  isSuccess?: boolean;
  invoicePaymentsDataLength: number;
  calculatedInvoiceData?: Array<any>;
}

@State<InvoiceStateInfo>({
  name: 'invoice',
  defaults: {
    sideListModel: [],
    invoiceList: [],
    isDataAvailable: true,
    schedule: new RecurringSchedule(),
    dueAmount: 0,
    isSendEmail: false,
    isSendReminder: false,
    emailTemplateData: new InvoiceEmailModel(),
    invoiceHistoryModel: new InvoiceHistoryModel(),
    reminderTypeModel: [],
    isSendPayment: false,
    isSaveReminder: false,
    isDynamicGridSaved: false,
    isRecordPaymentDeleted: false,
    invoiceDynamicDetailGrid: new InvoiceDynamicDetailGrid(),
    invoicePaymentsDataLength: 0,
  },
})
@Injectable()
export class InvoiceState {
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  constructor(
    private invoicesService: InvoicesService,
    public globalComponent: GlobalComponent
  ) {}

  @Selector()
  static isLastPage(state: InvoiceStateInfo): any {
    return state.isLastPage;
  }

  @Selector()
  static getInvoiceList(state: InvoiceStateInfo): any {
    return state.sideListModel;
  }

  @Selector()
  static getInvoices(state: InvoiceStateInfo): any {
    return state.invoiceList;
  }

  @Selector()
  static totalRecord(state: InvoiceStateInfo): any {
    return state.totalRecord;
  }

  @Selector()
  static isDataAvailable(state: InvoiceStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static getInvoiceId(state: InvoiceStateInfo) {
    return state.invoiceId;
  }

  @Selector()
  static getInvoiceTemplate(state: InvoiceStateInfo) {
    return state.emailTemplateData;
  }

  @Selector()
  static getPaymentId(state: InvoiceStateInfo) {
    return state.paymentId;
  }

  @Selector()
  static getInvoiceSchedule(state: InvoiceStateInfo) {
    return state.schedule;
  }

  @Selector()
  static getInvoiceData(state: InvoiceStateInfo): any {
    return state.invoiceData;
  }

  @Selector()
  static getInvoicePayments(state: InvoiceStateInfo): any {
    return state.invoicePayments ?? [];
  }

  @Selector()
  static getPaymentData(state: InvoiceStateInfo): any {
    return state.invoicePaymentData;
  }

  @Selector()
  static getInvoiceNotes(state: InvoiceStateInfo): any {
    return state.invoiceNotes;
  }

  @Selector()
  static getHighlightedIds(state: InvoiceStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getServiceExpenses(state: InvoiceStateInfo): any {
    return state.serviceExpenseData;
  }

  @Selector()
  static getInvoiceReceiptDetails(state: InvoiceStateInfo): any {
    return state.previewData;
  }

  @Selector()
  static getInvoicePaymentsDataLength(state: InvoiceStateInfo) {
    return state.invoicePaymentsDataLength;
  }

  @Action(GetInvoiceList, { cancelUncompleted: true })
  getInvoiceList(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceList
  ): any {
    return this.invoicesService.getInvoiceList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let invoicesData: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          invoicesData = state.sideListModel!;
          invoicesData = invoicesData.concat(res.body!);
        } else {
          invoicesData = res.body;
        }

        patchState({
          ...state,
          sideListModel: invoicesData!,
          isLastPage,
        });
      })
    );
  }

  @Action(SetDefaultInvoiceId)
  setDefaultInvoiceId({ patchState }: StateContext<InvoiceStateInfo>) {
    patchState({
      invoiceId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(GetInvoiceDetailList, { cancelUncompleted: true })
  getInvoiceDetailList(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceDetailList
  ): any {
    return this.invoicesService.getInvoiceDetailList(action.invoiceParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;
        patchState({
          ...state,
          invoiceList: res.body!,
          totalRecord,
          isDataAvailable,
        });
      })
    );
  }

  @Action(GetInvoiceByUniversalId)
  getInvoiceByUniversalId(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceByUniversalId
  ): any {
    return this.invoicesService
      .getInvoiceByUniversalId(action.universalId)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            invoiceId: action.universalId,
            invoiceData: res,
          });
        })
      );
  }

  @Action(SaveInvoice)
  saveInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: SaveInvoice
  ) {
    return this.invoicesService.saveInvoice(action.invoiceBasicInfo).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          invoiceId: res.data,
        });
      })
    );
  }

  @Action(GetInvoicePaymentsDetailList)
  getInvoicePaymentsDetailList(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoicePaymentsDetailList
  ): any {
    return this.invoicesService
      .getInvoicePaymentsDetailList(action.queryParams)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            invoicePayments: res,
          });
        })
      );
  }

  //////////////////////////////////

  @Action(CopyInvoices)
  copyInvoice(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: CopyInvoices
  ): any {
    return this.invoicesService.copy(action.invoiceIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(DeleteSelectedInvoice)
  deleteSelectedInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: DeleteSelectedInvoice
  ): any {
    return this.invoicesService.deleteInvoices(action.invoiceIds).pipe(
      tap(() => {
        const state = getState();

        let filteredInvoices = state.invoiceList;
        const filteredForSideList = [];

        patchState({
          ...state.invoiceList,
          invoiceList: filteredInvoices,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(UpdateInvoiceStatus)
  updateInvoiceStatus(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: UpdateInvoiceStatus
  ): any {
    return this.invoicesService.updateInvoiceStatus(
      action.invoiceIds,
      action.status
    );
  }

  @Action(SendInvoice)
  sendInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: SendInvoice
  ): any {
    return this.invoicesService.sendInvoice(action.invoiceId);
  }

  @Action(SetInvoiceId)
  setInvoiceId(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SetInvoiceId
  ) {
    patchState({
      invoiceId: action.invoiceId,
    });
  }

  @Action(GetScheduleSettingByInvoiceId)
  getScheduleSettingByInvoiceId(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetScheduleSettingByInvoiceId
  ): any {
    return this.invoicesService
      .getScheduleSettingByInvoiceId(action.invoiceId)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            schedule: res,
          });
        })
      );
  }

  @Action(SaveRecurringSchedule)
  saveRecurringSchedule(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: SaveRecurringSchedule
  ) {
    return this.invoicesService
      .saveRecurringSchedule(action.recurringSchedule)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            schedule: state.schedule,
          });
        })
      );
  }

  @Action(GetServiceExpenses)
  getServiceExpenses(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetServiceExpenses
  ): any {
    return this.invoicesService.getServiceExpenses(action.universalId).pipe(
      tap((res) => {
        const state = getState();

        patchState({
          ...state,
          serviceExpenseData: res,
        });
      })
    );
  }

  @Action(SaveInvoicePayment)
  saveInvoicePayment(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SaveInvoicePayment
  ) {
    return this.invoicesService.saveInvoicePayment(action.invoicePayment).pipe(
      tap((res) => {
        patchState({
          paymentId: res,
        });
      })
    );
  }

  @Action(DeleteInvoicePayment)
  deleteInvoicePayment(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: DeleteInvoicePayment
  ) {
    return this.invoicesService.deleteInvoicePayment(action.paymentId).pipe(
      tap((res) => {
        patchState({
          isRecordPaymentDeleted: res,
        });
      })
    );
  }

  @Action(GetPaymentByPaymentId)
  getPaymentByPaymentId(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetPaymentByPaymentId
  ): any {
    return this.invoicesService.getPaymentByPaymentId(action.paymentId).pipe(
      tap((res) => {
        const state = getState();

        patchState({
          ...state,
          invoicePaymentData: res,
        });
      })
    );
  }

  @Action(ExportInvoicePaymentToPDF)
  exportInvoicePaymentToPDF(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: ExportInvoicePaymentToPDF
  ): any {
    return this.invoicesService.exportInvoicePaymentToPDF(
      action.paymentId,
      action.exportAction
    );
  }

  @Action(SendPaymentReceipt)
  sendPaymentReceipt(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SendPaymentReceipt
  ): any {
    return this.invoicesService.sendPaymentReceipt(action.paymentId).pipe(
      tap((res) => {
        patchState({
          isSendPayment: res.data,
        });
      })
    );
  }

  @Action(GetInvoiceNoteListById)
  getInvoiceNoteListById(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceNoteListById
  ): any {
    return this.invoicesService.getInvoiceNoteList(action.invoiceId).pipe(
      tap((res) => {
        const state = getState();

        patchState({
          ...state,
          invoiceNotes: res,
        });
      })
    );
  }

  @Action(SaveInvoiceNotes)
  saveInvoiceNotes(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: SaveInvoiceNotes
  ) {
    return this.invoicesService.saveInvoiceNotes(action.note);
  }

  @Action(ExportInvoice)
  exportInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: ExportInvoice
  ): any {
    return this.invoicesService.exportInvoice(action.exportParams);
  }

  @Action(ExportInvoiceToPDF)
  exportInvoiceToPDF(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: ExportInvoiceToPDF
  ): any {
    return this.invoicesService.exportInvoiceToPDF(
      action.invoiceId,
      action.format,
      action.fileName,
      action.exportAction
    );
  }

  @Action(SendAsLinkInvoice)
  sendAsLinkInvoice(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: SendAsLinkInvoice
  ) {
    return this.invoicesService.sendAsLinkInvoice(
      action.invoiceSendAsLinkModel
    );
  }

  @Action(SendInvoiceEmail)
  sendInvoiceEmail(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SendInvoiceEmail
  ) {
    return this.invoicesService
      .sendInvoiceEmail(action.emailModel, ModuleName.Invoice)
      .pipe(
        tap((res) => {
          patchState({
            isSendEmail: res.isSuccess,
          });
        })
      );
  }

  @Action(DeleteAllInvoicePayment)
  deleteAllInvoicePayment(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: DeleteAllInvoicePayment
  ): any {
    return this.invoicesService.deleteAllInvoicePayment(action.invoiceId);
  }

  @Action(GetAmountDue)
  getAmountDue(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetAmountDue
  ) {
    return this.invoicesService.getAmountDueInvoiceId(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          dueAmount: res,
        });
      })
    );
  }

  @Action(GetInvoiceTemplate)
  getInvoiceTemplate(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceTemplate
  ) {
    return this.invoicesService
      .getInvoiceTemplate(action.invoiceId, action.templateId)
      .pipe(
        tap((res: TemplateDetail) => {
          patchState({
            emailTemplateData: res,
          });
        })
      );
  }

  @Action(GetInvoiceRecentHistory)
  getInvoiceRecentHistory(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceRecentHistory
  ) {
    return this.invoicesService.getInvoiceRecentHistory(action.invoiceId).pipe(
      tap((res) => {
        patchState({
          invoiceHistoryModel: res,
        });
      })
    );
  }

  @Action(GetReminderType)
  getReminderType(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetReminderType
  ) {
    return this.invoicesService.getReminderType().pipe(
      tap((res) => {
        patchState({
          reminderTypeModel: res,
        });
      })
    );
  }

  @Action(GetSelectedReminderType)
  getSelectedReminderType(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetSelectedReminderType
  ) {
    return this.invoicesService.getReminderIds(action.invoiceId).pipe(
      tap((res) => {
        const state = getState();

        state.reminderTypeModel.forEach((arr1) => {
          res.forEach((arr2) => {
            if (arr2.typeId === arr1.typeId) {
              arr1.isActive = arr2.isActive;
              arr1.id = arr2.id;
            }
          });
        });

        patchState({
          reminderTypeModel: state.reminderTypeModel,
        });
      })
    );
  }

  @Action(GetUserInvoiceSetting)
  getUserInvoiceSetting(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: GetUserInvoiceSetting
  ) {
    return this.invoicesService.getUserInvoiceSetting(action.moduleId).pipe(
      tap((res) => {
        patchState({
          invoiceDynamicDetailGrid: res,
        });
      })
    );
  }

  @Action(SaveInvoiceDyanamicGird)
  saveInvoiceDyanamicGird(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SaveInvoiceDyanamicGird
  ) {
    return this.invoicesService
      .saveInvoiceDyanamicGird(action.dynamicDetailData)
      .pipe(
        tap((res) => {
          patchState({
            isDynamicGridSaved: res,
          });
        })
      );
  }

  @Action(SaveReminder)
  saveReminder(
    { patchState }: StateContext<InvoiceStateInfo>,
    action: SaveReminder
  ) {
    return this.invoicesService
      .saveReminder(action.invoiceId, action.reminderTypeModel)
      .pipe(
        tap((res) => {
          patchState({
            isSaveReminder: res,
          });
        })
      );
  }

  @Action(SetInvoiceDefaultState)
  setInvoiceDefaultState({ patchState }: StateContext<InvoiceStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(ExportInvoicesToPDF)
  exportInvoicesToPDF(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: ExportInvoicesToPDF
  ): any {
    return this.invoicesService.exportInvoicesToPDF(
      action.invoiceIds,
      action.exportAction
    );
  }

  @Action(GetInvoiceReceiptDetails)
  getInvoiceReceiptDetails(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: GetInvoiceReceiptDetails
  ): any {
    return this.invoicesService
      .getInvoiceReceiptDetails(action.universalId)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            previewData: res,
          });
        })
      );
  }

  @Action(CalculateInvoiceByClientId)
  calculateInvoiceByClientId(
    { getState, patchState }: StateContext<InvoiceStateInfo>,
    action: CalculateInvoiceByClientId
  ) {
    return this.invoicesService
      .calculateInvoiceByClientId(action.clientId)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            calculatedInvoiceData: res,
          });
        })
      );
  }
}
