import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CalendarService, JobService, TimeService } from '@app/core/services';
import { AngularMaterialModule } from '@app/core/shared/angular-material/angular-material.module';
import { ComponentsModule } from '@app/core/shared/components/components.module';
import { CalendarState } from '@app/core/store';
import { JobState } from '@app/core/store/job';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsModule } from '@ngxs/store';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeModule } from '../home.module';
import { AddJobsComponent } from './add-jobs/add-jobs.component';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { JobDetailsActivityComponent } from './job-details/job-details-activity/job-details-activity.component';
import { JobDetailsCommentsComponent } from './job-details/job-details-comments/job-details-comments.component';
import { JobDetailsEmailComponent } from './job-details/job-details-email/job-details-email.component';
import { JobDetailsFilesComponent } from './job-details/job-details-files/job-details-files.component';
import { JobDetailsRecurringComponent } from './job-details/job-details-recurring/job-details-recurring.component';
import { JobDetailsReminderComponent } from './job-details/job-details-reminder/job-details-reminder.component';
import { JobDetailsSubtaskComponent } from './job-details/job-details-subtask/job-details-subtask.component';
import { JobDetailsUserRoiComponent } from './job-details/job-details-subtask/job-details-user-roi/job-details-user-roi.component';
import { JobDetailsTimelogChartComponent } from './job-details/job-details-timelog/job-details-timelog-chart/job-details-timelog-chart.component';
import { JobDetailsTimelogComponent } from './job-details/job-details-timelog/job-details-timelog.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobsRoutingModule } from './jobs-routing.module';
import { JobsComponent } from './jobs.component';
import {
  DayService,
  MonthService,
  ScheduleModule,
  WeekService,
} from '@syncfusion/ej2-angular-schedule';
import { JobDetailsInvoiceComponent } from './job-details/job-details-invoice/job-details-invoice.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    JobsComponent,
    JobsListComponent,
    CalendarListComponent,
    AddJobsComponent,
    JobDetailsComponent,
    JobDetailsTimelogComponent,
    JobDetailsSubtaskComponent,
    JobDetailsEmailComponent,
    JobDetailsReminderComponent,
    JobDetailsRecurringComponent,
    JobDetailsFilesComponent,
    JobDetailsCommentsComponent,
    JobDetailsActivityComponent,
    JobDetailsUserRoiComponent,
    JobDetailsTimelogChartComponent,
    JobDetailsInvoiceComponent,
  ],
  imports: [
    CommonModule,
    JobsRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
    NgSelectModule,
    NgxsModule.forFeature([JobState, CalendarState]),
    HomeModule,
    NgApexchartsModule,
    ScheduleModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    JobService,
    CalendarService,
    TimeService,
    DayService,
    WeekService,
    MonthService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [JobDetailsComponent],
})
export class JobsModule {}
