import { Guid } from 'guid-typescript';

export class TimeModule {
  startDate?: string;
  endDate?: string;
  search?: string;
  userId: Guid;
  pendingApprovalType?: number;
  showTimer?: boolean;
  showWeekView?: boolean;
  projectId?: string;
  timelogStatus?: number;
  selectedTab?: number;
  selectedTabOrdinalValue?: number;
  selectedStatus?: number;
}

export class TimelogDetailModel {
  universalId?: Guid;
  jobId?: Guid;
  clientId?: Guid;
  taskId?: Guid;
  subTaskId?: Guid;
  duration?: string;
  startTime?: string;
  endTime?: string;
  date?: Date;
  clientName?: string;
  userId?: string;
  isAddMode?: boolean;
  showTimer?: boolean;
  isDayView?: boolean;
  isCalendarView?: boolean;
  calendarData?: any;
}

export class TimelogByUser {
  userId: Guid;
  weekName: string;
  userType: string;
  totalHours: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalAmount: number;
  totalCost: number;
  isSelected: boolean;
  weekStartDate: string;
  weekEndDate: string;
}

export class TimelogByWeek {
  userId: Guid;
  fullName: string;
  userType: string;
  totalHours: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalAmount: number;
  totalCost: number;
  isSelected: boolean;
  weekStartDate: string;
  weekEndDate: string;
}

export class AddTimeLogModel {
  universalId?: Guid;
  jobId?: Guid;
  clientId?: Guid;
  taskId?: Guid;
  userId?: Guid;
  jobSubTaskId?: Guid;
  date?: string;
  status?: number;
  description?: string;
  taskName?: string;
  startTime?: string;
  endTime?: string;
  duration?: string;
  timeSpent?: string;
  isBillable?: boolean;
  isFromTimer?: boolean;
  applyMinimumChargeableTime?: boolean;
}

export class TimeSubTaskListModel {
  name: string;
  universalId: Guid;
  isBillable: boolean;
  isCompleted: boolean;
  isInclude: boolean;
}
