import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Modules } from '@app/core/enums';
import { CommonService, ToggleMenu } from '@app/core/services';
import { SettingsState } from '@app/core/store';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-notification-timeline',
  templateUrl: './notification-timeline.component.html',
  styleUrls: ['./notification-timeline.component.scss'],
})
export class NotificationTimelineComponent implements OnInit {
  selectedIndex = 0;
  showOuterTab = true;
  selectedTab = 0;
  subscriptionRouting: Subscription;
  modules = Modules;

  @Select(SettingsState.getSubMenus)
  subMenus$: Observable<any>;

  constructor(
    private toggleMenu: ToggleMenu,
    private _Activatedroute: ActivatedRoute,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService.sendThemeMessage('theme');
    this.toggleMenu.showIcon.next(false);
    this.subscriptionRouting = this._Activatedroute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.selectedTab = Number(params.get('tabId'));
        } else {
          this.selectedTab = 0;
        }
      }
    );
  }

  tabevent(event: WheelEvent): void {
    if (this.showOuterTab) {
      const scroll = event.deltaY;
      if (scroll > 0) {
        if (this.selectedIndex >= 2) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex++;
        }
      } else if (scroll < 0) {
        if (this.selectedIndex <= 0) {
          this.selectedIndex = 2;
        } else {
          this.selectedIndex--;
        }
      }
    }
  }
}
