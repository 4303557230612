<div class="notification-wrapper">
  <div class="info-tab sidenav-body-content">
    <mat-tab-group
      mat-align-tabs="start"
      [(selectedIndex)]="selectedTab"
      disableRipple
      animationDuration="0ms"
      animationDuration="0ms "
    >
      <mat-tab label="Announcement">
        <app-announcement></app-announcement>
      </mat-tab>

      <mat-tab label="Activity">
        <app-activity></app-activity>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
