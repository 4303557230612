<div class="content-body timelog-list">
  <div class="client-progress">
    <div class="mt-2">
      <div class="d-flex align-items-center gap-30">
        <div class="d-flex align-items-center gap-5 font-size-14 text-gray">
          <mat-icon class="text-primary">person</mat-icon>
          Clients : {{ timelogOverviewData?.totalClient }}
        </div>
        <div class="d-flex align-items-center gap-5 font-size-14 text-gray">
          <mat-icon class="font-size-18 text-primary">add_task</mat-icon>
          Jobs : {{ timelogOverviewData?.totalJob }}
        </div>
        <div class="d-flex align-items-center gap-5 font-size-14 text-gray">
          <mat-icon
            class="font-size-18 text-primary"
            fontSet="material-icons-outlined"
            >task</mat-icon
          >
          Tasks : {{ timelogOverviewData?.totalTask }}
        </div>
      </div>
    </div>
    <div class="client-progress-list">
      <div
        id="chart"
        class="cust-bar-chart"
        *ngIf="chartOptions && clientTaskDetail?.length !== 0"
      >
        <apx-chart
          [series]="chartOptions.series!"
          [chart]="chartOptions.chart!"
          [dataLabels]="chartOptions.dataLabels!"
          [plotOptions]="chartOptions.plotOptions!"
          [xaxis]="chartOptions.xaxis!"
          [stroke]="chartOptions.stroke!"
          [fill]="chartOptions.fill!"
          [title]="chartOptions.title!"
          [legend]="chartOptions.legend!"
          [grid]="chartOptions.grid!"
          [tooltip]="chartOptions.tooltip!"
        ></apx-chart>
      </div>
    </div>
    <div class="norecord-chart" *ngIf="clientTaskDetail?.length === 0">
      <img src="assets/images/column-icon.svg" alt="Column Chart" />
      <p class="pt-10">No data available yet!</p>
    </div>
  </div>
</div>
