export class OverviewSeriesModel {
  name: string;
  data: Array<number>;
}

export class InvoiceAmountByInvoiceMonthDataModel {
  categories: Array<string>;
  series: Array<OverviewSeriesModel>;
}

export class ClientByInvoiceAmountModel {
  labels: Array<string>;
  series: Array<number>;
}

export class InvoiceAmountByCategoryModel {
  typeName: string;
  totalAmount: number;
  percent: number;
}

export class EstimateCountByStatusDataModel {
  labels: Array<string>;
  series: Array<number>;
}

export class PaymentAmountByPaymentMethod {
  labels: Array<string>;
  series: Array<number>;
}

export class InvoiceCountByStatusMethod {
  labels: Array<string>;
  series: Array<number>;
}
