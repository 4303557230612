import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs/operators';
import { GlobalComponent } from '../models';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  service_count = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private globalComponent: GlobalComponent
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    this.service_count++;
    return next.handle(request).pipe(
      finalize(() => {
        this.service_count--;
        if (
          this.service_count === 0 &&
          this.globalComponent.getLoggedInUserId() !==
            (Guid.EMPTY as unknown as Guid)
        ) {
          this.spinner.hide();
        }
      })
    );
  }
}
