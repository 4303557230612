import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BulkJobModel,
  EmailModel,
  ExportParams1,
  FeesTypeModel,
  InvoiceParameters,
  JobCommentListModel,
  JobCommentModel,
  JobDetailModel,
  JobEmailModel,
  JobFilesModel,
  JobGroupByTimelogParams,
  JobInvoiceModel,
  JobModel,
  JobQueryParams,
  JobRecurring,
  JobReminderModel,
  JobSubTaskModel,
  JobSummaryModel,
  JobTaskModel,
  JobUserModel,
  ListModel,
  QueryParams,
  ReminderModel,
  ScheduleJobsModel,
  SideListModel,
  UpdateJobStatusModel,
} from '@app/core/models';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CommonService } from '../common';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Job';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  deleteSelectedJob(jobIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(jobIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteJobByUniversalId`,
      options
    );
  }

  completeJobSubTask(ids?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/completeJobSubTask`,
      JSON.stringify(ids),
      headers
    );
  }

  updateJobStatus(
    updateJobStatusModel?: UpdateJobStatusModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateJobStatus`,
      JSON.stringify(updateJobStatusModel),
      headers
    );
  }

  createScheduledJob(scheduleJobsModel?: ScheduleJobsModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createScheduledJob`,
      JSON.stringify(scheduleJobsModel),
      headers
    );
  }

  getJobRecurring(jobId: Guid): Observable<JobRecurring> {
    return this.http.get<JobRecurring>(
      `${this.apiUrl}${this.routeBase}/getJobRecurringByUniversalId/${jobId}`
    );
  }

  updateRecurringJobStatus(jobid?: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateRecurringJobStatus`,
      JSON.stringify(jobid),
      headers
    );
  }

  saveJobRecurringSetting(jobRecurring: JobRecurring): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveJobRecurringSetting`,
      JSON.stringify(jobRecurring),
      headers
    );
  }

  getAllJobs(): Observable<Array<ListModel>> {
    return this.http.get<Array<ListModel>>(
      `${this.apiUrl}${this.routeBase}/getAllJobs`
    );
  }

  getAvailableJobByRole(): Observable<Array<ListModel>> {
    return this.http.get<Array<ListModel>>(
      `${this.apiUrl}${this.routeBase}/getAvailableJobByRole`
    );
  }

  sendJobReminderEmail(
    jobId: Guid,
    reminderModel: ReminderModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/sendJobReminderEmail/${jobId}`,
      JSON.stringify(reminderModel),
      headers
    );
  }

  sendJobEmail(jobId: Guid, emailModel: EmailModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/sendJobEmail/${jobId}`,
      JSON.stringify(emailModel),
      headers
    );
  }

  getJobEmailList(jobId: Guid): Observable<Array<JobEmailModel>> {
    return this.http.get<Array<JobEmailModel>>(
      `${this.apiUrl}${this.routeBase}/getJobEmail/${jobId}`
    );
  }

  saveJobComment(jobId: Guid, emailModel: JobCommentModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveJobComment/${jobId}`,
      JSON.stringify(emailModel),
      headers
    );
  }

  getJobReminderList(jobId: Guid): Observable<Array<JobReminderModel>> {
    return this.http.get<Array<JobReminderModel>>(
      `${this.apiUrl}${this.routeBase}/getJobReminder/${jobId}`
    );
  }

  getJobCommentList(jobId: Guid): Observable<Array<JobCommentListModel>> {
    return this.http.get<Array<JobCommentListModel>>(
      `${this.apiUrl}${this.routeBase}/getJobComments/${jobId}`
    );
  }

  getAvailableUsers(
    clientId: Guid,
    taskId: Guid,
    jobId: Guid
  ): Observable<Array<JobUserModel>> {
    return this.http.get<Array<JobUserModel>>(
      `${this.apiUrl}${this.routeBase}/getAvailableUsers?clientId=${clientId}&taskId=${taskId}&jobId=${jobId}`
    );
  }
  getJobFiles(jobid: Guid): Observable<Array<JobFilesModel>> {
    return this.http.get<Array<JobFilesModel>>(
      `${this.apiUrl}${this.routeBase}/getJobFiles/${jobid}`
    );
  }

  getFeesType(
    clientId: Guid,
    taskId: Guid,
    jobId: Guid
  ): Observable<Array<FeesTypeModel>> {
    return this.http.get<Array<FeesTypeModel>>(
      `${this.apiUrl}${this.routeBase}/getFeesType?clientId=${clientId}&taskId=${taskId}&jobId=${jobId}`
    );
  }

  saveJob(jobModel: JobModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveJob`,
      JSON.stringify(jobModel),
      headers
    );
  }

  saveJobFiles(
    jobId: Guid,
    jobFilesModel: Array<JobFilesModel>
  ): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.put<boolean>(
      `${this.apiUrl}${this.routeBase}/saveJobFiles/${jobId}`,
      JSON.stringify(jobFilesModel),
      headers
    );
  }

  getJobDetailByUniversalId(jobId: Guid): Observable<JobDetailModel> {
    return this.http.get<JobDetailModel>(
      `${this.apiUrl}${this.routeBase}/getJobDetailByUniversalId/${jobId}`
    );
  }

  getJobList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Jobs/jobList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  getJobSubTaskByUniversalId(jobId: Guid): Observable<Array<JobSubTaskModel>> {
    return this.http.get<Array<JobSubTaskModel>>(
      `${this.apiUrl}${this.routeBase}/getJobSubTaskByUniversalId/${jobId}`
    );
  }

  getAssignedUsersByJobId(jobId: Guid): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getAssignedUsersByJobId/${jobId}`
    );
  }

  getJobSummaryByUniversalId(jobId: Guid): Observable<JobSummaryModel> {
    return this.http.get<JobSummaryModel>(
      `${this.apiUrl}${this.routeBase}/getJobSummaryByUniversalId/${jobId}`
    );
  }

  getJobDetailList(jobQueryParams: JobQueryParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeJobList`,
      JSON.stringify(jobQueryParams),
      headers
    );
  }

  getJobSubTaskDetailList(jobQueryParams: JobQueryParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeJobSubTaskList`,
      JSON.stringify(jobQueryParams),
      headers
    );
  }

  getJobSubTaskUserDetailList(jobQueryParams: JobQueryParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeJobSubTaskUserList`,
      JSON.stringify(jobQueryParams),
      headers
    );
  }

  getJobSubTaskAndUsersDetails(
    groupByParams: JobGroupByTimelogParams
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getJobSubTaskAndUsers`,
      JSON.stringify(groupByParams),
      headers
    );
  }

  getJobTimelogChart(jobId: Guid): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getJobTimelogChart/${jobId}`
    );
  }

  exportJob(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportJobs`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getJobByClientId(universalId: Guid): Observable<Array<JobTaskModel>> {
    return this.http.get<Array<JobTaskModel>>(
      `${this.apiUrl}${this.routeBase}/getJobByClientId/${universalId}`
    );
  }

  getJobSubTaskByJobId(jobId: Guid): Observable<Array<JobSubTaskModel>> {
    return this.http.get<Array<JobSubTaskModel>>(
      `${this.apiUrl}${this.routeBase}/getJobSubTaskByJobId/${jobId}`
    );
  }

  getJobInvoicesByJobId(
    jobId: Guid,
    queryParams: InvoiceParameters
  ): Observable<any> {
    return this.http.get<Array<JobInvoiceModel>>(
      `${this.apiUrl}${this.routeBase}/getJobInvoicesByJobId/${jobId}?&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`
    );
  }

  deleteJobByScheduleId(ids?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ids),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteJobByScheduleId`,
      options
    );
  }

  saveBulkJob(bulkJobModel: BulkJobModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveBulkJob`,
      JSON.stringify(bulkJobModel),
      headers
    );
  }
}
