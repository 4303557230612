import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  GlobalComponent,
  UserWeekTimelogOverviewModel,
} from '@app/core/models';
import { HighlightRow } from '@app/core/services';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexTooltip,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable, Subscription } from 'rxjs';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  fill: ApexFill;
};

@Component({
  selector: 'app-timelog-overview-jobchart',
  templateUrl: './timelog-overview-jobchart.component.html',
  styleUrls: ['./timelog-overview-jobchart.component.scss'],
})
export class TimelogOverviewJobchartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  chartColor = this.globalComponent.themecolor();
  timelogOverviewData = new UserWeekTimelogOverviewModel();
  timelogJobSubscription: Subscription;

  @Input() triggerTimelogOverviewData: Observable<any>;

  constructor(
    public globalComponent: GlobalComponent,
    private highlightRow: HighlightRow
  ) {
    this.highlightRow.defaultColor.subscribe((x) => {
      this.chartColor = x;
      this.chartData();
    });
  }

  ngOnInit(): void {
    this.timelogJobSubscription = this.triggerTimelogOverviewData?.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.timelogOverviewData = data;
          this.chartData();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.timelogJobSubscription?.unsubscribe();
  }

  chartData(): void {
    this.chartOptions = {
      series: this.timelogOverviewData.jobs?.series ?? [],
      chart: {
        type: 'bar',
        height: 220,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
        markers: {
          fillColors: ['#7B76D3', '#f2816f'],
        },
      },
      fill: {
        colors: ['#7B76D3', '#f2816f'],
      },
      plotOptions: {
        bar: {
          columnWidth: '30%',
        },
      },
      tooltip: {
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          const tooltipContent = document.createElement('div');
          tooltipContent.classList.add('custom-tooltip', 'timelog-tooltip');

          const tooltipDetailItem = document.createElement('div');
          tooltipDetailItem.classList.add('tooltip-detail', 'pl-5');
          tooltipDetailItem.innerHTML =
            `<div class="d-flex gap-5"><span class="dot"></span>` +
            w.config.series[seriesIndex].name;
          tooltipContent.appendChild(tooltipDetailItem);

          w.config.series.forEach((element, index) => {
            if (index === seriesIndex) {
              if (element.subTask[dataPointIndex].indexOf('|') > 0) {
                let splitArray = element.subTask[dataPointIndex].split('|');
                splitArray.forEach((ele) => {
                  const tooltipSubtaskItem = document.createElement('div');
                  tooltipSubtaskItem.classList.add('tooltip-detail', 'pl-5');
                  tooltipSubtaskItem.innerHTML = `<div class="detail-title">${
                    ele.split('-')[0]
                  }: </div><div class="fw-bold">${ele.split('-')[1]}</div>`;
                  tooltipContent.appendChild(tooltipSubtaskItem);
                });
              } else {
                const tooltipSubtaskItem = document.createElement('div');
                tooltipSubtaskItem.classList.add('tooltip-detail', 'pl-5');
                tooltipSubtaskItem.innerHTML = `<div class="detail-title">${
                  element.subTask[dataPointIndex].split('-')[0]
                }: </div><div class="fw-bold">${
                  element.subTask[dataPointIndex].split('-')[1]
                }</div>`;
                tooltipContent.appendChild(tooltipSubtaskItem);
              }
            }
          });

          return tooltipContent.outerHTML;
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: 'top',
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: this.timelogOverviewData.jobs?.categories ?? [],
      },
    };
  }
}
