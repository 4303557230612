import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ActivityLogModel, AuthorisedMenuModel } from '../../models';
import { ActivityService } from '../../services';
import {
  GetActivityList,
  GetAuthorizedMenus,
  GetJobUpdates,
} from './activity.action';

export class ActivityStateInfo {
  activities: Array<ActivityLogModel>;
  jobUpdateList: Array<ActivityLogModel>;
  entityId?: number;
  authorisedMenu?: Array<AuthorisedMenuModel>;
  isLastPage?: boolean;
}

@State<ActivityStateInfo>({
  name: 'activity',
  defaults: {
    activities: [],
    jobUpdateList: [],
    entityId: 0,
    authorisedMenu: [],
  },
})
@Injectable()
export class ActivityState {
  constructor(private activityService: ActivityService) {}

  @Action(GetActivityList)
  getActivityTasksList(
    { getState, setState }: StateContext<ActivityStateInfo>,
    action: GetActivityList
  ) {
    return this.activityService.getActivityList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();

        setState({
          ...state,
          activities: res,
        });
      })
    );
  }

  @Action(GetJobUpdates)
  getJobUpdates(
    { getState, setState }: StateContext<ActivityStateInfo>,
    action: GetJobUpdates
  ) {
    return this.activityService.getJobUpdates(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          jobUpdateList: res,
        });
      })
    );
  }

  @Action(GetAuthorizedMenus)
  getAuthorizedMenus(
    { patchState }: StateContext<ActivityStateInfo>,
    action: GetAuthorizedMenus
  ) {
    return this.activityService.getAuthorizedMenus().pipe(
      tap((res) => {
        patchState({
          authorisedMenu: res,
        });
      })
    );
  }
}
