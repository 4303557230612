export enum ConfirmationType {
  Archive = 1,
  Delete = 2,
  RemindToSubmit = 3,
  ResubmitForApproval = 4,
  Withdraw = 5,
  MarkAsDefault = 6,
  Mail = 7,
  Demo = 8,
  StripePayment = 9,
  StopTimeSheet = 10,
  RenameColumn = 11,
  recordPaymentDelete = 12,
  EndSchedule = 13,
  EndRecurringInvoice = 14,
  SaveFeeType = 15,
  ApplyMinimumChargeableTime = 16,
}
