<div class="cust-height" *ngIf="data?.length">
  <ejs-chart
    class="fees-dash-height"
    style="display: block"
    [chartArea]="chartArea"
    [width]="width"
    align="center"
    id="clientbalance"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    (load)="load($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="data"
        fill="#7CB5EC"
        type="Bar"
        xName="x"
        yName="y"
        name="GDP"
        [marker]="marker"
        columnSpacing="0.1"
        tooltipMappingName="tooltipMappingName"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<div class="norecord-chart" *ngIf="!data?.length">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
