import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InvoiceOverviewRequestModel } from '@app/core/models';

@Injectable({
  providedIn: 'root',
})
export class OverviewService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Overview';

  constructor(private http: HttpClient) {}

  getInvoiceOverviewCardData(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/GetInvoiceOverviewCardData?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getInvoiceAmountByInvoiceMonth(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getInvoiceAmountByInvoiceMonth?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getEstimateCountByStatus(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getEstimateCountByStatus?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getInvoiceBalanceAmountByDueMonth(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getInvoiceBalanceAmountByDueMonth?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getInvoiceAmountByCategory(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getInvoiceAmountByCategory?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getClientByInvoiceAmount(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getClientByInvoiceAmount?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getClientBalanceByInvoiceId(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getClientBalanceByInvoiceId?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getInvoiceIncomeTrend(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getInvoiceIncomeTrend?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getPaymentAmountByPaymentMethod(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getPaymentAmountByPaymentMethod?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getRevenueFlow(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getRevenueFlow?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }

  getInvoiceCountByStatus(
    invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getInvoiceCountByStatus?dateFilter=${invoiceOverviewRequestModel.dateFilter}&startDate=${invoiceOverviewRequestModel.startDate}&endDate=${invoiceOverviewRequestModel.endDate}`
    );
  }
}
