import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLicense } from '@syncfusion/ej2-base';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

fetch(
  `${environment.apiVersionUrl}/Business/getAzureKey?keyName=SyncfusionLicenseKey`
).then(function (response) {
  response.json().then(function (res) {
    if (res.isSuccess) {
      registerLicense(res.data);
    }
  });
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
