import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { Guid } from 'guid-typescript';
import { switchMap } from 'rxjs/operators';
import {
  ExportParams1,
  FileImportRequestModel,
  QueryParams,
  SideListModel,
  SubTaskModel,
  TaskGridModel,
  TaskModel,
  TaskModelByClient,
  TaskProjectModel,
  TaskUserClientModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Task';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getTaskOverview(
    taskId: Guid,
    monthNumber: number
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getTaskOverview/${taskId}/${monthNumber}`
    );
  }

  getTaskUsersDetails(
    queryParams: any
  ): Observable<HttpResponse<Array<TaskUserClientModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<Array<TaskUserClientModel>>(
      `${this.apiUrl}${this.routeBase}/getTaskUsers`,
      queryParams,
      httpOptions
    );
  }

  getTaskClientsDetails(
    queryParams: any
  ): Observable<HttpResponse<Array<TaskUserClientModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<Array<TaskUserClientModel>>(
      `${this.apiUrl}${this.routeBase}/getTaskClient`,
      queryParams,
      httpOptions
    );
  }

  getTask(queryParams: QueryParams): Observable<HttpResponse<TaskGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeTaskList`,
      queryParams,
      httpOptions
    );
  }

  saveTask(task: TaskModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveTask`,
      JSON.stringify(task),
      headers
    );
  }

  saveTaskUsers(
    taskId: Guid,
    task: Array<TaskUserClientModel>
  ): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/saveTaskUsers/${taskId}`,
      JSON.stringify(task),
      headers
    );
  }

  saveTaskClients(
    taskId: Guid,
    task: Array<TaskUserClientModel>
  ): Observable<boolean> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/saveTaskClients/${taskId}`,
      JSON.stringify(task),
      headers
    );
  }

  getTaskDataByTaskId(taskId: Guid): Observable<TaskModel> {
    return this.http.get<TaskModel>(
      `${this.apiUrl}${this.routeBase}/getTaskByUniversalId/${taskId}`
    );
  }

  updateTask(task: TaskModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateTask`,
      JSON.stringify(task),
      headers
    );
  }

  deleteTasks(taskIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(taskIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteTaskByUniversalId`,
      options
    );
  }

  deleteTaskUserByTaskId(taskId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(taskId),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteTaskUserByUniversalId`,
      options
    );
  }

  deleteTaskClientByTaskId(taskId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(taskId),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteTaskClientByUniversalId`,
      options
    );
  }

  exportTask(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportTask`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getTaskList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getAllTasksList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  copy(taskIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/copyTasksByUniversalId`,
      JSON.stringify(taskIds),
      headers
    );
  }

  archiveAndRestoreTask(
    taskIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      universalIds: taskIds,
      status: isArchive,
    };

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateTaskStatus`,
      JSON.stringify(statusModel),
      headers
    );
  }

  getTasksByProjectId(projectId: number): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getAllTaskByProjectId/${projectId}`
    );
  }

  getOtherTasks(): Observable<Array<TaskModel>> {
    return this.http.get<Array<TaskModel>>(
      `${this.apiUrl}${this.routeBase}/otherTask/list`
    );
  }

  updateProjectTask(
    projectId: number,
    taskList: Array<TaskProjectModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}Project/save/projectTask/${projectId}`,
      JSON.stringify(taskList),
      headers
    );
  }

  getAllTasks(): Observable<Array<TaskModel>> {
    return this.http.get<Array<TaskModel>>(
      `${this.apiUrl}${this.routeBase}/getAllTasks`
    );
  }

  importTasks(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/Task/importTask`,
      formData
    );
  }

  getAllSubTaskByTaskId(taskId: Guid): Observable<Array<SubTaskModel>> {
    return this.http.get<Array<SubTaskModel>>(
      `${this.apiUrl}${this.routeBase}/getAllSubTaskByTaskId/${taskId}`
    );
  }

  getTaskByClientId(universalId: Guid): Observable<Array<TaskModelByClient>> {
    return this.http.get<Array<TaskModelByClient>>(
      `${this.apiUrl}${this.routeBase}/getTaskByClientId/${universalId}`
    );
  }
}
