import { Guid } from 'guid-typescript';

export class FileDownloadRequest {
  userId?: Guid;
  fileUrl: string;
}

export class MultiFileDownloadRequest {
  userId?: Guid;
  fileUrls: Array<string>;
  attachmentType?: number;
}
