export enum TicketModules {
  Unknown = 0,
  Bookkeeping = 1,
  Accounts_Production = 2,
  Payroll = 3,
  Corporation_Tax = 4,
  Personal_Tax = 5,
  Accountant = 6,
  PracticeManagement = 7,
  PayrollAdvanced = 8,
  CompanySecretary = 9,
  P11D = 10,
  TrustTax = 11,
  Partnership_Tax = 12,
  BookKeepingMTD = 13,
  Capisign = 18,
  TimeandFee = 35,
  charity = 36,
  Bookkeeping_New = 37,
  Corporation_Tax_New = 38,
  SAMTD = 45,
  ClientPortal = 49,
  Capium365 = 60,
}

export enum TicketStatus {
  Open = 2,
  Pending = 3,
  Resolved = 4,
  Closed = 5,
}
