import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Modules } from '@app/core/enums';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  BusinessModel,
  BusinessTax,
  CompanyModel,
  DuplicateExistModel,
  DynamicColumnDetailModel,
  DynamicColumnModel,
  EmailTemplateModel,
  MenuItem,
  StandardPermission,
  UserSettingWeekModel,
  UserStandardPermission,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Business';

  constructor(private http: HttpClient) {}

  reloadMenus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  getBusinessInvoice(): Observable<BusinessModel> {
    return this.http.get<BusinessModel>(
      `${this.apiUrl}${this.routeBase}/getBusinessInvoice`
    );
  }

  getBusinessEstimate(): Observable<BusinessModel> {
    return this.http.get<BusinessModel>(
      `${this.apiUrl}${this.routeBase}/getBusinessEstimate`
    );
  }

  getSettingsData(): Observable<BusinessModel> {
    return this.http.get<BusinessModel>(
      `${this.apiUrl}${this.routeBase}/getBusinessById`
    );
  }

  getSettingsEmailTemplates(): Observable<Array<EmailTemplateModel>> {
    return this.http.get<Array<EmailTemplateModel>>(
      `${this.apiUrl}${this.routeBase}/getEmailTemplates`
    );
  }

  getDefaultBusinessId(): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}${this.routeBase}/getDefaultBusinessId`
    );
  }

  getCompany(): Observable<CompanyModel> {
    return this.http.get<CompanyModel>(
      `${this.apiUrl}${this.routeBase}/getCompany`
    );
  }

  getStartWeekAndEndWeek(): Observable<UserSettingWeekModel> {
    return this.http.get<UserSettingWeekModel>(
      `${this.apiUrl}${this.routeBase}/getStartWeekAndEndWeek`
    );
  }

  saveBusinessEstimateInfo(businessModel: BusinessModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBusinessEstimateInfo`,
      JSON.stringify(businessModel),
      headers
    );
  }

  saveBusinessInvoiceInfo(businessModel: BusinessModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBusinessInvoiceInfo`,
      JSON.stringify(businessModel),
      headers
    );
  }

  savePreferences(businessModel: BusinessModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/savePreferences`,
      JSON.stringify(businessModel),
      headers
    );
  }

  saveBasicInfo(businessModel: BusinessModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBasicInfo`,
      JSON.stringify(businessModel),
      headers
    );
  }

  saveBusiness(businessModel: BusinessModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBusiness`,
      JSON.stringify(businessModel),
      headers
    );
  }

  prepareGoogleOrOfficeInt(payload): Observable<any> {
    let provider =
      payload.ProviderType == 'google' ? 'prepareGoogle' : 'prepareOffice';
    let url = `${environment.apiVersionUrl}/ProviderAccount/${provider}`;
    return this.http
      .post(url, payload, { responseType: 'text' })
      .pipe(map((body) => body));
  }

  integrateStripe(payload): Observable<any> {
    return this.http
      .post(
        `${environment.apiVersionUrl}/ProviderAccount/prepareStripe`,
        payload,
        {
          responseType: 'text',
        }
      )
      .pipe(map((body) => body));
  }

  integrateCapiumPay(payload): Observable<any> {
    return this.http
      .post(
        `${environment.apiVersionUrl}/ProviderAccount/prepareCapiumPay`,
        payload,
        {
          responseType: 'text',
        }
      )
      .pipe(map((body) => body));
  }

  getUserMailList(providerId): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/ProviderAccount/getAllMail/${providerId}`
    );
  }

  deleteMail(id): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/ProviderAccount/deleteMail/${id}`
    );
  }

  disconnectStripe(id): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Payment/DisconnectPaymentAccount?providerId=${id}`
    );
  }

  defaultMail(payload): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/ProviderAccount/markAsDefaultMail`,
      payload,
      headers
    );
  }

  updateEmailTemplate(
    emailTemplates: Array<EmailTemplateModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/SaveEmailTemplates`,
      emailTemplates,
      headers
    );
  }

  getStripePaymentLink(payload) {
    return this.http.post<any>(
      `${environment.apiVersionUrl}/Payment/GetPaymentLink?invoiceId=${payload.referenceId}`,
      payload
    );
  }

  reauthentcateStripe(payload, universalId) {
    return this.http
      .post(
        `${environment.apiVersionUrl}/ProviderAccount/reAuthenticate/${universalId}`,
        payload,
        {
          responseType: 'text',
        }
      )
      .pipe(map((body) => body));
  }

  getStripeList(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Payment/GetAccount`
    );
  }

  saveDynamicColumns(
    dynamicColumnModel: DynamicColumnModel[]
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBusinessFeesSetting`,
      JSON.stringify(dynamicColumnModel),
      headers
    );
  }

  getBusinessFeesSetting(): Observable<Array<DynamicColumnModel>> {
    return this.http.get<Array<DynamicColumnModel>>(
      `${this.apiUrl}${this.routeBase}/getBusinessFeesSetting`
    );
  }

  saveBusinessFeesSettingByModuleId(
    dynamicColumnDetailModel: DynamicColumnDetailModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/SaveBusinessFeesSettingByModuleId`,
      JSON.stringify(dynamicColumnDetailModel),
      headers
    );
  }

  addRemoveDemoData(isAdd: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/demoData/${isAdd}`,
      headers
    );
  }

  getPermissions(searchText?: string): Observable<Array<StandardPermission>> {
    return this.http.get<Array<StandardPermission>>(
      `${environment.apiVersionUrl}/Menu/permission?search=${searchText}`
    );
  }

  updatePermissions(
    standardPermission: Array<StandardPermission>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Menu/permission`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  getMenus(): Observable<Array<MenuItem>> {
    return this.http.get<Array<MenuItem>>(
      `${environment.apiVersionUrl}/Menu/Menu`
    );
  }

  saveBusinessDynamicColumn(
    moduleId: Modules,
    dynamicColumnModel: any[]
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveBusinessDynamicColumn/${moduleId}`,
      JSON.stringify(dynamicColumnModel),
      headers
    );
  }

  getBusinessTax(): Observable<Array<BusinessTax>> {
    return this.http.get<Array<BusinessTax>>(
      `${this.apiUrl}${this.routeBase}/getBusinessTax`
    );
  }

  getUserBusinessPermission(
    userId: Guid
  ): Observable<Array<StandardPermission>> {
    return this.http.get<Array<StandardPermission>>(
      `${environment.apiVersionUrl}/Menu/getUserBusinessPermission/${userId}`
    );
  }

  saveUserPermission(
    standardPermission: Array<UserStandardPermission>,
    userId: Guid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Menu/saveUserPermission/${userId}`,
      JSON.stringify(standardPermission),
      headers
    );
  }

  updateEmailConfig(isFromCapium: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateEmailConfig/${isFromCapium}`,
      headers
    );
  }

  getClientTypes(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.routeBase}/getClientTypes`);
  }

  getVatScheme(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.routeBase}/getVatScheme`);
  }

  getVatSubmitType(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getVatSubmitType`
    );
  }

  getNextCodeByModuleId(moduleId: number): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );

    return this.http.get(
      `${this.apiUrl}${this.routeBase}/getNextCodeByModuleId?moduleId=${moduleId}`,
      { headers, responseType: 'text' }
    );
  }

  isDuplicateExist(reqBody: DuplicateExistModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/isDuplicateExist`,
      JSON.stringify(reqBody),
      headers
    );
  }
}
