import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  InvoiceAmountByInvoiceMonthDataModel,
  InvoiceOverviewRequestModel,
} from '@app/core/models';
import { GetClientBalanceByInvoiceId } from '@app/core/store';
import { Store } from '@ngxs/store';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-fees-client-balance',
  templateUrl: './fees-client-balance.component.html',
  styleUrls: ['./fees-client-balance.component.scss'],
})
export class FeesClientBalanceComponent {
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  //Initializing Chart Width
  public width: string = '100%';
  public data: Object[] = [];
  //Initializing Marker
  public marker: Object = {
    dataLabel: {
      visible: false,
      position: 'Top',
      font: {
        color: '#ffffff',
      },
    },
  };
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    valueType: 'Category',
    majorGridLines: { width: 0 },
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    labelFormat: '{value}',
    edgeLabelPlacement: 'Shift',
    majorTickLines: { width: 0 },
    lineStyle: { width: 0 },
  };
  public tooltip: Object = {
    enable: true,
    format: '<span>${point.tooltip}</span>',
    header: '',
  };
  public legend: Object = {
    visible: false,
    enableHighlight: true,
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }

  filter = 1;
  start: string = '';
  end: string = '';
  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  overview: any;
  clientBalanceByInvoiceId: InvoiceAmountByInvoiceMonthDataModel;
  @Input()
  triggerFilterData: any;

  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getClientBalanceByInvoiceId();
    });
  }

  getClientBalanceByInvoiceId(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(
        new GetClientBalanceByInvoiceId(this.invoiceOverviewRequestModel)
      )
      .pipe()
      .subscribe(
        (res) => {
          this.overview = res.overview.clientBalanceByInvoiceId;
          if (this.overview) {
            this.data = this.overview;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.data = [];
        }
      );
  }
}
