import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Modules } from '@app/core/enums';
import { ActivityLogModel, ActivityTaskParameters } from '@app/core/models';
import { CustomLoaderComponent } from '@app/core/shared/components';
import { Observable, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-job-details-activity',
  templateUrl: './job-details-activity.component.html',
  styleUrls: ['./job-details-activity.component.scss'],
})
export class JobDetailsActivityComponent {
  moduleId = Modules.Job;
  loader = new CustomLoaderComponent();
  isShowLoader = false;
  activityTaskParameters: ActivityTaskParameters;
  activityList: any = [];
  start: string = '';
  end: string = '';
  filter = 1;

  @Input()
  triggerJobId: Observable<any>;

  triggerActivityDetails: Subject<any> = new Subject<any>();
  activities: Array<ActivityLogModel>;
  jobActvitySubscription: Subscription;
  @Output()
  readonly triggerLoadTimeLineData = new EventEmitter<any>();
  @Output()
  readonly triggerActivityCountToTab = new EventEmitter<any>();
  constructor(public dialog: MatDialog, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.jobActvitySubscription = this.triggerJobId.subscribe((data) => {
      this.triggerLoadTimeLineData.next();
    });
  }

  ngAfterViewInit(): void {
    this.triggerLoadTimeLineData.next();
  }

  triggerActivityCount(value): void {
    this.triggerActivityCountToTab.emit(value);
  }
}
