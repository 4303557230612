<div class="d-flex align-items-center justify-space-between mb-1">
  <p class="font-size-16 text-primary fw-bold mb-0">Subtasks & Users</p>
  <ng-select
    class="w-120"
    panelClass="myPanelClass"
    [disableOptionCentering]="true"
    #groupBy
    (focus)="groupBy.open()"
    (valueChange)="groupBy.close()"
    [(ngModel)]="selectedGroupById"
    (change)="onGroupBySelectionChange($event)"
    *ngIf="!isFromJobList"
  >
    <ng-option *ngFor="let tgb of timelogGroupByList" [value]="tgb.value">
      {{ tgb.name }}
    </ng-option>
  </ng-select>
</div>
<div class="sidenav-table sidenav-table-input">
  <div class="mat-table-wrapper contact-detail-table">
    <table
      class="table-layout-fixed"
      mat-table
      [dataSource]="dataSource"
      aria-describedby="Timelog group by Table"
      matSort
      (matSortChange)="sorting(sort.active, sort.direction)"
    >
      <ng-container matColumnDef="groupByName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-270">
          <button
            mat-button
            (click)="$event.stopPropagation()"
            (click)="toggleAllClick()"
          >
            <mat-icon class="mat-icon-rtl-mirror pr-5">
              {{ toggleAll ? "keyboard_arrow_down" : "keyboard_arrow_right" }}
            </mat-icon>
          </button>
          <span
            *ngIf="selectedGroupById === TimelogGroupByList.BySubtask"
            [style.paddingLeft.px]="0"
          >
            Subtask
          </span>
          <span
            *ngIf="selectedGroupById === TimelogGroupByList.ByUser"
            [style.paddingLeft.px]="0"
          >
            User(Billable Rate/Cost Rate)
          </span>
        </th>
        <td mat-cell *matCellDef="let data">
          <div class="d-flex align-items-center">
            <button
              mat-button
              [style.visibility]="!data.expandable ? 'hidden' : ''"
              [style.marginLeft.px]="data.level * 32"
              (click)="treeControl.toggle(data)"
            >
              <mat-icon class="mat-icon-rtl-mirror pr-5">
                {{
                  treeControl.isExpanded(data)
                    ? "keyboard_arrow_down"
                    : "keyboard_arrow_right"
                }}
              </mat-icon>
            </button>
            <img
              src="assets/images/billable.svg"
              width="15px"
              alt="Billable"
              [style.visibility]="!data.expandable ? 'hidden' : ''"
            />
            <span
              class="onelineclamp"
              title="{{ data.childGroupByName }}"
              *ngIf="data.childGroupByName; else ShowRange"
              >{{ data.childGroupByName }}</span
            >
            <ng-template #ShowRange>
              <span
                class="onelineclamp ml-10"
                title="{{ data.groupByName }}"
                *ngIf="selectedGroupById === TimelogGroupByList.BySubtask"
              >
                {{ data.groupByName }}
              </span>
              <span
                class="onelineclamp ml-10"
                title="{{ data.groupByName }}"
                *ngIf="selectedGroupById === TimelogGroupByList.ByUser"
              >
                {{
                  data.groupByName +
                    "(" +
                    data.userBillableRate +
                    "/" +
                    data.userCostRate +
                    ")"
                }}
              </span></ng-template
            >
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="timeSpent">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Time Spent</th>
        <td mat-cell *matCellDef="let data">
          {{ data.timeSpent }}
        </td>
      </ng-container>
      <ng-container matColumnDef="amount">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Amount
          <mat-icon
            fontSet="material-icons-outlined"
            class="cst-info"
            [matTooltip]="setAmountText()"
            >info</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let data">
          £ {{ data.amount | number : "1.2-2" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="billable">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Billable
          <mat-icon
            fontSet="material-icons-outlined"
            class="cst-info"
            matTooltip="User Billable Rate * Total Billable Hours"
            >info</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let data">
          £ {{ data.userBillableAmount | number : "1.2-2" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="cost">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Cost
          <mat-icon
            fontSet="material-icons-outlined"
            class="cst-info"
            matTooltip="User Cost Rate * Total Logged Hours"
            >info</mat-icon
          >
        </th>
        <td mat-cell *matCellDef="let data">
          £ {{ data.cost | number : "1.2-2" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell"
          [ngClass]="'text-align-center'"
          [attr.colspan]="displayedColumns.length"
        >
          {{ commonNotificationText.NoRecordsFound }}
        </td>
      </tr>
    </table>
  </div>
</div>
