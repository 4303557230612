import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  ExpenseList,
  ExpenseTaskUserModel,
  ExpenseUnSubmittedList,
  Expenses,
  ExpensesSummary,
  GlobalComponent,
  SideListModel,
} from '../../models';
import { ExpensesService } from '../../services';
import {
  ApproveExpense,
  DeleteSelectedExpense,
  GetExpenseByClientId,
  GetExpenseDetailList,
  GetExpenseList,
  GetExpenseNextNumber,
  GetExpenseUnSubmittedList,
  GetExpensesById,
  GetNonAdminExpenseDetailList,
  GetUserExpenseByModuleId,
  ImportExpense,
  SaveExpense,
  SendExpenseEmail,
  SubmitExpense,
  UpdateExpenseStatus,
  WithdrawExpense,
} from './expense.action';

export class ExpensesInfo {
  expenseList?: Array<Expenses>;
  expenseNextNumber?: string;
  expenseId?: string;
  expenseData?: Expenses;
  userExpenseData?: Expenses;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  expensesSummary?: ExpensesSummary;
  totalRecord?: number;
  isLastPage?: boolean;
  expenseListForNonAdmin?: Array<ExpenseList>;
  expenseUnSubmittedList?: Array<ExpenseUnSubmittedList>;
  importData?: any;
  isDataAvailable?: boolean;
  expenseTaskUserList?: Array<ExpenseTaskUserModel>;
  expenseNote?: any;
  isDataSaved?: boolean;
}

@State<ExpensesInfo>({
  name: 'expense',
  defaults: {
    expenseList: [],
    expenseId: '',
    expenseNextNumber: '',
  },
})
@Injectable()
export class ExpenseState {
  defaultUniversalId = this.globalComponent.getDefaultUniversalId();
  constructor(
    private expenseService: ExpensesService,
    public globalComponent: GlobalComponent
  ) {}

  @Selector()
  static getExpenseId(state: ExpensesInfo): any {
    return state.expenseId;
  }

  @Selector()
  static getExpenseData(state: ExpensesInfo): Expenses | undefined {
    return state.expenseData;
  }

  @Selector()
  static totalRecord(state: ExpensesInfo): number | undefined {
    return state.totalRecord;
  }

  @Selector()
  static isLastPage(state: ExpensesInfo): boolean | undefined {
    return state.isLastPage;
  }

  @Selector()
  static getExpenseList(state: ExpensesInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getNonAdminExpenseDetails(state: ExpensesInfo) {
    return state.expenseListForNonAdmin;
  }

  @Selector()
  static getImportData(state: ExpensesInfo) {
    return state.importData;
  }

  @Selector()
  static isDataAvailable(state: ExpensesInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static getAllExpenseList(state: ExpensesInfo) {
    return state.expenseList;
  }

  @Selector()
  static getUserExpenseData(state: ExpensesInfo): Expenses | undefined {
    return state.userExpenseData;
  }

  @Action(GetExpenseDetailList, { cancelUncompleted: true })
  getExpenseDetailList(
    { getState, setState }: StateContext<ExpensesInfo>,
    action: GetExpenseDetailList
  ) {
    return this.expenseService.getExpenseDetailList(action.expenseParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;
        setState({
          ...state,
          expenseList: res.body!,
          totalRecord,
          isDataAvailable,
        });
      })
    );
  }

  @Action(GetExpenseUnSubmittedList)
  getExpenseUnSubmittedList(
    { patchState }: StateContext<ExpensesInfo>,
    action: GetExpenseUnSubmittedList
  ): any {
    return this.expenseService
      .getExpenseUnSubmittedList(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            expenseUnSubmittedList: res,
          });
        })
      );
  }

  @Action(GetExpenseList, { cancelUncompleted: true })
  getExpenseList(
    { getState, patchState }: StateContext<ExpensesInfo>,
    action: GetExpenseList
  ) {
    return this.expenseService.getExpenseList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let expenseData: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          expenseData = state.sideListModel!;
          expenseData = expenseData.concat(res.body!);
        } else {
          expenseData = res.body;
        }

        patchState({
          sideListModel: expenseData!,
          isLastPage,
        });
      })
    );
  }

  @Action(SaveExpense)
  saveExpenses(
    { patchState }: StateContext<ExpensesInfo>,
    action: SaveExpense
  ): any {
    return this.expenseService.saveExpense(action.expenses).pipe(
      tap((res) => {
        patchState({
          expenseId: res.data,
          isDataSaved: res.isSuccess,
        });
      })
    );
  }

  @Action(GetExpensesById)
  getExpensesById(
    { patchState }: StateContext<ExpensesInfo>,
    action: GetExpensesById
  ): any {
    return this.expenseService.getExpensesById(action.detailId).pipe(
      tap((res) => {
        patchState({
          expenseData: res,
        });
      })
    );
  }

  @Action(DeleteSelectedExpense)
  deleteSelectedExpenses(
    { setState }: StateContext<ExpensesInfo>,
    action: DeleteSelectedExpense
  ): any {
    return this.expenseService.deleteExpenses(action.ExpenseIds).pipe(
      tap(() => {
        const filteredForSideList = [];
        setState({
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(UpdateExpenseStatus)
  updateExpenseStatus(
    expense: StateContext<ExpensesInfo>,
    action: UpdateExpenseStatus
  ) {
    return this.expenseService.updateExpenseStatus(
      action.expenseRequestModelData
    );
  }

  @Action(WithdrawExpense)
  withdrawTimelog(
    expense: StateContext<ExpensesInfo>,
    action: WithdrawExpense
  ) {
    return this.expenseService.withdrawExpense(action.expenseRequestModelData);
  }

  @Action(ApproveExpense)
  approveExpense(expense: StateContext<ExpensesInfo>, action: ApproveExpense) {
    return this.expenseService.approveExpense(action.expenseRequestModelData);
  }

  @Action(SubmitExpense)
  submitExpense(expense: StateContext<ExpensesInfo>, action: SubmitExpense) {
    return this.expenseService.submitExpense(action.submitExpenseModel);
  }

  @Action(ImportExpense)
  importExpense(
    { patchState }: StateContext<ExpensesInfo>,
    action: ImportExpense
  ) {
    return this.expenseService
      .importExpense(action.fileImportRequestModel)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(GetExpenseNextNumber)
  getExpenseNextNumber(
    { patchState }: StateContext<ExpensesInfo>,
    action: GetExpenseNextNumber
  ): any {
    return this.expenseService.getExpenseNextNumber().pipe(
      tap((res) => {
        patchState({ expenseNextNumber: res });
      })
    );
  }

  @Action(SendExpenseEmail)
  sendEmail(expense: StateContext<ExpensesInfo>, action: SendExpenseEmail) {
    return this.expenseService.sendEmail(action.sendEmailExpenseModel);
  }

  @Action(GetNonAdminExpenseDetailList)
  getNonAdminExpenseDetailList(
    { getState, patchState }: StateContext<ExpensesInfo>,
    action: GetNonAdminExpenseDetailList
  ): any {
    return this.expenseService
      .getNonAdminExpenseDetailList(action.queryParams)
      .pipe(
        tap((res) => {
          patchState({
            expenseListForNonAdmin: res,
          });
        })
      );
  }

  @Action(GetUserExpenseByModuleId)
  getUserExpenseByModuleId(
    { patchState }: StateContext<ExpensesInfo>,
    action: GetUserExpenseByModuleId
  ): any {
    return this.expenseService
      .getUserExpenseByModuleId(action.userId, action.userExpenseParameters)
      .pipe(
        tap((res) => {
          patchState({
            userExpenseData: res,
          });
        })
      );
  }

  @Action(GetExpenseByClientId)
  getExpenseByClientId(
    { patchState }: StateContext<ExpensesInfo>,
    action: GetExpenseByClientId
  ) {
    return this.expenseService.getExpenseByClientId(action.universalId).pipe(
      tap((res) => {
        patchState({
          expenseTaskUserList: res,
        });
      })
    );
  }
}
