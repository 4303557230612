import { Guid } from 'guid-typescript';
import { AppliedTaxModel } from '../tax';

export class RecurringInvoiceBasicInfo {
  universalId?: Guid;
  clientId: Guid;
  userId: Guid;
  invoiceNo: string;
  isActive?: boolean;
  businessId?: string;
  invoiceDate: string;
  dueDate?: string;
  totalAmount: number;
  paidAmount: number;
  reference?: string;
  externalEstimateId: string;
  invoiceType?: number;
  isDeleted?: boolean;
  isSelected?: boolean;
  status: number;
  isEmailSendFromCapium?: boolean;
  isSendAutomatically?: boolean;
  isRecurring: boolean;
  footer: string;
  recurringInvoiceDetails: Array<RecurringInvoiceDetail>;
  isSaveAndSend: boolean;
  schedule?: string;
  sentOn?: Date;
  clientFullName?: string;
  discountName?: string;
  discountAmount?: number;
  isDiscountInPercentage?: boolean;
  costName?: string;
  costAmount?: number;
  isCostInPercentage?: boolean;
  paymentDueTermsId?: number;
  isDemoData?: boolean;
  createdBy?: string;
  updatedBy?: string;
  updatedOn?: Date;
  client?: any;
  createdOn?: Date;
  recurringInvoiceTaxes: Array<AppliedTaxModel>;
}

export class RecurringInvoiceDetail {
  universalId?: string;
  recurringInvoiceId: string;
  typeId: number;
  itemId?: string;
  itemName: string;
  subItemId?: string;
  subItemName: string;
  totalQuantity?: number;
  price?: number;
  amount?: number;
}
