import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AttachmentType,
  CommonNotificationText,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  PermissionType,
} from '@app/core/enums';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  JobFilesModel,
  JobModel,
  MainListParameters,
} from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { GetJobFiles, JobState, SaveJobFiles } from '@app/core/store/job';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-job-details-files',
  templateUrl: './job-details-files.component.html',
  styleUrls: ['./job-details-files.component.scss'],
})
export class JobDetailsFilesComponent implements OnInit {
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  displayedColumns: string[] = [
    'fileName',
    'createdOn',
    'createdBy',
    'download',
  ];
  selectedFiles: FileList;
  fileList: any[] = [];
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  ids?: Array<Guid>;
  jobData: JobModel;
  filesForm: FormGroup;
  jobList: JobModel[];
  jobFiles: JobFilesModel[] = [];
  isImageSelected = false;
  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly triggerFilesCount = new EventEmitter<any>();

  @Input() triggerJobId: Observable<any>;

  @Input() jobFilesModel: JobFilesModel;
  jobFile: Array<JobFilesModel>;

  listParameters: MainListParameters = new MainListParameters();
  dataSource: any = [];
  jobId = Guid.EMPTY as unknown as Guid;
  jobFilesSubscription: Subscription;
  commonNotificationText = CommonNotificationText;
  permissionType = PermissionType;
  isViewPermission = false;
  moduleId = Modules.JobFiles;

  @Select(JobState.getJobFiles)
  jobFileData$: Observable<Array<JobFilesModel>>;

  constructor(
    private globalComponent: GlobalComponent,
    private store: Store,
    private commonService: CommonService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.triggerJobId.subscribe((data) => {
      this.jobId = data;
      this.onCancel();
      this.getList();
    });

    this.isViewPermission = this.commonService.isViewPermission(this.moduleId);
  }

  setForm(): void {
    this.filesForm = this.formBuilder.group({
      fileName: new FormControl<string | null>(null),
    });
  }

  getList(): void {
    this.store.dispatch(new GetJobFiles(this.jobId)).subscribe((res) => {
      this.dataSource = res.job.jobFiles;
      this.triggerFilesCount.emit(
        res.job.jobFiles ? res.job.jobFiles.length : 0
      );
    });
  }

  setJobFiles(): void {
    if (this.fileUploadResponse !== undefined) {
      this.fileUploadResponse.forEach((x) => {
        this.jobFiles.push({
          fileName: x.fileName,
          fileUrl: x.fileUrl,
        });
      });
    }
  }

  onSave(isExit: boolean): void {
    if (this.filesForm.invalid) {
      this.commonService.addValidation(this.filesForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      this.jobFiles = [];
      if (this.fileUploadResponse.length > 0) {
        this.setJobFiles();
        this.store
          .dispatch(new SaveJobFiles(this.jobId, this.jobFiles))
          .pipe()
          .subscribe(
            (res) => {
              if (res.job.isFilesUploaded) {
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
                this.filesForm.reset();
                this.fileList = [];
                this.fileUploadResponse = [];
                this.getList();
                if (isExit) this.commonService.onListRouting(Modules.Jobs);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  onCancel(): void {
    this.filesForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  saveFile(): void {
    this.fileUploadRequest = {
      userId: this.globalComponent.getLoggedInUserId(),
      file: this.fileList,
      attachmentType: AttachmentType.Project,
    };
    this.commonService.fileUpload(this.fileUploadRequest).subscribe(
      (data) => {
        this.fileUploadResponse = data;
      },
      (error) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    );
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
        ) {
          if (file.size / 1024 / 1024 < 2) {
            this.fileList.push(file);
            this.saveFile();
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.fileSize2mb
            );
          }
        } else {
          this.commonService.onFailure(NotificationTextMessage.fileJpgPng);
        }
      });
    }
  }

  removeFile(index: any): void {
    this.fileList.splice(index, 1);
    this.fileUploadResponse.splice(index, 1);
  }

  downloadFile(url: string): void {
    const params = {
      fileUrl: url,
    };

    this.commonService.downloadFile(params).subscribe();
  }
}
