import { Component, Input, OnInit } from '@angular/core';
import {
  CommonNotificationText,
  FeeType,
  JobStatusModel,
  Modules,
} from '@app/core/enums';

@Component({
  selector: 'app-more-job-data',
  templateUrl: './more-job-data.component.html',
  styleUrls: ['./more-job-data.component.scss'],
})
export class MoreJobDataComponent implements OnInit {
  displayedColumns: string[] = [
    'id',
    'client',
    'task',
    'startDate',
    'endDate',
    'estimatedTime',
    'loggedTime',
  ];
  commonNotificationText = CommonNotificationText;
  headerText = 'Job Details';
  feeType = FeeType;
  jobStatusModel = JobStatusModel;

  @Input() getModuleId: number;

  @Input()
  element: any;

  ngOnInit(): void {
    let index = -1;
    switch (this.getModuleId) {
      case Modules.Clients:
        this.headerText = this.headerText + ' - ' + this.element.fullName;
        index = this.displayedColumns.indexOf('client');
        if (index !== -1) {
          this.displayedColumns.splice(index, 1);
        }
        break;

      case Modules.Tasks:
        this.headerText = this.headerText + ' - ' + this.element.name;
        index = this.displayedColumns.indexOf('task');
        if (index !== -1) {
          this.displayedColumns.splice(index, 1);
        }
        break;

      case Modules.Users:
        this.headerText = this.headerText + ' - ' + this.element.fullName;
        break;

      default:
        break;
    }
  }
}
