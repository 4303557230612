import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardRequestModel } from '@app/core/models';
import { GetTaskWiseHoursDetails } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexResponsive,
  ApexXAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { tap } from 'rxjs/operators';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};

@Component({
  selector: 'app-task-weekly-hours',
  templateUrl: './task-weekly-hours.component.html',
  styleUrls: ['./task-weekly-hours.component.scss'],
})
export class TaskWeeklyHoursComponent implements OnInit {
  public primaryXAxis?: Object;
  public chartData?: Object[];
  public title?: string;
  primaryYAxis: any;
  public tooltip?: Object = {
    enable: true,
    format: '<span>${point.x}</span> : <span>${point.y}h</span>',
  };
  public palette?: string[];
  public legend: Object = {
    visible: false,
    enableHighlight: true,
  };
  @Input()
  triggerFilterData: any;
  @Input()
  triggerTaskIdForHoursDetails: any;
  seriesData: any = [];
  dashboardRequestModel: DashboardRequestModel;
  filter = 1;
  start: string = '';
  end: string = '';
  taskWiseHoursDetails: any;
  taskId = Guid.EMPTY as unknown as Guid;
  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<span>${point.tooltip}</span></span>',
      header: '',
    };
    this.palette = ['#B9DD94', '#93CAF0', '#F1D071', '#C4C24A'];
    this.primaryXAxis = {
      interval: 1,
      valueType: 'Category',
    };

    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getTaskWiseHoursDetails();
    });

    this.triggerTaskIdForHoursDetails.subscribe((res) => {
      if (res) {
        this.taskId = res;
        this.getTaskWiseHoursDetails();
      }
    });
  }
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  getTaskWiseHoursDetails(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(
        new GetTaskWiseHoursDetails(this.dashboardRequestModel, this.taskId)
      )
      .pipe(
        tap(
          (res) => {
            this.taskWiseHoursDetails = res.dashboard.taskWiseHoursDetails;
            if (this.taskWiseHoursDetails) {
              this.seriesData = this.taskWiseHoursDetails.data;
            } else {
              this.seriesData = [];
            }
            window.dispatchEvent(new Event('resize'));
          },
          (error) => {
            this.seriesData = [];
          }
        )
      )
      .subscribe();
  }
}
