export enum RoutingPath {
  clients = 'manage/clients',
  addClient = 'manage/addClient',
  expenseTypes = 'manage/expenseTypes',
  addExpenseType = 'manage/addExpenseType',
  tasks = 'manage/tasks',
  addTask = 'manage/addTask',
  users = 'manage/users',
  addUser = 'manage/addUser',
  expenses = 'expenses/expenseList',
  addExpense = 'expenses/addExpense',
  jobDetails = 'jobs/jobDetails',
  jobs = 'jobs/jobList',
  addJob = 'jobs/addJob',
  calendar = 'jobs/calendarList',
  invoices = 'invoicestype/invoices',
  addInvoice = 'invoicestype/addInvoice',
  estimates = 'invoicestype/estimates',
  addEstimate = 'invoicestype/addEstimate',
  recurringInvoices = 'invoicestype/recurringInvoices',
  addRecurringInvoice = 'invoicestype/addRecurringInvoice',
  accessDenied = 'access-denied',
  timesheet = 'time/timesheet',

  time = 'time',
  teammates = 'manage/teams',
  taxes = 'manage/taxes',
  DetailExpense = 'expenses/detailexpenses',
  AddTax = 'manage/addTaxes',
  AddPaymentDue = 'addPaymentDue',
  Settings = 'settings',
  Profile = 'profile',
  Dashboard = 'dashboard',
  timeReport = 'reportstype/timeReport',
  timeDashboard = 'dashboardstype/timeDashboard',
}
