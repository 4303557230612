<div class="sidenav-body-content pl-0 pr-0">
  <div class="content-header">
    <div class="content-header-input">
      <button
        class="action-button primary-button"
        mat-button
        #button
        disableRipple
        (click)="openDialog()"
      >
        <mat-icon>add</mat-icon>
        <span>Add Client Ticket</span>
      </button>
      <mat-form-field appearance="fill" class="search-field">
        <input matInput placeholder="Search" autocomplete="off" />
        <mat-icon>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="content-right-button">
      <div class="sidenav-content">
        <mat-form-field appearance="fill" class="w-120">
          <mat-select
            #clients
            panelClass="myPanelClass"
            placeholder="All Status"
            [disableOptionCentering]="true"
          >
            <mat-option value="0">Active</mat-option>
            <mat-option value="1">Archived</mat-option>
            <mat-option value="2">All Status</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="content-body">
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="dataSource"
        aria-describedby="User Task Table"
      >
        <ng-container matColumnDef="ticket">
          <th mat-header-cell *matHeaderCellDef class="wp-150">Ticket No.</th>
          <td mat-cell *matCellDef="let element">
            {{ element.ticket }}
          </td>
        </ng-container>
        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef>Subject</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subject }}
          </td>
        </ng-container>
        <ng-container matColumnDef="create">
          <th mat-header-cell *matHeaderCellDef>Created on</th>
          <td mat-cell *matCellDef="let element">
            {{ element.create }}
          </td>
        </ng-container>
        <ng-container matColumnDef="sender">
          <th mat-header-cell *matHeaderCellDef>Sender</th>
          <td mat-cell *matCellDef="let element">
            {{ element.sender }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span class="success">{{ element.status }}</span>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
