import { BarChartDetail } from '../report';

export class ProjectAnalysisReport {
  hoursAndExpenseReport: ProjectHoursAndExpenseReport;
  teammateReport: Array<TeammateUtilizationReport>;
  valueReport: ValueReport;
  profitReport: ProfitReport;
  teamUtilizeHoursOnTasksReport: BarChartDetail;
  teamUtilizeHoursReport: BarChartDetail;
  teamUtilizeHours: BarChartDetail;
}

export class ProjectHoursAndExpenseReport {
  totalTeammates: number;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  totalExpense: number;
  totalBillableAmount: string;
  totalCostRate: string;
  totalBillableExpense: number;
  totalNonBillableExpense: number;
}

export class TeammateUtilizationReport {
  userId: string;
  fullName: string;
  photo: string;
  Roles: Array<UserRole>;
  Permission: Permission;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  totalExpense: number;
  utilization: number;
  rateInService: number;
  costEngagement: number;
  billableRate: number;
  costRate: number;
  StartDate: Date;
  EndDate: Date;
  totalCostRate: number;
  totalCapacity: number;
  totalBillableAmount: number;
}

export class UserRole {
  id: number;
  roleId: number;
  userId: string;
  parentId;
  number;
  isArchived: boolean;
  name: string;
  role: Array<Role>;
  isAssigned: boolean;
  isSelected: boolean;
}

export class Role {
  id: number;
  name: string;
  isArchived: boolean;
  parentId;
  number;
  userRoles: Array<UserRole>;
}

export class Permission {
  id: number;
  name: string;
  displaName: string;
  modulePermissions: Array<ModulePermission>;
}

export class ModulePermission {
  id: number;
  moduleId: number;
  permissionId: number;
  module: Module;
  permission: Permission;
  rolePermission: Array<RolePermission>;
}

export class Module {
  id: number;
  name: string;
  displaName: string;
  modulePermissions: Array<ModulePermission>;
}

export class RolePermission {
  id: number;
  RoleId: number;
  modulePermissionid: number;
  isEnable: boolean;
  modulePermission: ModulePermission;
  role: Role;
}

export class ValueReport {
  earnedValue: number;
  forecastValue: number;
}

export class ProfitReport {
  totalBudget: number;
  budgetSpent: number;
  budgetRemaining: number;
  isHour: boolean;
}
