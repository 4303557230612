import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  EmailTemplateModel,
  EstimateData,
  EstimateEmailModel,
  EstimateHistoryModel,
  Estimates,
  GlobalComponent,
  Note,
  SideListModel,
  TemplateDetail,
} from '../../models';
import { EstimatesService } from '../../services';
import {
  CopyEstimates,
  DeleteSelectedEstimate,
  ExportEstimate,
  ExportEstimateToPDF,
  ExportEstimatesToPDF,
  GetEstimateById,
  GetEstimateDetailList,
  GetEstimateList,
  GetEstimateNoteListById,
  GetEstimateReceiptDetails,
  GetEstimateRecentHistory,
  GetEstimateTemplate,
  SaveEstimateBasicInfo,
  SaveEstimateNotes,
  SendEstimateEmail,
  SetDefaultEstimateId,
  SetEstimateDefaultState,
  SetEstimateId,
  UpdateEstimateStatus,
} from './estimates.action';

export class EstimatesStateInfo {
  sideListModel?: Array<SideListModel>;
  isLastPage?: boolean;
  estimates: Array<EstimateData>;
  totalRecord?: number;
  estimateId?: Guid;
  exported?: boolean;
  statusChanged?: boolean;
  estimateNotes?: Array<Note>;
  estimateData?: Estimates;
  estimateHistoryModel?: EstimateHistoryModel;
  highlightIDs?: Array<string>;
  isSendEmail?: boolean;
  emailTemplateData?: EstimateEmailModel;
  previewData?: any;
  isDataAvailable?: boolean;
  isSuccess?: boolean;
}

@State<EstimatesStateInfo>({
  name: 'estimates',
  defaults: {
    sideListModel: [],
    estimates: [],
    isDataAvailable: true,
    emailTemplateData: new EmailTemplateModel(),
  },
})
@Injectable()
export class EstimatesState {
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  constructor(
    private estimatesService: EstimatesService,
    public globalComponent: GlobalComponent
  ) {}

  @Selector()
  static getEstimateTemplate(state: EstimatesStateInfo) {
    return state.emailTemplateData;
  }

  @Selector()
  static isLastPage(state: EstimatesStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getEstimateList(state: EstimatesStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getEstimates(state: EstimatesStateInfo): any {
    return state.estimates;
  }

  @Selector()
  static totalRecord(state: EstimatesStateInfo): any {
    return state.totalRecord;
  }

  @Selector()
  static getEstimateId(state: EstimatesStateInfo) {
    return state.estimateId;
  }

  @Selector()
  static getEstimateNotes(state: EstimatesStateInfo): any {
    return state.estimateNotes;
  }

  @Selector()
  static getEstimateData(state: EstimatesStateInfo): any {
    return state.estimateData;
  }

  @Selector()
  static getHighlightedIds(state: EstimatesStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getEstimateReceiptDetails(state: EstimatesStateInfo): any {
    return state.previewData;
  }

  @Selector()
  static isDataAvailable(state: EstimatesStateInfo) {
    return state.isDataAvailable;
  }

  @Action(SendEstimateEmail)
  sendEstimateEmail(
    { patchState }: StateContext<EstimatesStateInfo>,
    action: SendEstimateEmail
  ) {
    return this.estimatesService.sendEstimateEmail(action.emailModel).pipe(
      tap((res) => {
        patchState({
          isSendEmail: res.isSuccess,
        });
      })
    );
  }

  @Action(GetEstimateTemplate)
  getEstimateTemplate(
    { patchState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateTemplate
  ) {
    return this.estimatesService
      .getEstimateTemplate(action.estimateId, action.templateId)
      .pipe(
        tap((res: TemplateDetail) => {
          patchState({
            emailTemplateData: res,
          });
        })
      );
  }

  @Action(SetDefaultEstimateId)
  setDefaultInvoiceId({ patchState }: StateContext<EstimatesStateInfo>) {
    patchState({
      estimateId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(GetEstimateRecentHistory)
  getEstimateRecentHistory(
    { patchState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateRecentHistory
  ) {
    return this.estimatesService
      .getEstimateRecentHistory(action.estimateId)
      .pipe(
        tap((res) => {
          patchState({
            estimateHistoryModel: res,
          });
        })
      );
  }

  @Action(GetEstimateList, { cancelUncompleted: true })
  getEstimateList(
    { getState, setState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateList
  ) {
    return this.estimatesService.getEstimateList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let estimatesData: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          estimatesData = state.sideListModel!;
          estimatesData = estimatesData.concat(res.body!);
        } else {
          estimatesData = res.body;
        }

        setState({
          ...state,
          sideListModel: estimatesData!,
          isLastPage,
        });
      })
    );
  }

  @Action(GetEstimateDetailList, { cancelUncompleted: true })
  getEstimateDetailList(
    { getState, setState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateDetailList
  ): any {
    return this.estimatesService
      .getEstimateDetailList(action.estimateParams)
      .pipe(
        tap((res) => {
          const state = getState();
          const headers = JSON.parse(res.headers.get('Pagination')!);

          const totalRecord = headers.TotalItemCount;
          const isDataAvailable = headers.IsBusinessDataFound;
          setState({
            ...state,
            estimates: res.body!,
            totalRecord,
            isDataAvailable,
          });
        })
      );
  }

  @Action(DeleteSelectedEstimate)
  deleteSelectedEstimate(
    { getState, setState }: StateContext<EstimatesStateInfo>,
    action: DeleteSelectedEstimate
  ): any {
    return this.estimatesService.deleteEstimates(action.estimateIds).pipe(
      tap(() => {
        const state = getState();

        const filteredEstimates = state.estimates.filter(
          (item) =>
            !action.estimateIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = [];
        state.sideListModel?.filter(
          (item) =>
            !action.estimateIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.estimates,
          estimates: filteredEstimates,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(UpdateEstimateStatus)
  updateEstimateStatus(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: UpdateEstimateStatus
  ): any {
    return this.estimatesService
      .updateEstimateStatus(action.estimateIds, action.status)
      .pipe(
        switchMap((res) => {
          patchState({ statusChanged: res });

          return EMPTY;
        })
      );
  }

  @Action(SetEstimateId)
  setEstimateId(
    { patchState }: StateContext<EstimatesStateInfo>,
    action: SetEstimateId
  ) {
    patchState({
      estimateId: action.estimateId,
    });
  }

  @Action(CopyEstimates)
  copyEstimate(
    { patchState }: StateContext<EstimatesStateInfo>,
    action: CopyEstimates
  ): any {
    return this.estimatesService.copyEstimates(action.estimateIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(ExportEstimate)
  exportEstimate(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: ExportEstimate
  ) {
    return this.estimatesService.exportEstimate(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(ExportEstimateToPDF)
  exportEstimateToPDF(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: ExportEstimateToPDF
  ): any {
    return this.estimatesService.exportEstimateToPDF(
      action.estimateIds,
      action.exportAction
    );
  }

  @Action(SaveEstimateBasicInfo)
  saveEstimateBasicInfo(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: SaveEstimateBasicInfo
  ) {
    return this.estimatesService
      .saveEstimateBasicInfo(action.estimateBasicInfo)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state.estimates,
            isSuccess: res.isSuccess,
            estimateId: res.data,
          });
        })
      );
  }

  @Action(GetEstimateNoteListById)
  getEstimateNoteListById(
    { getState, setState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateNoteListById
  ): any {
    return this.estimatesService.getEstimateNoteList(action.estimateId).pipe(
      tap((res) => {
        const state = getState();

        setState({
          ...state,
          estimateNotes: res,
        });
      })
    );
  }

  @Action(SaveEstimateNotes)
  saveEstimateNotes(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: SaveEstimateNotes
  ) {
    return this.estimatesService.saveEstimateNotes(action.note);
  }

  @Action(GetEstimateById)
  getEstimateById(
    { getState, setState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateById
  ): any {
    return this.estimatesService.getEstimateById(action.estimateId).pipe(
      tap((res) => {
        const state = getState();

        setState({
          ...state,
          estimateId: action.estimateId,
          estimateData: res,
        });
      })
    );
  }

  @Action(ExportEstimatesToPDF)
  exportEstimatesToPDF(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: ExportEstimatesToPDF
  ): any {
    return this.estimatesService.exportEstimatesToPDF(
      action.estimateIds,
      action.exportAction
    );
  }

  @Action(SetEstimateDefaultState)
  setEstimateDefaultState({ patchState }: StateContext<EstimatesStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(GetEstimateReceiptDetails)
  getEstimateReceiptDetails(
    { getState, patchState }: StateContext<EstimatesStateInfo>,
    action: GetEstimateReceiptDetails
  ): any {
    return this.estimatesService
      .getEstimateReceiptDetails(action.universalId)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state,
            previewData: res,
          });
        })
      );
  }
}
