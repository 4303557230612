import {
  CompanyParameters,
  FileDownloadRequest,
  MultiFileDownloadRequest,
  QueryParams,
} from '@app/core/models';
import { Guid } from 'guid-typescript';
import { Modules } from '../../enums';
import { SaveImportRequestModel } from '../../models/common/import';
import { ExportParams1 } from '../../models/common/query-params';
import { EmailExistModel, NameExistModel } from '../../models/common/title';
import { FileUploadRequestModel } from '../../models/file/file-upload-model';

export class GetSideList {
  static readonly type = '[SIDELIST] Get Side-List  List ';

  constructor(public queryParams: QueryParams, public moduleId: Modules) {}
}

export class GetTitleList {
  static readonly type = '[TITLE] Get Titles';
}

export class GetAllIntegratedMailIds {
  static readonly type = '[EMAILS] Get All Integrated Mails Ids';
}

export class GetCurrencyList {
  static readonly type = '[CURRENCY] Get Currency';
}

export class FileUpload {
  static readonly type = '[FILE] File Upload';

  constructor(public fileUploadRequestModel: FileUploadRequestModel) {}
}

export class UploadFileAttachements {
  static readonly type = '[FILE] multiple File Upload';

  constructor(public fileUploadRequestModel: FileUploadRequestModel) {}
}

export class SendEmail {
  static readonly type = '[EMAIL] Send Email';

  constructor(public id: number, public message: string) {}
}

export class UpdateThemeColor {
  static readonly type = '[COLOR] Update Theme Color';

  constructor(public colorCode: string) {}
}

export class IsDataFound {
  static readonly type = '[ALL MODULES] Is Data Found';

  constructor(public moduleId: number) {}
}

export class IsNameExist {
  static readonly type = '[ALL MODULES] Is Name Exists';

  constructor(public NameExistModel: NameExistModel) {}
}

export class IsEmailExist {
  static readonly type = '[ALL MODULES] Is Email Exists';

  constructor(public EmailExistModel: EmailExistModel) {}
}

export class SaveModuleRequest {
  static readonly type = '[ALL MODULES] Save Module Request';

  constructor(public SaveImportRequestModel: SaveImportRequestModel) {}
}

export class GetCountryList {
  static readonly type = '[COUNTRY] Get Country';
}

export class GetCompanyList {
  static readonly type = '[COMPANY] Get Company';

  constructor(public queryParams: CompanyParameters) {}
}

//-------------
export class Export {
  static readonly type = '[EXPORT] Export ';

  constructor(public queryParams: ExportParams1, public moduleId: Modules) {}
}

export class Copy {
  static readonly type = '[COPY] Copy';

  constructor(public moduleId: number, public ids: Array<Guid>) {}
}

export class DownloadFile {
  static readonly type = '[DOWNLOAD] Download File';

  constructor(public fileDownloadRequest: FileDownloadRequest) {}
}

export class MultipleFileDownload {
  static readonly type = '[DOWNLOAD] Multiple File Download';

  constructor(public multiFileDownloadRequest: MultiFileDownloadRequest) {}
}

export class GetFileAsByte {
  static readonly type = '[FILE] Get File As Byte';

  constructor(public fileUrl: string) {}
}
