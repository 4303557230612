import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from './core/enums';
import {
  AddTimeLogModel,
  GlobalComponent,
  JobSummaryModel,
  TimeSubTaskListModel,
} from './core/models';
import { CommonService } from './core/services';
import { ConfirmationBoxComponent } from './core/shared/components';
import {
  GetSubTasks,
  IsTimerRunning,
  RunningTimerData,
  SaveTimeLog,
  SettingsState,
} from './core/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ui-timenfee';

  showTimerPopup = false;

  defaultGuid = Guid.EMPTY as unknown as Guid;
  subTaskList: TimeSubTaskListModel[];
  selectedSubtaskId = this.defaultGuid;
  selectedSubtaskName = 'N/A';

  startTime: Date;
  endTime: Date;
  currentTime: Date;
  elapsedTime: number;
  interval: any;
  isTimerRunning: boolean;
  isTimerPaused: boolean;
  totalTimeTracked: string;
  timeSpent: string;

  timeLogData: AddTimeLogModel;
  jobData: JobSummaryModel;
  timerSubscription: Subscription;

  minimumChargeableTime?: string;
  applyMinimumChargeableTime = false;

  constructor(
    public store: Store,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private globalComponent: GlobalComponent,
    public dialog: MatDialog,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.resetTimer();
    this.commonService.showTimer$.subscribe((res) => {
      if (res) {
        this.store.dispatch(new RunningTimerData(res.jobData));
        if (this.isTimerRunning) {
          this.dialog
            .open(ConfirmationBoxComponent, {
              data: {
                moduleId: Modules.Time,
                type: ConfirmationType.StopTimeSheet,
                headerText: NotificationHeader.stopTimerConfirmation,
                detailText: NotificationDetails.stopTimerDetailText,
                totalNumberOfRecordSelected: 1,
              },
            })
            .afterClosed()
            .subscribe((result) => {
              if (result) {
                this.onStopTimerClick();
              }
            });
        } else {
          this.showTimerPopup = res.showTimer;
          this.jobData = res.jobData;
          localStorage.setItem('jobData', JSON.stringify(this.jobData));

          this.getSubTasks();
        }
      }
    });

    this.setTimerPopupData();
  }

  getSubTasks(): void {
    this.store
      .dispatch(new GetSubTasks(this.jobData.jobId!, this.defaultGuid))
      .pipe(
        tap((res) => {
          this.subTaskList = res.time.subTaskList;
        })
      )
      .subscribe();
  }

  setTimerPopupData(): void {
    const storedStartTime = localStorage.getItem('startTime');
    const storedPausedTime = localStorage.getItem('pausedTime');
    const data = JSON.parse(localStorage.getItem('jobData')!);
    const selectedSubTask =
      localStorage.getItem('selectedSubTask') ?? this.defaultGuid;

    if (data) {
      this.jobData = data;
      this.store.dispatch(new RunningTimerData(data));
      this.getSubTasks();
    }

    if (selectedSubTask) {
      this.selectedSubtaskId = selectedSubTask as unknown as Guid;

      setTimeout(() => {
        this.onSelectionChange(this.selectedSubtaskId);
      }, 1000);
    }

    if (storedPausedTime) {
      this.elapsedTime = +storedPausedTime;
      this.showTimerPopup = true;
      this.isTimerRunning = true;
      this.isTimerPaused = true;
      this.store.dispatch(new IsTimerRunning(this.isTimerRunning));
    }

    if (storedStartTime && !this.isTimerPaused) {
      this.showTimerPopup = true;
      this.startTime = new Date(storedStartTime);
      this.isTimerRunning = true;
      this.store.dispatch(new IsTimerRunning(this.isTimerRunning));
      this.interval = setInterval(() => {
        this.currentTime = new Date();
        this.elapsedTime =
          this.currentTime.getTime() - this.startTime.getTime();
        this.formatTimeToString();
      }, 1000);
    }
  }

  onSelectionChange(val: any): void {
    const data = this.subTaskList.filter(
      (element) => element.universalId === val
    );
    localStorage.setItem('selectedSubTask', String(val));

    if (data.length > 0) {
      this.selectedSubtaskName = data[0].name;
    } else {
      this.selectedSubtaskName = 'N/A';
    }
  }

  onCloseClick(): void {
    this.showTimerPopup = false;
  }

  // #region Timer Changes
  onStartTimerClick() {
    this.startTime = new Date();
    this.interval = setInterval(() => {
      this.currentTime = new Date();
      this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
      this.formatTimeToString();
    }, 1000);
    localStorage.setItem('startTime', String(this.startTime));
    localStorage.removeItem('pausedTime');
    this.isTimerRunning = true;
    this.store.dispatch(new IsTimerRunning(this.isTimerRunning));
  }

  formatTimeToString() {
    const seconds = Math.floor(this.elapsedTime / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const formattedHours = this.formatTime(hours);
    const formattedMinutes = this.formatTime(minutes % 60);
    const formattedSeconds = this.formatTime(seconds % 60);

    this.totalTimeTracked = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    this.timeSpent = `${formattedHours}:${formattedMinutes}`;

    localStorage.setItem('time', this.totalTimeTracked);
  }

  formatTime(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  onStopTimerClick() {
    this.endTime = new Date();
    clearInterval(this.interval);
    this.isTimerRunning = false;
    this.store.dispatch(new IsTimerRunning(this.isTimerRunning));
    localStorage.clear();
    this.showTimerPopup = false;

    this.addTimeLog();
    this.titleService.setTitle('Time and Fees');
  }

  onPauseTimerClick() {
    clearInterval(this.interval);
    this.isTimerPaused = true;
    localStorage.setItem('pausedTime', String(this.elapsedTime));
  }

  onResumeTimerClick() {
    this.startTime = new Date(Date.now() - this.elapsedTime);
    this.interval = setInterval(() => {
      this.currentTime = new Date();
      this.elapsedTime = this.currentTime.getTime() - this.startTime.getTime();
      this.formatTimeToString();
    }, 1000);
    this.isTimerPaused = false;
    localStorage.setItem('startTime', String(this.startTime));
    localStorage.removeItem('pausedTime');
  }

  resetTimer() {
    this.elapsedTime = 0;
    this.interval = null;
    this.isTimerRunning = false;
    this.isTimerPaused = false;
    this.totalTimeTracked = '00:00:00';
    this.timeSpent = '00:00';
    this.jobData = new JobSummaryModel();
    this.selectedSubtaskId = this.defaultGuid;
    this.selectedSubtaskName = 'N/A';
    this.store.dispatch(new IsTimerRunning(this.isTimerRunning));
  }

  dataSubmit(): boolean {
    try {
      this.timeSpent = this.timeSpent === '00:00' ? '00:01' : this.timeSpent;
      this.timeLogData = {
        universalId: this.jobData.universalId,
        jobId: this.jobData.jobId,
        clientId: this.jobData.clientId,
        taskId: this.jobData.taskId,
        jobSubTaskId: this.selectedSubtaskId,
        userId: this.globalComponent.getLoggedInUserId(),
        date: this.datePipe.transform(new Date(), 'yyyy-MM-dd')?.toString(),
        description: '',
        taskName: this.jobData.taskName,
        startTime:
          this.datePipe.transform(this.startTime, 'HH:mm')?.toString() ??
          '00:00',
        endTime:
          this.datePipe.transform(this.endTime, 'HH:mm')?.toString() ?? '00:00',
        timeSpent: this.timeSpent ?? '00:00',
        isBillable: false,
        isFromTimer: true,
        applyMinimumChargeableTime: this.applyMinimumChargeableTime,
      };
    } catch (error) {
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  addTimeLog() {
    if (this.minimumChargeableTime !== '00:00') {
      this.checkValidMinimumChargeableTime();
    } else {
      this.saveTimelog();
    }
  }

  saveTimelog(): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveTimeLog(this.timeLogData))
        .pipe()
        .subscribe(
          (res) => {
            if (res.time.timeLogAddData.isSuccess) {
              this.resetTimer();
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              this.commonService.reloadTimesheetPage(true);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        );
    }
  }

  checkValidMinimumChargeableTime(): any {
    this.minimumChargeableTime = this.store.selectSnapshot(
      SettingsState.getSettingData
    ).minimumChargeableTime;
    const minimumChargeableMinutes =
      this.minimumChargeableTime?.split(':')[1] ?? '00';
    this.timeSpent = this.timeSpent === '00:00' ? '00:01' : this.timeSpent;
    const currentMinutes = this.timeSpent?.split(':')[1] ?? '00';

    const acceptableRanges: Array<[number, number]> = [];

    for (let i = 1; i <= 59; i += +minimumChargeableMinutes) {
      acceptableRanges.push([i, i + (+minimumChargeableMinutes - 2)]);
    }

    const isInUnacceptableRange = acceptableRanges.some(
      ([start, end]) => +currentMinutes >= start && +currentMinutes <= end
    );

    if (currentMinutes !== '00' && isInUnacceptableRange) {
      let detailText: string = NotificationDetails.minimumChargeableTimeMessage;
      let minutes = '00';
      switch (this.minimumChargeableTime) {
        case '00:15':
          minutes = '15';
          break;

        case '00:30':
          minutes = '30';
          break;

        case '00:45':
          minutes = '45';
          break;

        case '00:59':
          minutes = '59';
          break;

        default:
          break;
      }
      detailText = detailText.replace('#time', minutes);

      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            moduleId: Modules.Time,
            type: ConfirmationType.ApplyMinimumChargeableTime,
            headerText:
              NotificationHeader.applyMinimumChargeableTimeConfirmation,
            detailText: detailText,
            totalNumberOfRecordSelected: 1,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          this.applyMinimumChargeableTime = false;
          if (result) {
            this.applyMinimumChargeableTime = true;
          }

          this.saveTimelog();
        });
    } else {
      this.saveTimelog();
    }
  }
  //#endregion
}
