import { Guid } from 'guid-typescript';
import { SortOrdering } from '../../enums';
import { ActionModel } from './grid-action';

export class SideGridModel {
  totalItemCount: number;
  data: Array<SideListModel>;
  actions: Array<ActionModel>;
}

export class SideListModel {
  name: string;
  id: any;
  universalId: Guid;
  isSelected = false;
  url: string;
}

export class HighLightModel {
  sortBy: string = SortOrdering.createdOn;
  isHighlighted = false;
  moduleId?: number;
}

export class FinancialData {
  startDate?: string | null;
  endDate?: string | null;
}

export class ListModel {
  name: string;
  universalId: Guid;
  isAssigned = false;
}

export class SideListGroupModel {
  groupName: string;
  data: Array<TimeSideListModel>;
}

export class TimeSideListModel {
  universalId: Guid;
  name: string;
  isAssigned = false;
}
