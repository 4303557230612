import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { InvoiceOverviewRequestModel } from '@app/core/models';
import { GetInvoiceCountByStatus } from '@app/core/store';
import { Store } from '@ngxs/store';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';

@Component({
  selector: 'app-fees-invoice-status',
  templateUrl: './fees-invoice-status.component.html',
  styleUrls: ['./fees-invoice-status.component.scss'],
})
export class FeesInvoiceStatusComponent implements OnInit {
  public chartArea: Object = {
    border: {
      width: 0,
    },
  };
  //Initializing Chart Width
  public width: string = '100%';
  public pointColorMapping?: string;
  public chartData: Object[] = [];
  //Initializing Marker
  public marker: Object = {
    dataLabel: {
      visible: false,
      position: 'Top',
      font: {
        fontWeight: '600',
        color: '#ffffff',
      },
    },
  };
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    valueType: 'Category',
    majorGridLines: { width: 0 },
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    labelFormat: '£ {value}',
    edgeLabelPlacement: 'Shift',
    majorTickLines: { width: 0 },
    lineStyle: { width: 0 },
  };
  public tooltip: Object = {
    enable: true,
    format: '<span>${point.tooltip}</span> ',
    header: '',
  };
  public legend: Object = {
    visible: false,
    enableHighlight: false,
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }

  overview: any;
  invoiceCountByStatus: any = [];
  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  filter = 1;
  start: string = '';
  end: string = '';
  @Input()
  triggerFilterData: any;
  constructor(private store: Store, public datepipe: DatePipe) {}
  ngOnInit(): void {
    this.pointColorMapping = 'color';
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getInvoiceCountByStatus();
    });
  }

  getInvoiceCountByStatus(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(new GetInvoiceCountByStatus(this.invoiceOverviewRequestModel))
      .pipe()
      .subscribe(
        (res) => {
          this.overview = res.overview.invoiceCountByStatus;
          if (this.overview) {
            const colors = [
              '#F2816F',
              '#BFBBE9',
              '#B9DD94',
              '#F1D071',
              '#7CB5EC',
              '#FFD1A7',
            ];
            for (let i = 0; i < this.overview.length; i++) {
              this.overview[i].color = colors[i];
            }
            this.chartData = this.overview;
          } else {
            this.chartData = [];
          }
        },
        (error) => {
          this.chartData = [];
        }
      );
  }
}
