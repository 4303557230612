import { Injectable } from '@angular/core';
import { ActionModel } from '@app/core/models/common/grid-action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  SideListModel,
  SubTaskModel,
  TaskModel,
  TaskModelByClient,
  TaskUserClientModel,
} from '../../models';
import { TaskService } from '../../services';
import {
  ArchiveAndRestoreTask,
  DeleteSelectedTask,
  DeleteTaskClientByTaskId,
  DeleteTaskUserByTaskId,
  ExportTask,
  GetAllSubTaskByTaskId,
  GetAllTasks,
  GetTaskByClientId,
  GetTaskClientsDetails,
  GetTaskDataByTaskId,
  GetTaskDetailList,
  GetTaskList,
  GetTaskOverview,
  GetTaskUsersDetails,
  ImportTasks,
  SaveTask,
  SaveTaskClients,
  SaveTaskUsers,
  SetDefaultTaskId,
  SetTaskDefaultState,
} from './task.action';

export class TaskStateInfo {
  taskList: Array<TaskModel>;
  taskId?: Guid;
  taskNumber?: Guid;
  taskUsersData?: Array<TaskUserClientModel>;
  taskUsersDataLength: number;
  taskClientsDataLength: number;
  isTaskAssignedToUser?: boolean;
  taskClientsData?: Array<TaskUserClientModel>;
  isTaskAssignedTClient?: boolean;
  allTasks?: Array<TaskModel>;
  isDelete?: boolean;

  taskData?: TaskModel;
  exported?: boolean;
  sideListModel: Array<SideListModel>;
  totalRecord?: number;
  isLastPage?: boolean;
  tasksByProjectId?: Array<SideListModel>;
  highlightIDs?: Array<string>;
  isDataAvailable?: boolean;
  importData?: any;
  subTaskList?: Array<SubTaskModel>;
  taskOverview?: any;
  isSuccess?: boolean;
  taskListByClient?: Array<TaskModelByClient>;
  gridActions?: ActionModel[];
  totalRecordOfTaskClient?: number;
  totalRecordOfTaskUsers?: number;
  statusMessage?: string;
}

@State<TaskStateInfo>({
  name: 'task',
  defaults: {
    taskList: [],
    sideListModel: [],
    isDataAvailable: true,
    taskUsersDataLength: 0,
    taskClientsDataLength: 0,
  },
})
@Injectable()
export class TaskState {
  constructor(private taskService: TaskService) {}

  @Selector()
  static getTaskId(state: TaskStateInfo) {
    return state.taskId;
  }

  @Selector()
  static taskData(state: TaskStateInfo): TaskModel {
    return state.taskData!;
  }

  @Selector()
  static getTaskUsersDetails(state: TaskStateInfo): any {
    return state.taskUsersData ?? [];
  }

  @Selector()
  static gettaskUsersDataLength(state: TaskStateInfo): any {
    return state.taskUsersDataLength;
  }

  @Selector()
  static gettaskClientsDataLength(state: TaskStateInfo): any {
    return state.taskClientsDataLength;
  }

  @Selector()
  static getTaskClientsDetails(state: TaskStateInfo) {
    return state.taskClientsData ?? [];
  }

  @Selector()
  static totalRecord(state: TaskStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static totalRecordOfTaskClient(state: TaskStateInfo) {
    return state.totalRecordOfTaskClient;
  }

  @Selector()
  static totalRecordOfTaskUsers(state: TaskStateInfo) {
    return state.totalRecordOfTaskUsers;
  }

  @Selector()
  static isDataAvailable(state: TaskStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static isLastPage(state: TaskStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getTasksByProjectId(state: TaskStateInfo) {
    return state.tasksByProjectId;
  }

  @Selector()
  static getHighlightedIds(state: TaskStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: TaskStateInfo) {
    return state.importData;
  }

  @Selector()
  static getAllTasks(state: TaskStateInfo) {
    return state.allTasks;
  }

  @Selector()
  static getAllSubTasks(state: TaskStateInfo) {
    return state.subTaskList;
  }

  @Selector()
  static getGridActions(state: TaskStateInfo) {
    return state.gridActions;
  }

  @Action(SetDefaultTaskId)
  setDefaultExpenseTypeId({ patchState }: StateContext<TaskStateInfo>) {
    patchState({
      taskId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(GetTaskOverview, { cancelUncompleted: true })
  getTaskOverview(
    { getState, setState }: StateContext<TaskStateInfo>,
    action: GetTaskOverview
  ) {
    return this.taskService
      .getTaskOverview(action.taskId, action.monthNumber)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            taskOverview: res,
          });
        })
      );
  }

  @Action(GetTaskDetailList, { cancelUncompleted: true })
  getTaskDetailList(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: GetTaskDetailList
  ) {
    return this.taskService.getTask(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;

        patchState({
          taskList: res.body!.data,
          gridActions: res.body!.actions,
          totalRecord,
          isDataAvailable,
          taskData: undefined,
        });
      })
    );
  }

  @Action(SaveTask)
  saveTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: SaveTask
  ) {
    return this.taskService.saveTask(action.task).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          taskId: res.data,
          statusMessage: res.statusMessage,
        });
      })
    );
  }

  @Action(GetTaskDataByTaskId)
  getTaskDataByTaskId(
    { getState, setState }: StateContext<TaskStateInfo>,
    action: GetTaskDataByTaskId
  ) {
    return this.taskService.getTaskDataByTaskId(action.taskId).pipe(
      tap((res) => {
        const state = getState();

        setState({
          ...state,
          taskId: action.taskId,
          taskData: res,
        });
      })
    );
  }

  @Action(DeleteSelectedTask)
  deleteSelectedTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: DeleteSelectedTask
  ) {
    return this.taskService.deleteTasks(action.taskIds).pipe(
      tap(() => {
        const state = getState();

        const filteredTasks = state.taskList.filter(
          (item) =>
            !action.taskIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.taskIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );
        patchState({
          taskList: filteredTasks,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(DeleteTaskUserByTaskId)
  deleteTaskUserByTaskId(
    { patchState, setState }: StateContext<TaskStateInfo>,
    action: DeleteTaskUserByTaskId
  ) {
    return this.taskService.deleteTaskUserByTaskId(action.taskId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(DeleteTaskClientByTaskId)
  deleteTaskClientByTaskId(
    { patchState, setState }: StateContext<TaskStateInfo>,
    action: DeleteTaskClientByTaskId
  ) {
    return this.taskService.deleteTaskClientByTaskId(action.taskId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(ExportTask)
  exportTask(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: ExportTask
  ) {
    return this.taskService.exportTask(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });
        return EMPTY;
      })
    );
  }

  @Action(GetTaskList, { cancelUncompleted: true })
  getTaskList(
    { getState, setState }: StateContext<TaskStateInfo>,
    action: GetTaskList
  ) {
    return this.taskService.getTaskList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let common: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          common = state.sideListModel;
          common = common.concat(res.body!);
        } else {
          common = res.body;
        }

        setState({
          ...state,
          sideListModel: common!,
          isLastPage,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreTask)
  archiveRestoreTask(
    { patchState }: StateContext<TaskStateInfo>,
    action: ArchiveAndRestoreTask
  ) {
    return this.taskService.archiveAndRestoreTask(
      action.taskIds,
      action.isArchive
    );
  }

  @Action(GetAllTasks)
  getAllTask({ patchState }: StateContext<TaskStateInfo>) {
    return this.taskService.getAllTasks().pipe(
      tap((res) => {
        patchState({
          allTasks: res,
        });
      })
    );
  }

  @Action(ImportTasks)
  importTasks(
    { patchState }: StateContext<TaskStateInfo>,
    action: ImportTasks
  ) {
    return this.taskService.importTasks(action.fileImportRequestModel).pipe(
      tap((res) => {
        patchState({
          importData: res,
        });
      })
    );
  }

  @Action(SetTaskDefaultState)
  setTaskDefaultState({ patchState }: StateContext<TaskStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(GetTaskUsersDetails)
  getTaskUsersDetails(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: GetTaskUsersDetails
  ) {
    return this.taskService.getTaskUsersDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfTaskUsers = headers.TotalItemCount;
        patchState({
          totalRecordOfTaskUsers,
          taskUsersData: res.body!,
          taskUsersDataLength: res.body!.length,
        });
      })
    );
  }

  @Action(SaveTaskUsers)
  saveTaskUsers(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: SaveTaskUsers
  ) {
    return this.taskService.saveTaskUsers(action.taskId, action.task).pipe(
      tap((res) => {
        patchState({
          isTaskAssignedToUser: res,
        });
      })
    );
  }

  @Action(GetTaskClientsDetails)
  getTaskClientsDetails(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: GetTaskClientsDetails
  ) {
    return this.taskService.getTaskClientsDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfTaskClient = headers.TotalItemCount;
        patchState({
          totalRecordOfTaskClient,
          taskClientsData: res.body!,
          taskClientsDataLength: res.body!.length,
        });
      })
    );
  }

  @Action(SaveTaskClients)
  saveTaskClients(
    { getState, patchState }: StateContext<TaskStateInfo>,
    action: SaveTaskClients
  ) {
    return this.taskService.saveTaskClients(action.taskId, action.task).pipe(
      tap((res) => {
        patchState({
          isTaskAssignedTClient: res,
        });
      })
    );
  }

  @Action(GetAllSubTaskByTaskId)
  getAllSubTaskByTaskId(
    { patchState }: StateContext<TaskStateInfo>,
    action: GetAllSubTaskByTaskId
  ) {
    return this.taskService.getAllSubTaskByTaskId(action.taskId).pipe(
      tap((res) => {
        patchState({
          subTaskList: res,
        });
      })
    );
  }

  @Action(GetTaskByClientId)
  getTaskByClientId(
    { patchState }: StateContext<TaskStateInfo>,
    action: GetTaskByClientId
  ) {
    return this.taskService.getTaskByClientId(action.universalId).pipe(
      tap((res) => {
        patchState({
          taskListByClient: res,
        });
      })
    );
  }
}
