import { Component, Injector, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AttachmentType,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { TicketModules } from '@app/core/enums/ticket';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  ListModel,
} from '@app/core/models';
import { LetsConnectRequestModel } from '@app/core/models/ticket';
import { CommonService, NotificationService } from '@app/core/services';
import { GetClients, SaveSupportTicket } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss'],
})
export class TicketDialogComponent implements OnInit {
  selectedFile: File[] = [];
  supportTicketForm: FormGroup;
  acceptedFileTypes = ['.gif', '.jpg', '.jpeg', '.png'];
  isImageSelected = false;
  fileList: any[] = [];
  selectedFiles: FileList;
  filesForm: FormGroup;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  ticketModules = TicketModules;
  clientList: ListModel[];
  defaultGuid = Guid.EMPTY as unknown as Guid;
  externalUserGUID = this.cookieService.get(
    'externalUserGUID'
  ) as unknown as Guid;
  name = this.cookieService.get('name');
  email = this.cookieService.get('email');
  supportTicketData: LetsConnectRequestModel;
  commonService: CommonService;
  notifier: NotificationService;
  formBuilder: UntypedFormBuilder;
  store: Store;
  spinner: NgxSpinnerService;

  constructor(
    private globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<TicketDialogComponent>,
    private injector: Injector,
    private cookieService: CookieService
  ) {
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.formBuilder =
      this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.store = this.injector.get<Store>(Store);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
  }

  ngOnInit(): void {
    this.setForm();
  }

  setForm(): void {
    this.supportTicketForm = this.formBuilder.group({
      name: new FormControl<string | null>(this.name, [Validators.required]),
      email: new FormControl<string | null>(this.email, [
        Validators.required,
        Validators.pattern(
          '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$'
        ),
      ]),
      phone: new FormControl<string>('', Validators.required),
      client: new FormControl<string | null>(''),
      helpTopic: new FormControl<number | null>(9000140263),
      moduleId: new FormControl<string | null>('35'),
      subject: new FormControl<string | null>('', Validators.required),
      message: new FormControl<string | null>('', Validators.required),
    });

    this.getClients();
  }

  saveFile(): void {
    this.fileUploadRequest = {
      userId: this.globalComponent.getLoggedInUserId(),
      file: this.fileList,
      attachmentType: AttachmentType.Project,
    };
    this.commonService.fileUpload(this.fileUploadRequest).subscribe(
      (data) => {
        this.fileUploadResponse = data;
      },
      (error) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
      }
    );
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;
      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        if (
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          file.type === 'image/jpg'
        ) {
          if (file.size / 1024 / 1024 < 2) {
            this.fileList.push(file);
            this.selectedFile = this.fileList;
            this.saveFile();
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.fileSize2mb
            );
          }
        } else {
          this.commonService.onFailure(NotificationTextMessage.fileJpgPng);
        }
      });
    }
  }

  onCancel(): void {
    this.filesForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  removeFile(index: any): void {
    this.fileList.splice(index, 1);
    this.fileUploadResponse.splice(index, 1);
  }

  getClients(): void {
    this.store
      .dispatch(new GetClients(this.defaultGuid))
      .pipe(
        tap((res) => {
          this.clientList = res.time.clientList;
          this.supportTicketForm.controls.client.setValue(
            this.clientList[0].name
          );
        })
      )
      .subscribe();
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.supportTicketData = {
        name: this.supportTicketForm.controls.name.value,
        emailId: this.supportTicketForm.controls.email.value,
        phoneNumber: this.supportTicketForm.controls.phone.value,
        clientName: this.supportTicketForm.controls.client.value,
        supportTopic: 'Support Group',
        modules: this.supportTicketForm.controls.moduleId.value,
        subject: this.supportTicketForm.controls.subject.value,
        message: this.supportTicketForm.controls.message.value,
        externalUserGuid: this.externalUserGUID,
        file: this.selectedFile,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(): void {
    if (this.supportTicketForm.invalid) {
      this.commonService.addValidation(this.supportTicketForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit() && this.supportTicketForm.dirty) {
        this.store
          .dispatch(new SaveSupportTicket(this.supportTicketData))
          .pipe()
          .subscribe(
            (res) => {
              if (res?.ticket.isSuccess) {
                this.commonService.onSuccess(
                  res?.ticket.response.valueResult.message
                );
                this.dialogRef.close(true);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  onPhoneInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9\+\-\(\)\s]/g, '');
  }
}
