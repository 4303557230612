import { Guid } from 'guid-typescript';
import { Note } from '../notes/notes';

export class EstimateBasicInfo {
  universalId?: Guid;
  estimateNo: string;
  createdOn?: string;
  acceptedDate?: Date;
  expiresDate?: string;
  status?: number;
  logo: string;
  ref: string;
  clientId?: string;
  totalAmount?: number;
  isDeleted?: boolean;
  isSelected?: boolean;
  poid: string;
  lastSentOn?: Date;
  footer: string;
  estimateDetails: Array<EstimatesDetail>;
  estimateNotes?: Array<Note>;
}

export class EstimatesDetail {
  estimateId: string;
  clientId: string;
  type: string;
  projectId?: string;
  taskId?: string;
  projectName: string;
  taskDescription: string;
  hoursAndQuantity?: number;
  price?: number;
  amount?: number;
  isDeleted?: boolean;
  isArchived?: boolean;
}
