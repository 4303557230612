import { Guid } from 'guid-typescript';
import { ExportAction } from '../../enums';
import {
  EstimateBasicInfo,
  EstimateEmailModel,
  EstimateParameters,
  ExportParams1,
  Note,
  QueryParams,
} from '../../models';
export class SendEstimateEmail {
  static readonly type = '[ESTIMATES] Send Estimate Email';

  constructor(public emailModel: EstimateEmailModel) {}
}

export class GetEstimateTemplate {
  static readonly type = '[ESTIMATES] Get Estimate Template By Estimate ID';
  constructor(public estimateId: Guid, public templateId: number) {}
}

export class GetEstimateRecentHistory {
  static readonly type = '[ESTIMATES] Get estimate Recent History';

  constructor(public estimateId: Guid) {}
}

export class GetEstimateList {
  static readonly type = '[ESTIMATES] Get estimate names';

  constructor(public queryParams: QueryParams) {}
}

export class GetEstimateDetailList {
  static readonly type = '[ESTIMATES] Get Estimates List';

  constructor(public estimateParams: EstimateParameters) {}
}

export class DeleteSelectedEstimate {
  static readonly type = '[ESTIMATES] Delete Selected Estimate';

  constructor(public estimateIds?: Array<Guid>) {}
}

export class UpdateEstimateStatus {
  static readonly type = '[ESTIMATES] Update Estimate Status';

  constructor(public estimateIds?: Array<Guid>, public status?: number) {}
}

export class SetEstimateId {
  static readonly type = '[ESTIMATES] Set Estimate ID ';

  constructor(public estimateId: Guid) {}
}

export class CopyEstimates {
  static readonly type = '[ESTIMATES] Copy Selected Estimate';

  constructor(public estimateIds?: Array<Guid>) {}
}

export class ExportEstimate {
  static readonly type = '[ESTIMATES] Export Estimate';

  constructor(public exportParams: ExportParams1) {}
}

export class ExportEstimateToPDF {
  static readonly type = '[ESTIMATES] Export Estimate To PDF';

  constructor(
    public estimateIds: Array<string>,
    public exportAction: ExportAction
  ) {}
}

export class SaveEstimateBasicInfo {
  static readonly type = '[ESTIMATES] Save Basic Info for Estimate';

  constructor(public estimateBasicInfo: EstimateBasicInfo) {}
}

export class GetEstimateNoteListById {
  static readonly type = '[ESTIMATES] Get Estimate Note List By ID';

  constructor(public estimateId: number, public searchText?: string) {}
}

export class SaveEstimateNotes {
  static readonly type = '[ESTIMATES] Save Estimate Notes';

  constructor(public note: Note) {}
}

export class GetEstimateById {
  static readonly type = '[ESTIMATES] Get Estimate By ID';

  constructor(public estimateId: Guid) {}
}

export class ExportEstimatesToPDF {
  static readonly type = '[ESTIMATES] Export Estimates To PDF';

  constructor(
    public estimateIds: Array<Guid>,
    public exportAction: ExportAction
  ) {}
}

export class SetEstimateDefaultState {
  static readonly type = '[ESTIMATES] Set Estimate Default State';
}

export class GetEstimateReceiptDetails {
  static readonly type = '[ESTIMATES] Get Estimate Receipt Details';

  constructor(public universalId: Guid) {}
}

export class SetDefaultEstimateId {
  static readonly type = '[ESTIMATES] Set Default Estimate ID';
}
