import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthResolverService } from 'src/app/core/guards/auth.resolver';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { DashboardNewComponent } from './dashboard/dashboard-new.component';
import { HomeComponent } from './home.component';
import { LiveComponent } from './live/live.component';
import { TicketComponent } from './ticket/ticket.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    resolve: {
      result: AuthResolverService,
    },
    children: [
      { path: '', redirectTo: 'time', pathMatch: 'full' },
      {
        path: 'time',
        loadChildren: () =>
          import('./time/time.module').then((m) => m.TimeModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./manage/manage.module').then((m) => m.ManageModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'invoicestype',
        loadChildren: () =>
          import('./invoices-type/invoices-type.module').then(
            (m) => m.InvoicesTypeModule
          ),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings/settings.module').then((m) => m.SettingsModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'expenses',
        loadChildren: () =>
          import('./expenses/expenses.module').then((m) => m.ExpensesModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'reportstype',
        loadChildren: () =>
          import('./reports/reports.module').then((m) => m.ReportsModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'jobs',
        loadChildren: () =>
          import('./jobs/jobs.module').then((m) => m.JobsModule),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'dashboard',
        component: DashboardNewComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'announcementAndActivity/:tabId',
        loadChildren: () =>
          import('./notification-timeline/notification-timeline.module').then(
            (m) => m.NotificationTimelineModule
          ),
        resolve: {
          result: AuthResolverService,
        },
      },
      {
        path: 'live',
        component: LiveComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'ticket',
        component: TicketComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'access-denied',
        component: AccessDeniedComponent,
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
