<div class="main-contain">
  <div class="header-wrapper">
    <div class="navigation-container">
      <app-header></app-header>
    </div>
  </div>
  <div class="content-body-wrapper">
    <router-outlet (activate)="changeOfRoutes()"></router-outlet>
    <app-custom-notifications></app-custom-notifications>
  </div>
</div>
