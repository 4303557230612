export enum ImageURLs {
  Clients = 'person_outline',
  Tasks = 'list_alt',
  ExpenseType = 'local_atm',
  Expenses = 'local_atm',
  Taxes = 'monetization_on',
  Roles = 'portrait',
  Teammates = 'people_outline',
  Project = ' bar_chart',
  Invoices = 'receipt',
  RecurringInvoices = 'library_books',
  Unsubmitted = 'block',
  PendingApproval = 'update',
  Archive = 'archive',
  Estimates = 'assignment',
}
