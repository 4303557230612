export class ProjectBasicInfo {
  id?: number;
  universalId?: string;
  clientId?: number;
  code: string;
  name: string;
  startDate: Date;
  endDate: Date;
  hourlyRateTypeId?: number;
  hourlyRate?: number;
  showProjectReportToEveryone?: number;
  projectTypeId: number;
  projectFee?: number;
  totalBudget?: number;
  includeBillableAndNonBillable?: boolean;
  sendEmailAlertIfExceed?: boolean;
  resetBudgetEveryMonth?: boolean;
  exceedBudgetToSendEmail?: number;
  budgetTypeId?: number;
  ExceedBudgetToSendEmail?: number;
}

export class HourlyRateType {
  id: number;
  name: string;
}

export class BudgetType {
  id: number;
  name: string;
}

export class ProjectType {
  id: number;
  name: string;
}
