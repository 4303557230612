import { Injectable } from '@angular/core';
import { CalendarDetailModel } from '@app/core/models/calendar';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ListModel,
  SideListGroupModel,
  SideListModel,
  TimeLogFilterDetailModel,
  TimeLogModel,
  TimeSubTaskListModel,
  TimelogByUserModel,
  TimelogByWeekModel,
  TimelogCalendarDetailsModel,
  UserLiveTimeLogModel,
  UserTimeLogDetails,
  UserTimelogModel,
  UserWeekTimelogOverviewModel,
} from '../../models';
import { TimeService } from '../../services';
import {
  ApproveTimelog,
  CopyTimeLog,
  DeleteTimelogByUniversalId,
  DeleteUserTimeLogByWeek,
  ExportTimelog,
  ExportTimesheet,
  GetAlreadyApprovedTaskStatus,
  GetCalendar,
  GetClients,
  GetLiveUpdates,
  GetPendingSummaryData,
  GetSubTasks,
  GetTasks,
  GetTimeLogDetailsByUniversalId,
  GetTimeLogDetailsByWeek,
  GetTimeLogList,
  GetTimelogByUser,
  GetTimelogByWeek,
  GetTimesheetCalendarDetail,
  GetUserDayTimelog,
  GetUserWeekTimelogOverview,
  GetUserWeekTimelogs,
  GetUsers,
  GetUsersWhoLoggedTime,
  IsTimerRunning,
  RejectTimeLog,
  RunningTimerData,
  SaveTimeLog,
  SaveTimelogs,
  SaveWeekTimeLogByUniversalId,
  SaveWeekTimelog,
  SendEmailNote,
  SendEmailTime,
  SendReminderForApproval,
  SubmitTimelog,
  WithdrawTimelog,
} from './time.action';

export class TimeStateInfo {
  timeLogData?: Array<UserTimeLogDetails>;
  timelogs: TimeLogModel;
  usersWhoLoggedTimeList?: Array<SideListModel>;
  timeLogDetailsData: UserTimelogModel;
  timeLogStatus?: number;
  pendingsummaryData: TimeLogFilterDetailModel;
  isApproved?: boolean;
  isSubmitted?: boolean;
  isWithdraw?: boolean;
  isDeleted?: boolean;
  isAlreadyApprovedTask?: boolean;
  clientList?: Array<ListModel>;
  taskList?: Array<SideListGroupModel>;
  subTaskList?: Array<TimeSubTaskListModel>;
  userList?: Array<ListModel>;
  jobTimelogs?: Array<TimelogByUserModel>;
  jobTimelogsByUser?: Array<TimelogByUserModel>;
  jobTimelogsByWeek?: Array<TimelogByWeekModel>;
  isTimelogRejected?: boolean;
  isReminderSend?: boolean;
  isEmailNoteSend?: boolean;
  timeLogAddData?: any;
  isTimelogSaved?: boolean;
  timelogOverview?: UserWeekTimelogOverviewModel;
  userLiveTimelogList?: Array<UserLiveTimeLogModel>;
  exported?: boolean;
  calendarDetails?: CalendarDetailModel[];
  timesheetCalendarDetail?: TimelogCalendarDetailsModel[];
  isTimerRunning: boolean;
  runningTimerData?: any;
}

@State<TimeStateInfo>({
  name: 'time',
  defaults: {
    timelogs: new TimeLogModel(),
    pendingsummaryData: new TimeLogFilterDetailModel(),
    timeLogDetailsData: new UserTimelogModel(),
    isTimerRunning: false,
  },
})
@Injectable()
export class TimeState {
  constructor(private timeService: TimeService) {}

  @Selector()
  static runningTimerData(state: TimeStateInfo) {
    return state.runningTimerData;
  }

  @Selector()
  static isTimerRunning(state: TimeStateInfo) {
    return state.isTimerRunning;
  }

  @Selector()
  static getTimeLogList(state: TimeStateInfo) {
    return state.timeLogData;
  }

  @Selector()
  static getTimeLogStatus(state: TimeStateInfo) {
    return state.timeLogStatus;
  }

  @Selector()
  static getTimeLogDetails(state: TimeStateInfo) {
    return state.timeLogDetailsData;
  }

  @Selector()
  static getTimeSheetData(state: TimeStateInfo) {
    return state.timelogs;
  }

  @Selector()
  static getPendingSummaryData(state: TimeStateInfo) {
    return state.pendingsummaryData;
  }

  @Selector()
  static getAlreadyApprovedTaskStatus(state: TimeStateInfo) {
    return state.isAlreadyApprovedTask ?? false;
  }

  @Selector()
  static getClients(state: TimeStateInfo) {
    return state.clientList;
  }

  @Selector()
  static getTasks(state: TimeStateInfo) {
    return state.taskList;
  }

  @Selector()
  static getSubTasks(state: TimeStateInfo) {
    return state.subTaskList;
  }

  @Selector()
  static getUsers(state: TimeStateInfo) {
    return state.userList;
  }

  @Action(RunningTimerData)
  runningTimerData(
    { patchState }: StateContext<TimeStateInfo>,
    action: RunningTimerData
  ) {
    patchState({
      runningTimerData: action.runningTimerData,
    });
  }

  @Action(IsTimerRunning)
  isTimerRunning(
    { patchState }: StateContext<TimeStateInfo>,
    action: IsTimerRunning
  ) {
    patchState({
      isTimerRunning: action.isTimerRunning,
    });
  }

  @Action(SaveTimeLog)
  saveTimeLog(
    { patchState }: StateContext<TimeStateInfo>,
    action: SaveTimeLog
  ) {
    return this.timeService.saveTimeLog(action.addTimeLogModel).pipe(
      tap((res) => {
        patchState({
          timeLogAddData: res,
        });
      })
    );
  }

  @Action(GetTimeLogList, { cancelUncompleted: true })
  getTimeLogList(
    { getState, patchState }: StateContext<TimeStateInfo>,
    action: GetTimeLogList
  ) {
    return this.timeService.getFilterTimeLog(action.timeParameters).pipe(
      tap((res) => {
        patchState({
          timeLogData: res.userTimeLogDetails,
        });
      })
    );
  }

  @Action(GetPendingSummaryData, { cancelUncompleted: true })
  getPendingSummaryData(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetPendingSummaryData
  ) {
    return this.timeService.getFilterTimeLog(action.timeParameters).pipe(
      tap((res) => {
        patchState({
          pendingsummaryData: res,
        });
      })
    );
  }

  @Action(GetTimeLogDetailsByWeek)
  getTimeLogDetailsByWeek(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetTimeLogDetailsByWeek
  ) {
    return this.timeService
      .getTimeLogDetailsByWeek(action.timelogDetailModel)
      .pipe(
        tap((res) => {
          patchState({
            timeLogDetailsData: res,
          });
        })
      );
  }

  @Action(GetTimeLogDetailsByUniversalId)
  getTimeLogDetailsByUniversalId(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetTimeLogDetailsByUniversalId
  ) {
    return this.timeService
      .getTimeLogDetailsByUniversalId(action.universalId)
      .pipe(
        tap((res) => {
          patchState({
            timeLogDetailsData: res,
          });
        })
      );
  }

  @Action(GetUserWeekTimelogs, { cancelUncompleted: true })
  getUserWeekTimelogs(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetUserWeekTimelogs
  ) {
    return this.timeService.getUserWeekTimelogs(action.timelogParams).pipe(
      tap((res) => {
        patchState({
          timelogs: res,
        });
      })
    );
  }

  @Action(GetUserDayTimelog, { cancelUncompleted: true })
  getUserDayTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetUserDayTimelog
  ) {
    return this.timeService.getUserDayTimelog(action.dayTimelogParameters).pipe(
      tap((res) => {
        patchState({
          timelogs: res,
        });
      })
    );
  }

  @Action(CopyTimeLog)
  copyTimeLog(time: StateContext<TimeStateInfo>, action: CopyTimeLog) {
    return this.timeService.copyTimeLog(action.copyTimeLogModel);
  }

  @Action(WithdrawTimelog)
  withdrawTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: WithdrawTimelog
  ) {
    return this.timeService.withdrawTimelog(action.approveTimeLogModel).pipe(
      tap((res) => {
        patchState({
          isWithdraw: res,
        });
      })
    );
  }

  @Action(ApproveTimelog)
  approveTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: ApproveTimelog
  ) {
    return this.timeService.approveTimelog(action.approveTimeLogModel).pipe(
      tap((res) => {
        patchState({
          isApproved: res,
        });
      })
    );
  }

  @Action(SaveTimelogs)
  saveTimelogs(
    time: StateContext<TimeStateInfo>,
    action: SaveTimelogs
  ): Observable<any> {
    return this.timeService.saveTimelog(action.userTimelogModel);
  }

  @Action(SendEmailTime)
  SendEmailTime(time: StateContext<TimeStateInfo>, action: SendEmailTime) {
    return this.timeService.sendEmail(action.sendEmailModule);
  }

  @Action(DeleteUserTimeLogByWeek)
  deleteUserTimeLogByWeek(
    { patchState }: StateContext<TimeStateInfo>,
    action: DeleteUserTimeLogByWeek
  ) {
    return this.timeService
      .deleteUserTimeLogByWeek(action.deleteTimelogModel)
      .pipe(
        tap((res) => {
          patchState({
            isDeleted: res,
          });
        })
      );
  }

  @Action(DeleteTimelogByUniversalId)
  deleteTimelogByUniversalId(
    { patchState }: StateContext<TimeStateInfo>,
    action: DeleteTimelogByUniversalId
  ) {
    return this.timeService.deleteTimelogByUniversalId(action.universalId).pipe(
      tap((res) => {
        patchState({
          isDeleted: res,
        });
      })
    );
  }

  @Action(SubmitTimelog)
  submitTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: SubmitTimelog
  ) {
    return this.timeService.submitTimelog(action.submitTimeLogModel).pipe(
      tap((res) => {
        patchState({
          isSubmitted: res,
        });
      })
    );
  }

  @Action(SendReminderForApproval)
  sendReminderForApproval(
    { patchState }: StateContext<TimeStateInfo>,
    action: SendReminderForApproval
  ) {
    return this.timeService
      .sendReminderForApproval(action.submitTimeLogModel)
      .pipe(
        tap((res) => {
          patchState({
            isReminderSend: res,
          });
        })
      );
  }

  @Action(SendEmailNote)
  sendEmailNote(
    { patchState }: StateContext<TimeStateInfo>,
    action: SendEmailNote
  ) {
    return this.timeService.sendEmailNote(action.submitTimeLogModel).pipe(
      tap((res) => {
        patchState({
          isEmailNoteSend: res,
        });
      })
    );
  }

  @Action(RejectTimeLog)
  rejectTimeLog(
    { patchState }: StateContext<TimeStateInfo>,
    action: RejectTimeLog
  ) {
    return this.timeService.rejectTimeLog(action.submitTimeLogModel).pipe(
      tap((res) => {
        patchState({
          isTimelogRejected: res,
        });
      })
    );
  }

  @Action(GetAlreadyApprovedTaskStatus)
  getAlreadyApprovedTaskStatus(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetAlreadyApprovedTaskStatus
  ) {
    return this.timeService
      .getAlreadyApprovedTaskStatus(action.taskApprovedParameter)
      .pipe(
        tap((res) => {
          patchState({
            isAlreadyApprovedTask: res,
          });
        })
      );
  }

  @Action(GetClients)
  getClients({ patchState }: StateContext<TimeStateInfo>, action: GetClients) {
    return this.timeService.getClients(action.jobId).pipe(
      tap((res) => {
        patchState({
          clientList: res,
        });
      })
    );
  }

  @Action(GetTasks)
  getTasks({ patchState }: StateContext<TimeStateInfo>, action: GetTasks) {
    return this.timeService.getTasks(action.jobId, action.clientId).pipe(
      tap((res) => {
        patchState({
          taskList: res,
        });
      })
    );
  }

  @Action(GetSubTasks)
  getSubTasks(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetSubTasks
  ) {
    return this.timeService.getSubTasks(action.jobId, action.taskId).pipe(
      tap((res) => {
        patchState({
          subTaskList: res,
        });
      })
    );
  }
  @Action(GetUsers)
  getUsers({ patchState }: StateContext<TimeStateInfo>, action: GetUsers) {
    return this.timeService.getUsers(action.jobId, action.clientId).pipe(
      tap((res) => {
        patchState({
          userList: res,
        });
      })
    );
  }

  //--------------------------------------------------------

  @Action(GetTimelogByUser)
  getTimelogByUser(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetTimelogByUser
  ) {
    return this.timeService.getTimelogByUser(action.jobTimelogParams).pipe(
      tap((res) => {
        patchState({
          jobTimelogsByUser: res,
        });
      })
    );
  }

  @Action(GetTimelogByWeek)
  getTimelogByWeek(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetTimelogByWeek
  ) {
    return this.timeService.getTimelogByWeek(action.jobTimelogParams).pipe(
      tap((res) => {
        patchState({
          jobTimelogsByWeek: res,
        });
      })
    );
  }

  @Action(GetUsersWhoLoggedTime)
  getUsersWhoLoggedTime(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetUsersWhoLoggedTime
  ) {
    return this.timeService.getUsersWhoLoggedTime(action.jobId).pipe(
      tap((res) => {
        patchState({
          usersWhoLoggedTimeList: res,
        });
      })
    );
  }

  @Action(SaveWeekTimelog)
  saveWeekTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: SaveWeekTimelog
  ) {
    return this.timeService
      .saveWeekTimelog(action.timeLogModel, action.status)
      .pipe(
        tap((res) => {
          patchState({
            isTimelogSaved: res,
          });
        })
      );
  }

  @Action(GetUserWeekTimelogOverview)
  getUserWeekTimelogOverview(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetUserWeekTimelogOverview
  ) {
    return this.timeService
      .getUserWeekTimelogOverview(action.timeParameters)
      .pipe(
        tap((res) => {
          patchState({
            timelogOverview: res,
          });
        })
      );
  }

  @Action(GetLiveUpdates)
  getLiveUpdates(
    { patchState }: StateContext<TimeStateInfo>,
    action: GetLiveUpdates
  ) {
    return this.timeService.getLiveUpdates().pipe(
      tap((res) => {
        patchState({
          userLiveTimelogList: res,
        });
      })
    );
  }

  @Action(ExportTimelog)
  exportTimelog(
    { patchState }: StateContext<TimeStateInfo>,
    action: ExportTimelog
  ) {
    return this.timeService.exportTimelog(action.exportParams).pipe(
      tap(() => {
        const exported = true;
        patchState({
          exported,
        });
      })
    );
  }

  @Action(GetCalendar, { cancelUncompleted: true })
  getCalendar(
    { getState, patchState }: StateContext<TimeStateInfo>,
    action: GetCalendar
  ) {
    return this.timeService.getCalendar(action.calendarDetailParameters).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          calendarDetails: res.body!,
        });
      })
    );
  }

  @Action(GetTimesheetCalendarDetail, { cancelUncompleted: true })
  getTimesheetCalendarDetail(
    { getState, patchState }: StateContext<TimeStateInfo>,
    action: GetTimesheetCalendarDetail
  ) {
    return this.timeService
      .getTimesheetCalendarDetail(action.userId, action.currentDate)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            timesheetCalendarDetail: res,
          });
        })
      );
  }

  @Action(SaveWeekTimeLogByUniversalId)
  saveWeekTimeLogByUniversalId(
    { patchState }: StateContext<TimeStateInfo>,
    action: SaveWeekTimeLogByUniversalId
  ) {
    return this.timeService
      .saveWeekTimeLogByUniversalId(action.timeLogModel, action.status)
      .pipe(
        tap((res) => {
          patchState({
            isTimelogSaved: res,
          });
        })
      );
  }

  @Action(ExportTimesheet)
  exportTimesheet(
    { patchState }: StateContext<TimeStateInfo>,
    action: ExportTimesheet
  ) {
    return this.timeService.exportTimesheet(action.timesheetExportParams).pipe(
      tap(() => {
        const exported = true;
        patchState({
          exported,
        });
      })
    );
  }
}
