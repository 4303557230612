import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Subscription';

  private readonly subscriptionApiUrl = `${environment.subscriptionApiUrl}/`;
  private readonly subscriptionRouteBase = 'Subscriptions';

  constructor(private http: HttpClient) {}

  retrieveSubscription(): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/retrieveSubscription`
    );
  }

  getClientLimit(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}${this.routeBase}/getClientLimit`);
  }

  getModuleRedirectionURL(
    moduleId: number,
    userName: string,
    externalUserGuid: string
  ): Observable<any> {
    const encodedUserName = encodeURIComponent(userName);
    return this.http.get<any>(
      `${this.subscriptionApiUrl}${this.subscriptionRouteBase}/RedirectionURLforModule?moduleId=${moduleId}&username=${encodedUserName}&ExternalUserGUID=${externalUserGuid}`
    );
  }
}
