import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MainListParameters } from '@app/core/models';
import { CalendarState, GetCalendarUsers } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-calendar-side-list',
  templateUrl: './calendar-side-list.component.html',
  styleUrls: ['./calendar-side-list.component.scss'],
})
export class CalendarSideListComponent implements OnInit {
  closeMenu = true;

  @Output()
  readonly toggleSideList = new EventEmitter<boolean>();

  @Output()
  readonly showSideListAction = new EventEmitter<any>();
  selectedId = Guid.EMPTY as unknown as Guid;
  defaultGuidValue = Guid.EMPTY as unknown as Guid;
  menuToggle(): void {
    this.closeMenu = !this.closeMenu;

    this.toggleSideList.emit(this.closeMenu);
    window.dispatchEvent(new Event('resize'));
  }
  listParameters: MainListParameters = new MainListParameters();
  userList: any = [];
  @Output()
  readonly triggerUserId = new EventEmitter<any>();
  @Input()
  calendarSideList: Observable<boolean>;
  calendarParam: any;
  constructor(private store: Store) {}

  ngOnInit(): void {
    this.calendarSideList.subscribe((res) => {
      this.calendarParam = res;

      const queryParams = {
        calendarType: this.calendarParam.calendarType,
        currentDate: this.calendarParam.currentDate,
        userId: this.calendarParam.userId,
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        search: this.listParameters.search,
      };
      this.bindList(queryParams);
    });
  }

  onTableScroll(event): void {
    let isLastPage: boolean | undefined = false;
    isLastPage = this.store.selectSnapshot(CalendarState.isLastPage);
    if (isLastPage === false) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
      ) {
        const pageIndex = this.listParameters.pageNumber + 1;
        this.pageChanged(pageIndex);
      }
    }
  }

  pageChanged(pageIndex: number): void {
    this.listParameters.pageNumber = pageIndex;
    const queryParams = {
      calendarType: this.calendarParam.calendarType,
      currentDate: this.calendarParam.currentDate,
      userId: this.calendarParam.userId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      search: this.listParameters.search,
    };
    this.bindList(queryParams);
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    if (event.target.value) {
      this.listParameters.pageNumber = 1;
    }
    const queryParams = {
      calendarType: this.calendarParam.calendarType,
      currentDate: this.calendarParam.currentDate,
      userId: this.calendarParam.userId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      search: this.listParameters.search,
    };
    this.bindList(queryParams);
  }

  bindList(queryParams: any): void {
    this.store
      .dispatch(new GetCalendarUsers(queryParams))
      .pipe(
        tap(() => {
          this.userList = this.store.selectSnapshot(
            CalendarState.getCalendarUserSideList
          );
        })
      )
      .subscribe();
  }

  onUserClick(user?: any): void {
    if (user) {
      this.selectedId = user.universalId;
    } else {
      this.selectedId = this.defaultGuidValue;
    }
    this.triggerUserId.emit(this.selectedId);
  }

  isActive(element: any): boolean {
    return this.selectedId === element.universalId;
  }
}
