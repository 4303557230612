import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Client,
  ClientGridModel,
  ClientUserClientModel,
  InvoiceParameters,
  Invoices,
  QueryParams,
} from '../../models';
import { FileImportRequestModel } from '../../models/common/import';
import { ExportParams1 } from '../../models/common/query-params';
import { SideListModel } from '../../models/common/side-list';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Client';
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getClientInvoicesByClientId(
    clientId: Guid,
    queryParams: InvoiceParameters
  ): Observable<any> {
    return this.http.get<Array<Invoices>>(
      `${this.apiUrl}${this.routeBase}/getClientInvoicesByClientId/${clientId}?&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`
    );
  }

  getClientOverview(
    clientId: Guid,
    monthNumber: number
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getClientOverview/${clientId}/${monthNumber}`
    );
  }

  getClientDetailList(
    queryParams: QueryParams
  ): Observable<HttpResponse<ClientGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Client/executeClientList`,
      queryParams,
      httpOptions
    );
  }

  getClientList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Client/clientsList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  createClient(client: Client): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Client/createClient`,
      JSON.stringify(client),
      headers
    );
  }

  getClientById(clientId: Guid): Observable<Client> {
    return this.http.get<Client>(
      `${environment.apiVersionUrl}/Client/getClientByUniversalId/${clientId}`
    );
  }

  updateClient(client: Client): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Client/updateClient`,
      JSON.stringify(client),
      headers
    );
  }

  deleteClients(clientIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(clientIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/Client/deleteClientsByUniversalId`,
      options
    );
  }

  export(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Client/exportClients`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  copy(clientIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Client/copyClients`,
      JSON.stringify(clientIds),
      headers
    );
  }

  archiveAndRestoreClient(
    clientIds?: Array<string>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      universalIds: clientIds,
      status: isArchive,
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(statusModel),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Client/updateClientStatus`,
      JSON.stringify(statusModel),
      options
    );
  }

  getAllClientList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Client/getAllClients`
    );
  }

  getAllClientsWithContact(): Observable<Array<Client>> {
    return this.http.get<Array<Client>>(
      `${environment.apiVersionUrl}/Client/getAllClientWithContact`
    );
  }

  getClientTasksDetails(
    queryParams
  ): Observable<HttpResponse<Array<ClientUserClientModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<Array<ClientUserClientModel>>(
      `${environment.apiVersionUrl}/Client/getClientTasksWithSubTask`,
      queryParams,
      httpOptions
    );
  }

  //Reset
  getResetData(resetParams: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<Observable<any>>(
      `${environment.apiVersionUrl}/Task/getTaskFeeAmountByFeeTypeId`,
      JSON.stringify(resetParams),
      headers
    );
  }

  getClientUsersDetails(queryParams): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/Client/getClientUsers`,
      queryParams,
      httpOptions
    );
  }

  saveClient(client: Client): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/client/saveClient`,
      JSON.stringify(client),
      headers
    );
  }

  saveClientUsers(clientId: Guid, client: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/client/saveClientUsers/${clientId}`,
      JSON.stringify(client),
      headers
    );
  }

  saveClientTask(clientId: Guid, client: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/client/saveClientTasks/${clientId}`,
      JSON.stringify(client),
      headers
    );
  }

  deleteClientTaskByClientId(clientId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(clientId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/client/deleteClientTaskByUniversalId`,
      options
    );
  }

  deleteClientUserByClientId(clientId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(clientId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/client/deleteClientUserByUniversalId`,
      options
    );
  }

  importClients(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/Client/importClients`,
      formData
    );
  }

  getClientByProjectId(projectId: number): Observable<Client> {
    return this.http.get<Client>(
      `${environment.apiVersionUrl}/Client/getClientByProjectId/${projectId}`
    );
  }
}
