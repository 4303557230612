export enum ExportType {
  UnKnown = 0,
  CSV = 1,
  Excel = 2,
  GoogleDrive = 3,
  PDF = 4,
}

export enum ExportAction {
  Export = 0,
  View = 1,
  Print = 2,
  CopyLink = 3,
}
