<div class="sidenav-body-content">
  <div class="sidenav-table sidenav-table-input">
    <div class="mat-table-wrapper contact-detail-table">
      <table
        mat-table
        [dataSource]="jobInvoiceList"
        aria-describedby="Job Invocie Table"
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
      >
        <ng-container matColumnDef="id">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{ element.invoiceNo }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.date | date : "dd-LLL-yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Pre-tax amount
          </th>
          <td mat-cell *matCellDef="let element">£ {{ element.amount }}</td>
        </ng-container>
        <ng-container matColumnDef="lastReceivedOn">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            Last Received on
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.lastReceivedOn | date : "dd-LLL-yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <span
              [ngClass]="{
                'stext-gray-300': element.status === 0,
                'text-blue-400': element.status === 1,
                'text-danger-100': element.status === 2,
                'text-yellow-100': element.status === 3,
                'text-success': element.status === 4
              }"
              >{{ statusFilter[element.status] }}</span
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr class="mat-row" *matNoDataRow>
          <td
            class="mat-cell"
            [ngClass]="'text-align-center'"
            [attr.colspan]="displayedColumns.length"
          >
            <span>{{ commonNotificationText.NoRecordsFound }}</span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
