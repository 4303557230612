import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import {
  ActionType,
  CommonNotificationText,
  ConfirmationType,
  DataType,
  ExportType,
  FeeType,
  GridActionsText,
  JobStatusModel,
  ModuleHeaderText,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  GlobalComponent,
  JobFilterTypes,
  JobQueryParams,
  MainListParameters,
  SideListModel,
  UpdateJobStatusModel,
} from '@app/core/models';
import { ActionModel } from '@app/core/models/common/grid-action';
import { CommonService, NotificationService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/shared/components';
import {
  GetAllTasks,
  GetStartWeekAndEndWeek,
  SaveBusinessDynamicColumn,
  SettingsState,
  TimeState,
} from '@app/core/store';
import {
  CompleteJobSubTask,
  ExportJob,
  GetJobDetailList,
  GetJobSubTaskDetailList,
  GetJobSubTaskUserDetailList,
  JobState,
  UpdateJobStatus,
} from '@app/core/store/job';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { AddJobsComponent } from '../add-jobs/add-jobs.component';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-jobs-list',
  templateUrl: './jobs-list.component.html',
  styleUrls: ['./jobs-list.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class JobsListComponent implements OnInit, OnDestroy {
  listParameters: MainListParameters = new MainListParameters();
  jobList: any[] = [];
  jobSubtaskList: any[] = [];
  jobSubtaskUserList: any[] = [];
  displayedJobColumns: any[] = [];
  displayedSubtaskColumns: any[] = [];
  displayedSubtaskUserColumns: any[] = [];
  displayedJobColumnNames: any[] = [];
  displayedSubtaskColumnNames: any[] = [];
  displayedSubtaskUserColumnNames: any[] = [];
  dataType = DataType;
  currentJobRowSelected: any;
  currentSubtaskRowSelected: any;
  jobId = Guid.EMPTY as unknown as Guid;
  subTaskId = Guid.EMPTY as unknown as Guid;
  dataSource = new MatTableDataSource();
  commonNotificationText = CommonNotificationText;
  noDataFound = false;
  noSubtaskDataFound = false;
  noUserDataFound = false;
  columnList: any[] = [];
  isAllSelected = false;
  ids?: Array<Guid>;
  totalSelectedRecords = 0;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  currentUserId = this.globalComponent.getLoggedInUserId();
  userId = this.defaultGuid;
  isAdminUser = this.globalComponent.isAdmin();
  isRegularUser = this.globalComponent.isRegularUser();
  selectedJobDetails: any = {};

  jobFilterList: any[] = [
    {
      value: this.currentUserId,
      name: 'My Jobs',
    },
    {
      value: this.defaultGuid,
      name: "Everyone's Jobs",
    },
  ];

  @ViewChild(MatSort) sort: MatSort;

  showColumn = false;

  filterTypes = JobFilterTypes;
  filter = 5;
  typeFilter = '-1';
  startDate = new FormControl();
  endDate = new FormControl();
  start: string = '';
  end: string = '';
  calendarForm: FormGroup;
  showCalendar = false;
  moduleId = Modules.Jobs;
  expandedJobElement = null;
  expandedSubtaskElement = null;
  showPaginator = true;
  updateJobStatusModel: UpdateJobStatusModel;
  updateJobStatus: UpdateJobStatusModel[];
  jobStatusModel = JobStatusModel;
  moduleHeaderText = ModuleHeaderText.Jobs;
  exportType = ExportType;

  @Select(JobState.totalRecord)
  totalRecord$: Observable<number>;

  @Select(SettingsState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  @Select(JobState.isDataAvailable)
  isDataAvailable$: Observable<boolean>;

  feeType = FeeType;
  isRowHighlighted = false;
  dialog: MatDialog;
  commonService: CommonService;
  formBuilder: FormBuilder;
  store: Store;
  datepipe: DatePipe;
  spinner: NgxSpinnerService;
  notifier: NotificationService;
  isTimerRunning: boolean = false;
  runningTimerData: any;
  gridActions: ActionModel[];
  gridActionsText = GridActionsText;
  activatedRoute: ActivatedRoute;
  subscriptionRouting: Subscription;
  taskList: SideListModel[];
  dropdownSettings: IDropdownSettings = {};
  selectedTaskItems: any = [];
  selectedTaskIds: Guid[] = [];

  @ViewChildren('button') formButtons: QueryList<ElementRef>;

  triggerJobId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerJobFeesType: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;

  constructor(
    public injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this.dialog = this.injector.get<MatDialog>(MatDialog);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
    this.store = this.injector.get<Store>(Store);
    this.datepipe = this.injector.get<DatePipe>(DatePipe);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.activatedRoute = this.injector.get<ActivatedRoute>(ActivatedRoute);
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'universalId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
  }

  ngOnInit(): void {
    this.subscriptionRouting = this.activatedRoute.paramMap.subscribe(
      (params) => {
        if (params.keys.length > 0) {
          this.onJobAdd(false);
        }
      }
    );
    if (this.isRegularUser) {
      this.userId = this.currentUserId;
    }

    this.listParameters.filter = 0;
    this.setForm();
    this.getTasks();
    this.getJobDetailList();

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(900))
      .subscribe(() => {
        this.getJobDetailList();
      });
  }

  setForm(): void {
    this.calendarForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });
  }

  getTasks(): void {
    this.store
      .dispatch(new GetAllTasks())
      .pipe(
        tap((res) => {
          this.taskList = res.task.allTasks;
        })
      )
      .subscribe();
  }

  updateStatus(element: any, elementStatus: number): void {
    element.isMarkAsCompleted = elementStatus === JobStatusModel.Completed;
    element.isMarkAsYetToStart = elementStatus === JobStatusModel.NotYetStarted;
    element.isSelected = true;
    this.dataSubmit(element);

    this.store
      .dispatch(new UpdateJobStatus(this.updateJobStatusModel))
      .pipe()
      .subscribe((res) => {
        if (res.job.isStatusUpdated) {
          this.deSelectAll();
          this.getJobDetailList();
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  completeJobSubTask(element): void {
    let ids: any = [];
    ids.push(element[0]);
    this.store
      .dispatch(new CompleteJobSubTask(ids))
      .pipe()
      .subscribe((res) => {
        if (res.job.isStatusUpdated) {
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  onJobEdit(data?: any): void {
    if (!this.checkRunningTimerData(data)) {
      this.commonService.onEditRouting(this.moduleId, data[0]);
    }
  }

  getParams() {
    this.spinner.show();

    const queryParams: JobQueryParams = {
      jobId: this.jobId,
      subTaskId: this.subTaskId,
      userId: this.userId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      dateFilter: this.filter,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
      status: +this.typeFilter,
      taskIds: this.selectedTaskIds,
    };

    return queryParams;
  }

  getJobDetailList(): void {
    this.store
      .dispatch(new GetJobDetailList(this.getParams()))
      .subscribe((res) => {
        const result = {
          data: res.job.jobList.resultSet.data,
          columns: res.job.jobList.resultSet.columns,
        };

        this.gridActions = res.job.gridActions;

        this.commonService.setButtonPermissions(
          this.formButtons,
          this.gridActions
        );

        this.jobList = [];
        result.data.forEach((ele, j) => {
          let data: any[] = [];
          result.columns.forEach((element, i) => {
            if (i === 0) {
              data.push(ele[i]);
            }
            if (element.isSelected) {
              data.push(ele[i + 1]);
            }
          });
          this.jobList.push(data);
        });
        this.noDataFound = this.jobList.length > 0 ? false : true;
        this.columnList = [];
        result.columns.forEach((element) => {
          this.columnList.push(element);
        });
        this.displayedJobColumns = [];
        this.displayedJobColumns.push({
          id: 0,
          isDisabled: false,
          isSelected: true,
          optionId: 0,
          primaryName: '',
          secondaryName: '',
          settingName: 'ID',
          settingTypeId: 0,
          settingValue: '',
        });
        result.columns.forEach((element) => {
          if (element.isSelected) {
            this.displayedJobColumns.push(element);
          }
        });
        this.displayedJobColumns.push({
          id: 1,
          isDisabled: false,
          isSelected: true,
          optionId: 0,
          primaryName: '',
          secondaryName: '',
          settingName: 'Action',
          settingTypeId: 50,
          settingValue: '',
        });

        this.displayedJobColumnNames = this.displayedJobColumns.map(
          (x) => x.settingName
        );
      });
  }

  expandCollapseJob(element): void {
    this.jobId = element[0];
    this.expandedJobElement =
      this.expandedJobElement === element ? null : element;
    this.getJobSubTaskDetailListByJobId();
  }

  getJobSubTaskDetailListByJobId(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetJobSubTaskDetailList(this.getParams()))
      .subscribe((res) => {
        this.jobSubtaskList = res.job.jobSubtaskDetailList.resultSet.data;

        this.dataSource.data = this.jobSubtaskList;

        this.noSubtaskDataFound = this.jobSubtaskList.length > 0 ? false : true;

        this.displayedSubtaskColumns = [];
        this.displayedSubtaskColumns.push({
          name: 'id',
        });
        res.job.jobSubtaskDetailList.resultSet.columns.forEach((element) => {
          this.displayedSubtaskColumns.push(element);
        });

        this.displayedSubtaskColumnNames = this.displayedSubtaskColumns.map(
          (x) => x.name
        );
      });
  }

  expandCollapseSubtask(element): void {
    this.subTaskId = element[0];
    this.expandedSubtaskElement =
      this.expandedSubtaskElement === element ? null : element;
    this.getJobSubTaskUserDetailListByJobId();
  }

  getJobSubTaskUserDetailListByJobId(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetJobSubTaskUserDetailList(this.getParams()))
      .subscribe((res) => {
        this.jobSubtaskUserList =
          res.job.jobSubtaskUserDetailList.resultSet.data;

        this.dataSource.data = this.jobSubtaskUserList;

        this.noUserDataFound =
          this.jobSubtaskUserList.length > 0 ? false : true;

        this.displayedSubtaskUserColumns = [];
        res.job.jobSubtaskUserDetailList.resultSet.columns.forEach(
          (element) => {
            this.displayedSubtaskUserColumns.push(element);
          }
        );

        this.displayedSubtaskUserColumnNames =
          this.displayedSubtaskUserColumns.map((x) => x.name);
      });
  }

  onJobAdd(isAddBulkJob: boolean) {
    const dialogRef = this.dialog.open(AddJobsComponent, {
      data: {
        moduleId: Modules.Job,
        isAddBulkJob: isAddBulkJob,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const jobId = this.store.selectSnapshot(JobState.getJobId);
        const data: any[] = [];
        data.push(jobId);
        this.onJobEdit(data);
      } else {
        this.getJobDetailList();
      }
    });
  }

  onSelectionChange(val: any): void {
    this.filter = +val;

    if (this.filter === 11) {
      this.showCalendar = true;
      this.getStartWeekAndEndWeek();
    } else {
      this.showCalendar = false;
      this.dataChangeFromHeader();
    }
  }

  onUserSelectionChange(val: any): void {
    this.listParameters.pageNumber = 1;
    this.getJobDetailList();
  }

  onStatusSelectionChange(val: any): void {
    this.listParameters.pageNumber = 1;
    this.getJobDetailList();
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.calendarForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.dataChangeFromHeader();
    });
  }

  lessWeek(): void {
    this.calendarForm = this.formBuilder.group({
      end: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.calendarForm.controls.start.value);
    this.endDate.setValue(this.calendarForm.controls.end.value);
    this.dataChangeFromHeader();
  }

  addWeek(): void {
    this.calendarForm = this.formBuilder.group({
      start: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 7
        )
      ),
    });
  }

  dataChangeFromHeader(): void {
    this.end =
      this.filter < 11
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.end.value,
            'yyyy-MM-dd'
          )!;
    this.start =
      this.filter < 11
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.start.value,
            'yyyy-MM-dd'
          )!;

    this.getJobDetailList();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.dataChangeFromHeader();
    }
  }

  getActionType(actionType: any, data: any): void {
    switch (actionType) {
      case ActionType.Edit:
        this.onJobEdit(data);
        break;

      case ActionType.Delete:
        this.onDeleteClick(data);
        break;

      case ActionType.Invoice:
        break;
    }
  }

  checkTimerRunning(): void {
    this.isTimerRunning =
      this.store.selectSnapshot(TimeState.isTimerRunning) ?? false;
    if (this.isTimerRunning) {
      this.runningTimerData = this.store.selectSnapshot(
        TimeState.runningTimerData
      );
    } else {
      this.runningTimerData = {};
    }
  }

  checkRunningTimerData(data?: any) {
    this.checkTimerRunning();
    let isDataMatch = false;
    if (this.isTimerRunning) {
      if (data) {
        // action on row
        if (data[0] === this.runningTimerData?.jobId) {
          // timer running and match job
          isDataMatch = true;
        }
      } else {
        // action on bulk selection
        isDataMatch = this.ids?.includes(this.runningTimerData?.jobId)!;
      }
    }
    if (isDataMatch) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.restrictJobActionOnRunningTimer
      );
    }
    return isDataMatch;
  }

  onDeleteClick(data?: any): void {
    this.getSelectedIds(data);
    if (!this.checkRunningTimerData(data)) {
      if (this.ids!.length > 0) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              type: ConfirmationType.Delete,
              moduleId: this.moduleId,
              headerText: NotificationHeader.deleteConfirmation,
              totalNumberOfRecordSelected: this.ids!.length,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.deSelectAll();
              this.getJobDetailList();
            }
          });
      } else {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.selectOneOrMoreRecordToDelete
        );
      }
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    if (sortOrder) {
      this.listParameters.sortOrder = sortOrder === 'asc';
      this.listParameters.sortBy = sortBy;
    } else {
      this.listParameters.sortOrder = true;
      this.listParameters.sortBy = '';
    }
    this.getJobDetailList();
  }

  toggleList(): void {
    this.showColumn = !this.showColumn;
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    this.listParameters.pageNumber = 1;
    this.searchSubject.next(event.target.value);
  }

  onSaveDynamicColumns(): void {
    this.store
      .dispatch(new SaveBusinessDynamicColumn(Modules.Job, this.columnList))
      .subscribe(() => {
        this.getJobDetailList();
        this.onCancelClick();
      });
  }

  onCancelClick(): void {
    this.showColumn = false;
  }

  selectAll(event: any): void {
    this.jobList.forEach((x) => (x.isSelected = event.checked));
    this.showFooter();
  }

  showFooter(): void {
    this.getSelectedIds();
    this.totalSelectedRecords = this.ids!.length;
  }

  getSelectedIds(data?: any): void {
    this.ids = [];

    if (data === undefined) {
      this.jobList.forEach((x) =>
        x.isSelected ? this.ids!.push(x[0]!) : (Guid.EMPTY as unknown as Guid)
      );
    } else {
      this.ids.push(data[0]);
    }
  }

  pageChanged(pageIndex: number): void {
    if (this.listParameters.pageNumber !== pageIndex) {
      this.listParameters.pageNumber = pageIndex;
      this.getJobDetailList();
    }
  }

  pageSizeVal(val: any): void {
    this.listParameters.pageNumber = 1;
    this.listParameters.pageSize = val;
    this.getJobDetailList();
  }

  togglePaginator(val: any): void {
    this.showPaginator = val;
  }

  updateJobStatusClick(element?: any): void {
    this.dataSubmit(element);

    this.store
      .dispatch(new UpdateJobStatus(this.updateJobStatusModel))
      .pipe()
      .subscribe((res) => {
        if (res.job.isStatusUpdated) {
          this.getJobDetailList();
          this.cancelSelectionClick();
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  onCheckBoxSelected(element: any, event: any): void {
    element.isSelected = event.checked;

    this.isAllSelected = this.jobList.every(
      (item: any) => item.isSelected === true
    );

    this.showFooter();
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
  }

  deSelectAll(): void {
    this.jobList.forEach((x) => (x.isSelected = false));
    this.isAllSelected = false;
    this.totalSelectedRecords = 0;
  }

  dataSubmit(element?: any, elementStatus?: number) {
    let status = elementStatus ?? JobStatusModel.Completed;
    if (
      element &&
      element.isMarkAsCompleted !== undefined &&
      element.isMarkAsYetToStart !== undefined
    ) {
      status = element.isMarkAsCompleted
        ? JobStatusModel.Completed
        : JobStatusModel.NotYetStarted;
      element = undefined;
    }
    this.getSelectedIds(element);

    this.updateJobStatusModel = {
      universalIds: this.ids,
      status: status,
    };
  }

  export(format: number, isPrint: boolean): void {
    this.getSelectedIds();

    if (this.jobList.length > 0) {
      this.spinner.show();
      const exportParams: JobQueryParams = {
        jobId: this.jobId,
        subTaskId: this.subTaskId,
        userId: this.userId,
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        dateFilter: this.filter,
        startDate:
          this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
        endDate:
          this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
        format,
        isPrint,
        universalIds: this.ids,
      };

      this.store.dispatch(new ExportJob(exportParams)).subscribe(
        () => {
          this.spinner.hide();
          this.cancelSelectionClick();
        },
        (error) => {
          this.spinner.hide();
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      );
    } else {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  printClick(): void {
    this.export(ExportType.PDF, true);
    this.cancelSelectionClick();
  }

  ngOnDestroy(): void {
    this.subscriptionRouting?.unsubscribe();
    this.searchSubscription.unsubscribe();
  }

  onTaskDeSelect(item: any) {
    this.selectedTaskIds = [];
    this.selectedTaskItems.forEach((element, i) => {
      if (element.universalId === item.universalId) {
        this.selectedTaskItems.splice(i, 1);
      }
    });

    this.selectedTaskItems?.forEach((element) => {
      this.selectedTaskIds.push(element.universalId);
    });

    this.getJobDetailList();
  }

  onSelectAllTasks(items: any) {
    this.selectedTaskItems = items;
  }

  onDeSelectAllTasks() {
    this.selectedTaskItems = [];
  }

  onTaskSelect(item: any) {
    this.selectedTaskIds = [];
    this.selectedTaskItems?.forEach((element) => {
      this.selectedTaskIds.push(element.universalId);
    });

    this.getJobDetailList();
  }

  onLoggedTimeClick(element): void {
    this.selectedJobDetails = element;
    this.triggerJobId.next(element[0]);
    this.triggerJobFeesType.next(element[1]?.split('|')[1]);
  }
}
