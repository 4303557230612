import { Guid } from 'guid-typescript';

export class Expenses {
  universalId?: Guid;
  expenseCode?: string;
  businessId?: number;
  expenseTypeId?: Guid;
  clientId: Guid;
  taskId: Guid;
  userId?: Guid;
  expenseDate: any;
  status?: number;
  isBillable: boolean;
  totalPrice?: number;
  numberOfUnits?: number;
  unitPrice?: number;
  isArchived?: boolean;
  attachment: string;
  notes: string;
  isApproved?: boolean;
  isSelected?: boolean;
  userName?: string;
}
