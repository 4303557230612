export enum ExpenseAction {
  Unsubmitted = 0,
  Submitted = 1,
  Approved = 2,
  Reject = 3,
  Reminder = 4,
}

export enum ExpenseStatus {
  All = -1,
  Unsubmitted = 0,
  PFA = 1,
  Approved = 2,
  Rejected = 3,
}
