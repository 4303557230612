<div
  class="sidenav-body-content"
  [ngClass]="{
    'mb-4': formJobRecurring.dirty,
    'is-readonly': isViewPermission
  }"
>
  <form [formGroup]="formJobRecurring">
    <div class="form-field d-flex align-items-center gap-10">
      <p class="m-0 topspace w-120">Repeat this Job</p>
      <div>
        <div class="form-field-wrapper d-flex align-items-center gap-10">
          <div class="schedule-list d-flex align-items-center gap-10">
            <ng-select class="w-150" formControlName="recurringType">
              <ng-option
                *ngFor="let type of recurringTypeList"
                [value]="type.value"
              >
                {{ type.name }}
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value !==
                  recurringType.Never &&
                formJobRecurring.controls.recurringType.value !==
                  recurringType.Daily &&
                formJobRecurring.controls.recurringType.value !==
                  recurringType.Monthly
              "
            >
              every
            </p>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                recurringType.Monthly
              "
            >
              on the
            </p>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
              recurringType.Monthly
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="dayOfMonth"
            >
              <ng-option *ngFor="let day of dayList" [value]="day.value"
                >{{ day.lable }}
              </ng-option>
            </ng-select>
            <p class="m-0">Day of The Month</p>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
              recurringType.Weekly
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="dayOfWeek"
            >
              <ng-option
                *ngFor="let week of weekList; let i = index"
                [value]="i + 1"
                >{{ week }}
              </ng-option>
            </ng-select>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
              recurringType.Yearly
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="monthOfYear"
            >
              <ng-option
                *ngFor="let month of monthList; let i = index"
                [value]="i + 1"
                >{{ month }}
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                  recurringType.Yearly ||
                (formJobRecurring.controls.recurringType.value ===
                  recurringType.Custom &&
                  formJobRecurring.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year)
              "
            >
              on the
            </p>
          </div>

          <div
            class="schedule-list d-flex align-items-center gap-10"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
              recurringType.Custom
            "
          >
            <mat-form-field class="w-50">
              <input
                matInput
                formControlName="separationCount"
                oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                autocomplete="off"
                required
                maxlength="500"
                style="text-align: center"
              />
            </mat-form-field>
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="scheduleSubPeriod"
            >
              <ng-option
                *ngFor="let type of scheduleSubPeriodList"
                [value]="type.value"
              >
                {{ type.name }}
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                  recurringType.Custom &&
                formJobRecurring.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Year
              "
            >
              in
            </p>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                  recurringType.Custom &&
                formJobRecurring.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Week
              "
            >
              on
            </p>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                  recurringType.Custom &&
                formJobRecurring.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Month
              "
            >
              on the
            </p>
          </div>
        </div>
        <div class="form-field-wrapper d-flex align-items-center gap-10">
          <div
            class="schedule-list d-flex align-items-center gap-10 mt-1"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
                recurringType.Custom &&
              formJobRecurring.controls.scheduleSubPeriod.value ===
                scheduleSubPeriod.Week
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="dayOfWeek"
            >
              <ng-option
                *ngFor="let week of weekList; let i = index"
                [value]="i + 1"
                >{{ week }}
              </ng-option>
            </ng-select>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10 mt-1"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
                recurringType.Custom &&
              formJobRecurring.controls.scheduleSubPeriod.value ===
                scheduleSubPeriod.Year
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="monthOfYear"
            >
              <ng-option
                *ngFor="let month of monthList; let i = index"
                [value]="i + 1"
                >{{ month }}
              </ng-option>
            </ng-select>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.recurringType.value ===
                  recurringType.Yearly ||
                (formJobRecurring.controls.recurringType.value ===
                  recurringType.Custom &&
                  formJobRecurring.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year)
              "
            >
              on the
            </p>
          </div>
          <div
            class="schedule-list d-flex align-items-center gap-10 mt-1"
            *ngIf="
              formJobRecurring.controls.recurringType.value ===
                recurringType.Yearly ||
              (formJobRecurring.controls.recurringType.value ===
                recurringType.Custom &&
                (formJobRecurring.controls.scheduleSubPeriod.value ===
                  scheduleSubPeriod.Month ||
                  formJobRecurring.controls.scheduleSubPeriod.value ===
                    scheduleSubPeriod.Year))
            "
          >
            <ng-select
              class="w-150"
              [disableOptionCentering]="true"
              formControlName="dayOfMonth"
            >
              <ng-option *ngFor="let day of dayList" [value]="day.value"
                >{{ day.lable }}
              </ng-option>
            </ng-select>
            <p class="m-0">Day of The Month</p>
          </div>
        </div>
      </div>
    </div>
    <div class="form-field d-flex align-items-center gap-10">
      <p class="m-0 w-120">Create First Job on</p>
      <div class="d-flex align-items-center gap-10">
        <div class="form-field-wrapper">
          <div class="schedule-list d-flex align-items-center gap-10">
            <mat-form-field
              class="w-150"
              (click)="onpicker.open()"
              appearance="fill"
            >
              <input
                matInput
                readonly
                [matDatepicker]="onpicker"
                formControlName="startDate"
                autocomplete="off"
                required
                [min]="minStartDate"
                (dateChange)="setMinEndDate()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="onpicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #onpicker></mat-datepicker>
            </mat-form-field>
            <p class="m-0">end</p>
          </div>
        </div>
        <div class="form-field-wrapper">
          <div class="schedule-list d-flex align-items-center gap-10">
            <div class="d-flex align-items-center gap-10">
              <ng-select
                class="w-150"
                [disableOptionCentering]="true"
                formControlName="scheduleType"
                (change)="setMinEndDate()"
              >
                <ng-option
                  *ngFor="let type of scheduleTypeList"
                  [value]="type.value"
                >
                  {{ type.name }}
                </ng-option>
              </ng-select>
              <mat-form-field
                *ngIf="
                  formJobRecurring.controls.scheduleType.value ===
                  scheduleType.On
                "
                class="w-150"
                (click)="onpicker.open()"
                appearance="fill"
              >
                <input
                  matInput
                  readonly
                  [matDatepicker]="onpicker"
                  formControlName="endDate"
                  autocomplete="off"
                  required
                  [min]="minEndDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="onpicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #onpicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field
                class="w-50"
                *ngIf="
                  formJobRecurring.controls.scheduleType.value ===
                  scheduleType.After
                "
              >
                <input
                  matInput
                  formControlName="maxNumOfOccurrences"
                  oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                  autocomplete="off"
                  value="1"
                  required
                  maxlength="500"
                  style="text-align: center"
                />
              </mat-form-field>
            </div>
            <p
              class="m-0"
              *ngIf="
                formJobRecurring.controls.scheduleType.value ===
                scheduleType.After
              "
            >
              Job(s)
            </p>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="recuring mt-2">
    <div class="d-flex align-items-center justify-space-between w-636 mb-1">
      <p class="mb-0">Recurring Jobs</p>
      <div class="d-inline-block">
        <a
          href="javascript:void(0)"
          (click)="endRecurring()"
          class="d-flex align-items-center gap-5 hyperlink text-decoration-none"
        >
          End this Recurring
        </a>
      </div>
    </div>
    <div class="content-body">
      <div class="sidenav-table sidenav-table-input">
        <div class="mat-table-wrapper contact-detail-table w-636">
          <table
            mat-table
            [dataSource]="scheduleJobList"
            aria-describedby="User Task Table"
          >
            <ng-container matColumnDef="schedule">
              <th mat-header-cell *matHeaderCellDef>Schedule</th>
              <td mat-cell *matCellDef="let element">
                {{ element.scheduleDate | date : "EEE, dd-MMM-yyyy" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="plus">
              <th
                mat-header-cell
                *matHeaderCellDef
                class="text-align-right fw-normal"
              >
                <div class="d-flex align-items-center justify-content-end">
                  Total occurances
                  <span
                    *ngIf="
                      jobRecurringData.endRecurringTypeId !==
                      endReccuringType.Never
                    "
                  >
                    {{ jobRecurringData.maxNumOfOccurrences ?? 0 }}
                  </span>
                  <span
                    *ngIf="
                      jobRecurringData.endRecurringTypeId ===
                      endReccuringType.Never
                    "
                  >
                    many
                  </span>
                  / Showing
                  {{ scheduleJobList.length ?? 0 }}
                  <mat-icon
                    class="cursor-pointer pl-1"
                    *ngIf="showBulkDelete"
                    (click)="deleteScheduledJob()"
                    >close</mat-icon
                  >
                </div>
              </th>
              <td mat-cell *matCellDef="let element" class="text-align-right">
                <mat-icon
                  class="cursor-pointer"
                  *ngIf="!element.isCreated"
                  (click)="createScheduledJob(element)"
                  >add_circle_outline</mat-icon
                >
                <mat-icon
                  class="cursor-pointer"
                  *ngIf="element.isCreated"
                  (click)="redirectToJob(element)"
                  >search_circle_outline</mat-icon
                >
                <mat-icon
                  class="cursor-pointer pl-10"
                  *ngIf="element.isCreated"
                  (click)="deleteScheduledJob(element)"
                  >close</mat-icon
                >
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td
                class="mat-cell"
                [ngClass]="'text-align-center'"
                [attr.colspan]="displayedColumns.length"
              >
                {{ commonNotificationText.NoRecordsFound }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="action-wrapper custom-action-button"
  *ngIf="formJobRecurring.dirty && !isViewPermission"
>
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      (click)="onSave(false)"
      mat-button
      disableRipple
    >
      Save
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      (click)="onSave(true)"
      mat-button
      disableRipple
    >
      Save and Exit
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Cancel
    </button>
  </div>
</div>
