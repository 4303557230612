import { Guid } from 'guid-typescript';
import { ActionModel } from '../common/grid-action';
import { FileUploadResponseModel } from '../file';

export class JobMainListModel {
  pagination: {
    totalItemCount: number;
  };
  resultSet: {
    columns: Array<{
      id: number;
      isDisabled: boolean;
      isSelected: boolean;
      optionId: number;
      primaryName: string;
      secondaryName: string;
      settingName: string;
      settingTypeId: number;
      settingValue: string;
    }>;
    data: Array<{
      rowData: string;
      moduleId: number;
      isBold: boolean;
      id: Guid;
      alignment: string;
    }>;
    actions: Array<ActionModel>;
  };
}

export class JobRecurring {
  jobId: Guid;
  universalId?: Guid;
  createFirstJobOn?: string | null;
  endDate?: string | null;
  maxNumOfOccurrences?: number;
  separationCount?: number;
  recurringTypeId: number;
  dayOfWeek?: number | null;
  dayOfMonth?: number | null;
  weekOfMonth?: number | null;
  monthOfYear?: number | null;
  isDeleted?: boolean;
  schdeuleId?: string;
  endRecurringTypeId?: number | null;
  subRecurringTypeId?: number | null;
  scheduleJobs?: Array<ScheduleJobsModel>;
}

export class ScheduleJobsModel {
  jobId?: Guid;
  isCreated?: boolean;
  scheduleDate?: string;
  universalId?: Guid;
  createdJobId?: Guid;
}

export class UpdateJobStatusModel {
  universalIds?: Array<Guid>;
  status?: number;
}

export class JobModel {
  universalId?: Guid;
  jobCode?: string;
  clientId?: Guid;
  taskId?: Guid;
  startDate?: string;
  endDate?: string;
  estimatedHours?: number;
  estimatedMinutes?: number;
  feeTypeId?: number;
  status?: number;
  isDemoData?: boolean;
  jobSubtasks?: Array<JobSubTaskModel>;
  users?: Array<Guid>;
  timeAndFeesPrice?: number;
  fixedPrice?: number;
}

export class JobDetailModel {
  universalId?: Guid;
  jobCode?: string;
  clientId?: Guid;
  taskId?: Guid;
  startDate?: string;
  endDate?: string;
  estimatedHours?: number;
  estimatedMinutes?: number;
  feeTypeId?: number;
  timeAndFeesPrice?: number;
  fixedPrice?: number;
  status?: number;
  totalFeeAmount?: number;
  totalSpentAmount?: number;
  totalRemainingAmount?: number;
  totalCostAmount?: number;
  totalHoursSpent?: string;
  totalBillableHours?: string;
  totalNonBillableHours?: string;
  jobSubtasks?: Array<JobSubTaskModel>;
}

export class JobSummaryModel {
  universalId?: Guid;
  jobId?: Guid;
  jobCode?: string;
  startDate?: string;
  endDate?: string;
  estimatedTime?: string;
  loggedTime?: string;
  clientId?: Guid;
  clientName?: string;
  taskId?: Guid;
  taskName?: string;
  totalSubTask?: number;
  totalCompleted?: number;
  score?: number;
  status: number;
}

export class JobFilesModel {
  universalId?: Guid;
  jobId?: Guid;
  fileName: string;
  fileUrl: string;
  createdBy?: string;
  createdOn?: string;
}

export class JobSubTaskModel {
  universalId: Guid;
  jobId?: Guid;
  name: string;
  isBillable: boolean;
  isInclude: boolean;
  isCompleted?: boolean;
}

export class JobUserModel {
  userId: Guid;
  name: string;
  isAssigned: boolean;
  isDisabled: boolean;
}

export class FeesTypeModel {
  feeTypeId?: number;
  name?: string;
  feeAmount?: number;
  isSelected: boolean;
}
export class AllJobsModel {
  universalId: Guid;
  name: string;
  totalIteamCount: number;
}

export class ReminderModel {
  universalId?: Guid;
  sentTo: Array<string>;
  subject: string;
  message: string;
}

export class EmailModel {
  universalId?: Guid;
  sentTo: Array<string>;
  subject: string;
  message: string;
  attachments?: Array<FileUploadResponseModel>;
}

export class JobCommentModel {
  message: string;
}

export class JobEmailModel {
  universalId?: Guid;
  sentOn: string;
  sentBy: string;
  subject: string;
  message: string;
  sentToUsers: string;
  sentTo: Array<string>;
  attachFileName: string;
  fileNames: Array<string>;
  attachFileUrl: Array<string>;
  fileUrls: Array<string>;
}

export class JobCommentListModel {
  jobId: Guid;
  userId: Guid;
  fullName: string;
  message: string;
  universalId?: Guid;
  createdOn?: string;
  userImage?: string;
}

export class JobReminderModel {
  universalId?: Guid;
  jobId?: Guid;
  groupId?: Guid;
  sentFrom?: string;
  sentTo?: Array<string>;
  subject?: string;
  message?: string;
}

export class JobGroupByTimelogModel {
  groupById?: Guid;
  groupByName?: string;
  data?: Array<JobGroupByChildTimelogModel>;
}

export class JobGroupByChildTimelogModel {
  groupById?: Guid;
  childGroupById?: Guid;
  childGroupByName?: string;
  timeSpent: string;
  amount: number;
  cost: number;
}

export class JobGroupByTimelogs {
  groupById: string;
  childGroupById: Guid;
  childGroupByName: string;
  timeSpent: string;
  userCostRate: number;
  userBillableRate: number;
  userBillableAmount: number;
  amount: number;
  cost: number;
  isSelected: boolean;
}

export class JobTaskModel {
  clientId: Guid;
  jobId: Guid;
  taskId: Guid;
  name: string;
}

export class JobInvoiceModel {
  totalItemCount: number;
  jobId: Guid;
  invoiceId: Guid;
  invoiceNo: string;
  date: string;
  dueDays: number;
  amount: number;
  lastReceivedOn: string;
  status: number;
  dueDate: string;
}

export class BulkJobModel {
  universalId?: Guid;
  jobCode?: string;
  clientIds?: Guid[];
  taskId?: Guid;
  startDate?: string;
  endDate?: string;
  estimatedHours?: number;
  estimatedMinutes?: number;
  feeTypeId?: number;
  timeAndFeesPrice?: number;
  fixedPrice?: number;
  status?: number;
  isDemoData?: boolean;
  jobSubtasks?: Array<JobSubTaskModel>;
  users?: Array<Guid>;
  isRecurringJob?: boolean;
  createFirstJobOn?: string | null;
  scheduleEndDate?: string | null;
  recurringTypeId: number;
  endRecurringTypeId?: number | null;
  subRecurringTypeId?: number | null;
  maxNumOfOccurrences?: number;
  separationCount?: number;
  dayOfWeek?: number | null;
  dayOfMonth?: number | null;
  weekOfMonth?: number | null;
  monthOfYear?: number | null;
}
