import { LetsConnectRequestModel } from '@app/core/models/ticket';
import { Guid } from 'guid-typescript';

export class GetAllSupportTickets {
  static readonly type = '[TICKET] Get All Support Tickets';

  constructor(public externalUserGUID: Guid) {}
}

export class SaveSupportTicket {
  static readonly type = '[TICKET] Save Support Ticket';

  constructor(public letsConnectRequestModel: LetsConnectRequestModel) {}
}
