import { ExportType } from '@app/core/enums';
import { Guid } from 'guid-typescript';

export class ReportDetail {
  name: string;
  count: number;
  amount: number;
  percentage: number;
}

export class BarChartSeries {
  name: string;
  data: Array<number>;
}

export class BarChartDetail {
  series: Array<BarChartSeries>;
  categories: Array<string>;
}

export class PieChart {
  series: Array<number>;
  category: Array<string>;
}

export class TimeReport {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeProjectReport>;
}

export class ProjectReport {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeProjectReport>;
}

export class InvoiceReport {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<InvoiceProjectReport>;
}

export class TeammateReport {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TeammateProjectReport>;
}

export class ExpenseReport {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<ExpenseProjectReport>;
}

export class TimeProjectReport {
  clientId: number;
  projectId: number;
  projectName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeTaskReport>;
}

export class InvoiceProjectReport {
  clientId: number;
  projectId: number;
  projectName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeTaskReport>;
}

export class TeammateProjectReport {
  userId: string;
  userfullName: string;
  projectId: number;
  projectName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeTaskReport>;
}

export class ExpenseProjectReport {
  clientId: number;
  projectId: number;
  projectName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<TimeTaskReport>;
}

export class TimeTaskReport {
  projectId: number;
  taskId: number;
  taskName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
}

export class ProjectTaskReport {
  projectId: number;
  taskId: number;
  taskName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
}

export class ExpenseTaskReport {
  projectId: number;
  taskId: number;
  taskName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
}

export class InvoiceTaskReport {
  projectId: number;
  taskId: number;
  taskName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
}

export class TeammateTaskReport {
  projectId: number;
  taskId: number;
  taskName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
}

export class TimeReportSummary {
  totalTeammates: number;
  totalHours: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalBillableAmount: number;
  totalCostRate: number;
}

export class ProjectReportSummary {
  totalClients: number;
  totalProjects: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalBillableAmount: number;
  totalHours: number;
  totalCostRate: number;
}

export class ExpenseReportSummary {
  totalTeammates: number;
  totalBillableAmount: number;
  totalNonBillableAmount: number;
  totalAmount: number;
}

export class InvoiceReportSummary {
  totalInovices: number;
  totalClients: number;
  totalDueAmount: number;
  totalRaisedAmount: number;
  totalReceivedAmount: number;
}

export class TeammateReportSummary {
  totalTeammates: number;
  totalHours: number;
  totalCapacity: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalBillableAmount: number;
  totalCostRate: number;
}

export class TimeCategoryReport {
  id: number;
  Name: string;
  totalHours: number;
  totalBillableHours: number;
  billableAmount: number;
  totalItemCount: number;
}

export class ReportQueryParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string = '';
  sortOrder?: boolean;
  search?: string = '';
  startDate?: string;
  endDate?: string;
  isEmployee?: boolean;
  fileName?: string;
  format?: ExportType;
}

export class UserReportModel {
  userId: Guid;
  userName: string;
  totalCapacity: string;
  totalHours: string;
  remaining: string;
  amount: number;
  cost: number;
  profitAmount: number;
  isSelected: boolean;
  totalCount: number;
}

export class ReportExportParams extends ReportQueryParams {
  format?: ExportType;
  fileName?: string;
  isPrint? = false;
  isView? = false;
  isEmployee?: boolean;
  isFilter? = false;
  subModuleId?: string;
  userIds?: Array<Guid>;
  clientIds?: Array<Guid>;
  jobIds?: Array<Guid>;
  taskIds?: Array<Guid>;
  ids?: Array<Guid>;
  universalIds?: Array<Guid>;
  expenseTypeIds?: Array<Guid>;
  dates?: Array<string>;
  groupBy?: number;
  listStatus?: Array<number>;
}

export class TimeReportModel {
  userId: Guid;
  userName: string;
  totalCapacity: string;
  totalHours: string;
  remaining: string;
  amount: number;
  cost: number;
  profitAmount: number;
  isSelected: boolean;
}

export class TimeReportParameters extends ReportQueryParams {
  isClientWiseReport?: boolean;
  isProjectWiseReport?: boolean;
  isTaskWiseReport?: boolean;
  isTeammatesWiseReport?: boolean;
  isExpenseTypeWiseReport?: boolean;
  expenseTypeIds?: Array<Guid>;
  clientIds?: Array<Guid>;
  jobIds?: Array<Guid>;
  taskIds?: Array<Guid>;
  userIds?: Array<Guid>;
  status?: number;
  groupBy?: number;
  listStatus?: Array<number>;
}

export class InvoiceReportModel {
  totalDueAmount: number;
  clientId: Guid;
  clientName: string;
  createdDate: string;
  invoiceId: Guid;
  invoiceNo: string;
  totalInvoiceAmount: number;
  lastPaidOn: string;
  totalPaidAmount: number;
  status: string;
  totalItemCount: number;
}

export class TimeReportSummaryModel {
  leastProfitableId: Guid;
  leastProfitableName: string;
  leastProfitableAmount: number;
  mostProfitableId: Guid;
  mostProfitableName: string;
  mostProfitableAmount: number;
  mostTimeTakingId: Guid;
  mostTimeTakingName: string;
  mostTimeTakingHours: string;
  leastTimeTakingId: Guid;
  leastTimeTakingName: string;
  leastTimeTakingHours: string;
}

export class UserReportSummaryModel {
  leastProfitableId: Guid;
  leastProfitableName: string;
  leastProfitableAmount: number;
  mostProfitableId: Guid;
  mostProfitableName: string;
  mostProfitableAmount: number;
  mostWorkingId: Guid;
  mostWorkingName: string;
  mostWorkingHours: string;
  leastWorkingId: Guid;
  leastWorkingName: string;
  leastWorkingHours: string;
}
