<div class="side-list-actions" *ngIf="numberOfRecords > 0">
  <div class="sidenav-body-content">
    <div class="mb-2">
      <div class="d-flex align-items-center justify-space-between">
        <p class="text-muted fw-bold m-0">Selected Items</p>
        <button
          mat-button
          class="text-gray-300"
          disableRipple
          (click)="cancelSelection()"
        >
          <mat-icon>highlight_off</mat-icon>
        </button>
      </div>
      <div>
        <p class="font-size-18 fw-bold">
          <span class="text-muted font-size-20">Records:</span>
          {{ numberOfRecords }}
        </p>
        <a
          class="link hyperlink"
          href="javascript:void(0)"
          (click)="cancelSelection()"
          >Cancel Selection</a
        >
      </div>
    </div>
    <div class="hr"></div>
    <div>
      <div
        class="mt-1"
        *ngIf="
          !(
            (getModuleId === modules.TimePFA ||
              getModuleId === modules.TimeApproved ||
              getModuleId === modules.TimeRejected) &&
            isRegularUser
          )
        "
      >
        <p class="font-size-18 fw-bold mb-2">Edit</p>
        <div
          class="d-flex align-items-center"
          *ngIf="
            getModuleId !== modules.TimeUnsubmitted &&
            getModuleId !== modules.TimePFA &&
            getModuleId !== modules.TimeApproved &&
            getModuleId !== modules.TimeRejected
          "
        >
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            *ngIf="
              getModuleId !== modules.Expenses &&
              getModuleId !== modules.Users &&
              getModuleId !== modules.RecurringInvoice &&
              getModuleId !== modules.Jobs
            "
            (click)="copy()"
            name="{{ gridActionsText.Copy }}"
          >
            <mat-icon>content_copy</mat-icon>
            <p>Copy</p>
          </button>
          <button
            class="button-icon copy-archive-button"
            type="button"
            mat-button
            #button
            disableRipple
            *ngIf="getModuleId === modules.Jobs"
            (click)="updateJobStatus(true, false)"
            name="{{ gridActionsText.MarkAsCompleted }}"
          >
            <mat-icon>check_circle</mat-icon>
            <p>Mark as Completed</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            *ngIf="getModuleId === modules.Jobs"
            (click)="updateJobStatus(false, true)"
            name="{{ gridActionsText.MarkAsYetToStart }}"
          >
            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
            <p>Mark As Yet to Start</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            (click)="delete()"
            name="{{ gridActionsText.Delete }}"
          >
            <mat-icon>delete_outline</mat-icon>
            <p>Delete</p>
          </button>
        </div>
        <div
          class="d-flex align-items-center"
          *ngIf="
            getModuleId === modules.TimeUnsubmitted ||
            getModuleId === modules.TimePFA ||
            getModuleId === modules.TimeApproved ||
            getModuleId === modules.TimeRejected
          "
        >
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimeUnsubmitted && !isRegularUser"
            (click)="reminder()"
          >
            <mat-icon fontSet="material-icons-outlined">email</mat-icon>
            <p>Send Reminder</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimeUnsubmitted"
            (click)="submit()"
          >
            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
            <p>Submit for Approval</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimePFA"
            (click)="approve()"
          >
            <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
            <p>Approve</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimePFA"
            (click)="reject()"
          >
            <mat-icon fontSet="material-icons-outlined"
              >playlist_remove</mat-icon
            >
            <p>Reject</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimePFA"
            (click)="sendNote()"
          >
            <mat-icon fontSet="material-icons-outlined">edit_note</mat-icon>
            <p>Send Note</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimeApproved"
            (click)="withdrawApproval()"
          >
            <mat-icon fontSet="material-icons-outlined"
              >drive_file_move_rtl</mat-icon
            >
            <p>Withdraw Approval</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.TimeRejected"
            (click)="withdrawRejection()"
          >
            <mat-icon fontSet="material-icons-outlined"
              >assignment_returned</mat-icon
            >
            <p>Withdraw Rejection</p>
          </button>
        </div>
      </div>
      <div class="mt-2">
        <p class="font-size-18 fw-bold mb-2">Export</p>
        <div class="d-flex align-items-center">
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            (click)="export(exportType.Excel)"
            name="{{ gridActionsText.Excel }}"
          >
            <mat-icon>border_all</mat-icon>
            <p>Excel</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            (click)="export(exportType.CSV)"
            name="{{ gridActionsText.CSV }}"
          >
            <mat-icon fontSet="material-icons-outlined">description</mat-icon>
            <p>CSV</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            (click)="export(exportType.PDF)"
            name="{{ gridActionsText.PDF }}"
          >
            <mat-icon fontSet="material-icons-outlined"
              >picture_as_pdf</mat-icon
            >
            <p>PDF</p>
          </button>
        </div>
      </div>
    </div>

    <div>
      <div class="mt-2 mb-1">
        <p class="font-size-18 fw-bold mb-2">Options</p>
        <div class="d-flex align-items-center">
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            (click)="print()"
            name="{{ gridActionsText.Print }}"
          >
            <mat-icon fontSet="material-icons-outlined">print</mat-icon>
            <p>Print</p>
          </button>
          <button
            *ngIf="getModuleId === modules.Estimate"
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            (click)="statusChanged(feeStatus.Sent)"
          >
            <mat-icon fontSet="material-icons-outlined"
              >check_circle_outline</mat-icon
            >
            <p>Mark As Sent</p>
          </button>
          <button
            *ngIf="getModuleId === modules.Estimate"
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            (click)="statusChanged(feeStatus.Declined)"
          >
            <mat-icon fontSet="material-icons-outlined">highlight_off</mat-icon>
            <p>Mark As Decline</p>
          </button>
          <button
            *ngIf="getModuleId === modules.Estimate"
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            (click)="statusChanged(feeStatus.Accepted)"
          >
            <mat-icon fontSet="material-icons-outlined">beenhere</mat-icon>
            <p>Mark As Accepted</p>
          </button>
          <button
            *ngIf="getModuleId === modules.Estimate"
            class="button-icon"
            type="button"
            mat-button
            disableRipple
            (click)="statusChanged(feeStatus.Revert)"
          >
            <mat-icon fontSet="material-icons-outlined">access_time</mat-icon>
            <p>Revert Status</p>
          </button>
          <button
            class="button-icon"
            type="button"
            mat-button
            #button
            disableRipple
            *ngIf="
              getModuleId !== modules.Invoice &&
              getModuleId !== modules.RecurringInvoice &&
              getModuleId !== modules.Estimate &&
              getModuleId !== modules.Expenses &&
              getModuleId !== modules.Jobs &&
              getModuleId !== modules.TimeUnsubmitted &&
              getModuleId !== modules.TimePFA &&
              getModuleId !== modules.TimeApproved &&
              getModuleId !== modules.TimeRejected
            "
            (click)="archiveAndRestore(true)"
            name="{{ gridActionsText.Archive }}"
          >
            <mat-icon>archive</mat-icon>
            <p>Archive</p>
          </button>
          <button
            class="button-icon copy-archive-button"
            type="button"
            mat-button
            disableRipple
            *ngIf="getModuleId === modules.RecurringInvoice"
            (click)="endRecurringInvoice()"
          >
            <mat-icon fontSet="material-icons-outlined">archive</mat-icon>
            <p>End</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="numberOfRecords === 0">
  <div>
    <div class="timesheet-popup">
      <div class="sidenav-body-content p-0">
        <div class="mat-table-wrapper">
          <table
            mat-table
            [dataSource]="timelog.data"
            aria-describedby="List details table"
          >
            <ng-container
              [matColumnDef]="col"
              *ngFor="let col of displayedColumns; let columnIndex = index"
            >
              <th mat-header-cell *matHeaderCellDef>
                <span *ngIf="col !== 'Close'">
                  {{ col }}
                </span>
              </th>
              <td mat-cell *matCellDef="let element; let rowIndex = index">
                <!-- For 1st column and not for last data of 1st column -->
                <div class="d-flex align-items-center">
                  <span
                    *ngIf="
                      columnIndex === 0 && rowIndex !== timelog.data.length - 1
                    "
                    class="fw-bold onelineclamp text-primary"
                    [innerHTML]="element[columnIndex]"
                    title="{{ element[columnIndex] }}"
                  >
                  </span>
                  <span
                    class="onelineclamp"
                    *ngIf="
                      columnIndex === 1 && rowIndex < timelog.data.length - 1
                    "
                  >
                    {{ element[columnIndex] }}
                  </span>
                </div>
                <!-- For not (1st column && last 2 columns && last Row)-->
                <span
                  *ngIf="
                    columnIndex !== 0 &&
                    columnIndex !== 1 &&
                    columnIndex < displayedColumns.length - 2 &&
                    rowIndex < timelog.data.length - 1
                  "
                >
                  <div class="timepicker timepicker-duration">
                    <div class="timepicker-input">
                      <div class="time-picker d-none">
                        <ngx-timepicker-field
                          [format]="24"
                          [disabled]="true"
                          [(ngModel)]="element[columnIndex]"
                        >
                        </ngx-timepicker-field>
                      </div>
                    </div>
                  </div>
                </span>

                <!-- For last Row or For last secound column-->
                <span
                  *ngIf="
                    rowIndex === timelog.data.length - 1 ||
                    columnIndex === displayedColumns.length - 2
                  "
                >
                  {{ element[columnIndex] }}
                </span>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              [ngClass]="timelog.data ? 'highlightLastRow' : ''"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
