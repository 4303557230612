<span
  *ngIf="
    moduleId === modules.Jobs ? jobElement.length >= 1 : element?.length >= 1
  "
>
  <button
    mat-button
    class="w-auto h-auto"
    [matMenuTriggerFor]="menu1"
    #menuTrigger="matMenuTrigger"
    disableRipple
    (click)="getMoreDetails()"
  >
    <span class="d-flex onelineclamp">
      <span
        class="fw-normal onelineclamp"
        title="{{
          moduleId === modules.Jobs ? jobElement[colIndex] : element[0].name
        }}"
      >
        {{ moduleId === modules.Jobs ? jobElement[colIndex] : element[0].name }}
      </span>
      <span
        class="fw-bold pl-5 white-space-nowrap"
        *ngIf="element?.length > 1 && moduleId !== modules.Jobs"
        >(+{{ element?.length - 1 }})
      </span>
    </span>
  </button>
  <mat-menu [overlapTrigger]="false" #menu1="matMenu" class="contact-detail">
    <span (click)="$event.stopPropagation()">
      <div class="sidenav-body-content">
        <div class="form-field-title">
          <p class="font-size-16 pb-10">
            <strong>{{ headerText }}</strong>
          </p>
        </div>
        <div class="tooltip-list">
          <ul>
            <li *ngFor="let item of element">
              <div class="d-flex">
                <span
                  class="onelineclamp"
                  [ngClass]="
                    moduleId === modules.Jobs && item.isCompleted
                      ? 'text-line-through'
                      : ''
                  "
                  title="{{ item.name }}"
                  >{{ item.name }}</span
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </span>
  </mat-menu>
</span>
