import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteTimelogByWeekModel } from '@app/core/models';
import {
  AddRemoveDemoData,
  ArchiveAndRestoreTask,
  ArchiveAndRestoreUser,
  DeleteSelectedEstimate,
  DeleteSelectedExpense,
  DeleteSelectedTask,
  DeleteSelectedUser,
  DeleteTaskClientByTaskId,
  DeleteTaskUserByTaskId,
  DeleteTimelogByUniversalId,
  DeleteUserClientByUserId,
  DeleteUserTaskByUserId,
  DeleteUserTimeLogByWeek,
  SaveBusinessDynamicColumn,
  UpdateRecurringInvoiceStatus,
} from '@app/core/store';
import {
  DeleteInvoicePayment,
  DeleteSelectedInvoice,
} from '@app/core/store/invoice/invoice.action';
import {
  DeleteSelectedJob,
  UpdateRecurringJobStatus,
} from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ConfirmationType,
  ModuleHeaderText,
  ModuleName,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  RecurringInvoiceAction,
} from 'src/app/core/enums';
import { ConfirmationList } from 'src/app/core/models/common';
import {
  CommonService,
  ExpensesService,
  NotificationService,
  SettingService,
} from 'src/app/core/services';
import {
  ArchiveAndRestoreClient,
  DeleteClientTaskByClientId,
  DeleteClientUserByClientId,
  DeleteSelectedClient,
} from 'src/app/core/store/client/client.action';
import {
  ArchiveAndRestoreExpenseType,
  DeleteSelectedExpenseType,
} from 'src/app/core/store/expense-type/expense-type.action';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
})
export class ConfirmationBoxComponent implements OnInit {
  @Output()
  readonly reloadPage = new EventEmitter<any>();

  @Output()
  readonly invoiceEdited = new EventEmitter<any>();

  @Output()
  readonly invoiceDeleted = new EventEmitter<any>();

  triggerOpenEmail: Subject<number> = new Subject<number>();

  confirmationList: ConfirmationList;
  name: string;
  moduleName: string;
  moduleId: number;
  headerText: string;
  detailText?: string;
  buttonText?: string;
  totalNumberOfRecordSelected: number;
  id: number;
  projectId: string;
  universalId: string;
  universalId1: string;
  ids: Array<number>;
  universalIds: Array<string>;
  universalId1s: Array<string>;
  columnDetails: any[] = [];
  noteText: string;

  confirmationType = ConfirmationType;
  module = ModuleName;
  modules = Modules;
  deleteTimeSheet: DeleteTimelogByWeekModel;
  moduleText: string;
  confirmDelete = false;
  isButtonDisabled = false;
  dynamicColumnForm: FormGroup;
  isApplyToAll = true;
  redirectUrl: string;
  jobId = Guid.EMPTY as unknown as Guid;
  notifier: NotificationService;
  store: Store;
  spinner: NgxSpinnerService;
  commonService: CommonService;
  settingService: SettingService;
  formBuilder: UntypedFormBuilder;
  expenseService: ExpensesService;
  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConfirmationBoxComponent>
  ) {
    this.settingService = this.injector.get<SettingService>(SettingService);
    this.formBuilder =
      this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.expenseService = this.injector.get<ExpensesService>(ExpensesService);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.store = this.injector.get<Store>(Store);
  }

  ngOnInit(): void {
    this.confirmationList = this.data;
    this.moduleName = this.data.moduleName;
    this.moduleId = this.data.moduleId;
    this.headerText = this.data.headerText;
    this.detailText = this.data.detailText;
    this.noteText = this.data.noteText;
    this.id = this.data.id;
    this.universalId = this.data.universalId;
    this.universalId1 = this.data.universalId1;
    this.ids = this.data.ids;
    this.universalId1s = this.data.universalId1s;
    this.universalIds = this.data.universalIds;
    this.totalNumberOfRecordSelected = this.data.totalNumberOfRecordSelected;
    this.deleteTimeSheet = this.data.listOfData;
    this.columnDetails = this.data.value;
    this.redirectUrl = this.data.redirectUrl;
    this.projectId = this.data.projectId;
    this.jobId = this.data.jobId;
    this.dynamicColumnForm = this.formBuilder.group({
      dynamicColumns: new FormControl<boolean>(true),
    });
    this.setModuleText();

    this.confirmationList = this.data;
  }

  onYesClick(): void {
    this.spinner.show();
    if (this.confirmationList.type === ConfirmationType.Archive) {
      this.onArchiveClick();
    }

    if (this.confirmationList.type === ConfirmationType.Delete) {
      this.onDeleteClick();
    }

    if (this.confirmationList.type === ConfirmationType.Demo) {
      this.onDemoDataClick();
    }

    if (this.confirmationList.type === ConfirmationType.Mail) {
      this.onMailIntegrationClick();
    }

    if (this.confirmationList.type === ConfirmationType.StopTimeSheet) {
      this.dialogRef.close(true);
    }

    if (this.confirmationList.type === ConfirmationType.EndSchedule) {
      this.endJobRecurring();
    }

    if (this.confirmationList.moduleName === ModuleName.RecordPayment) {
      this.deleteInvoicePayment();
    }

    if (this.confirmationList.type === ConfirmationType.EndRecurringInvoice) {
      this.endRecurringInvoice();
    }

    if (this.confirmationList.type === ConfirmationType.RenameColumn) {
      this.renameColumn();
    }

    if (
      this.confirmationList.type === ConfirmationType.SaveFeeType ||
      this.confirmationList.type === ConfirmationType.StopTimeSheet ||
      this.confirmationList.type === ConfirmationType.ApplyMinimumChargeableTime
    ) {
      this.dialogRef.close(true);
    }
  }

  endJobRecurring(): void {
    this.store
      .dispatch(new UpdateRecurringJobStatus(this.jobId))
      .pipe(
        tap((res) => {
          if (res.job.isJobRecurringEnded) {
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.scheduleEndedSuccessfully
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        })
      )
      .subscribe(() => this.dialogRef.close('success'));
  }

  endRecurringInvoice(): void {
    this.store
      .dispatch(
        new UpdateRecurringInvoiceStatus(
          this.confirmationList.ids,
          RecurringInvoiceAction.Inactive
        )
      )
      .pipe(
        tap((res) => {
          if (res.recurringInvoice.isRecurringInvoiceUpdated) {
            this.notifier.success(
              NotificationHeader.success,
              NotificationTextMessage.scheduleEndedSuccessfully
            );
          } else {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        })
      )
      .subscribe(() => this.dialogRef.close('success'));
  }

  onDeleteClick(): void {
    let actionName;

    switch (this.confirmationList.moduleId) {
      case Modules.Clients:
        actionName = DeleteSelectedClient;
        break;

      case Modules.ExpenseType:
        actionName = DeleteSelectedExpenseType;
        break;

      case Modules.Tasks:
        actionName = DeleteSelectedTask;
        break;

      case Modules.Users:
        actionName = DeleteSelectedUser;
        break;

      case Modules.Expenses:
        actionName = DeleteSelectedExpense;
        break;

      case Modules.TaskUsers:
        actionName = DeleteTaskUserByTaskId;
        break;

      case Modules.TaskClients:
        actionName = DeleteTaskClientByTaskId;
        break;

      case Modules.ClientTasks:
        actionName = DeleteClientTaskByClientId;
        break;

      case Modules.ClientUsers:
        actionName = DeleteClientUserByClientId;
        break;

      case Modules.UserTasks:
        actionName = DeleteUserTaskByUserId;
        break;

      case Modules.UserClients:
        actionName = DeleteUserClientByUserId;
        break;

      case Modules.TaskSubtask:
        this.dialogRef.close(true);
        break;

      case Modules.Time:
        if (this.confirmationList.id !== (Guid.EMPTY as unknown as Guid)) {
          this.deleteTimelogByUniversalId();
        } else {
          this.deleteUserTimeLogByWeek();
        }
        break;

      case Modules.Jobs:
        actionName = DeleteSelectedJob;
        break;

      case Modules.Invoice:
      case Modules.RecurringInvoice:
        actionName = DeleteSelectedInvoice;
        break;

      case Modules.Estimate:
        actionName = DeleteSelectedEstimate;
        break;
    }

    if (actionName !== undefined) {
      this.store
        .dispatch(new actionName(this.confirmationList.ids))
        .subscribe(() => {
          this.onSuccessDelete();
        });
    } else if (this.confirmationList.moduleId !== Modules.Time) {
      this.spinner.hide();
      if (this.confirmationList.moduleId !== Modules.TaskSubtask) {
        this.dialogRef.close(false);
        this.onFailure();
      }
    }
  }

  onFailure(): void {
    this.notifier.error(
      NotificationHeader.error,
      NotificationTextMessage.errorMessage
    );
  }

  onSuccessDelete(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.recordDeletedSuccessfully
    );
    this.dialogRef.close(true);
  }

  onArchiveAndRestoreSuccess(): void {
    this.notifier.success(
      NotificationHeader.success,
      this.confirmationList.isArchive
        ? NotificationTextMessage.recordArchivedSuccessfully
        : NotificationTextMessage.recordRestoredSuccessfully
    );
    this.dialogRef.close(true);
  }

  onArchiveClick(): void {
    let actionName;

    switch (this.confirmationList.moduleId) {
      case Modules.Clients:
        actionName = ArchiveAndRestoreClient;
        break;

      case Modules.ExpenseType:
        actionName = ArchiveAndRestoreExpenseType;
        break;

      case Modules.Tasks:
        actionName = ArchiveAndRestoreTask;
        break;

      case Modules.Users:
        actionName = ArchiveAndRestoreUser;
        break;
    }

    if (actionName !== undefined) {
      this.store
        .dispatch(
          new actionName(
            this.confirmationList.ids,
            this.confirmationList.isArchive
          )
        )
        .subscribe((data) => {
          data
            ? this.onArchiveAndRestoreSuccess()
            : this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
        });
    } else {
      this.onFailure();
      this.spinner.hide();
      this.dialogRef.close();
    }
  }

  deleteRecordPayment(): void {
    this.store
      .dispatch(new DeleteInvoicePayment(this.universalId))
      .pipe(
        tap(
          (res) => {
            if (res.invoice.isRecordPaymentDelete) {
              this.notifier.success(
                NotificationHeader.success,
                NotificationTextMessage.successMessage
              );
            } else {
              this.notifier.error(
                NotificationHeader.error,
                NotificationTextMessage.errorMessage
              );
            }
          },
          (error) => {
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        )
      )
      .subscribe(() => this.dialogRef.close(true));
  }

  redirectToMail(configType, redirectUrl, provider) {
    let payload = {
      ProviderId: '1',
      configurationJson: configType,
      emailAddress: '',
      returnUrl: redirectUrl,
      ClientId: '1',
      UserId: '1',
      ProviderType: provider,
    };

    this.settingService
      .prepareGoogleOrOfficeInt(payload)
      .subscribe((res: any) => {
        window.open(res, '_self');
      });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onSelectionChange(value: string): void {
    if (value === '1') {
      this.isApplyToAll = true;
    } else if (value === '2') {
      this.isApplyToAll = false;
    }
  }

  onMailIntegrationClick() {
    let redirectUrl, provider, configType;
    switch (this.confirmationList.moduleName) {
      case ModuleName.MailDelete:
        this.settingService.deleteMail(this.id).subscribe((data: any) => {
          this.dialogRef.close(data);
        });
        break;

      case ModuleName.MailIntegration:
        redirectUrl =
          window.location.origin.toString() + '/settings/integration';
        provider = this.id[0] === 1 ? 'google' : 'office';
        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        this.redirectToMail(configType, redirectUrl, provider);
        break;

      case ModuleName.InvoiceMailReAuth:
        redirectUrl = this.ids[1];
        provider = this.ids[0] === 1 ? 'google' : 'office';
        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        this.redirectToMail(configType, redirectUrl, provider);
        break;

      case ModuleName.StripeIntegration:
        redirectUrl = window.location.origin.toString() + this.redirectUrl;
        provider = 'stripe';
        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        let payload = {
          ProviderId: '1',
          configurationJson: configType,
          emailAddress: '',
          returnUrl: redirectUrl,
          ClientId: '1',
          UserId: '1',
          ProviderType: provider,
        };

        this.settingService.integrateStripe(payload).subscribe((res: any) => {
          window.open(res, '_self');
        });
        break;

      case ModuleName.StripeReauth:
        redirectUrl =
          window.location.origin.toString() + '/invoicestype/addInvoices';

        configType =
          this.confirmationList.headerText ==
          NotificationHeader.reauthenticateYourAccount
            ? 'ReAuth'
            : '';
        let reAuthpayload = {
          configurationJson: configType,
          returnUrl: redirectUrl,
        };

        this.settingService
          .reauthentcateStripe(reAuthpayload, this.universalId)
          .subscribe((res: any) => {
            window.open(res, '_self');
          });
        break;

      case ModuleName.StripeDelete:
        this.isButtonDisabled = true;
        this.settingService.disconnectStripe(this.id).subscribe((data: any) => {
          this.dialogRef.close(data.isSuccess);
          this.isButtonDisabled = false;
        });
        break;

      default:
        break;
    }
  }

  withdrawSelectedRecords() {
    if (this.confirmationList.moduleName === ModuleName.Expenses) {
      this.expenseService
        .updateStatusNonAdminExpense(this.confirmationList.id, 2)
        .subscribe((res) => {
          this.reloadPage.emit();
        });
    }
  }

  setModuleText(): void {
    switch (this.moduleId) {
      case Modules.Clients:
        this.moduleText = ModuleHeaderText.Clients;
        break;

      case Modules.Tasks:
        this.moduleText = ModuleHeaderText.Tasks;
        break;

      case Modules.ExpenseType:
        this.moduleText = ModuleHeaderText.ExpenseTypes;
        break;

      case Modules.Users:
        this.moduleText = ModuleHeaderText.Users;
        break;

      case Modules.Jobs:
        this.moduleText = ModuleHeaderText.Jobs;
        break;

      case Modules.Invoice:
        this.moduleText = ModuleHeaderText.Invoices;
        break;

      case Modules.Estimate:
        this.moduleText = ModuleHeaderText.Estimates;
        break;

      case Modules.RecurringInvoice:
        this.moduleText = ModuleHeaderText.RecurringInvoices;
        break;

      default:
        break;
    }
    this.moduleText = this.moduleText?.toLowerCase();
  }

  onDemoDataClick(): void {
    this.store
      .dispatch(new AddRemoveDemoData(this.confirmationList.isAddDemoData!))
      .subscribe(
        () => {
          this.commonService.onSuccess(
            this.confirmationList.isAddDemoData
              ? NotificationTextMessage.demoDataAddedMessage
              : NotificationTextMessage.demoDataRemovedMessage
          );
          this.dialogRef.close(true);
        },
        (err) => {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
          this.dialogRef.close(false);
        }
      );
  }

  deleteUserTimeLogByWeek(): void {
    this.store
      .dispatch(new DeleteUserTimeLogByWeek(this.deleteTimeSheet))
      .subscribe((res: any) => {
        if (res.time.isDeleted) {
          this.onSuccessDelete();
        } else {
          this.onFailure();
          this.dialogRef.close();
        }
      });
  }

  deleteTimelogByUniversalId(): void {
    this.store
      .dispatch(new DeleteTimelogByUniversalId(this.confirmationList.id))
      .subscribe((res: any) => {
        if (res.time.isDeleted) {
          this.onSuccessDelete();
        } else {
          this.onFailure();
          this.dialogRef.close();
        }
      });
  }

  deleteInvoicePayment(): void {
    this.store.dispatch(new DeleteInvoicePayment(this.universalId)).subscribe(
      (res) => {
        if (res.invoice.isRecordPaymentDeleted) {
          this.commonService.onSuccess(
            NotificationTextMessage.scheduleEndedSuccessfully
          );
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
        this.dialogRef.close('success');
      },
      (error) => {
        this.commonService.onFailure(NotificationTextMessage.errorMessage);
        this.dialogRef.close();
      }
    );
  }

  renameColumn(): void {
    if (this.confirmationList.moduleName === ModuleName.RenameColumn) {
      if (this.isApplyToAll) {
        this.store
          .dispatch(
            new SaveBusinessDynamicColumn(Modules.Fees, this.columnDetails)
          )
          .subscribe(() => {
            this.dialogRef.close(true);
          });
      } else {
        this.spinner.hide();
        this.dialogRef.close();
      }
    }
  }
}
