import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DashboardRequestModel } from '@app/core/models';
import { GetBillableAndNonBillableHours } from '@app/core/store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-task-billable-hours',
  templateUrl: './task-billable-hours.component.html',
  styleUrls: ['./task-billable-hours.component.scss'],
})
export class TaskBillableHoursComponent implements OnInit {
  public billableData?: Object[];
  public nonBillableData?: Object[];
  public primaryXAxis?: Object;
  public primaryYAxis?: Object;
  public tooltip?: Object;
  public legend: Object = {
    visible: true,
    enableHighlight: true,
  };
  dashboardRequestModel: DashboardRequestModel;
  filter = 1;
  start: string = '';
  end: string = '';
  @Input()
  triggerFilterData: any;

  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<span>${point.tooltip}</span></span>',
      header: '',
    };
    this.primaryXAxis = {
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      majorTickLines: { width: 0 },
      minorTickLines: { width: 0 },
      interval: 1,
      lineStyle: { width: 0 },
      valueType: 'Category',
    };
    this.primaryYAxis = {
      lineStyle: { width: 0 },
      majorTickLines: { width: 0 },
      majorGridLines: { width: 1 },
      minorGridLines: { width: 1 },
      minorTickLines: { width: 0 },
      labelFormat: '{value}',
    };
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getBillableAndNonBillableHours();
    });
  }

  getBillableAndNonBillableHours(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(new GetBillableAndNonBillableHours(this.dashboardRequestModel))
      .pipe(
        tap((res) => {
          let hoursData = res.dashboard.billableAndNonBillableHours;
          this.billableData = hoursData.billableData;
          this.nonBillableData = hoursData.nonBillableData;
          window.dispatchEvent(new Event('resize'));
        })
      )
      .subscribe();
  }
}
