import { Injectable } from '@angular/core';
import { ImportModel } from '@app/core/models';
import { CommonService } from '@app/core/services';
import { ImportService } from '@app/core/services/import';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  DownloadImportedFile,
  GetCapiumImportSetting,
  GetImportHistory,
  GetImportTemplate,
  ImportModules,
  SaveCapiumImportSetting,
  SaveImport,
} from './import.action';

export class ImportStateInfo {
  exported?: boolean;
  importData?: any;
  saveImportData?: any;
  importedFiles: Array<ImportModel>;
  capiumImportSetting: any;
  saveCapiumImportSettingData?: any;
}

@State<ImportStateInfo>({
  name: 'import',
})
@Injectable()
export class ImportState {
  constructor(
    private importService: ImportService,
    private commonService: CommonService
  ) {}

  @Selector()
  static getImportData(state: ImportStateInfo) {
    return state.importData;
  }

  @Selector()
  static getImportedFiles(state: ImportStateInfo) {
    return state.importedFiles;
  }

  @Action(GetImportTemplate)
  getImportTemplate(
    { patchState }: StateContext<ImportStateInfo>,
    action: GetImportTemplate
  ) {
    return this.importService.getImportTemplate(action.moduleId).pipe(
      tap((res) => {
        patchState({
          exported: res,
        });
      })
    );
  }

  @Action(ImportModules)
  importModules(
    { patchState }: StateContext<ImportStateInfo>,
    action: ImportModules
  ) {
    return this.importService
      .importModules(action.fileImportRequestModel, action.moduleId)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(SaveImport)
  saveImport(
    { patchState }: StateContext<ImportStateInfo>,
    action: SaveImport
  ) {
    return this.importService.saveImport(action.param, action.moduleId).pipe(
      tap((res) => {
        patchState({
          saveImportData: res,
        });
      })
    );
  }

  @Action(GetImportHistory)
  getImportHistory(
    { getState, setState }: StateContext<ImportStateInfo>,
    action: GetImportHistory
  ) {
    return this.importService.getImportHistory().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          importedFiles: res,
        });
      })
    );
  }

  @Action(DownloadImportedFile)
  downloadImportedFile(
    { patchState }: StateContext<ImportStateInfo>,
    action: DownloadImportedFile
  ) {
    return this.importService
      .downloadImportedFile(action.universalId)
      .subscribe((response) => {
        this.commonService.download(response);
      });
  }

  @Action(GetCapiumImportSetting)
  getCapiumImportSetting(
    { patchState }: StateContext<ImportStateInfo>,
    action: GetCapiumImportSetting
  ) {
    return this.importService.getCapiumImportSetting(action.moduleId).pipe(
      tap((res) => {
        patchState({
          capiumImportSetting: res,
        });
      })
    );
  }

  @Action(SaveCapiumImportSetting)
  saveCapiumImportSetting(
    { patchState }: StateContext<ImportStateInfo>,
    action: SaveCapiumImportSetting
  ) {
    return this.importService.saveCapiumImportSetting(action.param).pipe(
      tap((res) => {
        patchState({
          saveCapiumImportSettingData: res,
        });
      })
    );
  }
}
