export enum ProjectTypeEnum {
  TimeAndMaterial = 1,
  FixedPrice = 2,
  NonBillable = 3,
}

export enum ProjectBudgetType {
  NoBudget = 2,
  TotalProjectFees = 3,
  FeePerTask = 4,
  TotalProjectHours = 5,
  HoursPerTask = 6,
  HoursPerPerson = 10,
}

export enum ProjectHourlyType {
  NoHourlyRate = 2,
  ProjectHourlyRate = 3,
  TaskHourlyRate = 4,
  PersonHourlyRate = 6,
}
