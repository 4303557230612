import { Guid } from 'guid-typescript';
import { AppliedTaxModel } from '../tax/taxes';

export class InvoiceBasicInfo {
  universalId?: Guid;
  clientId: Guid;
  userId: Guid;
  invoiceNo: string;
  invoiceDate: string;
  dueDate?: string;
  totalAmount: number;
  paidAmount: number;
  reference?: string;
  externalEstimateId: string;
  invoiceType?: number;
  isDeleted?: boolean;
  isSelected?: boolean;
  status: number;
  isEmailSendFromCapium?: boolean;
  isSendAutomatically?: boolean;
  isRecurring: boolean;
  footer: string;
  isCapiumPayPayment?: boolean;
  invoiceDetails: Array<InvoicesDetail>;
  isSaveAndSend: boolean;
  schedule?: string;
  sentOn?: Date;
  clientFullName?: string;
  discountName?: string;
  discountAmount?: number;
  isDiscountInPercentage?: boolean;
  costName?: string;
  costAmount?: number;
  isCostInPercentage?: boolean;
  paymentDueTermsId?: number;
  invoiceTaxes: Array<AppliedTaxModel>;
  client?: any;
  isTrackable?: boolean;
}

export class InvoicesDetail {
  universalId?: string;
  invoiceId: string;
  type: number;
  projectId?: string;
  taskId?: string;
  projectName: string;
  taskDescription: string;
  hoursAndQuantity?: number;
  price?: number;
  amount?: number;
}
