import { Directive, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appTimeFormat]',
})
export class TimeFormatDirective {
  @Input() allowNegative: boolean = false;

  constructor(private ngModel: NgModel) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = this.formatInputValue(value);
    this.ngModel.valueAccessor?.writeValue(formattedValue);
  }

  private formatInputValue(value: string): string {
    const negativeSign = value.startsWith('-') ? '-' : '';
    const numericValue = value.replace(/[^0-9:]/g, '');
    const parts = numericValue.split(':');
    let hours = parseInt(parts[0], 10);
    let minutes = parseInt(parts[1], 10);

    // Clamp hours between 0 and 23
    hours = Math.min(23, Math.max(0, isNaN(hours) ? 0 : hours));

    // Clamp minutes between 0 and 59
    minutes = Math.min(59, Math.max(0, isNaN(minutes) ? 0 : minutes));

    const formattedHours = this.padNumber(hours);
    const formattedMinutes = this.padNumber(minutes);

    return `${negativeSign}${formattedHours}:${formattedMinutes}`;
  }

  private padNumber(num: number): string {
    return num.toString().padStart(2, '0');
  }
}
