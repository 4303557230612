import { Injectable } from '@angular/core';
import { ActionModel } from '@app/core/models/common/grid-action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  Client,
  ClientUserClientModel,
  Invoices,
  SideListModel,
} from '../../models';
import { ClientService } from '../../services';
import {
  ArchiveAndRestoreClient,
  CopyClient,
  CreateClient,
  DeleteClientTaskByClientId,
  DeleteClientUserByClientId,
  DeleteSelectedClient,
  GetAllClientList,
  GetAllClientsWithContact,
  GetClientDataByClientId,
  GetClientDetailList,
  GetClientInvoicesByClientId,
  GetClientList,
  GetClientOverview,
  GetClientTasksDetails,
  GetClientUsersDetails,
  GetClientsByProjectId,
  GetResetData,
  ImportClients,
  SaveClient,
  SaveClientTask,
  SaveClientUsers,
  SetClientDefaultState,
  SetClientId,
  SetDefaultClientId,
  UpdateClient,
} from './client.action';

export class ClientStateInfo {
  clientList: Array<Client>;
  clientId?: Guid;
  clientData?: Client;
  isLastPage?: boolean;
  sideListModel?: Array<SideListModel>;
  allClient?: Array<SideListModel>;
  isClientAdded?: boolean;
  totalRecord?: number;
  isDataAvailable?: boolean;
  clientByProjectId?: Client;
  highlightIDs?: Array<string>;
  importData?: any;
  clientUsersData?: Array<any>;
  clientTasksData?: Array<ClientUserClientModel>;
  clientsUsersDataLength: number;
  clientsTaskDataLength: number;
  resetData?: any;
  isDelete?: boolean;
  isClientAssignedToUser?: boolean;
  isTaskAssignedToClient?: boolean;
  clientOverview?: any;
  isSuccess?: boolean;
  invoiceList: Invoices[];
  gridActions?: ActionModel[];
  totalRecordOfClientUsers?: number;
  totalRecordOfClientTask?: number;
}

@State<ClientStateInfo>({
  name: 'client',
  defaults: {
    clientList: [],
    clientsUsersDataLength: 0,
    clientsTaskDataLength: 0,
    isDataAvailable: true,
    clientUsersData: [],
    invoiceList: [],
  },
})
@Injectable()
export class ClientState {
  constructor(private clientService: ClientService) {}

  @Selector()
  static totalRecordOfClientUsers(state: ClientStateInfo) {
    return state.totalRecordOfClientUsers;
  }

  @Selector()
  static totalRecordOfClientTask(state: ClientStateInfo) {
    return state.totalRecordOfClientTask;
  }

  @Selector()
  static getClientUsersDetails(state: ClientStateInfo) {
    return state.clientUsersData ?? [];
  }

  @Selector()
  static getClientTasksDetails(state: ClientStateInfo) {
    return state.clientTasksData ?? [];
  }

  @Selector()
  static getClientsUsersDataLength(state: ClientStateInfo) {
    return state.clientsUsersDataLength;
  }

  @Selector()
  static getClientsTaskDataLength(state: ClientStateInfo) {
    return state.clientsTaskDataLength;
  }

  @Selector()
  static getAllClientList(state: ClientStateInfo) {
    return state.clientList;
  }

  @Selector()
  static getClientId(state: ClientStateInfo) {
    return state.clientId;
  }

  @Selector()
  static getClientDataByClientId(state: ClientStateInfo) {
    return state.clientData;
  }

  @Selector()
  static isLastPage(state: ClientStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static isDataAvailable(state: ClientStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static totalRecord(state: ClientStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static getClientList(state: ClientStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getAllClients(state: ClientStateInfo) {
    return state.allClient;
  }

  @Selector()
  static getClientsByProjectId(state: ClientStateInfo) {
    return state.clientByProjectId;
  }

  @Selector()
  static isClientAdded(state: ClientStateInfo): boolean {
    return state.isClientAdded ?? false;
  }

  @Selector()
  static getHighlightedIds(state: ClientStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: ClientStateInfo) {
    return state.importData;
  }

  @Selector()
  static getGridActions(state: ClientStateInfo) {
    return state.gridActions;
  }

  @Action(SetDefaultClientId)
  setDefaultClientId({ patchState }: StateContext<ClientStateInfo>) {
    patchState({
      clientId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(GetClientOverview, { cancelUncompleted: true })
  getClientOverview(
    { getState, setState }: StateContext<ClientStateInfo>,
    action: GetClientOverview
  ) {
    return this.clientService
      .getClientOverview(action.clientId, action.monthNumber)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            clientOverview: res,
          });
        })
      );
  }

  @Action(SetClientId)
  setClientId(
    { patchState }: StateContext<ClientStateInfo>,
    action: SetClientId
  ) {
    patchState({
      clientId: action.clientId,
    });
  }

  @Action(GetClientDetailList, { cancelUncompleted: true })
  getClientDetailList(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: GetClientDetailList
  ) {
    return this.clientService.getClientDetailList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;
        patchState({
          ...state,
          clientList: res.body!.data,
          gridActions: res.body!.actions,
          totalRecord,
          isDataAvailable,
        });
      })
    );
  }

  @Action(CreateClient)
  createClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: CreateClient
  ) {
    return this.clientService.createClient(action.client).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.clientList,
          clientId: res,
          isClientAdded: true,
        });
      })
    );
  }

  @Action(GetClientDataByClientId)
  getClientDataByClientId(
    { getState, setState }: StateContext<ClientStateInfo>,
    action: GetClientDataByClientId
  ) {
    return this.clientService.getClientById(action.clientId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          clientId: action.clientId,
          clientData: res,
        });
      })
    );
  }

  @Action(UpdateClient)
  updateClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: UpdateClient
  ) {
    return this.clientService.updateClient(action.client).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.clientList,
          clientList: res,
          isClientAdded: false,
        });
      })
    );
  }

  @Action(DeleteSelectedClient)
  deleteSelectedClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: DeleteSelectedClient
  ) {
    return this.clientService.deleteClients(action.clientIds).pipe(
      tap(() => {
        const state = getState();

        const filteredClients = state.clientList.filter(
          (item) =>
            !action.clientIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.clientIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.clientList,
          clientList: filteredClients,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(GetClientList, { cancelUncompleted: true })
  getClientList(
    { getState, setState }: StateContext<ClientStateInfo>,
    action: GetClientList
  ) {
    return this.clientService.getClientList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let clientData: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          clientData = state.sideListModel!;
          clientData = clientData.concat(res.body!);
        } else {
          clientData = res.body;
        }

        setState({
          ...state,
          sideListModel: clientData!,
          isLastPage,
        });
      })
    );
  }

  @Action(GetAllClientList)
  getAllClientList(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetAllClientList
  ) {
    return this.clientService.getAllClientList().pipe(
      tap((res) => {
        patchState({
          allClient: res,
        });
      })
    );
  }

  @Action(CopyClient)
  copyClient(
    { patchState }: StateContext<ClientStateInfo>,
    action: CopyClient
  ) {
    return this.clientService.copy(action.clientIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreClient)
  archiveRestoreClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: ArchiveAndRestoreClient
  ) {
    return this.clientService
      .archiveAndRestoreClient(action.clientIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state.clientList,
            clientList: state.clientList,
          });
        })
      );
  }

  @Action(GetClientsByProjectId)
  getClientsByProjectId(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetClientsByProjectId
  ) {
    return this.clientService.getClientByProjectId(action.projectId).pipe(
      tap((res) => {
        patchState({
          clientByProjectId: res,
        });
      })
    );
  }
  @Action(GetAllClientsWithContact)
  getAllClientsWithContact({ patchState }: StateContext<ClientStateInfo>) {
    return this.clientService.getAllClientsWithContact().pipe(
      tap((res) => {
        patchState({
          clientList: res,
        });
      })
    );
  }

  @Action(ImportClients)
  importClients(
    { patchState }: StateContext<ClientStateInfo>,
    action: ImportClients
  ) {
    return this.clientService.importClients(action.fileImportRequestModel).pipe(
      tap((res) => {
        patchState({
          importData: res,
        });
      })
    );
  }

  @Action(SetClientDefaultState)
  setClientDefaultState({ patchState }: StateContext<ClientStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(GetClientTasksDetails)
  getClientTasksDetails(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetClientTasksDetails
  ) {
    return this.clientService.getClientTasksDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfClientTask = headers.TotalItemCount;
        patchState({
          totalRecordOfClientTask,
          clientTasksData: res.body!,
          clientsTaskDataLength: res.body!.length,
        });
      })
    );
  }

  @Action(GetResetData)
  getResetData(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetResetData
  ) {
    return this.clientService.getResetData(action.resetParams).pipe(
      tap((res) => {
        patchState({
          resetData: res,
        });
      })
    );
  }

  @Action(GetClientUsersDetails)
  getClientUsersDetails(
    { patchState }: StateContext<ClientStateInfo>,
    action: GetClientUsersDetails
  ) {
    return this.clientService.getClientUsersDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfClientUsers = headers.TotalItemCount;
        patchState({
          totalRecordOfClientUsers,
          clientUsersData: res.body,
          clientsUsersDataLength: res.body.length,
        });
      })
    );
  }

  @Action(SaveClient)
  saveClient(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: SaveClient
  ) {
    return this.clientService.saveClient(action.client).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          clientId: res.data,
        });
      })
    );
  }

  @Action(SaveClientUsers)
  saveClientUsers(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: SaveClientUsers
  ) {
    return this.clientService
      .saveClientUsers(action.clientId, action.client)
      .pipe(
        tap((res) => {
          patchState({
            isClientAssignedToUser: res,
          });
        })
      );
  }

  @Action(SaveClientTask)
  saveClientTask(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: SaveClientTask
  ) {
    return this.clientService
      .saveClientTask(action.clientId, action.client)
      .pipe(
        tap((res) => {
          patchState({
            isTaskAssignedToClient: res,
          });
        })
      );
  }

  @Action(DeleteClientTaskByClientId)
  deleteClientTaskByClientId(
    { patchState, setState }: StateContext<ClientStateInfo>,
    action: DeleteClientTaskByClientId
  ) {
    return this.clientService.deleteClientTaskByClientId(action.clientId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(DeleteClientUserByClientId)
  deleteClientUserByClientId(
    { patchState, setState }: StateContext<ClientStateInfo>,
    action: DeleteClientTaskByClientId
  ) {
    return this.clientService.deleteClientUserByClientId(action.clientId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(GetClientInvoicesByClientId, { cancelUncompleted: true })
  getClientInvoicesByClientId(
    { getState, patchState }: StateContext<ClientStateInfo>,
    action: GetClientInvoicesByClientId
  ): any {
    return this.clientService
      .getClientInvoicesByClientId(action.clientId, action.invoiceParams)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            invoiceList: res,
          });
        })
      );
  }
}
