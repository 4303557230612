import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CommonNotificationText,
  ConfirmationType,
  EndReccuringType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  PermissionType,
  RecurringType,
  ScheduleSubPeriod,
  ScheduleType,
} from '@app/core/enums';
import { JobRecurring, ScheduleJobsModel } from '@app/core/models';
import { CommonService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/shared/components';
import {
  CreateScheduledJob,
  DeleteJobByScheduleId,
  GetJobRecurring,
  SaveJobRecurringSetting,
} from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-job-details-recurring',
  templateUrl: './job-details-recurring.component.html',
  styleUrls: ['./job-details-recurring.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class JobDetailsRecurringComponent implements OnInit {
  displayedColumns: string[] = ['schedule', 'plus'];
  formJobRecurring: FormGroup;
  recurringType = RecurringType;
  scheduleSubPeriod = ScheduleSubPeriod;
  scheduleType = ScheduleType;
  weekList: string[] = [];
  monthList: string[] = [];
  dayList: any[] = [];
  isDirty = false;
  minEndDate: Date;
  minStartDate: Date;
  scheduleJobsModel: ScheduleJobsModel;
  scheduleJobList: ScheduleJobsModel[] = [];
  jobRecurringData: JobRecurring;
  commonNotificationText = CommonNotificationText;
  recurringTypeList: any[] = [
    {
      value: RecurringType.Never,
      name: 'Never',
    },
    {
      value: RecurringType.Daily,
      name: 'Daily',
    },
    {
      value: RecurringType.Weekly,
      name: 'Weekly',
    },
    {
      value: RecurringType.Monthly,
      name: 'Monthly',
    },
    {
      value: RecurringType.Yearly,
      name: 'Yearly',
    },
    {
      value: RecurringType.Custom,
      name: 'Custom',
    },
  ];

  scheduleSubPeriodList: any[] = [
    {
      value: ScheduleSubPeriod.Day,
      name: 'Day(s)',
    },
    {
      value: ScheduleSubPeriod.Week,
      name: 'Week(s)',
    },
    {
      value: ScheduleSubPeriod.Month,
      name: 'Month(s)',
    },
    {
      value: ScheduleSubPeriod.Year,
      name: 'Year(s)',
    },
  ];

  scheduleTypeList: any[] = [
    {
      value: ScheduleType.After,
      name: 'After',
    },
    {
      value: ScheduleType.On,
      name: 'On',
    },
    {
      value: ScheduleType.Never,
      name: 'Never',
    },
  ];

  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  jobId = this.defaultUniversalId;
  recurringId = this.defaultUniversalId;
  permissionType = PermissionType;
  isViewPermission = false;
  moduleId = Modules.JobRecurring;
  jobIds: Guid[] = [];
  showBulkDelete = false;

  @Input()
  triggerJobId: Observable<any>;

  @Output()
  readonly triggerIsRecurringExist = new EventEmitter<any>();

  jobRecurringSubscription: Subscription;
  endReccuringType = EndReccuringType;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datepipe: DatePipe,
    private store: Store,
    public commonService: CommonService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService
  ) {
    this.minStartDate = new Date();
    this.minStartDate.setDate(this.minStartDate.getDate() + 1);
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.setScheduleForm();

    this.bindMonthList();

    this.bindWeekList();

    this.bindDayList();

    this.jobRecurringSubscription = this.triggerJobId.subscribe((data) => {
      this.jobId = data;
      this.getJobRecurring();
    });

    this.isViewPermission = this.commonService.isViewPermission(this.moduleId);
  }

  ngOnDestroy(): void {
    this.jobRecurringSubscription?.unsubscribe();
  }

  createScheduledJob(element): void {
    this.scheduleJobsModel = {
      jobId: this.jobId,
      scheduleDate: element.scheduleDate,
      universalId: element.universalId,
    };
    this.store
      .dispatch(new CreateScheduledJob(this.scheduleJobsModel))
      .pipe()
      .subscribe((res) => {
        if (res.job.isScheduledJobCreated) {
          this.getJobRecurring();
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  redirectToJob(element): void {
    this.commonService.onEditRouting(Modules.Jobs, element.createdJobId);
  }

  getJobRecurring(): void {
    this.store
      .dispatch(new GetJobRecurring(this.jobId))
      .pipe(
        tap((res) => {
          this.jobRecurringData = res.job.recurringData;
          this.scheduleJobList = this.jobRecurringData.scheduleJobs
            ? this.jobRecurringData.scheduleJobs
            : [];
          this.triggerIsRecurringExist.emit(
            this.scheduleJobList?.length ? true : false
          );

          this.showBulkDelete = false;
          if (this.scheduleJobList.length > 0) {
            this.showBulkDelete = this.scheduleJobList?.some(
              (x) => x.isCreated
            );
          }

          if (this.jobRecurringData) {
            this.setFormValues();
          } else {
            this.setScheduleForm();
          }
        })
      )
      .subscribe();
  }

  setFormValues(): void {
    if (Object.keys(this.jobRecurringData).length > 0) {
      this.recurringId = this.jobRecurringData.universalId!;
      this.formJobRecurring.patchValue({
        recurringType: this.jobRecurringData.recurringTypeId
          ? Number(this.jobRecurringData.recurringTypeId)
          : 1,
        dayOfWeek: this.jobRecurringData.dayOfWeek
          ? Number(this.jobRecurringData.dayOfWeek)
          : 1,
        dayOfMonth: this.jobRecurringData.dayOfMonth
          ? Number(this.jobRecurringData.dayOfMonth)
          : 1,
        separationCount: this.jobRecurringData.separationCount
          ? Number(this.jobRecurringData.separationCount)
          : 1,
        monthOfYear: this.jobRecurringData.monthOfYear
          ? Number(this.jobRecurringData.monthOfYear)
          : 1,
        startDate: this.jobRecurringData.createFirstJobOn,
        endDate: this.jobRecurringData.endDate,
        scheduleType: this.jobRecurringData.endRecurringTypeId ?? 1,
        maxNumOfOccurrences: this.jobRecurringData.maxNumOfOccurrences ?? 1,
        scheduleSubPeriod: this.jobRecurringData.subRecurringTypeId ?? 1,
      });
    }
  }

  getDayOfWeek() {
    return this.formJobRecurring.controls.recurringType.value ===
      RecurringType.Weekly ||
      (this.formJobRecurring.controls.recurringType.value ===
        RecurringType.Custom &&
        this.formJobRecurring.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Week)
      ? +this.formJobRecurring.controls.dayOfWeek.value
      : null;
  }

  getDayOfMonth() {
    return this.formJobRecurring.controls.recurringType.value ===
      RecurringType.Monthly ||
      this.formJobRecurring.controls.recurringType.value ===
        RecurringType.Yearly ||
      (this.formJobRecurring.controls.recurringType.value ===
        RecurringType.Custom &&
        (this.formJobRecurring.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Month ||
          this.formJobRecurring.controls.scheduleSubPeriod.value ===
            ScheduleSubPeriod.Year))
      ? +this.formJobRecurring.controls.dayOfMonth.value
      : null;
  }

  getMonthOfYear() {
    return this.formJobRecurring.controls.recurringType.value ===
      RecurringType.Yearly ||
      (this.formJobRecurring.controls.recurringType.value ===
        RecurringType.Custom &&
        this.formJobRecurring.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Year)
      ? +this.formJobRecurring.controls.monthOfYear.value
      : null;
  }

  dataSubmit() {
    this.spinner.show();
    try {
      this.jobRecurringData = {
        universalId: this.recurringId,
        jobId: this.jobId,
        createFirstJobOn:
          this.datepipe.transform(
            this.formJobRecurring.controls.startDate.value,
            'yyyy-MM-dd'
          ) ?? null,
        endDate:
          this.formJobRecurring.controls.scheduleType.value === ScheduleType.On
            ? this.datepipe.transform(
                this.formJobRecurring.controls.endDate.value,
                'yyyy-MM-dd'
              )
            : null,
        maxNumOfOccurrences:
          this.formJobRecurring.controls.scheduleType.value ===
          ScheduleType.After
            ? +this.formJobRecurring.controls.maxNumOfOccurrences.value
            : 0,
        separationCount:
          this.formJobRecurring.controls.recurringType.value ===
          RecurringType.Custom
            ? +this.formJobRecurring.controls.separationCount.value
            : 0,
        recurringTypeId: +this.formJobRecurring.controls.recurringType.value,
        dayOfWeek: this.getDayOfWeek(),
        dayOfMonth: this.getDayOfMonth(),
        monthOfYear: this.getMonthOfYear(),
        endRecurringTypeId: this.formJobRecurring.controls.scheduleType.value,
        subRecurringTypeId:
          this.formJobRecurring.controls.recurringType.value ===
          RecurringType.Custom
            ? this.formJobRecurring.controls.scheduleSubPeriod.value
            : null,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit: boolean): void {
    if (this.formJobRecurring.invalid) {
      this.commonService.addValidation(this.formJobRecurring);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new SaveJobRecurringSetting(this.jobRecurringData))
          .subscribe((res) => {
            if (res.job.recurringId) {
              this.getJobRecurring();
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
              if (isExit) this.commonService.onListRouting(Modules.Jobs);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          });
      }
    }
  }

  endRecurring(): void {
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          jobId: this.jobId,
          type: ConfirmationType.EndSchedule,
          moduleName: ModuleName.EndSchedule,
          headerText: NotificationHeader.endJobRecurringConfirmation,
          detailText: NotificationDetails.endRecurringJobDetailText,
        },
      })
      .afterClosed()
      .subscribe(() => {
        this.getJobRecurring();
      });
  }

  setScheduleForm(): void {
    this.recurringId = this.defaultUniversalId;
    this.formJobRecurring = this.formBuilder.group({
      recurringType: new FormControl<number | null>(1),
      scheduleSubPeriod: new FormControl<number | null>(1),
      dayOfWeek: new FormControl<number | null>(1),
      dayOfMonth: new FormControl<number | null>(1),
      separationCount: new FormControl<number | null>(1),
      maxNumOfOccurrences: new FormControl<number | null>(1),
      monthOfYear: new FormControl<number | null>(1),
      startDate: new FormControl<Date | null>(
        new Date(Date.now() + 3600 * 1000 * 24)
      ),
      endDate: new FormControl<Date | null>(new Date()),
      scheduleType: new FormControl<number | null>(0),
    });
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
  }

  bindWeekList(): void {
    this.weekList = Array.from({ length: 7 }, (e, i) => {
      return new Date(0, 0, i).toLocaleDateString('en', {
        weekday: 'long',
      });
    });
  }

  bindDayList(): void {
    for (let i = 1; i <= 31; i++) {
      let label = '';
      if (i === 1 || i === 21 || i === 31) {
        label = i + 'st';
      } else if (i === 2 || i === 22) {
        label = i + 'nd';
      } else if (i === 3 || i === 23) {
        label = i + 'rd';
      } else if (i >= 4) {
        label = i + 'th';
      }

      const obj = {
        value: i,
        lable: label,
      };
      this.dayList.push(obj);
    }
    this.formJobRecurring.controls.dayOfMonth.setValue(this.dayList[0].value);
  }

  setMinEndDate(): void {
    if (this.formJobRecurring.controls.scheduleType.value === ScheduleType.On) {
      this.minEndDate = new Date(
        this.formJobRecurring.controls.startDate.value
      );
      this.minEndDate.setDate(this.minEndDate.getDate() + 1);
      this.formJobRecurring.controls.endDate.setValue(this.minEndDate);
    }
  }

  deleteScheduledJob(element?: any): void {
    this.getSelectedIds(element);

    if (this.jobIds.length > 0) {
      this.store
        .dispatch(new DeleteJobByScheduleId(this.jobIds))
        .pipe()
        .subscribe((res) => {
          if (res.job.isScheduleJobDeleted) {
            this.getJobRecurring();
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        });
    }
  }

  getSelectedIds(element?: any): void {
    this.jobIds = [];
    if (element) {
      this.jobIds.push(element.universalId);
    } else {
      this.scheduleJobList.forEach((element) => {
        if (element.isCreated && element.universalId) {
          this.jobIds.push(element.universalId);
        }
      });
    }
  }
}
