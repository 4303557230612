<div class="action-wrapper" *ngIf="!isViewMode">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@save'"
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndExits'"
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@saveAndInvite'"
      (click)="onSave(true, false, true)"
      *ngIf="getModuleId === modules.Users"
    >
      Save and Invite
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      [ngShortcut]="'@cancel'"
      (click)="onCancel()"
    >
      Cancel Changes
    </button>
  </div>
</div>
<div class="action-wrapper" *ngIf="getModuleId === modules.Expenses">
  <div class="sidenav-body-content">
    <button
      class="action-button primary-button black-btn mr-1"
      type="button"
      (click)="onSave(false, false)"
      mat-button
      disableRipple
    >
      Save
    </button>
    <button
      class="action-button primary-button black-btn mr-1"
      type="button"
      (click)="onSave(true, false)"
      mat-button
      disableRipple
    >
      Save and Exit
    </button>
    <button
      class="action-button primary-button black-btn mr-1"
      type="button"
      (click)="onSave(false, true)"
      mat-button
      disableRipple
    >
      <span>Save and Submit</span>
    </button>

    <button
      class="action-button secondary-button default-btn mr-1"
      (click)="onCancel()"
      type="button"
      mat-button
      disableRipple
    >
      Cancel Changes
    </button>
  </div>
</div>
<div class="action-wrapper" *ngIf="isViewMode">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onUpdateStatus(actionType.Play)"
    >
      Play
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onUpdateStatus(actionType.End)"
    >
      End
    </button>
  </div>
</div>
<ng-shortcut></ng-shortcut>
