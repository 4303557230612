import { Guid } from 'guid-typescript';
import { ActionModel } from '../common/grid-action';

export class ExpenseTypeGridModel {
  totalItemCount: number;
  data: Array<ExpenseType>;
  actions: Array<ActionModel>;
}

export class ExpenseType {
  universalId?: Guid;
  name: string;
  hasUnitPrice: boolean;
  isArchived?: boolean;
  totalRecords?: number;
  isSelected?: boolean = false;
  expenseTypeCode?: string;
}
