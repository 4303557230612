import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentMethod } from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createPaymentMethod(client: PaymentMethod): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/PaymentMethod/create`,
      JSON.stringify(client),
      headers
    );
  }

  getPaymentMethod(
    page: number,
    pageSize: number,
    sortBy?: string,
    sortOrder?: boolean,
    searchText?: string,
    filter?: number
  ): Observable<HttpResponse<Array<PaymentMethod>>> {
    return this.http.get<Array<PaymentMethod>>(
      `${environment.apiVersionUrl}/PaymentMethod/list?pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchText}&filter=${filter}`,
      {
        observe: 'response',
      }
    );
  }

  archivePaymentMethod(roleId: number, isArchive: boolean): Observable<void> {
    return this.http.put<void>(
      `${environment.apiVersionUrl}/PaymentMethod/archive/${roleId}/${isArchive}`,
      null
    );
  }
  getPaymentMethodById(roleId: number): Observable<PaymentMethod> {
    return this.http.get<PaymentMethod>(
      `${environment.apiVersionUrl}/PaymentMethod/getPaymentMethodById/${roleId}`
    );
  }

  deletePaymentMethod(roleId: number): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiVersionUrl}/PaymentMethod/delete/${roleId}`
    );
  }

  updatePaymentMethod(roles: PaymentMethod): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/PaymentMethod/update`,
      JSON.stringify(roles),
      headers
    );
  }

  exportPaymentMethod(
    format: number,
    fileName: string,
    filter?: number,
    sortBy?: string,
    sortOrder?: boolean,
    searchText?: string
  ): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${environment.apiVersionUrl}/PaymentMethod/export?format=${format}&fileName=${fileName}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchText}&filter=${filter}`
    );
  }

  getPaymentMethods(): Observable<Array<PaymentMethod>> {
    return this.http.get<Array<PaymentMethod>>(
      `${environment.apiVersionUrl}/PaymentMethod/listPaymentMethods`
    );
  }
}
