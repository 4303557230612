<ejs-chart
  *ngIf="chartData?.length !== 0"
  allowResizing="true"
  class="byuser-box"
  id="chart-containers"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [title]="title"
  [tooltip]="tooltip"
>
  <e-series-collection>
    <e-series
      fill="#7CB5EC"
      columnWidth="0.4"
      [dataSource]="chartData"
      type="Column"
      xName="x"
      yName="y"
      tooltipMappingName="tooltipMappingName"
    ></e-series>
  </e-series-collection>
</ejs-chart>
<div class="norecord-chart" *ngIf="chartData?.length === 0">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
