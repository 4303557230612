import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PaymentDueTerms } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class PaymentDueService {
  constructor(private http: HttpClient) {}

  createPaymentDueTerms(PaymentDueData: PaymentDueTerms): Observable<number> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<number>(
      `${environment.apiVersionUrl}/paymentDueTerms/paymentDueTerm`,
      JSON.stringify(PaymentDueData),
      headers
    );
  }

  getPaymentDueList(): Observable<Array<PaymentDueTerms>> {
    return this.http.get<Array<PaymentDueTerms>>(
      `${environment.apiVersionUrl}/paymentDueTerms/list`
    );
  }
}
