export class TeamAndTaskTodayModel {
  clientName: string;
  projectName: string;
  taskName: string;
  userFullName: string;
  projectEndDate: Date;
  totalTodayHours: number;
  totalTrackedHours: number;
}

export class TaskWithMeTodayModel {
  userId: string;
  projectAndClient: string;
  taskName: string;
  projectEndDate: Date;
}

export class ProjectDeadlineStatusModel {
  dueDays: string;
  totalProjects: number;
}

export class InProgressProjectByTypeModel {
  projectTypeId: number;
  projectTypeName: string;
  totalProjects: number;
}

export class ProjectStatusByTimeCostAndExpenseModel {
  clientId: string;
  projectId: string;
  projectName: string;
  totalHours: number;
  totalCost: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
  totalExpense: number;
}

export class IncomeByTopFiveProjectModel {
  projectId: string;
  projectName: string;
  totalIncome: number;
}

export class IncomeByClientModel {
  clientId: string;
  clientName: string;
  totalIncome: number;
}

export class IncomeByProjectTypeModel {
  projectTypeId: string;
  projectTypeName: string;
  totalIncome: number;
}

export class BillableTeammateHoursModel {
  userFullName: string;
  totalBillableHours: number;
}

export class BillableAndNonBillableHoursByMonthModel {
  monthnumber: number;
  monthName: string;
  totalHours: number;
  totalBillableHours: number;
  totalNonBillableHours: number;
}

export class IncomeByPaymentMethodModel {
  monthnumber: number;
  monthName: string;
  totalAmount: number;
  bankPayment: number;
  cash: number;
  cheque: number;
  creditcard: number;
  other: number;
  paypal: number;
}

export class TeammatesHoursByClientModel {
  clientId: string;
  clientName: string;
  totalHours: number;
  data: Array<ClientUserModel>;
}

export class ClientUserModel {
  userId: string;
  userFullName: string;
}

export class TotalHoursByClientModel {
  clientId: string;
  clientName: string;
  totalHours: number;
  data: Array<TotalHoursByProjectModel>;
}

export class TotalHoursByProjectModel {
  clientId: string;
  projectId: string;
  projectName: string;
  totalHours: number;
  data: Array<TotalHoursByUserModel>;
}

export class TotalHoursByUserModel {
  userId: string;
  userFullName: string;
  totalHours: number;
}

export class ProjectByTeammateListModel {
  userId: string;
  userFullName: string;
  totalHours: number;
  isSelected: boolean;
}

export class BillableHoursByQuarterModel {
  quarterNumber: number;
  quarterName: string;
  totalBillableHours: number;
  data: Array<BillableHoursByClientModel>;
}

export class BillableHoursByClientModel {
  quarterNumber: number;
  clientId: string;
  clientName: string;
  totalBillableHours: number;
}

export class BillableHoursByClientListModel {
  clientId: string;
  clientName: string;
  totalBillableHours: number;
  isSelected: boolean;
}

export class TimeSheetSummaryByStatusModel {
  userId: string;
  clientId: string;
  projectId: string;
  userFullName: string;
  userType: string;
  projectName: string;
  totalLoggedHours: number;
  totalUnSubmittedHours: number;
  totalPFAHours: number;
  totalApprovedHours: number;
  capacity: number;
}

export class TimeOffByTeammateModel {
  userId: string;
  userFullName: string;
  totalHours: number;
  totalTimeOff: number;
}

export class InvoiceRaisedHistoryModel {
  weekNumber: number;
  weekName: string;
  weekStartDate: Date;
  weekEndDate: Date;
  raiseInvoice: number;
}

export class ProjectCountByStatusModel {
  status: string;
  totalProjects: number;
}

export class ProjectManageByUserModel {
  clientId: string;
  projectId: string;
  projectName: string;
  clientName: string;
  currentTask: string;
  data: Array<ProjectManagerUserModel>;
}

export class ProjectManagerUserModel {
  projectId: string;
  useId: string;
  userFullName: string;
}
