import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Modules } from '@app/core/enums';
import {
  TimeLogModel,
  TimeParameters,
  TimelogParameter,
  UserWeekTimelogOverviewModel,
} from '@app/core/models';
import { MaxRangeSelectionStrategy } from '@app/core/models/common/max-range-selection-strategy';
import {
  GetStartWeekAndEndWeek,
  GetUserWeekTimelogOverview,
  GetUserWeekTimelogs,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import {
  ApexChart,
  ApexFill,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
  ChartComponent,
} from 'ng-apexcharts';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  fill: ApexFill;
  plotOptions: ApexPlotOptions;
};

@Component({
  selector: 'app-timelog-overview',
  templateUrl: './timelog-overview.component.html',
  styleUrls: ['./timelog-overview.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: 'rangeMax', useValue: 6 },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: MaxRangeSelectionStrategy,
    },
  ],
})
export class TimelogOverviewComponent {
  timesheetForm: FormGroup;
  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });
  timelog: TimeLogModel = {
    columns: [],
    data: [],
    allDates: [],
    status: 0,
  };
  timelogOverviewData = new UserWeekTimelogOverviewModel();
  timeModeId?: number;
  displayedColumns: string[] = [];
  timelogOverviewSubscription: Subscription;
  timelogData: any;
  timelogHeaderText = '';

  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input()
  moduleId: number;

  @Input()
  status: number;

  @Input() triggerTimelogOverviewData: Observable<any>;

  @Output()
  readonly closeClick = new EventEmitter<any>();

  triggerTimehseetListData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  triggerTimelogOverviewChartData: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);

  @Output()
  readonly triggerHasOverviewData = new EventEmitter<any>(true);

  modules = Modules;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.timelogOverviewSubscription =
      this.triggerTimelogOverviewData?.subscribe((data) => {
        if (data) {
          this.timelogData = data[0];
          this.bindOverviewData();
        }
      });
  }

  ngOnDestroy(): void {
    this.timelogOverviewSubscription?.unsubscribe();
  }

  onCloseClick(): void {
    this.closeClick.emit();
  }

  setForm(): void {
    this.timesheetForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.timesheetForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.getList();
      this.getTimelogOverviewDetails();
    });
  }

  addWeek(e: any): void {
    e.stopPropagation();
    this.timesheetForm = this.formBuilder.group({
      start: new Date(
        new Date(this.timesheetForm.controls.end.value).setDate(
          new Date(this.timesheetForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.timesheetForm.controls.end.value).setDate(
          new Date(this.timesheetForm.controls.end.value).getDate() + 7
        )
      ),
    });

    this.startDate.setValue(this.timesheetForm.controls.start.value);
    this.endDate.setValue(this.timesheetForm.controls.end.value);

    this.getList();
    this.getTimelogOverviewDetails();
  }

  setDateToToday(): any {
    this.getStartWeekAndEndWeek();
  }

  lessWeek(e: any): void {
    e.stopPropagation();
    this.timesheetForm = this.formBuilder.group({
      end: new Date(
        new Date(this.timesheetForm.controls.start.value).setDate(
          new Date(this.timesheetForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.timesheetForm.controls.start.value).setDate(
          new Date(this.timesheetForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.timesheetForm.controls.start.value);
    this.endDate.setValue(this.timesheetForm.controls.end.value);

    this.getList();
    this.getTimelogOverviewDetails();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.getList();
      this.getTimelogOverviewDetails();
    }
  }

  getParams() {
    const timelogParams: TimelogParameter = {
      startDate: this.datepipe
        .transform(this.timesheetForm.controls.start.value, 'yyyy-MM-dd')
        ?.toString(),
      endDate: this.datepipe
        .transform(this.timesheetForm.controls.end.value, 'yyyy-MM-dd')
        ?.toString(),
      search: '',
      status: this.status,
      userId: this.timelogData.userId,
      isDayView: false,
      isSort: false,
    };

    return timelogParams;
  }

  getList(): any {
    this.store
      .dispatch(new GetUserWeekTimelogs(this.getParams()))
      .subscribe((res) => {
        this.timelog = res.time.timelogs;

        this.displayedColumns = this.timelog.columns;

        const obj = {
          isDayView: false,
          isFromOverview: true,
          timelogData: this.timelog,
          displayedColumns: this.displayedColumns,
          timeModeId: this.timeModeId,
          isSort: false,
        };
        this.triggerTimehseetListData.next(obj);
      });
  }

  bindOverviewData(): void {
    if (this.timelogData && Object.keys(this.timelogData).length > 0) {
      this.startDate.setValue(this.timelogData.startDate);
      this.endDate.setValue(this.timelogData.endDate);
    }

    this.timesheetForm = this.formBuilder.group({
      start: new FormControl<Date | null>(this.startDate.value),
      end: new FormControl<Date | null>(this.endDate.value),
    });

    this.getList();
    this.getTimelogOverviewDetails();
  }

  getOverviewParams() {
    const timeParameters: TimeParameters = {
      startDate: this.datepipe
        .transform(this.timesheetForm.controls.start.value, 'yyyy-MM-dd')
        ?.toString(),
      endDate: this.datepipe
        .transform(this.timesheetForm.controls.end.value, 'yyyy-MM-dd')
        ?.toString(),
      userId: this.timelogData.userId,
    };

    return timeParameters;
  }

  getTimelogOverviewDetails(): void {
    this.store
      .dispatch(new GetUserWeekTimelogOverview(this.getOverviewParams()))
      .subscribe((res) => {
        this.timelogOverviewData = res.time.timelogOverview;
        const date =
          this.datepipe
            .transform(this.timesheetForm.controls.start.value, 'dd MMM')
            ?.toString() +
          ' - ' +
          this.datepipe
            .transform(this.timesheetForm.controls.end.value, 'dd MMM yyyy')
            ?.toString();
        this.timelogHeaderText =
          this.timelogOverviewData.userFullName + ', ' + date;

        this.bindChartData();
        this.triggerTimelogOverviewChartData.next(this.timelogOverviewData);
        this.triggerHasOverviewData.next(
          this.timelogOverviewData.clients.length ? true : false
        );
      });
  }

  bindChartData(): void {
    this.chartOptions = {
      series: [this.timelogOverviewData.thisWeekPercentage],
      chart: {
        height: 120,
        width: 120,
        type: 'radialBar',
      },
      fill: {
        type: 'solid',
        colors: ['#41DA73'],
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          track: {
            background: '#D2D2D2',
          },

          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
              fontSize: '12px',
              color: '#6661B8',
              fontWeight: 600,
            },
            total: {
              show: false,
            },
          },
        },
      },
      labels: [''],
    };
  }
}
