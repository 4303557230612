<div class="content no-record">
  <div class="text-align-center">
    <div
      *ngIf="
        moduleId === modulesEnum.Clients ||
        moduleId === modulesEnum.Tasks ||
        moduleId === modulesEnum.Users ||
        moduleId === modulesEnum.ExpenseType
      "
    >
      <div class="alert-message mb-10" *ngIf="isViewPermission$ | async">
        <mat-icon class="material-icons-outlined">error_outline</mat-icon>
        {{ commonNotificationText.ViewOnlyAccessMessage }}
      </div>
    </div>
    <svg width="486" height="484" viewBox="0 0 486 484" fill="none">
      <g clip-path="url(#clip0_12579_820)">
        <path
          d="M320.458 128.098H240.716C238.971 128.103 237.247 127.71 235.676 126.949C234.105 126.187 232.728 125.078 231.651 123.704C230.554 122.318 229.787 120.699 229.41 118.972C229.033 117.245 229.055 115.454 229.474 113.736C236.824 82.9533 236.823 50.8724 229.474 20.0894C229.055 18.3719 229.033 16.5814 229.41 14.8542C229.787 13.127 230.554 11.5086 231.651 10.1221C232.728 8.74854 234.105 7.63884 235.676 6.8775C237.247 6.11615 238.971 5.7232 240.716 5.72857H320.458C323.211 5.73648 325.872 6.72141 327.967 8.50793C330.062 10.2944 331.454 12.7666 331.897 15.4839C337.145 49.5692 337.145 84.257 331.897 118.342C331.454 121.06 330.062 123.532 327.967 125.318C325.872 127.105 323.211 128.09 320.458 128.098Z"
          fill="#E6E6E6"
        />
        <path
          d="M240.718 11.7285C239.879 11.7257 239.051 11.9142 238.297 12.2795C237.542 12.6449 236.881 13.1775 236.363 13.8369C235.83 14.5126 235.458 15.3009 235.275 16.1419C235.093 16.983 235.104 17.8546 235.309 18.6905C242.879 50.3933 242.879 83.4328 235.31 115.136C235.105 115.971 235.093 116.843 235.275 117.684C235.458 118.525 235.83 119.313 236.363 119.988L236.363 119.989C236.881 120.648 237.542 121.181 238.297 121.547C239.051 121.912 239.879 122.101 240.718 122.098H320.459C321.781 122.098 323.061 121.629 324.069 120.775C325.078 119.92 325.751 118.735 325.968 117.431C331.123 83.9496 331.123 49.8767 325.968 16.3955C325.751 15.0912 325.078 13.9063 324.069 13.0516C323.061 12.1969 321.781 11.7281 320.459 11.7285L240.718 11.7285Z"
          fill="white"
        />
        <path
          d="M303.058 40.5786H261.213C259.622 40.5768 258.097 39.9441 256.972 38.8193C255.847 37.6945 255.215 36.1695 255.213 34.5788V32.2341C255.215 30.6434 255.847 29.1184 256.972 27.9936C258.097 26.8688 259.622 26.2361 261.213 26.2344H303.058C304.648 26.2361 306.173 26.8688 307.298 27.9936C308.423 29.1184 309.056 30.6434 309.057 32.2341V34.5788C309.056 36.1695 308.423 37.6946 307.298 38.8194C306.173 39.9442 304.648 40.5768 303.058 40.5786Z"
          fill="#E6E6E6"
        />
        <path
          d="M303.058 70.2035H261.213C259.622 70.2018 258.097 69.5691 256.972 68.4443C255.847 67.3195 255.215 65.7945 255.213 64.2038V61.8591C255.215 60.2684 255.847 58.7434 256.972 57.6186C258.097 56.4938 259.622 55.8611 261.213 55.8594H303.058C304.648 55.8611 306.173 56.4938 307.298 57.6186C308.423 58.7434 309.056 60.2684 309.057 61.8591V64.2038C309.056 65.7945 308.423 67.3195 307.298 68.4443C306.173 69.5691 304.648 70.2018 303.058 70.2035Z"
          fill="#E6E6E6"
        />
        <path
          d="M303.058 99.8305H261.213C259.622 99.8287 258.097 99.1961 256.972 98.0713C255.847 96.9465 255.215 95.4214 255.213 93.8307V91.4861C255.215 89.8954 255.847 88.3703 256.972 87.2455C258.097 86.1207 259.622 85.4881 261.213 85.4863H303.058C304.648 85.4881 306.173 86.1207 307.298 87.2455C308.423 88.3703 309.056 89.8954 309.057 91.4861V93.8307C309.056 95.4214 308.423 96.9465 307.298 98.0713C306.173 99.1961 304.648 99.8287 303.058 99.8305Z"
          fill="#E6E6E6"
        />
        <path
          d="M470.458 204.098H390.716C388.971 204.103 387.247 203.71 385.676 202.949C384.105 202.187 382.728 201.078 381.651 199.704C380.554 198.318 379.787 196.699 379.41 194.972C379.033 193.245 379.055 191.454 379.474 189.736C386.824 158.953 386.823 126.872 379.474 96.0894C379.055 94.3719 379.033 92.5814 379.41 90.8542C379.787 89.127 380.554 87.5086 381.651 86.1221C382.728 84.7485 384.105 83.6388 385.676 82.8775C387.247 82.1161 388.971 81.7232 390.716 81.7286H470.458C473.211 81.7365 475.872 82.7214 477.967 84.5079C480.062 86.2944 481.454 88.7666 481.897 91.4839C487.145 125.569 487.145 160.257 481.897 194.342C481.454 197.06 480.062 199.532 477.967 201.318C475.872 203.105 473.211 204.09 470.458 204.098Z"
          fill="#E6E6E6"
        />
        <path
          d="M390.718 87.7285C389.879 87.7257 389.051 87.9142 388.297 88.2795C387.542 88.6449 386.881 89.1775 386.363 89.8369C385.83 90.5126 385.458 91.3009 385.275 92.1419C385.093 92.983 385.104 93.8546 385.309 94.6905C392.879 126.393 392.879 159.433 385.31 191.136C385.105 191.971 385.093 192.843 385.275 193.684C385.458 194.525 385.83 195.313 386.363 195.988L386.363 195.989C386.881 196.648 387.542 197.181 388.297 197.547C389.051 197.912 389.879 198.101 390.718 198.098H470.459C471.781 198.098 473.061 197.629 474.069 196.775C475.078 195.92 475.751 194.735 475.968 193.431C481.123 159.95 481.123 125.877 475.968 92.3955C475.751 91.0913 475.078 89.9063 474.069 89.0516C473.061 88.1969 471.781 87.7281 470.459 87.7285L390.718 87.7285Z"
          fill="white"
        />
        <path
          d="M311.458 290.598H231.717C229.895 290.603 228.097 290.193 226.458 289.399C224.819 288.604 223.383 287.447 222.258 286.014C221.114 284.568 220.315 282.88 219.922 281.079C219.528 279.278 219.551 277.411 219.988 275.62C227.319 244.913 227.319 212.912 219.988 182.206C219.551 180.415 219.528 178.548 219.922 176.747C220.315 174.946 221.114 173.258 222.258 171.813C223.382 170.38 224.819 169.222 226.458 168.427C228.097 167.633 229.895 167.223 231.717 167.229H311.458C314.33 167.237 317.107 168.265 319.292 170.129C321.477 171.993 322.93 174.573 323.391 177.408C328.647 211.544 328.647 246.283 323.391 280.419C322.93 283.254 321.477 285.833 319.292 287.697C317.107 289.561 314.33 290.589 311.458 290.598Z"
          fill="#F2F2F2"
        />
        <path
          d="M453.058 131.579H411.213C409.622 131.577 408.097 130.944 406.972 129.819C405.847 128.695 405.215 127.169 405.213 125.579V123.234C405.215 121.643 405.847 120.118 406.972 118.994C408.097 117.869 409.622 117.236 411.213 117.234H453.058C454.648 117.236 456.173 117.869 457.298 118.994C458.423 120.118 459.056 121.643 459.057 123.234V125.579C459.056 127.17 458.423 128.695 457.298 129.819C456.173 130.944 454.648 131.577 453.058 131.579Z"
          fill="#E6E6E6"
        />
        <path
          d="M453.058 161.204H411.213C409.622 161.202 408.097 160.569 406.972 159.444C405.847 158.32 405.215 156.794 405.213 155.204V152.859C405.215 151.268 405.847 149.743 406.972 148.619C408.097 147.494 409.622 146.861 411.213 146.859H453.058C454.648 146.861 456.173 147.494 457.298 148.619C458.423 149.743 459.056 151.268 459.057 152.859V155.204C459.056 156.794 458.423 158.32 457.298 159.444C456.173 160.569 454.648 161.202 453.058 161.204Z"
          fill="#E6E6E6"
        />
        <path
          d="M271.811 251.5C284.513 251.5 294.811 241.203 294.811 228.5C294.811 215.797 284.513 205.5 271.811 205.5C259.108 205.5 248.811 215.797 248.811 228.5C248.811 241.203 259.108 251.5 271.811 251.5Z"
          fill="white"
        />
        <path
          d="M282.811 225.5H274.811V217.5C274.811 216.704 274.494 215.941 273.932 215.379C273.369 214.816 272.606 214.5 271.811 214.5C271.015 214.5 270.252 214.816 269.689 215.379C269.127 215.941 268.811 216.704 268.811 217.5V225.5H260.811C260.015 225.5 259.252 225.816 258.689 226.379C258.127 226.941 257.811 227.704 257.811 228.5C257.811 229.296 258.127 230.059 258.689 230.621C259.252 231.184 260.015 231.5 260.811 231.5H268.811V239.5C268.811 240.296 269.127 241.059 269.689 241.621C270.252 242.184 271.015 242.5 271.811 242.5C272.606 242.5 273.369 242.184 273.932 241.621C274.494 241.059 274.811 240.296 274.811 239.5V231.5H282.811C283.606 231.5 284.369 231.184 284.932 230.621C285.494 230.059 285.811 229.296 285.811 228.5C285.811 227.704 285.494 226.941 284.932 226.379C284.369 225.816 283.606 225.5 282.811 225.5Z"
          fill="#E6E6E6"
        />
        <path
          d="M300.811 17H258.811C257.617 16.9987 256.474 16.5241 255.63 15.6805C254.786 14.8369 254.312 13.6931 254.311 12.5V4.5C254.312 3.30694 254.786 2.16313 255.63 1.3195C256.474 0.475881 257.617 0.00134456 258.811 0H300.811C302.004 0.00134456 303.147 0.475881 303.991 1.3195C304.835 2.16313 305.309 3.30694 305.311 4.5V12.5C305.309 13.6931 304.835 14.8369 303.991 15.6805C303.147 16.5241 302.004 16.9987 300.811 17Z"
          fill="#CCCCCC"
        />
        <path
          d="M452.811 94H410.811C409.617 93.9987 408.474 93.5241 407.63 92.6805C406.786 91.8369 406.312 90.6931 406.311 89.5V81.5C406.312 80.3069 406.786 79.1631 407.63 78.3195C408.474 77.4759 409.617 77.0013 410.811 77H452.811C454.004 77.0013 455.147 77.4759 455.991 78.3195C456.835 79.1631 457.309 80.3069 457.311 81.5V89.5C457.309 90.6931 456.835 91.8369 455.991 92.6805C455.147 93.5241 454.004 93.9987 452.811 94Z"
          fill="#CCCCCC"
        />
        <path
          d="M88.5957 471.061H100.856L104.689 423.773H88.5938L88.5957 471.061Z"
          fill="#FFB8B8"
        />
        <path
          d="M85.4688 467.058L109.613 467.057H109.614C111.634 467.057 113.635 467.455 115.502 468.228C117.368 469.001 119.065 470.134 120.493 471.563C121.922 472.992 123.056 474.688 123.829 476.555C124.602 478.422 125 480.422 125 482.443V482.943L85.4695 482.944L85.4688 467.058Z"
          fill="#2F2E41"
        />
        <path
          d="M22.5958 471.061H34.8557L40.6887 423.773H22.5938L22.5958 471.061Z"
          fill="#FFB8B8"
        />
        <path
          d="M19.4688 467.058L43.6125 467.057H43.6136C45.6341 467.057 47.6349 467.455 49.5017 468.228C51.3685 469.001 53.0647 470.134 54.4935 471.563C55.9222 472.992 57.0556 474.688 57.8288 476.555C58.6021 478.422 59.0001 480.422 59 482.443V482.943L19.4695 482.944L19.4688 467.058Z"
          fill="#2F2E41"
        />
        <path
          d="M24.7707 456.124C23.5201 456.123 22.3153 455.653 21.3957 454.805C20.902 454.355 20.5044 453.809 20.2268 453.202C19.9491 452.594 19.7972 451.936 19.7799 451.268L14.8633 275.055L84.6744 292.507L106.214 357.125C108.992 365.437 110.194 374.195 109.759 382.949L107.085 445.585C107.024 446.869 106.471 448.079 105.541 448.965C104.611 449.851 103.375 450.345 102.09 450.344H90.3815C89.1729 450.339 88.0066 449.899 87.0965 449.104C86.1864 448.308 85.5936 447.212 85.4266 446.015L77.0577 376.873C76.3264 371.479 74.4396 366.307 71.526 361.709L55.0565 335.633C54.9464 335.459 54.7851 335.323 54.5948 335.244C54.4045 335.165 54.1945 335.146 53.9933 335.191C53.7922 335.236 53.6099 335.342 53.4713 335.495C53.3327 335.647 53.2445 335.839 53.2189 336.043L40.2943 451.131C40.1555 452.299 39.6094 453.38 38.752 454.184C37.8947 454.988 36.7811 455.464 35.6073 455.528L25.0553 456.115C24.9604 456.12 24.8656 456.124 24.7707 456.124Z"
          fill="#2F2E41"
        />
        <path
          d="M73.0571 160.968C86.6218 160.968 97.6182 149.971 97.6182 136.407C97.6182 122.842 86.6218 111.846 73.0571 111.846C59.4924 111.846 48.4961 122.842 48.4961 136.407C48.4961 149.971 59.4924 160.968 73.0571 160.968Z"
          fill="#FFB8B8"
        />
        <path
          class="addfill"
          d="M84.341 299.672C83.9183 299.671 83.4974 299.618 83.088 299.513H83.0873L13.823 281.753C12.5846 281.436 11.5148 280.657 10.8335 279.575C10.1522 278.493 9.91123 277.192 10.1601 275.938L26.0673 190.247C27.0583 185.294 29.2367 180.655 32.4155 176.729C35.5942 172.803 39.6783 169.707 44.317 167.707C48.8369 165.707 53.7797 164.85 58.7092 165.212C63.6387 165.574 68.4034 167.143 72.5826 169.782C73.5524 170.387 74.5292 171.047 75.4852 171.743C79.3341 174.583 82.4729 178.275 84.6553 182.531C86.8377 186.787 88.0045 191.49 88.0641 196.273L89.329 294.601C89.3375 295.263 89.2149 295.919 88.9682 296.533C88.7216 297.146 88.3558 297.705 87.892 298.177C87.4282 298.648 86.8756 299.023 86.2661 299.28C85.6566 299.536 85.0023 299.67 84.341 299.672Z"
        />
        <path
          d="M20.9495 300.68C20.1233 299.464 19.5736 298.082 19.3394 296.631C19.1052 295.179 19.192 293.694 19.5938 292.28C19.9955 290.866 20.7025 289.557 21.6648 288.446C22.6271 287.335 23.8214 286.448 25.1635 285.848L22.0827 250.246L38.4087 259.095L38.8313 291.546C39.6896 293.868 39.6666 296.423 38.7664 298.729C37.8663 301.034 36.1517 302.93 33.9476 304.056C31.7434 305.182 29.2028 305.46 26.8072 304.838C24.4116 304.216 22.3274 302.736 20.9495 300.68H20.9495Z"
          fill="#FFB8B8"
        />
        <path
          class="addfill"
          d="M26.781 281.139C26.3204 281.138 25.8616 281.08 25.4153 280.966C24.4554 280.721 23.5796 280.222 22.8804 279.52C22.1812 278.818 21.6847 277.94 21.4434 276.98L13.4202 245.096C10.6163 233.96 11.9529 222.185 17.1814 211.961L33.9903 179.079C35.0528 175.142 37.6201 171.781 41.1386 169.72C44.657 167.658 48.8447 167.063 52.7984 168.061C54.769 168.558 56.6212 169.442 58.2477 170.661C59.8741 171.88 61.2424 173.41 62.2732 175.161C63.3252 176.938 64.0133 178.906 64.2972 180.952C64.5812 182.997 64.4554 185.078 63.9273 187.074L39.9998 240.58L40.284 272.616C40.2913 273.746 39.9515 274.851 39.3104 275.782C38.6694 276.713 37.758 277.424 36.6995 277.82L28.6922 280.794C28.0807 281.022 27.4335 281.139 26.781 281.139Z"
        />
        <path
          d="M141.318 287.585C139.858 287.418 138.452 286.933 137.199 286.164C135.946 285.395 134.877 284.36 134.067 283.133C133.258 281.906 132.727 280.517 132.512 279.062C132.298 277.608 132.405 276.124 132.825 274.716L103.832 253.826L121.189 247.225L146.06 268.074C148.38 268.937 150.302 270.622 151.462 272.808C152.622 274.995 152.94 277.53 152.356 279.936C151.771 282.341 150.325 284.448 148.291 285.858C146.256 287.268 143.776 287.882 141.318 287.585H141.318Z"
          fill="#FFB8B8"
        />
        <path
          class="addfill"
          d="M126.14 272.331C125.285 272.331 124.442 272.132 123.677 271.75L94.2781 257.032C84.0095 251.893 75.9568 243.199 71.6175 232.567L57.6602 198.377C55.3697 195.003 54.4968 190.864 55.2297 186.853C55.9626 182.841 58.2425 179.278 61.5779 176.932C63.2444 175.761 65.1275 174.932 67.1174 174.495C69.1073 174.059 71.1642 174.023 73.1683 174.389C75.1958 174.753 77.1309 175.517 78.8614 176.635C80.5918 177.752 82.0832 179.203 83.2491 180.901L108.194 233.941L132.66 254.623C133.521 255.355 134.138 256.333 134.425 257.426C134.712 258.519 134.657 259.674 134.267 260.735L131.297 268.743C130.909 269.794 130.209 270.7 129.29 271.34C128.366 271.985 127.267 272.331 126.14 272.331Z"
        />
        <path
          d="M67.9002 161.344C69.2052 161.915 71.8775 151.516 70.6804 149.437C68.9002 146.344 69.0054 146.366 67.8236 144.32C66.6418 142.273 66.3774 139.472 67.9078 137.672C69.4382 135.872 72.9798 136.11 73.6782 138.368C73.2289 134.08 77.4701 130.633 81.6712 129.665C85.8723 128.696 90.3037 129.297 94.5279 128.436C99.4303 127.435 104.531 123.326 102.575 117.935C102.239 117.037 101.737 116.211 101.094 115.501C98.8341 112.96 95.6729 111.875 92.582 110.826C86.152 108.643 79.546 106.433 72.7599 106.674C67.1685 106.967 61.7836 108.883 57.2642 112.188C52.7448 115.493 49.2868 120.044 47.3136 125.284C46.8459 126.594 46.4836 127.94 46.2301 129.309C43.9009 141.833 51.1738 154.187 62.9864 158.956L67.9002 161.344Z"
          fill="#2F2E41"
        />
        <path
          opacity="0.2"
          d="M38.9004 273.344L39.4574 240.415L56.9004 205.344L42.9004 241.344L38.9004 273.344Z"
          fill="black"
        />
        <path
          d="M197.078 355.983L123.439 325.388C121.825 324.723 120.384 323.699 119.225 322.393C118.067 321.087 117.221 319.534 116.753 317.852C116.272 316.151 116.185 314.362 116.5 312.622C116.814 310.883 117.521 309.237 118.568 307.812C137.165 282.205 149.474 252.579 154.498 221.332C154.77 219.585 155.437 217.924 156.448 216.473C157.459 215.023 158.788 213.823 160.332 212.963C161.855 212.108 163.552 211.611 165.295 211.511C167.037 211.411 168.78 211.709 170.39 212.384L244.029 242.98C246.568 244.043 248.648 245.974 249.897 248.427C251.146 250.881 251.483 253.698 250.849 256.377C242.618 289.868 229.308 321.901 211.384 351.364C209.933 353.703 207.698 355.452 205.078 356.298C202.458 357.144 199.623 357.032 197.078 355.983Z"
          fill="#E6E6E6"
        />
        <path
          d="M168.087 217.923C167.314 217.599 166.477 217.455 165.64 217.503C164.803 217.551 163.988 217.789 163.257 218.199C162.505 218.619 161.859 219.204 161.368 219.911C160.877 220.617 160.553 221.427 160.421 222.277C155.247 254.458 142.571 284.969 123.417 311.341C122.907 312.034 122.562 312.834 122.408 313.681C122.254 314.528 122.295 315.398 122.528 316.226L122.528 316.227C122.753 317.035 123.159 317.78 123.716 318.407C124.272 319.034 124.965 319.526 125.74 319.845L199.378 350.441C200.599 350.948 201.961 351.006 203.22 350.604C204.48 350.202 205.556 349.366 206.256 348.245C223.863 319.304 236.936 287.839 245.022 254.942C245.322 253.655 245.155 252.302 244.552 251.126C243.948 249.949 242.946 249.026 241.725 248.519L168.087 217.923Z"
          fill="white"
        />
        <path
          class="addfill"
          d="M207.297 286.032L168.655 269.977C167.186 269.365 166.021 268.196 165.414 266.725C164.807 265.255 164.807 263.604 165.416 262.134L166.316 259.969C166.928 258.501 168.097 257.335 169.567 256.728C171.038 256.121 172.689 256.122 174.158 256.731L212.801 272.786C214.269 273.398 215.435 274.567 216.042 276.037C216.649 277.508 216.648 279.159 216.039 280.628L215.14 282.794C214.528 284.262 213.358 285.428 211.888 286.035C210.418 286.642 208.767 286.641 207.297 286.032Z"
        />
        <path
          class="addfill"
          d="M195.93 313.39L157.288 297.334C155.819 296.722 154.654 295.553 154.047 294.083C153.439 292.613 153.44 290.961 154.049 289.492L154.949 287.327C155.561 285.858 156.73 284.693 158.2 284.086C159.67 283.478 161.322 283.479 162.791 284.088L201.434 300.143C202.902 300.755 204.067 301.925 204.675 303.395C205.282 304.865 205.281 306.516 204.672 307.986L203.773 310.151C203.161 311.619 201.991 312.785 200.521 313.392C199.051 313.999 197.4 313.998 195.93 313.39Z"
        />
        <path
          d="M222.781 247.581C222.202 247.581 221.629 247.469 221.093 247.251L182.156 231.508C181.05 231.06 180.167 230.191 179.702 229.093C179.236 227.995 179.224 226.756 179.67 225.65L182.669 218.233C183.116 217.126 183.985 216.243 185.084 215.777C186.182 215.311 187.421 215.301 188.528 215.748L227.466 231.491C228.571 231.939 229.454 232.808 229.92 233.906C230.385 235.004 230.397 236.242 229.951 237.349L226.952 244.766C226.615 245.597 226.039 246.308 225.296 246.81C224.553 247.311 223.677 247.58 222.781 247.581Z"
          fill="#CCCCCC"
        />
        <path
          d="M141 483.5H1C0.734784 483.5 0.480444 483.395 0.292908 483.207C0.105371 483.02 0 482.765 0 482.5C0 482.235 0.105371 481.98 0.292908 481.793C0.480444 481.605 0.734784 481.5 1 481.5H141C141.265 481.5 141.52 481.605 141.707 481.793C141.895 481.98 142 482.235 142 482.5C142 482.765 141.895 483.02 141.707 483.207C141.52 483.395 141.265 483.5 141 483.5Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_12579_820">
          <rect width="485.834" height="483.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <p class="pt-2">
      <strong>{{ noRecordFound }}</strong>
    </p>
    <button
      type="button"
      class="action-button primary-button"
      mat-button
      (click)="onAddClick()"
      disableRipple
      [disabled]="isViewPermission$ | async"
    >
      <mat-icon>add</mat-icon>
      <span>Add {{ moduleHeaderText }}</span>
    </button>
  </div>
</div>
