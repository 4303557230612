export enum AttachmentType {
  TimeLog = 0,
  Expense = 1,
  Project = 2,
  Users = 3,
  Client = 4,
  Estiamte = 5,
  Invoice = 6,
  Profile = 7,
}
