export class ProjectDetail {
  id: number;
  name: string;
  totalBudget: string;
  budgetSpent: string;
  budgetRemaining: string;
  costs: string;
  isArchived: boolean;
  totalRecords?: number;
  isManager: boolean;
  isAssigned: boolean;
  isBillable?: boolean;
  isSelected?: boolean;
}
