<div
  class="content-body"
  [ngClass]="moduleId !== modules.Timelog ? 'overview-timelist' : ''"
>
  <div class="mat-table-wrapper" *ngIf="!isDayView && timelog.data.length > 1">
    <table
      mat-table
      [dataSource]="timelog.data"
      aria-describedby="Timesheet Week View Table"
    >
      <ng-container
        [matColumnDef]="col"
        *ngFor="let col of displayedColumns; let columnIndex = index"
      >
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="columnIndex === 2 && col !== '' && !isFromOverview">
            {{ col }}
          </span>
          <div
            class="week-list"
            *ngIf="
              columnIndex !== 0 &&
              columnIndex !== 1 &&
              columnIndex > 2 &&
              columnIndex !== 11
            "
          >
            <div class="d-flex justify-content-center gap-5 text-gray">
              <div class="days font-size-26 mt-5">
                {{ col.split("-")[0].split(" ")[1] }}
              </div>
              <div>
                <div class="week font-size-14 line-height-normal">
                  {{ col.split(" ")[0] }}
                </div>
                <div class="month font-size-12 line-height-normal">
                  {{ col.split("-")[1] }}
                </div>
              </div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index">
          <div
            class="timelist-view d-flex align-items-center justify-space-between gap-20"
            *ngIf="columnIndex === 0 && rowIndex < timelog.data.length - 1"
          >
            <div
              (click)="
                timeModeId === timeMode.Duration &&
                !isFromOverview &&
                +element[16]?.value === timelogActionStatus.CanEditOrDelete &&
                element[16]?.invoiceId === null
                  ? onEditTimeSheetClick(element)
                  : ''
              "
              class="wp-100"
              [ngClass]="[
                isFromOverview ? '' : 'cursor-pointer',
                timeModeId === timeMode.Duration &&
                !isFromOverview &&
                +element[16]?.value === timelogActionStatus.CanEditOrDelete &&
                element[16]?.invoiceId === null
                  ? ''
                  : 'cursor-not-allowed'
              ]"
            >
              <div
                [title]="
                  element[columnIndex]?.value?.split('|')[2] +
                  ' - ' +
                  element[columnIndex]?.value?.split('|')[4] +
                  ' - ' +
                  element[columnIndex]?.value?.split('|')[5] +
                  (element[columnIndex]?.value?.split('|')[7]
                    ? ' - ' + element[columnIndex]?.value?.split('|')[7]
                    : '')
                "
              >
                <div class="time-title onelineclamp">
                  <img
                    *ngIf="
                      +element[columnIndex]?.value?.split('|')[1] ===
                      feeType.TimeAndFee
                    "
                    src="assets/images/timeandfees.svg"
                    width="13px"
                    alt="Time and Fees"
                    matTooltip="Time and Fees"
                  />
                  <img
                    *ngIf="
                      +element[columnIndex]?.value?.split('|')[1] ===
                      feeType.FixedPrice
                    "
                    src="assets/images/fixedfees.svg"
                    width="15px"
                    alt="Fixed Price"
                    matTooltip="Fixed Price"
                  />
                  <img
                    *ngIf="
                      +element[columnIndex]?.value?.split('|')[1] ===
                      feeType.NonBillable
                    "
                    src="assets/images/nonbillable.svg"
                    width="14px"
                    alt="Non Billable"
                    matTooltip="Non Billable"
                  />
                  {{
                    element[columnIndex]?.value?.indexOf("|") > 0
                      ? element[columnIndex]?.value?.split("|")[2]
                      : element[columnIndex]?.value
                  }}

                  <mat-icon
                    *ngIf="+element[columnIndex]?.value?.split('|')[3] === 1"
                    >south</mat-icon
                  >
                  <mat-icon
                    class="danger"
                    *ngIf="
                      element[columnIndex]?.value?.indexOf('|') > 0
                        ? +element[columnIndex]?.value?.split('|')[3] === 0
                        : +element[columnIndex]?.value === 0
                    "
                    >north</mat-icon
                  >
                  {{
                    element[columnIndex]?.value?.indexOf("|") > 0
                      ? element[columnIndex]?.value?.split("|")[4]
                      : element[columnIndex]?.value
                  }}
                </div>
                <div class="time-detail onelineclamp ml-10 pl-10">
                  {{
                    element[columnIndex]?.value?.indexOf("|") > 0
                      ? element[columnIndex]?.value?.split("|")[5] +
                        (element[columnIndex]?.value?.split("|")[7]
                          ? " - " + element[columnIndex]?.value?.split("|")[7]
                          : "")
                      : element[columnIndex]?.value
                  }}
                </div>
              </div>
            </div>
            <div class="time-coment">
              <button
                mat-button
                class="w-auto h-auto"
                [matMenuTriggerFor]="menu1"
                #menuTrigger="matMenuTrigger"
                disableRipple
                [ngClass]="{ 'link-disable': element[1]?.value === '' }"
              >
                <mat-icon
                  fontSet="material-icons-outlined"
                  [ngClass]="
                    element[1]?.value === ''
                      ? 'text-primary not-comment cursor-default'
                      : 'text-primary comment cursor-pointer'
                  "
                  matTooltipPosition="right"
                  >comment</mat-icon
                >
              </button>
              <mat-menu
                [overlapTrigger]="false"
                #menu1="matMenu"
                class="contact-detail time-tooltip"
              >
                <span (click)="$event.stopPropagation()">
                  <div class="sidenav-body-content">
                    <div class="form-field-title">
                      <p class="font-size-16 pb-1 text-gray">
                        <strong>Description</strong>
                      </p>
                    </div>
                    <div class="tooltip-list comment-des">
                      <ul>
                        <li>
                          <div [innerHTML]="element[1]?.value"></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </mat-menu>
            </div>
          </div>
          <div class="d-flex align-items-center" *ngIf="!isFromOverview">
            <span
              class="onelineclamp"
              title="{{ element[columnIndex]?.value }}"
              *ngIf="columnIndex === 2 && rowIndex < timelog.data.length - 1"
            >
              {{ element[columnIndex]?.value }}
            </span>
          </div>

          <!-- For not (1st column && last 2 columns && last Row)-->
          <span
            *ngIf="
              columnIndex !== 0 &&
              columnIndex !== 1 &&
              columnIndex !== 2 &&
              columnIndex < displayedColumns.length - 2 &&
              rowIndex < timelog.data.length - 1
            "
          >
            <div class="timepicker timepicker-duration week-view">
              <div class="timepicker-input">
                <div
                  class="time-picker d-flex align-items-center justify-content-center"
                >
                  <ngx-timepicker-field
                    [format]="24"
                    [disabled]="
                      +element[16]?.value !==
                        timelogActionStatus.CanEditOrDelete ||
                      timeModeId === timeMode.StartAndEnd ||
                      isFromOverview ||
                      element[columnIndex].invoiceId !== null
                    "
                    [(ngModel)]="element[columnIndex].value"
                    [ngClass]="{
                      'light-bg':
                        +element[16]?.value !==
                          timelogActionStatus.CanEditOrDelete ||
                        timeModeId === timeMode.StartAndEnd ||
                        isFromOverview ||
                        element[columnIndex].invoiceId !== null
                    }"
                    (timeChanged)="onTimeChange(columnIndex, rowIndex)"
                  ></ngx-timepicker-field>
                </div>
              </div>
            </div>
          </span>

          <!-- For last Row or For last secound column For week view-->
          <div
            class="text-align-center fw-bold"
            *ngIf="
              (!isFromOverview &&
                (columnIndex === displayedColumns.length - 2 ||
                  rowIndex === timelog.data.length - 1)) ||
              (isFromOverview &&
                columnIndex !== 0 &&
                (columnIndex === displayedColumns.length - 2 ||
                  rowIndex === timelog.data.length - 1))
            "
          >
            {{ element[columnIndex]?.value }}
          </div>

          <!-- For last Column but not for last 2 rows-->
          <span
            *ngIf="
              columnIndex === displayedColumns.length - 1 &&
              rowIndex < timelog.data.length - 1 &&
              !isFromOverview
            "
          >
            <button
              type="button"
              mat-button
              disableRipple
              (click)="onDeleteClick(element)"
              title="{{ notificationTextMessage.deleteTimesheetMessage }}"
              *ngIf="
                +element[16]?.value === timelogActionStatus.CanEditOrDelete &&
                element[16]?.invoiceId === null
              "
            >
              <mat-icon>highlight_off</mat-icon>
            </button>
            <mat-icon
              *ngIf="
                +element[16]?.value === timelogActionStatus.CanEditOrDelete &&
                element[16]?.invoiceId !== null
              "
              class="text-success"
              title="{{ notificationTextMessage.timelogInvoicedMessage }}"
              >lock</mat-icon
            >
            <mat-icon
              *ngIf="+element[16]?.value === timelogActionStatus.Approved"
              class="text-success"
              title="{{ notificationTextMessage.approvedTimesheetMessage }}"
              >lock</mat-icon
            >
            <mat-icon
              *ngIf="+element[16]?.value === timelogActionStatus.Rejected"
              class="text-danger-100"
              title="{{ notificationTextMessage.rejectedTimesheetMessage }}"
              >lock</mat-icon
            >
            <img
              *ngIf="+element[16]?.value === timelogActionStatus.Completed"
              [src]="'assets/images/check.svg'"
              [alt]="'Check'"
              title="{{ notificationTextMessage.jobCompletedMessage }}"
              width="17px"
            />
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="[
          timelog.data ? 'highlightLastRow' : '',
          isRowHighlighted ? 'highlightRow' : ''
        ]"
      ></tr>
    </table>
  </div>
  <div
    class="norecord-chart text-align-center mt-4"
    *ngIf="!isDayView && timelog.data.length === 1"
  >
    <img src="assets/images/week-view-icon.svg" alt="Timer" />
    <p class="pt-1 no-border" *ngIf="moduleId !== modules.Timelog">
      No records found for this week
    </p>
    <p class="pt-1 no-border" *ngIf="moduleId === modules.Timelog">
      This is the Week view of your timesheet. Perfect <br />
      for speedily entering a lot of time at once!
    </p>
  </div>

  <div class="mat-table-wrapper" *ngIf="isDayView && timelog.data.length > 1">
    <table
      mat-table
      [dataSource]="timelog.data"
      aria-describedby="Timesheet Day View Table"
    >
      <ng-container
        [matColumnDef]="col"
        *ngFor="let col of displayedColumns; let columnIndex = index"
      >
        <th mat-header-cell *matHeaderCellDef>
          <span *ngIf="columnIndex === 2 && col !== ''">
            {{ col }}
          </span>
          <div
            class="week-list dayview-list"
            *ngIf="
              columnIndex !== 0 &&
              columnIndex !== 1 &&
              columnIndex > 2 &&
              columnIndex < 4
            "
          >
            <div class="d-flex justify-content-center gap-5 text-gray">
              <div class="days font-size-26 mt-5">
                {{ col.split("-")[0].split(" ")[1] }}
              </div>
              <div>
                <div class="week font-size-14 line-height-normal">
                  {{ col.split(" ")[0] }}
                </div>
                <div class="month font-size-12 line-height-normal">
                  {{ col.split("-")[1] }}
                </div>
              </div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element; let rowIndex = index">
          <div
            class="timelist-view timelist-day d-flex align-items-center justify-space-between gap-20"
            *ngIf="columnIndex === 0 && rowIndex < timelog.data.length - 1"
          >
            <div
              (click)="
                +element[10]?.value === timelogActionStatus.CanEditOrDelete &&
                element[10]?.invoiceId === null
                  ? onEditTimeSheetClick(element)
                  : ''
              "
              class="wp-100 d-flex gap-20"
              [ngClass]="[
                +element[10]?.value === timelogActionStatus.CanEditOrDelete &&
                element[10]?.invoiceId === null
                  ? ''
                  : 'cursor-not-allowed'
              ]"
            >
              <div
                class="startend-time"
                *ngIf="timeModeId === timeMode.StartAndEnd"
              >
                <div class="fw-bold">
                  {{
                    element[columnIndex]?.value?.indexOf("|") > 0
                      ? element[columnIndex]?.value?.split("|")[0]
                      : element[columnIndex]?.value
                  }}
                </div>

                <div>
                  {{
                    element[columnIndex]?.value?.indexOf("|") > 0
                      ? element[columnIndex]?.value?.split("|")[1]
                      : element[columnIndex]?.value
                  }}
                </div>
              </div>
              <div
                class="wp-100"
                [title]="
                  element[columnIndex]?.value?.split('|')[4] +
                  ' - ' +
                  element[columnIndex]?.value?.split('|')[6] +
                  ' - ' +
                  element[columnIndex]?.value?.split('|')[7] +
                  (element[columnIndex]?.value?.split('|')[9]
                    ? ' - ' + element[columnIndex]?.value?.split('|')[9]
                    : '')
                "
              >
                <div>
                  <div class="time-title onelineclamp">
                    <img
                      *ngIf="
                        +element[columnIndex]?.value?.split('|')[3] ===
                        feeType.TimeAndFee
                      "
                      src="assets/images/timeandfees.svg"
                      width="13px"
                      alt="Time and Fees"
                      matTooltip="Time and Fees"
                    />
                    <img
                      *ngIf="
                        +element[columnIndex]?.value?.split('|')[3] ===
                        feeType.FixedPrice
                      "
                      src="assets/images/fixedfees.svg"
                      width="15px"
                      alt="Fixed Price"
                      matTooltip="Fixed Price"
                    />
                    <img
                      *ngIf="
                        +element[columnIndex]?.value?.split('|')[3] ===
                        feeType.NonBillable
                      "
                      src="assets/images/nonbillable.svg"
                      width="14px"
                      alt="Non Billable"
                      matTooltip="Non Billable"
                    />
                    {{
                      element[columnIndex]?.value?.indexOf("|") > 0
                        ? element[columnIndex]?.value?.split("|")[4]
                        : element[columnIndex]?.value
                    }}

                    <mat-icon
                      *ngIf="
                        element[columnIndex]?.value?.indexOf('|') > 0
                          ? +element[columnIndex]?.value?.split('|')[5] === 1
                          : +element[columnIndex]?.value === 1
                      "
                      >south</mat-icon
                    >
                    <mat-icon
                      class="danger"
                      *ngIf="
                        element[columnIndex]?.value?.indexOf('|') > 0
                          ? +element[columnIndex]?.value?.split('|')[5] === 0
                          : +element[columnIndex]?.value === 0
                      "
                      >north</mat-icon
                    >
                    {{
                      element[columnIndex]?.value?.indexOf("|") > 0
                        ? element[columnIndex]?.value?.split("|")[6]
                        : element[columnIndex]?.value
                    }}
                  </div>
                  <div class="time-detail onelineclamp ml-10 pl-10">
                    {{
                      element[columnIndex]?.value?.indexOf("|") > 0
                        ? element[columnIndex]?.value?.split("|")[7] +
                          (element[columnIndex]?.value?.split("|")[9]
                            ? " - " + element[columnIndex]?.value?.split("|")[9]
                            : "")
                        : element[columnIndex]?.value
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div class="time-coment">
              <button
                mat-button
                class="w-auto h-auto"
                [matMenuTriggerFor]="menu1"
                #menuTrigger="matMenuTrigger"
                disableRipple
                [ngClass]="{ 'link-disable': element[1]?.value === '' }"
              >
                <mat-icon
                  fontSet="material-icons-outlined"
                  class="cursor-pointer"
                  [ngClass]="
                    element[1]?.value === ''
                      ? 'text-primary not-comment'
                      : 'text-primary comment'
                  "
                  matTooltipPosition="right"
                  >comment</mat-icon
                >
              </button>
              <mat-menu
                [overlapTrigger]="false"
                #menu1="matMenu"
                class="contact-detail time-tooltip"
              >
                <span (click)="$event.stopPropagation()">
                  <div class="sidenav-body-content">
                    <div class="form-field-title">
                      <p class="font-size-16 pb-1 text-gray">
                        <strong>Description</strong>
                      </p>
                    </div>
                    <div class="tooltip-list comment-des">
                      <ul>
                        <li>
                          <div [innerHTML]="element[1]?.value"></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </span>
              </mat-menu>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span
              class="onelineclamp"
              title="{{ element[columnIndex]?.value }}"
              *ngIf="columnIndex === 2 && rowIndex < timelog.data.length - 1"
            >
              {{ element[columnIndex]?.value }}
            </span>
          </div>

          <!-- For not (1st column && last 2 columns && last Row)-->
          <span
            *ngIf="
              columnIndex !== 0 &&
              columnIndex !== 1 &&
              columnIndex !== 2 &&
              columnIndex < displayedColumns.length - 2 &&
              rowIndex < timelog.data.length - 1
            "
          >
            <div class="timepicker timepicker-duration day-week">
              <div class="timepicker-input">
                <div
                  class="time-picker d-flex align-items-center justify-content-center gap-10"
                >
                  <ngx-timepicker-field
                    [format]="24"
                    [disabled]="
                      +element[10]?.value !==
                        timelogActionStatus.CanEditOrDelete ||
                      timeModeId === timeMode.StartAndEnd ||
                      isFromOverview ||
                      element[columnIndex].invoiceId !== null
                    "
                    [(ngModel)]="element[columnIndex].value"
                    [ngClass]="{
                      'light-bg':
                        +element[10]?.value !==
                          timelogActionStatus.CanEditOrDelete ||
                        timeModeId === timeMode.StartAndEnd ||
                        isFromOverview ||
                        element[columnIndex].invoiceId !== null
                    }"
                    (timeChanged)="onTimeChange(columnIndex, rowIndex)"
                  ></ngx-timepicker-field>

                  <div
                    class="d-flex align-items-center timer-buttons"
                    *ngIf="col === todayDate"
                  >
                    <div *ngIf="col === this.todayDate">
                      <button
                        class="action-button"
                        type="button "
                        mat-button
                        disableRipple
                        [disabled]="
                          selection.selected.length > 0
                            ? !selection.isSelected(element)
                            : false
                        "
                        [disabled]="
                          +element[10]?.value !==
                          timelogActionStatus.CanEditOrDelete
                        "
                        (click)="onStartDayViewTimer(element)"
                      >
                        <mat-icon class="text-primary"
                          >play_circle_outline</mat-icon
                        >
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <!-- For last Row or For last secound column For week view-->
          <div
            class="text-align-center fw-bold space-right"
            *ngIf="rowIndex === timelog.data.length - 1 && columnIndex === 0"
          >
            {{ element[columnIndex]?.value }}
          </div>

          <!-- For last Column but not for last 2 rows-->
          <span
            *ngIf="
              columnIndex === displayedColumns.length - 1 &&
              rowIndex < timelog.data.length - 1
            "
          >
            <button
              type="button"
              mat-button
              disableRipple
              (click)="onDeleteClick(element)"
              title="{{ notificationTextMessage.deleteTimesheetMessage }}"
              *ngIf="
                +element[10]?.value === timelogActionStatus.CanEditOrDelete &&
                element[10]?.invoiceId === null
              "
            >
              <mat-icon>highlight_off</mat-icon>
            </button>
            <mat-icon
              *ngIf="
                +element[10]?.value === timelogActionStatus.CanEditOrDelete &&
                element[10]?.invoiceId !== null
              "
              class="text-success"
              title="{{ notificationTextMessage.timelogInvoicedMessage }}"
              >lock</mat-icon
            >
            <mat-icon
              *ngIf="+element[10]?.value === timelogActionStatus.Approved"
              class="text-success"
              title="{{ notificationTextMessage.approvedTimesheetMessage }}"
              >lock</mat-icon
            >
            <mat-icon
              *ngIf="+element[10]?.value === timelogActionStatus.Rejected"
              class="text-danger-100"
              title="{{ notificationTextMessage.rejectedTimesheetMessage }}"
              >lock</mat-icon
            >
            <img
              *ngIf="+element[10]?.value === timelogActionStatus.Completed"
              [src]="'assets/images/check.svg'"
              [alt]="'Check'"
              title="{{ notificationTextMessage.jobCompletedMessage }}"
              width="17px"
            />
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [ngClass]="[
          timelog.data ? 'highlightLastRow' : '',
          isRowHighlighted ? 'highlightRow' : ''
        ]"
      ></tr>
    </table>
  </div>
  <div
    class="norecord-chart text-align-center mt-4"
    *ngIf="isDayView && timelog.data.length === 1"
  >
    <img src="assets/images/day-view-icon.svg" alt="Timer" />
    <p class="pt-1 no-border" *ngIf="moduleId !== modules.Timelog">
      No records found for today
    </p>
    <p class="pt-1 no-border" *ngIf="moduleId === modules.Timelog">
      This is the Day view of your timesheet. <br />
      Keep track of where your time goes, down to the minute.
    </p>
  </div>
</div>
