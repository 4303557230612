<div class="mat-dialog-wrapper timelog-popup">
  <mat-dialog-content>
    <form [formGroup]="timeLogForm">
      <div class="main-contain">
        <div class="sidenav-header">
          <div class="d-flex align-items-center justify-space-between">
            <h2 class="m-0">
              <span>Log Time</span>
            </h2>
            <div mat-dialog-close>
              <mat-icon class="close">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="sidenav-body">
        <div class="d-flex gap-30">
          <div class="form-field w-230">
            <p class="fw-bold">Job ID</p>
            <ng-select
              formControlName="jobId"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              (change)="onJobSelectionChange($event)"
              appearance="fill"
            >
              <ng-option [value]="defaultGuid">--Select--</ng-option>
              <ng-option
                *ngFor="let job of jobList"
                [value]="job.universalId"
                >{{ job.jobCode }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">
              Client
              <span class="text-red">*</span>
            </p>
            <ng-select
              #client
              (focus)="client.open()"
              (valueChange)="client.close()"
              formControlName="clientId"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              (change)="onClientSelectionChange($event)"
              appearance="fill"
              required
              [disabled]="moduleId === moduleEnum.Jobs"
            >
              <ng-option [value]="defaultGuid">--Select--</ng-option>
              <ng-option
                *ngFor="let client of clientList"
                [value]="client.universalId"
                >{{ client.name }}</ng-option
              >
            </ng-select>
          </div>
          <div class="form-field w-230">
            <p class="fw-bold">
              Task
              <span class="text-red">*</span>
            </p>
            <mat-form-field
              class="wp-100"
              [ngClass]="moduleId === moduleEnum.Jobs ? 'light-bg' : ''"
              appearance="fill"
            >
              <input
                type="text"
                matInput
                formControlName="taskId"
                [matAutocomplete]="auto"
                maxlength="100"
                #trigger="matAutocompleteTrigger"
                appAutocompletePosition="trigger"
                (input)="onTaskSearch()"
                [readonly]="moduleId === moduleEnum.Jobs"
              />
              <mat-icon class="downarrow" matSuffix
                >keyboard_arrow_down</mat-icon
              >
              <mat-autocomplete
                #auto="matAutocomplete"
                role="listbox"
                class="task-dropdown"
                (optionSelected)="onTaskSelectionChange($event)"
                #input
                (opened)="(input.opened)"
              >
                <mat-optgroup
                  *ngFor="let group of taskList"
                  [label]="group.groupName"
                >
                  <div class="scrollable-content">
                    <mat-option
                      *ngFor="let task of group.data"
                      [value]="task.name"
                    >
                      <ng-container
                        *ngIf="
                          group.groupName === 'Assigned';
                          else otherContent
                        "
                      >
                        <img
                          width="18"
                          alt="icon"
                          [src]="'assets/images/check.svg'"
                        /> </ng-container
                      >&nbsp;&nbsp;
                      <ng-template #otherContent>
                        <ng-container *ngIf="group.groupName !== 'Assigned'">
                          <img
                            width="18"
                            alt="icon"
                            [src]="'assets/images/close-icon.svg'"
                          />
                        </ng-container>
                      </ng-template>
                      {{ task.name }}
                    </mat-option>
                  </div>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-30">
          <div class="form-field w-230">
            <p class="fw-bold">Subtask</p>
            <ng-select
              #subtask
              (focus)="subtask.open()"
              (valueChange)="subtask.close()"
              formControlName="subTaskId"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              (change)="onSubTaskSelectionChange($event)"
              appearance="fill"
            >
              <ng-option [value]="defaultGuid">--Select--</ng-option>
              <ng-option
                *ngFor="let subTask of subTaskList"
                [value]="subTask.universalId"
                >{{ subTask.name }}</ng-option
              >
            </ng-select>
          </div>
          <div
            class="form-field w-230"
            *ngIf="isRegularUser && userList.length === 1"
          >
            <p>User <span class="text-red">*</span></p>
            <mat-form-field appearance="fill">
              <input
                matInput
                autocomplete="off"
                formControlName="users"
                readonly
              />
            </mat-form-field>
          </div>
          <div
            class="form-field w-230"
            *ngIf="isOwner || isAdmin || (isRegularUser && userList.length > 1)"
          >
            <p class="fw-bold">
              User
              <span class="text-red">*</span>
            </p>
            <ng-select
              #user
              (focus)="user.open()"
              (valueChange)="user.close()"
              formControlName="userId"
              panelClass="myPanelClass"
              [disableOptionCentering]="true"
              (change)="onUserSelectionChange($event)"
              appearance="fill"
              required
              [items]="userList"
              [groupBy]="getGroupName"
              bindLabel="name"
              bindValue="universalId"
            >
            </ng-select>
          </div>

          <div class="form-field w-230">
            <p class="fw-bold">
              Date
              <span class="text-red">*</span>
            </p>
            <mat-form-field (click)="picker.open()" class="wp-100">
              <input
                matInput
                formControlName="date"
                readonly
                [matDatepicker]="picker"
                autocomplete="off"
                required
                (dateChange)="onDateChange($event)"
              />
              <mat-datepicker-toggle
                class="suffix-text"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex gap-30">
          <div
            class="form-field w-230"
            *ngIf="
              timeModeId === timeMode.StartAndEnd ||
              timelogDetailModel?.isCalendarView
            "
          >
            <p class="fw-bold">Start Time</p>
            <mat-form-field appearance="fill" class="wp-100">
              <input
                type="time"
                autocomplete="off"
                matInput
                [(ngModel)]="startTime"
                [ngModelOptions]="{ standalone: true }"
                (change)="onTimeChanged()"
              />
            </mat-form-field>
          </div>
          <div
            class="form-field w-230"
            *ngIf="
              timeModeId === timeMode.StartAndEnd ||
              timelogDetailModel?.isCalendarView
            "
          >
            <p class="fw-bold">End Time</p>
            <mat-form-field appearance="fill" class="wp-100">
              <input
                type="time"
                autocomplete="off"
                matInput
                [(ngModel)]="endTime"
                [ngModelOptions]="{ standalone: true }"
                (change)="onTimeChanged()"
              />
            </mat-form-field>
            <div *ngIf="isInvalidEndTime" class="invalidEndTime">
              End time must be greater than start time
            </div>
          </div>
          <div class="form-field w-230 timepicker-input duration-time">
            <p class="fw-bold">Time Spent</p>
            <ngx-timepicker-field
              class="wp-100 full-width"
              [format]="24"
              [disabled]="
                timeModeId === timeMode.StartAndEnd ||
                timelogDetailModel?.isCalendarView === true
              "
              [(ngModel)]="timeSpent"
              [ngModelOptions]="{ standalone: true }"
            ></ngx-timepicker-field>
          </div>
        </div>
      </div>
      <div class="form-field textarea mb-1">
        <p>Description</p>
        <mat-form-field class="wp-100" appearance="fill">
          <textarea
            matInput
            formControlName="description"
            autocomplete="off"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="d-flex pb-5">
        <button
          class="action-button primary-button mr-1"
          type="button"
          mat-button
          disableRipple
          (click)="addTimeLog()"
          [disabled]="isAlreadyApprovedRejectedTimelog"
        >
          Save
        </button>
        <button
          class="action-button secondary-button"
          mat-dialog-close
          disableRipple
          type="button"
          mat-button
          disableRipple
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
<app-custom-notifications></app-custom-notifications>
