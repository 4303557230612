import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ClientState,
  EstimatesState,
  InvoiceState,
  TaskState,
  UserState,
} from '@app/core/store';
import { JobState } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  EntityTypes,
  ModuleHeaderActivity,
  ModuleName,
  Modules,
  RoutingPath,
} from 'src/app/core/enums';
import { ActivityTask } from 'src/app/core/models';
import { GlobalComponent } from 'src/app/core/models/common';
import { ActivityTaskParameters } from 'src/app/core/models/query-params/query-params';
import { CommonService } from 'src/app/core/services';
import { GetActivityList } from 'src/app/core/store/activity/activity.action';

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.scss'],
})
export class ActivityDetailsComponent implements OnInit {
  @Input() getModuleId: number;
  @Input() activityDetails: any;
  hasAttachments = false;

  activityList: any = [];
  attachment: any[];
  moduleName: string;
  entityTypes = EntityTypes;

  selectedModule = 0;
  id = 0;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  universalId = this.defaultUniversalId;
  selectedEntityTypeId = 1;

  selectedStartDate = '';
  selectedEndDate = '';
  message = '';
  routeUrl = RoutingPath.addClient;

  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityTask>;

  @Input()
  triggerLoadTimeLineData: Observable<any>;

  @Input()
  triggerActivityDetails: Observable<any>;

  @Input()
  triggerSave: Observable<any>;

  @Output()
  readonly triggerActivityCount = new EventEmitter<any>();
  constructor(
    private store: Store,
    public globalComponent: GlobalComponent,
    public commonService: CommonService,
    public datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.selectedModule = this.getModuleId;

    this.triggerLoadTimeLineData?.subscribe(() => {
      this.activityList = [];
      this.setId();
      this.getList();
    });

    this.triggerActivityDetails?.subscribe(() => {
      this.createMessage(this.activityDetails.activityModels);
    });

    this.triggerSave?.subscribe(() => {
      this.setId();
      this.getList();
    });
  }

  onDownloadAttachmemt(url): void {
    const params = {
      fileUrl: url,
    };

    this.commonService.downloadFile(params).subscribe();
  }

  setId(): void {
    if (this.selectedModule === Modules.Users) {
      this.universalId =
        this.store.selectSnapshot(UserState.getUserId) ?? this.universalId;
    }
    if (
      this.selectedModule === Modules.Invoice ||
      this.selectedModule === Modules.RecurringInvoice
    ) {
      this.universalId =
        this.store.selectSnapshot(InvoiceState.getInvoiceId) ??
        this.defaultUniversalId;
    }
    if (this.selectedModule === Modules.Estimate) {
      this.universalId =
        this.store.selectSnapshot(EstimatesState.getEstimateId) ??
        this.defaultUniversalId;
    }

    if (this.selectedModule === Modules.Tasks) {
      this.universalId =
        this.store.selectSnapshot(TaskState.getTaskId) ??
        this.defaultUniversalId;
    }

    if (this.selectedModule === Modules.Clients) {
      this.universalId =
        this.store.selectSnapshot(ClientState.getClientId) ??
        this.defaultUniversalId;
    }

    if (this.selectedModule === Modules.Job) {
      this.universalId =
        this.store.selectSnapshot(JobState.getJobId) ?? this.defaultUniversalId;
    }
  }

  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    let key = Object.keys(enumName)[indexOfS];
    key = key.replace(/([A-Z])/g, ' $1').trim();

    return key;
  }

  createTimeMessage(items: any, moduleName: string, name: string): void {
    if (items.entityTypeId === EntityTypes.Submitted) {
      // John Submitted timesheet for the week 24-Jun-2022 to 31-Jun-2022 for approval
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheet for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong> for approval';
    }
    if (items.entityTypeId === EntityTypes.Resubmitted) {
      // John Resubmitted timesheet for the week 24-Jun-2022 to 31-Jun-2022 for  approval
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheet for the week  <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong> for approval';
    }
    if (items.entityTypeId === EntityTypes.Reminder) {
      // Arena Sent reminder to John, Smith, Bob for submiting the timesheet for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' reminder to <strong>' +
        name +
        '</strong> for submiting the timesheet for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Sent) {
      // Arena Sent email to John, Smith, Bob with notes 'Sent notes will be here'  for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        'Sent email to <strong>' +
        name +
        ' </strong> with notes Sent notes will be here for the week ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to <strong>' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Approved) {
      // Arena Approved timesheets of John, Smith, Bob for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' timesheets of <strong>' +
        name +
        '</strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Withdrawn) {
      // Arena withdrawn approval on the timesheet submitted by John, smith for the week 24-Jun-2022 to 31-Jun-2022
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' approval on the timesheet <strong>' +
        name +
        ' </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
  }

  createExpenseMessage(items: any, moduleName: string, name: string): void {
    if (items.entityTypeId === EntityTypes.Created) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' an expense for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong> to the <strong>' +
        this.datePipe.transform(items.expenseDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Submitted) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' expenses for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong>for the week <strong> ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Approved) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        '  expenses submitted by <strong>' +
        name +
        '</strong> for the amount of <strong>' +
        items.entities[0].amount +
        ' GBP </strong>for the week <strong> ' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Sent) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        " a note '" +
        items.entities[0].note +
        "' on email to <strong>" +
        name +
        ' </strong> on expense submitted for the amount of <strong> ' +
        items.entities[0].amount +
        ' GBP </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Withdrawn) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' approval on the expenses submitted by <strong>' +
        name +
        '</strong> for the amount of <strong> ' +
        items.entities[0].amount +
        ' GBP </strong> for the week <strong>' +
        this.datePipe.transform(items.startDate, 'dd-MMM-yyyy') +
        ' to ' +
        this.datePipe.transform(items.endDate, 'dd-MMM-yyyy') +
        '</strong>';
    }
    if (items.entityTypeId === EntityTypes.Reminder) {
      this.message +=
        'Sent ' +
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' expense to <strong>' +
        name +
        '</strong> for the amount due <strong>' +
        items.entities[0].amount +
        ' GBP</strong>';
    }

    if (items.entityTypeId === EntityTypes.Rejected) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' expense <strong>' +
        name +
        '</strong> for the amount due <strong>' +
        items.entities[0].amount +
        ' GBP</strong>';
    }

    switch (items.entityTypeId) {
      case EntityTypes.ExportedPDF:
        this.message += 'Exported the ';
        this.message += moduleName + ' on PDF';
        break;
      case EntityTypes.ExportedEXCEL:
        this.message += 'Exported the ';
        this.message += moduleName + ' on Excel';
        break;
      case EntityTypes.ExportedCSV:
        this.message += 'Exported the ';
        this.message += moduleName + ' on CSV';
        break;
      default:
        this.message +=
          this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
          ' ' +
          moduleName +
          ' ';
        break;
    }
  }
  createJobMessage(items: any, name: string): void {
    if (items.entityTypeId === EntityTypes.Created) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        '  <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
    if (items.entityTypeId === EntityTypes.Updated) {
      this.message +=
        this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
        ' <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
    if (items.entityTypeId === EntityTypes.Comment) {
      this.message +=
        'added comment' +
        ' <strong>"' +
        items.entities[0].note +
        '"</strong> on <strong>' +
        items.entities[0].code +
        ' </strong>';
    }
  }

  createMessage(val: any): void {
    this.activityList = [];
    this.activities = val;
    if (this.activities) {
      this.activities.forEach((items) => {
        this.message = ' ';
        const moduleName = this.getEmunKeyByValue(
          items.moduleId,
          ModuleHeaderActivity
        );
        const emailId = items.entities[0].toEmail?.map((x) => x).join(',');

        const name = items.entities?.map((x) => x.name).join(',');
        if (this.selectedModule === ModuleHeaderActivity.Time) {
          this.createTimeMessage(items, moduleName, name);
        } else if (this.selectedModule === ModuleHeaderActivity.Expense) {
          this.createExpenseMessage(items, moduleName, name);
        } else if (this.selectedModule === ModuleHeaderActivity.Job) {
          this.createJobMessage(items, name);
        } else {
          switch (items.entityTypeId) {
            case EntityTypes.ExportedPDF:
              this.message += 'Exported the ';
              this.message += moduleName + ' on PDF';
              break;
            case EntityTypes.ExportedEXCEL:
              this.message += 'Exported the ';
              this.message += moduleName + ' on Excel';
              break;
            case EntityTypes.ExportedCSV:
              this.message += 'Exported the ';
              this.message += moduleName + ' on CSV';
              break;
            case EntityTypes.NoteCreated:
              this.message +=
                'added a new notes as' +
                ' <strong>"' +
                items.entities[0].note +
                '"</strong> on ' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Recorded:
              this.message +=
                ' Recorded payment invoice <strong>' +
                items.entities[0].name +
                '</strong> for <strong>' +
                items.entities[0].amount +
                '</strong> GBP via <strong>' +
                items.entities[0].paymentMethodName +
                '</strong>';
              break;
            case EntityTypes.RecieptSent:
              this.message +=
                ' Sent receipt to <strong>" ' +
                emailId +
                '"</strong> for the payment of ' +
                items.entities[0].amount +
                'GBP on the' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Sent:
              this.message +=
                ' Sent Invoice <a class="link fw-bold">' +
                items.entities[0].name +
                '</a> to ' +
                emailId +
                ' via <strong>' +
                items.entities[0].providerType +
                '</strong>';
              break;
            case EntityTypes.DeleteAll:
              this.message +=
                ' Deleted all the payments and reminders that are made on the ' +
                moduleName +
                ' ';
              break;
            case EntityTypes.Status:
              // Arena updated invoice INV124 status from Draft to Sent
              this.message +=
                ' Updated ' +
                moduleName +
                '  <strong>' +
                name +
                '</strong> status from <strong>' +
                items.entities[0].previousStatus +
                '</strong>  to  <strong>' +
                items.entities[0].newStatus +
                '</strong>';
              break;
            case EntityTypes.Recurring:
              this.message +=
                'Created a recurring ' +
                moduleName +
                ' with schedule ' +
                items.entities[0].customMessage +
                ' ';
              break;
            case EntityTypes.Reminder:
              this.message +=
                ' Sent Reminder invoice <strong>' +
                items.entities[0].name +
                '</strong> for the amount due <strong>' +
                items.entities[0].amount +
                ' GBP</strong> to ' +
                emailId;
              break;

            case EntityTypes.DeletePayment:
              this.message +=
                'Deleted the payment from invoice <strong>' +
                items.entities[0].name +
                '</strong> for the <strong>' +
                items.entities[0].amount +
                '</strong> GBP paid via <strong>' +
                items.entities[0].paymentMethodName +
                '</strong>';
              break;

            case EntityTypes.UpdatedBasicInfo:
              this.message +=
                'Updated ' +
                moduleName +
                ' <strong>' +
                items.entities[0].name +
                '</strong> basic info';
              break;

            case EntityTypes.UpdatedReminders:
              this.message +=
                'Updated ' +
                moduleName +
                ' <strong>' +
                items.entities[0].name +
                '</strong> reminders';
              break;

            default:
              this.message +=
                this.getEmunKeyByValue(items.entityTypeId, EntityTypes) +
                ' ' +
                moduleName +
                ' ';
              break;
          }
        }

        items.entities.forEach((entity) => {
          this.attachment = entity.attachments;
          this.hasAttachments = entity.hasAttachment ?? false;
        });

        const obj = {
          id: items.entities[0].id,
          createdByName: items.createdByName,
          name: items.entities[0].name,
          message: this.message,
          entityTypeId: items.entityTypeId,
          activityDate: items.activityDate,
          hasAttachment: this.hasAttachments,
          attachments: this.attachment,
        };
        this.activityList.push(obj);
      });
    }
  }

  getSingularModuleName(moduleName) {
    return moduleName.substring(
      0,
      moduleName.toLowerCase() === ModuleName.Taxes
        ? moduleName.length - 2
        : moduleName.length - 1
    );
  }

  getList(): void {
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: this.selectedModule,
      search: '',
      entityId: this.universalId,
      startDate: '',
      endDate: '',
      dateFilter: 1,
      pageNumber: 0,
      pageSize: 0,
    };
    if (this.selectedModule !== null) {
      this.store
        .dispatch(new GetActivityList(this.activityTaskParameters))
        .pipe(
          tap((res) => {
            this.triggerActivityCount.emit(
              res.activity.activities
                ? res.activity.activities[0].activityModels.length
                : 0
            );
            if (res.activity.activities.length > 0) {
              this.createMessage(res.activity.activities[0].activityModels);
            }
          })
        )
        .subscribe();
    }
  }
}
