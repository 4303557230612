import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ActionType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  ApproveTimeLogModel,
  ExpenseRequestModel,
  GlobalComponent,
  SendEmailModule,
  SubmitTimeLogModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import {
  ApproveTimelog,
  RejectTimeLog,
  SendEmailNote,
  SendReminderForApproval,
  SubmitTimelog,
  UpdateExpenseStatus,
  WithdrawTimelog,
} from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-time-expense-confirmation',
  templateUrl: './time-expense-confirmation.component.html',
  styleUrls: ['./time-expense-confirmation.component.scss'],
})
export class TimeExpenseConfirmationComponent implements OnInit {
  isShowLoader = false;
  headerText: string;
  detailText: string;
  totalNumberOfRecordSelected: number;
  isButtonDisabled = false;
  expenseStatus: number;
  expenseAction: number;
  moduleId: number;
  moduleIdEnum = Modules;
  expenseRequestModelData: ExpenseRequestModel;
  isResubmitted: boolean;
  defaultUniversalId = this.globalComponent.getDefaultUniversalId();
  expenseData: {
    startDate: string;
    endDate: string;
    expenseIds: Array<Guid>;
  }[] = [];
  type: number;
  listOfData: {
    startDate: string;
    endDate: string;
    userId?: string;
    projectId?: string;
    taskId?: string;
    clientId?: string;
    expenseIds: Array<string>;
  }[] = [];
  status: number;
  submitTimeLogModelData: SubmitTimeLogModel[] = [];
  approveTimeLogModelData: ApproveTimeLogModel[] = [];
  sendEmailModule: SendEmailModule[] = [];
  emailDataList: any;
  noteForm: FormGroup;
  actionType = ActionType;
  noteHeader: string;

  formBuilder: UntypedFormBuilder;
  spinner: NgxSpinnerService;
  store: Store;
  commonService: CommonService;
  datepipe: DatePipe;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public injector: Injector,
    public globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<TimeExpenseConfirmationComponent>
  ) {
    this.formBuilder =
      this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.store = this.injector.get<Store>(Store);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.datepipe = this.injector.get<DatePipe>(DatePipe);
  }

  ngOnInit(): void {
    this.noteForm = this.formBuilder.group({
      message: new FormControl<string | null>('', Validators.required),
    });
    this.expenseAction = this.data.expenseAction;
    this.headerText = this.data.headerText;
    this.detailText = this.data.detailText;
    this.expenseData = this.data.expenseData;
    this.moduleId = this.data.moduleId;
    this.listOfData = this.data.listOfData;
    this.totalNumberOfRecordSelected = this.data.totalNumberOfRecordSelected;
    this.status = this.data.status;
    this.isResubmitted = this.data.isResubmitted;
    this.type = this.data.type;
    this.noteHeader = this.data.noteHeader;
  }

  onSendClick(): void {
    if (!this.noteForm.invalid) {
      this.isButtonDisabled = true;
      this.listOfData.forEach((x) => {
        this.sendEmailModule.push({
          userId: x.userId ?? this.defaultUniversalId,
          startDate: this.datepipe
            .transform(x.startDate, 'yyyy-MM-dd')
            ?.toString(),
          endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd')?.toString(),
          message: this.noteForm.controls.message.value,
          uRL: `${environment.uiUrl}time/timesheet`,
        });
      });

      if (this.status === ActionType.Reminder) {
        this.store
          .dispatch(new SendReminderForApproval(this.sendEmailModule))
          .subscribe(
            (data: any) => {
              if (data.time.isReminderSend) {
                this.commonService.onSuccess(
                  NotificationTextMessage.sentReminder
                );
                this.dialogRef.close(data.time.isReminderSend);
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
                this.dialogRef.close();
              }
            },
            (error: any) => {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
              this.dialogRef.close();
            }
          );
        this.isButtonDisabled = false;
      } else if (this.status === ActionType.Email) {
        this.store.dispatch(new SendEmailNote(this.sendEmailModule)).subscribe(
          (data: any) => {
            if (data.time.isEmailNoteSend) {
              this.commonService.onSuccess(
                NotificationTextMessage.emailSendSucessfully
              );
              this.dialogRef.close(data.time.isEmailNoteSend);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
              this.dialogRef.close();
            }
          },
          (error: any) => {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.dialogRef.close();
          }
        );
      }
    }
  }

  onRejectClick(): void {
    if (!this.noteForm.invalid) {
      this.isButtonDisabled = true;
      this.listOfData.forEach((x) => {
        this.sendEmailModule.push({
          userId: x.userId ?? this.defaultUniversalId,
          startDate: this.datepipe
            .transform(x.startDate, 'yyyy-MM-dd')
            ?.toString(),
          endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd')?.toString(),
          message: this.noteForm.controls.message.value,
          uRL: `${environment.uiUrl}time/rejected`,
        });
      });

      this.store.dispatch(new RejectTimeLog(this.sendEmailModule)).subscribe(
        (data: any) => {
          if (data.time.isTimelogRejected) {
            this.commonService.onSuccess(
              NotificationTextMessage.timeSheetRejected
            );
            this.dialogRef.close(data.time.isTimelogRejected);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.dialogRef.close();
          }
        },
        (error: any) => {
          this.dialogRef.close();
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      );
      this.isButtonDisabled = false;
    }
  }

  onWithdrawClick(): void {
    if (!this.noteForm.invalid) {
      this.isButtonDisabled = true;
      this.listOfData.forEach((x) => {
        this.sendEmailModule.push({
          userId: x.userId ?? this.defaultUniversalId,
          startDate: this.datepipe
            .transform(x.startDate, 'yyyy-MM-dd')
            ?.toString(),
          endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd')?.toString(),
          message: this.noteForm.controls.message.value,
          uRL: `${environment.uiUrl}time/timesheet`,
          isApproved: this.moduleId === Modules.TimeApproved ? true : false,
        });
      });

      this.store.dispatch(new WithdrawTimelog(this.sendEmailModule)).subscribe(
        (data: any) => {
          if (data.time.isWithdraw) {
            this.commonService.onSuccess(
              this.moduleId === Modules.TimeApproved
                ? NotificationTextMessage.approveTimeLogWithdrawn
                : NotificationTextMessage.rejectedTimeLogWithdrawn
            );
            this.dialogRef.close(data.time.isWithdraw);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.dialogRef.close();
          }
        },
        (error: any) => {
          this.dialogRef.close();
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      );
      this.isButtonDisabled = false;
    }
  }

  pendingApprovalData(): void {
    this.listOfData.forEach((x) => {
      this.approveTimeLogModelData.push({
        userId: x.userId ?? this.defaultUniversalId,
        startDate: this.datepipe.transform(x.startDate, 'yyyy-MM-dd')!,
        endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd')!,
        projectId: x.projectId ?? this.defaultUniversalId,
        uRL: `${environment.uiUrl}time/approved`,
      });
    });
  }

  approveTimelog(): void {
    this.isShowLoader = true;
    this.isButtonDisabled = true;
    this.store
      .dispatch(new ApproveTimelog(this.approveTimeLogModelData))
      .subscribe(
        (data: any) => {
          this.isShowLoader = false;
          this.isButtonDisabled = false;
          if (data.time.isApproved) {
            this.commonService.onSuccess(
              NotificationTextMessage.approvedTimelog
            );
            this.dialogRef.close(data.time.isApproved);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (error: any) => {
          this.isShowLoader = false;
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
          this.isButtonDisabled = false;
          this.dialogRef.close();
        }
      );
  }

  submittedData(): void {
    this.listOfData.forEach((x) => {
      this.submitTimeLogModelData.push({
        userId: x.userId ?? this.defaultUniversalId,
        startDate: this.datepipe.transform(x.startDate, 'yyyy-MM-dd')!,
        endDate: this.datepipe.transform(x.endDate, 'yyyy-MM-dd')!,
        isResubmitted: this.isResubmitted ?? false,
        uRL: `${environment.uiUrl}time/pfa`,
      });
    });
  }

  submitTimelog(): void {
    this.isButtonDisabled = true;
    if (this.submitTimeLogModelData.length > 0) {
      this.store
        .dispatch(new SubmitTimelog(this.submitTimeLogModelData))
        .subscribe(
          (data: any) => {
            this.isButtonDisabled = false;
            if (data.time.isSubmitted) {
              this.commonService.onSuccess(
                this.isResubmitted
                  ? NotificationTextMessage.resubmitForApprovalTimelog
                  : NotificationTextMessage.submitForApprovalTimelog
              );

              this.dialogRef.close(data.time.isSubmitted);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (error: any) => {
            this.isShowLoader = false;
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.isButtonDisabled = false;
            this.dialogRef.close();
          }
        );
    }
  }

  dataSubmit(): void {
    this.expenseData.forEach((x) => {
      this.expenseRequestModelData = {
        expenseIds: x.expenseIds,
        status: this.expenseAction,
        url: `${environment.uiUrl}expenses/expenseList`,
        userId: this.globalComponent.getLoggedInUserId(),
      };
    });
  }

  onYesClick(): void {
    if (this.moduleId === Modules.Expenses) {
      this.dataSubmit();

      this.spinner.show();
      this.store
        .dispatch(new UpdateExpenseStatus(this.expenseRequestModelData))
        .subscribe(
          (data: any) => {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
            this.dialogRef.close(NotificationHeader.success);
          },
          (error: any) => {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.dialogRef.close();
          }
        );
    }

    if (
      this.moduleId === Modules.TimeUnsubmitted ||
      this.moduleId === Modules.Time
    ) {
      this.submittedData();
      this.submitTimelog();
    }

    if (this.moduleId === Modules.TimePFA) {
      this.pendingApprovalData();
      this.approveTimelog();
    }
  }
}
