import { Guid } from 'guid-typescript';
import { ExportType, ImportStep } from '../../enums';

export class ImportRequestModel {
  file: File;
  type: ExportType;
}

export class FileImportRequestModel {
  file: File;
  step: ImportStep;
  isDuplicate?: boolean;
  dataFieldList?: Array<string>;
  isFromCapium3: boolean;
  addonId: string;
  isRecurringImport: boolean;
  clientTypes: Array<string>;
}

export class ImportModel {
  universalId: Guid;
  fileName: string;
  moduleId: number;
  fileUrl: string;
  status: boolean;
  createdBy: number;
  createdOn: number;
  moduleName: string;
  createdByUserName: string;
  fileSize: string;
}

export class ImportMapFieldModel {
  sysFieldId: string;
  mapFieldId: string;
}

export class SaveImportRequestModel {
  customerId: string;
  customerName: string;
  moduleName: string;
  email: string;
  phone: string;
  message: string;
}
