import { ActivityEntity } from './activity-entity';

export class ActivityLogModel {
  month: string;
  year: number;
  activityModels: Array<ActivityTask>;
}

export class ActivityTask {
  createdByName: string;
  createdByPhoto: string;
  moduleId: number;
  entityTypeId: number;
  businessId: number;
  createdById: number;
  activityDate: string;
  startDate: string;
  endDate: string;
  expenseDate: string;
  entities: Array<ActivityEntity>;
}

export class AuthorisedMenuModel {
  id: number;
  name: string;
  displayName: string;
  parentId: 0;
  moduleUrl: string;
  moduleAddUrl: string;
  iconName: string;
  permissions: [
    {
      id: 0;
      name: string;
    }
  ];
}
