import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FeeType,
  Modules,
  NotificationTextMessage,
  TimeMode,
  TimelogActionStatus,
} from '@app/core/enums';
import { TimeLogModel } from '@app/core/models';
import { CommonService } from '@app/core/services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.scss'],
})
export class TimesheetListComponent implements OnInit {
  displayedColumns: string[] = [];
  isFromOverview = false;
  isDayView = false;
  timelog: TimeLogModel = {
    columns: [],
    data: [],
    allDates: [],
    status: 0,
  };
  timeModeId?: number;
  timeMode = TimeMode;
  todayDate? = '';
  today = new Date();
  selection = new SelectionModel<any>(true, []);
  timesheetListSubscription: Subscription;
  feeType = FeeType;
  modules = Modules;
  notificationTextMessage = NotificationTextMessage;
  timelogActionStatus = TimelogActionStatus;
  isRowHighlighted = false;
  selectedCells: any[] = [];

  @Input() moduleId: number;

  @Input() triggerTimehseetListData: Observable<any>;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly editClick = new EventEmitter<any>();

  constructor(public datepipe: DatePipe, private commonService: CommonService) {
    this.todayDate = this.datepipe.transform(this.today, 'EE dd-MMM') ?? '';
  }

  ngOnInit(): void {
    this.timesheetListSubscription = this.triggerTimehseetListData?.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.isDayView = data.isDayView;
          this.isFromOverview = data.isFromOverview;
          this.timelog = data.timelogData;
          this.displayedColumns = data.displayedColumns;
          this.timeModeId = data.timeModeId;
          this.isRowHighlighted = data.isSort;
          this.selectedCells = [];
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.timesheetListSubscription?.unsubscribe();
  }

  selectRow(row): void {
    this.selection.clear();
    this.selection.toggle(row);
  }

  onDeleteClick(element: any): void {
    this.deleteClick.emit(element);
  }

  onEditTimeSheetClick(element): void {
    this.editClick.emit(element);
  }

  onStartDayViewTimer(element: any): void {
    const data = {
      jobId: element[5]?.value,
      clientId: element[6]?.value,
      taskId: element[7]?.value,
      universalId: element[11]?.value,
      jobCode: element[0]?.value?.split('|')[4],
      clientName: element[0]?.value
        ?.split('|')[6]
        .replace('(', '')
        .replace(')', ''),
      taskName: element[0]?.value?.split('|')[7],
    };

    const timerData = {
      showTimer: true,
      jobData: data,
    };
    this.commonService.showTimerPopup(timerData);
  }

  onTimeChange(newColumnIndex, newRowIdex): void {
    const newCell = {
      columnIndex: newColumnIndex,
      rowIndex: newRowIdex,
    };

    const existingCell = this.selectedCells.find(
      (cell) =>
        cell.columnIndex === newColumnIndex && cell.rowIndex === newRowIdex
    );

    if (!existingCell) {
      this.selectedCells.push(newCell);
    }
  }
}
