<h2 mat-dialog-title>{{ headerText }}</h2>
<mat-dialog-content>
  <div>
    <p class="text-muted fw-bold">Selected Items</p>
    <p class="font-size-18 fw-bold">
      <span class="text-muted font-size-20">Records:</span>
      {{ totalNumberOfRecordSelected }}
    </p>
  </div>
  <p class="font-size-14" [innerHTML]="detailText"></p>
</mat-dialog-content>

<mat-dialog-actions class="m-0">
  <div>
    <div class="button-container">
      <div class="spinner-container" *ngIf="isButtonDisabled">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        (click)="onYesClick()"
        [disabled]="isButtonDisabled"
      >
        Yes
      </button>
    </div>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      No
    </button>
  </div>
</mat-dialog-actions>
