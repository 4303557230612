import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  CommonNotificationText,
  Modules,
  NotificationTextMessage,
  PermissionType,
} from '@app/core/enums';
import {
  GlobalComponent,
  JobCommentListModel,
  JobCommentModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { GetJobCommentList, SaveJobComment } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-job-details-comments',
  templateUrl: './job-details-comments.component.html',
  styleUrls: ['./job-details-comments.component.scss'],
})
export class JobDetailsCommentsComponent implements OnInit {
  commentList: JobCommentListModel[];
  commentForm: FormGroup;
  commentData: JobCommentModel;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  jobId = this.defaultUniversalId;
  jobCommentSubscription: Subscription;
  commonNotificationText = CommonNotificationText;
  permissionType = PermissionType;
  isViewPermission = false;
  moduleId = Modules.JobComment;

  @Input()
  triggerJobId: Observable<any>;

  @Output()
  readonly triggerCommentCount = new EventEmitter<any>();

  constructor(
    private globalComponent: GlobalComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.jobCommentSubscription = this.triggerJobId.subscribe((data) => {
      this.jobId = data;
      this.cancelClick();
      this.getJobCommentList();
    });

    this.isViewPermission = this.commonService.isViewPermission(this.moduleId);
  }

  ngOnDestroy(): void {
    this.jobCommentSubscription?.unsubscribe();
  }

  cancelClick(): void {
    this.commentForm.reset();
  }

  getJobCommentList(): void {
    this.store
      .dispatch(new GetJobCommentList(this.jobId))
      .pipe(
        tap((res) => {
          this.commentList = res.job.commentList;
          this.triggerCommentCount.emit(
            this.commentList ? this.commentList.length : 0
          );
        })
      )
      .subscribe();
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.commentData = {
        message: this.commentForm.controls.message.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  submit(): void {
    if (this.commentForm.invalid) {
      this.commonService.addValidation(this.commentForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new SaveJobComment(this.jobId, this.commentData))
          .pipe()
          .subscribe(
            (res) => {
              if (res.job.isCommentSubmitted) {
                this.cancelClick();
                this.getJobCommentList();
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setForm(): void {
    this.commentForm = this.formBuilder.group({
      message: new FormControl<string>('', Validators.required),
    });
  }
}
