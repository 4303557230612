import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { MainListParameters } from 'src/app/core/models/common/query-params';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss'],
})
export class CustomPaginatorComponent implements OnInit {
  showPaginator = true;

  @Input()
  length: number | null;

  @Input()
  listParameters: MainListParameters;

  @Input()
  pageSizeChanged: number;

  pageSize = 50;
  pageSizestr = '50';

  math = Math;

  preventAutoPageSync = false;

  numPages: number;

  page1: number = 1;
  page2: number = 2;
  page3: number = 3;
  page4: number = 4;
  page5: number = 5;

  @Output()
  readonly pageSizeVal = new EventEmitter<number>();

  @Output()
  readonly togglePaginator = new EventEmitter<any>();

  private readonly paginationObservable = new Subject<any>();

  @Input()
  triggerOnSearch: Observable<any>;

  @Output()
  readonly pageChanged = new EventEmitter<number>();
  paginatorForm: FormGroup;

  constructor() {
    this.paginatorForm = new FormGroup({
      pageNumber: new FormControl<string | null>('', [Validators.required]),
    });
  }

  onKeyUp(key: any): void {
    const pageNumber = Math.ceil(this.length / this.pageSize);
    if (key.key === 'Enter') {
      if (this.paginatorForm.controls.pageNumber.value > pageNumber) {
        this.paginatorForm.controls.pageNumber.setValue(pageNumber);
      }
      if (this.paginatorForm.controls.pageNumber.value <= 0) {
        this.paginatorForm.controls.pageNumber.setValue(1);
      }
    }
    this.paginationObservable.next(key);
  }

  onEnterKey(): void {
    const pageNo = this.paginatorForm.controls.pageNumber.value;
    const pageNumber = this.paginatorForm.controls.pageNumber.value;
    this.paginatorForm.controls.pageNumber.setValue(
      pageNumber > 0 && pageNumber <= Math.ceil(this.length / this.pageSize)
        ? pageNumber
        : 1
    );

    if (pageNumber > this.page5) {
      this.paginatorForm.controls.pageNumber.setValue(this.page5);
      this.goToNextPage(pageNo, false);
    } else if (pageNumber < this.page1) {
      this.paginatorForm.controls.pageNumber.setValue(this.page1);
      this.goToPriviousPage(pageNo, false);
    } else if (pageNumber <= this.page5 && pageNumber >= this.page1) {
      this.paginatorForm.controls.pageNumber.setValue(pageNumber);
      this.pageChanged.emit(pageNumber);
    }
  }

  goToPriviousPage(pageNo: any, isPrevious: boolean): void {
    const pageNumber: number = this.paginatorForm.controls.pageNumber.value;
    if (
      this.paginatorForm.controls.pageNumber.value === '' ||
      pageNumber === 1 ||
      pageNumber > Math.ceil(this.length / this.pageSize)
    ) {
      return;
    }

    if (this.page1 === pageNumber) {
      this.page1 = pageNumber - 4;
      this.page2 = pageNumber - 3;
      this.page3 = pageNumber - 2;
      this.page4 = pageNumber - 1;
      this.page5 = pageNumber;
    }

    let tempPageNumber = 0;
    if (isPrevious) {
      tempPageNumber = pageNumber - 1;
      this.paginatorForm.controls.pageNumber.setValue(pageNumber - 1);
    } else {
      tempPageNumber = pageNo;
      this.paginatorForm.controls.pageNumber.setValue(pageNo);
    }

    this.pageChanged.emit(tempPageNumber);
  }

  goToNextPage(pageNo: any, isNext: boolean): void {
    if (this.paginatorForm.controls.pageNumber.value === '') {
      this.paginatorForm.controls.pageNumber.setValue(1);
    }
    const pageNumber: number = this.paginatorForm.controls.pageNumber.value;

    if (pageNumber >= Math.ceil(this.length / this.pageSize)) {
      return;
    }

    if (this.page5 === pageNumber) {
      this.page1 = pageNumber;
      this.page2 = pageNumber + 1;
      this.page3 = pageNumber + 2;
      this.page4 = pageNumber + 3;
      this.page5 = pageNumber + 4;
    }
    let tempPageNumber = 0;
    if (isNext) {
      tempPageNumber = pageNumber + 1;
      this.paginatorForm.controls.pageNumber.setValue(pageNumber + 1);
    } else {
      tempPageNumber = pageNo;
      this.paginatorForm.controls.pageNumber.setValue(pageNo);
    }

    this.pageChanged.emit(tempPageNumber);
  }

  goToLastPage(): void {
    const pageNumber = Math.ceil(this.length / this.pageSize);
    if (pageNumber > Math.ceil(this.length / this.pageSize)) {
      return;
    }
    if (pageNumber < Math.ceil(this.length / this.pageSize)) {
      this.page1 = pageNumber - 4;
      this.page2 = pageNumber - 3;
      this.page3 = pageNumber - 2;
      this.page4 = pageNumber - 1;
      this.page5 = pageNumber;
    }

    this.paginatorForm.controls.pageNumber.setValue(pageNumber);

    this.pageChanged.emit(pageNumber);
  }

  goToFirstPage(): void {
    const pageNumber = 1;
    if (pageNumber > Math.ceil(this.length / this.pageSize)) {
      return;
    }

    this.page1 = pageNumber;
    this.page2 = pageNumber + 1;
    this.page3 = pageNumber + 2;
    this.page4 = pageNumber + 3;
    this.page5 = pageNumber + 4;

    this.paginatorForm.controls.pageNumber.setValue(pageNumber);

    this.pageChanged.emit(pageNumber);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.length || changes.pageSize) {
      this.updateNumPages();
      this.goToFirstPage();
    }
  }

  updateNumPages(): void {
    this.numPages = Math.ceil(this.length / this.pageSize);
  }

  ngOnInit(): void {
    this.pageSize = this.listParameters.pageSize ?? 50;
    this.pageSizestr = this.listParameters.pageSize.toString() ?? '50';

    this.paginatorForm.controls.pageNumber.setValue(
      this.listParameters.pageNumber
    );
    this.onEnterKey();

    this.updateNumPages();
    this.triggerOnSearch?.subscribe(() => {
      this.updateNumPages();
    });
  }

  onSelectionChange(val: number): void {
    this.numPages = Math.ceil(this.length / +val);
    this.paginatorForm.controls.pageNumber.setValue(1);
    this.pageSizeVal.emit(+val);
    this.pageSize = +val;
    this.pageSizestr = val.toString();
    this.goToFirstPage();
  }

  onPageNumberClick(val: number): void {
    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  onPageNumberLastClick(val: number): void {
    if (val !== Math.ceil(this.length / this.pageSize)) {
      this.page1 = val;
      this.page2 = val + 1;
      this.page3 = val + 2;
      this.page4 = val + 3;
      this.page5 = val + 4;
    }

    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  onPageNumberFirstClick(val: number): void {
    if (val !== 1) {
      this.page1 = val - 4;
      this.page2 = val - 3;
      this.page3 = val - 2;
      this.page4 = val - 1;
      this.page5 = val;
    }

    this.paginatorForm.controls.pageNumber.setValue(
      val > 0 && val <= Math.ceil(this.length / this.pageSize) ? val : 1
    );

    this.paginatorForm.controls.pageNumber.setValue(val);

    this.pageChanged.emit(val);
  }

  togglePagination() {
    this.showPaginator = !this.showPaginator;
    this.togglePaginator.emit(this.showPaginator);
  }
}
