import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FileImportRequestModel } from '../../models/common/import';
import { ExportParams1 } from '../../models/common/query-params';
import { SideListModel } from '../../models/common/side-list';
import {
  ExpenseType,
  ExpenseTypeGridModel,
} from '../../models/expense/expense-type';
import { QueryParams } from '../../models/query-params/query-params';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ExpenseTypeService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  saveExpenseType(expenseType: ExpenseType): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/ExpenseType/saveExpenseType`,
      JSON.stringify(expenseType),
      headers
    );
  }

  getExpenseTypeDetailList(
    queryParams: QueryParams
  ): Observable<HttpResponse<ExpenseTypeGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/ExpenseType/executeExpenseTypeList`,
      queryParams,
      httpOptions
    );
  }

  getALlExpenseType(): Observable<Array<ExpenseType>> {
    return this.http.get<Array<ExpenseType>>(
      `${environment.apiVersionUrl}/ExpenseType/getAllExpenseTypes`
    );
  }

  getExpenseTypeById(universalId: Guid): Observable<ExpenseType> {
    return this.http.get<ExpenseType>(
      `${environment.apiVersionUrl}/ExpenseType/getExpenseTypeByUniversalId/${universalId}`
    );
  }

  export(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/ExpenseType/export`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getExpenseTypeList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/ExpenseType/expenseTypesList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  deleteExpenseTypes(expenseTypesIds?: Array<Guid>): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(expenseTypesIds),
    };

    return this.http.delete<any>(
      `${environment.apiVersionUrl}/ExpenseType/deleteExpenseTypeByUniversalIds`,
      options
    );
  }

  copy(expenseTypesIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/ExpenseType/copyExpenseTypesByUniversalIds`,
      JSON.stringify(expenseTypesIds),
      headers
    );
  }

  archiveAndRestoreExpenseTypes(
    expenseTypesIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      ids: expenseTypesIds,
      universalIds: expenseTypesIds,
      status: isArchive,
    };

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/ExpenseType/updateExpenseTypeStatus`,
      JSON.stringify(statusModel),
      headers
    );
  }

  importExpenseType(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/ExpenseType/import`,
      formData
    );
  }
}
