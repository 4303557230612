import { Guid } from 'guid-typescript';
import { EmailTemplate } from '../../enums';
import { Note } from '../notes/notes';
import { AppliedTaxModel } from '../tax/taxes';

export class EstimateData {
  universalId?: Guid;
  clientId: number;
  clientName: string;
  totalHours: number;
  totalCreated: number;
  totalAccepted: number;
  totalDeclined: number;
  data: Array<Estimates>;
}

export class Estimates {
  universalId?: Guid;
  estimateNo: string;
  createdOn?: string;
  acceptedDate?: Date;
  expiresDate?: string;
  status?: number;
  logo: string;
  ref: string;
  clientId?: string;
  totalAmount?: number;
  isDeleted?: boolean;
  isSelected?: boolean;
  poid: string;
  lastSentOn?: Date;
  footer: string;
  estimateDetails: Array<EstimateDetail>;
  estimateNotes?: Array<Note>;
  discountName?: string;
  discountAmount?: number;
  isDiscountInPercentage?: boolean;
  costName?: string;
  costAmount?: number;
  isCostInPercentage?: boolean;
  statusUpdatedOn?: Date;
  updatedOn?: Date;
  estimateTaxes: Array<AppliedTaxModel>;
}

export class EstimateEmailModel {
  subject?: string;
  message?: string;
  isSendFromCapium?: boolean;
  sendCopyToSelf?: boolean;
  toEmail?: Array<string>;
  fromEmail?: string;
  templateId?: EmailTemplate;
  entityId?: Guid; // this is used as estimate id
  id?: number;
  providerAccountId?: Guid;
}

export class EstimateHistoryModel {
  id: number;
  createdOn?: Date;
  lastScheduleDate?: Date;
  lastRemiderSentDate?: Date;
  lastPaymentReceivedDate?: Date;
  amountDue?: number;
  status?: number;
  lastSentOn?: Date;
}

export class EstimateDetail {
  universalId?: number;
  estimateId: string;
  clientId: string;
  type: string;
  projectId?: string;
  taskId?: string;
  projectName: string;
  taskDescription: string;
  hoursAndQuantity?: number;
  price?: number;
  amount?: number;
  isDeleted?: boolean;
  isArchived?: boolean;
}

export class EstimateSummary {
  totalEstimates: number;
  totalClients: number;
  totalCreated: number;
  totalAccepted: number;
  totalDeclined: number;
}
