import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  InvoiceAmountByInvoiceMonthDataModel,
  InvoiceOverviewRequestModel,
} from '@app/core/models';
import { GetInvoiceAmountByInvoiceMonth } from '@app/core/store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-fees-invoice-month',
  templateUrl: './fees-invoice-month.component.html',
  styleUrls: ['./fees-invoice-month.component.scss'],
})
export class FeesInvoiceMonthComponent implements OnInit {
  public primaryXAxis?: Object;
  public chartData?: Object[];
  public title?: string;
  public primaryYAxis?: Object;
  // public tooltip?: Object;
  public tooltip: Object = {
    enable: true,
    header: '<b>£ {point.tooltip}</b>',
    shared: true,
  };

  invoiceAmountByInvoiceMonthData: InvoiceAmountByInvoiceMonthDataModel;

  filter = 1;
  start: string = '';
  end: string = '';
  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  overview: any;

  @Input()
  triggerFilterData: any;

  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<span>${point.tooltip}</span>',
      header: '',
    };
    this.chartData = [];
    this.primaryXAxis = {
      valueType: 'Category',
    };

    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getInvoiceAmountByInvoiceMonth();
    });
  }

  getInvoiceAmountByInvoiceMonth(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(
        new GetInvoiceAmountByInvoiceMonth(this.invoiceOverviewRequestModel)
      )
      .pipe()
      .subscribe(
        (res) => {
          this.overview = res.overview.invoiceAmountByInvoiceMonthData;
          if (this.overview) {
            this.chartData = this.overview;
          } else {
            this.chartData = [];
          }
        },
        (error) => {
          this.chartData = [];
        }
      );
  }
}
