import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { CookieService } from 'ngx-cookie-service';
import { of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DummyService } from '../services';

@Injectable()
export class AuthResolverService implements Resolve<any> {
  constructor(
    private dummyService: DummyService,
    private cookieService: CookieService,
    private oidcSecurityService: OidcSecurityService,
    private http: HttpClient
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    return this.dummyService.getLoggedInUser().then((value) => {
      const storedStartTime = localStorage.getItem('startTime');
      const storedPausedTime = localStorage.getItem('pausedTime');

      if (!value.isSubscribed) {
        this.cookieService.deleteAll();
        this.oidcSecurityService.logoff();
        return false;
      } else {
        if (value.isExpired && (storedStartTime || storedPausedTime)) {
          if (
            this.cookieService.get('isRefreshToken') === '' ||
            this.cookieService.get('isRefreshToken') === 'false'
          ) {
            this.cookieService.set('isRefreshToken', 'true');
            const refreshToken = this.cookieService.get('refreshToken')
              ? this.cookieService.get('refreshToken')
              : this.oidcSecurityService.getRefreshToken();

            this.dummyService
              .getTokenUsingRefreshToken(refreshToken, environment.clientId)
              .pipe(
                switchMap((response) => {
                  this.cookieService.set('isRefreshToken', 'false');
                  this.cookieService.set('newToken', response.access_token);
                  this.cookieService.set(
                    'refreshToken',
                    response.refresh_token
                  );

                  return of(response.access_token);
                }),
                catchError((error) => {
                  return throwError('Error');
                })
              )
              .subscribe();
          }
        } else if (value.isExpired && !storedStartTime && !storedPausedTime) {
          this.cookieService.deleteAll();
          this.oidcSecurityService.logoff();
        }

      }
      return !value.isExpired ? true : false;
    });
  }
}
