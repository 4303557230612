export enum FeeType {
  TimeAndFee = 1,
  FixedPrice = 2,
  NonBillable = 3,
}

export enum FeesType {
  'Time And Fee' = 1,
  'Fixed Price' = 2,
  'Non Billable' = 3,
}
