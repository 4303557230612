<div class="d-flex h-100">
  <div
    class="side-list"
    [ngClass]="[
      !dataSourceSelectedLength && selectedId === commonService.defaultGuidValue
        ? 'edit'
        : '',
      closeMenu ? 'hide-sidelist' : ''
    ]"
  >
    <div class="side-list-wrapper h-100">
      <div
        class="d-flex justify-space-between align-items-center side-list-header"
      >
        <div class="d-flex align-items-center">
          <mat-checkbox
            [(ngModel)]="isAllSelected"
            (change)="selectAll($event)"
            disableRipple
          ></mat-checkbox>
          <span class="clamp side-list-header-text" title="{{ headerText }}">
            {{ headerText }}</span
          >
        </div>
        <div class="side-list-icon">
          <button
            mat-button
            class="search"
            disableRipple
            (click)="toggleSearchBar()"
          >
            <mat-icon [ngClass]="showSearchBar ? 'text-primary' : ''"
              >search</mat-icon
            >
          </button>
          <button
            mat-button
            class="edit-add-form"
            #button
            disableRipple
            (click)="addClick()"
            name="{{ gridActionsText.Create }}"
          >
            <mat-icon>{{
              isAddMode ? "add_circle" : "add_circle_outline"
            }}</mat-icon>
          </button>
        </div>
      </div>
      <div class="side-list-search">
        <mat-form-field
          appearance="fill"
          class="mini-search-field search-field wp-100"
          *ngIf="showSearchBar"
        >
          <input
            matInput
            placeholder="Search"
            #searchText
            autocomplete="off"
            (keyup)="onSearch($event)"
          />

          <mat-icon>search</mat-icon>
          <a
            href="javascript:void(0)"
            (click)="onCancel()"
            (click)="searchText.value = ''"
          >
            <mat-icon class="close">highlight_off</mat-icon>
          </a>
        </mat-form-field>
      </div>
      <div
        class="mat-table-wrapper"
        (scroll)="onTableScroll($event)"
        [ngClass]="showSearchBar ? 'mat-table-wrapper-full' : ''"
      >
        <table aria-describedby="side-list" mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              [ngClass]="{
                highlightRecord: isActive(element),
                active: isActive(element)
              }"
            >
              <div class="list-wrapper">
                <div class="d-flex align-items-center">
                  <mat-checkbox
                    [(ngModel)]="element.isSelected"
                    (change)="onCheckBoxSelected($event)"
                    disableRipple
                  >
                  </mat-checkbox>
                  <img
                    src="assets/images/timeandfees.svg"
                    *ngIf="element.feeTypeId === feeType.TimeAndFee"
                    width="15px"
                    alt="Time and Fees"
                    matTooltip="Time and Fees"
                    class="pr-10"
                  />
                  <img
                    src="assets/images/fixedfees.svg"
                    *ngIf="element.feeTypeId === feeType.FixedPrice"
                    width="18px"
                    alt="Fixed Price"
                    matTooltip="Fixed Price"
                    class="pr-10"
                  />
                  <img
                    src="assets/images/nonbillable.svg"
                    *ngIf="element.feeTypeId === feeType.NonBillable"
                    width="15px"
                    alt="Non Billable"
                    matTooltip="Non Billable"
                    class="pr-10"
                  />
                  <a
                    href="javascript:void(0)"
                    class="list-name clamp"
                    title="{{ element.name }}"
                    (click)="editClick(element)"
                    >{{ element.name }}</a
                  >
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="isRowHighlighted ? 'highlightRow' : ''"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedColumns.length"
            >
              <span *ngIf="listParameters && !listParameters.search">{{
                commonNotificationText.NoRecordsFound
              }}</span>
              <span *ngIf="listParameters && listParameters.search">{{
                commonNotificationText.NoRecordsFoundForFilter
              }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <app-side-list-actions
    class="side-list-actions-wrapper"
    [getModuleId]="getModuleId"
    [toggleSideList]="toggleSideList"
    [gridActions]="gridActions"
    (deleteClick)="deleteClick()"
    (exportClick)="exportClick($event, false)"
    (copyClick)="copyClick()"
    (cancelSelectionClick)="cancelSelectionClick()"
    (printClick)="printClick()"
    (endRecurringInvoiceClick)="endRecurringInvoiceClick()"
    (archiveAndRestoreClick)="archiveAndRestoreClick($event)"
    [triggerNumberOfRecords]="triggerNumberOfRecords"
    (triggerReloadSideList)="onReloadSideList()"
    (updateJobStatusClick)="updateJobStatusClick($event)"
    (acceptClick)="statusChangeClick(feeStatus.Accepted)"
    (declineClick)="statusChangeClick(feeStatus.Declined)"
    (revertClick)="statusChangeClick(feeStatus.Draft)"
    (markSentClick)="statusChangeClick(feeStatus.Sent)"
    (timelogSubmitClick)="onSubmitClick()"
    (timelogReminderClick)="onEmailClick()"
    (timelogApproveClick)="onApproveClick()"
    (timelogRejectClick)="onRejectClick()"
    (timelogSendClick)="onSendNoteClick()"
    (timelogWithdrawApprovalClick)="onWithdrawApproval()"
    (timelogWithdrawRejectionClick)="onWithdrawRejection()"
  >
  </app-side-list-actions>
</div>
<div
  class="menu-toggle"
  (click)="menuToggle()"
  [ngClass]="closeMenu ? 'toggle-icon' : ''"
>
  <mat-icon>{{
    closeMenu ? "keyboard_arrow_right" : "keyboard_arrow_left"
  }}</mat-icon>
</div>
