import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { AuthResolverService } from '@app/core/guards/auth.resolver';
import { CalendarListComponent } from './calendar-list/calendar-list.component';
import { JobDetailsComponent } from './job-details/job-details.component';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobsComponent } from './jobs.component';

const routes: Routes = [
  {
    path: '',
    component: JobsComponent,
    children: [
      {
        path: 'jobList',
        component: JobsListComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'calendarList',
        component: CalendarListComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'jobDetails',
        component: JobDetailsComponent,
        resolve: {
          result: AuthResolverService,
        },
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class JobsRoutingModule {}
