import { Guid } from 'guid-typescript';
import { EmailTemplate } from '../../enums';

export class InvoiceData {
  clientId: number;
  clientName: string;
  totalHours: number;
  totalBillableHours: number;
  totalBillableAmount: number;
  totalItemCount: number;
  data: Array<Invoices>;
}

export class Invoices {
  universalId?: Guid;
  clientId: Guid;
  userId: Guid;
  invoiceNo: string;
  invoiceDate: Date;
  dueDate: Date;
  totalAmount: number;
  paidAmount: number;
  reference: string;
  externalEstimateId: string;
  invoiceType: number;
  isDeleted?: boolean;
  isSelected?: boolean;
  status: number;
  isEmailSendFromCapium?: boolean;
  toEmailAddress: string;
  emailSubject: string;
  emailMessage: string;
  isSendAutomatically?: boolean;
  isRecurring: boolean;
  footer: string;
  invoiceDetails: Array<InvoiceDetail>;
  isSaveAndSend: boolean;
  isBlankInvoice: boolean;
  schedule?: string;
  sentOn?: Date;
  clientFullName?: string;
}

export class InvoiceDetail {
  universalId?: Guid;
  invoiceId: Guid;
  typeId: number;
  itemId?: string;
  itemName: string;
  subItemId?: string;
  subItemName: string;
  totalQuantity?: number;
  price?: number;
  amount?: number;
  isTrackable?: boolean;
}

export class InvoiceRequest {
  invoiceId: Guid;
  typeId: number;
  projectId: string;
  taskId: string;
  expenseTypeId: number;
}

export class InvoiceResponse {
  hoursAndQuantity: number;
  price?: number;
  amount?: number;
}

export class InvoicePaymentModel {
  universalId?: Guid;
  invoiceId?: Guid;
  paymentId?: Guid;
  amount?: number;
  paymentMethodId: number;
  paymentMethodName?: string;
  paymentDate: Date;
  isSendReceiptToClient?: boolean;
  amountDue?: number;
  totalItemCount?: number;
}

export class InvoiceSendAsLinkModel {
  invoiceId: string;
  invoiceLink: string;
  toEmail: string;
}

export class InvoiceEmailModel {
  subject?: string;
  message?: string;
  isSendFromCapium?: boolean;
  sendCopyToSelf?: boolean;
  toEmail?: Array<string>;
  fromEmail?: string;
  templateId?: EmailTemplate;
  entityId?: Guid; // this is used as invoice id
  id?: number;
  providerAccountId?: Guid;
}

export class RecurringSchedule {
  universalId: Guid;
  firstRecurrenceDate: string | null;
  endRecurrenceDate?: string | null;
  maxNumOfOccurrences?: number;
  separationCount?: number;
  recurringTypeId: number;
  dayOfWeek?: number | null;
  dayOfMonth?: number | null;
  weekOfMonth?: number | null;
  monthOfYear?: number | null;
  isDeleted?: boolean;
  schdeuleId?: string;
  recurringEndTypeId?: number | null;
  subRecurringTypeId?: number | null;
}

export class InvoiceHistoryModel {
  id: number;
  createdOn?: Date;
  lastScheduleDate?: Date;
  lastRemiderSentDate?: Date;
  lastPaymentReceivedDate?: Date;
  amountDue?: number;
  receivedAmount?: number;
  totalAmount?: number;
}

export class ReminderTypeModel {
  id: number;
  typeId: number;
  name: string;
  isActive: boolean;
}

export class InvoiceReminderModel {
  invoiceId: string;
  reminders: Array<ReminderTypeModel>;
}

export class InvoiceDynamicDetailGrid {
  moduleId: number;
  settings: Array<InvoiceDetailGrid>;
}

export class InvoiceDetailGrid {
  id: number;
  isSelected: boolean;
  name: string;
  optionId: number;
  typeId: number;
  value: string;
}

export class ServiceExpenseModel {
  expenseId: string;
  expenseNo: string;
  hoursAndQuantity: number;
  price: number;
  amount: number;
}

export class InvoiceSummary {
  totalInvoices: number;
  totalClients: number;
  totalDueAmount: number;
  totalRaisedAmount: number;
  totalReceivedAmount: number;
}
