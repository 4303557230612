<div
  class="sidenav-body"
  *ngIf="
    moduleName !== module.InvoiceEdit &&
    moduleName !== module.InvoiceDelete &&
    moduleName !== module.EndSchedule &&
    moduleName !== module.RenameColumn
  "
>
  <mat-dialog-content>
    <div class="main-contain">
      <div class="sidenav-header hr-bottom pb-10 mb-5">
        <div class="d-flex align-items-center justify-space-between">
          <h2 mat-dialog-title class="mb-0">{{ headerText }}</h2>
          <div>
            <mat-icon class="close" mat-dialog-close>close</mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        confirmationList.type !== confirmationType.Mail &&
        confirmationList.type !== confirmationType.Demo &&
        confirmationList.type !== confirmationType.recordPaymentDelete &&
        confirmationList.type !== confirmationType.ApplyMinimumChargeableTime
      "
    >
      <p class="text-muted mb-5 fw-bold">Selected Items</p>
      <p class="font-size-18 fw-bold">
        <span class="text-muted font-size-20">Records:</span>
        {{ totalNumberOfRecordSelected }}
      </p>
    </div>
    <p class="font-size-14" [innerHTML]="detailText"></p>

    <ng-container
      *ngIf="
        confirmationList.type === confirmationType.Delete &&
        (confirmationList.moduleId === modules.Clients ||
          confirmationList.moduleId === modules.Tasks ||
          confirmationList.moduleId === modules.ExpenseType ||
          confirmationList.moduleId === modules.Users ||
          confirmationList.moduleId === modules.Invoice ||
          confirmationList.moduleId === modules.Estimate ||
          confirmationList.moduleId === modules.RecurringInvoice ||
          confirmationList.moduleId === modules.Jobs)
      "
    >
      <p class="mt-1 text-red d-flex align-items-center">
        <img src="assets/images/danger.svg" class="mr-1" alt="Danger" /> All
        relevant data associated with these {{ moduleText }}(s) will also be
        deleted in this module.<br />
        <span
          style="display: contents"
          *ngIf="confirmationList.moduleId === modules.Users"
        >
          This user will be marked as inactive in 'Time And Fees' module.
        </span>
      </p>

      <p class="mt-2">
        <mat-checkbox
          class="example-margin"
          [(ngModel)]="confirmDelete"
          required="true"
          >Delete by selecting the box
        </mat-checkbox>
      </p>
    </ng-container>

    <div *ngIf="false">
      <p class="font-size-16 text-align-left mt-1">
        Select modules to grant access to this user :
      </p>
      <div class="invite-check">
        <mat-checkbox>Time And Fees</mat-checkbox>
        <mat-checkbox class="inactive" checked="true" [disabled]="true"
          >Charity</mat-checkbox
        >
        <mat-checkbox class="inactive" checked="true" [disabled]="true"
          >Bookkeeping</mat-checkbox
        >
        <mat-checkbox>Account Production</mat-checkbox>
        <mat-checkbox>Corporation Tax</mat-checkbox>
        <mat-checkbox>Self Assessment</mat-checkbox>
        <mat-checkbox>Self Assessment</mat-checkbox>
        <mat-checkbox>Payroll</mat-checkbox>
        <mat-checkbox>Capisign</mat-checkbox>
        <mat-checkbox>Capium Hub</mat-checkbox>
        <mat-checkbox>My Admin</mat-checkbox>
      </div>
    </div>

    <ng-container
      *ngIf="
        confirmationList.type === confirmationType.Archive &&
        confirmationList.isArchive === true &&
        (confirmationList.moduleId === modules.Clients ||
          confirmationList.moduleId === modules.Tasks ||
          confirmationList.moduleId === modules.ExpenseType ||
          confirmationList.moduleId === modules.Users ||
          confirmationList.moduleId === modules.Invoice ||
          confirmationList.moduleId === modules.Estimate ||
          confirmationList.moduleId === modules.RecurringInvoice)
      "
    >
      <p class="mt-10 text-red d-flex align-items-center">
        <img src="assets/images/danger.svg" class="mr-1" alt="Danger" /> All
        relevant data associated with these {{ moduleText }}(s) will also be
        archived.<br />
        **The relevant time and expenses will be hidden until you restore these
        {{ moduleText }}(s)**<br />
        <span
          style="display: contents"
          *ngIf="confirmationList.moduleId === modules.Users"
        >
          This user not be able to access this module until you grant permission
          again.
        </span>
      </p>
    </ng-container>

    <ng-container
      *ngIf="
        confirmationList.type === confirmationType.Archive &&
        confirmationList.isArchive === false &&
        (confirmationList.moduleId === modules.Clients ||
          confirmationList.moduleId === modules.Tasks ||
          confirmationList.moduleId === modules.ExpenseType ||
          confirmationList.moduleId === modules.Users ||
          confirmationList.moduleId === modules.Invoice ||
          confirmationList.moduleId === modules.Estimate ||
          confirmationList.moduleId === modules.RecurringInvoice)
      "
    >
      <p class="mt-10 text-red d-flex align-items-center">
        <img src="assets/images/danger.svg" class="mr-1" alt="Danger" /> All
        relevant data associated with these {{ moduleText }}(s) will also be
        restored. <br />
        **With the restoration of these {{ moduleText }}(s), the relevant time
        and expenses will be shown**<br />
        <span
          style="display: contents"
          *ngIf="confirmationList.moduleId === modules.Users"
        >
          This user will be marked as inactive in 'Time And Fees' module.
        </span>
      </p>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <div>
      <button
        class="action-button secondary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
        [disabled]="!confirmDelete"
        *ngIf="
          confirmationList.type === confirmationType.Delete &&
            (confirmationList.moduleId === modules.Clients ||
              confirmationList.moduleId === modules.Tasks ||
              confirmationList.moduleId === modules.ExpenseType ||
              confirmationList.moduleId === modules.Users ||
              confirmationList.moduleId === modules.Invoice ||
              confirmationList.moduleId === modules.Estimate ||
              confirmationList.moduleId === modules.RecurringInvoice ||
              confirmationList.moduleId === modules.Jobs);
          else ShowRange
        "
      >
        Yes
      </button>
      <ng-template #ShowRange>
        <div class="button-container">
          <div class="spinner-container" *ngIf="isButtonDisabled">
            <mat-spinner diameter="24"></mat-spinner>
          </div>
          <button
            class="action-button secondary-button mr-1"
            [ngClass]="isButtonDisabled ? 'btnDisabled' : ''"
            type="button"
            mat-button
            disableRipple=""
            (click)="onYesClick()"
            [disabled]="isButtonDisabled"
          >
            Yes
          </button>
        </div>
      </ng-template>
      <button
        class="action-button primary-button"
        type="button"
        mat-button
        disableRipple
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div class="sidenav-body" *ngIf="moduleName === module.InvoiceEdit">
  <h2 mat-dialog-title class="mb-0">{{ headerText }}</h2>
  <mat-dialog-content>
    <div>
      <p>
        Sure, you want to edit the invoice? <br />

        If <strong>yes</strong>, you need to <strong> delete</strong> the
        payments and reminders recorded.
      </p>

      <p class="mt-2"><strong>Delete payments & reminders</strong></p>

      <p class="mt-2">
        All the payments & reminders of this invoice will be deleted and changes
        the status of invoice to <strong>‘Draft’</strong>. Then, you can edit
        the invoice
      </p>

      <p class="mt-2 text-red d-flex align-items-center">
        <img src="assets/images/danger.svg" class="mr-1" alt="Danger" /> This
        action cannot be reverted
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Delete Payments & Reminders
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        mat-dialog-close
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div class="sidenav-body" *ngIf="moduleName === module.InvoiceDelete">
  <h2 mat-dialog-title class="mb-0">{{ headerText }}</h2>
  <mat-dialog-content>
    <div>
      <p class="text-align-left mt-1">
        Sure, you want to delete the invoice? <br />
      </p>
      <p class="text-align-left mt-2">
        <strong> You will get deleted with all the payments & reminders</strong>
      </p>

      <p class="text-align-left mt-2">
        All the payments & reminders of this invoice will be deleted along with
        the invoice
      </p>

      <p class="text-align-left mt-2 text-red">
        <img src="assets/images/danger.svg" alt="Danger" />This action cannot be
        reverted
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Delete
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        mat-dialog-close
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div class="sidenav-body" *ngIf="moduleName === module.RenameColumn">
  <h2 mat-dialog-title class="mb-0">{{ headerText }}</h2>
  <form [formGroup]="dynamicColumnForm">
    <mat-dialog-content>
      <div>
        <mat-radio-group formControlName="dynamicColumns">
          <div class="align-center">
            <mat-radio-button
              value="1"
              [checked]="true"
              disableRipple
              class="mr-2"
              (change)="onSelectionChange('1')"
            >
              Apply these changes for this & all future invoices, recurring
              invoices and estimates</mat-radio-button
            >
            <p class="text-align-left mt-2">
              This selection will apply to entire fee section. you can change
              these from invoice under settings whenever you want. <br />
            </p>
            <mat-radio-button
              value="2"
              disableRipple
              class="mr-2"
              (change)="onSelectionChange('2')"
              >Apply these changes only to this invoice</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </div>
    </mat-dialog-content>
  </form>

  <mat-dialog-actions class="m-0 mt-1 p-0">
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Save
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        (click)="onNoClick()"
      >
        Cancel Changes
      </button>
    </div>
  </mat-dialog-actions>
</div>

<div class="sidenav-body" *ngIf="moduleName === module.EndSchedule">
  <h2 mat-dialog-title class="mb-0">{{ headerText }}</h2>
  <mat-dialog-content>
    <div>
      <p class="text-align-left mt-1">{{ detailText }}</p>
      <p
        class="text-align-left mt-2"
        *ngIf="confirmationList.type === confirmationType.EndRecurringInvoice"
      >
        <strong
          >Note: Click on yes changes the status of invoice to inactive.</strong
        >
      </p>
      <p
        class="text-align-left mt-2"
        *ngIf="confirmationList.type === confirmationType.SaveFeeType"
      >
        <strong>{{ noteText }}</strong>
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="m-0">
    <div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onYesClick()"
      >
        Yes
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        mat-button
        disableRipple
        mat-dialog-close
        (click)="onNoClick()"
      >
        No
      </button>
    </div>
  </mat-dialog-actions>
</div>
<app-custom-notifications></app-custom-notifications>
