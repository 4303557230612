import { Component } from '@angular/core';
import { CommonService } from '@app/core/services';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  constructor(
    public spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
  }

  changeOfRoutes(): void {
    this.commonService.changeOfRoutes();
  }
}
