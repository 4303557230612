<div *ngIf="overview?.length">
  <ejs-accumulationchart
    id="invoice-category"
    class="fees-dash-height payment-mlist"
    align="center"
    [legendSettings]="legendSettings"
    [tooltip]="tooltip"
    selectionMode="Point"
    (load)="load($event)"
    [enableBorderOnMouseMove]="false"
  >
    <e-accumulation-series-collection>
      <e-accumulation-series
        name="Revenue"
        [palettes]="palette"
        [dataSource]="overview"
        xName="x"
        [startAngle]="startAngle"
        yName="y"
        [radius]="radius"
        [innerRadius]="innerRadius"
        [dataLabel]="dataLabel"
        tooltipMappingName="tooltipMappingName"
      >
      </e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
</div>

<div class="norecord-chart" *ngIf="!overview?.length">
  <img src="assets/images/pia-icon.svg" alt="Pie Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
