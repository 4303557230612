import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToggleMenu {
  showIcon: Subject<boolean>;
  showtimer: Subject<any>;

  constructor() {
    this.showIcon = new Subject<boolean>();
    this.showtimer = new Subject<any>();
  }
}
