import { Component } from '@angular/core';
export interface PeriodicElement {
  name: string;
  weekone: string;
  weektwo: string;
  weekthree: string;
  weekfour: string;
  weekfive: string;
  weeksix: string;
  weekseven: string;
  total: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    name: '',
    weekone: '1:00',
    weektwo: '1:00',
    weekthree: '1:00',
    weekfour: '1:00',
    weekfive: '12:00',
    weeksix: '12:00',
    weekseven: '1:00',
    total: '26:55',
  },
  {
    name: '',
    weekone: '1:00',
    weektwo: '1:00',
    weekthree: '1:00',
    weekfour: '1:00',
    weekfive: '12:00',
    weeksix: '12:00',
    weekseven: '1:00',
    total: '26:55',
  },
  {
    name: '',
    weekone: '1:00',
    weektwo: '1:00',
    weekthree: '1:00',
    weekfour: '1:00',
    weekfive: '12:00',
    weeksix: '12:00',
    weekseven: '1:00',
    total: '26:55',
  },
  {
    name: '',
    weekone: '1:00',
    weektwo: '1:00',
    weekthree: '1:00',
    weekfour: '1:00',
    weekfive: '12:00',
    weeksix: '12:00',
    weekseven: '1:00',
    total: '26:55',
  },
];

@Component({
  selector: 'app-timelog-overview-grid',
  templateUrl: './timelog-overview-grid.component.html',
  styleUrls: ['./timelog-overview-grid.component.scss'],
})
export class TimelogOverviewGridComponent {
  displayedColumns: string[] = [
    'name',
    'weekone',
    'weektwo',
    'weekthree',
    'weekfour',
    'weekfive',
    'weeksix',
    'weekseven',
    'total',
  ];
  dataSource = ELEMENT_DATA;
}
