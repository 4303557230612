import { Guid } from 'guid-typescript';
import {
  DashboardRequestModel,
  LockUserWidgetModel,
  UserWidgetModel,
} from '../../models';

export class GetListByClient {
  static readonly type = '[DASHBOARD] Get List By Client';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByPaymentLateDays {
  static readonly type = '[DASHBOARD] Get List By Payment Late Days';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByPaymentMethod {
  static readonly type = '[DASHBOARD] Get List By Payment Method';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByStatus {
  static readonly type = '[DASHBOARD] Get List By Status';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByTask {
  static readonly type = '[DASHBOARD] Get List By Task';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByTimeSpentOnClient {
  static readonly type = '[DASHBOARD] Get List By Time Spent on Client';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByTimeSpentByHoursOnTasks {
  static readonly type = '[DASHBOARD] Get List By Time Spent by Hours on Task';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByTeamUtilizeHoursOnTasks {
  static readonly type = '[DASHBOARD] Get List By Team Utilize Hours on Task';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

export class GetListByTimeSpentByGbpOnTasks {
  static readonly type = '[DASHBOARD] Get List By Time Spent by Gbp on Task';

  constructor(
    public filter: number,
    public startDate: Date,
    public endDate: Date
  ) {}
}

// New Dashboard Changes

export class GetTeammatesExpectedAndActualCapacity {
  static readonly type =
    '[DASHBOARD] Get Teammates & Expected vs Actual Capacity Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTeamAndTaskToday {
  static readonly type = '[DASHBOARD] Get Team And Task Today';

  constructor(public selectedDate: string) {}
}

export class GetTaskWithMeToday {
  static readonly type = '[DASHBOARD] Get Task With Me Today';

  constructor(public selectedDate: string) {}
}

export class GetProjectDeadlineStatus {
  static readonly type = '[DASHBOARD] Get Project Deadline Status';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetInProgressProjectsByType {
  static readonly type = '[DASHBOARD] Get In Progress Projects By Type';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetProjectStatusByTimeCostAndExpense {
  static readonly type =
    '[DASHBOARD] Get Project Status By Time Cost And Expense';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByTopFiveProjects {
  static readonly type = '[DASHBOARD] Get Income By Top Five Projects';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByClient {
  static readonly type = '[DASHBOARD] Get Income By Client';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByProjectType {
  static readonly type = '[DASHBOARD] Get Income By Project Type';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetBillableTeammateByHours {
  static readonly type = '[DASHBOARD] Get Billable Teammate By Hours';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetBillableAndNonBillableHoursByMonth {
  static readonly type =
    '[DASHBOARD] Get Billable And Non Billable Hours By Month';

  constructor(public selectedYear: string) {}
}

export class GetIncomeByPaymentMethods {
  static readonly type = '[DASHBOARD] Get Income By Payment Methods';

  constructor(public selectedYear: string) {}
}

export class GetTeamUtilizationHoursOnClients {
  static readonly type = '[DASHBOARD] Get Team Utilization Hours On Clients';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTotalProjectHoursByTeammate {
  static readonly type = '[DASHBOARD] Get Total Project Hours By Teammate';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetBillableHoursByQuarterAndByClient {
  static readonly type =
    '[DASHBOARD] Get Billable Hours By Quarter And By Client';

  constructor(public selectedYear: string) {}
}

export class GetTimeSheetSummaryByStatus {
  static readonly type = '[DASHBOARD] Get TimeSheet Summary By Status';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTimeOffByTeammate {
  static readonly type = '[DASHBOARD] Get Time Off By Teammate';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetInvoiceHistory {
  static readonly type = '[DASHBOARD] Get Invoice History';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetProjectsCountByStatus {
  static readonly type = '[DASHBOARD] Get Projects Count By Status';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetProjectManageByUser {
  static readonly type = '[DASHBOARD] Get Project Manage By User';

  constructor(public selectedDate: string) {}
}

export class GetInvoiceHistoryGraphical {
  static readonly type = '[DASHBOARD] Get Invoice History Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByProjectTypeGraphical {
  static readonly type = '[DASHBOARD] Get Income By Project Type Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByClientGraphical {
  static readonly type = '[DASHBOARD] Get Income By Client Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetBillableHoursByQuarterAndByClientGraphical {
  static readonly type =
    '[DASHBOARD] Get Billable Hours By Quarter And By Client Graphical';

  constructor(public selectedYear: string) {}
}

export class GetTeamUtilizationHoursOnClientGraphical {
  static readonly type =
    '[DASHBOARD] Get Team Utilization Hours On Client Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTotalProjectHoursByTeammateGraphical {
  static readonly type =
    '[DASHBOARD] Get Total Project Hours By Teammate Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetBillableTeammateByHoursGraphical {
  static readonly type = '[DASHBOARD] Get Billable Teammate By Hours Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByProjectGraphical {
  static readonly type = '[DASHBOARD] Get Income By Project Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetIncomeByPaymentMethodsGraphical {
  static readonly type = '[DASHBOARD] Get Income By Payment Methods Graphical';

  constructor(public selectedYear: string) {}
}

export class GetBillableAndNonBillableHoursByMonthGraphical {
  static readonly type =
    '[DASHBOARD] Get Billable And Non Billable Hours By Month Graphical';

  constructor(public selectedYear: string) {}
}

export class GetNumberOfOngoingProjects {
  static readonly type = '[DASHBOARD] Get Number Of Ongoing Projects';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetInvoicedThisYearAsOnDate {
  static readonly type = '[DASHBOARD] Get Invoiced This Year As On Date';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetAmountReceivedThisYearAsOnDate {
  static readonly type = '[DASHBOARD] Get Amount Received This Year As On Date';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetAmountDueThisYearAsOnDate {
  static readonly type = '[DASHBOARD] Get Amount Due This Year As On Date';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetInvoiceByPaymentLateDaysGraphical {
  static readonly type =
    '[DASHBOARD] Get Invoice By Payment Late Days Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetProjectStatusByTimeCostAndExpenseGraphical {
  static readonly type =
    '[DASHBOARD] Get Project Status By Time Cost And Expense Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTimeSheetSummaryByStatusGraphical {
  static readonly type =
    '[DASHBOARD] Get TimeSheet Summary By Status Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTimeOffByTeammateGraphical {
  static readonly type = '[DASHBOARD] Get Time Off By Teammate Graphical';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetExpenseByStatus {
  static readonly type = '[DASHBOARD] Get Expense By Status';

  constructor(public status: number) {}
}

export class GetWeeklySchedule {
  static readonly type = '[DASHBOARD] Get Weekly Schedule';
}

///2.0
export class GetUserWidgets {
  static readonly type = '[DASHBOARD] Get User Widgets';
}

export class GetTimeDashboardSummary {
  static readonly type = '[DASHBOARD] Get Time Dashboard Summary';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetMostAndLeastCount {
  static readonly type = '[DASHBOARD] Get Most And Least Count';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTaskWiseHoursDetails {
  static readonly type = '[DASHBOARD] Get Task Wise Hours Details';

  constructor(
    public dashboardRequestModel: DashboardRequestModel,
    public taskId: Guid
  ) {}
}

export class SaveUserWidgets {
  static readonly type = '[DASHBOARD] Save User Widgets';

  constructor(public userWidgetModel: Array<UserWidgetModel>) {}
}

export class LockUserWidgets {
  static readonly type = '[DASHBOARD] Lock User Widgets';

  constructor(public lockUserWidgetModel: Array<LockUserWidgetModel>) {}
}

export class DeleteUserWidgetByWidgetId {
  static readonly type = '[DASHBOARD] Delete User Widget by WidgetId';

  constructor(public widgetId: number) {}
}

export class GetBillableAndNonBillableHours {
  static readonly type = '[DASHBOARD] Get Billable And NonBillable Hours';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetTimeOffByUsers {
  static readonly type = '[DASHBOARD] Get TimeOff By Users';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class GetOverallHoursDetail {
  static readonly type = '[DASHBOARD] Get Overall Hours Detail';

  constructor(
    public dashboardRequestModel: DashboardRequestModel,
    public taskId: Guid
  ) {}
}

export class GetJobCount {
  static readonly type = '[DASHBOARD] Get Job Count';

  constructor(public dashboardRequestModel: DashboardRequestModel) {}
}

export class RevertToDefaultDashboardSetting {
  static readonly type = '[DASHBOARD] Revert To Default Dashboard Setting';
}
