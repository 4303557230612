export enum EmailTemplate {
  InvoiceEmailTemplate = 1,
  InvoiceOverdueReminderTemplate = 2,
  PartialPaymentEmailTemplate = 3,
  FullPaymentEmailTemplate = 4,
  AutomaticInvoiceReminderTemplate = 5,
  ManualInvoiceReminderTemplate = 6,
  EstimateEmailTemplate = 7,
  TimeSheetSubmitted = 8,
  TimeSheetApproved = 9,
  TimeSheetCommented = 10,
  TimeSheetReminder = 11,
  ExpenseReminder = 12,
  ExpenseCommented = 13,
  ExpenseWithdrawn = 14,
  ExpenseSubmitted = 15,
  ExpenseApproved = 16,
  TimeSheetWithdrawed = 17,
  ExpenseRejected = 18,
  TimeSheetRejected = 19,
}
