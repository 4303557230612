import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { CommonService } from '@app/core/services';
import {
  ExportType,
  FeeStatus,
  GridActionsText,
  InvoiceAction,
  Modules,
} from 'src/app/core/enums';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.scss'],
})
export class CardFooterComponent {
  @Input()
  totalSelectedRecords: number;

  @Input()
  getModuleId?: number;

  @Input()
  getSubModuleName?: number;

  @Input()
  gridActions: any;

  exportType = ExportType;
  modules = Modules;
  feeStatus = FeeStatus;
  invoiceAction = InvoiceAction;
  gridActionsText = GridActionsText;
  isRegularUser = this.globalComponent.isRegularUser();

  @ViewChildren('button') buttons: QueryList<ElementRef>;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly acceptClick = new EventEmitter<any>();

  @Output()
  readonly declineClick = new EventEmitter<any>();

  @Output()
  readonly revertClick = new EventEmitter<any>();

  @Output()
  readonly markSentClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  @Output()
  readonly endRecurringInvoiceClick = new EventEmitter<any>();

  @Output()
  readonly updateJobStatusClick = new EventEmitter<{
    isMarkAsCompleted: boolean;
    isMarkAsYetToStart: boolean;
  }>();

  @Output()
  readonly reminderClick = new EventEmitter<any>();

  @Output()
  readonly submitClick = new EventEmitter<any>();

  @Output()
  readonly approveClick = new EventEmitter<any>();

  @Output()
  readonly rejectClick = new EventEmitter<any>();

  @Output()
  readonly sendNoteClick = new EventEmitter<any>();

  @Output()
  readonly withdrawClick = new EventEmitter<any>();

  constructor(
    public commonService: CommonService,
    private globalComponent: GlobalComponent
  ) {}

  ngAfterViewInit(): void {
    this.commonService.setButtonPermissions(this.buttons, this.gridActions);
  }

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  statusChangedClick(status: number): void {
    if (status === this.feeStatus.Accepted) {
      this.acceptClick.emit();
    }
    if (status === this.feeStatus.Declined) {
      this.declineClick.emit();
    }
    if (status === this.feeStatus.Draft) {
      this.revertClick.emit();
    }
    if (status === this.feeStatus.Sent) {
      this.markSentClick.emit();
    }
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }

  endRecurringInvoice(): void {
    this.endRecurringInvoiceClick.emit();
  }

  updateJobStatus(
    isMarkAsCompleted: boolean,
    isMarkAsYetToStart: boolean
  ): void {
    this.updateJobStatusClick.emit({ isMarkAsCompleted, isMarkAsYetToStart });
  }

  reminder(): void {
    this.reminderClick.emit();
  }

  submit(): void {
    this.submitClick.emit();
  }

  approve(): void {
    this.approveClick.emit();
  }

  reject(): void {
    this.rejectClick.emit();
  }

  sendNote(): void {
    this.sendNoteClick.emit();
  }

  withdraw(): void {
    this.withdrawClick.emit();
  }
}
