export class SendEmailModule {
  userId: string;
  startDate?: string;
  endDate?: string;
  uRL: string;
  projectId?: string;
  message: string;
  isApproved?: boolean;
}

export class SendEmailExpenseModel {
  expenseIds: Array<number>;
  comment: string;
  uRL: string;
}
