import { Guid } from 'guid-typescript';
import {
  ExpenseRequestModel,
  ExpensesParameters,
  ExportParams1,
  QueryParams,
  SubmitExpenseModel,
  UserExpenseParameters,
} from '../../models';
import { FileImportRequestModel } from '../../models/common/import';
import { SendEmailExpenseModel } from '../../models/common/send-email';
import { Expenses } from '../../models/expense/expenses';

export class SaveExpense {
  static readonly type = '[EXPENSE] Save Expense';

  constructor(public expenses: Expenses) {}
}

export class GetExpensesById {
  static readonly type = '[EXPENSE] Get Expenses By ID';

  constructor(public detailId: Guid) {}
}

export class ExportExpense {
  static readonly type = '[EXPENSE] Export Expense';

  constructor(public exportParams: ExportParams1) {}
}

export class DeleteSelectedExpense {
  static readonly type = '[EXPENSE] Delete Selected Expense';

  constructor(public ExpenseIds?: Array<Guid>) {}
}

export class GetUsersList {
  static readonly type = '[EXPENSE] Users List';

  constructor(
    public queryParams: QueryParams,
    public userId: Guid,
    public startDate?: Date,
    public endDate?: Date
  ) {}
}

export class UpdateExpenseStatus {
  static readonly type = '[EXPENSE] Update Expense Status';

  constructor(public expenseRequestModelData: ExpenseRequestModel) {}
}

export class WithdrawExpense {
  static readonly type = '[EXPENSE] Withdraw Expense';

  constructor(public expenseRequestModelData: ExpenseRequestModel) {}
}

export class ApproveExpense {
  static readonly type = '[EXPENSE] Approve Expense';

  constructor(public expenseRequestModelData: ExpenseRequestModel) {}
}

export class SubmitExpense {
  static readonly type = '[EXPENSE] Submit Expense';

  constructor(public submitExpenseModel: SubmitExpenseModel) {}
}

export class GetExpenseListNonAdmin {
  static readonly type = '[EXPENSE] Get Expense List Non Admin';

  constructor(public queryParams: ExpensesParameters) {}
}

export class GetExpenseList {
  static readonly type = '[EXPENSE] Get Expense List ';

  constructor(public queryParams: QueryParams) {}
}

export class GetExpenseUnSubmittedList {
  static readonly type = '[EXPENSE] Get Expense UnSubmitted List';

  constructor(public queryParams: any) {}
}

export class ImportExpense {
  static readonly type = '[EXPENSE] Import Expense';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class GetExpenseDetailList {
  static readonly type = '[EXPENSE] Get Expense Detail List';

  constructor(public expenseParams: ExpensesParameters) {}
}

export class GetExpenseNextNumber {
  static readonly type = '[EXPENSE] Get Expense Next Number';
}

export class SendExpenseEmail {
  static readonly type = '[EXPENSE] Send Expense Email';

  constructor(public sendEmailExpenseModel: SendEmailExpenseModel) {}
}

export class GetNonAdminExpenseSummary {
  static readonly type = '[EXPENSE] Get Non Admin Expense Summary';

  constructor(public summaryParamters: any) {}
}

export class GetNonAdminExpenseDetailList {
  static readonly type = '[EXPENSE] Get Non Admin Expense Detail List';

  constructor(public queryParams: ExpensesParameters) {}
}

export class GetUserExpenseByModuleId {
  static readonly type = '[EXPENSE] Get User Expense By UserId';

  constructor(
    public userId: Guid,
    public userExpenseParameters: UserExpenseParameters
  ) {}
}

export class GetExpenseByClientId {
  static readonly type = '[EXPENSE] Get Expense By ClientId';

  constructor(public universalId: Guid) {}
}
