import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LetsConnectRequestModel } from '@app/core/models/ticket';
import { environment } from '@environments/environment';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  private readonly apiUrl = `${environment.communicationFrameworkApiUrl}/`;
  private readonly routeBase = 'FreshDeskTicket';

  constructor(private http: HttpClient) {}

  getAllSupportTickets(externalUserGUID: Guid): Observable<any> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/GetTickets?UserGuid=${externalUserGUID}`
    );
  }

  saveSupportTicket(
    letsConnectRequestModel: LetsConnectRequestModel
  ): Observable<any> {
    const formData = new FormData();
    formData.append('name', letsConnectRequestModel.name);
    formData.append('emailId', letsConnectRequestModel.emailId);
    formData.append('phoneNumber', letsConnectRequestModel.phoneNumber);
    formData.append('clientName', letsConnectRequestModel.clientName);
    formData.append('supportTopic', letsConnectRequestModel.supportTopic);
    formData.append('modules', letsConnectRequestModel.modules);
    formData.append('subject', letsConnectRequestModel.subject);
    formData.append('message', letsConnectRequestModel.message);
    formData.append(
      'externalUserGuid',
      letsConnectRequestModel.externalUserGuid.toString()
    );
    for (let file of letsConnectRequestModel.file) {
      formData.append('files', file);
    }
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/LetsConnectRequestAsync`,
      formData
    );
  }
}
