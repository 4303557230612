import { FilteredStatus } from '../../enums';

export class Pagination {
  totalRecords: number;
  pageNumber = 1;
  pageSize = 20;
  sortBy = '';
  sortOrder = true;
  searchText = '';
  filter = FilteredStatus.Active;
  pageSizes: string;
  startDate: string;
  endDate: string;
}
