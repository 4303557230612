import { Guid } from 'guid-typescript';
import { ExpenseStatus, ExportType, FilteredStatus } from '../../enums';

export class QueryParams1 {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string;
  sortByNewlyAdded?: string;
  maxPageSize?: number;
  sortOrder?: boolean;
  search?: string;
  startDate?: string;
  endDate?: string;
  isRecurring?: boolean;
  moduleId?: number;
  dateFilter?: number;
}

export class ExportParams1 extends QueryParams1 {
  format?: ExportType;
  fileName?: string;
  isPrint? = false;
  userId?: Guid;
  isView? = false;
  isCopy? = false;
  expenseTypeId?: Guid;
  //ids?: Array<Guid>;
}

export class MainListParameters {
  pageNumber = 1;
  pageSize = 50;
  filter = FilteredStatus['All Status'];
  sortBy = '';
  sortOrder = true;
  search = '';
  status = ExpenseStatus['All'];
  startDate?: string;
  endDate?: string;
  format?: ExportType;
  isPrint?: boolean;
  isRowHighlighted?: false;
  userId?: Guid;
  dateFilter?: number;
}

export class JobQueryParams extends QueryParams1 {
  jobId?: Guid;
  subTaskId?: Guid;
  clientId?: Guid;
  userId?: Guid;
  monthNumber?: number;
  format?: ExportType;
  isPrint?: boolean;
  universalIds?: Guid[];
  status?: number;
  taskIds?: Guid[];
}

export class JobTimelogParams extends QueryParams1 {
  userIds?: Array<Guid>;
  status?: number;
  jobId?: Guid;
  format?: ExportType;
  isPrint?: boolean;
  groupBy?: string;
}

export class JobGroupByTimelogParams {
  jobId?: Guid;
  sortBy = '';
  sortOrder = true;
  search = '';
  groupBy: number;
}

export class TimesheetExportParams {
  dateFilter?: number;
  startDate?: string;
  endDate?: string;
  hoursType?: number;
  status?: number;
  clientIds?: Guid[];
  jobStatus?: number;
  jobIds?: Guid[];
  userIds?: Guid[];
}
