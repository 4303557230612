import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AttachmentType,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
  GlobalComponent,
  Note,
  NoteAttachmentModel,
} from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import {
  SaveEstimateNotes,
  SaveInvoiceNotes,
  UploadFileAttachements,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  noteForm: FormGroup;
  moduleId: Modules;
  acceptedFileTypes = [
    '.jpg',
    '.png',
    '.gif',
    '.xlsx',
    '.csv',
    '.pdf',
    '.jpeg',
    '.docx',
  ];
  selectedFiles: FileList;
  fileList: any[] = [];
  saveNoteData: Note;
  attachmentsFileUpload: NoteAttachmentModel[] = [];
  noteData: Array<Note>;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;

  @Input() getModuleId: number;

  @Input() universalId: Guid;

  @Output()
  readonly onCloseClick = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private globalComponent: GlobalComponent,
    private store: Store,
    private notifier: NotificationService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.moduleId = this.getModuleId;
    this.setForm();
  }

  onCancel(): void {
    this.noteForm.reset();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  setForm(): void {
    this.noteForm = this.formBuilder.group({
      notes: new FormControl<string | null>('', Validators.required),
      files: new FormControl<string | null>(''),
    });

    this.fileList = [];
  }

  onBackClick() {
    this.onCloseClick.emit();
  }

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;

      for (let file of event.target.files) {
        if (file.size / 1024 / 1024 < 2) {
          this.fileList.push(file);
        } else {
          this.fileList = [];
          this.notifier.error(
            NotificationHeader.error,
            NotificationTextMessage.fileSize2mb
          );
          break;
        }
      }

      if (this.fileList.length > 0) {
        this.saveFile();
      }
    }
  }

  removeFile(index: any): void {
    this.fileUploadResponse.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  saveFile(): void {
    this.fileUploadRequest = {
      userId: this.globalComponent.getLoggedInUserId(),
      file: this.fileList,
      attachmentType: AttachmentType.Project,
    };

    this.store
      .dispatch(new UploadFileAttachements(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel;
        })
      )
      .subscribe();
  }

  dataSubmit() {
    this.spinner.show();

    try {
      if (this.fileUploadResponse !== undefined) {
        this.fileUploadResponse.forEach((x) => {
          this.attachmentsFileUpload.push({
            attachment: x.fileUrl,
            fileSize: x.fileSize,
          });
        });
      }

      this.saveNoteData = {
        universalId: this.universalId,
        note: this.noteForm.controls.notes.value,
        attachments: this.attachmentsFileUpload,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getActionName() {
    let actionName;
    switch (this.getModuleId) {
      case Modules.Invoice:
      case Modules.RecurringInvoice:
        actionName = SaveInvoiceNotes;
        break;

      case Modules.Estimate:
        actionName = SaveEstimateNotes;
        break;
    }
    return actionName;
  }

  onAdd(): void {
    if (this.noteForm.invalid) {
      this.commonService.addValidation(this.noteForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit()) {
        let actionName = this.getActionName();

        if (actionName !== undefined) {
          this.store.dispatch(new actionName(this.saveNoteData)).subscribe(
            (res) => {
              if (res !== undefined) {
                this.noteForm.reset();
                this.fileList = [];
                this.successMessage();
              } else {
                this.errorMessage();
              }
            },
            (error) => this.errorMessage()
          );
        }
      }
    }
  }

  errorMessage(): void {
    this.commonService.onFailure(NotificationTextMessage.errorMessage);
  }

  successMessage(): void {
    this.commonService.onSuccess(NotificationTextMessage.successMessage);
    this.onCloseClick.emit();
  }

  getFileName(fileUrl): string {
    return fileUrl.slice(fileUrl.lastIndexOf('/') + 1);
  }
}
