import { BudgetType, HourlyRateType, ProjectType } from './project-basic-info';

export class TimeAndMaterial {
  totalBudget: number;
  budgetType: BudgetType;
  totalHourlyRate: number;
  hourlyRateType: HourlyRateType;
  includeBillableAndNonBillable: boolean;
  sendEmailAlertIfExceed: boolean;
  exceedBudgetToSendEmail: number;
  resetBudgetEveryMonth: boolean;
}

export class FixedPrice {
  totalBudget: number;
  budgetType: BudgetType;
  totalHourlyRate: number;
  hourlyRateType: HourlyRateType;
  includeBillableAndNonBillable: boolean;
  sendEmailAlertIfExceed: boolean;
  exceedBudgetToSendEmail: number;
  resetBudgetEveryMonth: boolean;
}

export class NonBillable {
  totalBudget: number;
  budgetType: BudgetType;
  totalHourlyRate: number;
  hourlyRateType: HourlyRateType;
  includeBillableAndNonBillable: boolean;
  sendEmailAlertIfExceed: boolean;
  exceedBudgetToSendEmail: number;
  resetBudgetEveryMonth: boolean;
}

export class ProjectStateInfo {
  id: number;
  name: string;
  code: string;
  startDate: Date;
  endDate: Date;
  type: ProjectType;
  timeAndMaterial?: TimeAndMaterial;
  fixedPrice?: FixedPrice;
  nonBillable?: NonBillable;
  clientId: number;
  permissionsId: number;
}
