import {
  ExportParams,
  FileImportRequestModel,
  QueryParams,
  TaxesModel,
} from '../../models';

export class GetTaxesDetailList {
  static readonly type = '[TAXES] Get Taxes';

  constructor(public queryParams: QueryParams) {}
}

export class CreateTaxes {
  static readonly type = '[TAXES] Create Taxes';

  constructor(public taxes: TaxesModel) {}
}

export class GetTaxesData {
  static readonly type = '[TAXES] Get Taxes Data By Taxes ID';

  constructor(public taxesId: string) {}
}

export class UpdateTaxes {
  static readonly type = '[TAXES] Update Taxes';

  constructor(public taxes: TaxesModel) {}
}

export class ExportTaxes {
  static readonly type = '[TAXES] Export Taxes';

  constructor(public exportParams: ExportParams) {}
}

export class ImportTaxes {
  static readonly type = '[TAXES] Import taxes';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class DeleteSelectedTaxes {
  static readonly type = '[TAXES] Delete Selected Taxes';

  constructor(public taxesIds?: Array<string>) {}
}

export class GetTaxesList {
  static readonly type = '[TAXES] Get Tax Names ';

  constructor(public queryParams: QueryParams) {}
}

export class CopyTaxes {
  static readonly type = '[TAXES] Copy Selected Taxes';

  constructor(public taxesIds?: Array<string>) {}
}

export class ArchiveAndRestoreTaxes {
  static readonly type = '[TAXES] Archive and Restore Selected Taxes';

  constructor(public taxesIds?: Array<string>, public isArchive?: boolean) {}
}

export class MarkTaxesAsDefault {
  static readonly type = '[TAXES] Mark Taxes As Default';

  constructor(public taxesId: string, public isMarkAsDefault: boolean) {}
}

export class SetTaxDefaultState {
  static readonly type = '[TAXES] Set Tax Default State';
}

export class GetDefaultVatTaxes {
  static readonly type = '[TAXES] Get Default Vat Taxes';
}
