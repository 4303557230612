import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExportAction } from '../../enums';
import {
  EstimateBasicInfo,
  EstimateDetail,
  EstimateEmailModel,
  EstimateHistoryModel,
  EstimateParameters,
  Estimates,
  ExportParams1,
  Note,
  QueryParams,
  SideListModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class EstimatesService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Estimate';

  getEstimateList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getEstimateList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  getEstimateDetailList(estimateParams: EstimateParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService.postExecuteRequest(
      `${this.apiUrl}${this.routeBase}/executeEstimateList`,
      JSON.stringify(estimateParams),
      headers
    );
  }

  deleteEstimate(estimateId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}${this.routeBase}/delete/${estimateId}`
    );
  }

  deleteEstimates(estimateIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(estimateIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteEstimates`,
      options
    );
  }

  createEstimateBasicInfo(estimateBasicInfo: Estimates): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/create`,
      JSON.stringify(estimateBasicInfo),
      headers
    );
  }

  getEstimateById(estimateId: Guid): Observable<Estimates> {
    return this.http.get<Estimates>(
      `${this.apiUrl}${this.routeBase}/getEstimateByuniversalId/${estimateId}`
    );
  }

  updateEstimateBasicInfo(estimateData: Estimates): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/update`,
      JSON.stringify(estimateData),
      headers
    );
  }

  exportEstimates(
    format: number,
    fileName: string,
    filter?: number,
    sortBy?: string,
    sortOrder?: boolean,
    searchText?: string
  ): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${this.apiUrl}${this.routeBase}/exportEstimates?format=${format}&fileName=${fileName}&sortBy=${sortBy}&sortOrder=${sortOrder}&search=${searchText}&filter=${filter}`
    );
  }

  sendEstimateEmail(emailModel: EstimateEmailModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/sendEstimateEmail`,
      JSON.stringify(emailModel),
      headers
    );
  }

  getEstimateTemplate(
    estimateId: Guid,
    templateId: number
  ): Observable<EstimateEmailModel> {
    return this.http.get<EstimateEmailModel>(
      `${this.apiUrl}${this.routeBase}/getEstimateEmailTemplateByUniversalId?universalId=${estimateId}&templateId=${templateId}`
    );
  }

  getEstimateRecentHistory(id: Guid): Observable<EstimateHistoryModel> {
    return this.http.get<EstimateHistoryModel>(
      `${this.apiUrl}${this.routeBase}/getEstimateRecentHistoryByUniversalId/${id}`
    );
  }

  exportEstimate(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportEstimates`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else if (exportParams.isView) {
            this.commonService.view(response);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  duplicateEstimate(estimateId: number): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}${this.routeBase}/duplicateEstimates/${estimateId}`,
      null
    );
  }

  copyEstimates(estimateIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/duplicateEstimates`,
      JSON.stringify(estimateIds),
      headers
    );
  }

  updateEstimateStatus(
    estimateIds?: Array<Guid>,
    status?: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/updateEstimateStatus?action=${status}`,
      JSON.stringify(estimateIds),
      headers
    );
  }

  deleteEstimateDetail(ids: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids,
      },
    };

    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteEstimateDetails`,
      options
    );
  }

  getEstimateDetailById(id: number): Observable<Array<EstimateDetail>> {
    return this.http.get<Array<EstimateDetail>>(
      `${this.apiUrl}${this.routeBase}/listEstimateDetail/${id}`
    );
  }

  createEstimateDetail(
    estimateDetailData: Array<EstimateDetail>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createEstimateDetails`,
      JSON.stringify(estimateDetailData),
      headers
    );
  }

  updateEstimateDetail(estimateDetailData: EstimateDetail[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateEstimateDetails`,
      JSON.stringify(estimateDetailData),
      headers
    );
  }

  saveEstimateNotes(notes: Note): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createNote`,
      JSON.stringify(notes),
      headers
    );
  }

  downloadEstimateNotes(notesId: number): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${this.apiUrl}${this.routeBase}/download/estimateNote/attachment?noteId=${notesId}`
    );
  }

  getEstimateNoteList(
    estimateId: number,
    searchText?: string
  ): Observable<Array<Note>> {
    return this.http.get<Array<Note>>(
      `${this.apiUrl}${this.routeBase}/list/notes/${estimateId}?searchText=${searchText}`
    );
  }

  exportEstimateToPDF(
    estimateIds: Array<string>,
    exportAction: ExportAction
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportToPdf`,
        JSON.stringify(estimateIds),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportAction === ExportAction.Print) {
            this.commonService.print(body);
          } else if (exportAction === ExportAction.View) {
            this.commonService.view(response);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  convertEstimateToInvoice(estimateIds: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/convertToInvoice`,
      JSON.stringify(estimateIds),
      options
    );
  }

  saveEstimateBasicInfo(estimateBasicInfo: EstimateBasicInfo): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveEstimate`,
      JSON.stringify(estimateBasicInfo),
      headers
    );
  }

  exportEstimatesToPDF(
    estimateIds: Array<Guid>,
    exportAction: ExportAction
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportToPdf`,
        JSON.stringify(estimateIds),
        options
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportAction === ExportAction.Print) {
            this.commonService.print(body);
          } else if (exportAction === ExportAction.View) {
            this.commonService.view(response);
          } else if (exportAction === ExportAction.CopyLink) {
            this.commonService.copyLink(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getEstimateReceiptDetails(universalId: Guid): Observable<any> {
    return this.http.get(
      `${this.apiUrl}${this.routeBase}/previewEstimateReceipt/${universalId}`
    );
  }
}
