<div *ngIf="seriesData.length">
  <div class="d-flex justify-content-center gap-30 total-invoice">
    <div class="total-invoice-wrapper text-align-center">
      <p class="total-title mb-5">Total Hours</p>
      <p class="fw-bold font-size-16 mb-0">
        {{ taskWiseHoursDetails.totalHours }}
      </p>
    </div>
    <div class="total-invoice-wrapper text-align-center">
      <p class="total-title mb-5">Billable Hours</p>
      <p class="fw-bold text-success font-size-16 mb-0">
        {{ taskWiseHoursDetails.totalBillableHours }}
      </p>
    </div>
    <div class="total-invoice-wrapper text-align-center">
      <p class="total-title mb-5">Non-Billable Hours</p>
      <p class="fw-bold text-danger-100 font-size-16 mb-0">
        {{ taskWiseHoursDetails.totalNonBillableHours }}
      </p>
    </div>
  </div>
  <ejs-chart
    id="chart-container"
    class="weekly-box"
    [palettes]="palette"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <ng-container *ngFor="let series of seriesData">
        <e-series
          [dataSource]="series.data"
          type="StackingColumn"
          columnWidth="0.3"
          [name]="series.name"
          xName="x"
          yName="y"
          tooltipMappingName="tooltipMappingName"
        ></e-series>
      </ng-container>
    </e-series-collection>
  </ejs-chart>
</div>
<div class="norecord-chart" *ngIf="seriesData?.length === 0">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
