export enum FilteredStatus {
  'All Status' = -1,
  Active = 0,
  Archived = 1,
}

export enum UserFilteredStatus {
  'All Status' = -1,
  'Active' = 1,
  'Resend Invitation' = 2,
  'Invite' = 3,
  'Inactive' = 4,
}

export enum RecurringStatus {
  'All Status' = -1,
  'Inactive (ended)' = 0,
  'Inactive (paused)' = 1,
  'Active' = 2,
}

export enum InvoiceFilteredStatus {
  'All Status' = -1,
  'Draft' = 0,
  'Sent' = 1,
  'Overdue' = 2,
  'Partially Paid' = 3,
  'Paid' = 4,
}

export enum EstimateFilteredStatus {
  'All Status' = -1,
  'Draft' = 0,
  'Accepted' = 1,
  'Declined' = 2,
  'Sent' = 3,
}
