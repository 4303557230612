export enum ActionType {
  Create = 1,
  Edit = 2,
  Archive = 3,
  Restore = 4,
  Delete = 5,
  Export = 6,
  UnlockPeriod = 7,
  Ceased = 8,
  MarkComparative = 9,
  SendToCapsign = 10,
  Copy = 11,
  Submit = 12,
  Reminder = 13,
  Email = 14,
  Approve = 15,
  Reject = 16,
  WithdrawApproval = 17,
  Invoice = 18,
  WithdrawRejection = 19,
  View = 20,
  Send = 21,
  Print = 22,
  MarkAsSent = 23,
  MarkAsDecline = 24,
  MarkAsAccepted = 25,
  ExportAsPdf = 26,
  CopyLink = 27,
  Preview = 28,
  Duplicate = 29,
  End = 30,
  Payment = 31,
  Play = 32,
  Pause = 33,
}

export enum GridActionsText {
  Create = 'Create',
  Edit = 'Edit',
  Delete = 'Delete',
  View = 'View',
  Archive = 'Archive',
  Restore = 'Restore',
  Copy = 'Copy',
  Excel = 'Excel',
  CSV = 'CSV',
  PDF = 'PDF',
  Print = 'Print',
  MarkAsCompleted = 'Mark As Completed',
  MarkAsYetToStart = 'Mark As Yet To Start',
  Comment = 'Comment',
  File = 'File',
  Preview = 'Preview',
  Email = 'Email',
}
