export class ExpenseDetail {
  startDate: Date;
  endDate: Date;
  expense: number;
  isArchived: boolean;
  isApproved: boolean;
  status: number;
  detailId: number;
  detailName: string;
  billable: string;
  notes: string;
  clientName: string;
  fullName: string;
  projectName: string;
  expenseCategory: string;
  attachment: string;
  attachmentURL: string;
}

export class ExpenseDetailModel {
  userId: string;
  fullName: string;
  totalExpense: number;
  isApproved: boolean;
  status: number;
  attachment: string;
  detailId: number;
  totalRecords: number;
}
