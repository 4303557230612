import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardRequestModel } from '@app/core/models';
import { GetOverallHoursDetail } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ChartComponent,
} from 'ng-apexcharts';
import { tap } from 'rxjs/operators';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  legend: ApexLegend;
};

export interface PeriodicElement {
  task: string;
  user: string;
  hours: string;
  progress: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-task-overall-hours',
  templateUrl: './task-overall-hours.component.html',
  styleUrls: ['./task-overall-hours.component.scss'],
})
export class TaskOverallHoursComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  displayedColumns: string[] = [
    'taskName',
    'userName',
    'timeSpent',
    'progress',
  ];
  dataSource = ELEMENT_DATA;

  public piedata?: Object[];
  public legendSettings?: Object;
  public tooltip?: Object;
  dashboardRequestModel: DashboardRequestModel;
  filter = 1;
  start: string = '';
  end: string = '';
  @Input()
  triggerFilterData: any;
  @Input()
  triggerTaskId: any;
  taskId = Guid.EMPTY as unknown as Guid;
  public palette?: string[];
  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<div>${point.tooltip}</div>',
    };
    this.legendSettings = {
      visible: false,
    };

    this.triggerTaskId?.subscribe((res) => {
      if (res) {
        this.taskId = res;
        this.getOverallHoursDetail();
      }
    });
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getOverallHoursDetail();
    });
    this.palette = [
      '#93CAF0',
      '#B9DD94',
      '#F1D071',
      '#F2816F',
      '#BFBBE9',
      '#FFD1A7',
      '#7CB5EC',
    ];
  }

  constructor(private store: Store, public datepipe: DatePipe) {
    this.chartOptions = {
      series: [20, 25, 55],
      chart: {
        width: 200,
        height: 200,
        type: 'donut',
      },
      fill: {
        colors: ['#93CAF0', '#B9DD94', '#F1D071'],
      },
      legend: {
        show: false,
        position: 'right',
        horizontalAlign: 'right',
        offsetX: 0,
        offsetY: 0,
        markers: {
          fillColors: ['#93CAF0', '#B9DD94', '#F1D071'],
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: [
        'Estimates Created - 60%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 42',
        'Estimates Accepted - 30%  </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 22',
        'Estimates Rejected - 10% </br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 12',
      ],
    };
  }

  getOverallHoursDetail(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(
        new GetOverallHoursDetail(this.dashboardRequestModel, this.taskId)
      )
      .pipe(
        tap((res) => {
          let overallHoursDetailData = res.dashboard.overallHoursDetail;
          this.piedata = overallHoursDetailData.points;
          this.dataSource = overallHoursDetailData.data;
          window.dispatchEvent(new Event('resize'));
        })
      )
      .subscribe();
  }
}
