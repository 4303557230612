import { Guid } from 'guid-typescript';
import {
  ExportParams1,
  FileImportRequestModel,
  QueryParams,
  TaskModel,
  TaskUserClientModel,
} from '../../models';

export class SetDefaultTaskId {
  static readonly type = '[TASK] Set Default Task ID';
}

export class GetTaskByClientId {
  static readonly type = '[TASK] Get Task By ClientId';

  constructor(public universalId: Guid) {}
}

export class GetTaskOverview {
  static readonly type = '[TASK] Get Task Overview';

  constructor(public taskId: Guid, public monthNumber: number) {}
}

export class GetTaskDetailList {
  static readonly type = '[TASK] Get Task';

  constructor(public queryParams: QueryParams) {}
}

export class GetTaskDataByTaskId {
  static readonly type = '[TASK] Get Task Data By Task ID';

  constructor(public taskId: Guid) {}
}

export class SaveTask {
  static readonly type = '[TASK] Save Task';

  constructor(public task: TaskModel) {}
}

export class ExportTask {
  static readonly type = '[TASK] Export Task';

  constructor(public exportParams: ExportParams1) {}
}

export class DeleteSelectedTask {
  static readonly type = '[TASK] Delete Selected Task';

  constructor(public taskIds?: Array<Guid>) {}
}

export class GetTaskList {
  static readonly type = '[TASK] Get Task Names ';

  constructor(public queryParams: QueryParams) {}
}

export class ArchiveAndRestoreTask {
  static readonly type = '[TASK] Archive and Restore Selected Task';

  constructor(public taskIds?: Array<Guid>, public isArchive?: boolean) {}
}

export class GetTaskUsersDetails {
  static readonly type = '[TASK] Get Task Users Details By Task ID';

  constructor(public queryParams: any) {}
}

export class SaveTaskUsers {
  static readonly type = '[TASK] Save Task Users';

  constructor(public taskId: Guid, public task: Array<TaskUserClientModel>) {}
}

export class GetTaskClientsDetails {
  static readonly type = '[TASK] Get Task Users Clients By Task ID';

  constructor(public queryParams: any) {}
}

export class SaveTaskClients {
  static readonly type = '[TASK] Save Task Clients';

  constructor(public taskId: Guid, public task: Array<TaskUserClientModel>) {}
}

export class DeleteTaskUserByTaskId {
  static readonly type = '[TASK] Delete Task User By Task ID';

  constructor(public taskId: Guid) {}
}

export class DeleteTaskClientByTaskId {
  static readonly type = '[TASK] Delete Task Client By Task ID';

  constructor(public taskId: Guid) {}
}

//-----------------

export class GetAllTasks {
  static readonly type = '[TASK] Get All Tasks';
}

export class ImportTasks {
  static readonly type = '[TASK] Import Task';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class SetTaskDefaultState {
  static readonly type = '[TASK] Set Task Default State';
}

export class GetAllSubTaskByTaskId {
  static readonly type = '[TASK] Get All Sub Task By Task ID';

  constructor(public taskId: Guid) {}
}
