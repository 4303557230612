import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {
  AutocompletePositionDirective,
  DurationMaskDirective,
  EmailValidatorDirective,
  TimeFormatDirective,
} from '@app/core/directives';
import { NumbersOnlyDirective } from '@app/core/directives/numbers-only.directive';
import { OptionsScrollDirective } from '@app/core/directives/options-scroll.directive';
import { MentionModule } from 'angular-mentions';
import { SafeHtmlPipe, ShortNamePipe, UseEnumValuePipe } from '../../pipes';
import { DummyService, NotificationService } from '../../services';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { AddClosePopupComponent, SideListActionsComponent } from './common';
import { ActivityDetailsComponent } from './common/activity-details/activity-details.component';
import { AsideComponent } from './common/aside/aside.component';
import { ButtonsComponent } from './common/buttons/buttons.component';
import { CalendarSideListComponent } from './common/calendar-side-list/calendar-side-list.component';
import { CardFooterComponent } from './common/card-footer/card-footer.component';
import { ChangeConfirmationComponent } from './common/change-confirmation/change-confirmation.component';
import { ConfirmationBoxComponent } from './common/confirmation-box/confirmation-box.component';
import { DynamicGIFComponent } from './common/dynamic-GIF/dynamic-GIF.component';
import { EmailComponent } from './common/email/email.component';
import { ExpenseConfirmationComponent } from './common/expense-confirmation/expense-confirmation.component';
import { JobsInfoComponent } from './common/jobs-info/jobs-info.component';
import { MoreGridActionsComponent } from './common/more-grid-actions/more-grid-actions.component';
import { MoreJobDataComponent } from './common/more-job-data/more-job-data.component';
import { NotesComponent } from './common/notes/notes.component';
import { ShowMoreAddedDataComponent } from './common/show-more-added-data/show-more-added-data.component';
import { SideListComponent } from './common/side-list/side-list.component';
import { CustomLoaderComponent } from './custom/custom-loader/custom-loader.component';
import { CustomNotificationsComponent } from './custom/custom-notifications/custom-notifications.component';
import { CustomPaginatorComponent } from './custom/custom-paginator/custom-paginator.component';
import { TextareaHighlightComponent } from './custom/textarea-highlight/textarea-highlight.component';
import { TwoDigitDecimaNumberDirective } from '@app/core/directives/Two-Digit-Decimal-Number-Directive';

@NgModule({
  declarations: [
    CustomNotificationsComponent,
    ConfirmationBoxComponent,
    AsideComponent,
    SideListComponent,
    CustomLoaderComponent,
    CustomPaginatorComponent,
    CardFooterComponent,
    ActivityDetailsComponent,
    ShortNamePipe,
    SafeHtmlPipe,
    UseEnumValuePipe,
    JobsInfoComponent,
    TextareaHighlightComponent,
    MoreGridActionsComponent,
    DynamicGIFComponent,
    ButtonsComponent,
    ShowMoreAddedDataComponent,
    AddClosePopupComponent,
    ExpenseConfirmationComponent,
    SideListActionsComponent,
    EmailComponent,
    AutocompletePositionDirective,
    DurationMaskDirective,
    MoreJobDataComponent,
    CalendarSideListComponent,
    NotesComponent,
    NumbersOnlyDirective,
    EmailValidatorDirective,
    TwoDigitDecimaNumberDirective,
    OptionsScrollDirective,
    ChangeConfirmationComponent,
    TimeFormatDirective,
  ],
  imports: [CommonModule, AngularMaterialModule, MentionModule],
  exports: [
    CustomNotificationsComponent,
    ConfirmationBoxComponent,
    AsideComponent,
    MoreGridActionsComponent,
    SideListComponent,
    CustomLoaderComponent,
    CustomPaginatorComponent,
    CardFooterComponent,
    ActivityDetailsComponent,
    TwoDigitDecimaNumberDirective,
    ShortNamePipe,
    SafeHtmlPipe,
    JobsInfoComponent,
    TextareaHighlightComponent,
    UseEnumValuePipe,
    DynamicGIFComponent,
    ButtonsComponent,
    ShowMoreAddedDataComponent,
    AddClosePopupComponent,
    ExpenseConfirmationComponent,
    SideListActionsComponent,
    EmailComponent,
    AutocompletePositionDirective,
    DurationMaskDirective,
    MoreJobDataComponent,
    CalendarSideListComponent,
    NotesComponent,
    NumbersOnlyDirective,
    EmailValidatorDirective,
    OptionsScrollDirective,
    ChangeConfirmationComponent,
    TimeFormatDirective,
  ],
  providers: [NotificationService, DummyService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule {}
