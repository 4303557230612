export class InvoiceRaisedHistoryChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class SeriesModel {
  name: string;
  data: Array<number>;
}

export class IncomeByProjectTypeChartModel {
  series: Array<number>;
  labels: Array<string>;
}

export class IncomeByClientChartModel {
  series: Array<number>;
  labels: Array<string>;
}

export class BillableHoursByQuarterChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class TeamUtilizationHoursOnClientChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class TeammatesExpectedAndActualCapacityModel {
  data: Array<TeammatesExpectedAndActualCapacityDataModel>;
  day: Array<DayModel>;
}

export class DayModel {
  dayName: string;
  dayNumber: number;
  dayOrder: number;
  timesheetDay: string;
}

export class TeammatesExpectedAndActualCapacityDataModel {
  uerFullName: string;
  userId: string;
  userPhoto: string;
  usertype: string;
  day1: number;
  day2: number;
  day3: number;
  day4: number;
  day5: number;
  day6: number;
  day7: number;
}

export class ProjectHoursByTeamamteChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class TopBillableTeammateHoursChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class IncomeByProjectChartModel {
  series: Array<number>;
  labels: Array<string>;
}

export class IncomeByPaymentMethodChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class BillableAndNonBillableHoursByMonthChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class InvoiceByPaymentLateDaysChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class ProjectStatusByTimeCostAndExpenseChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class TimeSheetSummaryByStatusChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class TimeOffByTeammatesChartModel {
  categories: Array<string>;
  series: Array<SeriesModel>;
}

export class ExpenseByStatusModel {
  expenseDayList: Array<ExpenseDayModel>;
  expenseStatusList: Array<ExpenseStatusListModel>;
}

export class ExpenseDayModel {
  expenseDay: string;
  expenseDate: Date;
  totalAmount: string;
}

export class ExpenseStatusListModel {
  expenseDate: Date;
  status: number;
  noOfRecords: number;
  expenseList: Array<ExpenseNameModel>;
}

export class ExpenseNameModel {
  universalId: string;
  expenseName: string;
}

export class WeeklyScheduleModel {
  weekDays: Array<WeekDaysModel>;
  weekData: Array<WeekStatusModel>;
}

export class WeekDaysModel {
  dayNumber: number;
  dayName: string;
  expenseDay: string;
  expenseDate: Date;
  totalPrice: string;
}

export class WeekStatusModel {
  expenseDate: Date;
  status: number;
  totalRecords: number;
  data: Array<WeekDataModel>;
}

export class WeekDataModel {
  dayNumber: number;
  status: number;
  expenseDate: Date;
  totalPrice: number;
  clientId: string;
  clientName: string;
  projectId: string;
  projectName: string;
  expenseTypeId: string;
  expenseTypeName: string;
}
