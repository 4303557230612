import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AddClosePopupComponent } from '../add-close-popup/add-close-popup.component';

@Component({
  selector: 'app-change-confirmation',
  templateUrl: './change-confirmation.component.html',
  styleUrls: ['./change-confirmation.component.scss'],
})
export class ChangeConfirmationComponent implements OnInit {
  headerText: string = '';
  bodyText: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClosePopupComponent>
  ) {}

  ngOnInit(): void {
    this.headerText = this.data.headerText;
    this.bodyText = this.data.bodyText;
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
