import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { NgxsModule } from '@ngxs/store';
import {
  MonthService,
  ScheduleModule,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import {
  AuthModule,
  LogLevel,
  LoggerService,
  OidcConfigService,
} from 'angular-auth-oidc-client';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgShortcutConfig, NgShortcutModule } from 'ng-shortcut';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthResolverService } from './core/guards/auth.resolver';
import {
  CustomHttpInterceptor,
  GlobalErrorHandler,
  LoaderInterceptor,
} from './core/interceptors';
import { CommonService, DummyService } from './core/services';
import { AngularMaterialModule } from './core/shared/angular-material/angular-material.module';
import { CapiumpayDialogComponent } from './modules/home/common/capiumpay-dialog/capiumpay-dialog.component';
import { HomeModule } from './modules/home/home.module';
import { JobsModule } from './modules/home/jobs/jobs.module';
import { NotificationTimelineModule } from './modules/home/notification-timeline/notification-timeline.module';

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: '.',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: ',',
  nullable: true,
};

// tslint:disable-next-line:typedef
export function configureAuth(oidcConfigService: OidcConfigService) {
  return () =>
    oidcConfigService.withConfig({
      stsServer: `${environment.stsServerUrl}`,
      redirectUrl: window.location.origin,
      postLogoutRedirectUri: `${environment.identityLoginUrl}`,
      clientId: `${environment.clientId}`,
      scope: 'capium_myadmin_api openid offline_access',
      responseType: 'code',
      silentRenew: false,
      silentRenewUrl: `${window.location.origin}/silent-renew.html`,
      useRefreshToken: true,
      logLevel: LogLevel.Debug,
      historyCleanupOff: true,
      autoCleanStateAfterAuthentication: true,
      postLoginRoute: '/time/timesheet',
      unauthorizedRoute: `${environment.identityLoginUrl}`,
      autoUserinfo: false,
    });
}

const shortcutConfig: NgShortcutConfig[] = [
  {
    id: '@save',
    key: 's',
    preventDefault: true,
    ctrlKey: true,
  },
  {
    id: '@saveAndExits',
    key: 'e',
    preventDefault: true,
    ctrlKey: true,
  },
  {
    id: '@cancel',
    key: 'x',
    preventDefault: true,
    ctrlKey: true,
  },
];

@NgModule({
  declarations: [AppComponent, CapiumpayDialogComponent],
  imports: [
    AuthModule.forRoot(),
    NgShortcutModule.forRoot(shortcutConfig),
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NotificationTimelineModule,
    NgApexchartsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
    NgxsModule.forRoot([]),
    DragDropModule,
    JobsModule,
    ScheduleModule,
  ],
  providers: [
    CommonService,
    OidcConfigService,
    LoggerService,
    DummyService,
    AuthResolverService,
    WeekService,
    MonthService,
    WorkWeekService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,

      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
