import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TimelogParameter } from '@app/core/models';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ExportType,
  FeeStatus,
  GridActionsText,
  Modules,
  TimeModuleSelectedTab,
  TimelogAction,
} from 'src/app/core/enums';
import {
  GlobalComponent,
  Pagination,
  TimeModule,
} from 'src/app/core/models/common';
import { TimeLogModel } from 'src/app/core/models/time/time-week';
import { CommonService } from 'src/app/core/services';
import { GetUserWeekTimelogs } from 'src/app/core/store/Time/time.action';
import { TimeState } from 'src/app/core/store/Time/time.state';

@Component({
  selector: 'app-side-list-actions',
  templateUrl: './side-list-actions.component.html',
  styleUrls: ['./side-list-actions.component.scss'],
})
export class SideListActionsComponent implements OnInit {
  numberOfRecords = 0;
  modules = Modules;
  exportType = ExportType;
  paginationParameters: Pagination = new Pagination();
  istoggleSideList = false;
  feeStatus = FeeStatus;
  isRegularUser = this.globalComponent.isRegularUser();

  @Input() getModuleId: number;

  @Input() toggleSideList: Observable<boolean>;

  @Input() triggerNumberOfRecords: Observable<any>;

  @Input()
  gridActions: any;

  @Output()
  readonly deleteClick = new EventEmitter<any>();

  @Output()
  readonly convertInvoiceClick = new EventEmitter<any>();

  @Output()
  readonly acceptClick = new EventEmitter<any>();

  @Output()
  readonly declineClick = new EventEmitter<any>();

  @Output()
  readonly revertClick = new EventEmitter<any>();

  @Output()
  readonly markSentClick = new EventEmitter<any>();

  @Output()
  readonly exportClick = new EventEmitter<any>();

  @Output()
  readonly copyClick = new EventEmitter<any>();

  @Output()
  readonly cancelSelectionClick = new EventEmitter<any>();

  @Output()
  readonly printClick = new EventEmitter<any>();

  @Output()
  readonly archiveAndRestoreClick = new EventEmitter<any>();

  @Output()
  readonly endRecurringInvoiceClick = new EventEmitter<any>();

  @Output()
  readonly updateJobStatusClick = new EventEmitter<{
    isMarkAsCompleted: boolean;
    isMarkAsYetToStart: boolean;
  }>();

  //#region  time
  @Input()
  triggerLoadTimeSheetPopup: Observable<any>;

  @Output()
  readonly triggerReloadSideList = new EventEmitter<any>();

  triggerSelectedTab: Subject<any> = new Subject<any>();

  timeModule: TimeModule;
  timeModuleSelectedTab = TimeModuleSelectedTab;
  timelogData: TimeModule[];
  timeSheetData: any;

  timelog: TimeLogModel = {
    columns: [],
    data: [],
    allDates: [],
  };

  displayedColumns: string[] = this.timelog.columns;

  isAprroved: boolean;
  selectedTabOrdinalValue: number;
  gridActionsText = GridActionsText;

  @ViewChildren('button') buttons: QueryList<ElementRef>;
  //#endregion

  @Output()
  readonly timelogSubmitClick = new EventEmitter<any>();

  @Output()
  readonly timelogReminderClick = new EventEmitter<any>();

  @Output()
  readonly timelogApproveClick = new EventEmitter<any>();

  @Output()
  readonly timelogRejectClick = new EventEmitter<any>();

  @Output()
  readonly timelogSendClick = new EventEmitter<any>();

  @Output()
  readonly timelogWithdrawApprovalClick = new EventEmitter<any>();

  @Output()
  readonly timelogWithdrawRejectionClick = new EventEmitter<any>();

  constructor(
    public datepipe: DatePipe,
    private store: Store,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.triggerNumberOfRecords.subscribe((data: any) => {
      this.numberOfRecords = data && data > 0 ? data : 0;

      setTimeout(() => {
        this.commonService.setButtonPermissions(this.buttons, this.gridActions);
      }, 400);
    });

    this.toggleSideList.subscribe((data: boolean) => {
      this.istoggleSideList = !this.istoggleSideList;
    });

    this.triggerLoadTimeSheetPopup?.subscribe((data: any) => {
      this.timeSheetData = data;
      this.timeModule = data.listOfData;
      this.selectedTabOrdinalValue = data.selectedTabOrdinalValue;

      this.getList();
    });
  }

  statusChanged(status): void {
    if (status === FeeStatus.Accepted) {
      this.acceptClick.emit();
    }
    if (status === FeeStatus.Declined) {
      this.declineClick.emit();
    }
    if (status === FeeStatus.Revert) {
      this.revertClick.emit();
    }
    if (status === FeeStatus.Sent) {
      this.markSentClick.emit();
    }
  }

  getParams() {
    const timelogParams: TimelogParameter = {
      startDate: this.timeModule.startDate,
      endDate: this.timeModule.endDate,
      search: this.timeModule.search ?? '',
      status: this.timeModule.timelogStatus,
      userId: this.timeModule.userId,
    };

    return timelogParams;
  }

  getList(): void {
    this.store
      .dispatch(new GetUserWeekTimelogs(this.getParams()))
      .pipe(
        tap((data) => {
          this.timelog = this.store.selectSnapshot(TimeState.getTimeSheetData);
          this.timelog.data.splice(this.timelog.data.length - 2, 1);
          this.isAprroved =
            this.timelog.status === TimelogAction.Approved ? true : false;
          this.timeSheetData.showButton = this.timelog.data.length > 1;
          this.triggerSelectedTab.next(this.timeSheetData);
          this.displayedColumns = this.timelog.columns;
        })
      )
      .subscribe();
  }

  export(format: number): void {
    this.exportClick.emit(format);
  }

  delete(): void {
    this.deleteClick.emit();
  }

  convertToInvoice(): void {
    this.convertInvoiceClick.emit();
  }

  copy(): void {
    this.copyClick.emit();
  }

  cancelSelection(): void {
    this.cancelSelectionClick.emit();
  }

  print(): void {
    this.printClick.emit();
  }

  archiveAndRestore(isArchive: boolean): void {
    this.archiveAndRestoreClick.emit(isArchive);
  }

  //#region  time

  dataSubmit(): void {
    this.timelogData = [];

    if (this.timeSheetData.timelogData.length > 0) {
      this.timelogData = this.timeSheetData.timelogData;
    } else {
      this.timelogData.push({
        startDate: this.timeModule.startDate,
        endDate: this.timeModule.endDate,
        userId: this.timeModule.userId,
        projectId:
          this.timeModule.projectId ??
          this.globalComponent.getDefaultUniversalId(),
      });
    }
  }

  endRecurringInvoice(): void {
    this.endRecurringInvoiceClick.emit();
  }
  //#endregion

  updateJobStatus(
    isMarkAsCompleted: boolean,
    isMarkAsYetToStart: boolean
  ): void {
    this.updateJobStatusClick.emit({ isMarkAsCompleted, isMarkAsYetToStart });
  }

  reminder(): void {
    this.timelogReminderClick.emit();
  }

  submit(): void {
    this.timelogSubmitClick.emit();
  }

  approve(): void {
    this.timelogApproveClick.emit();
  }

  reject(): void {
    this.timelogRejectClick.emit();
  }

  sendNote(): void {
    this.timelogSendClick.emit();
  }

  withdrawApproval(): void {
    this.timelogWithdrawApprovalClick.emit();
  }

  withdrawRejection(): void {
    this.timelogWithdrawRejectionClick.emit();
  }
}
