import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import {
  ActivityService,
  ClientService,
  ExpenseTypeService,
  ExpensesService,
  SettingService,
  TaskService,
  TicketService,
  TimeService,
  UserService,
} from '@app/core/services';
import { AngularMaterialModule } from '@app/core/shared/angular-material/angular-material.module';
import { ComponentsModule } from '@app/core/shared/components/components.module';
import {
  ActivityState,
  ClientState,
  CommonState,
  DashboardState,
  ExpenseTypeState,
  OverviewState,
  PaymentDueState,
  SettingsState,
  SubscriptionState,
  TaskState,
  TicketState,
  TimeState,
  UserState,
} from '@app/core/store';
import { NgxsModule } from '@ngxs/store';
import {
  AccumulationAnnotationService,
  AccumulationChartModule,
  AccumulationDataLabelService,
  AccumulationLegendService,
  AccumulationTooltipService,
  BarSeriesService,
  CategoryService,
  ChartAnnotationService,
  ChartModule,
  ColumnSeriesService,
  DateTimeService,
  LegendService,
  LineSeriesService,
  PieSeriesService,
  RangeColumnSeriesService,
  ScrollBarService,
  SplineSeriesService,
  StackingBarSeriesService,
  StackingColumnSeriesService,
  StackingLineSeriesService,
  StepLineSeriesService,
  TooltipService,
} from '@syncfusion/ej2-angular-charts';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AccessDeniedComponent } from './common/access-denied/access-denied.component';
import { FeesClientBalanceComponent } from './common/fees-overview/fees-client-balance/fees-client-balance.component';
import { FeesDueMonthComponent } from './common/fees-overview/fees-due-month/fees-due-month.component';
import { FeesEstimateStatusComponent } from './common/fees-overview/fees-estimate-status/fees-estimate-status.component';
import { FeesIncomeTrendComponent } from './common/fees-overview/fees-income-trend/fees-income-trend.component';
import { FeesInvoiceAmountComponent } from './common/fees-overview/fees-invoice-amount/fees-invoice-amount.component';
import { FeesInvoiceCategoryComponent } from './common/fees-overview/fees-invoice-category/fees-invoice-category.component';
import { FeesInvoiceMonthComponent } from './common/fees-overview/fees-invoice-month/fees-invoice-month.component';
import { FeesInvoiceStatusComponent } from './common/fees-overview/fees-invoice-status/fees-invoice-status.component';
import { FeesPaymentMethodsComponent } from './common/fees-overview/fees-payment-methods/fees-payment-methods.component';
import { FeesRevenueFlowComponent } from './common/fees-overview/fees-revenue-flow/fees-revenue-flow.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { TimeExpenseConfirmationComponent } from './common/time-expense-confirmation/time-expense-confirmation.component';
import { TimelogOverviewClientComponent } from './common/timelog-overview/timelog-overview-client/timelog-overview-client.component';
import { TimelogOverviewGridComponent } from './common/timelog-overview/timelog-overview-grid/timelog-overview-grid.component';
import { TimelogOverviewJobchartComponent } from './common/timelog-overview/timelog-overview-jobchart/timelog-overview-jobchart.component';
import { TimelogOverviewComponent } from './common/timelog-overview/timelog-overview.component';
import { TimesheetListComponent } from './common/timesheet-list/timesheet-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TaskBillableHoursComponent } from './dashboard/task-billable-hours/task-billable-hours.component';
import { TaskOverallHoursComponent } from './dashboard/task-overall-hours/task-overall-hours.component';
import { TaskTimeByuserComponent } from './dashboard/task-time-byuser/task-time-byuser.component';
import { TaskWeeklyHoursComponent } from './dashboard/task-weekly-hours/task-weekly-hours.component';
import { HeaderComponent } from './header/header.component';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { LiveComponent } from './live/live.component';
import { NotificationTimelineModule } from './notification-timeline/notification-timeline.module';
import { ClientTicketComponent } from './ticket/client-ticket/client-ticket.component';
import { TicketDialogComponent } from './ticket/common/ticket-dialog/ticket-dialog.component';
import { SupportTicketComponent } from './ticket/support-ticket/support-ticket.component';
import { TicketComponent } from './ticket/ticket.component';
import { OverviewService } from '@app/core/services/overview';
import { WidgetListComponent } from './dashboard/widget-list/widget-list.component';
import { DashboardNewComponent } from './dashboard/dashboard-new.component';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    TimelogOverviewComponent,
    TimelogOverviewGridComponent,
    TimelogOverviewClientComponent,
    TimelogOverviewJobchartComponent,
    LiveComponent,
    TimeExpenseConfirmationComponent,
    TimesheetListComponent,
    DashboardComponent,
    DashboardNewComponent,
    AccessDeniedComponent,
    PageNotFoundComponent,
    TaskWeeklyHoursComponent,
    TaskOverallHoursComponent,
    TaskBillableHoursComponent,
    TaskTimeByuserComponent,
    TicketComponent,
    SupportTicketComponent,
    ClientTicketComponent,
    TicketDialogComponent,
    FeesInvoiceMonthComponent,
    FeesIncomeTrendComponent,
    FeesRevenueFlowComponent,
    FeesDueMonthComponent,
    FeesInvoiceAmountComponent,
    FeesClientBalanceComponent,
    FeesPaymentMethodsComponent,
    FeesEstimateStatusComponent,
    FeesInvoiceStatusComponent,
    FeesInvoiceCategoryComponent,
    WidgetListComponent,
  ],
  imports: [
    CommonModule,
    HomeRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
    NotificationTimelineModule,
    DashboardLayoutModule,
    ChartModule,
    AccumulationChartModule,
    MatCardModule,
    MatGridListModule,
    MatMenuModule,
    DragDropModule,
    LayoutModule,
    NgxsModule.forFeature([
      CommonState,
      ClientState,
      SettingsState,
      ExpenseTypeState,
      TaskState,
      UserState,
      PaymentDueState,
      ActivityState,
      TimeState,
      SubscriptionState,
      DashboardState,
      OverviewState,
      TicketState,
    ]),
    NgApexchartsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-spin-fade' }),
  ],
  exports: [
    TimelogOverviewComponent,
    TimesheetListComponent,
    FeesInvoiceMonthComponent,
    FeesIncomeTrendComponent,
    FeesRevenueFlowComponent,
    FeesDueMonthComponent,
    FeesInvoiceAmountComponent,
    FeesClientBalanceComponent,
    FeesPaymentMethodsComponent,
    FeesEstimateStatusComponent,
    FeesInvoiceStatusComponent,
    FeesInvoiceCategoryComponent,
    DashboardComponent,
    DashboardNewComponent,
  ],
  providers: [
    ClientService,
    SettingService,
    UserService,
    ExpenseTypeService,
    TaskService,
    ExpensesService,
    ActivityService,
    TimeService,
    OverviewService,
    CategoryService,
    DateTimeService,
    ScrollBarService,
    LineSeriesService,
    StepLineSeriesService,
    SplineSeriesService,
    StackingLineSeriesService,
    ColumnSeriesService,
    ChartAnnotationService,
    RangeColumnSeriesService,
    StackingColumnSeriesService,
    LegendService,
    TooltipService,
    PieSeriesService,
    BarSeriesService,
    StackingBarSeriesService,
    AccumulationLegendService,
    AccumulationTooltipService,
    AccumulationDataLabelService,
    AccumulationAnnotationService,
    TicketService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HomeModule {}
