import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExpenseStatus } from '@app/core/enums';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExpenseList,
  ExpenseListForNonAdmin,
  ExpenseRequestModel,
  ExpenseTaskUserModel,
  ExpenseUnSubmittedList,
  Expenses,
  ExpensesParameters,
  ExportParams1,
  FileImportRequestModel,
  QueryParams,
  SendEmailExpenseModel,
  SideListModel,
  SubmitExpenseModel,
  UserExpenses,
  UserExpensesRequest,
  UserExpensesTotal,
  UserTimelog,
  UserTimelogTotal,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ExpensesService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Expense';

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  getExpenseDetailList(
    expensesParameters: ExpensesParameters
  ): Observable<HttpResponse<Array<Expenses>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeExpenseList`,
      expensesParameters,
      httpOptions
    );
  }

  saveExpense(expenses: Expenses): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveExpense`,
      JSON.stringify(expenses),
      headers
    );
  }

  getExpensesListForNonAdmin(
    queryParams: ExpensesParameters
  ): Observable<Array<ExpenseListForNonAdmin>> {
    return this.http.get<Array<ExpenseListForNonAdmin>>(
      `${this.apiUrl}${this.routeBase}/getExpensesByNonAdmin?userId=${queryParams.userId}&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&search=${queryParams.search}&sortOrder=${queryParams.sortOrder}&sortBy=${queryParams.sortBy}&filter=${queryParams.filter}`
    );
  }

  getExpenseUnSubmittedList(
    queryParams: any
  ): Observable<Array<ExpenseUnSubmittedList>> {
    return this.http.get<Array<ExpenseUnSubmittedList>>(
      `${this.apiUrl}${this.routeBase}/getExpensesByNonAdmin?userId=${queryParams.moduleId}&startDate=${queryParams.startDate}&endDate=${queryParams.endDate}&search=${queryParams.search}&sortOrder=${queryParams.sortOrder}&sortBy=${queryParams.sortBy}&filter=${queryParams.filter}`
    );
  }

  getExpenseList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/expenseList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${ExpenseStatus.Unsubmitted}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  getExpensesListForAdmin(queryParams: ExpensesParameters): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<Array<ExpenseList>>(
      `${this.apiUrl}${this.routeBase}/list`,
      queryParams,
      httpOptions
    );
  }

  getUserTimelog(user: UserExpensesRequest): Observable<any> {
    return this.http.get<Array<UserTimelog>>(
      `${environment.apiVersionUrl}/User/timelogs?startDate=${user.startDate}&endDate=${user.endDate}&userId=${user.userId}&status=${user.status}`
    );
  }

  getUserTimelogTotal(userTotal: UserExpensesRequest): Observable<any> {
    return this.http.get<UserTimelogTotal>(
      `${environment.apiVersionUrl}/User/timelogsSummary?startDate=${userTotal.startDate}&endDate=${userTotal.endDate}&userId=${userTotal.userId}&status=${userTotal.status}`
    );
  }

  getUserExpenses(userExpenseParams: UserExpensesRequest): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<UserExpenses>>(
      `${environment.apiVersionUrl}/User/getUserExpenseList`,
      JSON.stringify(userExpenseParams),
      headers
    );
  }

  getUserExpensesTotal(userTotal: UserExpensesRequest): Observable<any> {
    return this.http.get<UserExpensesTotal>(
      `${environment.apiVersionUrl}/User/expensesSummary?startDate=${userTotal.startDate}&endDate=${userTotal.endDate}&userId=${userTotal.userId}&status=${userTotal.status}`
    );
  }

  saveTimelogAndExpenses(userTotal: UserExpensesRequest): Observable<any> {
    return this.http.put<UserExpensesTotal>(
      `${
        environment.apiVersionUrl
      }/Expense/changeUserTimelogAndExpenseApproval?startDate=${this.datepipe.transform(
        userTotal.startDate,
        'yyyy-MM-dd'
      )}&endDate=${this.datepipe.transform(
        userTotal.endDate,
        'yyyy-MM-dd'
      )}&userId=${userTotal.userId}&status=${userTotal.status}`,
      null
    );
  }

  deleteExpenses(expenseIds?: Array<Guid>): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(expenseIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/delete`,
      options
    );
  }

  updateStatusNonAdminExpense(
    expenseId: number,
    status?: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateStatusNonAdmin?expenseId=${expenseId}&status=${status}`,
      headers
    );
  }

  exportExpense(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/export`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getExpensesById(detailId: Guid): Observable<Expenses> {
    return this.http.get<Expenses>(
      `${this.apiUrl}${this.routeBase}/getExpenseByUniversalId/${detailId}`
    );
  }

  getExpenseNextNumber(): Observable<any> {
    return this.http.get(
      `${environment.apiVersionUrl}/Business/getExpenseNextNumber`,
      { responseType: 'text' }
    );
  }

  updateExpenseStatus(
    expenseRequestModelData: ExpenseRequestModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateExpenseStatus`,
      JSON.stringify(expenseRequestModelData),
      headers
    );
  }

  withdrawExpense(
    expenseRequestModelData: ExpenseRequestModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/withdrawApproval`,
      JSON.stringify(expenseRequestModelData),
      headers
    );
  }

  sendEmail(sendEmailExpenseModel: SendEmailExpenseModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/comment`,
      JSON.stringify(sendEmailExpenseModel),
      headers
    );
  }

  approveExpense(
    expenseRequestModelData: ExpenseRequestModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/approve`,
      JSON.stringify(expenseRequestModelData),
      headers
    );
  }

  submitExpense(submitExpenseModel: SubmitExpenseModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/submit`,
      JSON.stringify(submitExpenseModel),
      headers
    );
  }

  importExpense(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate!.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/importExpense`,
      formData
    );
  }

  getNonAdminExpenseDetailList(
    queryParams: ExpensesParameters
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<Array<ExpenseList>>(
      `${this.apiUrl}${this.routeBase}/userExpenseList`,
      queryParams,
      httpOptions
    );
  }

  getUserExpenseByModuleId(
    userId: Guid,
    parameters: QueryParams
  ): Observable<Expenses> {
    return this.http.get<Expenses>(
      `${this.apiUrl}${this.routeBase}/getExpenseByModule/${userId}?startDate=${parameters.startDate}&endDate=${parameters.endDate}&search=${parameters.search}&sortOrder=${parameters.sortOrder}&sortBy=${parameters.sortBy}&dateFilter=${parameters.filter}&moduleId=${parameters.moduleId}`
    );
  }

  getExpenseByClientId(
    universalId: Guid
  ): Observable<Array<ExpenseTaskUserModel>> {
    return this.http.get<Array<ExpenseTaskUserModel>>(
      `${this.apiUrl}${this.routeBase}/getExpenseByClientId/${universalId}`
    );
  }
}
