import { ExpenseAction } from '@app/core/enums';
import { Guid } from 'guid-typescript';

export class SubmitExpenseModel {
  userId: string;
  startDate?: string;
  endDate?: string;
  isResubmitted: boolean;
  uRL: string;
  expenseIds: Array<string>;
}

export class ExpenseRequestModel {
  expenseIds: Array<Guid>;
  status?: ExpenseAction;
  userId?: Guid;
  url?: string;
  isResubmitted?: boolean;
}
