import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgShortcut, NgShortcutService } from 'ng-shortcut';
import { ActionType, Modules } from 'src/app/core/enums';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss'],
})
export class ButtonsComponent {
  actionType = ActionType;

  @Input() getModuleId: number;

  @Input() isViewMode: boolean = false;

  @Output()
  readonly triggerOnSaveClick = new EventEmitter<{
    isExit: boolean;
    isSaveAndSubmit?: boolean;
    isSaveAndInvite?: boolean;
  }>();

  @Output()
  readonly triggerOnCancelClick = new EventEmitter<any>();

  @Output()
  readonly triggerUpdateStatusClick = new EventEmitter<any>();

  modules = Modules;
  constructor(private ngShortcutService: NgShortcutService) {
    ngShortcutService.push(
      new NgShortcut('s', () => this.onSave(false), {
        preventDefault: true,
        ctrlKey: true,
      })
    );

    ngShortcutService.push(
      new NgShortcut('e', () => this.onSave(true), {
        preventDefault: true,
        ctrlKey: true,
      })
    );

    ngShortcutService.push(
      new NgShortcut('x', () => this.onCancel(), {
        preventDefault: true,
        ctrlKey: true,
      })
    );
  }

  onSave(
    isExit: boolean,
    isSaveAndSubmit?: boolean,
    isSaveAndInvite?: boolean
  ): void {
    this.triggerOnSaveClick.next({ isExit, isSaveAndSubmit, isSaveAndInvite });
  }

  onCancel(): void {
    this.triggerOnCancelClick.next();
  }

  onUpdateStatus(actionType): void {
    this.triggerUpdateStatusClick.next(actionType);
  }
}
