<div class="sidenav-body-content">
  <div class="content-header">
    <div class="content-header-input">
      <mat-form-field appearance="fill" class="search-field">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          (keyup)="onSearch($event)"
        />
        <mat-icon>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="content-right-button">
      <div class="sidenav-content">
        <ng-select
          #dateFilter
          (focus)="dateFilter.open()"
          (valueChange)="dateFilter.close()"
          [(ngModel)]="filter"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          (change)="onSelectionChange($event)"
          appearance="fill"
        >
          <ng-option *ngFor="let filter of filterTypes" [value]="filter.id">
            {{ filter.name }}
          </ng-option>
        </ng-select>
        <form [formGroup]="calendarForm" *ngIf="showCalendar">
          <div class="d-flex align-center">
            <mat-form-field>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  matInput
                  [value]="startDate.value"
                  formControlName="start"
                  #start
                  readonly
                />
                <input
                  matEndDate
                  matInput
                  [value]="endDate.value"
                  formControlName="end"
                  #end
                  readonly
                  (dateChange)="dateRangeChange(start.value, end.value)"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matPrefix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <div class="mar-daterange-arrow">
                <button (click)="lessWeek()" type="button" disableRipple>
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button (click)="addWeek()" type="button" disableRipple>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="content-body">
    <div class="mat-table-wrapper mt-2">
      <table
        mat-table
        [dataSource]="jobList"
        aria-describedby="Job list table"
        multiTemplateDataRows="true"
        matSort
        (matSortChange)="sorting(sort.active, sort.direction)"
      >
        <ng-container
          *ngFor="let column of displayedJobColumns; let colIndex = index"
          matColumnDef="{{ column.settingName }}"
          [sticky]="colIndex < 5 && !noDataFound"
        >
          <ng-container>
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              [id]="column.settingName"
              [ngClass]="[column.settingName === 'ID' ? 'hidden' : '']"
            >
              <span *ngIf="colIndex > 1">
                {{ column.settingName }}
              </span>
              <span
                *ngIf="column.settingName === 'Job ID'"
                class="d-flex align-items-center job-check gap-10"
              >
                {{ column.settingName }}
              </span>
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              [ngClass]="[column.settingName === 'ID' ? 'hidden' : '']"
            >
              <div class="d-flex align-items-center gap-20">
                <span
                  *ngIf="column.settingName === 'Job ID'"
                  class="d-flex align-items-center job-check gap-10"
                >
                  <div class="listview">
                    <img
                      [src]="
                        +element[colIndex].split('|')[0] ===
                        jobStatusModel.Completed
                          ? 'assets/images/check.svg'
                          : 'assets/images/uncheck.svg'
                      "
                      [alt]="
                        +element[colIndex].split('|')[0] ===
                        jobStatusModel.Completed
                          ? 'Check'
                          : 'Uncheck'
                      "
                      width="16px"
                      class="cursor-pointer"
                    />
                  </div>
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.TimeAndFee
                    "
                    src="assets/images/timeandfees.svg"
                    width="15px"
                    alt="Time and Fees"
                    matTooltip="Time and Fees"
                  />
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.FixedPrice
                    "
                    src="assets/images/fixedfees.svg"
                    width="18px"
                    alt="Fixed Price"
                    matTooltip="Fixed Price"
                  />
                  <img
                    *ngIf="
                      +element[colIndex].split('|')[1] === feeType.NonBillable
                    "
                    src="assets/images/nonbillable.svg"
                    width="15px"
                    alt="Non Billable"
                    matTooltip="Non Billable"
                  />
                  <a
                    class="hyperlink white-space-nowrap pr-10"
                    [title]="element[colIndex].split('|')[2]"
                    (click)="onJobEdit(element)"
                    >{{ element[colIndex].split("|")[2] }}
                  </a>
                </span>
                <span *ngIf="column.settingName === 'Fees Type'">
                  <span class="white-space-nowrap">
                    {{ element[colIndex] }}</span
                  >
                </span>
                <span
                  *ngIf="column.settingName === 'Status'"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="onelineclamp" title="{{ element[colIndex] }}">
                    <span
                      *ngIf="
                        +element[colIndex] === jobStatusModel.NotYetStarted
                      "
                    >
                      Yet to Start</span
                    >
                    <span
                      *ngIf="+element[colIndex] === jobStatusModel.InProgress"
                    >
                      In Progress</span
                    >
                    <span
                      *ngIf="+element[colIndex] === jobStatusModel.Completed"
                    >
                      Completed</span
                    >
                  </span>
                </span>
                <span
                  *ngIf="column.settingName === 'Task'"
                  title="{{ element[colIndex] }}"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="onelineclamp"> {{ element[colIndex] }}</span>
                </span>
                <span
                  *ngIf="column.settingName === 'User'"
                  title="{{ element[colIndex] }}"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="onelineclamp"> {{ element[colIndex] }}</span>
                </span>
                <span
                  *ngIf="column.settingName === 'Spent'"
                  class="d-flex white-space-nowrap align-items-center justify-space-between wp-100 gap-10"
                  >£ {{ element[colIndex] | number : "1.2-2" }}
                  <div class="w-50 grid-progress">
                    <mat-progress-bar
                      mode="determinate"
                      value="{{ element[colIndex] }}"
                    ></mat-progress-bar>
                  </div>
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Start Date' ||
                    column.settingName === 'End Date' ||
                    column.settingName === 'Created On' ||
                    column.settingName === 'Completed On'
                  "
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                  >{{ element[colIndex] | date : "dd-LLL-yyyy" }}
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Remaining' ||
                    column.settingName === 'Cost'
                  "
                  class="d-flex align-items-center gap-5 white-space-nowrap"
                  >£ {{ element[colIndex] | number : "1.2-2" }}
                </span>
                <span
                  *ngIf="
                    column.settingName === 'Created By' ||
                    column.settingName === 'Recurring'
                  "
                  title="{{ element[colIndex] }}"
                  class="d-flex align-items-center gap-5"
                >
                  <span class="white-space-nowrap">
                    {{ element[colIndex] }}</span
                  >
                </span>
                <div
                  [ngClass]="
                    column.settingName === 'Client' ||
                    column.settingName === 'User'
                      ? 'onelineclamp'
                      : ''
                  "
                  *ngIf="
                    colIndex > 1 &&
                    column.settingName !== 'Status' &&
                    column.settingName !== 'Task' &&
                    column.settingName !== 'User' &&
                    column.settingName !== 'Start Date' &&
                    column.settingName !== 'End Date' &&
                    column.settingName !== 'Created By' &&
                    column.settingName !== 'Created On' &&
                    column.settingName !== 'Completed On' &&
                    column.settingName !== 'Spent' &&
                    column.settingName !== 'Recurring' &&
                    column.settingName !== 'Remaining' &&
                    column.settingName !== 'Cost' &&
                    column.settingName !== 'Fees Type'
                  "
                >
                  {{ element[colIndex] }}
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="expandedSubtaskDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedJobColumns.length"
            class="flex-column inner-grid"
          >
            <div
              class="example-element-detail"
              [@detailExpand]="
                element == expandedJobElement ? 'expanded' : 'collapsed'
              "
            >
              <table
                aria-describedby="main-list"
                mat-table
                [dataSource]="jobSubtaskList"
                multiTemplateDataRows="true"
              >
                <ng-container
                  *ngFor="
                    let column of displayedSubtaskColumns;
                    let colIndex = index
                  "
                  matColumnDef="{{ column.name }}"
                >
                  <th mat-header-cell *matHeaderCellDef [id]="column.name">
                    <div *ngIf="colIndex === 0">
                      <a href="javascript:void(0)" class="text-gray">
                        <mat-icon class="cursor-pointer">expand_more</mat-icon>
                      </a>
                    </div>
                    <span *ngIf="colIndex !== 0">{{ column.name }}</span>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex align-items-center gap-20">
                      <div *ngIf="colIndex === 0">
                        <a
                          href="javascript:void(0)"
                          *ngIf="!(element === expandedSubtaskElement)"
                          class="text-gray"
                          (click)="expandCollapseSubtask(element)"
                        >
                          <mat-icon class="cursor-pointer"
                            >expand_more</mat-icon
                          >
                        </a>
                        <a
                          href="javascript:void(0)"
                          *ngIf="element === expandedSubtaskElement"
                          class="text-gray"
                          (click)="expandCollapseSubtask(element)"
                        >
                          <mat-icon class="cursor-pointer"
                            >expand_less</mat-icon
                          >
                        </a>
                      </div>
                      <div *ngIf="colIndex > 0">
                        <span
                          *ngIf="
                            column.name === 'Amount' || column.name === 'Cost'
                          "
                          class="d-flex align-items-center gap-5 white-space-nowrap"
                          >£ {{ element[colIndex] | number : "1.2-2" }}
                        </span>
                        <span
                          *ngIf="
                            column.name !== 'Amount' && column.name !== 'Cost'
                          "
                          class="d-flex align-items-center gap-5"
                        >
                          {{ element[colIndex] }}
                        </span>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="expandedSubtaskUserDetail">
                  <td mat-cell *matCellDef="let element" class="flex-column">
                    <table
                      aria-describedby="main-list two"
                      mat-table
                      matSort
                      (matSortChange)="sorting(sort.active, sort.direction)"
                      [dataSource]="dataSource"
                    >
                      <ng-container
                        *ngFor="
                          let column of displayedSubtaskUserColumns;
                          let colIndex = index
                        "
                        matColumnDef="{{ column.name }}"
                      >
                        <th
                          mat-header-cell
                          *matHeaderCellDef
                          [id]="column.name"
                          fxFlex="50"
                          [ngClass]="[colIndex === 0 ? 'hidden' : '']"
                        >
                          <div>
                            <span>
                              {{ column.name }}
                            </span>
                          </div>
                        </th>

                        <td
                          mat-cell
                          *matCellDef="let element; let rowIndex = index"
                        >
                          <div *ngIf="colIndex !== 0">
                            <div class="clamp">
                              <span
                                *ngIf="
                                  column.name === 'Amount' ||
                                  column.name === 'Cost'
                                "
                                class="d-flex align-items-center gap-5 white-space-nowrap"
                                >£ {{ element[colIndex] | number : "1.2-2" }}
                              </span>
                              <span
                                *ngIf="
                                  column.name !== 'Amount' &&
                                  column.name !== 'Cost'
                                "
                                class="d-flex align-items-center gap-5"
                              >
                                {{ element[colIndex] }}
                              </span>
                            </div>
                          </div>
                        </td>
                      </ng-container>
                      <tr
                        mat-row
                        *matRowDef="
                          let row;
                          columns: displayedSubtaskUserColumnNames
                        "
                      ></tr>
                    </table>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="displayedSubtaskColumnNames; sticky: true"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedSubtaskColumnNames"
                  matRipple
                  class="element-row"
                  [class.expanded]="row === expandedSubtaskElement"
                ></tr>

                <tr
                  mat-row
                  *matRowDef="let row; columns: ['expandedSubtaskUserDetail']"
                  class="example-detail-row"
                  style="overflow: hidden"
                ></tr>
              </table>
            </div>
            <div *ngIf="noSubtaskDataFound" class="text-align-center mt-1 mb-1">
              No records found
            </div>
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedJobColumnNames; sticky: true"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: displayedJobColumnNames"
          matRipple
          class="element-row"
          [class.expanded]="row === expandedJobElement"
        ></tr>

        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedSubtaskDetail']"
          class="example-detail-row"
          style="overflow: hidden"
        ></tr>
      </table>
      <div *ngIf="noDataFound" class="text-align-center mt-1 mb-1">
        No records found
      </div>
    </div>
  </div>
</div>
