import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DashboardRequestModel } from '@app/core/models';
import { GetTimeOffByUsers } from '@app/core/store';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
@Component({
  selector: 'app-task-time-byuser',
  templateUrl: './task-time-byuser.component.html',
  styleUrls: ['./task-time-byuser.component.scss'],
})
export class TaskTimeByuserComponent {
  public primaryXAxis?: Object;
  public chartData?: Object[];
  public title?: string;
  public primaryYAxis?: Object;
  public tooltip?: Object;
  dashboardRequestModel: DashboardRequestModel;
  filter = 1;
  start: string = '';
  end: string = '';
  @Input()
  triggerFilterData: any;

  constructor(private store: Store, public datepipe: DatePipe) {}

  ngOnInit(): void {
    this.tooltip = {
      enable: true,
      format: '<span>${point.tooltip}</span>',
      header: '',
    };
    this.primaryXAxis = {
      valueType: 'Category',
    };

    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getTimeOffByUsers();
    });
  }

  getTimeOffByUsers(): void {
    this.dashboardRequestModel = {
      filter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    this.store
      .dispatch(new GetTimeOffByUsers(this.dashboardRequestModel))
      .pipe(
        tap((res) => {
          this.chartData = res.dashboard.timeOffByUsers;
          window.dispatchEvent(new Event('resize'));
        })
      )
      .subscribe();
  }
}
