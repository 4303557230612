import { PaymentDueTerms } from '../../models';

export class GetPaymentDueList {
  static readonly type = '[PAYMENTDUE] Get Payment Due List';
}

export class CreatePaymentDue {
  static readonly type = '[PAYMENTDUE] Create Payment Due';

  constructor(public paymentDueTerms: PaymentDueTerms) {}
}
