export class ProjectInvoices {
  id?: number;
  clientId?: number;
  projectName: string;
  projectCode: string;
  startDate: Date;
  endDate: Date;
  projectTypeId?: number;
  hourlyRateTypeId?: number;
  budgetTypeId?: number;
  totalBudget?: number;
  includeBillableAndNonBillable?: boolean;
  sendEmailAlertIfExceed: boolean;
  exceedBudgetToSendEmail: number;
  permissionsId: number;
}
