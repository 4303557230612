export class UserProject {
  userId?: string;
  userName?: string;
  firstName?: number;
  lastName?: string;
  projectId?: number;
  assigned?: boolean;
  isManager?: boolean;
  budgetPerMonth?: number;
  hourlyRate?: string;
  isAssign?: boolean;
}

export class UserModelProject {
  userId?: string;
  fullName?: string;
  projectId?: string;
  isManager?: boolean;
  isAssign?: boolean;
  budgetPerMonth?: number;
  hourlyRate?: number;
  hours?: string;
  isAdminOrOwner: boolean;
}
