import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.scss'],
})
export class CustomLoaderComponent {
  public isHidden;
  hideloader(isHidden: boolean): void {
    setTimeout(() => {
      const spinner = document.getElementById('spinner1')!;
      if (isHidden && spinner !== null) {
        spinner.style.display = 'none';
      }
      if (!isHidden && spinner !== null) {
        spinner.style.display = 'block';
      }
    }, 1000);
  }
}
