import { Guid } from 'guid-typescript';
import { ExportAction } from '../../enums';
import {
  ExportParams1,
  InvoiceBasicInfo,
  InvoiceDynamicDetailGrid,
  InvoiceEmailModel,
  InvoiceParameters,
  InvoicePaymentModel,
  InvoiceSendAsLinkModel,
  Note,
  QueryParams,
  RecurringSchedule,
  ReminderTypeModel,
} from '../../models';

export class GetInvoiceList {
  static readonly type = '[INVOICE] Get invoice names ';

  constructor(public queryParams: QueryParams) {}
}

export class GetInvoiceDetailList {
  static readonly type = '[INVOICE] Get Invoices List';

  constructor(public invoiceParams: InvoiceParameters) {}
}

export class GetInvoiceByUniversalId {
  static readonly type = '[INVOICE] Get Invoice By UniversalId';

  constructor(public universalId: Guid) {}
}

export class SaveInvoice {
  static readonly type = '[INVOICE] Save Invoice';

  constructor(public invoiceBasicInfo: InvoiceBasicInfo) {}
}

export class GetInvoicePaymentsDetailList {
  static readonly type = '[INVOICE] Get Invoice Payments Detail List';

  constructor(public queryParams: any) {}
}

/////////////////////////////

export class CopyInvoices {
  static readonly type = '[INVOICE] Copy Selected Invoice';

  constructor(public invoiceIds?: Array<Guid>) {}
}

export class DeleteSelectedInvoice {
  static readonly type = '[INVOICE] Delete Selected Invoice';

  constructor(public invoiceIds?: Array<Guid>) {}
}

export class UpdateInvoiceStatus {
  static readonly type = '[INVOICE] Update Invoice Status';

  constructor(public invoiceIds?: Array<Guid>, public status?: number) {}
}

export class ExportInvoice {
  static readonly type = '[INVOICE] Export Invoice';

  constructor(public exportParams: ExportParams1) {}
}

export class SendInvoice {
  static readonly type = '[INVOICE] Send Invoice';

  constructor(public invoiceId: Guid) {}
}

export class SetInvoiceId {
  static readonly type = '[INVOICE] Set Invoice ID ';

  constructor(public invoiceId: Guid) {}
}

export class GetScheduleSettingByInvoiceId {
  static readonly type = '[INVOICE] Get Schedule Setting By InvoiceId';

  constructor(public invoiceId: Guid) {}
}

export class SaveRecurringSchedule {
  static readonly type = '[INVOICE] Save Recurring Schedule';

  constructor(public recurringSchedule: RecurringSchedule) {}
}

export class SaveInvoicePayment {
  static readonly type = '[INVOICE] Save Invoice Payment';

  constructor(public invoicePayment: InvoicePaymentModel) {}
}

export class DeleteInvoicePayment {
  static readonly type = '[INVOICE] Delete Invoice Payment';

  constructor(public paymentId: string) {}
}

export class GetPaymentByPaymentId {
  static readonly type = '[INVOICE] Get Payment By Payment ID';

  constructor(public paymentId: Guid) {}
}

export class ExportInvoicePaymentToPDF {
  static readonly type = '[INVOICE] Export Invoice Payment To PDF';

  constructor(public paymentId: number, public exportAction: ExportAction) {}
}

export class SendPaymentReceipt {
  static readonly type = '[INVOICE] Send Payment Receipt';

  constructor(public paymentId: number) {}
}

export class GetInvoiceNoteListById {
  static readonly type = '[INVOICE] Get Invoice Note List By ID';

  constructor(public invoiceId: Guid, public searchText?: string) {}
}

export class SaveInvoiceNotes {
  static readonly type = '[INVOICE] Save Invoice Notes';

  constructor(public note: Note) {}
}

export class ExportInvoiceToPDF {
  static readonly type = '[INVOICE] Export Invoice To PDF';

  constructor(
    public invoiceId: Guid,
    public format: number,
    public fileName: string,
    public exportAction: ExportAction
  ) {}
}

export class SendAsLinkInvoice {
  static readonly type = '[INVOICE] Send As Link Invoice';

  constructor(public invoiceSendAsLinkModel: InvoiceSendAsLinkModel) {}
}

export class DeleteAllInvoicePayment {
  static readonly type = '[INVOICE] Delete All Invoice Payment';

  constructor(public invoiceId: Guid) {}
}

export class SendInvoiceEmail {
  static readonly type = '[INVOICE] Send Invoice Email';

  constructor(public emailModel: InvoiceEmailModel) {}
}

export class SetInvoiceDueAmount {
  static readonly type = '[PROJECT] Set Invoice Total Amount ';

  constructor(public dueAmount: number) {}
}

export class GetAmountDue {
  static readonly type = '[INVOICE] Get Amount Due';

  constructor(public invoiceId: Guid) {}
}

export class GetInvoiceTemplate {
  static readonly type = '[INVOICE] Get Invoice Template By Invoice ID';
  constructor(public invoiceId: Guid, public templateId: number) {}
}

export class GetInvoiceRecentHistory {
  static readonly type = '[INVOICE] Get Invoice Recent History';

  constructor(public invoiceId: Guid) {}
}

export class GetReminderType {
  static readonly type = '[INVOICE] Get Reminder Type';
}

export class GetSelectedReminderType {
  static readonly type = '[INVOICE] Get Selected Reminder Type';

  constructor(
    public invoiceId: Guid,
    public reminderType: Array<ReminderTypeModel>
  ) {}
}

export class SaveReminder {
  static readonly type = '[INVOICE] Save Reminder';

  constructor(
    public invoiceId: Guid,
    public reminderTypeModel: Array<ReminderTypeModel>
  ) {}
}

export class GetUserInvoiceSetting {
  static readonly type = '[INVOICE] Get User Invoice Setting';

  constructor(public moduleId: number) {}
}

export class SaveInvoiceDyanamicGird {
  static readonly type = '[INVOICE] Save Invoice Dyanamic Gird';

  constructor(public dynamicDetailData: InvoiceDynamicDetailGrid) {}
}

export class SetInvoiceDefaultState {
  static readonly type = '[INVOICE] Set Invoice Default State';
}

export class ExportInvoicesToPDF {
  static readonly type = '[INVOICE] Export Invoices To PDF';

  constructor(
    public invoiceIds: Array<Guid>,
    public exportAction: ExportAction
  ) {}
}

export class GetServiceExpenses {
  static readonly type = '[INVOICE] Get Service Expenses';

  constructor(public universalId: Guid) {}
}

export class GetInvoiceReceiptDetails {
  static readonly type = '[INVOICE] Get Invoice Receipt Details';

  constructor(public universalId: Guid) {}
}

export class SetDefaultInvoiceId {
  static readonly type = '[INVOICE] Set Default Invoice ID';
}

export class CalculateInvoiceByClientId {
  static readonly type = '[INVOICE] Calculate Invoice By Client ID';

  constructor(public clientId: Guid) {}
}
