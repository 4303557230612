import { FlatTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatSort } from '@angular/material/sort';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  CommonNotificationText,
  TimelogStatus,
  TimelogTypeList,
} from '@app/core/enums';
import {
  JobTimelogParams,
  MainListParameters,
  SideListModel,
  TimeModule,
  TimelogByUser,
  TimelogByWeek,
} from '@app/core/models';
import {
  GetStartWeekAndEndWeek,
  GetTimelogByUser,
  GetTimelogByWeek,
  GetUsersWhoLoggedTime,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export class TimelogByUserListNode extends TimelogByUser {
  fullName: string;
  weekData: Array<TimelogByUserListNode>;
}

export class TimelogByUserListFlatNode extends TimelogByUserListNode {
  expandable: boolean;
  level: number;
}

export class TimelogByWeekListNode extends TimelogByWeek {
  weekName: string;
  userData: Array<TimelogByWeekListNode>;
}

export class TimelogByWeekListFlatNode extends TimelogByWeekListNode {
  expandable: boolean;
  level: number;
}

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-job-details-timelog',
  templateUrl: './job-details-timelog.component.html',
  styleUrls: ['./job-details-timelog.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class JobDetailsTimelogComponent {
  showColumn = false;
  timesheetForm: FormGroup;
  searchForm: FormGroup;
  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });
  userList: SideListModel[];
  selectedUserIds: Guid[] = [];
  jobId = Guid.EMPTY as unknown as Guid;
  selectedTypeId = 0;
  selectedStatusId = TimelogStatus.Approved.toString();
  timelogStatus = TimelogStatus;
  toggleAll = false;
  searchText = '';
  listParameters: MainListParameters = new MainListParameters();
  commonNotificationText = CommonNotificationText;
  jobTimelogSubscription: Subscription;
  timelogTypes = TimelogTypeList;
  jobTimelogParams = new JobTimelogParams();
  timelogData: TimeModule[];

  @ViewChild(MatSort) sort: MatSort;

  @Input()
  triggerJobTimelogs: Observable<any>;

  @Input()
  triggerJobTimelogData: Observable<any>;

  @Output()
  readonly overviewListClick = new EventEmitter<any>();
  triggerJobIdToChart: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  displayedTimelogByUserColumns: string[] = [
    'fullName',
    'totalHours',
    'totalBillableHours',
    'totalNonBillableHours',
    'totalAmount',
    'totalCost',
  ];

  displayedTimelogByWeekColumns: string[] = [
    'weekName',
    'totalHours',
    'totalBillableHours',
    'totalNonBillableHours',
    'totalAmount',
    'totalCost',
  ];

  timelogTypeList: any[] = [
    {
      value: TimelogTypeList.ByUser,
      name: 'By User',
    },
    {
      value: TimelogTypeList.ByWeek,
      name: 'By Week',
    },
  ];

  private transformerByUser = (node: TimelogByUserListNode, level: number) => {
    return {
      expandable: !!node.weekData && node.weekData.length > 0,
      weekData: node.weekData,
      userId: node.userId,
      fullName: node.fullName,
      weekName: node.weekName,
      userType: node.userType,
      totalHours: node.totalHours,
      totalBillableHours: node.totalBillableHours,
      totalNonBillableHours: node.totalNonBillableHours,
      totalAmount: node.totalAmount,
      totalCost: node.totalCost,
      weekStartDate: node.weekStartDate,
      weekEndDate: node.weekEndDate,
      isSelected: node.isSelected,
      level,
    };
  };

  treeControlByUser = new FlatTreeControl<TimelogByUserListFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  // tslint:disable-next-line:member-ordering
  treeFlattenerByUser = new MatTreeFlattener(
    this.transformerByUser,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.weekData
  );

  // tslint:disable-next-line:member-ordering
  timelogByUserDataSource = new MatTreeFlatDataSource(
    this.treeControlByUser,
    this.treeFlattenerByUser
  );

  getLevel = (node: TimelogByUserListFlatNode) => node.level;

  private transformerByWeek = (node: TimelogByWeekListNode, level: number) => {
    return {
      expandable: !!node.userData && node.userData.length > 0,
      userData: node.userData,
      userId: node.userId,
      fullName: node.fullName,
      weekName: node.weekName,
      userType: node.userType,
      totalHours: node.totalHours,
      totalBillableHours: node.totalBillableHours,
      totalNonBillableHours: node.totalNonBillableHours,
      totalAmount: node.totalAmount,
      totalCost: node.totalCost,
      weekStartDate: node.weekStartDate,
      weekEndDate: node.weekEndDate,
      isSelected: node.isSelected,
      level,
    };
  };

  treeControlByWeek = new FlatTreeControl<TimelogByWeekListFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  // tslint:disable-next-line:member-ordering
  treeFlattenerByWeek = new MatTreeFlattener(
    this.transformerByWeek,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.userData
  );

  // tslint:disable-next-line:member-ordering
  timelogByWeekDataSource = new MatTreeFlatDataSource(
    this.treeControlByWeek,
    this.treeFlattenerByWeek
  );

  getLevelByWeek = (node: TimelogByWeekListFlatNode) => node.level;

  toggleList(): void {
    this.showColumn = !this.showColumn;
  }

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.jobTimelogSubscription = this.triggerJobTimelogData.subscribe(
      (data) => {
        if (data) {
          this.jobId = data;
          this.getUsersWhoLoggedTime();
          this.triggerJobIdToChart.next(this.jobId);
          this.getList();
        }
      }
    );

    this.triggerJobTimelogs.subscribe((data) => {
      if (data) {
        this.triggerJobIdToChart.next(this.jobId);
        this.getList();
      }
    });
  }

  ngOnDestroy(): void {
    this.jobTimelogSubscription?.unsubscribe();
  }

  setForm(): void {
    this.timesheetForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });

    this.searchForm = this.formBuilder.group({
      search: new FormControl<string | null>(''),
    });
  }

  getList(): void {
    if (this.selectedTypeId === TimelogTypeList.ByUser) {
      this.getTimeLogByUser();
    } else {
      this.getTimeLogByWeek();
    }
  }

  getTimeLogByUser(): void {
    this.store
      .dispatch(new GetTimelogByUser(this.getParams()))
      .subscribe((res) => {
        this.timelogByUserDataSource.data = res.time.jobTimelogsByUser;
      });
  }

  getTimeLogByWeek(): void {
    this.store
      .dispatch(new GetTimelogByWeek(this.getParams()))
      .subscribe((res) => {
        this.timelogByWeekDataSource.data = res.time.jobTimelogsByWeek;
      });
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.timesheetForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.getList();
    });
  }

  addWeek(e: any): void {
    e.stopPropagation();
    this.timesheetForm = this.formBuilder.group({
      start: new Date(
        new Date(this.timesheetForm.controls.end.value).setDate(
          new Date(this.timesheetForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.timesheetForm.controls.end.value).setDate(
          new Date(this.timesheetForm.controls.end.value).getDate() + 7
        )
      ),
    });

    this.startDate.setValue(this.timesheetForm.controls.start.value);
    this.endDate.setValue(this.timesheetForm.controls.end.value);

    this.getList();
  }

  lessWeek(e: any): void {
    e.stopPropagation();
    this.timesheetForm = this.formBuilder.group({
      end: new Date(
        new Date(this.timesheetForm.controls.start.value).setDate(
          new Date(this.timesheetForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.timesheetForm.controls.start.value).setDate(
          new Date(this.timesheetForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.timesheetForm.controls.start.value);
    this.endDate.setValue(this.timesheetForm.controls.end.value);

    this.getList();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.getList();
    }
  }

  getUsersWhoLoggedTime(): void {
    if (this.jobId) {
      this.store
        .dispatch(new GetUsersWhoLoggedTime(this.jobId))
        .pipe(
          tap((res) => {
            this.userList = res.time.usersWhoLoggedTimeList;
          })
        )
        .subscribe();
    }
  }

  getParams(): any {
    this.jobTimelogParams = {
      jobId: this.jobId,
      status: +this.selectedStatusId,
      userIds: this.selectedUserIds,
      search: this.searchText,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
    };

    return this.jobTimelogParams;
  }

  setDateToToday(): any {
    this.getStartWeekAndEndWeek();
  }

  overviewClick(element?: any) {
    this.dataSubmit(element);
    const data = {
      timelogData: this.timelogData,
      status: this.selectedStatusId,
    };
    this.overviewListClick.emit(data);
  }

  onUserSelectionChange(): void {
    this.getList();
  }

  onTypeChange(val): void {
    this.toggleAll = false;
    this.getList();
  }

  onStatusChange(val): void {
    this.getList();
  }

  toggleAllClick(): void {
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.treeControlByUser.expandAll();
      this.treeControlByWeek.expandAll();
    } else {
      this.treeControlByUser.collapseAll();
      this.treeControlByWeek.collapseAll();
    }
  }

  onSearch(event: any): void {
    this.searchText = event.target.value;
    this.getList();
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getList();
  }

  dataSubmit(element?: any): void {
    this.timelogData = [];
    const obj = {
      userId: element.userId,
      startDate: this.datepipe
        .transform(element.weekStartDate, 'yyyy-MM-dd')
        ?.toString(),
      endDate: this.datepipe
        .transform(element.weekEndDate, 'yyyy-MM-dd')
        ?.toString(),
    };
    this.timelogData.push(obj);
  }
}
