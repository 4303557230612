import { Injectable } from '@angular/core';
import { ActionModel } from '@app/core/models/common/grid-action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  Note,
  Profile,
  ProjectClient,
  SideListModel,
  UserModel,
  UserPermission,
  UserTaskModel,
} from '../../models';
import { ProfileService, UserService } from '../../services';
import {
  ArchiveAndRestoreUser,
  CopyUser,
  DeleteSelectedUser,
  DeleteUserClientByUserId,
  DeleteUserTaskByUserId,
  ExportUser,
  GetAllUsers,
  GetNotification,
  GetUserClientsDetails,
  GetUserDataByUserId,
  GetUserDataNotes,
  GetUserDetailList,
  GetUserOverview,
  GetUserPermissionsList,
  GetUserTasksDetails,
  GetUsersByPermission,
  ImportUsers,
  InviteUser,
  LockUnlockDashboard,
  SaveCapacityPermission,
  SaveUser,
  SaveUserBasicInfo,
  SaveUserClients,
  SaveUserNotes,
  SaveUserProject,
  SaveUserTask,
  SetNotification,
  SetUserDefaultState,
  SetUserId,
} from './user.action';

export class UserStateInfo {
  userList?: Array<UserModel>;
  userId?: Guid;
  userData?: UserModel;
  allUsers?: Array<UserModel>;
  userResponse?: any;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  projectList?: Array<ProjectClient>;
  isLastPage?: boolean;
  totalRecord?: number;
  userNotesData?: Array<Note>;
  userBasicInfoData?: UserModel;
  userPermissionList?: Array<UserPermission>;
  highlightIDs?: Array<string>;
  importData?: any;
  isDataAvailable?: boolean;
  userOverview?: any;
  usersByPermissionList?: Array<SideListModel>;
  isSuccess?: boolean;
  profileNotification?: Profile;
  userTasksData?: Array<UserTaskModel>;
  usersTaskDataLength?: number;
  isTaskAssignedToUser?: boolean;
  isDelete?: boolean;
  userClientsData?: Array<any>;
  userClientsDataLength?: number;
  isUserAssignedToClient?: boolean;
  gridActions?: ActionModel[];
  totalRecordOfUserClient?: number;
  totalRecordOfUserTask?: number;
}

@State<UserStateInfo>({
  name: 'user',
  defaults: {
    userList: [],
    isDataAvailable: true,
    allUsers: [],
    usersTaskDataLength: 0,
    userClientsDataLength: 0,
  },
})
@Injectable()
export class UserState {
  constructor(
    private userService: UserService,
    private profileService: ProfileService
  ) {}

  @Selector()
  static getUserId(state: UserStateInfo) {
    return state.userId;
  }

  @Selector()
  static getUserResponse(state: UserStateInfo) {
    return state.userResponse;
  }

  @Selector()
  static getAllUsers(state: UserStateInfo) {
    return state.allUsers;
  }

  @Selector()
  static getUserBasicInfoData(state: UserStateInfo) {
    return state.userBasicInfoData;
  }

  @Selector()
  static getUserNotesData(state: UserStateInfo) {
    return state.userNotesData;
  }

  @Selector()
  static getUserList(state: UserStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static getProjectDetailList(state: UserStateInfo) {
    return state.projectList;
  }

  @Selector()
  static isLastPage(state: UserStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static totalRecord(state: UserStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static totalRecordOfUserClient(state: UserStateInfo) {
    return state.totalRecordOfUserClient;
  }

  @Selector()
  static totalRecordOfUserTask(state: UserStateInfo) {
    return state.totalRecordOfUserTask;
  }

  @Selector()
  static getProfileNotification(state: UserStateInfo) {
    return state.profileNotification;
  }

  @Selector()
  static getUserPermissions(state: UserStateInfo) {
    return state.userPermissionList;
  }

  @Selector()
  static getHighlightedIds(state: UserStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: UserStateInfo) {
    return state.importData;
  }

  @Selector()
  static isDataAvailable(state: UserStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static getUserTasksDetails(state: UserStateInfo) {
    return state.userTasksData ?? [];
  }

  @Selector()
  static getUserTasksDataLength(state: UserStateInfo) {
    return state.usersTaskDataLength;
  }

  @Selector()
  static userData(state: UserStateInfo): UserModel {
    return state.userData!;
  }

  @Selector()
  static getUserClientsDetails(state: UserStateInfo) {
    return state.userClientsData ?? [];
  }

  @Selector()
  static getUserClientsDataLength(state: UserStateInfo) {
    return state.userClientsDataLength;
  }

  @Selector()
  static getGridActions(state: UserStateInfo) {
    return state.gridActions;
  }

  @Action(GetAllUsers)
  getAllUsers(
    { patchState }: StateContext<UserStateInfo>,
    action: GetAllUsers
  ) {
    return this.userService.getAllUsers().pipe(
      tap((res) => {
        patchState({
          allUsers: res,
        });
      })
    );
  }

  @Action(GetUserDataByUserId)
  getUserDataByUserId(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: GetUserDataByUserId
  ) {
    return this.userService.getUserDataByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          userId: action.userId,
          userData: res,
        });
      })
    );
  }

  @Action(SaveUserBasicInfo)
  saveUserBasicInfo(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SaveUserBasicInfo
  ) {
    return this.userService.saveUserBasicInfo(action.user).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.userList,
          userId: res.data,
          userResponse: res,
        });
      })
    );
  }

  @Action(SaveCapacityPermission)
  saveCapacityPermission(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SaveCapacityPermission
  ) {
    return this.userService.saveCapacityPermission(action.user).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.userList,
          userId: res,
        });
      })
    );
  }

  @Action(SaveUserProject)
  saveUserProject(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SaveUserProject
  ) {
    return this.userService.saveUserProject(action.userProject).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.userList,
          userList: res,
        });
      })
    );
  }

  @Action(SaveUserNotes)
  saveUserNotes(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SaveUserNotes
  ) {
    return this.userService.saveUserNotes(action.userId, action.user).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.userList,
          userList: res,
        });
      })
    );
  }

  @Action(GetUserDataNotes)
  getUserDataNotes(
    { getState, setState }: StateContext<UserStateInfo>,
    action: GetUserDataNotes
  ) {
    return this.userService.getUserDataNotes(action.userId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userNotesData: res,
          userId: action.userId,
        });
      })
    );
  }

  @Action(DeleteSelectedUser)
  deleteSelectedUser(
    { getState, setState }: StateContext<UserStateInfo>,
    action: DeleteSelectedUser
  ) {
    return this.userService.deleteUsers(action.userIds).pipe(
      tap(() => {
        const state = getState();

        const filteredUser = state.userList?.filter(
          (item) =>
            !action.userIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.userIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        setState({
          ...state.userList,
          userList: filteredUser,
          sideListModel: filteredForSideList,
        });
      })
    );
  }
  @Action(ExportUser)
  exportUser(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: ExportUser
  ) {
    return this.userService.exportUser(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });
        return EMPTY;
      })
    );
  }
  @Action(GetUserDetailList, { cancelUncompleted: true })
  getUserDetailList(
    { getState, setState }: StateContext<UserStateInfo>,
    action: GetUserDetailList
  ) {
    return this.userService.getUserDetailList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecord = headers.TotalItemCount;
        const isDataAvailable = headers.IsBusinessDataFound;
        setState({
          ...state,
          userList: res.body!.data,
          gridActions: res.body!.actions,
          isDataAvailable,
          totalRecord,
        });
      })
    );
  }

  @Action(GetUserOverview, { cancelUncompleted: true })
  getUserOverview(
    { getState, setState }: StateContext<UserStateInfo>,
    action: GetUserOverview
  ) {
    return this.userService
      .getUserOverview(action.userId, action.monthNumber)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            userOverview: res,
          });
        })
      );
  }

  @Action(CopyUser)
  copyUser({ patchState }: StateContext<UserStateInfo>, action: CopyUser) {
    return this.userService.copy(action.userIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreUser)
  archiveRestoreUser(
    { getState, setState }: StateContext<UserStateInfo>,
    action: ArchiveAndRestoreUser
  ) {
    return this.userService
      .archiveAndRestoreUser(action.userIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          setState({
            ...state.userList,
            userList: state.userList,
          });
        })
      );
  }

  @Action(SetUserId)
  setUserId(
    { getState, setState }: StateContext<UserStateInfo>,
    action: SetUserId
  ) {
    const state = getState();

    setState({
      ...state.projectList,
      userId: action.userId,
      userList: state.userList,
    });
  }

  //#region profile
  @Action(GetNotification)
  getNotification(
    { getState, setState }: StateContext<UserStateInfo>,
    action: GetNotification
  ) {
    return this.profileService.getProfileNotification(action.userId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          profileNotification: res,
        });
      })
    );
  }

  @Action(SetNotification)
  setNotification(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SetNotification
  ) {
    return this.profileService
      .updateProfileNotification(action.profileNotfication)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            profileNotification: res,
          });
        })
      );
  }
  //#endregion

  @Action(ImportUsers)
  importUsers(
    { patchState }: StateContext<UserStateInfo>,
    action: ImportUsers
  ) {
    return this.userService.importUsers(action.fileImportRequestModel).pipe(
      tap((res) => {
        patchState({
          importData: res,
        });
      })
    );
  }

  @Action(GetUserPermissionsList)
  getUserPermissionsList({ patchState }: StateContext<UserStateInfo>) {
    return this.userService.getUserPermissions().pipe(
      tap((res) => {
        patchState({
          userPermissionList: res,
        });
      })
    );
  }

  @Action(SetUserDefaultState)
  setUserDefaultState({ patchState }: StateContext<UserStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(InviteUser)
  inviteUser({ patchState }: StateContext<UserStateInfo>, action: InviteUser) {
    return this.userService.inviteUser(action.universalId).pipe(
      tap((res) => {
        patchState({
          userResponse: res,
          isSuccess: res.isSuccess,
        });
      })
    );
  }

  @Action(SaveUser)
  saveUser({ patchState }: StateContext<UserStateInfo>, action: SaveUser) {
    return this.userService.saveUser(action.user).pipe(
      tap((res) => {
        patchState({
          isSuccess: res.isSuccess,
          userId: res.data,
          userResponse: res,
        });
      })
    );
  }

  @Action(GetUsersByPermission)
  getUsersByPermission({ patchState }: StateContext<UserStateInfo>) {
    return this.userService.getUsersByPermission().pipe(
      tap((res) => {
        patchState({
          usersByPermissionList: res,
        });
      })
    );
  }

  @Action(GetUserTasksDetails)
  getUserTasksDetails(
    { patchState }: StateContext<UserStateInfo>,
    action: GetUserTasksDetails
  ) {
    return this.userService.getUserTasksDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfUserTask = headers.TotalItemCount;
        patchState({
          totalRecordOfUserTask,
          userTasksData: res.body!,
          usersTaskDataLength: res.body!.length,
        });
      })
    );
  }

  @Action(SaveUserTask)
  saveUserTask(
    { getState, patchState }: StateContext<UserStateInfo>,
    action: SaveUserTask
  ) {
    return this.userService.saveUserTask(action.userId, action.userTask).pipe(
      tap((res) => {
        patchState({
          isTaskAssignedToUser: res,
        });
      })
    );
  }

  @Action(DeleteUserTaskByUserId)
  deleteUserTaskByUserId(
    { patchState }: StateContext<UserStateInfo>,
    action: DeleteUserTaskByUserId
  ) {
    return this.userService.deleteUserTaskByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(GetUserClientsDetails)
  getUserClientsDetails(
    { patchState }: StateContext<UserStateInfo>,
    action: GetUserClientsDetails
  ) {
    return this.userService.getUserClientsDetails(action.queryParams).pipe(
      tap((res) => {
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecordOfUserClient = headers.TotalItemCount;
        patchState({
          totalRecordOfUserClient,
          userClientsData: res.body,
          userClientsDataLength: res.body.length,
        });
      })
    );
  }

  @Action(SaveUserClients)
  saveUserClients(
    { patchState }: StateContext<UserStateInfo>,
    action: SaveUserClients
  ) {
    return this.userService
      .saveUserClients(action.userId, action.userClients)
      .pipe(
        tap((res) => {
          patchState({
            isUserAssignedToClient: res,
          });
        })
      );
  }

  @Action(DeleteUserClientByUserId)
  deleteUserClientByUserId(
    { patchState }: StateContext<UserStateInfo>,
    action: DeleteUserClientByUserId
  ) {
    return this.userService.deleteUserClientByUserId(action.userId).pipe(
      tap((res) => {
        patchState({
          isDelete: res,
        });
      })
    );
  }

  @Action(LockUnlockDashboard)
  addRemoveDemoData(
    { getState }: StateContext<UserStateInfo>,
    action: LockUnlockDashboard
  ) {
    return this.userService.lockUnlockDashboard(action.isLocked);
  }
}
