import { Guid } from 'guid-typescript';

export class RecurringInvoices {
  universalId?: Guid;
  invoiceId?: Guid;
  clientId?: Guid;
  clientName?: string;
  invoiceNo?: string;
  totalAmount?: number;
  recurringStatus?: number;
  scheduleMessage?: string;
  createdOn?: Date;
  firstRecurrenceDate?: string | null;
  endRecurrenceDate?: string | null;
  nextRecurrenceDate?: Date;
  lastRecurrenceDate?: Date;
  recurringTypeId: number;
  recurringEndTypeId: number | null;
  subRecurringTypeId: number | null;
  scheduleId?: string;
  maxNumOfOccurrences?: number;
  separationCount?: number;
  dayOfWeek?: number | null;
  dayOfMonth?: number | null;
  weekOfMonth?: number | null;
  monthOfYear?: number | null;
  timeZone?: string;
  noOfRecurrence?: number;
  createdBy?: string;
  updatedBy?: string;
  updatedOn?: Date;
  isDeleted?: boolean;
  isSelected?: boolean;
  businessId?: number;
  totalItemCount?: number;
}
