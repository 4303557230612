<div
  class="timelog-chart"
  *ngIf="
    chartOptions &&
    jobTimelogChartData?.categories?.length !== 0 &&
    jobTimelogChartData?.series?.length !== 0
  "
>
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [dataLabels]="chartOptions.dataLabels!"
    [plotOptions]="chartOptions.plotOptions!"
    [yaxis]="chartOptions.yaxis!"
    [legend]="chartOptions.legend!"
    [fill]="chartOptions.fill!"
    [stroke]="chartOptions.stroke!"
    [tooltip]="chartOptions.tooltip!"
    [xaxis]="chartOptions.xaxis!"
  ></apx-chart>
</div>

<div
  class="timelog-chart norecord-chart"
  *ngIf="
    jobTimelogChartData?.categories?.length === 0 &&
    jobTimelogChartData?.series?.length === 0
  "
>
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
