import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
} from '@angular/core';

@Directive({ selector: '[appTwoDigitDecimalNumber]' })
export class TwoDigitDecimaNumberDirective implements OnInit {
  private regexString(max?: number) {
    const maxStr = max ? `{0,${max}}` : `+`;
    return `^(\\d${maxStr}(\\.\\d{0,2})?|\\.\\d{0,2})$`;
  }

  private digitRegex: RegExp;
  private setRegex(maxDigits?: number) {
    this.digitRegex = new RegExp(this.regexString(maxDigits), 'g');
  }

  @Input() set maxDigits(maxDigits: number) {
    this.setRegex(maxDigits);
  }

  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
    this.setRegex();
  }

  ngOnInit() {
    if (!this.isFormatted(this.el.value)) {
      this.el.value = this.formatCurrency(this.el.value);
    }
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = this.removeCurrencyFormatting(value);
    this.el.select();
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = this.formatCurrency(value);
  }

  @HostListener('keydown.control.z', ['$event.target.value'])
  onUndo(value) {
    this.el.value = '';
  }

  private isFormatted(value: string): boolean {
    return value.includes('$');
  }

  private removeCurrencyFormatting(value: string): string {
    return value.replace(/[^0-9.]+/g, '');
  }

  private formatCurrency(value: string): string {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? '' : numberValue.toFixed(2);
  }

  private lastValid = '';
  @HostListener('input', ['$event'])
  onInput(event) {
    const cleanValue = (event.target.value.match(this.digitRegex) || []).join(
      ''
    );
    if (cleanValue || !event.target.value) this.lastValid = cleanValue;
    this.el.value = cleanValue || this.lastValid;
  }
}
