import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HighLightModel } from '../../models/common/side-list';

@Injectable({
  providedIn: 'root',
})
export class HighlightRow {
  mainListHighlighted: HighLightModel;
  sideListHighlighted: HighLightModel;
  defaultColor: Subject<string>;

  //-----------
  isHighlighted: boolean;
  sortBy: string;

  constructor() {
    this.sideListHighlighted = new HighLightModel();
    this.mainListHighlighted = new HighLightModel();
    this.defaultColor = new Subject<string>();
  }

  // constructor() {
  //   this.isHighlighted = false;
  //   this.defaultColor = new Subject<string>();
  // }
}
