export enum TimeMode {
  Duration = 1,
  StartAndEnd = 2,
}

export enum TimelogAction {
  'All Time Statuses' = -1,
  Unsubmitted = 0,
  PFA = 1,
  Approved = 2,
  Rejected = 3,
}

export enum TimelogTypeList {
  ByUser = 0,
  ByWeek = 1,
}

export enum TimeModuleSelectedTab {
  timesheet = 0,
  unsubmitted = 1,
  pendingApproval = 2,
  archive = 3,
  sendReminder = 4,
  withdrawApproval = 5,
  withdrawRejected = 6,
}

export enum TimeSheetButtonText {
  submitForApproval = 'Submit for Approval',
  reSubmitForApproval = 'Resubmit for Approval',
  withdrawApproval = 'Withdraw Approval',
  Approve = 'Approve',
}

export enum TimelogStatus {
  Unsubmitted = 0,
  PFA = 1,
  Approved = 2,
  Rejected = 3,
}

export enum TimelogGroupByList {
  BySubtask = 1,
  ByUser = 2,
}

export enum GroupByReport {
  ByClient = 1,
  ByJob = 2,
  ByTask = 3,
  ByUser = 4,
  ByDate = 5,
}

export enum GroupByExpenseReport {
  ByClient = 1,
  ByTask = 2,
  ByExpenseType = 3,
  ByUser = 4,
  ByDate = 5,
}

export enum TimelogActionStatus {
  CanEditOrDelete = 0,
  Completed = 1,
  Approved = 2,
  Rejected = 3,
}
