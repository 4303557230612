import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  FeeType,
  ModuleName,
  Modules,
  NotificationTextMessage,
  RecurringType,
  RoutingPath,
  ScheduleSubPeriod,
  ScheduleType,
} from '@app/core/enums';
import {
  BulkJobModel,
  FeesTypeModel,
  JobModel,
  JobUserModel,
  SideListModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { GetAllClientList, GetAllTasks } from '@app/core/store';
import {
  GetAvailableUsers,
  GetFeesType,
  SaveBulkJob,
  SaveJob,
} from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-add-jobs',
  templateUrl: './add-jobs.component.html',
  styleUrls: ['./add-jobs.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AddJobsComponent implements OnInit {
  isExit: boolean;
  moduleId = Modules.Jobs;
  moduleName = ModuleName.Jobs;
  startTime = '00:00';
  endTime = '00:00';
  detailArray: any;
  timeSheetId: number;
  selectedUserId: string;
  totalDuration?: string = '00:00';
  timeSpent?: number = 0.0;
  totalHours = 0;
  totalMins = 0;
  isInvalidEndTime = false;
  displayedColumns: string[] = ['no', 'name', 'isBillable', 'isInclude'];
  tableDataSource: MatTableDataSource<AbstractControl>;
  closeMenu = false;

  clientList: SideListModel[];
  taskList: SideListModel[];
  userList: JobUserModel[];
  feesTypeList: FeesTypeModel[];
  jobForm: FormGroup;
  startDate = new Date();
  endDate = new Date();
  selectedClientId: any;
  selectedTaskId: any;
  selectedUserIds: Guid[] = [];
  feesTypeDetails: any;
  defaultSelected = 1;
  jobData: JobModel;
  defaultGuidValue = Guid.EMPTY as unknown as Guid;
  jobId = Guid.EMPTY as unknown as Guid;
  feeType = FeeType;
  selectedUserList: JobUserModel[];
  getModuleId: number;
  modules = Modules;
  clientId = Guid.EMPTY as unknown as Guid;
  taskId = Guid.EMPTY as unknown as Guid;

  @Output()
  readonly reloadSideList = new EventEmitter<any>();

  @Output()
  readonly toggleTopList = new EventEmitter<boolean>();

  store: Store;
  route: Router;
  formBuilder: FormBuilder;
  commonService: CommonService;
  spinner: NgxSpinnerService;
  datePipe: DatePipe;

  showRepeatjob = false;
  isAddBulkJob = false;
  bulkJobData: BulkJobModel;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  dropdownSettings: IDropdownSettings = {};
  selectedClientItems: any = [];
  selectedClientIds: Guid[] = [];
  isRecurringJob = false;

  weekList: string[] = [];
  monthList: string[] = [];
  dayList: any[] = [];
  recurringType = RecurringType;
  scheduleSubPeriod = ScheduleSubPeriod;
  scheduleType = ScheduleType;
  minEndDate: Date;
  minStartDate: Date;

  recurringTypeList: any[] = [
    {
      value: RecurringType.Never,
      name: 'Never',
    },
    {
      value: RecurringType.Daily,
      name: 'Daily',
    },
    {
      value: RecurringType.Weekly,
      name: 'Weekly',
    },
    {
      value: RecurringType.Monthly,
      name: 'Monthly',
    },
    {
      value: RecurringType.Yearly,
      name: 'Yearly',
    },
    {
      value: RecurringType.Custom,
      name: 'Custom',
    },
  ];

  scheduleSubPeriodList: any[] = [
    {
      value: ScheduleSubPeriod.Day,
      name: 'Day(s)',
    },
    {
      value: ScheduleSubPeriod.Week,
      name: 'Week(s)',
    },
    {
      value: ScheduleSubPeriod.Month,
      name: 'Month(s)',
    },
    {
      value: ScheduleSubPeriod.Year,
      name: 'Year(s)',
    },
  ];

  scheduleTypeList: any[] = [
    {
      value: ScheduleType.After,
      name: 'After',
    },
    {
      value: ScheduleType.On,
      name: 'On',
    },
    {
      value: ScheduleType.Never,
      name: 'Never',
    },
  ];

  constructor(
    public injector: Injector,
    public dialogRef: MatDialogRef<AddJobsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.endDate.setMonth(this.endDate.getMonth() + 1);
    this.store = this.injector.get<Store>(Store);
    this.route = this.injector.get<Router>(Router);
    this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datePipe = this.injector.get<DatePipe>(DatePipe);

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'universalId',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };

    this.minStartDate = new Date();
    this.minStartDate.setDate(this.minStartDate.getDate() + 1);
    this.minEndDate = new Date();
    this.minEndDate.setDate(this.minEndDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.getModuleId = this.data.moduleId;
    this.isAddBulkJob = this.data.isAddBulkJob;
    this.clientId = this.data.clientId ?? this.defaultGuidValue;
    this.taskId = this.data.taskId ?? this.defaultGuidValue;
    this.setForm();
    this.getClients();
    this.getTasks();
    this.bindMonthList();
    this.bindWeekList();
    this.bindDayList();
  }

  setForm(): void {
    this.jobForm = this.formBuilder.group({
      client: new FormControl<Guid | null>(null),
      task: new FormControl<Guid | null>(null),
      startDate: new FormControl<Date | null>(this.startDate),
      endDate: new FormControl<Date | null>(this.endDate),
      estimatedHours: new FormControl<number | null>(0),
      estimatedMinutes: new FormControl<number | null>(0),
      user: new FormControl<Guid | null>(null),
      feesType: new FormControl<number | null>(null),
      timeAndFeesPrice: new FormControl<number | null>(0),
      fixedPrice: new FormControl<number | null>(0),
      scheduleStartDate: new FormControl<Date | null>(
        new Date(Date.now() + 3600 * 1000 * 24)
      ),
      scheduleEndDate: new FormControl<Date | null>(new Date()),
      recurringType: new FormControl<number | null>(1),
      scheduleSubPeriod: new FormControl<number | null>(1),
      dayOfWeek: new FormControl<number | null>(1),
      dayOfMonth: new FormControl<number | null>(1),
      separationCount: new FormControl<number | null>(1),
      maxNumOfOccurrences: new FormControl<number | null>(1),
      monthOfYear: new FormControl<number | null>(1),
      scheduleType: new FormControl<number | null>(0),
    });
  }

  getTimeInSeconds(str) {
    let currentTime: any[] = [];

    currentTime = str.split(':');
    for (let i = 0; i < currentTime.length; i++) {
      currentTime[i] = parseInt(currentTime[i]);
    }

    let time = currentTime[0] * 60 * 60 + currentTime[1] * 60;

    return time;
  }

  convertSecToTime(time) {
    let hours = Math.floor(time / 3600);
    let hh = hours < 10 ? '0' + hours.toString() : hours.toString();
    let min = Math.floor((time % 3600) / 60);
    let mm = min < 10 ? '0' + min.toString() : min.toString();
    let ans = hh + ':' + mm;
    return ans;
  }

  onTimeChanged() {
    let t1 = this.getTimeInSeconds(this.startTime);
    let t2 = this.getTimeInSeconds(this.endTime);

    if (t2 < t1) {
      this.totalDuration = '';
      this.timeSpent = 0.0;
      this.isInvalidEndTime = true;
      return;
    } else {
      this.isInvalidEndTime = false;
    }

    let timeDifference = t1 - t2 < 0 ? t2 - t1 : t1 - t2;

    this.totalDuration = this.convertSecToTime(timeDifference);
    this.timeSpent = this.timeToDecimal(this.totalDuration);
  }

  timeToDecimal(time) {
    time = time.split(':');
    return Number(Number(Number(time[0]) + Number(time[1]) / 60).toFixed(2));
  }

  menuToggle(): void {
    this.closeMenu = !this.closeMenu;
    this.toggleTopList.emit(this.closeMenu);
  }

  getClients(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetAllClientList())
      .pipe(
        tap((res) => {
          this.clientList = res.client.allClient;

          if (this.clientId !== this.defaultGuidValue) {
            this.jobForm.controls.client.setValue(this.clientId);
            this.selectedClientId = this.clientId;
          } else {
            this.jobForm.controls.client.setValue(
              this.clientList[0].universalId
            );
            this.selectedClientId = this.clientList[0].universalId;
          }

          this.getAvailableUsers();
          this.getFeesType();
        })
      )
      .subscribe();
  }

  getTasks(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetAllTasks())
      .pipe(
        tap((res) => {
          this.taskList = res.task.allTasks;

          if (this.taskId !== this.defaultGuidValue) {
            this.jobForm.controls.task.setValue(this.taskId);
            this.selectedTaskId = this.taskId;
          } else {
            this.jobForm.controls.task.setValue(this.taskList[0].universalId);
            this.selectedTaskId = this.taskList[0].universalId;
          }

          this.getAvailableUsers();
          this.getFeesType();
        })
      )
      .subscribe();
  }

  onClientSelectionChange(val: any): void {
    this.selectedClientId = val;
    this.getAvailableUsers();
    this.getFeesType();
  }

  onTaskSelectionChange(val: any): void {
    this.selectedTaskId = val;
    this.getAvailableUsers();
    this.getFeesType();
  }

  getAvailableUsers(): void {
    if (this.selectedClientId && this.selectedTaskId) {
      this.store
        .dispatch(
          new GetAvailableUsers(
            this.selectedClientId,
            this.selectedTaskId,
            this.jobId
          )
        )
        .pipe(
          tap((res) => {
            this.userList = res.job.userList;
            this.jobForm.controls.user.setValue(this.userList[0].userId);

            this.selectedUserList = this.userList.filter((x) => x.isAssigned);

            this.selectedUserIds = this.userList
              .filter((x) => x.isAssigned === true)
              .map((x) => x.userId);
          })
        )
        .subscribe();
    }
  }

  getFeesType(): void {
    if (this.selectedClientId && this.selectedTaskId) {
      this.store
        .dispatch(
          new GetFeesType(
            this.selectedClientId,
            this.selectedTaskId,
            this.jobId
          )
        )
        .pipe(
          tap((res) => {
            this.feesTypeList = res.job.feesTypeList;
            this.jobForm.controls.feesType.setValue(
              this.feesTypeList[0].feeTypeId
            );
            this.defaultSelected = this.feesTypeList[0].feeTypeId!;

            this.feesTypeList.forEach((element) => {
              if (element.feeTypeId === FeeType.TimeAndFee) {
                this.jobForm.controls.timeAndFeesPrice.setValue(
                  element.feeAmount
                );
              } else if (element.feeTypeId === FeeType.FixedPrice) {
                this.jobForm.controls.fixedPrice.setValue(element.feeAmount);
              }
            });
            if (this.feesTypeList.some((x) => x.isSelected)) {
              const data = this.feesTypeList.find((x) => x.isSelected === true);
              this.defaultSelected = data?.feeTypeId!;
              this.jobForm.controls.feesType.setValue(data?.feeTypeId!);
              if (this.defaultSelected === FeeType.FixedPrice) {
                this.feesTypeDetails = data?.name + ' - £ ' + data?.feeAmount;
              } else {
                this.feesTypeDetails =
                  data?.name + ' - £ ' + data?.feeAmount + ' / Hours';
              }
            } else {
              this.jobForm.controls.feesType.setValue(
                this.feesTypeList[0].feeTypeId
              );
              this.defaultSelected = this.feesTypeList[0].feeTypeId!;
              if (this.defaultSelected === FeeType.FixedPrice) {
                this.feesTypeDetails =
                  this.feesTypeList[0].name +
                  ' - £ ' +
                  this.feesTypeList[0].feeAmount;
              } else {
                this.feesTypeDetails =
                  this.feesTypeList[0].name +
                  ' - £ ' +
                  this.feesTypeList[0].feeAmount +
                  ' / Hours';
              }
            }
          })
        )
        .subscribe();
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.jobData = {
        universalId: this.jobId,
        clientId: this.jobForm.controls.client.value,
        taskId: this.jobForm.controls.task.value,
        startDate: this.datePipe
          .transform(this.jobForm.controls.startDate.value, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datePipe
          .transform(this.jobForm.controls.endDate.value, 'yyyy-MM-dd')
          ?.toString(),
        estimatedHours: this.jobForm.controls.estimatedHours.value,
        estimatedMinutes: this.jobForm.controls.estimatedMinutes.value,
        feeTypeId: this.jobForm.controls.feesType.value,
        status: 0,
        jobSubtasks: [],
        users: this.selectedUserIds,
        timeAndFeesPrice: this.jobForm.controls.timeAndFeesPrice.value,
        fixedPrice: this.jobForm.controls.fixedPrice.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }
  isFormSubmited: boolean = false;

  onSave(isExit: boolean, isViewDetails: boolean): void {
    if (this.jobForm.invalid) {
      this.commonService.addValidation(this.jobForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else if (this.isAddBulkJob && this.selectedClientIds.length === 0) {
      this.isFormSubmited = true;
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.isAddBulkJob) {
        this.saveBulkJob(isExit, isViewDetails);
      } else {
        this.saveSingleJob(isExit, isViewDetails);
      }
    }
  }

  setClass() {
    if (this.isFormSubmited && this.selectedClientIds.length === 0) {
      return 'invalidField';
    } else {
      return 'validField';
    }
  }

  saveSingleJob(isExit, isViewDetails): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveJob(this.jobData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.jobId = res.job.jobId;
              this.onSuccesResponse(isExit, isViewDetails);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  dataSubmitForBulkJob(): boolean {
    this.spinner.show();

    try {
      this.bulkJobData = {
        universalId: this.jobId,
        clientIds: this.selectedClientIds,
        taskId: this.jobForm.controls.task.value,
        startDate: this.datePipe
          .transform(this.jobForm.controls.startDate.value, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datePipe
          .transform(this.jobForm.controls.endDate.value, 'yyyy-MM-dd')
          ?.toString(),
        estimatedHours: this.jobForm.controls.estimatedHours.value,
        estimatedMinutes: this.jobForm.controls.estimatedMinutes.value,
        feeTypeId: this.jobForm.controls.feesType.value,
        status: 0,
        jobSubtasks: [],
        users: this.selectedUserIds,
        timeAndFeesPrice: this.jobForm.controls.timeAndFeesPrice.value,
        fixedPrice: this.jobForm.controls.fixedPrice.value,
        isRecurringJob: this.isRecurringJob,
        createFirstJobOn:
          this.datePipe.transform(
            this.jobForm.controls.scheduleStartDate.value,
            'yyyy-MM-dd'
          ) ?? null,
        scheduleEndDate:
          this.jobForm.controls.scheduleType.value === ScheduleType.On
            ? this.datePipe.transform(
                this.jobForm.controls.scheduleEndDate.value,
                'yyyy-MM-dd'
              )
            : null,
        maxNumOfOccurrences:
          this.jobForm.controls.scheduleType.value === ScheduleType.After
            ? +this.jobForm.controls.maxNumOfOccurrences.value
            : 0,
        separationCount:
          this.jobForm.controls.recurringType.value === RecurringType.Custom
            ? +this.jobForm.controls.separationCount.value
            : 0,
        recurringTypeId: +this.jobForm.controls.recurringType.value,
        dayOfWeek: this.getDayOfWeek(),
        dayOfMonth: this.getDayOfMonth(),
        monthOfYear: this.getMonthOfYear(),
        endRecurringTypeId: this.jobForm.controls.scheduleType.value,
        subRecurringTypeId:
          this.jobForm.controls.recurringType.value === RecurringType.Custom
            ? this.jobForm.controls.scheduleSubPeriod.value
            : null,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  saveBulkJob(isExit, isViewDetails): void {
    if (this.dataSubmitForBulkJob()) {
      this.store
        .dispatch(new SaveBulkJob(this.bulkJobData))
        .pipe()
        .subscribe(
          (res) => {
            if (res !== undefined) {
              this.jobId = res.job.jobId;
              this.onSuccesResponse(isExit, isViewDetails);
            } else {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.commonService.onFailure(err.message);
          }
        );
    }
  }

  onSuccesResponse(isExit, isViewDetails): void {
    if (!isExit) {
      this.onCancel(false);
    } else {
      this.onCancel(true, isViewDetails);
    }
    this.commonService.onSuccess(NotificationTextMessage.successMessage);
  }

  onCancel(isCancelClick: boolean, isViewDetails?: boolean): void {
    if (isCancelClick && this.jobId !== (Guid.EMPTY as unknown as Guid)) {
      this.dialogRef.close(isViewDetails);
    } else {
      this.jobId = Guid.EMPTY as unknown as Guid;
      this.ngOnInit();
    }
  }

  setHighlightData(isExit: boolean): void {
    this.commonService.setHighlightData(
      this.jobId,
      isExit,
      Modules.Jobs,
      RoutingPath.addJob
    );
  }

  onFeeTypeChange(val): void {
    const data = this.feesTypeList.find((x) => x.feeTypeId === val.feeTypeId);
    this.defaultSelected = val.feeTypeId;
    if (this.defaultSelected === FeeType.FixedPrice) {
      this.feesTypeDetails = data?.name + ' - £ ' + this.getFeeAmount(val);
    } else {
      this.feesTypeDetails =
        data?.name + ' - £ ' + this.getFeeAmount(val) + ' / Hours';
    }
  }

  getFeeAmount(val): number {
    let feeAmount = 0;
    switch (val.feeTypeId) {
      case FeeType.TimeAndFee:
        feeAmount = this.jobForm.controls.timeAndFeesPrice.value;
        break;

      case FeeType.FixedPrice:
        feeAmount = this.jobForm.controls.fixedPrice.value;
        break;

      default:
        break;
    }
    return feeAmount;
  }

  onUserSelectionChange(): void {
    this.selectedUserList = [];
    this.userList.forEach((element) => {
      this.selectedUserIds.forEach((ele) => {
        if (element.userId === ele) {
          this.selectedUserList.push(element);
        }
      });
    });
  }

  onViewCalendarClick(): void {
    this.dialogRef.close();
    this.route.navigate([RoutingPath.calendar]);
  }

  onStartDateChange(): void {
    this.startDate = new Date(this.jobForm.controls.startDate.value);
    this.endDate = new Date(this.startDate);
    this.endDate.setMonth(this.startDate.getMonth() + 1);
    this.jobForm.controls.endDate.setValue(this.endDate);
  }

  onRepeatjobClick() {
    this.showRepeatjob = true;
  }

  getDayOfWeek() {
    return this.jobForm.controls.recurringType.value === RecurringType.Weekly ||
      (this.jobForm.controls.recurringType.value === RecurringType.Custom &&
        this.jobForm.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Week)
      ? +this.jobForm.controls.dayOfWeek.value
      : null;
  }

  getDayOfMonth() {
    return this.jobForm.controls.recurringType.value ===
      RecurringType.Monthly ||
      this.jobForm.controls.recurringType.value === RecurringType.Yearly ||
      (this.jobForm.controls.recurringType.value === RecurringType.Custom &&
        (this.jobForm.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Month ||
          this.jobForm.controls.scheduleSubPeriod.value ===
            ScheduleSubPeriod.Year))
      ? +this.jobForm.controls.dayOfMonth.value
      : null;
  }

  getMonthOfYear() {
    return this.jobForm.controls.recurringType.value === RecurringType.Yearly ||
      (this.jobForm.controls.recurringType.value === RecurringType.Custom &&
        this.jobForm.controls.scheduleSubPeriod.value ===
          ScheduleSubPeriod.Year)
      ? +this.jobForm.controls.monthOfYear.value
      : null;
  }

  onClientDeSelect(item: any) {
    this.selectedClientIds = [];
    this.selectedClientItems.forEach((element, i) => {
      if (element.universalId === item.universalId) {
        this.selectedClientItems.splice(i, 1);
      }
    });

    this.selectedClientItems?.forEach((element) => {
      this.selectedClientIds.push(element.universalId);
    });
  }

  onSelectAllClients(items: any) {
    this.selectedClientItems = items;

    this.selectedClientItems?.forEach((element) => {
      this.selectedClientIds.push(element.universalId);
    });
  }

  onDeSelectAllClients() {
    this.selectedClientItems = [];
    this.selectedClientIds = [];
  }

  onClientSelect(item: any) {
    this.selectedClientIds = [];
    this.selectedClientItems?.forEach((element) => {
      this.selectedClientIds.push(element.universalId);
    });
  }

  bindMonthList(): void {
    this.monthList = Array.from({ length: 12 }, (e, i) => {
      return new Date(0, i + 1, 0).toLocaleDateString('en', {
        month: 'long',
      });
    });
  }

  bindWeekList(): void {
    this.weekList = Array.from({ length: 7 }, (e, i) => {
      return new Date(0, 0, i).toLocaleDateString('en', {
        weekday: 'long',
      });
    });
  }

  bindDayList(): void {
    for (let i = 1; i <= 31; i++) {
      let label = '';
      if (i === 1 || i === 21 || i === 31) {
        label = i + 'st';
      } else if (i === 2 || i === 22) {
        label = i + 'nd';
      } else if (i === 3 || i === 23) {
        label = i + 'rd';
      } else if (i >= 4) {
        label = i + 'th';
      }

      const obj = {
        value: i,
        lable: label,
      };
      this.dayList.push(obj);
    }
    this.jobForm.controls.dayOfMonth.setValue(this.dayList[0].value);
  }

  setMinEndDate(): void {
    if (this.jobForm.controls.scheduleType.value === ScheduleType.On) {
      this.minEndDate = new Date(this.jobForm.controls.startDate.value);
      this.minEndDate.setDate(this.minEndDate.getDate() + 1);
      this.jobForm.controls.endDate.setValue(this.minEndDate);
    }
  }
}
