export class ProviderAccountModel {
  id: number;
  universalId: string;
  providerId: number;
  emailAddress: string;
  configurationJson: string;
  isActive: boolean;
  isDefault: boolean;
  createdBy: number;
  createdOn: string;
  updatedBy: number;
  updatedOn: string;
}
