import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  CommonNotificationText,
  ConfirmationType,
  FeeType,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  PermissionType,
  RoutingPath,
} from '@app/core/enums';
import {
  FeesTypeModel,
  JobDetailModel,
  JobModel,
  JobSubTaskModel,
  JobUserModel,
  MainListParameters,
  SideListModel,
} from '@app/core/models';
import { CommonService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/shared/components';
import { GetAllClientList, GetAllTasks } from '@app/core/store';
import {
  GetAvailableUsers,
  GetFeesType,
  JobState,
  SaveJob,
} from '@app/core/store/job';
import { Select, Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-job-details-subtask',
  templateUrl: './job-details-subtask.component.html',
  styleUrls: ['./job-details-subtask.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class JobDetailsSubtaskComponent implements OnInit {
  displayedColumns: string[] = [
    'no',
    'name',
    'isBillable',
    'isCompleted',
    'isInclude',
    'close',
  ];

  jobDetailArray: any;
  formDetails: UntypedFormGroup;
  tableDataSource: MatTableDataSource<AbstractControl>;
  commonNotificationText = CommonNotificationText;
  isAllBillableSelected = false;
  isAllCompleteSelected = false;
  isAllIncludeSelected = false;
  selectedSubtaskList: JobSubTaskModel[];
  jobForm: FormGroup;
  startDate = new Date();
  endDate = new Date();
  clientList: SideListModel[];
  taskList: SideListModel[];
  userList: JobUserModel[];
  feesTypeList: FeesTypeModel[];
  selectedClientId: any;
  selectedTaskId: any;
  selectedUserIds: Guid[] = [];
  selectedUserList: JobUserModel[];
  feesTypeDetails: any;
  defaultSelected = 1;
  feeType = FeeType;
  jobData: JobModel;
  jobDetailData: JobDetailModel;
  moduleId = Modules.JobDetail;
  jobId = Guid.EMPTY as unknown as Guid;
  jobDetailSubscription: Subscription;
  jobSubtaskDetailSubscription: Subscription;
  listParameters: MainListParameters = new MainListParameters();
  groupBy = 0;
  defaultGuid = Guid.EMPTY as unknown as Guid;
  selectedFeeTypeId: number;
  permissionType = PermissionType;
  isViewPermission = false;

  @Input()
  triggerJobId: Observable<any>;

  @Input()
  triggerJobSubtaskData: Observable<any>;

  triggerROIJobId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  triggerJobFeesType: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  @Output()
  readonly reloadJobDetails = new EventEmitter<any>();

  @Select(JobState.getJobDetailByUniversalId)
  jobData$: Observable<JobDetailModel>;

  constructor(
    private formBuilder: FormBuilder,
    private store: Store,
    private spinner: NgxSpinnerService,
    private route: Router,
    private commonService: CommonService,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {
    this.endDate.setDate(this.endDate.getDate() + 1);
  }

  ngOnInit(): void {
    this.setForm(false);
    this.getClients();
    this.getTasks();

    this.jobSubtaskDetailSubscription = this.triggerJobSubtaskData?.subscribe(
      (data) => {
        this.jobDetailData = data;
        this.editJobDetails(data);
        this.editJobSubtasks(data);
        this.triggerJobFeesType.next(this.jobDetailData.feeTypeId);
      }
    );

    this.jobDetailSubscription = this.triggerJobId.subscribe((data) => {
      this.jobId = data;
      this.triggerROIJobId.next(this.jobId);
    });

    this.isViewPermission = this.commonService.isViewPermission(this.moduleId);
  }

  ngOnDestroy(): void {
    this.jobDetailSubscription?.unsubscribe();
    this.jobSubtaskDetailSubscription?.unsubscribe();
  }

  setForm(addNewRow: boolean): void {
    this.jobForm = this.formBuilder.group({
      client: new FormControl<Guid | null>(null),
      task: new FormControl<Guid | null>(null),
      startDate: new FormControl<Date | null>(this.startDate),
      endDate: new FormControl<Date | null>(this.endDate),
      estimatedHours: new FormControl<number | null>(0),
      estimatedMinutes: new FormControl<number | null>(0),
      user: new FormControl<Guid | null>(null),
      feesType: new FormControl<number | null>(null),
      timeAndFeesPrice: new FormControl<number | null>(0),
      fixedPrice: new FormControl<number | null>(0),
    });

    this.formDetails = new FormGroup({
      jobDetailArray: new UntypedFormArray([]),
    });

    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;
    this.setDataSource(this.jobDetailArray);
    if (addNewRow) this.createRow();
  }

  editJobDetails(data): void {
    this.jobForm.patchValue({
      client: data.clientId,
      task: data.taskId,
      startDate: data.startDate,
      endDate: data.endDate,
      estimatedHours: data.estimatedHours,
      estimatedMinutes: data.estimatedMinutes,
      feesType: data.feeTypeId,
      timeAndFeesPrice: data.timeAndFeesPrice,
      fixedPrice: data.fixedPrice,
    });
    this.selectedClientId = data.clientId;
    this.selectedTaskId = data.taskId;
    this.selectedFeeTypeId = data.feeTypeId;

    this.setFeeTypeData(data.jobFees);

    this.getAvailableUsers();
  }

  editJobSubtasks(data): void {
    this.resetJobDetailForm();
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    for (let i = 0; i < this.jobDetailArray.length; i++) {
      this.jobDetailArray.removeAt(i);
    }
    if (this.jobDetailArray.length === 1) {
      this.jobDetailArray.removeAt(0);
    }

    data.jobSubTasks.forEach((item, i) => {
      this.jobDetailArray.push(this.buildOrderItemsForm(item));
    });

    this.setDataSource(this.jobDetailArray);

    this.isAllBillableSelected =
      this.jobDetailArray?.getRawValue().length > 0
        ? this.jobDetailArray
            ?.getRawValue()
            .every((item: any) => item.isBillable === true)
        : false;

    this.isAllCompleteSelected =
      this.jobDetailArray?.getRawValue().length > 0
        ? this.jobDetailArray
            ?.getRawValue()
            .every((item: any) => item.isCompleted === true)
        : false;

    this.isAllIncludeSelected =
      this.jobDetailArray?.getRawValue().length > 0
        ? this.jobDetailArray
            ?.getRawValue()
            .every((item: any) => item.isInclude === true)
        : false;
  }

  buildOrderItemsForm(item: any): FormGroup {
    return this.formBuilder.group({
      id: item.universalId,
      name: item.name,
      isBillable: item.isBillable,
      isCompleted: item.isCompleted,
      isInclude: item.isInclude,
    });
  }

  createRow(): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;
    this.jobDetailArray.push(this.createItem());

    this.setDataSource(this.jobDetailArray);
  }

  setDataSource(array: UntypedFormArray): void {
    this.tableDataSource = new MatTableDataSource(array.controls);
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl<Guid>(Guid.EMPTY as unknown as Guid),
      name: new FormControl<string | null>(''),
      isBillable: new FormControl<boolean | null>(false),
      isCompleted: new FormControl<boolean | null>(false),
      isInclude: new FormControl<boolean | null>(true),
    });
  }

  addNewRow(): void {
    for (let i = 0; i < 1; i++) {
      this.createRow();
    }

    this.isAllIncludeSelected = this.jobDetailArray
      ?.getRawValue()
      .every((item: any) => item.isInclude === true);
    this.jobDetailArray?.getRawValue().forEach((item, i) => {
      if (this.jobForm.controls.feesType.value !== FeeType.NonBillable) {
        this.jobDetailArray.controls[i]['controls'].isBillable.setValue(true);
      }
    });
    this.onSelectBillable();
  }

  onSelectBillable(): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.isAllBillableSelected = this.jobDetailArray
      ?.getRawValue()
      .every((item: any) => item.isBillable === true);
  }

  onSelectComplete(): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.isAllCompleteSelected = this.jobDetailArray
      ?.getRawValue()
      .every((item: any) => item.isCompleted === true);
  }

  onSelectInclude(): void {
    this.selectedSubtaskList = [];
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.isAllIncludeSelected = this.jobDetailArray
      ?.getRawValue()
      .every((item: any) => item.isInclude === true);

    this.jobDetailArray?.getRawValue().forEach((item, i) => {
      if (item.isInclude) {
        this.selectedSubtaskList.push(item);
      }
    });
  }

  onSelectAllBillable(event: any): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.jobDetailArray?.getRawValue().forEach((item, i) => {
      this.jobDetailArray.controls[i]['controls'].isBillable.setValue(false);
      item.isBillable = event.checked;
      this.jobDetailArray.controls[i]['controls'].isBillable.setValue(
        event.checked
      );
    });
    this.jobDetailArray.markAsDirty();
  }

  onSelectAllComplete(event: any): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.jobDetailArray?.getRawValue().forEach((item, i) => {
      this.jobDetailArray.controls[i]['controls'].isCompleted.setValue(false);
      item.isCompleted = event.checked;
      this.jobDetailArray.controls[i]['controls'].isCompleted.setValue(
        event.checked
      );
    });
    this.jobDetailArray.markAsDirty();
  }

  onSelectAllInclude(event: any): void {
    this.selectedSubtaskList = [];
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;

    this.jobDetailArray?.getRawValue().forEach((item, i) => {
      this.jobDetailArray.controls[i]['controls'].isInclude.setValue(false);
      item.isInclude = event.checked;
      this.jobDetailArray.controls[i]['controls'].isInclude.setValue(
        event.checked
      );

      if (item.isInclude) {
        this.selectedSubtaskList.push(item);
      }
    });
    this.jobDetailArray.markAsDirty();
  }

  getClients(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetAllClientList())
      .pipe(
        tap((res) => {
          this.clientList = res.client.allClient;
        })
      )
      .subscribe();
  }

  getTasks(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetAllTasks())
      .pipe(
        tap((res) => {
          this.taskList = res.task.allTasks;
        })
      )
      .subscribe();
  }

  onClientSelectionChange(val: any): void {
    this.selectedClientId = val;
    this.getAvailableUsers();
    this.getFeesType();
  }

  onTaskSelectionChange(val: any): void {
    this.selectedTaskId = val;
    this.getAvailableUsers();
    this.getFeesType();
  }

  getAvailableUsers(): void {
    if (this.selectedClientId && this.selectedTaskId) {
      this.store
        .dispatch(
          new GetAvailableUsers(
            this.selectedClientId,
            this.selectedTaskId,
            this.jobId
          )
        )
        .pipe(
          tap((res) => {
            this.userList = res.job.userList;
            this.jobForm.controls.user.setValue(this.userList[0].userId);

            this.selectedUserList = this.userList.filter((x) => x.isAssigned);

            this.selectedUserIds = this.userList
              .filter((x) => x.isAssigned === true)
              .map((x) => x.userId);
          })
        )
        .subscribe();
    }
  }

  getFeesType(): void {
    if (this.selectedClientId && this.selectedTaskId) {
      this.store
        .dispatch(
          new GetFeesType(
            this.selectedClientId,
            this.selectedTaskId,
            this.defaultGuid
          )
        )
        .pipe(
          tap((res) => {
            this.setFeeTypeData(res.job.feesTypeList);
          })
        )
        .subscribe();
    }
  }

  onFeeTypeChange(val): void {
    const data = this.feesTypeList.find((x) => x.feeTypeId === val.feeTypeId);
    this.defaultSelected = val.feeTypeId;
    if (this.defaultSelected === FeeType.FixedPrice) {
      this.feesTypeDetails = data?.name + ' - £ ' + this.getFeeAmount(val);
    } else {
      this.feesTypeDetails =
        data?.name + ' - £ ' + this.getFeeAmount(val) + ' / Hours';
    }
  }

  getFeeAmount(val): number {
    let feeAmount = 0;
    switch (val.feeTypeId) {
      case FeeType.TimeAndFee:
        feeAmount = this.jobForm.controls.timeAndFeesPrice.value;
        break;

      case FeeType.FixedPrice:
        feeAmount = this.jobForm.controls.fixedPrice.value;
        break;

      default:
        break;
    }
    return feeAmount;
  }

  onUserSelectionChange(): void {
    this.jobForm.markAsDirty();
    this.selectedUserList = [];
    this.userList.forEach((element) => {
      this.selectedUserIds.forEach((ele) => {
        if (element.userId === ele) {
          this.selectedUserList.push(element);
        }
      });
    });
  }

  onViewCalendarClick(): void {
    this.route.navigate([RoutingPath.calendar]);
  }

  onCancel(isCancelClick): void {
    if (isCancelClick) {
      this.ngOnInit();
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      const subTaskData = new Array<JobSubTaskModel>();
      this.jobDetailArray?.getRawValue().forEach((x) => {
        if (x.name !== '') {
          subTaskData.push({
            universalId: x.id,
            name: x.name,
            isBillable: x.isBillable,
            isCompleted: x.isCompleted,
            isInclude: x.isInclude,
          });
        }
      });

      this.jobData = {
        universalId: this.jobId,
        clientId: this.jobForm.controls.client.value,
        taskId: this.jobForm.controls.task.value,
        startDate: this.datePipe
          .transform(this.jobForm.controls.startDate.value, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datePipe
          .transform(this.jobForm.controls.endDate.value, 'yyyy-MM-dd')
          ?.toString(),
        estimatedHours: this.jobForm.controls.estimatedHours.value,
        estimatedMinutes: this.jobForm.controls.estimatedMinutes.value,
        feeTypeId: this.jobForm.controls.feesType.value,
        timeAndFeesPrice: this.jobForm.controls.timeAndFeesPrice.value,
        fixedPrice: this.jobForm.controls.fixedPrice.value,
        status: 0,
        jobSubtasks: subTaskData,
        users: this.selectedUserIds,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  onSave(isExit): void {
    if (this.jobForm.invalid) {
      this.commonService.addValidation(this.jobForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit()) {
        if (this.selectedFeeTypeId !== this.jobForm.controls.feesType.value) {
          this.spinner.hide();
          this.confirmationDialogOpen(isExit);
        } else {
          this.saveJob(isExit);
        }
      }
    }
  }

  confirmationDialogOpen(isExit: boolean): void {
    const noteText =
      this.jobForm.controls.feesType.value === FeeType.NonBillable
        ? NotificationTextMessage.nonBillableMessage
        : NotificationTextMessage.billableMessage;
    this.dialog
      .open(ConfirmationBoxComponent, {
        data: {
          jobId: this.jobId,
          type: ConfirmationType.SaveFeeType,
          moduleName: ModuleName.EndSchedule,
          headerText: NotificationHeader.saveFeesType,
          detailText: NotificationDetails.saveFeesType,
          noteText: noteText,
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.saveJob(isExit);
        }
      });
  }

  saveJob(isExit: boolean): void {
    this.store
      .dispatch(new SaveJob(this.jobData))
      .pipe()
      .subscribe(
        (res) => {
          if (res !== undefined) {
            this.jobId = res.job.jobId;
            this.onSuccesResponse(isExit);
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        },
        (err) => {
          this.commonService.onFailure(err.message);
        }
      );
  }

  onSuccesResponse(isExit: boolean): void {
    if (!isExit) {
      this.setHighlightData(isExit);
      this.onCancel(true);

      this.reloadJobDetails.emit(true);
    } else {
      this.setHighlightData(isExit);
      this.onCancel(false);
      this.commonService.onListRouting(Modules.Jobs);
    }
    this.commonService.onSuccess(NotificationTextMessage.successMessage);
  }

  setHighlightData(isExit: boolean = false): void {
    this.commonService.setHighlightData(
      this.jobId,
      isExit,
      Modules.Jobs,
      RoutingPath.addJob
    );
  }

  setFeeTypeData(res: any): void {
    this.feesTypeList = res;

    this.feesTypeList.forEach((element) => {
      if (element.feeTypeId === FeeType.TimeAndFee) {
        this.jobForm.controls.timeAndFeesPrice.setValue(element.feeAmount);
      } else if (element.feeTypeId === FeeType.FixedPrice) {
        this.jobForm.controls.fixedPrice.setValue(element.feeAmount);
      }
    });

    if (this.feesTypeList.some((x) => x.isSelected)) {
      const data = this.feesTypeList.find((x) => x.isSelected === true);
      this.jobForm.controls.feesType.setValue(data?.feeTypeId);
      this.defaultSelected = data?.feeTypeId!;
      if (this.defaultSelected === FeeType.FixedPrice) {
        this.feesTypeDetails = data?.name + ' - £ ' + data?.feeAmount;
      } else {
        this.feesTypeDetails =
          data?.name + ' - £ ' + data?.feeAmount + ' / Hours';
      }
    } else {
      this.jobForm.controls.feesType.setValue(this.feesTypeList[0].feeTypeId);
      this.defaultSelected = this.feesTypeList[0].feeTypeId!;
      if (this.defaultSelected === FeeType.FixedPrice) {
        this.feesTypeDetails =
          this.feesTypeList[0].name + ' - £ ' + this.feesTypeList[0].feeAmount;
      } else {
        this.feesTypeDetails =
          this.feesTypeList[0].name +
          ' - £ ' +
          this.feesTypeList[0].feeAmount +
          ' / Hours';
      }
    }
  }

  onDeleteSubtask(index: number): void {
    this.jobDetailArray = this.formDetails.get(
      'jobDetailArray'
    ) as UntypedFormArray;
    this.jobDetailArray.removeAt(index);
    this.setDataSource(this.jobDetailArray);
  }

  resetJobDetailForm(): void {
    const frmArray = this.formDetails.get('jobDetailArray') as UntypedFormArray;
    frmArray.clear();
  }
}
