import { ProjectDetail } from './project-detail';

export class ProjectClient {
  id: number;
  headerName: string;
  expanded: boolean;
  projects: Array<ProjectDetail>;
  isSelected?: boolean;
}

export class ProjectList {
  universalId: string;
  name: string;
  projectTypeId: number;
  projectTypeName?: string;
  hourlyRateTypeId: number;
  startDate?: string;
  endDate?: string;
  clientId?: string;
  clientName?: string;
  clientLogo?: string;
  createdById?: number;
  createdByName?: string;
  createdByPhoto?: string;
  isArchived?: boolean;
  isDeleted?: boolean;
  users?: Array<string>;
  isSelected?: boolean;
}
