export class RetrieveSubscription {
  static readonly type = '[SUBSCRIPTION] Retrieve Subscription';
}

export class GetClientLimit {
  static readonly type = '[SUBSCRIPTION] Get Client Limit';
}

export class GetModuleRedirectionURL {
  static readonly type = '[SUBSCRIPTION] Get Module Redirection URL';

  constructor(
    public moduleId: number,
    public userName: string,
    public externalUserGuid: string
  ) {}
}
