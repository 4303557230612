import { Guid } from 'guid-typescript';
import { ListModel } from '../common';
import { ActionModel } from '../common/grid-action';

export class ClientGridModel {
  totalItemCount: number;
  data: Array<Client>;
  actions: Array<ActionModel>;
}

export class Client {
  universalId?: Guid;
  fullName: string;
  isArchived?: boolean;
  clientTypeId: number;
  companyNumber: string;
  vatnumber: string;
  title: string;
  languageId?: number;
  paymentDueTermsId?: number;
  prefferedCurrencyId?: number;
  totalRecords?: number;
  //isSelected?: boolean;
  isSelected?: boolean = false;
  logo?: string;
  contacts: Array<Contacts>;
  clientCode?: string;
  contactName?: string;
  email?: string;
  phone?: string;
  addToAllFutureTasks: boolean;
  addToAllExistingTasks: boolean;
  addToAllFutureUsers: boolean;
  addToAllExistingUsers: boolean;
  addresses: Array<AddressModel>;
  businessStartDate?: string;
  bookStartDate?: string;
  yearEndMonth?: number;
  yearEndDate?: number;
  utrNumber?: string;
  vatSchemeId?: number | null;
  vatRegNumber?: string;
  vatRegistrationDate?: string;
  vatSubmitTypeId?: number | null;
  accountOfficeRefNumber?: string;
  payeRefNumber?: string;
  assignAsDirector?: string;
  isShowVat?: boolean;
  nationalInsuranceNo?: string;
  dateOfBirth?: string;
}

export class ClientUserClientModel {
  userId: Guid;
  isDisbaled: boolean;
  isAssigned: boolean;
  fullName: string;
  costRate: number;
  billable: number;
  isAdminOrOwner: boolean;
  feeTypeId: number;
  feeAmount: number;
  timeAndFeesPrice: number;
  fixedPrice: number;
  subTasks: Array<ListModel>;
}

export class Contacts {
  universalId: string;
  name: string;
  email: string;
  phone: string;
  title: string;
  countryId?: number;
  isPrimary: boolean;
}

export class CompanyModel {
  name: string;
  companyNumber: string;
  address: string;
  city: string;
  county: string;
  country: string;
  countryId: number;
  postalCode: string;
}

export class AddressModel {
  universalId: Guid;
  clientId: Guid;
  addressType: string;
  address: string;
  city: string;
  county: string;
  countryId: number;
  postalCode: string;
}
