<div class="sidenav-body-content">
  <div class="text-align-center mt-4 pt-4">
    <img src="assets/images/denied-icon.svg" class="mr-1" alt="Access Denied" />
    <h2 class="font-size-20 text-gray fw-bold mb-5 mt-1">
      Access denied for the page you are looking for.
    </h2>
    <p class="font-size-14 text-gray">
      You don’t have authorization to view this page. HTTP ERROR 403
    </p>
  </div>
</div>
