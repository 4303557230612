import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { SendEmailExpenseModel } from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { SendExpenseEmail } from '@app/core/store';
import { environment } from '@environments/environment';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  headerText = NotificationHeader.sendEmail;
  moduleId: Modules;
  emailForm: FormGroup;
  emailData: any;
  isButtonDisabled = false;
  sendEmailExpenseModel: SendEmailExpenseModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private notifier: NotificationService,
    private store: Store,
    public dialogRef: MatDialogRef<EmailComponent>
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.moduleId = this.data.moduleId;
    this.emailData = this.data.emailData;
  }

  setForm(): void {
    this.emailForm = this.formBuilder.group({
      message: new FormControl<string | null>('', Validators.required),
    });
  }

  getParams(): any {
    this.emailData.forEach((x) => {
      this.sendEmailExpenseModel = {
        expenseIds: x.expenseIds,
        comment: this.emailForm.controls.message.value,
        uRL: environment.uiUrl + 'expenses/expenseList',
      };
    });

    return this.sendEmailExpenseModel;
  }

  onSendClick(): void {
    if (this.emailForm.invalid) {
      this.commonService.addValidation(this.emailForm);
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.validationMessage
      );
    } else {
      if (this.moduleId === Modules.Expenses) {
        if (this.emailData && this.emailData.length > 0) {
          this.store.dispatch(new SendExpenseEmail(this.getParams())).subscribe(
            (data: any) => {
              this.commonService.onSuccess(NotificationTextMessage.emailSent);
              this.dialogRef.close(NotificationHeader.success);
            },
            (error: any) => {
              this.commonService.onFailure(
                NotificationTextMessage.errorMessage
              );
              this.dialogRef.close(NotificationHeader.error);
            }
          );
        }
      }
    }
  }
}
