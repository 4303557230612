import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { CommonNotificationText, InvoiceFilteredStatus } from '@app/core/enums';
import {
  InvoiceParameters,
  JobInvoiceModel,
  MainListParameters,
} from '@app/core/models';
import { GetJobInvoicesByJobId } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-job-details-invoice',
  templateUrl: './job-details-invoice.component.html',
  styleUrls: ['./job-details-invoice.component.scss'],
})
export class JobDetailsInvoiceComponent implements OnInit {
  commonNotificationText = CommonNotificationText;
  displayedColumns: string[] = [
    'id',
    'date',
    'amount',
    'lastReceivedOn',
    'status',
  ];
  listParameters: MainListParameters = new MainListParameters();
  jobInvoiceList: JobInvoiceModel[];
  jobId: any;

  @Input()
  triggerJobInvoiceData: Observable<any>;

  statusFilter = InvoiceFilteredStatus;
  jobInvoiceSubscription: Subscription;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.jobInvoiceSubscription = this.triggerJobInvoiceData.subscribe(
      (data) => {
        if (data) {
          this.jobId = data;
          this.getList();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.jobInvoiceSubscription?.unsubscribe();
  }

  getParamter(): any {
    const queryParams: InvoiceParameters = {
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      filter: 0,
    };

    return queryParams;
  }

  getList(): void {
    this.store
      .dispatch(new GetJobInvoicesByJobId(this.jobId, this.getParamter()))
      .subscribe((res) => {
        this.jobInvoiceList = res.job.jobInvoiceList;
      });
  }

  sorting(sortBy: string, sortOrder: string): void {
    if (sortOrder) {
      this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
      this.listParameters.sortBy = sortBy;
    } else {
      this.listParameters.sortOrder = true;
      this.listParameters.sortBy = '';
    }
    this.getList();
  }
}
