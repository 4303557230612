import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CommonNotificationText } from '@app/core/enums';
import { TicketStatus } from '@app/core/enums/ticket';
import { GetAllSupportTickets } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';
import { TicketDialogComponent } from '../common/ticket-dialog/ticket-dialog.component';

@Component({
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.scss'],
})
export class SupportTicketComponent implements OnInit {
  externalUserGUID = this.cookieService.get(
    'externalUserGUID'
  ) as unknown as Guid;
  supportTicketList: any[];
  ticketStatus = TicketStatus;
  selectedStatus = this.ticketStatus.Open;
  commonNotificationText = CommonNotificationText;
  dataSource: MatTableDataSource<any>;

  displayedColumns: string[] = [
    'id',
    'subject',
    'createdOn',
    'status',
    'groupName',
  ];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private store: Store,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.store
      .dispatch(new GetAllSupportTickets(this.externalUserGUID))
      .subscribe((res) => {
        this.supportTicketList = res.ticket.supportTicketList;
        this.dataSource = new MatTableDataSource(this.supportTicketList);
        this.dataSource.sort = this.sort;

        this.onSelectionChange();
      });
  }

  openDialog() {
    const dialogRef = this.dialog.open(TicketDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.getList();
      }
    });
  }

  onSearch(event: any): void {
    const searchText = event.target.value;

    this.dataSource.filter = searchText.trim().toLowerCase();
  }

  onSelectionChange(): void {
    this.dataSource = new MatTableDataSource(this.supportTicketList);

    this.dataSource.data = this.dataSource.data.filter(
      (element) => element.status === this.selectedStatus
    );
  }
}
