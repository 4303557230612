import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'app-textarea-highlight',
  templateUrl: './textarea-highlight.component.html',
  styleUrls: ['./textarea-highlight.component.scss'],
})
export class TextareaHighlightComponent implements OnInit {
  @Input() inputNum: number;
  @Input() subjectText: string = '';

  @Input()
  triggerGetTextFieldData: any;

  @Output() triggerTemplateIsDirty: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Output() triggerUpdatedData: EventEmitter<any> = new EventEmitter<any>();

  message = '';
  highlightTexts: any;
  commonElement: any = [];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.addSuggestedLabel(this.triggerGetTextFieldData.suggestedLabels);
    this.applyHighlights(this.subjectText);
  }

  addSuggestedLabel(suggestedLabels: any): void {
    this.highlightTexts = Object.entries(suggestedLabels).map(([k, v]) => v);
  }

  onMessageChange(text): any {
    this.applyHighlights(text.innerHTML);
    this.triggerUpdatedData.emit(text.innerHTML);
  }

  onInputChange(): any {
    this.triggerTemplateIsDirty.emit(true);
  }

  applyHighlights(text): any {
    text = text.replace(/\s+/g, ' ').trim();
    const removemark = ['<mark>', '</mark>'];
    removemark.forEach((element) => {
      if (text.includes(element)) {
        text = text.replace(new RegExp(element, 'g'), '');
      }
    });

    this.highlightTexts.forEach((element) => {
      const strRegExp = new RegExp(element, 'g');
      text = text.replace(strRegExp, `<mark>${element}</mark>`);
    });

    this.message = text;
    return text;
  }

  onMentionSelect(selection: any): string {
    return ' ' + selection.name + ' ';
  }
}
