import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  AttachmentType,
  CommonNotificationText,
  Modules,
  NotificationTextMessage,
  PermissionType,
} from '@app/core/enums';
import {
  EmailModel,
  GlobalComponent,
  JobEmailModel,
  MultiFileDownloadRequest,
} from '@app/core/models';
import {
  FileUploadRequestModel,
  FileUploadResponseModel,
} from '@app/core/models/file';
import { CommonService } from '@app/core/services';
import { UploadFileAttachements } from '@app/core/store';
import { GetJobEmailList, SendJobEmail } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-job-details-email',
  templateUrl: './job-details-email.component.html',
  styleUrls: ['./job-details-email.component.scss'],
})
export class JobDetailsEmailComponent implements OnInit {
  acceptedFileTypes = [
    '.jpg',
    '.png',
    '.xlsx',
    '.csv',
    '.pdf',
    '.jpeg',
    '.docx',
    '.gif',
  ];
  selectedFiles: FileList;
  fileUploadRequest: FileUploadRequestModel;
  fileUploadResponse: Array<FileUploadResponseModel>;
  fileList: any[] = [];
  displayedColumns: string[] = [
    'senton',
    'sentby',
    'sentto',
    'subject',
    'message',
    'attachment',
  ];

  displayedDetailColumns: string[] = ['name'];
  emailForm: FormGroup;
  emailData: EmailModel;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  jobId = this.defaultUniversalId;
  emailDetailList: JobEmailModel[] = [];
  jobEmailSubscription: Subscription;
  commonNotificationText = CommonNotificationText;
  permissionType = PermissionType;
  isViewPermission = false;
  moduleId = Modules.JobEmail;

  @Input()
  triggerJobId: Observable<any>;

  @Output()
  readonly triggerEmailCount = new EventEmitter<any>();

  constructor(
    private globalComponent: GlobalComponent,
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setForm();

    this.jobEmailSubscription = this.triggerJobId.subscribe((data) => {
      this.jobId = data;
      this.getJobEmailList();
    });

    this.isViewPermission = this.commonService.isViewPermission(this.moduleId);
  }

  ngOnDestroy(): void {
    this.jobEmailSubscription?.unsubscribe();
  }

  cancelClick(): void {
    this.ngOnInit();
    this.fileList = [];
    this.fileUploadResponse = [];
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.emailData = {
        sentTo: this.emailForm.controls.sentTo.value.split(','),
        subject: this.emailForm.controls.subject.value,
        message: this.emailForm.controls.message.value,
        attachments: this.fileUploadResponse,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getJobEmailList(): void {
    this.store
      .dispatch(new GetJobEmailList(this.jobId))
      .pipe(
        tap((res) => {
          this.emailDetailList = res.job.emailDetailList;
          this.triggerEmailCount.emit(
            this.emailDetailList ? this.emailDetailList.length : 0
          );
        })
      )
      .subscribe();
  }

  sendEmail(): void {
    if (this.emailForm.invalid) {
      this.commonService.addValidation(this.emailForm);
      if (
        this.emailForm.controls.sentTo.value !== '' &&
        this.emailForm.controls.sentTo.invalid
      ) {
        this.commonService.onFailure(NotificationTextMessage.invalidEmailIds);
      } else {
        this.commonService.onFailure(NotificationTextMessage.validationMessage);
      }
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new SendJobEmail(this.jobId, this.emailData))
          .pipe()
          .subscribe(
            (res) => {
              if (res.job.isEmailSend) {
                this.getJobEmailList();
                this.cancelClick();
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setForm(): void {
    this.emailForm = this.formBuilder.group({
      sentTo: new FormControl<string>('', [
        Validators.required,
        this.validateCommaSeperatedEmail,
      ]),
      subject: new FormControl<string>('', Validators.required),
      message: new FormControl<string>('', Validators.required),
      files: new FormControl<string | null>(''),
    });
  }

  validateCommaSeperatedEmail = (
    control: AbstractControl
  ): { [key: string]: any } | null => {
    const emails = control.value.split(',').map((e) => e.trim());
    const forbidden = emails.some((sentTo) =>
      Validators.email(new FormControl(sentTo))
    );
    return forbidden ? { toAddress: { value: control.value } } : null;
  };

  selectFiles(event: any): void {
    if (event) {
      this.selectedFiles = event.target.files;

      Array.prototype.forEach.call(this.selectedFiles, (file) => {
        this.fileList.push(file);
      });

      if (this.fileList.every((element) => element.size / 1024 / 1024 < 5)) {
        this.saveFile();
      } else {
        this.fileList = [];
        this.commonService.onFailure(NotificationTextMessage.fileSize5mb);
      }
    }
  }

  removeFile(index: any): void {
    this.fileUploadResponse.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  saveFile(): void {
    this.spinner.show();
    this.fileUploadRequest = {
      userId: this.globalComponent.getLoggedInUserId(),
      file: this.fileList,
      attachmentType: AttachmentType.Project,
    };

    this.store
      .dispatch(new UploadFileAttachements(this.fileUploadRequest))
      .pipe(
        tap((data) => {
          this.fileUploadResponse = data.common.fileUploadRequestModel;
        })
      )
      .subscribe();
  }

  downloadFile(fileUrls: string[]): void {
    if (fileUrls.length > 1) {
      let params: MultiFileDownloadRequest = {
        fileUrls: fileUrls,
      };
      this.commonService.multipleFileDownload(params).subscribe();
    } else {
      const params = {
        fileUrl: fileUrls[0],
      };

      this.commonService.downloadFile(params).subscribe();
    }
  }
}
