import { Guid } from 'guid-typescript';

export class FileUploadResponseModel {
  isSuccess?: boolean;
  fileUrl: string;
  fileName: string;
  fileSize: string;
}

export class FileUploadRequestModel {
  userId?: Guid;
  universalId?: string;
  file: Array<File>;
  attachmentType: number;
}
