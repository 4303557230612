<div
  class="sidenav-body-content"
  [ngClass]="{
    'mb-4': filesForm.dirty,
    'is-readonly': isViewPermission
  }"
>
  <form [formGroup]="filesForm">
    <div class="form-field attached mb-2">
      <div class="attachFile" [ngClass]="isImageSelected ? 'hidden' : ''">
        <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        <input
          #fileupload
          multiple
          type="file"
          [accept]="acceptedFileTypes"
          formControlName="fileName"
          autocomplete="off"
          maxlength="100"
          id="file"
          (change)="selectFiles($event)"
          class="cursor-pointer"
        />
        <label for="file" class="cursor-pointer">
          <strong>Choose a file</strong> or drag it here to attach a file with
          Job.<br />
          (Max file size - 2 MB)
        </label>
      </div>
    </div>
    <div class="note-table">
      <div [ngClass]="fileList.length === 0 ? '' : 'table-border'">
        <div
          class="clickable d-flex align-items-center justify-space-between"
          *ngFor="let file of fileList; let i = index"
        >
          <div>{{ file.name }}</div>
          <div class="close">
            <a href="javascript:void(0)" (click)="removeFile(i)">
              <mat-icon>close</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body mb-1 mt-1">
      <div class="form-field">
        <p>History</p>
      </div>
      <div class="sidenav-table sidenav-table-input">
        <div class="mat-table-wrapper contact-detail-table w-636">
          <table
            mat-table
            [dataSource]="dataSource"
            aria-describedby="Job Files Table"
          >
            <ng-container matColumnDef="fileName">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <span class="onelineclamp" title="{{ element.fileName }}">{{
                    element.fileName
                  }}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdOn">
              <th mat-header-cell *matHeaderCellDef>Uploaded on</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <span class="onelineclamp" title="{{ element.createdOn }}">{{
                    element.createdOn | date : "dd-LLL-yyyy"
                  }}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
              <th mat-header-cell *matHeaderCellDef>Uploaded by</th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <span class="onelineclamp" title="{{ element.createdBy }}">{{
                    element.createdBy
                  }}</span>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef class="w-50"></th>
              <td mat-cell *matCellDef="let element">
                <div class="d-flex align-items-center">
                  <a (click)="downloadFile(element.fileUrl)">
                    <mat-icon fontSet="material-icons-outlined"
                      >file_download</mat-icon
                    >
                  </a>
                </div>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td
                class="mat-cell"
                [ngClass]="'text-align-center'"
                [attr.colspan]="displayedColumns.length"
              >
                {{ commonNotificationText.NoRecordsFound }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
<div
  class="action-wrapper custom-action-button"
  *ngIf="filesForm.dirty && !isViewPermission"
>
  <div class="sidenav-body-content">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel()"
    >
      Cancel
    </button>
  </div>
</div>
