export enum ReportType {
  TimeReport = 0, //consolidate
  TimeReportByClient = 1,
  TimeReportByProject = 2,
  TimeReportByTask = 3,
  InvoiceReport = 4,
  InvoiceReportByInvoice = 5,
  ExpenseReport = 6,
  TeammatesReport = 7,
}
