import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  MatDateRangeSelectionStrategy,
} from '@angular/material/datepicker';

@Injectable()
export class MaxRangeSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  start: any;
  constructor(
    @Inject('rangeMax') private delta: number,
    private dateAdapter: DateAdapter<D>
  ) {}

  selectionFinished(date: D, currentRange: DateRange<D>): any {
    let { start, end } = currentRange;
    if (start == null || (start && end)) {
      start = date;
      end = null;
    } else if (end == null) {
      const maxDate = this.dateAdapter.addCalendarDays(start, this.delta);
      end = this.getRangeEnd(date, maxDate);
    }

    return new DateRange<D>(start, end);
  }

  getRangeEnd(activeDate, maxDate) {
    if (activeDate) {
      if (activeDate > maxDate) {
        return maxDate;
      } else {
        return activeDate;
      }
    } else {
      return null;
    }
  }

  createPreview(
    activeDate: D | null,
    currentRange: DateRange<D>
  ): DateRange<D> {
    if (currentRange.start && !currentRange.end) {
      const maxDate = this.dateAdapter.addCalendarDays(
        currentRange.start,
        this.delta
      );
      const rangeEnd = this.getRangeEnd(activeDate, maxDate);

      return new DateRange(currentRange.start, rangeEnd);
    }

    return new DateRange<D>(null, null);
  }
}
