export class UserProjectModel {
  userId: string;
  fullName: string;
  projectId: number;
  isManager: boolean;
  isAssign: boolean;
  budgetPerMonth: number;
  hourlyRate: string;
  hours: number;
}
