export enum AuthorizedRole {
  Owner = 1,
  Admin = 2,
  Manager = 3,
  User = 4,
}

export enum AuthorizedRoleName {
  Owner = 'Owner',
  Admin = 'Administrator',
  Manager = 'Manager',
  User = 'Regular User',
}
