import { Guid } from 'guid-typescript';
import { SideListModel } from '../common';
import { ActionModel } from '../common/grid-action';

export class TaskGridModel {
  totalItemCount: number;
  data: Array<TaskModel>;
  actions: Array<ActionModel>;
}

export class TaskModel {
  universalId?: Guid;
  name?: string;
  estimatedTime: number;
  defaultFeeTypeId?: number;
  timeAndFeesPrice?: number;
  fixedPrice?: number;
  nonBillablePrice?: number;
  isDemoData?: boolean;
  isSelected?: boolean;
  isArchived?: boolean;
  subTasks?: Array<SideListModel>;
  taskFees?: Array<any>;
  addToAllFutureClients: boolean;
  addToAllExistingClients: boolean;
  addToAllFutureUsers: boolean;
  addToAllExistingUsers: boolean;
}

export class TaskUserClientModel {
  userId?: Guid;
  isDisbaled: boolean;
  isAssigned?: boolean;
  fullName: string;
  costRate: number;
  billableRate: number;
  isAdminOrOwner: boolean;
  feeTypeId: number;
  feeAmount: number;
}

export class SubTaskModel {
  universalId: Guid;
  name?: string;
  taskId?: Guid;
  isBillable: boolean;
  isInclude: boolean;
}

//-------------------

export class TaskProjectModel {
  universalId: string;
  name: string;
  isBillable?: boolean;
  budgetPerMonth?: string;
  hourlyRate?: number;
  isProjectTask?: number;
  isAssignedToThisProject?: boolean;
}

export class TaskModelByClient {
  taskId: Guid;
  name: string;
  feeTypeId: number;
  timeAndFeesPrice: number;
  fixedPrice: number;
  isAssigned: boolean;
}
