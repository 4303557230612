export enum Modules {
  Standard = 0,
  Dashboard = 1,
  Time = 2,
  Jobs = 3,
  Fees = 4,
  Expenses = 5,
  Report = 6,
  Manage = 7,
  Setting = 8,
  Activity = 9,
  Announcement = 10,
  Live = 11,
  DashboardStatistical = 12,
  DashboardGraphical = 13,
  Timelog = 14,
  TimeUnsubmitted = 15,
  TimePFA = 16,
  TimeApproved = 17,
  TimeRejected = 18,
  Job = 19,
  JobsCalendar = 20,
  InvoiceOverView = 21,
  Invoice = 22,
  RecurringInvoice = 23,
  Estimate = 24,
  ReportTime = 25,
  ReportExpense = 26,
  ReportInvoice = 27,
  ReportUsers = 28,
  Clients = 29,
  Tasks = 30,
  Users = 31,
  ExpenseType = 32,
  MyBusiness = 33,
  Preferences = 34,
  DemoData = 35,
  SettingInvoices = 36,
  SettingEstimates = 37,
  Templates = 38,
  Integration = 39,
  Import = 40,
  Export = 41,
  Permission = 42,
  JobDetail = 43,
  JobTimelogs = 44,
  JobEmail = 45,
  JobRecurring = 46,
  JobFiles = 47,
  JobComment = 48,
  JobActivity = 49,
  JobInvoice = 50,

  TaskUsers = 52,
  TaskClients = 53,
  TaskSubtask = 54,
  ClientTasks = 55,
  ClientUsers = 56,
  UserTasks = 57,
  UserClients = 58,
}

export enum SubModules {
  TimelogByUser = 1,
  TimelogByWeek = 2,
}
