<div
  class="d-flex justify-content-space-around gap-15"
  *ngIf="piedata?.length !== 0"
>
  <ejs-accumulationchart
    id="chart-container1"
    [legendSettings]="legendSettings"
    [tooltip]="tooltip"
    class="fees-dash-height"
    width="350"
  >
    <e-accumulation-series-collection>
      <e-accumulation-series
        [dataSource]="piedata"
        [palettes]="palette"
        xName="x"
        yName="y"
        tooltipMappingName="tooltipMappingName"
        innerRadius="50%"
      ></e-accumulation-series>
    </e-accumulation-series-collection>
  </ejs-accumulationchart>
  <div>
    <div class="card-body p-0">
      <div class="mat-table-wrapper">
        <table
          mat-table
          [dataSource]="dataSource"
          aria-describedby="Income by top 5 projects Table"
        >
          <ng-container matColumnDef="taskName">
            <th mat-header-cell *matHeaderCellDef>Task</th>
            <td mat-cell *matCellDef="let element">{{ element.taskName }}</td>
          </ng-container>
          <ng-container matColumnDef="userName">
            <th mat-header-cell *matHeaderCellDef>User</th>
            <td mat-cell *matCellDef="let element">{{ element.userName }}</td>
          </ng-container>
          <ng-container matColumnDef="timeSpent">
            <th mat-header-cell *matHeaderCellDef>Hours</th>
            <td mat-cell *matCellDef="let element">{{ element.timeSpent }}</td>
          </ng-container>
          <ng-container matColumnDef="progress">
            <th mat-header-cell *matHeaderCellDef>Progress</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center gap-10">
                <div class="cust-progress wp-100">
                  <mat-progress-bar
                    mode="determinate"
                    [value]="element.timeSpentByPercentage"
                  ></mat-progress-bar>
                </div>
                {{ element.timeSpentByPercentage }}%
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="norecord-chart" *ngIf="piedata?.length === 0">
  <img src="assets/images/pia-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
