<router-outlet></router-outlet>

<ngx-spinner
  template="<img class='loader-image mr-1' src='assets/images/loader.svg' />
  <span class='loader'></span>"
></ngx-spinner>

<div
  class="timer-box main-contain"
  cdkDrag
  cdkDragBoundary="body"
  *ngIf="showTimerPopup"
>
  <div class="drag-icon" *ngIf="isTimerRunning" cdkDragHandle>
    <mat-icon>drag_indicator</mat-icon>
  </div>
  <div class="timer-title d-flex align-items-center justify-space-between">
    <div>
      {{ jobData?.clientName }}
      <p>
        <strong>{{ jobData?.jobCode }}</strong> - {{ jobData?.taskName }}<br />
        <span *ngIf="isTimerRunning"> ({{ selectedSubtaskName }}) </span>
      </p>
    </div>
    <div>
      <button
        mat-button
        class="header-text-close d-flex align-items-center"
        disableRipple
        (click)="onCloseClick()"
        *ngIf="!isTimerRunning"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="form-field pt-10" *ngIf="!isTimerRunning">
    <p class="fw-normal">Please Select Subtask</p>
    <ng-select
      class="w-170"
      panelClass="myPanelClass"
      [disableOptionCentering]="true"
      [(ngModel)]="selectedSubtaskId"
      #subTask
      (focus)="subTask.open()"
      (valueChange)="subTask.close()"
      (change)="onSelectionChange($event)"
    >
      <ng-option [value]="defaultGuid">--Select--</ng-option>
      <ng-option
        *ngFor="let subTask of subTaskList"
        [value]="subTask.universalId"
        >{{ subTask.name }}</ng-option
      >
    </ng-select>
  </div>
  <button
    class="action-button secondary-button"
    mat-dialog-close
    disableRipple
    type="button"
    mat-button
    (click)="onStartTimerClick()"
    disableRipple
    *ngIf="!isTimerRunning"
  >
    <mat-icon class="timer-icon" fontSet="material-icons-outlined"
      >timer</mat-icon
    >
    Start Timer
  </button>

  <div class="start-timer" *ngIf="isTimerRunning">
    <div>
      <mat-icon (click)="onStopTimerClick()">stop_circle</mat-icon>
      <mat-icon (click)="onPauseTimerClick()" *ngIf="!isTimerPaused"
        >pause_circle
      </mat-icon>
      <mat-icon (click)="onResumeTimerClick()" *ngIf="isTimerPaused"
        >play_circle
      </mat-icon>
    </div>
    <p>{{ totalTimeTracked }}</p>
  </div>
</div>
