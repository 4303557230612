import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { EntityTypes } from '@app/core/enums';
import {
  ActivityLogModel,
  ActivityTaskParameters,
  AuthorisedMenuModel,
  FilterTypes,
  GlobalComponent,
} from '@app/core/models';
import { CustomLoaderComponent } from '@app/core/shared/components';
import {
  GetActivityList,
  GetAuthorizedMenus,
  GetStartWeekAndEndWeek,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class ActivityComponent implements OnInit {
  filterTypes = FilterTypes;
  loader = new CustomLoaderComponent();
  moduleList: Array<AuthorisedMenuModel>;

  activityList: any = [];
  attachment: any[];

  hasAttachments = false;
  message = '';
  search = '';
  selectedModule = 0;
  entityTypes = EntityTypes;
  activityTaskParameters: ActivityTaskParameters;
  activities: Array<ActivityLogModel>;
  triggerActivityDetails: Subject<any> = new Subject<any>();
  calendarForm: FormGroup;
  filter = 1;
  start: string = '';
  end: string = '';
  showCalendar = false;
  startDate = new FormControl({ value: new Date(), disabled: true });
  endDate = new FormControl({ value: new Date(), disabled: true });
  isShowLoader = false;
  isRegularUser = this.globalComponent.isRegularUser();
  constructor(
    private store: Store,
    public dialog: MatDialog,
    private globalComponent: GlobalComponent,
    public datepipe: DatePipe,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getAuthorizedMenus();
  }

  setForm(): void {
    this.calendarForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });
  }

  onSelectionChange(val: any): void {
    this.filter = +val;

    if (this.filter === 9) {
      this.showCalendar = true;
      this.getStartWeekAndEndWeek();
    } else {
      this.showCalendar = false;
      this.dataChangeFromHeader();
    }
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.calendarForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.dataChangeFromHeader();
    });
  }

  dataChangeFromHeader(): void {
    this.end =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.end.value,
            'yyyy-MM-dd'
          )!;
    this.start =
      this.filter < 9
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.start.value,
            'yyyy-MM-dd'
          )!;

    this.getList();
  }

  lessWeek(): void {
    this.calendarForm = this.formBuilder.group({
      end: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.calendarForm.controls.start.value);
    this.endDate.setValue(this.calendarForm.controls.end.value);
    this.dataChangeFromHeader();
  }

  addWeek(): void {
    this.calendarForm = this.formBuilder.group({
      start: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 7
        )
      ),
    });

    this.startDate.setValue(this.calendarForm.controls.start.value);
    this.endDate.setValue(this.calendarForm.controls.end.value);
    this.dataChangeFromHeader();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.dataChangeFromHeader();
    }
  }

  getEmunKeyByValue(value: number, enumName: any): string {
    const indexOfS = Object.values(enumName).indexOf(
      value as unknown as InstanceType<typeof enumName>
    );
    const key = Object.keys(enumName)[indexOfS];

    return key;
  }

  getAuthorizedMenus(): void {
    this.store
      .dispatch(new GetAuthorizedMenus())
      .pipe(
        tap((res) => {
          if (res.activity.authorisedMenu) {
            this.moduleList = res.activity.authorisedMenu;
            if (this.isRegularUser) {
              this.selectedModule = this.moduleList[0].id;
            }
          }
          this.getList();
        })
      )
      .subscribe();
  }

  getList(): void {
    this.loader.hideloader(false);
    this.isShowLoader = true;
    this.activityList = [];

    this.activityTaskParameters = {
      moduleId: this.selectedModule,
      search: this.search,
      entityId: Guid.EMPTY as unknown as Guid,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
      dateFilter: this.filter ?? 0,
      pageNumber: 0,
      pageSize: 0,
    };

    this.store
      .dispatch(new GetActivityList(this.activityTaskParameters))
      .pipe(
        tap((res) => {
          this.activities = res.activity.activities;
          this.loader.hideloader(true);
          this.isShowLoader = false;
          setTimeout(() => {
            this.triggerActivityDetails.next();
          }, 500);
        })
      )
      .subscribe();
  }

  onSearch(event: any): void {
    this.search = event.target.value;
    this.getList();
  }

  onActivitySelectionChange(activityId: any): void {
    this.selectedModule = activityId;
    this.getList();
  }
}
