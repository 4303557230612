import { Component, OnInit } from '@angular/core';
import { ToggleMenu, CommonService } from '@app/core/services';
import { SettingsState } from '@app/core/store';
import { Select } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss'],
})
export class JobsComponent implements OnInit {
  showMenu = true;
  showAddPage = false;

  @Select(SettingsState.getSubMenus)
  subMenus$: Observable<any>;

  constructor(
    private toggleMenu: ToggleMenu,
    private spinner: NgxSpinnerService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService.sendThemeMessage('theme');
    this.toggleMenu.showIcon.next(true);
  }

  menuToggle() {
    this.showMenu = !this.showMenu;
  }
  onAddClick() {
    this.spinner.show();
    this.spinner.hide();
    this.showAddPage = false;
    this.showAddPage = !this.showAddPage;
  }
}
