import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import { ExpenseRequestModel, GlobalComponent } from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { UpdateExpenseStatus } from '@app/core/store';
import { environment } from '@environments/environment.beta';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-expense-confirmation',
  templateUrl: './expense-confirmation.component.html',
  styleUrls: ['./expense-confirmation.component.scss'],
})
export class ExpenseConfirmationComponent implements OnInit {
  headerText: string;
  detailText: string;
  totalNumberOfRecordSelected: number;
  isButtonDisabled = false;
  expenseStatus: number;
  expenseAction: number;
  moduleId: number;
  expenseRequestModelData: ExpenseRequestModel;

  expenseData: {
    startDate: string;
    endDate: string;
    expenseIds: Array<Guid>;
  }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private spinner: NgxSpinnerService,
    private store: Store,
    private notifier: NotificationService,
    private commonService: CommonService,
    private globalComponent: GlobalComponent,
    public dialogRef: MatDialogRef<ExpenseConfirmationComponent>
  ) {}

  ngOnInit(): void {
    this.expenseStatus = this.data.expenseStatus;
    this.expenseAction = this.data.expenseAction;
    this.headerText = this.data.headerText;
    this.detailText = this.data.detailText;
    this.expenseData = this.data.expenseData;
    this.moduleId = this.data.moduleId;
    this.totalNumberOfRecordSelected = this.data.totalNumberOfRecordSelected;
  }

  dataSubmit(): void {
    this.expenseData.forEach((x) => {
      this.expenseRequestModelData = {
        expenseIds: x.expenseIds,
        status: this.expenseAction,
        url: `${environment.uiUrl}expenses/expenseList`,
        userId: this.globalComponent.getLoggedInUserId(),
      };
    });
  }

  onYesClick(): void {
    if (this.moduleId === Modules.Expenses) {
      this.dataSubmit();

      this.spinner.show();
      this.store
        .dispatch(new UpdateExpenseStatus(this.expenseRequestModelData))
        .subscribe(
          (data: any) => {
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
            this.dialogRef.close(NotificationHeader.success);
          },
          (error: any) => {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
            this.dialogRef.close();
          }
        );
    }
  }
}
