import { Guid } from 'guid-typescript';
import { UserTimelog } from '../expense';

export class UserTimelogModel {
  universalId?: Guid;
  clientId?: Guid;
  taskId?: Guid;
  taskName?: string;
  userId: Guid;
  startTime?: string;
  endTime?: string;
  timeSpent: string;
  jobId?: Guid;
  subTaskId?: Guid;
  date?: string;
  status?: number;
  isBillable?: boolean;
  description?: string;
  isArchived?: boolean;
  isDeleted?: boolean;
}

export class PendingTimelogList {
  startDate: Date;
  endDate: Date;
  totalHours: number;
  billableHours: number;
  nonBillableHours: number;
  totalExpense: number;
  users: Array<UserTimelog>;
  isSelected: boolean;
  userId?: string;
}

export class Archived {
  dateRange: string;
  totalHours: number;
  isSelected: boolean;
  ids: string;
}

export class ArchivedTimelogList {
  userId: string;
  name: string;
  totalHours: number;
  timelogs: Array<Archived>;
  isSelected: boolean;
}

export class PendingSummary {
  totalTeamMembers: number;
  totalHours: number;
  billableHours: number;
  nonBillableHours: number;
  totalExpense: number;
}

export class TimeLogModel {
  columns: Array<string>;
  data: Array<Array<HoursModel>>;
  allDates: Array<Date>;
  userId?: Guid;
  status?: number;
  totalTodayHours?: string;
  totalNonBillableHours?: string;
  totalBillableHours?: string;
  totalWeekHours?: string;
  currentDate?: string;
}

export class HoursModel {
  value: string;
  invoiceId: Guid;
  applyMinimumChargeableTime?: boolean;
}

export class UserSettingModel {
  id?: number;
  userId?: string;
  timezone?: string;
  fiscalYear?: number;
  weekStartOn?: number;
  defaultCapacity?: number;
  timesheetDeadline?: number;
  timeFormatHour?: number;
  timeModel?: number;
  currencyId?: number;
  amountFormat?: number;
  webAddress?: string;
  isCompany: boolean;
  companyNumber: string;
  companyVatnumber: string;
  address1: string;
  address2: string;
  postalCode: string;
  city: string;
  fullName?: string;
  name?: string;
  email: string;
  phone: string;
  logo: string;
}

export class CopyTimeLogModel {
  userId?: Guid;
  startDate?: string;
  endDate?: string;
}

export class DeleteTimelogByWeekModel {
  userId?: Guid;
  jobId?: Guid;
  taskId?: Guid;
  subTaskId?: Guid;
  startDate?: string;
  endDate?: string;
}

export class SubmitTimeLogModel {
  userId: string;
  startDate?: string;
  endDate?: string;
  isResubmitted: boolean;
  uRL: string;
}

export class ApproveTimeLogModel {
  userId: string;
  startDate?: string;
  endDate?: string;
  uRL: string;
  projectId?: string;
}

export class PendingForApprovalModel {
  timelogByWeek: Array<TimelogListByWeekModel>;
  timelogByProject: Array<TimelogListByProjectModel>;
  timelogByPeople: Array<TimelogListByPeopleModel>;
}

export class TimelogListByWeekModel {
  startDate: string;
  endDate: string;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  totalExpense: number;
  isSelected: boolean;
  users: Array<UserLogModel>;
}

export class TimelogDetailListByProjectModel {
  totalTeammate: number;
  totalHours: string;
  totalBillableHours: string;
  totalNonBillableHours: string;
  totalExpense: number;

  projects: Array<TimelogListByProjectModel>;
}

export class TimelogListByProjectModel {
  projectId: number;
  projectName: string;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  totalExpense: number;
  totalTeammate: number;
  totalCost: number;
  totalBillableAmount: number;
  users: Array<UserLogModel>;
}

export class TimelogListByPeopleModel {
  userId: string;
  userFullName: string;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  totalExpense: number;
  users: Array<UserLogModel>;
}

export class UserLogModel {
  userId: string;
  name: string;
  timelogIds: string;
  totalHours: number;
  billableHours: number;
  nonBillableHours: number;
  totalExpense: number;
  startDate: string;
  endDate: string;
  isChildSelected: boolean;
  totalCost: number;
  totalBillableAmount: number;
}

export class TimeLogFilterDetailModel {
  totalTeammate: number;
  totalHours: string;
  totalCost: string;
  totalBillableAmount: number;
  totalBillableHours: string;
  totalNonBillableHours: string;
  totalExpense: number;
  userTimeLogDetails: Array<UserTimeLogDetails>;
}

export class UserTimeLogDetails {
  userId: string;
  name: string;
  totalHours: number;
  billableHours: number;
  nonBillableHours: number;
  expense: number;
  weekTimelogDetails: Array<WeekTimeLogDetailModel>;
  isSelected?: boolean;
}

export class WeekTimeLogDetailModel {
  ids: Array<number>;
  startDate: string;
  endDate: string;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  expense: string;
  userId: string;
  isSelected?: boolean;
  weekTimeLogDetailModel: Array<WeekTimeLogDetailModel>;
}

//-------------------------------------------------------------

export class UserTimelogDataModel {
  userId: Guid;
  fullName: string;
  userType: string;
  totalHours: string;
  totalBillableHours: string;
  totalNonBillableHours: string;
  totalAmount: number;
  totalCost: number;
  weekNumber: number;
}

export class TimelogByUserModel extends UserTimelogDataModel {
  userId: Guid;
  weekData: Array<WeekModel>;
}

export class WeekModel {
  weekNumber: number;
  weekName: string;
  year: number;
  totalHours: string;
  totalBillableHours: string;
  totalNonBillableHours: string;
  totalCost: number;
  totalAmount: number;
  weekStartDate: string;
  weekEndDate: string;
}

export class TimelogByWeekModel extends WeekModel {
  userData: Array<UserTimelogDataModel>;
}

export class UserWeekTimelogOverviewModel {
  userId: Guid;
  userFullName: string;
  totalHours: string;
  billableHours: string;
  nonBillableHours: string;
  thisWeekPercentage: number;
  totalDifference: string;
  totalClient: number;
  totalJob: number;
  totalTask: number;
  score: number;
  submittedOn: string;
  clients: Array<WeekTimelogClientOverViewModel>;
  jobs: WeekTimelogChartModel;
}

export class WeekTimelogClientOverViewModel {
  clientId: Guid;
  taskId: Guid;
  jobId: Guid;
  clientName: string;
  taskName: string;
  timeSpent: string;
  pecentage: number;
}

export class WeekTimelogChartModel {
  categories: Array<string>;
  series: Array<WeekTimelogSeriesModel>;
}

export class WeekTimelogSeriesModel {
  name: string;
  data: Array<number>;
  subTask: Array<string>;
}

export class UserLiveTimeLogModel {
  userId: Guid;
  userName: string;
  heading: string;
  timeSpent: string;
}

export class TimelogCalendarDetailsModel {
  universalId: Guid;
  heading: string;
  userId: Guid;
  jobId: Guid;
  clientId: Guid;
  taskId: Guid;
  subTaskId: Guid;
  date: string;
  timeSpent: string;
  description: string;
  startTime: string;
  endTime: string;
  status: number;
}
