export enum BackLinkText {
  Teammates = 'Back to Teammates',
  Profile = 'Back to Profile',
  Project = 'Back to Service',
  Invoice = 'Back to Invoice',
  RecurringInvoices = 'Back to Recurring Invoice',
  Estimate = 'Back to Estimate',
}

export enum BackLinkType {
  Teammates = 'tm',
  Profile = 'ps',
  Project = 'prj',
  Invoice = 'inv',
  RecurringInvoices = 'rinv',
  Estimate = 'est',
}
