import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CalendarDetailModel } from '@app/core/models/calendar';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  AddTimeLogModel,
  ApproveTimeLogModel,
  CalendarDetailParameters,
  CopyTimeLogModel,
  DayTimelogParameters,
  DeleteTimelogByWeekModel,
  ExportParams1,
  JobTimelogParams,
  ListModel,
  PendingSummary,
  SendEmailModule,
  SideListGroupModel,
  SideListModel,
  SubmitTimeLogModel,
  TimeLogFilterDetailModel,
  TimeLogModel,
  TimeParameters,
  TimeSubTaskListModel,
  TimelogCalendarDetailsModel,
  TimelogDetailListByProjectModel,
  TimelogDetailModel,
  TimelogListByWeekModel,
  TimelogParameter,
  TimesheetExportParams,
  UserLiveTimeLogModel,
  UserSettingModel,
  UserTimelogModel,
  UserWeekTimelogOverviewModel,
} from '../../models';
import { CommonService } from '../common';

@Injectable({
  providedIn: 'root',
})
export class TimeService {
  //#region  timer property
  startTime: Date;
  projectId: string;
  taskId: string;
  timeCaptured: string;
  timer: any;
  projectClientAndTaskName: string;
  seconds = 0;
  timerPause = false;
  timerStart = false;
  istimerPin = true;
  isDataAdded = false;
  showTimer = false;
  timeTracker = this.timeTrackedFormatter(this.seconds);

  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Timelog';

  isRecordFound: BehaviorSubject<boolean> = new BehaviorSubject(true);

  //#endregion

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private titleService: Title,
    private commonService: CommonService
  ) {}

  getTimesheetCalendarDetail(
    userId: Guid,
    currentDate: string
  ): Observable<Array<TimelogCalendarDetailsModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getTimesheetCalendarDetail/${userId}?currentDate=${currentDate}`,
      headers
    );
  }

  getCalendar(
    calendarDetailParameters: CalendarDetailParameters
  ): Observable<HttpResponse<CalendarDetailModel[]>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getCalendar`,
      calendarDetailParameters,
      httpOptions
    );
  }

  saveTimeLog(addTimeLogModel: AddTimeLogModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveTimelog`,
      JSON.stringify(addTimeLogModel),
      headers
    );
  }

  getUsers(jobId: Guid, clientId: Guid): Observable<Array<ListModel>> {
    return this.http.get<Array<ListModel>>(
      `${this.apiUrl}${this.routeBase}/getUsers?jobId=${jobId}&clientId=${clientId}`
    );
  }

  getTasks(jobId: Guid, clientId: Guid): Observable<Array<SideListGroupModel>> {
    return this.http.get<Array<SideListGroupModel>>(
      `${this.apiUrl}${this.routeBase}/getTasks?jobId=${jobId}&clientId=${clientId}`
    );
  }

  getSubTasks(
    jobId: Guid,
    taskId: Guid
  ): Observable<Array<TimeSubTaskListModel>> {
    return this.http.get<Array<TimeSubTaskListModel>>(
      `${this.apiUrl}${this.routeBase}/getSubTasks?jobId=${jobId}&taskId=${taskId}`
    );
  }

  getClients(jobId: Guid): Observable<Array<ListModel>> {
    return this.http.get<Array<ListModel>>(
      `${this.apiUrl}${this.routeBase}/getClients?jobId=${jobId}`
    );
  }

  getFilterTimeLog(
    timeParameters: TimeParameters
  ): Observable<TimeLogFilterDetailModel> {
    return this.http.get<TimeLogFilterDetailModel>(
      `${this.apiUrl}${this.routeBase}/getFilterTimeLog?userId=${timeParameters.userId}&startDate=${timeParameters.startDate}&endDate=${timeParameters.endDate}&status=${timeParameters.status}&search=${timeParameters.search}&sortBy=${timeParameters.sortBy}&sortOrder=${timeParameters.sortOrder}`
    );
  }

  copyTimeLog(copyTimeLogModel: CopyTimeLogModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/copyTimeLog`,
      JSON.stringify(copyTimeLogModel),
      headers
    );
  }

  withdrawTimelog(
    approveTimeLogModel: Array<ApproveTimeLogModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/withdrawnTimeLog`,
      JSON.stringify(approveTimeLogModel),
      headers
    );
  }

  sendEmail(sendEmailModule: Array<SendEmailModule>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/Comment`,
      JSON.stringify(sendEmailModule),
      headers
    );
  }

  sendReminderForApproval(
    submitTimeLogModel: Array<SendEmailModule>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/reminder`,
      JSON.stringify(submitTimeLogModel),
      headers
    );
  }

  sendEmailNote(submitTimeLogModel: Array<SendEmailModule>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/commentTimelog`,
      JSON.stringify(submitTimeLogModel),
      headers
    );
  }

  rejectTimeLog(submitTimeLogModel: Array<SendEmailModule>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/reject`,
      JSON.stringify(submitTimeLogModel),
      headers
    );
  }

  submitTimelog(
    submitTimeLogModel: Array<SubmitTimeLogModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/submit`,
      JSON.stringify(submitTimeLogModel),
      headers
    );
  }

  approveTimelog(
    approveTimeLogModel: Array<ApproveTimeLogModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/approve`,
      JSON.stringify(approveTimeLogModel),
      headers
    );
  }

  saveTimelog(userTimelog: UserTimelogModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveTimelog`,
      JSON.stringify(userTimelog),
      headers
    );
  }

  getTimeLogDetailsByWeek(
    timelogDetailModel: TimelogDetailModel
  ): Observable<UserTimelogModel> {
    return this.http.get<UserTimelogModel>(
      `${this.apiUrl}${
        this.routeBase
      }/getTimelog?date=${this.datepipe.transform(
        timelogDetailModel.date,
        'yyyy-MM-dd'
      )}&userId=${timelogDetailModel.userId}&jobId=${
        timelogDetailModel.jobId
      }&subTaskId=${timelogDetailModel.subTaskId}`
    );
  }

  getTimeLogDetailsByUniversalId(
    universalId: Guid
  ): Observable<UserTimelogModel> {
    return this.http.get<UserTimelogModel>(
      `${this.apiUrl}${this.routeBase}/getTimelogByUniversalId/${universalId}`
    );
  }

  getUserSettings(userId: string): Observable<UserSettingModel> {
    return this.http.get<UserSettingModel>(
      `${environment.apiVersionUrl}/Settings/getUserSettings/${userId}`
    );
  }

  getPendingTimeLogByProject(
    timeParameters: TimeParameters
  ): Observable<Array<TimelogDetailListByProjectModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Array<TimelogDetailListByProjectModel>>(
      `` +
        `${
          environment.apiVersionUrl
        }/TimeLog/pendingApproval/byProject?&search=${
          timeParameters.search
        }&projectId=${timeParameters.projectId}&userId=${
          timeParameters.userId
        }&startDate=${this.datepipe.transform(
          timeParameters.startDate,
          'yyyy-MM-dd'
        )}&endDate=${this.datepipe.transform(
          timeParameters.endDate,
          'yyyy-MM-dd'
        )}&sortBy=${timeParameters.sortBy}&sortOrder=${
          timeParameters.sortOrder
        }&status=${timeParameters.status}`,
      headers
    );
  }

  getUserWeekTimelogs(
    timelogParams: TimelogParameter
  ): Observable<TimeLogModel> {
    return this.http.get<TimeLogModel>(
      `${this.apiUrl}${this.routeBase}/getWeekTimelog?startDate=${timelogParams.startDate}&endDate=${timelogParams.endDate}&search=${timelogParams.search}&status=${timelogParams.status}&userId=${timelogParams.userId}&isSort=${timelogParams.isSort}`
    );
  }

  getUserDayTimelog(
    dayTimelogParams: DayTimelogParameters
  ): Observable<TimeLogModel> {
    return this.http.get<TimeLogModel>(
      `${this.apiUrl}${this.routeBase}/getDayTimelog?currentDate=${dayTimelogParams.currentDate}&search=${dayTimelogParams.search}&status=${dayTimelogParams.status}&userId=${dayTimelogParams.userId}&isSort=${dayTimelogParams.isSort}`
    );
  }

  getPendingTimeLogByWeek(
    pendingTimeSheetParameter: TimeParameters
  ): Observable<Array<TimelogListByWeekModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Array<TimelogListByWeekModel>>(
      `` +
        `${this.apiUrl}${this.routeBase}/pendingApproval/byWeek?pageNumber=${pendingTimeSheetParameter.pageNumber}&pageSize=${pendingTimeSheetParameter.pageSize}&sortBy=${pendingTimeSheetParameter.sortBy}&sortOrder=${pendingTimeSheetParameter.sortOrder}&search=${pendingTimeSheetParameter.search}&filter=${pendingTimeSheetParameter.filter}&userId=${pendingTimeSheetParameter.userId}`,
      headers
    );
  }

  PendingApprovalByProject(
    pendingTimeSheetParameter: TimeParameters
  ): Observable<Array<TimelogDetailListByProjectModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.get<Array<TimelogDetailListByProjectModel>>(
      `` +
        `${
          environment.apiVersionUrl
        }/TimeLog/pendingApproval/byProject?pageNumber=${
          pendingTimeSheetParameter.pageNumber
        }&pageSize=${pendingTimeSheetParameter.pageSize}&sortBy=${
          pendingTimeSheetParameter.sortBy
        }&sortOrder=${pendingTimeSheetParameter.sortOrder}&search=${
          pendingTimeSheetParameter.search
        }&filter=${pendingTimeSheetParameter.filter}&userId=${
          pendingTimeSheetParameter.userId
        }&startDate=${this.datepipe.transform(
          pendingTimeSheetParameter.startDate,
          'yyyy-MM-dd'
        )}&endDate=${this.datepipe.transform(
          pendingTimeSheetParameter.endDate,
          'yyyy-MM-dd'
        )}`,
      headers
    );
  }

  getPendingSummary(): Observable<PendingSummary> {
    return this.http.get<PendingSummary>(
      `${this.apiUrl}${this.routeBase}/pending/summary`
    );
  }

  createTimelog(log: any): Observable<number> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<number>(
      `${this.apiUrl}${this.routeBase}/create`,
      JSON.stringify(log),
      headers
    );
  }

  saveWeekTimelog(timelogModel: TimeLogModel, status: number): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveweektimelog?status=${status}`,
      JSON.stringify(timelogModel),
      headers
    );
  }

  deleteUserTimeLogByWeek(
    deleteTimeSheet: DeleteTimelogByWeekModel
  ): Observable<boolean> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(deleteTimeSheet),
    };

    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteUserTimeLogByWeek`,
      options
    );
  }

  deleteTimelogByUniversalId(universalId: Guid): Observable<boolean> {
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteTimelogByUniversalId/${universalId}`
    );
  }

  //#region timer

  timerStopMethod(): any {
    clearInterval(this.timer);
    this.timerStart = false;
    this.timerPause = false;
    this.timeCaptured = this.timeTracker;
    this.seconds = 0;
    this.timeTracker = this.timeTrackedFormatter(this.seconds);
    this.titleService.setTitle('CapiumSite');
  }

  timerStartMethod(): any {
    this.timerStart = true;
    this.timer = setInterval(() => {
      this.seconds++;
      this.timeTracker = this.timeTrackedFormatter(this.seconds);
    }, 1000);
    this.startTime = new Date();
  }

  timerPauseMethod(): any {
    clearInterval(this.timer);
    this.timerStart = false;
    this.timerPause = true;
  }

  timerPinMethod(val: boolean): any {
    this.istimerPin = val;
    if (this.istimerPin) {
      this.timeTracker = this.timeTrackedFormatter(this.seconds);
    }
  }

  displayTimeTracker(timerStart: boolean): any {
    if (timerStart) {
      this.titleService.setTitle(localStorage.getItem('time') + ' CapiumSite');
      return this.timeTracker;
    }
  }

  timeTrackedFormatter(seconds: any): any {
    let minutes = 0;
    let hours = 0;
    while (seconds >= 3600) {
      seconds -= 3600;
      hours++;
    }
    while (seconds >= 60) {
      seconds -= 60;
      minutes++;
    }
    return (
      (hours < 10 ? '0' + hours : hours) +
      ':' +
      (minutes < 10 ? '0' + minutes : minutes) +
      ':' +
      (seconds < 10 ? '0' + seconds : seconds)
    );
  }
  //#endregion

  //#region day view

  timerStartMethodDayView(element: any, columnIndex: number): void {
    this.timerStart = true;
    const hours = element[columnIndex]
      ? parseFloat(element[columnIndex].split(':')[0])
      : 0;
    const minutes = element[columnIndex]
      ? parseFloat(element[columnIndex].split(':')[1])
      : 0;
    const sec =
      element[columnIndex].lastIndexOf(':') > 2
        ? parseFloat(element[columnIndex].split(':')[2])
        : 0;

    this.seconds = hours * 3600 + minutes * 60 + sec;

    this.timer = setInterval(() => {
      this.seconds++;
      element[columnIndex] = this.timeTracker = this.timeTrackedFormatter(
        this.seconds
      );
    }, 1000);

    this.startTime = new Date();
  }

  //#endregion

  getAlreadyApprovedTaskStatus(taskApprovedParameter: any): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/isTaskAlreadyApproved`,
      JSON.stringify(taskApprovedParameter),
      headers
    );
  }

  //-----------------------------------

  getTimelogByUser(jobTimelogParams: JobTimelogParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getTimelogByUser`,
      JSON.stringify(jobTimelogParams),
      headers
    );
  }

  getTimelogByWeek(jobTimelogParams: JobTimelogParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getTimelogByWeek`,
      JSON.stringify(jobTimelogParams),
      headers
    );
  }

  getUsersWhoLoggedTime(jobId: Guid): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getUsersWhoLoggedTime?jobId=${jobId}`
    );
  }

  getUserWeekTimelogOverview(
    timeParameters: TimeParameters
  ): Observable<UserWeekTimelogOverviewModel> {
    return this.http.get<UserWeekTimelogOverviewModel>(
      `${this.apiUrl}${this.routeBase}/getUserWeekTimelogOverview?userId=${timeParameters.userId}&startDate=${timeParameters.startDate}&endDate=${timeParameters.endDate}`
    );
  }

  getLiveUpdates(): Observable<Array<UserLiveTimeLogModel>> {
    return this.http.get<Array<UserLiveTimeLogModel>>(
      `${this.apiUrl}${this.routeBase}/getLiveUpdates`
    );
  }

  exportTimelog(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportTimelog`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  saveWeekTimeLogByUniversalId(
    timelogModel: TimeLogModel,
    status: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveWeekTimeLogByUniversalId`,
      JSON.stringify(timelogModel),
      headers
    );
  }

  exportTimesheet(
    timesheetExportParams: TimesheetExportParams
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportTimesheet`,
        JSON.stringify(timesheetExportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);

          return of(true);
        })
      );
  }
}
