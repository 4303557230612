<div class="wrapper">
  <app-aside class="aside" (trigger)="menuToggle()">
    <div
      class="menu-item"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      *ngFor="let subMenu of subMenus$ | async"
    >
      <div class="d-flex justify-space-between">
        <a class="menu-link" routerLink="{{ subMenu.url }}">
          <div class="d-flex align-items-center">
            <mat-icon
              fontSet="material-icons-outlined"
              title="{{ subMenu.name }}"
              >{{ subMenu.iconName }}</mat-icon
            >
            <span>{{ subMenu.name }}</span>
          </div>
        </a>
        <button
          mat-button
          disableRipple
          routerLink="{{ subMenu.addUrl }}"
          (click)="onAddClick()"
        >
          <span>{{ subMenu.name }}</span>
          <mat-icon>{{ subMenu.iconName }}</mat-icon>
        </button>
      </div>
    </div>
  </app-aside>
  <div class="container">
    <router-outlet></router-outlet>
    <app-custom-notifications></app-custom-notifications>
  </div>
</div>
