<div class="cust-height" *ngIf="chartData?.length">
  <ejs-chart
    class="fees-dash-height"
    allowResizing="true"
    id="byinvoice-month"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [title]="title"
    [tooltip]="tooltip"
  >
    <e-series-collection>
      <e-series
        fill="#B9DD94"
        columnWidth="0.4"
        [dataSource]="chartData"
        type="Column"
        xName="x"
        yName="y"
        tooltipMappingName="tooltipMappingName"
      ></e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<div class="norecord-chart" *ngIf="!chartData?.length">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
