<span *ngIf="element.jobs.length >= 1">
  <button
    mat-button
    class="w-auto h-auto"
    [matMenuTriggerFor]="menu1"
    #menuTrigger="matMenuTrigger"
    disableRipple
  >
    <span class="d-flex onelineclamp">
      <span class="fw-normal onelineclamp"> {{ element.jobName }} </span>
    </span>
  </button>
  <mat-menu [overlapTrigger]="false" #menu1="matMenu" class="job-detail">
    <span>
      <div class="sidenav-body-content">
        <div class="form-field-title">
          <p class="font-size-16 pb-10">
            <strong>{{ headerText }}</strong>
          </p>
        </div>
        <div class="sidenav-table wp-100">
          <div class="mat-table-wrapper">
            <table
              [dataSource]="element.jobs"
              mat-table
              aria-describedby="Job details table"
            >
              <ng-container matColumnDef="id" class="w-100">
                <th mat-header-cell *matHeaderCellDef>Job ID</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <span class="d-flex align-items-center gap-5">
                    <div class="listview">
                      <img
                        [src]="
                          element.status === jobStatusModel.Completed
                            ? 'assets/images/check.svg'
                            : 'assets/images/uncheck.svg'
                        "
                        [alt]="
                          +element.status === jobStatusModel.Completed
                            ? 'Check'
                            : 'Uncheck'
                        "
                        width="20px"
                        class="cursor-pointer"
                        [matTooltip]="
                          +element.status === jobStatusModel.Completed
                            ? 'Mark as yet to start'
                            : 'Mark as completed'
                        "
                      />
                    </div>

                    <img
                      *ngIf="element.feeTypeId === feeType.TimeAndFee"
                      src="assets/images/timeandfees.svg"
                      width="15px"
                      alt="Time and Fees"
                      title="Time and Fees"
                    />
                    <img
                      *ngIf="element.feeTypeId === feeType.FixedPrice"
                      src="assets/images/fixedfees.svg"
                      width="18px"
                      alt="Fixed Price"
                      title="Fixed Price"
                    />
                    <img
                      *ngIf="element.feeTypeId === feeType.NonBillable"
                      src="assets/images/nonbillable.svg"
                      width="15px"
                      alt="Non Billable"
                      title="Non Billable"
                    />
                    <span class="mainclamp" title="{{ element.jobCode }}">{{
                      element.jobCode
                    }}</span>
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef>Client</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="d-flex align-items-center">
                    <span class="onelineclamp" title="{{ element.clientName }}">
                      {{ element.clientName }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="task">
                <th mat-header-cell *matHeaderCellDef>Task</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="d-flex align-items-center">
                    <span class="onelineclamp" title="{{ element.taskName }}">
                      {{ element.taskName }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>Start Date</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <div class="d-flex align-items-center">
                    <span
                      class="white-space-nowrap"
                      title="{{ element.startDate }}"
                    >
                      {{ element.startDate | date : "dd-LLL-yyyy" }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef>End Date</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <span
                    class="white-space-nowrap"
                    title="{{ element.endDate }}"
                  >
                    {{ element.endDate | date : "dd-LLL-yyyy" }}
                  </span>
                </td>
              </ng-container>

              <ng-container matColumnDef="estimatedTime">
                <th mat-header-cell *matHeaderCellDef>Estimated Time</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ element.estimatedTime }}
                </td>
              </ng-container>

              <ng-container matColumnDef="loggedTime">
                <th mat-header-cell *matHeaderCellDef>Logged Time</th>
                <td mat-cell *matCellDef="let element; let i = index">
                  {{ element.loggedTime }}
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td
                  class="mat-cell"
                  [ngClass]="'text-align-center'"
                  [attr.colspan]="displayedColumns.length"
                >
                  {{ commonNotificationText.NoRecordsFound }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </span>
  </mat-menu>
</span>
