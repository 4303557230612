import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ActionType,
  CommonNotificationText,
  ConfirmationType,
  ExportType,
  FeeStatus,
  FeeType,
  FilteredStatus,
  GridActionsText,
  HeaderTextForSideList,
  InvoiceFilteredStatus,
  JobStatusModel,
  ModuleName,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  RecurringInvoiceAction,
  SortOrdering,
  TimeModuleSelectedTab,
  TimeNoteDetailText,
  TimelogTypeList,
} from '@app/core/enums';
import { UpdateJobStatusModel } from '@app/core/models';
import { ActionModel } from '@app/core/models/common/grid-action';
import {
  JobTimelogParams,
  MainListParameters,
} from '@app/core/models/common/query-params';
import {
  CommonService,
  HighlightRow,
  NotificationService,
} from '@app/core/services';
import {
  ClientState,
  CommonState,
  Copy,
  Export,
  ExportTimelog,
  GetSideList,
  SettingsState,
  UpdateEstimateStatus,
} from '@app/core/store';
import { UpdateJobStatus } from '@app/core/store/job';
import { TimeExpenseConfirmationComponent } from '@app/modules/home/common/time-expense-confirmation/time-expense-confirmation.component';
import { Select, Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
@Component({
  selector: 'app-side-list',
  templateUrl: './side-list.component.html',
  styleUrls: ['./side-list.component.scss'],
})
export class SideListComponent implements OnInit {
  @Input()
  getModuleId: number;

  dataSourceSelectedLength = 0;
  selectedId = Guid.EMPTY as unknown as Guid;
  closeMenu = true;
  ids: Array<Guid>;
  dataSource: any;
  gridActions: ActionModel[];
  isAllSelected = false;
  headerText: string;
  showSearchBar = false;
  isAddMode = true;
  isRowHighlighted = false;
  displayedColumns: string[] = ['name'];
  feeType = FeeType;
  @Input()
  reloadSideList: Observable<any>;

  @Input()
  userIdToTimeOverview: Observable<any>;

  @ViewChild('searchText') searchField: ElementRef;
  listParameters: MainListParameters = new MainListParameters();

  @Output()
  readonly toggleSideList = new EventEmitter<boolean>();

  @Output()
  readonly showSideListAction = new EventEmitter<any>();

  @Output()
  readonly reloadPage = new EventEmitter();

  @Output()
  readonly triggerTimeOverviewData = new EventEmitter<boolean>();

  @Input() triggerTimelogOverviewData: Observable<any>;

  @Select(SettingsState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  timelogOverviewSubscription: Subscription;
  triggerNumberOfRecords: Subject<any> = new Subject<any>();
  router: Router;
  store: Store;
  notify: NotificationService;
  dialog: MatDialog;
  highlightRow: HighlightRow;
  commonService: CommonService;
  activatedRoute: ActivatedRoute;
  spinner: NgxSpinnerService;
  datepipe: DatePipe;
  updateJobStatusModel: UpdateJobStatusModel;
  feeStatus = FeeStatus;
  commonNotificationText = CommonNotificationText;
  modules = Modules;
  timelogData: any;
  gridActionsText = GridActionsText;

  @ViewChildren('button') formButtons: QueryList<ElementRef>;

  constructor(private injector: Injector, private renderer: Renderer2) {
    this.dialog = injector.get<MatDialog>(MatDialog);
    this.store = injector.get<Store>(Store);
    this.notify = injector.get<NotificationService>(NotificationService);
    this.highlightRow = injector.get<HighlightRow>(HighlightRow);
    this.commonService = injector.get<CommonService>(CommonService);
    this.activatedRoute = injector.get<ActivatedRoute>(ActivatedRoute);
    this.spinner = injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datepipe = injector.get<DatePipe>(DatePipe);
  }

  autoScroll(): void {
    const fragment = 'highlightRecord';
    const element = this.renderer.selectRootElement(`.${fragment}`, true);
    element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  ngOnInit(): void {
    this.listParameters.pageSize = 20;
    this.isAddMode = true;

    this.activatedRoute.params.subscribe((params) => {
      if (params && params['id']) {
        this.selectedId = Buffer.from(
          params['id'],
          'base64'
        ).toString() as unknown as Guid;
      }
    });

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.reloadSideList?.subscribe(() => {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: FilteredStatus['All Status'],
        sortOrder: false,
        sortBy: this.highlightRow.sideListHighlighted.sortBy,
        search: this.listParameters.search,
        isRowHighlighted: this.highlightRow.sideListHighlighted.isHighlighted,
      };
      this.selectedId = this.highlightRow.sideListHighlighted.isHighlighted
        ? (Guid.EMPTY as unknown as Guid)
        : this.selectedId;
      this.bindList(queryParams);
    });

    this.userIdToTimeOverview?.subscribe((res) => {
      this.selectedId = res;
    });

    this.bindList(queryParams);

    this.timelogOverviewSubscription =
      this.triggerTimelogOverviewData?.subscribe((data) => {
        if (data) {
          this.timelogData = data[0];
        }
      });
  }

  statusChangeClick(selectedActionStatus): void {
    this.selectedIds();
    this.isRowHighlighted = false;
    this.store
      .dispatch(new UpdateEstimateStatus(this.ids, selectedActionStatus))
      .subscribe((res) => {
        if (res.estimates.statusChanged) {
          this.notify.success(
            NotificationHeader.success,
            NotificationTextMessage.successMessage
          );
          this.onReloadSideList();
          this.reloadParentPage(true);
        } else {
          this.notify.error(
            NotificationHeader.error,
            NotificationTextMessage.errorMessage
          );
        }
      });
  }

  changeHeaderText(): void {
    switch (this.getModuleId) {
      case Modules.Clients:
        this.headerText = HeaderTextForSideList.Clients;
        break;
      case Modules.ExpenseType:
        this.headerText = HeaderTextForSideList.ExpenseTypes;
        break;
      case Modules.Tasks:
        this.headerText = HeaderTextForSideList.Tasks;
        break;
      case Modules.Users:
        this.headerText = HeaderTextForSideList.Users;
        break;
      case Modules.Expenses:
        this.headerText = HeaderTextForSideList.Expenses;
        break;
      case Modules.Jobs:
        this.headerText = HeaderTextForSideList.Jobs;
        break;
      case Modules.TimeUnsubmitted:
      case Modules.TimePFA:
      case Modules.TimeApproved:
      case Modules.TimeRejected:
        this.headerText = HeaderTextForSideList.Users;
        break;
      case Modules.Invoice:
        this.headerText = HeaderTextForSideList.Invoices;
        break;
      case Modules.RecurringInvoice:
        this.headerText = HeaderTextForSideList.RecurringInvoices;
        break;
      case Modules.Estimate:
        this.headerText = HeaderTextForSideList.Estimates;
        break;
    }
  }

  bindList(queryParams: any): void {
    this.changeHeaderText();

    this.store
      .dispatch(new GetSideList(queryParams, this.getModuleId))
      .pipe(
        tap(() => {
          this.isRowHighlighted = queryParams.isRowHighlighted ?? false;
          this.dataSource = this.store.selectSnapshot(CommonState.getSideList);
          this.gridActions = this.store.selectSnapshot(
            CommonState.getGridActions
          )!;

          this.commonService.setButtonPermissions(
            this.formButtons,
            this.gridActions
          );
          if (this.selectedId !== (Guid.EMPTY as unknown as Guid)) {
            setTimeout(() => {
              this.autoScroll();
              this.isRowHighlighted = false;
            }, 200);
          }
        })
      )
      .subscribe();
  }

  selectAll(event: any): void {
    this.dataSource.forEach((x) => (x.isSelected = event.checked));

    this.isActionVisible(false);
    if (!event.checked) this.reloadParentPage(true);
  }

  onReloadSideList(): void {
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: true,
    };

    this.cancelSelectionClick();
    this.bindList(queryParams);
  }

  addClick(): void {
    this.spinner.show();
    this.isAddMode = true;
    this.selectedId = Guid.EMPTY as unknown as Guid;
    this.isRowHighlighted = false;
    this.commonService.onAddRoutingFromSideList(
      this.getModuleId === Modules.RecurringInvoice
        ? Modules.Invoice
        : this.getModuleId
    );
    this.spinner.hide();
  }

  editClick(element: any): void {
    this.spinner.show();
    this.selectedId = element.universalId;
    if (
      this.getModuleId === Modules.TimeUnsubmitted ||
      this.getModuleId === Modules.TimePFA ||
      this.getModuleId === Modules.TimeApproved ||
      this.getModuleId === Modules.TimeRejected
    ) {
      this.triggerTimeOverviewData.emit(element.universalId);
    } else if (this.getModuleId === Modules.Invoice) {
      let isViewMode = false;
      isViewMode =
        element.status === InvoiceFilteredStatus['Partially Paid'] ||
        element.status === InvoiceFilteredStatus['Paid'];

      this.commonService.onViewRouting(
        this.getModuleId,
        element.universalId,
        isViewMode ? 'true' : 'false'
      );
    } else if (this.getModuleId === Modules.RecurringInvoice) {
      let isViewMode = false;
      isViewMode =
        element.recurringStatus === RecurringInvoiceAction.Inactive ||
        element.recurringStatus === RecurringInvoiceAction.Paused;

      this.commonService.onViewRouting(
        this.getModuleId,
        element.universalId,
        isViewMode ? 'true' : 'false'
      );
    } else {
      this.isAddMode = false;
      this.isRowHighlighted = false;
      if (
        this.getModuleId === Modules.Clients ||
        this.getModuleId === Modules.Tasks ||
        this.getModuleId === Modules.Users
      ) {
        this.commonService.onEditRouting(
          this.getModuleId,
          element.universalId,
          1
        );
      } else {
        this.commonService.onEditRouting(this.getModuleId, element.universalId);
      }
      this.reloadParentPage(false);
    }
    this.spinner.hide();
  }

  deleteClick(): void {
    this.selectedIds();

    if (this.checkCanDeleteArchive(false)) {
      if (this.ids.length > 0) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              type: ConfirmationType.Delete,
              moduleId: this.getModuleId,
              totalNumberOfRecordSelected: this.ids.length,
              headerText: NotificationHeader.deleteConfirmation,
              detailText: NotificationDetails.deleteAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.cancelSelectionClick();
              if (this.getModuleId === Modules.Jobs) {
                this.commonService.onListRouting(this.getModuleId);
              } else {
                this.addClick();
              }
            }
          });
      }
    }
  }

  selectedIds(): void {
    this.ids = [];

    this.dataSource.forEach((x) =>
      x.isSelected ? this.ids.push(x.universalId ?? 0) : ''
    );
  }

  toggleSearchBar(): void {
    this.showSearchBar = !this.showSearchBar;

    if (this.showSearchBar) {
      setTimeout(() => {
        this.searchField.nativeElement.focus();
      }, 0);
    }
  }

  copyClick(): void {
    this.spinner.show();
    this.selectedIds();

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: false,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      //isRowHighlighted: true,
    };
    this.store
      .dispatch(new Copy(this.getModuleId, this.ids))
      .subscribe((res) => {
        if (res.common.isCopied) {
          this.notify.success(
            NotificationHeader.success,
            NotificationTextMessage.recordCopySuccessfully
          );
          this.listParameters.sortBy = SortOrdering.createdOn;
          this.listParameters.sortOrder = false;
          this.bindList(queryParams);
          this.reloadParentPage(true);
          this.cancelSelectionClick();
        }
        setTimeout(() => {
          this.isRowHighlighted = true;
        }, 5000);
      });
  }

  exportClick(format: number, isPrint: boolean): void {
    this.spinner.show();
    this.selectedIds();
    if (
      this.getModuleId !== Modules.TimeUnsubmitted &&
      this.getModuleId !== Modules.TimePFA &&
      this.getModuleId !== Modules.TimeApproved &&
      this.getModuleId !== Modules.TimeRejected
    ) {
      const queryParams = {
        pageNumber: this.listParameters.pageNumber,
        pageSize: this.listParameters.pageSize,
        filter: this.listParameters.filter,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        search: this.listParameters.search,
        ids: this.ids,
        universalIds: this.ids,
        startDate: this.datepipe
          .transform(this.listParameters.startDate, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datepipe
          .transform(this.listParameters.endDate, 'yyyy-MM-dd')
          ?.toString(),
        format: format,
        isPrint: isPrint,
      };

      this.store.dispatch(new Export(queryParams, this.getModuleId)).subscribe(
        (res) => {
          this.isRowHighlighted = false;
          this.dataSource = res.common.sideListModel;
        },
        (error) => {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      );
    } else {
      this.export(format, isPrint);
    }
  }

  archiveAndRestoreClick(isArchive: boolean): void {
    this.selectedIds();

    if (this.checkCanDeleteArchive(true)) {
      if (this.ids.length > 0) {
        this.dialog
          .open(ConfirmationBoxComponent, {
            data: {
              ids: this.ids,
              isArchive,
              type: ConfirmationType.Archive,
              moduleId: this.getModuleId,
              totalNumberOfRecordSelected: this.ids.length,
              headerText: isArchive
                ? NotificationHeader.archiveConfirmation
                : NotificationHeader.restoreConfirmation,
              detailText: isArchive
                ? NotificationDetails.archiveAllDetailText
                : NotificationDetails.restoreAllDetailText,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            if (result) {
              this.cancelSelectionClick();
              this.addClick();
            }
          });
      }
    }
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    if (event.target.value) {
      this.listParameters.pageNumber = 1;
    }
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.bindList(queryParams);
  }

  onCancel(): void {
    this.listParameters.search = '';
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };
    this.bindList(queryParams);
  }

  onTableScroll(event): void {
    let isLastPage: boolean | undefined = false;

    if (this.getModuleId === Modules.Clients)
      isLastPage = this.store.selectSnapshot(ClientState.isLastPage);

    if (isLastPage === false) {
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
      ) {
        const pageIndex = this.listParameters.pageNumber + 1;
        this.pageChanged(pageIndex);
        this.listParameters.pageNumber = 1;
      }
    }
  }

  pageChanged(pageIndex: number): void {
    this.listParameters.pageNumber = pageIndex;
    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };
    this.bindList(queryParams);
  }

  onCheckBoxSelected(event): void {
    this.isAllSelected = this.dataSource.every(
      (item: any) => item.isSelected === true
    );
    this.isActionVisible(false);
    if (!event.checked) this.reloadParentPage(true);
  }

  reloadParentPage(isLoad: boolean): void {
    this.reloadPage.emit(isLoad);
  }

  cancelSelectionClick(): void {
    this.deSelectAll();
    this.isActionVisible(true);
    this.reloadParentPage(true);
  }

  deSelectAll(): void {
    this.dataSource.forEach((x) => (x.isSelected = false));
    this.triggerNumberOfRecords.next(0);
    this.isAllSelected = false;
  }

  printClick(): void {
    this.selectedIds();
    this.exportClick(ExportType.PDF, true);
  }

  isActionVisible(reloadList: boolean): void {
    this.dataSourceSelectedLength = 0;

    const queryParams = {
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: FilteredStatus['All Status'],
      sortOrder: this.listParameters.sortOrder,
      sortBy: this.listParameters.sortBy,
      search: this.listParameters.search,
      isRowHighlighted: false,
    };

    this.dataSource.forEach((x) =>
      x.isSelected ? +this.dataSourceSelectedLength++ : ''
    );

    this.showSideListAction.emit(
      this.dataSourceSelectedLength > 0 ? true : false
    );

    this.triggerNumberOfRecords.next(this.dataSourceSelectedLength);

    if (reloadList || this.dataSourceSelectedLength === 0) {
      this.bindList(queryParams);
    }
  }

  isActive(element: any): boolean {
    return this.selectedId === element.universalId;
  }

  menuToggle(): void {
    this.closeMenu = !this.closeMenu;

    this.toggleSideList.emit(this.closeMenu);
    window.dispatchEvent(new Event('resize'));
  }

  updateJobStatusClick(element?: any): void {
    this.spinner.show();
    let status = element ? element : JobStatusModel.Completed;
    if (
      element &&
      element.isMarkAsCompleted !== undefined &&
      element.isMarkAsYetToStart !== undefined
    ) {
      status = element.isMarkAsCompleted
        ? JobStatusModel.Completed
        : JobStatusModel.NotYetStarted;
    }
    this.selectedIds();

    this.updateJobStatusModel = {
      universalIds: this.ids,
      status: status,
    };

    this.store
      .dispatch(new UpdateJobStatus(this.updateJobStatusModel))
      .pipe()
      .subscribe((res) => {
        if (res.job.isStatusUpdated) {
          this.cancelSelectionClick();
          this.commonService.onSuccess(NotificationTextMessage.successMessage);
        } else {
          this.commonService.onFailure(NotificationTextMessage.errorMessage);
        }
      });
  }

  endRecurringInvoiceClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            ids: this.ids,
            type: ConfirmationType.EndRecurringInvoice,
            moduleId: this.getModuleId,
            totalNumberOfRecordSelected: this.ids.length,
            headerText: NotificationHeader.endConfirmation,
            detailText: NotificationDetails.endDetailText,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
            if (this.getModuleId === Modules.RecurringInvoice) {
              this.commonService.onListRouting(this.getModuleId);
            } else {
              this.addClick();
            }
          }
        });
    }
  }

  checkCanDeleteArchive(isArchiveClicked: boolean): boolean {
    let returnValue = true;

    if (this.getModuleId === Modules.Users) {
      this.dataSource?.forEach((data) => {
        if (this.ids?.includes(data.universalId!) && !data.canDelete) {
          this.notify.error(
            NotificationHeader.error,
            isArchiveClicked
              ? NotificationTextMessage.canNotArchive
              : NotificationTextMessage.canNotDelete
          );
          returnValue = false;
        }
      });
    }

    return returnValue;
  }

  timelogDataSubmit() {
    const timelogData: any = [];
    this.ids.forEach((x) => {
      timelogData.push({
        userId: x,
        startDate: this.datepipe
          .transform(this.timelogData.startDate, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datepipe
          .transform(this.timelogData.endDate, 'yyyy-MM-dd')
          ?.toString(),
      });
    });
    return timelogData;
  }

  onSubmitClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.unsubmitted,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.submitForApprovalConfirmation,
            totalNumberOfRecordSelected: this.ids.length,
            detailText: NotificationDetails.submitTimesheetDetailText,
            status: ActionType.Submit,
            isResubmitted: false,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onEmailClick(): void {
    this.selectedIds();
    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.unsubmitted,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.sendReminder,
            status: ActionType.Reminder,
            noteHeader: TimeNoteDetailText.note,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onApproveClick(): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.pendingApproval,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.approvalConfirmation,
            totalNumberOfRecordSelected: this.ids.length,
            detailText: NotificationDetails.approveTimesheet,
            status: ActionType.Approve,
            isResubmitted: false,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onRejectClick(): void {
    this.selectedIds();
    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.pendingApproval,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.rejectionConfirmation,
            status: ActionType.Reject,
            noteHeader: TimeNoteDetailText.sendRejectionNoteToUser,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onSendNoteClick(): void {
    this.selectedIds();
    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.pendingApproval,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.sendNote,
            status: ActionType.Email,
            noteHeader: TimeNoteDetailText.note,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onWithdrawApproval(): void {
    this.selectedIds();
    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.withdrawApproval,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.withdrawApproval,
            status: ActionType.WithdrawApproval,
            noteHeader: TimeNoteDetailText.note,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  onWithdrawRejection(): void {
    this.selectedIds();
    if (this.ids.length > 0) {
      this.dialog
        .open(TimeExpenseConfirmationComponent, {
          data: {
            moduleId: this.getModuleId,
            listOfData: this.timelogDataSubmit(),
            type: TimeModuleSelectedTab.withdrawRejected,
            moduleName: ModuleName.Time,
            headerText: NotificationHeader.withdrawRejection,
            status: ActionType.WithdrawRejection,
            noteHeader: TimeNoteDetailText.note,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.cancelSelectionClick();
          }
        });
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }

  export(format: number, isPrint: boolean): void {
    this.selectedIds();

    if (this.ids.length > 0) {
      this.spinner.show();
      const exportParams: JobTimelogParams = {
        startDate: this.datepipe
          .transform(this.timelogData.startDate, 'yyyy-MM-dd')
          ?.toString(),
        endDate: this.datepipe
          .transform(this.timelogData.endDate, 'yyyy-MM-dd')
          ?.toString(),
        status: +this.timelogData.selectedStatus,
        userIds: this.ids,
        search: this.listParameters.search,
        sortBy: this.listParameters.sortBy,
        sortOrder: this.listParameters.sortOrder,
        format,
        isPrint,
        groupBy: TimelogTypeList[0],
      };

      this.store.dispatch(new ExportTimelog(exportParams)).subscribe(
        () => {
          this.spinner.hide();
          this.cancelSelectionClick();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    } else {
      this.notify.error(
        NotificationHeader.error,
        NotificationTextMessage.noRecordFoundText
      );
    }
  }
}
