import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ClientByInvoiceAmountModel,
  InvoiceOverviewRequestModel,
} from '@app/core/models';
import { GetClientByInvoiceAmount } from '@app/core/store';
import { Store } from '@ngxs/store';
import {
  AccumulationTheme,
  IAccLoadedEventArgs,
} from '@syncfusion/ej2-angular-charts';
import { Browser } from '@syncfusion/ej2-base';

@Component({
  selector: 'app-fees-invoice-amount',
  templateUrl: './fees-invoice-amount.component.html',
  styleUrls: ['./fees-invoice-amount.component.scss'],
})
export class FeesInvoiceAmountComponent implements OnInit {
  public data: Object[] = [];
  //Initializing Legend
  public legendSettings: Object = {
    visible: true,
    toggleVisibility: false,
    position: Browser.isDevice ? 'Bottom' : 'Right',
    height: Browser.isDevice ? '30%' : '60%',
    width: Browser.isDevice ? '60%' : '40%',
    textWrap: 'Wrap',
    maximumLabelWidth: 150,
  };
  // public title: string = Browser.isDevice ? "Browser Market Share" : '';
  public innerRadius: string = '0%';
  public startAngle: number = 40;
  public radius: string = '90%';
  //Initializing Datalabel
  public dataLabel: Object = {
    name: 'text',
    visible: false,
    font: {
      color: '#ffffff',
    },
  };

  // custom code start
  public load(args: IAccLoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.accumulation.theme = <AccumulationTheme>(
      (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
        .replace(/-dark/i, 'Dark')
        .replace(/contrast/i, 'Contrast')
    );
  }
  public tooltip: Object = {
    enable: true,
    format: '<span>${point.tooltip}</span></span>',
    header: '',
  };

  public palette?: string[];
  filter = 1;
  start: string = '';
  end: string = '';
  invoiceOverviewRequestModel: InvoiceOverviewRequestModel;
  overview: any;
  clientByInvoiceAmountData: ClientByInvoiceAmountModel;
  @Input()
  triggerFilterData: any;
  constructor(private store: Store, public datepipe: DatePipe) {}
  ngOnInit(): void {
    this.palette = [
      '#B9DD94',
      '#7CB5EC',
      '#F1D071',
      '#F2816F',
      '#BFBBE9',
      '#FFD1A7',
      '#7CB5EC',
    ];
    this.triggerFilterData.subscribe((res) => {
      this.filter = res.dateFilter;
      this.start = res.startDate;
      this.end = res.endDate;
      this.getClientByInvoiceAmount();
    });
  }
  getClientByInvoiceAmount(): void {
    this.invoiceOverviewRequestModel = {
      dateFilter: this.filter ?? 0,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };
    this.store
      .dispatch(new GetClientByInvoiceAmount(this.invoiceOverviewRequestModel))
      .pipe()
      .subscribe(
        (res) => {
          this.overview = res.overview.clientByInvoiceAmount;
          if (this.overview) {
            this.data = this.overview;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.data = [];
        }
      );
  }
}
