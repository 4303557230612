import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { CalendarService } from '../../services';
import {
  GetCalendarDetail,
  GetCalendarUsers,
  GetDateWiseJobList,
} from './calendar.action';
import {
  CalendarDetailModel,
  CalendarUserModel,
} from '@app/core/models/calendar';

export class CalendarStateInfo {
  calendarDetails: CalendarDetailModel[];
  calendarUserList: CalendarUserModel[];
  dateWiseJobList?: any;
  isLastPage?: boolean;
}

@State<CalendarStateInfo>({
  name: 'calendar',
  defaults: {
    calendarDetails: [],
    calendarUserList: [],
  },
})
@Injectable()
export class CalendarState {
  constructor(private calendarService: CalendarService) {}

  @Selector()
  static isLastPage(state: CalendarStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getCalendarUserSideList(state: CalendarStateInfo) {
    return state.calendarUserList;
  }

  @Action(GetCalendarDetail, { cancelUncompleted: true })
  getCalendarDetail(
    { getState, patchState }: StateContext<CalendarStateInfo>,
    action: GetCalendarDetail
  ) {
    return this.calendarService
      .getCalendarDetail(action.calendarDetailParameters)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            calendarDetails: res.body!,
          });
        })
      );
  }

  @Action(GetDateWiseJobList, { cancelUncompleted: true })
  getDateWiseJobList(
    { getState, patchState }: StateContext<CalendarStateInfo>,
    action: GetDateWiseJobList
  ) {
    return this.calendarService
      .getDateWiseJobList(action.date, action.userId)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            dateWiseJobList: res,
          });
        })
      );
  }

  @Action(GetCalendarUsers, { cancelUncompleted: true })
  getCalendarUsers(
    { getState, patchState }: StateContext<CalendarStateInfo>,
    action: GetCalendarUsers
  ) {
    return this.calendarService
      .getCalendarUsers(action.calendarDetailParameters)
      .pipe(
        tap((res) => {
          const state = getState();
          let dateWiseJobListData: CalendarUserModel[] | null = [];
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isLastPage = headers.IsLastPage;
          if (
            action.calendarDetailParameters.pageNumber &&
            action.calendarDetailParameters.pageNumber > 1
          ) {
            dateWiseJobListData = state.calendarUserList!;
            dateWiseJobListData = dateWiseJobListData.concat(res.body!);
          } else {
            dateWiseJobListData = res.body;
          }

          patchState({
            ...state,
            calendarUserList: dateWiseJobListData!,
            isLastPage,
          });
        })
      );
  }
}
