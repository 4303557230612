<div class="mat-dialog-wrapper">
  <mat-dialog-content>
    <div class="main-contain">
      <div class="sidenav-header">
        <h2>
          <span>{{ headerText }}</span>
        </h2>
      </div>
    </div>
    <div>
      <p>{{ bodyText }}</p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      class="action-button primary-button mr-1"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onYesClick()"
    >
      Yes
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      type="button"
      mat-button
      disableRipple
      (click)="onNoClick()"
    >
      No
    </button>
  </mat-dialog-actions>
</div>
