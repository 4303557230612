<h2 mat-dialog-title class="mb-10">{{ headerText }}</h2>
<mat-dialog-content>
  <div
    *ngIf="
      (moduleId !== moduleIdEnum.TimeUnsubmitted &&
        moduleId !== moduleIdEnum.TimePFA &&
        moduleId !== moduleIdEnum.TimeApproved &&
        moduleId !== moduleIdEnum.TimeRejected) ||
      (status !== actionType.Reminder &&
        status !== actionType.Reject &&
        status !== actionType.Email &&
        status !== actionType.WithdrawApproval &&
        status !== actionType.WithdrawRejection)
    "
  >
    <div>
      <p class="text-muted fw-bold">Selected Items</p>
      <p class="font-size-18 fw-bold">
        <span class="text-muted font-size-20">Records:</span>
        {{ totalNumberOfRecordSelected }}
      </p>
    </div>
    <p class="font-size-14" [innerHTML]="detailText"></p>
  </div>

  <div
    *ngIf="
      (moduleId === moduleIdEnum.TimeUnsubmitted ||
        moduleId === moduleIdEnum.TimePFA ||
        moduleId === moduleIdEnum.TimeApproved ||
        moduleId === moduleIdEnum.TimeRejected) &&
      (status === actionType.Reminder ||
        status === actionType.Reject ||
        status === actionType.Email ||
        status === actionType.WithdrawApproval ||
        status === actionType.WithdrawRejection)
    "
  >
    <form [formGroup]="noteForm">
      <div class="form-field textarea">
        <p class="fw-bold">{{ noteHeader }}</p>
        <mat-form-field appearance="fill">
          <textarea
            matInput
            formControlName="message"
            autocomplete="off"
            required
            maxlength="500"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="m-0">
  <div
    *ngIf="
      (moduleId !== moduleIdEnum.TimeUnsubmitted &&
        moduleId !== moduleIdEnum.TimePFA &&
        moduleId !== moduleIdEnum.TimeApproved &&
        moduleId !== moduleIdEnum.TimeRejected) ||
      (status !== actionType.Reminder &&
        status !== actionType.Reject &&
        status !== actionType.Email &&
        status !== actionType.WithdrawApproval &&
        status !== actionType.WithdrawRejection)
    "
  >
    <div class="button-container">
      <div class="spinner-container" *ngIf="isButtonDisabled">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        (click)="onYesClick()"
        [disabled]="isButtonDisabled"
      >
        <span *ngIf="moduleId === moduleIdEnum.TimePFA; else confirmAction"
          >Approve</span
        >
        <ng-template #confirmAction> Yes </ng-template>
      </button>
    </div>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      <span *ngIf="moduleId === moduleIdEnum.TimePFA; else action">Cancel</span>
      <ng-template #action>No</ng-template>
    </button>
  </div>

  <div
    *ngIf="
      (moduleId === moduleIdEnum.TimeUnsubmitted ||
        moduleId === moduleIdEnum.TimePFA ||
        moduleId === moduleIdEnum.TimeApproved ||
        moduleId === moduleIdEnum.TimeRejected) &&
      (status === actionType.Reminder ||
        status === actionType.Reject ||
        status === actionType.Email ||
        status === actionType.WithdrawApproval ||
        status === actionType.WithdrawRejection)
    "
  >
    <div class="button-container">
      <div class="spinner-container" *ngIf="isButtonDisabled">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button
        *ngIf="status === actionType.Reminder || status === actionType.Email"
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        (click)="onSendClick()"
        [disabled]="isButtonDisabled"
      >
        <span>Send</span>
      </button>
      <button
        class="action-button primary-button mr-1"
        *ngIf="status === actionType.Reject"
        type="button"
        mat-button
        (click)="onRejectClick()"
        [disabled]="isButtonDisabled"
      >
        <span>Reject</span>
      </button>

      <button
        class="action-button primary-button mr-1"
        *ngIf="
          status === actionType.WithdrawApproval ||
          status === actionType.WithdrawRejection
        "
        type="button"
        mat-button
        (click)="onWithdrawClick()"
        [disabled]="isButtonDisabled"
      >
        <span *ngIf="status === actionType.WithdrawApproval">Withdraw</span>
        <span *ngIf="status === actionType.WithdrawRejection"
          >Withdraw Rejection</span
        >
      </button>
    </div>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
