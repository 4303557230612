import { Injectable } from '@angular/core';
import {
  FeesTypeModel,
  JobCommentListModel,
  JobDetailModel,
  JobEmailModel,
  JobFilesModel,
  JobGroupByTimelogModel,
  JobInvoiceModel,
  JobMainListModel,
  JobRecurring,
  JobReminderModel,
  JobSubTaskModel,
  JobSummaryModel,
  JobTaskModel,
  JobUserModel,
  ListModel,
  SideListModel,
} from '@app/core/models';
import { ActionModel } from '@app/core/models/common/grid-action';
import { JobService } from '@app/core/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import {
  CompleteJobSubTask,
  CreateScheduledJob,
  DeleteJobByScheduleId,
  DeleteSelectedJob,
  ExportJob,
  GetAllJobs,
  GetAssignedUsersByJobId,
  GetAvailableJobByRole,
  GetAvailableUsers,
  GetFeesType,
  GetJobByClientId,
  GetJobCommentList,
  GetJobDetailByUniversalId,
  GetJobDetailList,
  GetJobEmailList,
  GetJobFiles,
  GetJobInvoicesByJobId,
  GetJobRecurring,
  GetJobReminderList,
  GetJobSubTaskAndUsersDetails,
  GetJobSubTaskByJobId,
  GetJobSubTaskDetailList,
  GetJobSubTaskUserDetailList,
  GetJobSummaryByUniversalId,
  GetJobTimelogChart,
  SaveBulkJob,
  SaveJob,
  SaveJobComment,
  SaveJobFiles,
  SaveJobRecurringSetting,
  SendJobEmail,
  SendJobReminderEmail,
  SetJobId,
  UpdateJobStatus,
  UpdateRecurringJobStatus,
} from './job.action';

export class JobStateInfo {
  jobList?: JobMainListModel;
  userList?: Array<JobUserModel>;
  feesTypeList?: Array<FeesTypeModel>;
  jobId?: Guid;
  jobFiles?: Array<JobFilesModel>;
  jobData?: JobDetailModel;
  isRemiderSend?: boolean;
  isEmailSend?: boolean;
  isSubmitSend?: boolean;
  reminderList?: Array<JobReminderModel>;
  emailDetailList?: Array<JobEmailModel>;
  isDataAvailable?: boolean;
  totalRecord?: number;
  isLastPage?: boolean;
  jobfileDataLength: number;
  isFilesUploaded?: boolean;
  sideListModel?: Array<SideListModel>;
  allJobs: Array<ListModel>;
  subTaskList?: Array<JobSubTaskModel>;
  assignedUsersList?: Array<SideListModel>;
  isCommentSubmitted?: boolean;
  commentList?: Array<JobCommentListModel>;
  jobSummaryData?: JobSummaryModel;
  jobSubtaskDetailList?: any;
  jobSubtaskUserDetailList?: any;
  jobGroupByTimelogList?: JobGroupByTimelogModel;
  recurringId?: Guid;
  isJobRecurringEnded?: boolean;
  recurringData?: JobRecurring;
  isScheduledJobCreated?: boolean;
  isStatusUpdated?: boolean;
  jobTimelogChartData?: any;
  exported?: boolean;
  jobTaskList?: Array<JobTaskModel>;
  jobSubTaskList?: Array<JobSubTaskModel>;
  allAvailableJobByRole: Array<ListModel>;
  gridActions?: ActionModel[];
  jobInvoiceList?: JobInvoiceModel[];
  isScheduleJobDeleted?: boolean;
}

@State<JobStateInfo>({
  name: 'job',
  defaults: {
    allJobs: [],
    allAvailableJobByRole: [],
    jobfileDataLength: 0,
    isDataAvailable: true,
  },
})
@Injectable()
export class JobState {
  constructor(private jobService: JobService) {}

  @Selector()
  static getAvailableUsers(state: JobStateInfo) {
    return state.userList;
  }

  @Selector()
  static getJobFiles(state: JobStateInfo): any {
    return state.jobFiles;
  }

  @Selector()
  static isDataAvailable(state: JobStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static totalRecord(state: JobStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static getJobDetailByUniversalId(state: JobStateInfo) {
    return state.jobData;
  }

  @Selector()
  static getAllJobs(state: JobStateInfo) {
    return state.allJobs;
  }

  @Selector()
  static getJobSubTasks(state: JobStateInfo) {
    return state.subTaskList;
  }

  @Selector()
  static getJobId(state: JobStateInfo) {
    return state.jobId;
  }

  @Selector()
  static getGridActions(state: JobStateInfo) {
    return state.gridActions;
  }

  @Action(SendJobReminderEmail)
  sendJobReminderEmail(
    { patchState }: StateContext<JobStateInfo>,
    action: SendJobReminderEmail
  ) {
    return this.jobService
      .sendJobReminderEmail(action.jobId, action.reminderModel)
      .pipe(
        tap((res) => {
          patchState({
            isRemiderSend: res.isSuccess,
          });
        })
      );
  }

  @Action(SendJobEmail)
  sendJobEmail(
    { patchState }: StateContext<JobStateInfo>,
    action: SendJobEmail
  ) {
    return this.jobService.sendJobEmail(action.jobId, action.emailModel).pipe(
      tap((res) => {
        patchState({
          isEmailSend: res.isSuccess,
        });
      })
    );
  }

  @Action(GetJobEmailList)
  getJobEmailList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobEmailList
  ) {
    return this.jobService.getJobEmailList(action.jobId).pipe(
      tap((res) => {
        patchState({
          emailDetailList: res,
        });
      })
    );
  }

  @Action(SaveJobComment)
  saveJobComment(
    { patchState }: StateContext<JobStateInfo>,
    action: SaveJobComment
  ) {
    return this.jobService.saveJobComment(action.jobId, action.emailModel).pipe(
      tap((res) => {
        patchState({
          isCommentSubmitted: res,
        });
      })
    );
  }

  @Action(GetJobCommentList)
  getJobCommentList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobCommentList
  ) {
    return this.jobService.getJobCommentList(action.jobId).pipe(
      tap((res) => {
        patchState({
          commentList: res,
        });
      })
    );
  }

  @Action(GetJobReminderList)
  getJobReminderList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobReminderList
  ) {
    return this.jobService.getJobReminderList(action.jobId).pipe(
      tap((res) => {
        patchState({
          reminderList: res,
        });
      })
    );
  }

  @Action(GetAvailableUsers)
  getAvailableUsers(
    { patchState }: StateContext<JobStateInfo>,
    action: GetAvailableUsers
  ) {
    return this.jobService
      .getAvailableUsers(action.clientId, action.taskId, action.jobId)
      .pipe(
        tap((res) => {
          patchState({
            userList: res,
          });
        })
      );
  }

  @Action(GetFeesType)
  getFeesType({ patchState }: StateContext<JobStateInfo>, action: GetFeesType) {
    return this.jobService
      .getFeesType(action.clientId, action.taskId, action.jobId)
      .pipe(
        tap((res) => {
          patchState({
            feesTypeList: res,
          });
        })
      );
  }

  @Action(SaveJob)
  saveJob({ patchState }: StateContext<JobStateInfo>, action: SaveJob) {
    return this.jobService.saveJob(action.jobModel).pipe(
      tap((res) => {
        patchState({
          jobId: res.data,
        });
      })
    );
  }

  @Action(GetJobDetailByUniversalId)
  getJobDetailByUniversalId(
    { getState, setState }: StateContext<JobStateInfo>,
    action: GetJobDetailByUniversalId
  ) {
    return this.jobService.getJobDetailByUniversalId(action.jobId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          jobId: action.jobId,
          jobData: res,
          subTaskList: res.jobSubtasks,
        });
      })
    );
  }

  @Action(GetAssignedUsersByJobId)
  getAssignedUsersByJobId(
    { patchState }: StateContext<JobStateInfo>,
    action: GetAssignedUsersByJobId
  ) {
    return this.jobService.getAssignedUsersByJobId(action.jobId).pipe(
      tap((res) => {
        patchState({
          assignedUsersList: res,
        });
      })
    );
  }

  @Action(GetAllJobs)
  getAllJobs({ patchState }: StateContext<JobStateInfo>, action: GetAllJobs) {
    return this.jobService.getAllJobs().pipe(
      tap((res) => {
        patchState({
          allJobs: res,
        });
      })
    );
  }

  @Action(GetAvailableJobByRole)
  getAvailableJobByRole(
    { patchState }: StateContext<JobStateInfo>,
    action: GetAvailableJobByRole
  ) {
    return this.jobService.getAvailableJobByRole().pipe(
      tap((res) => {
        patchState({
          allAvailableJobByRole: res,
        });
      })
    );
  }

  @Action(GetJobSummaryByUniversalId)
  getJobSummaryByUniversalId(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobSummaryByUniversalId
  ) {
    return this.jobService.getJobSummaryByUniversalId(action.jobId).pipe(
      tap((res) => {
        patchState({
          jobSummaryData: res,
        });
      })
    );
  }

  @Action(GetJobFiles)
  getJobFiles({ patchState }: StateContext<JobStateInfo>, action: GetJobFiles) {
    return this.jobService.getJobFiles(action.jobId).pipe(
      tap((res) => {
        patchState({
          jobFiles: res,
          jobfileDataLength: res.length,
        });
      })
    );
  }

  @Action(SaveJobFiles)
  saveJobFiles(
    { patchState }: StateContext<JobStateInfo>,
    action: SaveJobFiles
  ) {
    return this.jobService
      .saveJobFiles(action.jobId, action.jobFilesModel)
      .pipe(
        tap((res) => {
          patchState({
            isFilesUploaded: res,
          });
        })
      );
  }

  @Action(GetJobDetailList)
  getJobDetailList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobDetailList
  ) {
    return this.jobService.getJobDetailList(action.jobQueryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            jobList: result,
            gridActions: result.resultSet.actions,
            totalRecord: result.pagination.totalItemCount,
            isDataAvailable: result.pagination.isBusinessDataFound,
          });
        },
      })
    );
  }

  @Action(GetJobSubTaskDetailList, { cancelUncompleted: true })
  getJobSubTaskDetailList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobSubTaskDetailList
  ) {
    return this.jobService.getJobSubTaskDetailList(action.jobQueryParams).pipe(
      tap({
        next: (result) => {
          patchState({
            jobSubtaskDetailList: result,
          });
        },
      })
    );
  }

  @Action(GetJobSubTaskUserDetailList, { cancelUncompleted: true })
  getJobSubTaskUserDetailList(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobSubTaskUserDetailList
  ) {
    return this.jobService
      .getJobSubTaskUserDetailList(action.jobQueryParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              jobSubtaskUserDetailList: result,
            });
          },
        })
      );
  }

  @Action(GetJobSubTaskAndUsersDetails, { cancelUncompleted: true })
  getJobSubTaskAndUsersDetails(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobSubTaskAndUsersDetails
  ) {
    return this.jobService
      .getJobSubTaskAndUsersDetails(action.groupByParams)
      .pipe(
        tap({
          next: (result) => {
            patchState({
              jobGroupByTimelogList: result,
            });
          },
        })
      );
  }

  @Action(SaveJobRecurringSetting)
  saveJobRecurringSetting(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: SaveJobRecurringSetting
  ) {
    return this.jobService.saveJobRecurringSetting(action.jobRecurring).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          recurringId: res,
        });
      })
    );
  }

  @Action(UpdateRecurringJobStatus)
  updateRecurringJobStatus(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: UpdateRecurringJobStatus
  ): any {
    return this.jobService.updateRecurringJobStatus(action.jobId).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          isJobRecurringEnded: res,
        });
      })
    );
  }

  @Action(GetJobRecurring)
  getJobRecurring(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: GetJobRecurring
  ): any {
    return this.jobService.getJobRecurring(action.jobId).pipe(
      tap((res) => {
        const state = getState();

        patchState({
          ...state,
          recurringData: res,
        });
      })
    );
  }

  @Action(CreateScheduledJob)
  createScheduledJob(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: CreateScheduledJob
  ): any {
    return this.jobService.createScheduledJob(action.scheduleJobsModel).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          isScheduledJobCreated: res.isSuccess,
        });
      })
    );
  }

  @Action(UpdateJobStatus)
  updateJobStatus(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: UpdateJobStatus
  ): any {
    return this.jobService.updateJobStatus(action.updateJobStatusModel).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          isStatusUpdated: true,
        });
      })
    );
  }

  @Action(CompleteJobSubTask)
  completeJobSubTask(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: CompleteJobSubTask
  ): any {
    return this.jobService.completeJobSubTask(action.ids).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          isStatusUpdated: true,
        });
      })
    );
  }

  @Action(GetJobTimelogChart)
  getJobTimelogChart(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobTimelogChart
  ) {
    return this.jobService.getJobTimelogChart(action.jobId).pipe(
      tap((res) => {
        patchState({
          jobTimelogChartData: res,
        });
      })
    );
  }

  @Action(DeleteSelectedJob)
  deleteSelectedJob(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: DeleteSelectedJob
  ) {
    return this.jobService.deleteSelectedJob(action.jobIds).pipe(
      tap(() => {
        const state = getState();

        const filteredForSideList = state.sideListModel?.filter(
          (item) =>
            !action.jobIds?.includes(
              item.universalId ?? (Guid.EMPTY as unknown as Guid)
            )
        );

        patchState({
          ...state.jobList,
          sideListModel: filteredForSideList,
        });
      })
    );
  }

  @Action(ExportJob)
  exportJob({ patchState }: StateContext<JobStateInfo>, action: ExportJob) {
    return this.jobService.exportJob(action.exportParams).pipe(
      tap(() => {
        const exported = true;
        patchState({
          exported,
        });
      })
    );
  }

  @Action(GetJobByClientId)
  getJobByClientId(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobByClientId
  ) {
    return this.jobService.getJobByClientId(action.universalId).pipe(
      tap((res) => {
        patchState({
          jobTaskList: res,
        });
      })
    );
  }

  @Action(GetJobSubTaskByJobId)
  getJobSubTaskByJobId(
    { patchState }: StateContext<JobStateInfo>,
    action: GetJobSubTaskByJobId
  ) {
    return this.jobService.getJobSubTaskByJobId(action.jobId).pipe(
      tap((res) => {
        patchState({
          jobSubTaskList: res,
        });
      })
    );
  }

  @Action(GetJobInvoicesByJobId)
  getJobInvoicesByJobId(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: GetJobInvoicesByJobId
  ): any {
    return this.jobService
      .getJobInvoicesByJobId(action.jobId, action.invoiceParams)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state,
            jobInvoiceList: res,
          });
        })
      );
  }

  @Action(SetJobId)
  setJobId(
    { getState, setState }: StateContext<JobStateInfo>,
    action: SetJobId
  ) {
    const state = getState();

    setState({
      ...state,
      jobId: action.jobId,
    });
  }

  @Action(DeleteJobByScheduleId)
  deleteJobByScheduleId(
    { getState, patchState }: StateContext<JobStateInfo>,
    action: DeleteJobByScheduleId
  ): any {
    return this.jobService.deleteJobByScheduleId(action.ids).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state,
          isScheduleJobDeleted: true,
        });
      })
    );
  }

  @Action(SaveBulkJob)
  saveBulkJob({ patchState }: StateContext<JobStateInfo>, action: SaveBulkJob) {
    return this.jobService.saveBulkJob(action.bulkJobModel).pipe(
      tap((res) => {
        patchState({
          jobId: res.data[0],
        });
      })
    );
  }
}
