import { Guid } from 'guid-typescript';
import { DashboardFilterTypes, ExportType } from '../../enums';

//Please dont use this File
export class QueryParams {
  pageNumber?: number;
  pageSize?: number;
  filter?: number;
  sortBy?: string = '';
  sortByNewlyAdded?: string;
  maxPageSize?: number;
  sortOrder?: boolean;
  search?: string = '';
  startDate?: string;
  endDate?: string;
  isRecurring?: boolean;
  moduleId?: number;
}

export class ExportParams extends QueryParams {
  format?: ExportType;
  fileName?: string;
  isPrint? = false;
  isView? = false;
  isRecurring?: boolean;
  isFilter? = false;
  subModuleId?: string;
  userId?: string;
  ids?: Array<number>;
  universalIds?: Array<Guid>;
}

export class DownloadParams extends QueryParams {
  type?: string;
}

export class ProjectTaskParameters extends QueryParams {
  isProjectTask: boolean;
}

export class ProjectParameters extends QueryParams {
  moduleId: number;
}

export class InvoiceParameters extends QueryParams {
  filter: number;
}

export class TimeParameters extends QueryParams {
  userId?: string;
  status?: number;
  projectId?: string;
  pendingApprovalType?: boolean;
}

export class EstimateParameters extends QueryParams {
  filter: number;
  startDate?: any;
  endDate?: any;
}

export class ActivityTaskParameters extends QueryParams {
  entityId?: Guid;
  entityTypeId?: number;
  moduleId: number;
  startDate?: string;
  endDate?: string;
  dateFilter?: number;
  pageNumber?: number;
  pageSize?: number;
}

export class JobUpdatesParameters extends QueryParams {
  monthNumber?: number;
  clientId?: Guid;
  taskId?: Guid;
  pageNumber?: number;
  pageSize?: number;
}

export class ExpensesParameters extends QueryParams {
  userId?: Guid;
  expenseTypeId?: Guid;
  dateFilter?: number;
  status?: number;
}

export class ReportParameters extends ExportParams {
  isClientWiseReport?: boolean;
  isProjectWiseReport?: boolean;
  isTaskWiseReport?: boolean;
  isTeammatesWiseReport?: boolean;
  isExpenseTypeWiseReport?: boolean;
  expenseTypeIds?: Array<string>;
  clientIds?: Array<string>;
  projectIds?: Array<string>;
  taskIds?: Array<string>;
  userIds?: Array<string>;
  status?: number;
}

export class TaskApprovedParameter {
  userId?: string;
  projectId?: number;
  taskId?: number;
  startDate?: string;
  endDate?: string;
}

export class TimelogParameter {
  startDate?: string;
  endDate?: string;
  search?: string;
  status?: number;
  userId?: Guid;
  isDayView?: boolean;
  isSort?: boolean;
}

export class DashboardRequestModel {
  filter: DashboardFilterTypes;
  startDate: string;
  endDate: string;
  userId?: string;
  projectId?: string;
}

export class RecurringInvoiceParameters extends QueryParams {
  isRecurring: boolean;
  filter: number;
}

export class UserExpenseParameters {
  sortBy?: string = '';
  sortOrder?: boolean;
  search?: string = '';
  startDate?: string;
  endDate?: string;
  dateFilter?: number;
}

export class CompanyParameters extends QueryParams {
  companyName: string = '';
}

export class InvoiceOverviewRequestModel {
  dateFilter: number;
  startDate: string;
  endDate: string;
}

export class CalendarDetailParameters {
  userId?: Guid;
  calendarType?: number;
  currentDate?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
}

export class DayTimelogParameters {
  currentDate?: string;
  search?: string;
  status?: number;
  userId?: Guid;
  isSort?: boolean;
}
