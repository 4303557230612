import { ActivityAttachment } from './activity-attachment';

export class ActivityEntity {
  id: number;
  name: string;
  amount: string;
  hasAttachment?: boolean;
  toEmail?: Array<string>;
  note: string;
  providerType: string;
  providerTypeId: number;
  previousStatus: string;
  paymentMethodName: string;
  newStatus: string;
  customMessage: string;
  attachments: Array<ActivityAttachment>;
  code?: string;
}
