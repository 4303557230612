export enum InvoiceAction {
  All = -1,
  Draft = 0,
  Sent = 1,
  Overdue = 2,
  PartiallyPaid = 3,
  Paid = 4,
}

export enum tabSelection {
  Send = 'send',
  SendReminder = 'sendReminder',
  RecordPayment = 'recordPayment',
}

export enum DiscountType {
  Euro = '£',
  Percentage = '%',
}

export enum ColumnSettingType {
  Type = 19,
  JobTask = 20,
  Description = 21,
  HoursAndQuantity = 22,
  Price = 23,
  Amount = 24,
}

export enum InvoiceDetailTypeList {
  Service = 1,
  Expense = 2,
  Adhoc = 3,
}
