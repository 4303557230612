import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TicketDialogComponent } from '../common/ticket-dialog/ticket-dialog.component';

export interface PeriodicElement {
  ticket: string;
  subject: string;
  create: string;
  sender: string;
  status: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    ticket: 'CLI693',
    subject: 'Activity tab is not working.',
    create: '16-Aug-2023',
    sender: 'Mr Anusha',
    status: 'Open',
  },
  {
    ticket: 'CLI694',
    subject: 'I am unable to add & edit client',
    create: '18-Aug-2023',
    sender: 'Dr John smith',
    status: 'Open',
  },
  {
    ticket: 'CLI695',
    subject: 'Activity tab is not working.',
    create: '20-Aug-2023',
    sender: 'Mr Anusha',
    status: 'Open',
  },
];

@Component({
  selector: 'app-client-ticket',
  templateUrl: './client-ticket.component.html',
  styleUrls: ['./client-ticket.component.scss'],
})
export class ClientTicketComponent {
  displayedColumns: string[] = [
    'ticket',
    'subject',
    'create',
    'sender',
    'status',
  ];
  dataSource = ELEMENT_DATA;

  constructor(public dialog: MatDialog) {}

  openDialog() {
    const dialogRef = this.dialog.open(TicketDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
