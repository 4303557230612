export class ConfirmationList {
  id?: any;
  universalId?: string;
  universalId1?: string;
  type: number;
  isArchive?: boolean;
  moduleName: string;
  ids?: any;
  universalIds?: Array<string>;
  startDate?: Date;
  endDate?: Date;
  headerText: string;
  detailText?: string;
  isAddDemoData?: boolean;

  //
  moduleId?: number;
}
