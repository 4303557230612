<div
  class="sidenav-body-content"
  [ngClass]="{
    'mb-4': commentForm.dirty,
    'is-readonly': isViewPermission
  }"
>
  <form [formGroup]="commentForm">
    <div class="form-field textarea mb-2">
      <p>Add Comment &nbsp;<span class="text-red">*</span></p>
      <mat-form-field class="w-636" appearance="fill">
        <textarea
          matInput
          formControlName="message"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="form-field textarea mb-5">
    <p class="mb-1">Past Comments</p>
    <div class="notification w-434">
      <div class="notification-list">
        <div class="notification-list-wrapper">
          <ul>
            <li *ngFor="let comment of commentList">
              <div class="d-flex gap-20">
                <div class="notification-date d-flex flex-column">
                  <span class="date">{{
                    comment.createdOn | date : "MMM dd, y" | slice : 4 : 6
                  }}</span>
                  <span class="month">{{
                    comment.createdOn | date : "MMM d, y" | slice : 0 : 4
                  }}</span>
                </div>
                <div class="notification-body">
                  <div class="media d-flex">
                    <div class="main-avatar avatar-md online">
                      <span
                        class="rounded-img rounded-img-text"
                        *ngIf="!comment.userImage"
                        >{{ comment.fullName | shortName : 2 }}</span
                      >
                      <img
                        *ngIf="comment.userImage"
                        class="rounded-img"
                        [src]="comment.userImage"
                        alt="User"
                      />
                    </div>
                    <div class="main-avatar avatar-md online hidden">
                      <span class="rounded-img rounded-img-text">MP</span>
                    </div>
                    <div class="media-body">
                      <p class="status">
                        <span class="fw-bold">{{ comment.fullName }}</span>
                        <a href="javascript:void(0)">
                          <mat-icon title="add">post_add</mat-icon>
                        </a>
                        <span class="fw-normal">Added Comment</span>
                        <span class="fw-bold"> "{{ comment.message }}" </span>
                      </p>
                      <p class="time text-primary-400 fw-normal m-0">
                        at {{ comment.createdOn | date : "shortTime" }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="notification" *ngIf="commentList.length === 0">
      {{ commonNotificationText.NoRecordsFound }}
    </div>
  </div>
</div>
<div
  class="action-wrapper custom-action-button"
  *ngIf="commentForm.dirty && !isViewPermission"
>
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="submit()"
    >
      Submit
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="cancelClick()"
    >
      Cancel
    </button>
  </div>
</div>
