<div class="d-flex h-100">
  <div class="side-list" [ngClass]="[closeMenu ? 'hide-sidelist' : '']">
    <div class="side-list-wrapper h-100">
      <div
        class="d-flex justify-space-between align-items-center side-list-header"
      >
        <mat-form-field appearance="fill" class="search-field">
          <input
            matInput
            placeholder="Search"
            #searchText
            autocomplete="off"
            (keyup)="onSearch($event)"
          />
          <mat-icon>search</mat-icon>
        </mat-form-field>
      </div>

      <div class="calendar-list-wrapper" (scroll)="onTableScroll($event)">
        <div
          class="calendar-list"
          [ngClass]="this.selectedId === defaultGuidValue ? 'active' : ''"
          (click)="onUserClick()"
        >
          <div class="calendar-list-icon">
            <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
          </div>
          <div class="calendar-list-details">
            <h2>Everyone</h2>
          </div>
        </div>
        <div
          class="calendar-list"
          *ngFor="let user of userList"
          [ngClass]="{
            active: isActive(user)
          }"
        >
          <div class="calendar-list-img">
            <div class="main-avatar avatar-md online">
              <span class="rounded-img rounded-img-text rounded-border"
                >{{ user.name | shortName : 2 }}
              </span>
            </div>
          </div>
          <div class="calendar-list-details" (click)="onUserClick(user)">
            <h2>{{ user.name }}</h2>
            <p>Total Hours: {{ user.totalHours }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="menu-toggle"
  (click)="menuToggle()"
  [ngClass]="closeMenu ? 'toggle-icon' : ''"
>
  <mat-icon>{{
    closeMenu ? "keyboard_arrow_right" : "keyboard_arrow_left"
  }}</mat-icon>
</div>
