<div
  class="sidenav-body-content"
  [ngClass]="{
    'mb-4': jobForm.dirty || jobDetailArray.dirty,
    'is-readonly': isViewPermission
  }"
>
  <form [formGroup]="jobForm">
    <div class="sidenav-body mb-2">
      <div class="d-flex justify-space-between">
        <div class="wp-70">
          <div class="d-flex gap-20">
            <div class="form-field mb-5">
              <p class="fw-bold">Client</p>
              <ng-select
                class="w-295"
                #client
                autoFocus
                (focus)="client.close()"
                (valueChange)="client.close()"
                formControlName="client"
                panelClass="myPanelClass"
                [disableOptionCentering]="true"
                appearance="fill"
                required
              >
                <ng-option
                  *ngFor="let cl of clientList"
                  [value]="cl.universalId"
                  >{{ cl.name }}</ng-option
                >
              </ng-select>
            </div>
            <div class="form-field mb-5">
              <p class="fw-bold">Task</p>
              <ng-select
                class="w-295"
                #task
                autoFocus
                (focus)="task.open()"
                (valueChange)="task.close()"
                formControlName="task"
                panelClass="myPanelClass"
                [disableOptionCentering]="true"
                appearance="fill"
                (change)="onTaskSelectionChange($event)"
                required
              >
                <ng-option
                  *ngFor="let tl of taskList"
                  [value]="tl.universalId"
                  >{{ tl.name }}</ng-option
                >
              </ng-select>
              <div
                class="d-flex justify-space-between assign-user"
                *ngIf="
                  jobForm.controls.client.value !== null &&
                  jobForm.controls.task.value !== null
                "
              >
                <div class="d-flex align-items-center gap-5">
                  <mat-icon fontSet="material-icons-outlined">info</mat-icon
                  >{{ feesTypeDetails }}
                </div>
                <div class="d-flex align-items-center gap-5">
                  <button mat-button disableRipple [matMenuTriggerFor]="menu">
                    <a class="link hyperlink">Change fees type</a>
                  </button>
                  <mat-menu #menu="matMenu" [overlapTrigger]="false">
                    <div
                      class="change-type p-1"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="feesType"
                      >
                        <ng-container *ngFor="let feesType of feesTypeList">
                          <mat-radio-button
                            [checked]="feesType.feeTypeId === defaultSelected"
                            [value]="feesType.feeTypeId"
                            disableRipple
                            class="mb-1"
                            (change)="onFeeTypeChange(feesType)"
                          >
                            <div class="d-flex gap-10">
                              <div class="w-90 pt-10">{{ feesType.name }}</div>
                              <div
                                class="main-contain"
                                *ngIf="
                                  feesType.feeTypeId === feeType.TimeAndFee
                                "
                                (click)="$event.stopPropagation()"
                              >
                                <mat-form-field appearance="fill" class="w-120">
                                  <mat-icon
                                    matPrefix
                                    class="pl-10 font-size-14"
                                    style="line-height: 23px"
                                    >currency_pound</mat-icon
                                  >
                                  <input
                                    matInput
                                    value="£ 10 / Hour"
                                    autocomplete="off"
                                    type="tel"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    formControlName="timeAndFeesPrice"
                                    (click)="
                                      defaultSelected !== feeType.TimeAndFee
                                        ? onFeeTypeChange(feesType)
                                        : ''
                                    "
                                  />
                                  <span matSuffix class="pr-10">/ Hour</span>
                                </mat-form-field>
                              </div>
                              <div
                                class="main-contain"
                                *ngIf="
                                  feesType.feeTypeId === feeType.FixedPrice
                                "
                                (click)="$event.stopPropagation()"
                              >
                                <mat-form-field appearance="fill" class="w-120">
                                  <mat-icon
                                    matPrefix
                                    class="pl-10 font-size-14"
                                    style="line-height: 23px"
                                    >currency_pound</mat-icon
                                  >
                                  <input
                                    matInput
                                    value="£ 1000"
                                    autocomplete="off"
                                    type="tel"
                                    [maxDigits]="8"
                                    appTwoDigitDecimalNumber
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    formControlName="fixedPrice"
                                    (click)="
                                      defaultSelected !== feeType.FixedPrice
                                        ? onFeeTypeChange(feesType)
                                        : ''
                                    "
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                          </mat-radio-button>
                        </ng-container>
                      </mat-radio-group>
                      <div class="text-align-right">
                        <a
                          class="link hyperlink mt-10 text-align-right"
                          (click)="getFeesType()"
                          >Reset</a
                        >
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex gap-20">
            <div class="form-field datepicker">
              <p class="fw-bold">Start Date</p>
              <mat-form-field
                class="w-295"
                appearance="fill"
                (click)="startdate.open()"
              >
                <input
                  matInput
                  formControlName="startDate"
                  readonly
                  [matDatepicker]="startdate"
                  autocomplete="off"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startdate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startdate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-field datepicker">
              <p class="fw-bold">End Date</p>
              <mat-form-field
                class="w-295"
                appearance="fill"
                (click)="enddate.open()"
              >
                <input
                  matInput
                  formControlName="endDate"
                  readonly
                  [matDatepicker]="enddate"
                  autocomplete="off"
                  [min]="jobForm.value.startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="enddate"
                ></mat-datepicker-toggle>
                <mat-datepicker #enddate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex gap-20">
            <div class="form-field">
              <p class="fw-bold">Estimated Time</p>
              <div class="d-flex gap-15 hm-time">
                <mat-form-field appearance="fill" class="w-140">
                  <input
                    matInput
                    autocomplete="off"
                    required
                    numbersOnly
                    maxlength="12"
                    formControlName="estimatedHours"
                  />
                  <span matSuffix>h</span>
                </mat-form-field>
                <mat-form-field appearance="fill" class="w-140">
                  <input
                    matInput
                    autocomplete="off"
                    required
                    numbersOnly
                    maxlength="16"
                    formControlName="estimatedMinutes"
                  />
                  <span matSuffix>m</span>
                </mat-form-field>
              </div>
            </div>
            <div class="form-field">
              <p class="fw-bold">Assign to</p>
              <mat-form-field appearance="fill" class="w-295">
                <mat-select
                  panelClass="checkPanelClass"
                  [disableOptionCentering]="true"
                  [(ngModel)]="selectedUserIds"
                  [ngModelOptions]="{ standalone: true }"
                  multiple
                  placeholder="Users"
                  (selectionChange)="onUserSelectionChange()"
                >
                  <mat-select-trigger class="trigger">
                    Users ({{ selectedUserIds.length }})
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let user of userList"
                    [value]="user.userId"
                    title="{{ user.name }}"
                    [disabled]="user.isDisabled"
                    >{{ user.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <div class="d-flex justify-space-between assign-user mt-10">
                <div class="d-flex align-items-center gap-5"></div>
                <div class="d-flex align-items-center gap-5">
                  <mat-icon>calendar_month</mat-icon
                  ><a class="link hyperlink" (click)="onViewCalendarClick()"
                    >View Calendar</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wp-30">
          <div class="form-field">
            <p class="fw-bold d-flex align-items-center gap-5">ROI</p>
            <div class="roi-detail mb-1">
              <div
                class="d-flex justify-space-between text-primary font-size-14 pb-10"
              >
                <span>Total Fees</span>
                <span class="d-flex align-items-center">
                  £ {{ jobDetailData.totalFeeAmount | number : "1.2-2" }}
                </span>
              </div>
              <div
                class="d-flex justify-space-between text-primary font-size-14 pb-10"
              >
                <span>Spent</span>
                <span class="d-flex align-items-center gap-10">
                  <div class="w-50 grid-progress">
                    <mat-progress-bar
                      mode="determinate"
                      [value]="jobDetailData.totalSpentAmount"
                    ></mat-progress-bar>
                  </div>
                  £
                  {{ jobDetailData.totalSpentAmount | number : "1.2-2" }}</span
                >
              </div>
              <div
                class="d-flex justify-space-between text-primary font-size-14 pb-10"
              >
                <span>Remaining</span>
                <span
                  >£
                  {{
                    jobDetailData.totalRemainingAmount | number : "1.2-2"
                  }}</span
                >
              </div>
              <div
                class="d-flex justify-space-between text-primary font-size-14"
              >
                <span>Cost</span>
                <span class="d-flex align-items-center">
                  £ {{ jobDetailData.totalCostAmount | number : "1.2-2" }}</span
                >
              </div>
            </div>
            <div class="roi-detail">
              <div
                class="d-flex justify-space-between text-primary font-size-14 pb-10"
              >
                <span>Hours Spent</span>
                <span class="d-flex align-items-center">
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="font-size-16"
                    >watch_later</mat-icon
                  >{{ jobDetailData.totalHoursSpent }}</span
                >
              </div>
              <div
                class="d-flex justify-space-between text-primary font-size-14 pb-10"
              >
                <span>Billable</span>
                <span class="d-flex align-items-center">
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="font-size-16"
                    >watch_later </mat-icon
                  >{{ jobDetailData.totalBillableHours }}
                </span>
              </div>
              <div
                class="d-flex justify-space-between text-primary font-size-14"
              >
                <span>Non Billable</span>
                <span class="d-flex align-items-center">
                  <mat-icon
                    fontSet="material-icons-outlined"
                    class="font-size-16"
                    >watch_later </mat-icon
                  >{{ jobDetailData.totalNonBillableHours }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="content-body">
    <p class="font-size-16 text-primary fw-bold">Subtasks</p>
    <div class="sidenav-table sidenav-table-input">
      <div class="mat-table-wrapper contact-detail-table">
        <form [formGroup]="formDetails">
          <ng-container formArrayName="jobDetailArray">
            <table
              mat-table
              [dataSource]="tableDataSource"
              aria-describedby="Job Sub Task Table"
            >
              <ng-container matColumnDef="no">
                <th mat-header-cell *matHeaderCellDef class="w-50"></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  #{{ i + 1 }}
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Subtask</th>

                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-form-field appearance="fill" class="wp-100">
                    <input
                      matInput
                      [value]="element.name"
                      autocomplete="off"
                      formControlName="name"
                    />
                  </mat-form-field>
                </td>
              </ng-container>
              <ng-container matColumnDef="isBillable">
                <th mat-header-cell *matHeaderCellDef class="w-185">
                  <mat-checkbox
                    [(ngModel)]="isAllBillableSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onSelectAllBillable($event)"
                    [disabled]="selectedFeeTypeId === feeType.NonBillable"
                  ></mat-checkbox>
                  Billable
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-checkbox
                    formControlName="isBillable"
                    (change)="onSelectBillable()"
                    [disabled]="selectedFeeTypeId === feeType.NonBillable"
                  ></mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="isCompleted">
                <th mat-header-cell *matHeaderCellDef class="w-185">
                  <mat-checkbox
                    [(ngModel)]="isAllCompleteSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onSelectAllComplete($event)"
                  ></mat-checkbox>
                  Mark as Completed
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-checkbox
                    formControlName="isCompleted"
                    (change)="onSelectComplete()"
                  ></mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="isInclude">
                <th mat-header-cell *matHeaderCellDef class="w-185">
                  <mat-checkbox
                    [(ngModel)]="isAllIncludeSelected"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onSelectAllInclude($event)"
                  ></mat-checkbox>
                  Include
                </th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  [formGroupName]="i"
                >
                  <mat-checkbox
                    formControlName="isInclude"
                    (change)="onSelectInclude()"
                  ></mat-checkbox>
                </td>
              </ng-container>
              <ng-container matColumnDef="close">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="w-30 text-align-center"
                ></th>
                <td
                  mat-cell
                  *matCellDef="let element; let i = index"
                  class="text-align-center"
                >
                  <button
                    class="close-btn"
                    type="button"
                    mat-button
                    disableRipple
                    *ngIf="element.controls.id.value === this.defaultGuid"
                    (click)="onDeleteSubtask(i)"
                  >
                    <mat-icon>highlight_off</mat-icon>
                  </button>
                </td>
              </ng-container>
              <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"
              ></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td
                  class="mat-cell"
                  [ngClass]="'text-align-center'"
                  [attr.colspan]="displayedColumns.length"
                >
                  {{ commonNotificationText.NoRecordsFound }}
                </td>
              </tr>
            </table>
          </ng-container>
        </form>
      </div>
    </div>
    <div class="mb-1 mt-1 d-inline-block">
      <a
        href="javascript:void(0)"
        class="d-flex align-items-center gap-5 hyperlink text-decoration-none"
        (click)="addNewRow()"
      >
        <mat-icon>add</mat-icon>
        <span class="line-height"> Add Subtask</span></a
      >
    </div>
  </div>
  <div class="content-body">
    <app-job-details-user-roi
      [triggerJobId]="triggerROIJobId"
      [triggerJobFeesType]="triggerJobFeesType"
    ></app-job-details-user-roi>
  </div>
</div>
<div
  class="action-wrapper custom-action-button"
  *ngIf="(jobForm.dirty || jobDetailArray.dirty) && !isViewPermission"
>
  <div class="sidenav-body-content">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false)"
    >
      Save
    </button>
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true)"
    >
      Save and Exit
    </button>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      (click)="onCancel(true)"
    >
      Cancel Changes
    </button>
  </div>
</div>
