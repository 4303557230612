import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExportParams1,
  FileImportRequestModel,
  Note,
  ProjectClient,
  ProjectUserDetail,
  QueryParams,
  SideListModel,
  TimelogParameter,
  UserGridModel,
  UserModel,
  UserPermission,
  UserTaskModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable()
export class UserService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'User';

  public userBasicInfoData: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  data: Observable<any> = this.userBasicInfoData.asObservable();

  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  getUserOverview(
    userId: Guid,
    monthNumber: number
  ): Observable<HttpResponse<any>> {
    return this.http.get<any>(
      `${this.apiUrl}${this.routeBase}/getUserOverview/${userId}/${monthNumber}`
    );
  }

  getUserDetailList(
    queryParams: QueryParams
  ): Observable<HttpResponse<UserGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/executeUserList`,
      queryParams,
      httpOptions
    );
  }

  copy(userIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/copyUsersByUniversalId`,
      JSON.stringify(userIds),
      headers
    );
  }

  getUserList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getUsersList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  exportUser(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportUsers`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  deleteUsers(userIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(userIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteUsersByUnivesalId`,
      options
    );
  }

  saveUserBasicInfo(userProject: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveUserBasicInfo`,
      JSON.stringify(userProject),
      headers
    );
  }

  saveCapacityPermission(userProject: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveUserCapacityAndPermission`,
      JSON.stringify(userProject),
      headers
    );
  }

  saveUserProject(userProject: ProjectUserDetail[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/saveProjectForUser`,
      JSON.stringify(userProject),
      headers
    );
  }

  saveUserNotes(userId: Guid, userProject: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createNote/${userId}`,
      JSON.stringify(userProject),
      headers
    );
  }

  getUserDataNotes(userId: Guid): Observable<Array<Note>> {
    return this.http.get<Array<Note>>(
      `${this.apiUrl}${this.routeBase}/getNote/${userId}`
    );
  }

  archiveAndRestoreUser(
    userIds?: Array<Guid>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      ids: userIds,
      universalIds: userIds,
      status: isArchive,
    };

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateUserStatus`,
      JSON.stringify(statusModel),
      headers
    );
  }

  getProjectsByClients(
    userId: Guid,
    searchText: string,
    sortBy: string,
    sortOrder: boolean
  ): Observable<Array<ProjectClient>> {
    const param = {
      userId,
      search: searchText,
      sortBy,
      sortOrder,
    };

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getUserAllClientsWithProjects`,
      JSON.stringify(param),
      headers
    );
  }

  getUserDataByUserId(userId: Guid): Observable<UserModel> {
    return this.http.get<UserModel>(
      `${this.apiUrl}${this.routeBase}/getUserByUniversalId/${userId}`
    );
  }

  getNotesForUser(userId: Guid, searchText: string): Observable<Note[]> {
    return this.http.get<Note[]>(
      `${this.apiUrl}${this.routeBase}/getNote/${userId}?search=${searchText}`
    );
  }

  getAllUsers(): Observable<Array<UserModel>> {
    return this.http.get<Array<UserModel>>(
      `${this.apiUrl}${this.routeBase}/getAllUsers`
    );
  }

  getUserTime(timelogParameter: TimelogParameter): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getUserTimeLogByWeek`,
      JSON.stringify(timelogParameter),
      headers
    );
  }

  updateUserBasicInfo(userData: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateUserBasicInfo`,
      JSON.stringify(userData),
      headers
    );
  }

  downloadUsersNotes(notesId: number): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${this.apiUrl}${this.routeBase}/download/noteAttachment/${notesId}`
    );
  }

  getUserPermissions(): Observable<Array<UserPermission>> {
    return this.http.get<Array<UserPermission>>(
      `${this.apiUrl}${this.routeBase}/getStandardRoles`
    );
  }

  getUserDefaultRate(userId: Guid): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}${this.routeBase}/getDefaultHourlyRate/${userId}`
    );
  }

  importUsers(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate!.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/importUsers`,
      formData
    );
  }

  inviteUser(universalId: Guid): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/inviteUser/${universalId}`,
      headers
    );
  }

  saveUser(user: UserModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/saveUser`,
      JSON.stringify(user),
      headers
    );
  }

  getUsersByPermission(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getUsersByPermission`
    );
  }

  getUserTasksDetails(
    queryParams
  ): Observable<HttpResponse<Array<UserTaskModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<Array<UserTaskModel>>(
      `${this.apiUrl}${this.routeBase}/getUserTasks`,
      queryParams,
      httpOptions
    );
  }

  saveUserTask(userId: Guid, userTask: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/saveUserTasks/${userId}`,
      JSON.stringify(userTask),
      headers
    );
  }

  deleteUserTaskByUserId(userId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(userId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/task/deleteTaskUserByUniversalId`,
      options
    );
  }

  getUserClientsDetails(queryParams): Observable<HttpResponse<any>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getUserClients`,
      queryParams,
      httpOptions
    );
  }

  saveUserClients(userId: Guid, userClients: Array<any>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };

    return this.http.post<boolean>(
      `${this.apiUrl}${this.routeBase}/saveUserClients/${userId}`,
      JSON.stringify(userClients),
      headers
    );
  }

  deleteUserClientByUserId(userId: Guid): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(userId),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/client/deleteClientUserByUniversalId`,
      options
    );
  }

  lockUnlockDashboard(isLocked: boolean): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/lockDashboard/${isLocked}`,
      headers
    );
  }
}
