import { Component, Input } from '@angular/core';
import { SettingsState } from '@app/core/store';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  CommonNotificationText,
  Modules,
  noRecordFound,
} from 'src/app/core/enums';
import { CommonService } from 'src/app/core/services';

@Component({
  selector: 'app-dynamic-GIF',
  templateUrl: './dynamic-GIF.component.html',
  styleUrls: ['./dynamic-GIF.component.scss'],
})
export class DynamicGIFComponent {
  @Input()
  moduleId: number;

  @Input()
  moduleHeaderText: any;

  noRecordFound = noRecordFound.noRecordText;

  modulesEnum = Modules;

  commonNotificationText = CommonNotificationText;

  @Select(SettingsState.getSelectedMenuPermission)
  isViewPermission$: Observable<any>;

  constructor(private commonService: CommonService) {}

  onAddClick(): void {
    this.commonService.onAddRouting(this.moduleId);
  }
}
