<mat-dialog-content class="timesheet">
  <div class="main-contain">
    <div class="sidenav-header">
      <div class="d-flex align-items-center justify-space-between">
        <h2 class="m-0">Add New Job</h2>
        <div>
          <mat-icon class="close" mat-dialog-close>close</mat-icon>
        </div>
      </div>
    </div>
    <form [formGroup]="jobForm">
      <div class="sidenav-body">
        <div>
          <div class="d-flex gap-30">
            <div class="form-field suffix mb-5">
              <p class="fw-bold">Client<span class="text-red">*</span></p>
              <ng-select
                *ngIf="!isAddBulkJob"
                #client
                autoFocus
                (focus)="client.close()"
                (valueChange)="client.close()"
                formControlName="client"
                panelClass="myPanelClass"
                [disableOptionCentering]="true"
                appearance="fill"
                (change)="onClientSelectionChange($event)"
                required
              >
                <ng-option
                  *ngFor="let cl of clientList"
                  [value]="cl.universalId"
                  >{{ cl.name }}</ng-option
                >
              </ng-select>

              <ng-multiselect-dropdown
                *ngIf="isAddBulkJob"
                class="custom-multiselect w-295"
                [placeholder]="'Select Clients'"
                [settings]="dropdownSettings"
                [data]="clientList"
                [(ngModel)]="selectedClientItems"
                [ngModelOptions]="{ standalone: true }"
                (onSelect)="onClientSelect($event)"
                (onDeSelect)="onClientDeSelect($event)"
                (onDeSelectAll)="onDeSelectAllClients()"
                (onSelectAll)="onSelectAllClients($event)"
                [required]="true"
                [ngClass]="setClass()"
              >
              </ng-multiselect-dropdown>
            </div>
            <div class="form-field suffix mb-5">
              <p class="fw-bold">Task<span class="text-red">*</span></p>
              <ng-select
                #task
                autoFocus
                (focus)="task.open()"
                (valueChange)="task.close()"
                formControlName="task"
                panelClass="myPanelClass"
                [disableOptionCentering]="true"
                appearance="fill"
                (change)="onTaskSelectionChange($event)"
                required
              >
                <ng-option
                  *ngFor="let tl of taskList"
                  [value]="tl.universalId"
                  >{{ tl.name }}</ng-option
                >
              </ng-select>

              <div
                class="d-flex justify-space-between assign-user"
                *ngIf="
                  jobForm.controls.client.value !== null &&
                  jobForm.controls.task.value !== null
                "
              >
                <div class="d-flex align-items-center gap-5">
                  <mat-icon fontSet="material-icons-outlined">info</mat-icon
                  >{{ feesTypeDetails }}
                </div>
                <div class="d-flex align-items-center gap-5">
                  <button mat-button [matMenuTriggerFor]="menu" disableRipple>
                    <a class="link hyperlink">Change Fees Type</a>
                  </button>
                  <mat-menu #menu="matMenu" [overlapTrigger]="false">
                    <div
                      class="change-type p-1"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-radio-group
                        aria-label="Select an option"
                        formControlName="feesType"
                      >
                        <ng-container *ngFor="let feesType of feesTypeList">
                          <mat-radio-button
                            [checked]="feesType.feeTypeId === defaultSelected"
                            [value]="feesType.feeTypeId"
                            disableRipple
                            class="mb-1"
                            (change)="onFeeTypeChange(feesType)"
                          >
                            <div class="d-flex gap-10">
                              <div class="w-90 pt-10">{{ feesType.name }}</div>
                              <div
                                class="main-contain"
                                *ngIf="
                                  feesType.feeTypeId === feeType.TimeAndFee
                                "
                                (click)="$event.stopPropagation()"
                              >
                                <mat-form-field appearance="fill" class="w-120">
                                  <mat-icon
                                    matPrefix
                                    class="pl-10 font-size-14"
                                    style="line-height: 23px"
                                    >currency_pound</mat-icon
                                  >
                                  <input
                                    matInput
                                    value="£ 10 / Hour"
                                    autocomplete="off"
                                    type="tel"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    formControlName="timeAndFeesPrice"
                                    (click)="
                                      defaultSelected !== feeType.TimeAndFee
                                        ? onFeeTypeChange(feesType)
                                        : ''
                                    "
                                  />
                                  <span matSuffix class="pr-10">/ Hour</span>
                                </mat-form-field>
                              </div>
                              <div
                                class="main-contain"
                                *ngIf="
                                  feesType.feeTypeId === feeType.FixedPrice
                                "
                                (click)="$event.stopPropagation()"
                              >
                                <mat-form-field appearance="fill" class="w-120">
                                  <mat-icon
                                    matPrefix
                                    class="pl-10 font-size-14"
                                    style="line-height: 23px"
                                    >currency_pound</mat-icon
                                  >
                                  <input
                                    matInput
                                    value="£ 1000"
                                    autocomplete="off"
                                    [maxDigits]="8"
                                    appTwoDigitDecimalNumber
                                    type="tel"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    formControlName="fixedPrice"
                                    (click)="
                                      defaultSelected !== feeType.FixedPrice
                                        ? onFeeTypeChange(feesType)
                                        : ''
                                    "
                                  />
                                </mat-form-field>
                              </div>
                            </div>
                          </mat-radio-button>
                        </ng-container>
                      </mat-radio-group>
                      <div class="text-align-right">
                        <a
                          class="link hyperlink mt-10 text-align-right"
                          (click)="getFeesType()"
                          >Reset</a
                        >
                      </div>
                    </div>
                  </mat-menu>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex gap-30">
            <div class="form-field datepicker">
              <p class="fw-bold">Start Date</p>
              <mat-form-field appearance="fill" (click)="startdate.open()">
                <input
                  matInput
                  formControlName="startDate"
                  readonly
                  [matDatepicker]="startdate"
                  autocomplete="off"
                  required
                  (dateChange)="onStartDateChange()"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="startdate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startdate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-field datepicker">
              <p class="fw-bold">End Date</p>
              <mat-form-field appearance="fill" (click)="enddate.open()">
                <input
                  matInput
                  formControlName="endDate"
                  readonly
                  [matDatepicker]="enddate"
                  autocomplete="off"
                  [min]="jobForm.value.startDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="enddate"
                ></mat-datepicker-toggle>
                <mat-datepicker #enddate></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="d-flex gap-30">
            <div class="form-field">
              <p class="fw-bold">Estimated Time</p>
              <div class="d-flex gap-15 hm-time">
                <mat-form-field appearance="fill" class="w-140">
                  <input
                    matInput
                    autocomplete="off"
                    required
                    numbersOnly
                    maxlength="12"
                    formControlName="estimatedHours"
                  />
                  <span matSuffix>h</span>
                </mat-form-field>
                <mat-form-field appearance="fill" class="w-140">
                  <input
                    matInput
                    autocomplete="off"
                    required
                    numbersOnly
                    maxlength="16"
                    formControlName="estimatedMinutes"
                  />
                  <span matSuffix>m</span>
                </mat-form-field>
              </div>
            </div>
            <div class="form-field">
              <p class="fw-bold">Assign to</p>
              <mat-form-field appearance="fill">
                <mat-select
                  panelClass="checkPanelClass addjob"
                  [disableOptionCentering]="true"
                  [(ngModel)]="selectedUserIds"
                  [ngModelOptions]="{ standalone: true }"
                  multiple
                  placeholder="Users"
                  (selectionChange)="onUserSelectionChange()"
                >
                  <mat-select-trigger class="trigger">
                    Users ({{ selectedUserIds.length }})
                  </mat-select-trigger>
                  <mat-option
                    *ngFor="let user of userList"
                    [value]="user.userId"
                    title="{{ user.name }}"
                    [disabled]="user.isDisabled"
                    >{{ user.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <div class="d-flex justify-space-between assign-user mt-10">
                <div class="d-flex align-items-center gap-5"></div>
                <div class="d-flex align-items-center gap-5">
                  <mat-icon>calendar_month</mat-icon
                  ><a class="link hyperlink" (click)="onViewCalendarClick()"
                    >View Calendar</a
                  >
                </div>
              </div>
            </div>
          </div>

          <mat-checkbox
            class="pt-0 pb-1"
            [(ngModel)]="isRecurringJob"
            [ngModelOptions]="{ standalone: true }"
            *ngIf="isAddBulkJob"
            (click)="onRepeatjobClick()"
            >Recurring Job</mat-checkbox
          >

          <div *ngIf="showRepeatjob">
            <div class="form-field d-flex gap-10">
              <p class="m-0 topspace w-120 pt-10">Repeat this Job</p>
              <div>
                <div
                  class="form-field-wrapper d-flex align-items-center gap-10"
                >
                  <div class="schedule-list d-flex align-items-center gap-10">
                    <ng-select class="w-150" formControlName="recurringType">
                      <ng-option
                        *ngFor="let type of recurringTypeList"
                        [value]="type.value"
                      >
                        {{ type.name }}
                      </ng-option>
                    </ng-select>
                    <p
                      class="m-0 w-50"
                      *ngIf="
                        jobForm.controls.recurringType.value !==
                          recurringType.Never &&
                        jobForm.controls.recurringType.value !==
                          recurringType.Daily &&
                        jobForm.controls.recurringType.value !==
                          recurringType.Monthly
                      "
                    >
                      every
                    </p>
                    <p
                      class="m-0 w-50"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                        recurringType.Monthly
                      "
                    >
                      on the
                    </p>
                  </div>
                  <div
                    class="schedule-list d-flex align-items-center gap-10"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                      recurringType.Monthly
                    "
                  >
                    <ng-select
                      class="w-100"
                      [disableOptionCentering]="true"
                      formControlName="dayOfMonth"
                    >
                      <ng-option *ngFor="let day of dayList" [value]="day.value"
                        >{{ day.lable }}
                      </ng-option>
                    </ng-select>
                    <p class="m-0">Day of The Month</p>
                  </div>

                  <div
                    class="schedule-list d-flex align-items-center gap-10"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                      recurringType.Weekly
                    "
                  >
                    <ng-select
                      class="w-150"
                      [disableOptionCentering]="true"
                      formControlName="dayOfWeek"
                    >
                      <ng-option
                        *ngFor="let week of weekList; let i = index"
                        [value]="i + 1"
                        >{{ week }}
                      </ng-option>
                    </ng-select>
                  </div>

                  <div
                    class="schedule-list d-flex align-items-center gap-10"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                      recurringType.Yearly
                    "
                  >
                    <ng-select
                      class="w-150"
                      [disableOptionCentering]="true"
                      formControlName="monthOfYear"
                    >
                      <ng-option
                        *ngFor="let month of monthList; let i = index"
                        [value]="i + 1"
                        >{{ month }}
                      </ng-option>
                    </ng-select>
                    <p
                      class="m-0 w-50"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                          recurringType.Yearly ||
                        (jobForm.controls.recurringType.value ===
                          recurringType.Custom &&
                          jobForm.controls.scheduleSubPeriod.value ===
                            scheduleSubPeriod.Year)
                      "
                    >
                      on the
                    </p>
                  </div>

                  <div
                    class="schedule-list d-flex align-items-center gap-10"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                      recurringType.Custom
                    "
                  >
                    <mat-form-field class="w-50">
                      <input
                        matInput
                        formControlName="separationCount"
                        oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                        autocomplete="off"
                        required
                        maxlength="500"
                        style="text-align: center"
                      />
                    </mat-form-field>
                    <ng-select
                      class="w-150"
                      [disableOptionCentering]="true"
                      formControlName="scheduleSubPeriod"
                    >
                      <ng-option
                        *ngFor="let type of scheduleSubPeriodList"
                        [value]="type.value"
                      >
                        {{ type.name }}
                      </ng-option>
                    </ng-select>
                    <p
                      class="m-0"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                          recurringType.Custom &&
                        jobForm.controls.scheduleSubPeriod.value ===
                          scheduleSubPeriod.Year
                      "
                    >
                      in
                    </p>
                    <p
                      class="m-0"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                          recurringType.Custom &&
                        jobForm.controls.scheduleSubPeriod.value ===
                          scheduleSubPeriod.Week
                      "
                    >
                      on
                    </p>
                    <p
                      class="m-0 w-50"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                          recurringType.Custom &&
                        jobForm.controls.scheduleSubPeriod.value ===
                          scheduleSubPeriod.Month
                      "
                    >
                      on the
                    </p>
                  </div>
                </div>
                <div
                  class="form-field-wrapper d-flex align-items-center gap-10"
                >
                  <div
                    class="schedule-list d-flex align-items-center gap-10 mt-1"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                        recurringType.Custom &&
                      jobForm.controls.scheduleSubPeriod.value ===
                        scheduleSubPeriod.Week
                    "
                  >
                    <ng-select
                      class="w-150"
                      [disableOptionCentering]="true"
                      formControlName="dayOfWeek"
                    >
                      <ng-option
                        *ngFor="let week of weekList; let i = index"
                        [value]="i + 1"
                        >{{ week }}
                      </ng-option>
                    </ng-select>
                  </div>
                  <div
                    class="schedule-list d-flex align-items-center gap-10 mt-1"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                        recurringType.Custom &&
                      jobForm.controls.scheduleSubPeriod.value ===
                        scheduleSubPeriod.Year
                    "
                  >
                    <ng-select
                      class="w-150"
                      [disableOptionCentering]="true"
                      formControlName="monthOfYear"
                    >
                      <ng-option
                        *ngFor="let month of monthList; let i = index"
                        [value]="i + 1"
                        >{{ month }}
                      </ng-option>
                    </ng-select>
                    <p
                      class="m-0 w-50"
                      *ngIf="
                        jobForm.controls.recurringType.value ===
                          recurringType.Yearly ||
                        (jobForm.controls.recurringType.value ===
                          recurringType.Custom &&
                          jobForm.controls.scheduleSubPeriod.value ===
                            scheduleSubPeriod.Year)
                      "
                    >
                      on the
                    </p>
                  </div>
                  <div
                    class="schedule-list d-flex align-items-center gap-10 mt-1"
                    *ngIf="
                      jobForm.controls.recurringType.value ===
                        recurringType.Yearly ||
                      (jobForm.controls.recurringType.value ===
                        recurringType.Custom &&
                        (jobForm.controls.scheduleSubPeriod.value ===
                          scheduleSubPeriod.Month ||
                          jobForm.controls.scheduleSubPeriod.value ===
                            scheduleSubPeriod.Year))
                    "
                  >
                    <ng-select
                      class="w-100"
                      [disableOptionCentering]="true"
                      formControlName="dayOfMonth"
                    >
                      <ng-option *ngFor="let day of dayList" [value]="day.value"
                        >{{ day.lable }}
                      </ng-option>
                    </ng-select>
                    <p class="m-0">Day of The Month</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-field d-flex align-items-center gap-10">
              <p class="m-0 w-120">Create First Job on</p>
              <div class="d-flex align-items-center gap-10">
                <div class="form-field-wrapper">
                  <div class="schedule-list d-flex align-items-center gap-10">
                    <mat-form-field
                      class="w-150"
                      (click)="onpicker.open()"
                      appearance="fill"
                    >
                      <input
                        matInput
                        readonly
                        [matDatepicker]="onpicker"
                        formControlName="scheduleStartDate"
                        autocomplete="off"
                        required
                        [min]="minStartDate"
                        (dateChange)="setMinEndDate()"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="onpicker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #onpicker></mat-datepicker>
                    </mat-form-field>
                    <p class="m-0 w-50">end</p>
                  </div>
                </div>
                <div class="form-field-wrapper">
                  <div class="schedule-list d-flex align-items-center gap-10">
                    <div class="d-flex align-items-center gap-10">
                      <ng-select
                        class="w-100"
                        [disableOptionCentering]="true"
                        formControlName="scheduleType"
                        (change)="setMinEndDate()"
                      >
                        <ng-option
                          *ngFor="let type of scheduleTypeList"
                          [value]="type.value"
                        >
                          {{ type.name }}
                        </ng-option>
                      </ng-select>
                      <mat-form-field
                        *ngIf="
                          jobForm.controls.scheduleType.value ===
                          scheduleType.On
                        "
                        class="w-150"
                        (click)="onpicker.open()"
                        appearance="fill"
                      >
                        <input
                          matInput
                          readonly
                          [matDatepicker]="onpicker"
                          formControlName="scheduleEndDate"
                          autocomplete="off"
                          required
                          [min]="minEndDate"
                        />
                        <mat-datepicker-toggle
                          matSuffix
                          [for]="onpicker"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #onpicker></mat-datepicker>
                      </mat-form-field>
                      <mat-form-field
                        class="w-50"
                        *ngIf="
                          jobForm.controls.scheduleType.value ===
                          scheduleType.After
                        "
                      >
                        <input
                          matInput
                          formControlName="maxNumOfOccurrences"
                          oninput="this.value = this.value.replace(/[^0-9.]/g,'').replace(/(\..*)\./g, '$1');"
                          autocomplete="off"
                          value="1"
                          required
                          maxlength="500"
                          style="text-align: center"
                        />
                      </mat-form-field>
                    </div>
                    <p
                      class="m-0"
                      *ngIf="
                        jobForm.controls.scheduleType.value ===
                        scheduleType.After
                      "
                    >
                      Job(s)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="justify-content-start">
  <div class="d-flex">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true, false)"
    >
      Save
    </button>
    <button
      *ngIf="getModuleId === modules.Job"
      class="action-button secondary-button mr-1"
      disableRipple
      type="button"
      mat-button
      disableRipple
      (click)="onSave(true, true)"
    >
      Save & View Details
    </button>
    <button
      *ngIf="getModuleId === modules.Job"
      class="action-button secondary-button mr-1"
      disableRipple
      type="button"
      mat-button
      disableRipple
      (click)="onSave(false, false)"
    >
      Save & Add Another
    </button>
    <button
      class="action-button secondary-button"
      mat-dialog-close
      disableRipple
      type="button"
      mat-button
      disableRipple
      (click)="onCancel(true)"
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
