import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  ExpenseReportSummary,
  InvoiceReport,
  InvoiceReportSummary,
  ProjectReport,
  TimeCategoryReport,
  TimeReportModel,
  TimeReportSummary,
  UserReportModel,
} from '../../models';
import { ReportService } from '../../services';

import {
  ExpenseReportList,
  ExportExpenseReport,
  ExportInvoiceReport,
  ExportProjectReport,
  ExportTeammateReport,
  ExportTimeReport,
  ExportUserReport,
  GetExpenseReport,
  GetExpenseReportSummary,
  GetInvoiceReportSummary,
  GetProjectReport,
  GetTeammateReport,
  GetTimeReport,
  GetTimeReportByCategory,
  GetTimeReportSummary,
  InvoiceReportList,
  TimeReportList,
  UserReport,
} from './report.action';

export class ReportStateInfo {
  timeReport: Array<TimeReportModel>;
  invoiceReport: Array<InvoiceReport>;
  teammateReport: Array<InvoiceReport>;
  projectReport: Array<ProjectReport>;
  totalRecord?: number;
  timeReportSummary?: TimeReportSummary;
  expenseReportSummary?: ExpenseReportSummary;
  timeCategoryReport: Array<TimeCategoryReport>;
  invoiceReportSummary?: InvoiceReportSummary;
  exported?: boolean;
  isLastPage?: boolean;
  userReport: Array<UserReportModel>;
  expenseReport?: Array<TimeReportModel>;
}

@State<ReportStateInfo>({
  name: 'report',
  defaults: {
    timeReport: [],
    timeCategoryReport: [],
    expenseReport: [],
    invoiceReport: [],
    projectReport: [],
    teammateReport: [],
    userReport: [],
  },
})
@Injectable()
export class ReportState {
  constructor(private reportService: ReportService) {}

  @Selector()
  static getTimeReport(state: ReportStateInfo) {
    return state.timeReport;
  }

  @Selector()
  static isLastPage(state: ReportStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getProjectReport(state: ReportStateInfo) {
    return state.projectReport;
  }

  @Selector()
  static getExpenseReport(state: ReportStateInfo) {
    return state.timeReport;
  }

  @Selector()
  static getInvocieReport(state: ReportStateInfo) {
    return state.invoiceReport;
  }

  @Selector()
  static getTimeReportSummary(state: ReportStateInfo) {
    return state.timeReportSummary;
  }

  @Selector()
  static getExpenseReportSummary(state: ReportStateInfo) {
    return state.expenseReportSummary;
  }

  @Selector()
  static getInoviceReportSummary(state: ReportStateInfo) {
    return state.invoiceReportSummary;
  }

  @Selector()
  static getTimeCategoryReport(state: ReportStateInfo) {
    return state.timeCategoryReport;
  }

  @Action(GetTimeReport, { cancelUncompleted: true })
  getTimeReport(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetTimeReport
  ) {
    return this.reportService.getTimeReport(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          totalRecord,
          isLastPage,
        });
      })
    );
  }

  @Action(GetProjectReport, { cancelUncompleted: true })
  getProjectReport(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetProjectReport
  ) {
    return this.reportService.getProjectReport(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          projectReport: res.body!,
          totalRecord,
          isLastPage,
        });
      })
    );
  }

  @Action(GetExpenseReport, { cancelUncompleted: true })
  GetExpenseReport(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetExpenseReport
  ) {
    return this.reportService.getExpenseReport(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          totalRecord,
        });
      })
    );
  }

  @Action(ExportTeammateReport)
  exportTeammateReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportTeammateReport
  ) {
    return this.reportService.exportTeammateReport(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(ExportTimeReport)
  exportTimeReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportTimeReport
  ) {
    return this.reportService.exportTimeReport(action.reportExportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(ExportProjectReport)
  exportProjectReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportProjectReport
  ) {
    return this.reportService.exportProjectReport(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(GetTeammateReport, { cancelUncompleted: true })
  getTeammateReport(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetTeammateReport
  ) {
    return this.reportService.getTeammateReport(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          teammateReport: res.body!,
          totalRecord,
          isLastPage,
        });
      })
    );
  }

  @Action(GetTimeReportSummary)
  getTimeReportSummary(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetTimeReportSummary
  ) {
    return this.reportService.getTimeReportSummary().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          timeReportSummary: res,
        });
      })
    );
  }

  @Action(GetExpenseReportSummary)
  getExpenseReportSummary(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetExpenseReportSummary
  ) {
    return this.reportService.getExpenseReportSummary().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          expenseReportSummary: res,
        });
      })
    );
  }
  @Action(GetInvoiceReportSummary)
  getInvoiceReportSummary(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetInvoiceReportSummary
  ) {
    return this.reportService.getInvoiceReportSummary().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          invoiceReportSummary: res,
        });
      })
    );
  }

  @Action(GetTimeReportByCategory, { cancelUncompleted: true })
  getTimeReportByCategory(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: GetTimeReportByCategory
  ) {
    return this.reportService.getTimeReportByCategory(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);

        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          timeCategoryReport: res.body!,
          totalRecord,
        });
      })
    );
  }

  //2.0 states
  @Action(UserReport, { cancelUncompleted: true })
  userReport(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: UserReport
  ) {
    return this.reportService.userReport(action.reportQueryParams).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          userReport: res.body!,
        });
      })
    );
  }

  @Action(ExportUserReport)
  exportUserReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportUserReport
  ) {
    return this.reportService.exportUserReport(action.reportExportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(TimeReportList, { cancelUncompleted: true })
  timeReportList(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: TimeReportList
  ) {
    return this.reportService.timeReportList(action.reportQueryParams).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          timeReport: res.body!,
        });
      })
    );
  }

  @Action(ExpenseReportList, { cancelUncompleted: true })
  expenseReportList(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: ExpenseReportList
  ) {
    return this.reportService.expenseReportList(action.reportQueryParams).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          expenseReport: res.body!,
        });
      })
    );
  }

  @Action(ExportExpenseReport)
  exportExpenseReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportExpenseReport
  ) {
    return this.reportService
      .exportExpenseReport(action.reportExportParams)
      .pipe(
        switchMap((res) => {
          patchState({ exported: true });

          return EMPTY;
        })
      );
  }

  @Action(InvoiceReportList, { cancelUncompleted: true })
  invoiceReportList(
    { getState, setState }: StateContext<ReportStateInfo>,
    action: InvoiceReportList
  ) {
    return this.reportService.invoiceReportList(action.reportParams).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          invoiceReport: res.body!,
        });
      })
    );
  }

  @Action(ExportInvoiceReport)
  exportInvoiceReport(
    { getState, patchState }: StateContext<ReportStateInfo>,
    action: ExportInvoiceReport
  ) {
    return this.reportService
      .exportInvoiceReport(action.reportExportParams)
      .pipe(
        switchMap((res) => {
          patchState({ exported: true });

          return EMPTY;
        })
      );
  }
}
