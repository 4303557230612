import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import {
  ExpenseStatus,
  InvoiceAction,
  Modules,
  RecurringInvoiceAction,
} from 'src/app/core/enums';
import { ActionType } from 'src/app/core/enums/action-type';

@Component({
  selector: 'app-more-grid-actions',
  templateUrl: './more-grid-actions.component.html',
  styleUrls: ['./more-grid-actions.component.scss'],
})
export class MoreGridActionsComponent implements OnInit {
  selectedRowIndex: number = -1;
  isRegularUser = this.globalComponent.isRegularUser();

  @Input()
  moduleId: number;

  @Input()
  element: any;

  @Input()
  gridActions: any;

  @Output()
  readonly triggerActionType = new EventEmitter<any>();

  actions: any = [];
  modules = Modules;

  actionClick(actionType: ActionType) {
    this.triggerActionType.emit(actionType);
  }

  constructor(private globalComponent: GlobalComponent) {}

  ngOnInit(): void {
    switch (this.moduleId) {
      case Modules.Clients:
      case Modules.ExpenseType:
      case Modules.Users:
      case Modules.Tasks:
        this.createManageActions();
        break;

      case Modules.Expenses:
        this.createExpenseActions();
        break;

      case Modules.Jobs:
        this.createJobActions();
        break;

      case Modules.TimeUnsubmitted:
        this.createTimeUnsubmittedActions();
        break;

      case Modules.TimePFA:
        this.createTimePFAActions();
        break;

      case Modules.TimeApproved:
        this.createTimeApprovedActions();
        break;

      case Modules.TimeRejected:
        this.createTimeRejectedActions();
        break;

      case Modules.Invoice:
        this.createInvoiceActions();
        break;

      case Modules.Estimate:
        this.createEstimateActions();
        break;

      case Modules.RecurringInvoice:
        this.createRecurringInvoiceActions();
        break;
      default:
        break;
    }
  }

  createManageActions(): void {
    this.actions = [
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
        isDisabled: false,
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
        isDisabled: false,
      },
      {
        name: this.element.isArchived ? 'Restore' : 'Archive',
        actionType: ActionType.Archive,
        icon: this.element.isArchived ? 'unarchive' : 'archive',
        isDisabled: false,
      },
    ];

    if (this.element.isArchived) {
      this.actions = this.actions.filter(
        (x) => x.actionType !== ActionType.Edit
      );
    }

    if (this.moduleId === Modules.Users) {
      if (!this.element.canDelete) {
        this.actions = this.actions.filter(
          (x) =>
            x.actionType !== ActionType.Delete &&
            x.actionType !== ActionType.Archive
        );
      }
    }

    this.gridActions.forEach((element) => {
      this.actions.forEach((ele) => {
        if (element.name === ele.name) {
          ele.isDisabled = element.isDisabled;
        }
      });
    });
  }

  createExpenseActions(): void {
    this.actions = [
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: 'Reminder',
        actionType: ActionType.Reminder,
        icon: 'alternate_email',
      },
      {
        name: 'Submit',
        actionType: ActionType.Submit,
        icon: 'playlist_add_check',
      },
      {
        name: 'Email',
        actionType: ActionType.Email,
        icon: 'email',
      },
      {
        name: 'Approve',
        actionType: ActionType.Approve,
        icon: 'check_circle_outline',
      },
      {
        name: 'Reject',
        actionType: ActionType.Reject,
        icon: 'playlist_remove',
      },
      {
        name: 'Withdraw',
        actionType: ActionType.WithdrawApproval,
        icon: 'assignment_returned',
      },
    ];

    switch (this.element.status) {
      case ExpenseStatus.Unsubmitted:
        this.actions = this.actions.filter(
          (x) =>
            x.actionType === ActionType.Edit ||
            x.actionType === ActionType.Delete ||
            x.actionType === ActionType.Submit ||
            x.actionType === ActionType.Reminder
        );

        if (this.isRegularUser) {
          this.actions = this.actions.filter(
            (x) => x.actionType !== ActionType.Reminder
          );
        }

        if (this.element.invoiceId) {
          this.actions = this.actions.filter(
            (x) =>
              x.actionType === ActionType.Submit ||
              x.actionType === ActionType.Reminder
          );
        }
        break;

      case ExpenseStatus.PFA:
        this.actions = this.actions.filter(
          (x) =>
            x.actionType === ActionType.Edit ||
            x.actionType === ActionType.Delete ||
            x.actionType === ActionType.Email ||
            x.actionType === ActionType.Approve ||
            x.actionType === ActionType.Reject
        );

        if (this.isRegularUser) {
          this.actions = this.actions.filter(
            (x) =>
              x.actionType !== ActionType.Email &&
              x.actionType !== ActionType.Approve &&
              x.actionType !== ActionType.Reject
          );
        }
        break;

      case ExpenseStatus.Approved:
        this.actions = this.actions.filter(
          (x) => x.actionType === ActionType.WithdrawApproval
        );

        if (this.isRegularUser) {
          this.actions = [];
        }
        break;

      case ExpenseStatus.Rejected:
        this.actions = [];
        break;

      default:
        break;
    }
  }

  createJobActions(): void {
    this.actions = [
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
        isDisabled: false,
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
        isDisabled: false,
      },
    ];

    this.gridActions.forEach((element) => {
      this.actions.forEach((ele) => {
        if (element.name === ele.name) {
          ele.isDisabled = element.isDisabled;
        }
      });
    });
  }

  createTimeUnsubmittedActions(): void {
    this.actions = [
      {
        name: 'Send Reminder',
        actionType: ActionType.Reminder,
        icon: 'email',
      },
      {
        name: 'Submit for Approval',
        actionType: ActionType.Submit,
        icon: 'check_circle',
      },
    ];

    if (this.isRegularUser) {
      this.actions = this.actions.filter(
        (x) => x.actionType !== ActionType.Reminder
      );
    }
  }

  createTimePFAActions(): void {
    this.actions = [
      {
        name: 'Approve',
        actionType: ActionType.Approve,
        icon: 'check_circle',
      },
      {
        name: 'Reject',
        actionType: ActionType.Reject,
        icon: 'playlist_remove',
      },
      {
        name: 'Send Note',
        actionType: ActionType.Email,
        icon: 'edit_note',
      },
    ];

    if (this.isRegularUser) {
      this.actions = [];
    }
  }

  createTimeApprovedActions(): void {
    this.actions = [
      {
        name: 'Withdraw Approval',
        actionType: ActionType.WithdrawApproval,
        icon: 'drive_file_move_rtl',
      },
    ];

    if (this.isRegularUser) {
      this.actions = [];
    }
  }

  createTimeRejectedActions(): void {
    this.actions = [
      {
        name: 'Withdraw Rejection',
        actionType: ActionType.WithdrawRejection,
        icon: 'assignment_returned',
      },
    ];

    if (this.isRegularUser) {
      this.actions = [];
    }
  }

  createInvoiceActions(): void {
    this.actions = [
      {
        name: 'Preview',
        actionType: ActionType.Preview,
        icon: 'visibility',
      },
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: 'Send',
        actionType: ActionType.Send,
        icon: 'send',
      },
      {
        name: 'Duplicate',
        actionType: ActionType.Copy,
        icon: 'content_copy',
      },
      {
        name: 'Export as PDF',
        actionType: ActionType.Export,
        icon: 'save_alt',
      },
      {
        name: 'Print',
        actionType: ActionType.Print,
        icon: 'print',
      },
      {
        name: 'Copy Link',
        actionType: ActionType.CopyLink,
        icon: 'link',
      },
      {
        name: 'Record Payment',
        actionType: ActionType.Payment,
        icon: 'payment',
      },
    ];

    if (this.element.status === InvoiceAction.Paid) {
      this.actions = this.actions.filter(
        (x) => x.actionType !== ActionType.Edit
      );
    }
  }

  createEstimateActions(): void {
    this.actions = [
      {
        name: 'Preview',
        actionType: ActionType.Preview,
        icon: 'visibility',
      },
      {
        name: 'Edit',
        actionType: ActionType.Edit,
        icon: 'edit',
      },
      {
        name: 'Delete',
        actionType: ActionType.Delete,
        icon: 'delete_outline',
      },
      {
        name: 'Send',
        actionType: ActionType.Send,
        icon: 'send',
      },
      {
        name: 'Print',
        actionType: ActionType.Print,
        icon: 'print',
      },
      {
        name: 'Mark as Sent',
        actionType: ActionType.MarkAsSent,
        icon: 'check_circle_outline',
      },
      {
        name: 'Mark as Decline',
        actionType: ActionType.MarkAsDecline,
        icon: 'highlight_off',
      },
      {
        name: 'Mark as Accepted',
        actionType: ActionType.MarkAsAccepted,
        icon: 'beenhere',
      },
      {
        name: 'Copy',
        actionType: ActionType.Copy,
        icon: 'content_copy',
      },
      {
        name: 'Export as PDF',
        actionType: ActionType.ExportAsPdf,
        icon: 'save_alt',
      },
      {
        name: 'Copy Link',
        actionType: ActionType.CopyLink,
        icon: 'link',
      },
    ];
  }

  createRecurringInvoiceActions(): void {
    switch (this.element.recurringStatus) {
      case RecurringInvoiceAction.Active:
        this.actions = [
          {
            name: 'Edit',
            actionType: ActionType.Edit,
            icon: 'edit',
          },
          {
            name: 'Delete',
            actionType: ActionType.Delete,
            icon: 'delete_outline',
          },
          {
            name: 'End',
            actionType: ActionType.End,
            icon: 'not_interested',
          },
          {
            name: 'Pause',
            actionType: ActionType.Pause,
            icon: 'pause_circle',
          },
        ];
        break;

      case RecurringInvoiceAction.Inactive:
        this.actions = [
          {
            name: 'Delete',
            actionType: ActionType.Delete,
            icon: 'delete_outline',
          },
        ];
        break;

      case RecurringInvoiceAction.Paused:
        this.actions = [
          {
            name: 'Delete',
            actionType: ActionType.Delete,
            icon: 'delete_outline',
          },
          {
            name: 'End',
            actionType: ActionType.End,
            icon: 'not_interested',
          },
          {
            name: 'Play',
            actionType: ActionType.Play,
            icon: 'play_circle',
          },
        ];
        break;
      default:
        break;
    }
  }

  onButtonClick(i): void {
    this.selectedRowIndex = i;
  }

  allowCloseOnClickOut(): void {
    this.selectedRowIndex = -1;
  }
}
