import {
  ExportParams,
  ReportExportParams,
  ReportParameters,
  ReportQueryParams,
  TimeReportParameters,
} from '../../models';

export class ExportProjectReport {
  static readonly type = '[REPORT] Export Project Report';

  constructor(public exportParams: ExportParams) {}
}

export class ExportTeammateReport {
  static readonly type = '[REPORT] Export Teammate Report';

  constructor(public exportParams: ExportParams) {}
}

export class GetTimeReport {
  static readonly type = '[REPORT] Get Time Report';

  constructor(public reportParams: ReportParameters) {}
}

export class GetProjectReport {
  static readonly type = '[REPORT] Get Project Report';

  constructor(public reportParams: ReportParameters) {}
}

export class GetExpenseReport {
  static readonly type = '[REPORT] Get Expense Report';

  constructor(public reportParams: ReportParameters) {}
}

export class GetTeammateReport {
  static readonly type = '[REPORT] Get Teammate Report';

  constructor(public reportParams: ReportParameters) {}
}

export class GetTimeReportSummary {
  static readonly type = '[REPORT] Get Time Report Summary';
}

export class GetExpenseReportSummary {
  static readonly type = '[REPORT] Get Expense Report Summary';
}

export class GetInvoiceReportSummary {
  static readonly type = '[REPORT] Get Invoice Report Summary';
}

export class GetTimeReportByCategory {
  static readonly type = '[REPORT] Get Time Report By Category';

  constructor(public reportParams: ReportParameters) {}
}

// 2.0 Actions
export class UserReport {
  static readonly type = '[REPORT] User Report';

  constructor(public reportQueryParams: ReportQueryParams) {}
}

export class ExportUserReport {
  static readonly type = '[REPORT] Export User Report';

  constructor(public reportExportParams: ReportExportParams) {}
}

export class TimeReportList {
  static readonly type = '[REPORT] Time Report';

  constructor(public reportQueryParams: TimeReportParameters) {}
}

export class ExportTimeReport {
  static readonly type = '[REPORT] Export Time Report';

  constructor(public reportExportParams: ReportExportParams) {}
}

export class ExpenseReportList {
  static readonly type = '[REPORT] Expense Report';

  constructor(public reportQueryParams: TimeReportParameters) {}
}

export class ExportExpenseReport {
  static readonly type = '[REPORT] Export Expense Report';

  constructor(public reportExportParams: ReportExportParams) {}
}

export class InvoiceReportList {
  static readonly type = '[REPORT] Invoice Report';

  constructor(public reportParams: TimeReportParameters) {}
}

export class ExportInvoiceReport {
  static readonly type = '[REPORT] Export Invoice Report';

  constructor(public reportExportParams: ReportExportParams) {}
}
