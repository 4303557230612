import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NotificationType } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public subject = new Subject<Notification>();
  public keepAfterRouteChange = true;

  constructor(public router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          // only keep for a single route change
          this.keepAfterRouteChange = false;
        } else {
          // clear alert messages
          this.clear();
        }
      }
    });
  }

  getAlert(): Observable<any> {
    return this.subject.asObservable();
  }

  success(header: string, message: string, keepAfterRouteChange = false) {
    this.showNotification(
      header,
      NotificationType.Success,
      message,
      keepAfterRouteChange
    );
  }

  error(header: string, message: string, keepAfterRouteChange = false) {
    this.showNotification(
      header,
      NotificationType.Error,
      message,
      keepAfterRouteChange
    );
  }

  info(header: string, message: string, keepAfterRouteChange = false) {
    this.showNotification(
      header,
      NotificationType.Info,
      message,
      keepAfterRouteChange
    );
  }

  warn(header: string, message: string, keepAfterRouteChange = false) {
    this.showNotification(
      header,
      NotificationType.Warning,
      message,
      keepAfterRouteChange
    );
  }

  showNotification(
    header: string,
    type: NotificationType,
    message: string,
    keepAfterRouteChange = false
  ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next(<Notification>(
      (<unknown>{ type: type, message: message, header: header })
    ));
  }

  clear() {
    this.subject.next();
  }
}
