<h2 mat-dialog-title>{{ headerText }}</h2>
<mat-dialog-content>
  <div>
    <form [formGroup]="emailForm">
      <div class="form-field textarea">
        <p class="fw-bold">Message</p>
        <mat-form-field appearance="fill">
          <textarea
            matInput
            formControlName="message"
            autocomplete="off"
            required
            maxlength="500"
          ></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="m-0">
  <div>
    <div class="button-container">
      <div class="spinner-container" *ngIf="isButtonDisabled">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onSendClick()"
        [disabled]="isButtonDisabled"
      >
        Send
      </button>
    </div>
    <button
      class="action-button secondary-button"
      type="button"
      mat-button
      disableRipple
      mat-dialog-close
    >
      Cancel
    </button>
  </div>
</mat-dialog-actions>
