<div
  class="sidenav-body-content"
  [ngClass]="{
    'mb-4': emailForm.dirty,
    'is-readonly': isViewPermission
  }"
>
  <form [formGroup]="emailForm">
    <div class="form-field">
      <p class="fw-bold">To &nbsp;<span class="text-red">*</span></p>
      <mat-form-field appearance="fill" class="w-434">
        <input
          matInput
          autocomplete="off"
          required
          maxlength="100"
          formControlName="sentTo"
        />
      </mat-form-field>
      <h5 class="notes-message">
        Separate multiple email addresses using the comma character
      </h5>
    </div>
    <div class="form-field">
      <p class="fw-bold">Subject &nbsp;<span class="text-red">*</span></p>
      <mat-form-field appearance="fill" class="w-434">
        <input
          matInput
          autocomplete="off"
          required
          maxlength="100"
          formControlName="subject"
        />
      </mat-form-field>
    </div>
    <div class="form-field textarea mb-2">
      <p>Message &nbsp;<span class="text-red">*</span></p>
      <mat-form-field class="w-434" appearance="fill">
        <textarea
          matInput
          formControlName="message"
          autocomplete="off"
          required
        ></textarea>
      </mat-form-field>
    </div>
    <div class="form-field attached mb-2">
      <p>Attachment</p>
      <div class="attachFile">
        <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
        <input
          #fileupload
          multiple
          type="file"
          [accept]="acceptedFileTypes"
          formControlName="files"
          autocomplete="off"
          maxlength="100"
          (change)="selectFiles($event)"
          id="file"
          class="cursor-pointer"
        />
        <label for="file" class="cursor-pointer"
          ><strong>Choose a file</strong
          ><span class="dragndrop"> or drag it here</span>.</label
        >
      </div>
    </div>
    <div class="note-table w-434 mb-2">
      <div [ngClass]="fileList.length === 0 ? '' : 'table-border'">
        <div
          class="clickable d-flex align-items-center justify-space-between"
          *ngFor="let file of fileList; let i = index"
        >
          <div>{{ file.name }}</div>
          <div>
            <a href="javascript:void(0)" (click)="removeFile(i)">
              <mat-icon>close</mat-icon>
            </a>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="content-body">
    <div class="form-field">
      <p>History</p>
    </div>
    <div class="sidenav-table sidenav-table-input">
      <div class="mat-table-wrapper contact-detail-table">
        <table
          mat-table
          [dataSource]="emailDetailList"
          aria-describedby="User Task Table"
        >
          <ng-container matColumnDef="senton">
            <th mat-header-cell *matHeaderCellDef>Sent on</th>
            <td mat-cell *matCellDef="let element">
              <span class="white-space-nowrap">
                {{ element.sentOn | date : "dd-MMM-yyyy" }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="sentby">
            <th mat-header-cell *matHeaderCellDef>Sent by</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.sentBy }}">{{
                  element.sentBy
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="sentto">
            <th mat-header-cell *matHeaderCellDef>Sent to</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex send-list onelineclamp align-items-center">
                <span
                  *ngIf="element.sentTo.length === 1"
                  class="onelineclamp"
                  title="{{ element.sentTo }}"
                  >{{ element.sentTo[0] }}
                </span>
                <span class="onelineclamp" *ngIf="element.sentTo.length > 1">
                  <button
                    mat-button
                    class="w-auto h-auto"
                    [matMenuTriggerFor]="menu1"
                    #menuTrigger="matMenuTrigger"
                    disableRipple
                  >
                    <span class="d-flex">
                      <span
                        class="fw-normal inner-width onelineclamp text-align-left"
                        *ngIf="element.sentTo.length >= 1"
                        title="{{ element.sentTo[0] }}"
                      >
                        {{ element.sentTo[0] }}
                      </span>
                      <span class="fw-bold pl-5"
                        >(+{{ element.sentTo.length - 1 }})
                      </span>
                    </span>
                  </button>
                  <mat-menu
                    [overlapTrigger]="false"
                    #menu1="matMenu"
                    class="contact-detail"
                  >
                    <span (click)="$event.stopPropagation()">
                      <div class="sidenav-body-content">
                        <div class="form-field-title">
                          <p class="font-size-16 pb-10">
                            <strong>Emails</strong>
                          </p>
                        </div>
                        <div class="sidenav-table mt-1 wp-100">
                          <div class="mat-table-wrapper tooltip-box">
                            <table
                              [dataSource]="element.sentTo"
                              mat-table
                              aria-describedby="Contact details table"
                            >
                              <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef>Name</th>
                                <td
                                  mat-cell
                                  *matCellDef="let element; let i = index"
                                >
                                  <div class="d-flex">
                                    <span
                                      class="onelineclamp"
                                      title="{{ element }}"
                                      >{{ element }}</span
                                    >
                                  </div>
                                </td>
                              </ng-container>

                              <tr
                                mat-header-row
                                *matHeaderRowDef="
                                  displayedDetailColumns;
                                  sticky: true
                                "
                              ></tr>
                              <tr
                                mat-row
                                *matRowDef="
                                  let row;
                                  columns: displayedDetailColumns
                                "
                              ></tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </span>
                  </mat-menu>
                </span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>Subject</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.subject }}">{{
                  element.subject
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.message }}">{{
                  element.message
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="attachment">
            <th mat-header-cell *matHeaderCellDef>Attachment</th>
            <td mat-cell *matCellDef="let element">
              <div
                class="d-flex align-items-center"
                *ngIf="element.fileUrls?.length > 0"
              >
                <a (click)="downloadFile(element.fileUrls)">
                  <mat-icon>save_alt</mat-icon>
                </a>
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedColumns.length"
            >
              {{ commonNotificationText.NoRecordsFound }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div
  class="action-wrapper custom-action-button"
  *ngIf="emailForm.dirty && !isViewPermission"
>
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="sendEmail()"
    >
      Send Email
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="cancelClick()"
    >
      Cancel
    </button>
  </div>
</div>
