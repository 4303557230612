<div class="content">
  <div class="d-flex h-100">
    <app-calendar-side-list
      (showSideListAction)="showSideListAction($event)"
      (toggleSideList)="toggleSideList()"
      [reloadSideList]="reloadSideList"
      [calendarSideList]="calendarSideList"
      (triggerUserId)="getUserId($event)"
    ></app-calendar-side-list>
    <div class="sidenav-body-content">
      <div class="content-header">
        <div class="content-header-input">
          <button
            class="action-button primary-button"
            mat-button
            disableRipple
            (click)="onJobAdd()"
            *ngIf="!(isViewPermission$ | async)"
          >
            <mat-icon>add</mat-icon>
            <span>Add Job</span>
          </button>
          <mat-form-field appearance="fill" class="search-field">
            <input
              matInput
              placeholder="Search"
              autocomplete="off"
              (keyup)="onSearch($event)"
            />
            <mat-icon>search</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <ejs-schedule
        width="100%"
        height="90%"
        [(currentView)]="currentView"
        (eventRendered)="onEventRendered($event)"
        [selectedDate]="selectedDate"
        [eventSettings]="eventSettings"
        (selectedDateChange)="dateChange($event)"
        (currentViewChange)="currentViewChange($event)"
        (select)="editJob($event)"
        (popupOpen)="onPopupOpen($event)"
        (moreEventsClick)="getJobDetails($event); sideNav.open()"
      >
        <e-views>
          <e-view option="Day"></e-view>
          <e-view option="Week"></e-view>
          <e-view option="Month" [showWeekend]="showWeekend"></e-view>
        </e-views>
      </ejs-schedule>
    </div>
  </div>
</div>
<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer #sideNav class="sidenav-drawer" position="end">
    <div class="sidenav-body-content sidebar-scroll pt-5">
      <div
        class="hr-bottom d-flex justify-space-between align-items-center pb-5"
      >
        <h2 class="font-size-20 text-primary fw-bold m-0">Jobs</h2>
        <button class="action-button" mat-button (click)="sideNav.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div
        class="mat-dialog-wrapper d-flex justify-space-between align-items-center pt-1 pb-1"
      >
        <span class="font-size-16 text-primary">{{ jobDate }}</span>
        <span class="font-size-16 text-primary"
          >Total : {{ dateWiseJobList.length }} Jobs</span
        >
      </div>
      <div
        class="job-side-details d-flex align-items-center justify-space-between"
        *ngFor="let job of dateWiseJobList"
      >
        <div class="wp-100">
          <p class="font-size-12 text-primary">
            <span
              class="status gray"
              *ngIf="job.jobStatus === jobStatus.NotYetStarted"
            ></span>
            <span
              *ngIf="job.jobStatus === jobStatus.Completed"
              class="status green"
            ></span>
            <span
              *ngIf="job.jobStatus === jobStatus.InProgress"
              class="status orange"
            ></span>
            {{ job.jobCode }} - {{ job.clientName }} - {{ job.taskName }}
            <span class="jb-date">
              ({{ job.startDate | date : "dd-MMM-yyyy" }} -
              {{ job.endDate | date : "dd-MMM-yyyy" }})
            </span>
          </p>
        </div>
        <div class="job-side-edit">
          <button type="button" mat-button disableRipple (click)="editJob(job)">
            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
</mat-drawer-container>
