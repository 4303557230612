import { Guid } from 'guid-typescript';
import {
  AddTimeLogModel,
  ApproveTimeLogModel,
  CalendarDetailParameters,
  CopyTimeLogModel,
  DayTimelogParameters,
  DeleteTimelogByWeekModel,
  ExportParams1,
  JobTimelogParams,
  SendEmailModule,
  SubmitTimeLogModel,
  TaskApprovedParameter,
  TimeLogModel,
  TimeParameters,
  TimelogDetailModel,
  TimelogParameter,
  TimesheetExportParams,
  UserTimelogModel,
} from '../../models';

export class GetTimesheetCalendarDetail {
  static readonly type = '[CALENDAR] Get Timesheet Calendar Detail';

  constructor(public userId: Guid, public currentDate: string) {}
}

export class GetCalendar {
  static readonly type = '[CALENDAR] Get Calendar';

  constructor(public calendarDetailParameters: CalendarDetailParameters) {}
}

export class GetTimeLogList {
  static readonly type = '[TIME] Get TimeLog List';

  constructor(public timeParameters: TimeParameters) {}
}

export class CopyTimeLog {
  static readonly type = '[TIME] Copy Time Log';

  constructor(public copyTimeLogModel: CopyTimeLogModel) {}
}

export class GetPendingSummaryData {
  static readonly type = '[TIME] Get Pending Summary Data';

  constructor(public timeParameters: TimeParameters) {}
}

export class WithdrawTimelog {
  static readonly type = '[TIME] Withdraw Timelog';

  constructor(public approveTimeLogModel: Array<ApproveTimeLogModel>) {}
}

export class ApproveTimelog {
  static readonly type = '[TIME] Approve Timelog';

  constructor(public approveTimeLogModel: Array<ApproveTimeLogModel>) {}
}

export class SaveTimelogs {
  static readonly type = '[TIME] Save Timelog';

  constructor(public userTimelogModel: UserTimelogModel) {}
}

export class GetTimeLogDetailsByWeek {
  static readonly type = '[TIME] Get Timelog Details By Week';

  constructor(public timelogDetailModel: TimelogDetailModel) {}
}

export class GetTimeLogDetailsByUniversalId {
  static readonly type = '[TIME] Get TimeLog Details By UniversalId';

  constructor(public universalId: Guid) {}
}

export class SubmitTimelog {
  static readonly type = '[TIME] Submit Timelog';

  constructor(public submitTimeLogModel: Array<SubmitTimeLogModel>) {}
}

export class SendReminderForApproval {
  static readonly type = '[TIME] Send Reminder For Approval';

  constructor(public submitTimeLogModel: Array<SendEmailModule>) {}
}

export class SendEmailNote {
  static readonly type = '[TIME] Send Email Note';

  constructor(public submitTimeLogModel: Array<SendEmailModule>) {}
}

export class RejectTimeLog {
  static readonly type = '[TIME] Reject TimeLog';

  constructor(public submitTimeLogModel: Array<SendEmailModule>) {}
}

export class GetUserWeekTimelogs {
  static readonly type = '[TIME] Get User Week Timelogs';

  constructor(public timelogParams: TimelogParameter) {}
}

export class GetUserDayTimelog {
  static readonly type = '[TIME] Get User Day Timelog';

  constructor(public dayTimelogParameters: DayTimelogParameters) {}
}

export class SendEmailTime {
  static readonly type = '[TIME] Send Email';

  constructor(public sendEmailModule: Array<SendEmailModule>) {}
}

export class DeleteUserTimeLogByWeek {
  static readonly type = '[TIME] Delete User TimeLog By Week';

  constructor(public deleteTimelogModel: DeleteTimelogByWeekModel) {}
}

export class DeleteTimelogByUniversalId {
  static readonly type = '[TIME] Delete Timelog By UniversalId';

  constructor(public universalId: Guid) {}
}

export class GetAlreadyApprovedTaskStatus {
  static readonly type = '[TIME] Get Approved Task Status';

  constructor(public taskApprovedParameter: TaskApprovedParameter) {}
}

export class GetClients {
  static readonly type = '[TIME] Get Clients';

  constructor(public jobId: Guid) {}
}

export class GetTasks {
  static readonly type = '[TIME] Get Tasks';

  constructor(public jobId: Guid, public clientId: Guid) {}
}

export class GetSubTasks {
  static readonly type = '[TIME] Get sub Tasks';

  constructor(public jobId: Guid, public taskId: Guid) {}
}

export class GetUsers {
  static readonly type = '[TIME] Get USers';

  constructor(public jobId: Guid, public clientId: Guid) {}
}

export class SaveTimeLog {
  static readonly type = '[TIME] Save Time Log';

  constructor(public addTimeLogModel: AddTimeLogModel) {}
}

//----------------------------------------------------------

export class GetTimelogByUser {
  static readonly type = '[TIME] Get Timelog By User';

  constructor(public jobTimelogParams: JobTimelogParams) {}
}

export class GetTimelogByWeek {
  static readonly type = '[TIME] Get Timelog By Week';

  constructor(public jobTimelogParams: JobTimelogParams) {}
}

export class GetUsersWhoLoggedTime {
  static readonly type = '[TIME] Get Users Who logged By Time';

  constructor(public jobId: Guid) {}
}

export class SaveWeekTimelog {
  static readonly type = '[TIME] Save Week Timelog';

  constructor(public timeLogModel: TimeLogModel, public status: number) {}
}

export class GetUserWeekTimelogOverview {
  static readonly type = '[TIME] Get User Week Timelog Overview';

  constructor(public timeParameters: TimeParameters) {}
}

export class GetLiveUpdates {
  static readonly type = '[TIME] Get Live Updates';
}

export class ExportTimelog {
  static readonly type = '[TIME] Export Timelog';

  constructor(public exportParams: ExportParams1) {}
}

export class IsTimerRunning {
  static readonly type = '[TIME] Is Timer Running';

  constructor(public isTimerRunning: boolean) {}
}

export class RunningTimerData {
  static readonly type = '[TIME] Running Timer Data';

  constructor(public runningTimerData: any) {}
}

export class SaveWeekTimeLogByUniversalId {
  static readonly type = '[TIME] Save Week TimeLog By UniversalId';

  constructor(public timeLogModel: TimeLogModel, public status: number) {}
}

export class ExportTimesheet {
  static readonly type = '[EXPORT] Export Timesheet';

  constructor(public timesheetExportParams: TimesheetExportParams) {}
}
