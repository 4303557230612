import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExportParams,
  FileImportRequestModel,
  QueryParams,
  SideListModel,
  TaxesModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class TaxesService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  createTaxes(tax: TaxesModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Taxes/createTax`,
      JSON.stringify(tax),
      headers
    );
  }

  updateTax(tax: TaxesModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Taxes/updateTax`,
      JSON.stringify(tax),
      headers
    );
  }

  getTaxesDetailList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<TaxesModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Taxes/executeTaxList`,
      queryParams,
      httpOptions
    );
  }

  getAllTaxes(): Observable<Array<TaxesModel>> {
    return this.http.get<Array<TaxesModel>>(
      `${environment.apiVersionUrl}/Taxes/getAllTaxes`
    );
  }

  getTaxById(taxId: string): Observable<TaxesModel> {
    return this.http.get<TaxesModel>(
      `${environment.apiVersionUrl}/Taxes/getTaxByUniversalId/${taxId}`
    );
  }

  archiveTax(taxId: string, isArchive: boolean): Observable<void> {
    return this.http.put<void>(
      `${environment.apiVersionUrl}/Taxes/archive/${taxId}/${isArchive}`,
      null
    );
  }
  exportTax(exportParams: ExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Taxes/exportTaxes`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getTaxesList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Taxes/getallTaxList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  markAsDefault(taxesId: string, isDefault: boolean): Observable<any> {
    return this.http.put<any>(
      `${environment.apiVersionUrl}/Taxes/MarkDefaultStatus/${taxesId}/${isDefault}`,
      null
    );
  }

  copyTaxes(taxesId?: Array<string>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/Taxes/copyTaxesByUniversalId`,
      JSON.stringify(taxesId),
      headers
    );
  }

  deleteTaxes(taxesIds?: Array<string>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(taxesIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/Taxes/deleteTaxesByUniversalId`,
      options
    );
  }

  getDefaultVatTaxes(): Observable<Array<TaxesModel>> {
    return this.http.get<Array<TaxesModel>>(
      `${environment.apiVersionUrl}/Taxes/getAllDefaultTaxes`
    );
  }

  archiveAndRestoreTaxes(
    taxesId?: Array<string>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      ids: [],
      universalIds: taxesId,
      status: isArchive,
    };

    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Taxes/updateTaxStatus`,
      JSON.stringify(statusModel),
      headers
    );
  }

  importTaxes(fileImportRequestModel: FileImportRequestModel): Observable<any> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate!.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Taxes/importTaxes`,
      formData
    );
  }
}
