import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  Note,
  Profile,
  UserModel,
  UserPermission,
  UserProject,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private http: HttpClient,
    public datepipe: DatePipe,
    private commonService: CommonService
  ) {}

  getProfileSettings(userId: string): Observable<Profile> {
    return this.http.get<Profile>(
      `${environment.apiVersionUrl}/Settings/getUserSettings/${userId}`
    );
  }

  updateProfileBasicInfo(profileData: Profile): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/User/updateTeamBasicInfo`,
      JSON.stringify(profileData),
      headers
    );
  }

  createProfileNotes(userId: string, notes: Note): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/User/createNote/${userId}`,
      JSON.stringify(notes),
      headers
    );
  }

  getProfileNotes(userId: string, searchText: string): Observable<Note[]> {
    return this.http.get<Note[]>(
      `${environment.apiVersionUrl}/User/getNote/${userId}?search=${searchText}`
    );
  }

  updateProfileNotification(profileData: Profile): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/User/updateNotification`,
      JSON.stringify(profileData),
      headers
    );
  }

  getProfileNotification(userId: Guid): Observable<Profile> {
    return this.http.get<Profile>(
      `${environment.apiVersionUrl}/User/getNotification/${userId}`
    );
  }

  updateProfileProject(profileData: Profile[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/projectTeam/save`,
      JSON.stringify(profileData),
      headers
    );
  }
  getProfileProject(): Observable<Profile> {
    return this.http.get<Profile>(
      `${environment.apiVersionUrl}/Profile/list/project`
    );
  }
  createProfileTime(profileData: Profile): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Profile/create/time`,
      JSON.stringify(profileData),
      headers
    );
  }
  updateProfileTime(profileData: Profile): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Profile/update/time`,
      JSON.stringify(profileData),
      headers
    );
  }
  getProfileTime(
    startDate: Date,
    endDate: Date,
    userId: Guid
  ): Observable<any> {
    return this.http.get<any>(
      `${
        environment.apiVersionUrl
      }/User/timelogs?startDate=${this.datepipe.transform(
        startDate,
        'yyyy-MM-dd'
      )}&endDate=${this.datepipe.transform(
        endDate,
        'yyyy-MM-dd'
      )}&userId=${userId}`
    );
  }

  createProfileProjects(userProject: Array<Profile>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/User/saveProjectForTeam`,
      JSON.stringify(userProject),
      headers
    );
  }

  getProfileBasicInfo(userId: Guid): Observable<UserModel> {
    return this.http.get<UserModel>(
      `${environment.apiVersionUrl}/User/getTeamBasicInfo/${userId}`
    );
  }

  getUserPermissions(): Observable<Array<UserPermission>> {
    return this.http.get<Array<UserPermission>>(
      `${environment.apiVersionUrl}/User/list/permissions`
    );
  }

  deleteProfile(userId: Guid): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiVersionUrl}/User/delete/${userId}`
    );
  }

  deleteProfiles(ProfileIds?: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(ProfileIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/User/deleteUsers`,
      options
    );
  }

  saveProfileForProject(ProfileList: Array<UserProject>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/projectTeam/save`,
      JSON.stringify(ProfileList),
      headers
    );
  }

  getProfileByProjectId(
    projectId: number,
    searchText: string
  ): Observable<Array<UserProject>> {
    return this.http.get<Array<UserProject>>(
      `${environment.apiVersionUrl}/Project/allUsersWithProjects/${projectId}?search=${searchText}`
    );
  }

  getProfileDefaultRate(ProfileId: number): Observable<number> {
    return this.http.get<number>(
      `${environment.apiVersionUrl}/Task/projectTask/list/${ProfileId}`
    );
  }

  downloadUsersNotes(notesId: number): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${environment.apiVersionUrl}/User/download/noteAttachment/${notesId}`
    );
  }
}
