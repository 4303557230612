export enum EntityTypes {
  Created = 1,
  Updated = 2,
  Deleted = 3,
  Copy = 4,
  Archived = 5,
  Restored = 6,
  Imported = 7,
  ExportedCSV = 8,
  ExportedEXCEL = 9,
  ExportedPDF = 10,
  NoteCreated = 11,
  Sent = 12,
  Submitted = 13,
  Resubmitted = 14,
  Reminder = 15,
  Approved = 16,
  Withdrawn = 17,
  Status = 18,
  Recurring = 19,
  Recorded = 20,
  DeleteAll = 21,
  RecieptSent = 22,
  Rejected = 23,
  Comment = 24,
  DeletePayment = 25,
  UpdatedBasicInfo = 26,
  UpdatedReminders = 27,
}
