<div class="overview-wrapper timelog-overview">
  <div class="mat-dialog-wrapper form-list-content pb-0 mb-1">
    <div class="main-contain">
      <div class="sidenav-header">
        <div class="d-flex align-items-center justify-space-between">
          <h2>
            {{ timelogHeaderText }}
          </h2>
          <div class="d-flex align-items-center gap-20">
            <form [formGroup]="timesheetForm">
              <mat-form-field (click)="picker.open()" class="w-250">
                <mat-date-range-input [rangePicker]="picker">
                  <input
                    matStartDate
                    matInput
                    [value]="startDate.value"
                    formControlName="start"
                    #start
                    readonly
                  />
                  <input
                    matEndDate
                    matInput
                    [value]="endDate.value"
                    formControlName="end"
                    #end
                    readonly
                    (dateChange)="dateRangeChange(start.value, end.value)"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matPrefix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <div class="mar-daterange-arrow">
                  <button
                    (click)="lessWeek($event)"
                    type="button"
                    disableRipple
                  >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                  <button (click)="addWeek($event)" type="button" disableRipple>
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
              </mat-form-field>
            </form>
            <a
              class="hyperlink"
              href="javascript:void(0)"
              (click)="setDateToToday()"
              >Today</a
            >
            <button
              mat-button
              class="header-text-close d-flex align-items-center"
              disableRipple
              (click)="onCloseClick()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sidenav-body-content pt-0">
    <div class="job-detail-card">
      <div class="job-wrapper">
        <div
          class="job-card d-flex align-items-center justify-space-between pt-0 pb-0"
        >
          <div class="d-flex align-items-center gap-30">
            <div
              class="progress-status text-primary font-size-14 fw-bold pl-2 pr-2"
            >
              <div>Total Hours</div>
              <span class="font-size-20">{{
                timelogOverviewData?.totalHours
              }}</span>
            </div>
            <div id="chart">
              <apx-chart
                [series]="chartOptions.series!"
                [chart]="chartOptions.chart!"
                [plotOptions]="chartOptions.plotOptions!"
                [labels]="chartOptions.labels!"
                [fill]="chartOptions.fill!"
              ></apx-chart>
            </div>
            <div class="pl-1">
              <div class="jb-list mb-10">
                <div class="d-flex align-items-center gap-10 wp-100">
                  <span></span>Billable
                </div>
                <div class="fw-bold white-space-nowrap">
                  {{ timelogOverviewData?.billableHours }}
                </div>
              </div>
              <div class="jb-list">
                <div class="d-flex align-items-center gap-10 wp-100">
                  <span class="gray"></span>Non Billable
                </div>
                <div class="fw-bold white-space-nowrap">
                  {{ timelogOverviewData?.nonBillableHours }}
                </div>
              </div>
            </div>
          </div>
          <div class="pr-4">
            <div class="d-flex align-items-center gap-20 font-size-14">
              <span
                *ngIf="timelogOverviewData?.score === 0"
                class="d-flex align-items-center text-red font-size-16 fw-bold"
                ><mat-icon>arrow_drop_down</mat-icon
                >{{ timelogOverviewData?.totalDifference }}</span
              >
              <span
                *ngIf="timelogOverviewData?.score === 1"
                class="d-flex align-items-center font-size-16 fw-bold"
                ><mat-icon>arrow_drop_up</mat-icon
                >{{ timelogOverviewData?.totalDifference }}</span
              >
              Previous Week
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dash-row">
      <div class="dash-col">
        <div class="overview-wrap detail-wrap mb-1 ml-10 mr-10">
          <div>
            <p>Hours by Jobs</p>
            <app-timelog-overview-jobchart
              *ngIf="timelogOverviewData.jobs"
              [triggerTimelogOverviewData]="triggerTimelogOverviewChartData"
            ></app-timelog-overview-jobchart>
          </div>
          <div class="norecord-chart" *ngIf="!timelogOverviewData.jobs">
            <img src="assets/images/column-icon.svg" alt="Column Chart" />
            <p class="pt-10 no-border">No data available yet!</p>
          </div>
        </div>
      </div>
      <div class="dash-col">
        <div class="overview-wrap detail-wrap mb-1 ml-10 mr-10">
          <div>
            <p>Hours by Clients</p>
            <app-timelog-overview-client
              *ngIf="timelogOverviewData.clients.length"
              [triggerTimelogOverviewData]="triggerTimelogOverviewChartData"
            ></app-timelog-overview-client>
          </div>
          <div
            class="norecord-chart"
            *ngIf="!timelogOverviewData.clients.length"
          >
            <img src="assets/images/column-icon.svg" alt="Pie Chart" />
            <p class="pt-10 no-border">No data available yet!</p>
          </div>
        </div>
      </div>
    </div>

    <div class="overview-wrap timelog-list">
      <app-timesheet-list
        [moduleId]="moduleId"
        [triggerTimehseetListData]="triggerTimehseetListData"
      ></app-timesheet-list>
    </div>
  </div>
</div>
