export class ClientProjectDataTime {
  hours: number;
  isBillable: boolean;
  clientId: string;
  clientName: string;
  projectId: string;
  projectName: string;
  taskId: string;
  taskName: string;
}
