import { Component, EventEmitter, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.scss'],
})
export class AsideComponent {
  showIcon = false;
  showMenuSubscription: Subscription;

  @Output()
  trigger = new EventEmitter();
}
