import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DatePipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  FeeType,
  JobStatusModel,
  Modules,
  NotificationHeader,
  NotificationTextMessage,
} from '@app/core/enums';
import {
  JobFilterTypes,
  JobQueryParams,
  MainListParameters,
} from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { GetStartWeekAndEndWeek, TimeState } from '@app/core/store';
import {
  GetJobDetailList,
  GetJobSubTaskDetailList,
  GetJobSubTaskUserDetailList,
} from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-jobs-info',
  templateUrl: './jobs-info.component.html',
  styleUrls: ['./jobs-info.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class JobsInfoComponent {
  jobList: any[] = [];
  jobSubtaskList: any[] = [];
  jobSubtaskUserList: any[] = [];
  displayedJobColumns: any[] = [];
  displayedSubtaskColumns: any[] = [];
  displayedSubtaskUserColumns: any[] = [];
  displayedJobColumnNames: any[] = [];
  displayedSubtaskColumnNames: any[] = [];
  displayedSubtaskUserColumnNames: any[] = [];
  isAllSelected = false;
  expandedJobElement = null;
  expandedSubtaskElement = null;
  dataSource = new MatTableDataSource();
  noDataFound = false;
  noSubtaskDataFound = false;
  noUserDataFound = false;
  listParameters: MainListParameters = new MainListParameters();
  jobId = Guid.EMPTY as unknown as Guid;
  subTaskId = Guid.EMPTY as unknown as Guid;

  showColumn = false;

  filterTypes = JobFilterTypes;
  filter = 5;
  dateFilter = 5;
  startDate = new FormControl();
  endDate = new FormControl();
  start: string = '';
  end: string = '';
  calendarForm: FormGroup;
  showCalendar = false;
  moduleId = Modules.Clients;
  clientId = Guid.EMPTY as unknown as Guid;
  taskId = Guid.EMPTY as unknown as Guid;
  userId = Guid.EMPTY as unknown as Guid;
  jobInfoSubscription: Subscription;
  feeType = FeeType;
  taskIds: Guid[] = [];

  isTimerRunning: boolean = false;
  runningTimerData: any;

  @ViewChild(MatSort) sort: MatSort;
  jobStatusModel = JobStatusModel;
  @Input()
  triggerId: Observable<any>;

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private store: Store,
    private datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.listParameters.filter = 0;
    this.setForm();

    this.jobInfoSubscription = this.triggerId.subscribe((data) => {
      if (data) {
        this.taskIds = [];
        this.moduleId = data.moduleId;
        switch (this.moduleId) {
          case Modules.Clients:
            this.clientId = data.clientId;
            break;

          case Modules.Tasks:
            this.taskId = data.taskId;
            this.taskIds.push(this.taskId);
            break;

          case Modules.Users:
            this.userId = data.userId;
            break;

          default:
            break;
        }
        this.getJobDetailList();
      }
    });
  }

  ngOnDestroy(): void {
    this.jobInfoSubscription?.unsubscribe();
  }

  setForm(): void {
    this.calendarForm = this.formBuilder.group({
      start: new FormControl<string | null>(''),
      end: new FormControl<string | null>(''),
    });
  }

  getParams() {
    const queryParams: JobQueryParams = {
      jobId: this.jobId,
      subTaskId: this.subTaskId,
      clientId: this.clientId,
      taskIds: this.taskIds,
      userId: this.userId,
      pageNumber: this.listParameters.pageNumber,
      pageSize: this.listParameters.pageSize,
      filter: this.listParameters.filter,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      dateFilter: this.filter,
      startDate:
        this.datepipe.transform(this.start, 'yyyy-MM-dd')?.toString() ?? '',
      endDate:
        this.datepipe.transform(this.end, 'yyyy-MM-dd')?.toString() ?? '',
    };

    return queryParams;
  }

  getJobDetailList(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetJobDetailList(this.getParams()))
      .subscribe((res) => {
        this.spinner.hide();
        const result = {
          data: res.job.jobList.resultSet.data,
          columns: res.job.jobList.resultSet.columns,
        };

        this.jobList = [];
        let columnName = '';
        switch (this.moduleId) {
          case Modules.Clients:
            columnName = 'Client';
            break;

          case Modules.Tasks:
            columnName = 'Task';
            break;

          case Modules.Users:
            columnName = 'Users';
            break;

          default:
            break;
        }
        result.data.forEach((ele, j) => {
          let data: any[] = [];
          result.columns.forEach((element, i) => {
            if (i === 0) {
              data.push(ele[i]);
            }
            if (element.isSelected && element.settingName !== columnName) {
              data.push(ele[i + 1]);
            }
          });
          this.jobList.push(data);
        });

        this.noDataFound = this.jobList.length > 0 ? false : true;
        this.displayedJobColumns = [];
        this.displayedJobColumns.push({
          id: 0,
          isDisabled: false,
          isSelected: true,
          optionId: 0,
          primaryName: '',
          secondaryName: '',
          settingName: 'ID',
          settingTypeId: 0,
          settingValue: '',
        });
        result.columns.forEach((element) => {
          if (element.isSelected && element.settingName !== columnName) {
            this.displayedJobColumns.push(element);
          }
        });

        this.displayedJobColumnNames = this.displayedJobColumns.map(
          (x) => x.settingName
        );
      });
  }

  expandCollapseJob(element): void {
    this.jobId = element[0];
    this.expandedJobElement =
      this.expandedJobElement === element ? null : element;
    this.getJobSubTaskDetailListByJobId();
  }

  getJobSubTaskDetailListByJobId(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetJobSubTaskDetailList(this.getParams()))
      .subscribe((res) => {
        this.jobSubtaskList = res.job.jobSubtaskDetailList.resultSet.data;

        this.dataSource.data = this.jobSubtaskList;

        this.noSubtaskDataFound = this.jobSubtaskList.length > 0 ? false : true;

        this.displayedSubtaskColumns = [];
        this.displayedSubtaskColumns.push({
          name: 'id',
        });
        res.job.jobSubtaskDetailList.resultSet.columns.forEach((element) => {
          this.displayedSubtaskColumns.push(element);
        });

        this.displayedSubtaskColumnNames = this.displayedSubtaskColumns.map(
          (x) => x.name
        );
      });
  }

  expandCollapseSubtask(element): void {
    this.subTaskId = element[0];
    this.expandedSubtaskElement =
      this.expandedSubtaskElement === element ? null : element;
    this.getJobSubTaskUserDetailListByJobId();
  }

  getJobSubTaskUserDetailListByJobId(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetJobSubTaskUserDetailList(this.getParams()))
      .subscribe((res) => {
        this.jobSubtaskUserList =
          res.job.jobSubtaskUserDetailList.resultSet.data;

        this.dataSource.data = this.jobSubtaskUserList;

        this.noUserDataFound =
          this.jobSubtaskUserList.length > 0 ? false : true;

        this.displayedSubtaskUserColumns = [];
        res.job.jobSubtaskUserDetailList.resultSet.columns.forEach(
          (element) => {
            this.displayedSubtaskUserColumns.push(element);
          }
        );

        this.displayedSubtaskUserColumnNames =
          this.displayedSubtaskUserColumns.map((x) => x.name);
      });
  }

  onSelectionChange(val: any): void {
    this.filter = +val;

    if (this.filter === 11) {
      this.showCalendar = true;
      this.getStartWeekAndEndWeek();
    } else {
      this.showCalendar = false;
      this.dataChangeFromHeader();
    }
  }

  getStartWeekAndEndWeek(): void {
    this.store.dispatch(new GetStartWeekAndEndWeek()).subscribe((res) => {
      this.startDate.setValue(
        res.setting.startWeekAndEndWeek.startDate ?? null
      );
      this.endDate.setValue(res.setting.startWeekAndEndWeek.endDate ?? null);

      this.calendarForm = this.formBuilder.group({
        start: new FormControl<Date | null>(this.startDate.value),
        end: new FormControl<Date | null>(this.endDate.value),
      });

      this.dataChangeFromHeader();
    });
  }

  lessWeek(): void {
    this.calendarForm = this.formBuilder.group({
      end: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 1
        )
      ),
      start: new Date(
        new Date(this.calendarForm.controls.start.value).setDate(
          new Date(this.calendarForm.controls.start.value).getDate() - 7
        )
      ),
    });

    this.startDate.setValue(this.calendarForm.controls.start.value);
    this.endDate.setValue(this.calendarForm.controls.end.value);
    this.dataChangeFromHeader();
  }

  addWeek(): void {
    this.calendarForm = this.formBuilder.group({
      start: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 1
        )
      ),
      end: new Date(
        new Date(this.calendarForm.controls.end.value).setDate(
          new Date(this.calendarForm.controls.end.value).getDate() + 7
        )
      ),
    });
  }

  dataChangeFromHeader(): void {
    this.end =
      this.filter < 11
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.end.value,
            'yyyy-MM-dd'
          )!;
    this.start =
      this.filter < 11
        ? ''
        : this.datepipe.transform(
            this.calendarForm.controls.start.value,
            'yyyy-MM-dd'
          )!;

    this.getJobDetailList();
  }

  dateRangeChange(dateRangeStart: any, dateRangeEnd: any): void {
    if (dateRangeStart !== '' && dateRangeEnd !== '') {
      this.dataChangeFromHeader();
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc' ? true : false;
    this.listParameters.sortBy = sortBy;
    this.getJobDetailList();
  }

  toggleList(): void {
    this.showColumn = !this.showColumn;
  }

  onSearch(event: any): void {
    this.listParameters.search = event.target.value;
    this.listParameters.pageNumber = 1;
    this.getJobDetailList();
  }

  onJobEdit(data?: any): void {
    if (!this.checkRunningTimerData(data)) {
      this.commonService.onEditRouting(Modules.Jobs, data[0]);
    }
  }

  checkRunningTimerData(data?: any) {
    this.checkTimerRunning();
    let isDataMatch = false;
    if (this.isTimerRunning) {
      if (data) {
        // action on row
        if (data[0] === this.runningTimerData?.jobId) {
          // timer running and match job
          isDataMatch = true;
        }
      }
    }
    if (isDataMatch) {
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.restrictJobActionOnRunningTimer
      );
    }
    return isDataMatch;
  }

  checkTimerRunning(): void {
    this.isTimerRunning =
      this.store.selectSnapshot(TimeState.isTimerRunning) ?? false;
    if (this.isTimerRunning) {
      this.runningTimerData = this.store.selectSnapshot(
        TimeState.runningTimerData
      );
    } else {
      this.runningTimerData = {};
    }
  }
}
