import { Guid } from 'guid-typescript';
import { ExportParams, QueryParams } from '../../models';
import { FileImportRequestModel } from '../../models/common/import';
import { ExpenseType } from '../../models/expense/expense-type';

export class GetExpenseTypeDetailList {
  static readonly type = '[EXPENSETYPE] Get Expense Type';

  constructor(public queryParams: QueryParams) {}
}

export class SaveExpenseType {
  static readonly type = '[EXPENSETYPE] Save Expense Type';

  constructor(public expenseType: ExpenseType) {}
}

export class SetDefaultExpenseTypeId {
  static readonly type = '[EXPENSETYPE] Set Default Expense Type ID';
}

export class GetExpenseTypeData {
  static readonly type =
    '[EXPENSETYPE] Get ExpenseType Data By Expense Type ID';

  constructor(public expenseTypeId: Guid) {}
}

export class ExportExpenseType {
  static readonly type = '[EXPENSETYPE] Export Expense Type';

  constructor(public exportParams: ExportParams) {}
}

export class DeleteSelectedExpenseType {
  static readonly type = '[EXPENSE_TYPE] Delete Selected Expense Type';

  constructor(public expenseTypeIds?: Array<Guid>) {}
}

export class GetExpenseTypeList {
  static readonly type = '[EXPENSE_TYPE] Get Expense Type Names ';

  constructor(public queryParams: QueryParams) {}
}

export class CopyExpenseType {
  static readonly type = '[EXPENSE_TYPE] Copy Selected Expense Type';

  constructor(public expenseTypeIds?: Array<Guid>) {}
}

export class ArchiveAndRestoreExpenseType {
  static readonly type =
    '[EXPENSE_TYPE] Archive and Restore Selected Expense Type';

  constructor(
    public expenseTypeIds?: Array<Guid>,
    public isArchive?: boolean
  ) {}
}

export class ImportExpenseType {
  static readonly type = '[EXPENSE_TYPE] Import Expense Type';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class GetAllExpenseType {
  static readonly type = '[EXPENSE_TYPE] Get All Expense Type';
}

export class SetExpenseTypeDefaultState {
  static readonly type = '[EXPENSE_TYPE] Set Expense Type Default State';
}
