import { Guid } from 'guid-typescript';

export class TaxesModel {
  id?: number;
  universalId?: string;
  name?: string;
  rate?: number;
  isDefault?: boolean;
  isCompound?: boolean;
  abbrevations?: boolean;
  decription?: boolean;
  isArchived?: boolean;
  totalRecords?: number;
  isSelected?: boolean;
  taxCode?: string;
}

export class AppliedTaxModel {
  id?: number;
  taxId?: Guid;
  rate?: number;
}
