import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CalendarDetailParameters } from '../../models';
import {
  CalendarDetailModel,
  CalendarUserModel,
} from '@app/core/models/calendar';
import { Guid } from 'guid-typescript';
@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: HttpClient) {}
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Calendar';

  getCalendarDetail(
    calendarDetailParameters: CalendarDetailParameters
  ): Observable<HttpResponse<CalendarDetailModel[]>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getCalendar`,
      calendarDetailParameters,
      httpOptions
    );
  }

  getDateWiseJobList(
    date: string,
    userId: Guid
  ): Observable<HttpResponse<CalendarDetailModel[]>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getCalendarDetail?currentDate=${date}&userId=${userId}`,
      httpOptions
    );
  }

  getCalendarUsers(
    calendarDetailParameters: CalendarDetailParameters
  ): Observable<HttpResponse<CalendarUserModel[]>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/getCalendarUsers`,
      calendarDetailParameters,
      httpOptions
    );
  }
}
