import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserWeekTimelogOverviewModel } from '@app/core/models';
import { Observable, Subscription } from 'rxjs';
import {
  ApexChart,
  ChartComponent,
  ApexFill,
  ApexDataLabels,
  ApexLegend,
  ApexAxisChartSeries,
  ApexGrid,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
  grid: ApexGrid;
};

@Component({
  selector: 'app-timelog-overview-client',
  templateUrl: './timelog-overview-client.component.html',
  styleUrls: ['./timelog-overview-client.component.scss'],
})
export class TimelogOverviewClientComponent implements OnInit {
  timelogClientSubscription: Subscription;
  timelogOverviewData = new UserWeekTimelogOverviewModel();
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @Input() triggerTimelogOverviewData: Observable<any>;
  clientTaskDetail: any = [];
  colorList: any = [
    '#AE9AE8',
    '#7B76D3',
    '#F4C64D',
    '#F2816F',
    '#BA5397',
    '#A05195',
    '#D45087',
    '#F95D6A',
    '#FF7C43',
    '#FFA600',
    '#B3D891',
    '#F8C373',
    '#7A5295',
    '#003F5C',
    '#2F4B7C',
    '#665191',
  ];
  ngOnInit(): void {
    this.timelogClientSubscription = this.triggerTimelogOverviewData?.subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.timelogOverviewData = data;

          this.setChartData(data.clients);
        }
      }
    );
  }

  setChartData(data: any): void {
    let tempSeries: any = [];
    data.forEach((element, i) => {
      tempSeries.push({
        name:
          '<span>' +
          element.clientName +
          '</span> <b>: <span> ' +
          element.timeSpent +
          '</span></b>',
        data: [element.loggedHours],
      });
    });
    this.clientTaskDetail = data;
    this.chartOptions = {
      series: tempSeries,
      chart: {
        type: 'bar',
        height: 90,
        stacked: true,
        stackType: '100%',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
        width: 1,
        colors: this.colorList,
      },
      xaxis: {
        categories: [''],
        labels: {
          formatter: function (val) {
            return '';
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return '';
          },
        },
      },
      grid: {
        show: false,
      },
      fill: {
        opacity: 1,
        colors: this.colorList,
      },
      legend: {
        showForSingleSeries: true,
        position: 'bottom',
        offsetX: 0,
        offsetY: 120,
        height: 140,
        floating: true,
        markers: {
          fillColors: this.colorList,
        },
      },
    };
  }

  ngOnDestroy(): void {
    this.timelogClientSubscription?.unsubscribe();
  }
}
