<div class="mat-dialog-wrapper form-list-content">
  <form [formGroup]="noteForm">
    <div class="main-contain">
      <div class="sidenav-header">
        <div class="d-flex align-items-center justify-space-between">
          <h2>
            <span>Add Notes</span>
          </h2>
          <div (click)="onBackClick()">
            <mat-icon class="close">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="notes mt-1">
        <div class="form-field textarea">
          <p>Notes &nbsp;<span class="text-red">*</span></p>
          <mat-form-field appearance="fill">
            <textarea
              matInput
              formControlName="notes"
              autocomplete="off"
              maxlength="500"
              required
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="form-field attached">
        <p>Attachments</p>
        <div class="attachFile">
          <mat-icon fontSet="material-icons-outlined">cloud_upload</mat-icon>
          <input
            #input
            multiple
            type="file"
            [accept]="acceptedFileTypes"
            formControlName="files"
            autocomplete="off"
            maxlength="100"
            (change)="selectFiles($event)"
            id="file"
          />
          <label for="file"><strong>Drop a file/image here</strong></label>
        </div>
      </div>
    </div>
    <div class="note-table" *ngIf="fileUploadResponse?.length">
      <div
        [ngClass]="fileList.length === 0 ? '' : 'table-border'"
        role="presentation"
      >
        <div
          class="clickable d-flex align-items-center justify-space-between"
          *ngFor="let file of fileList; let i = index"
        >
          <div>
            <span class="onelineclamp" title="{{ file.name }}">{{
              file.name
            }}</span>
          </div>
          <div class="text-align-right">
            <button
              type="button"
              mat-button
              disableRipple
              (click)="removeFile(i)"
            >
              <mat-icon class="text-gray-100">highlight_off</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="action-wrapper">
  <div class="sidenav-body-content">
    <div class="d-flex">
      <button
        class="action-button primary-button mr-1"
        type="button"
        mat-button
        disableRipple
        (click)="onAdd()"
      >
        Save
      </button>
      <button
        class="action-button secondary-button"
        type="button"
        (click)="onCancel()"
        mat-button
        disableRipple
      >
        Cancel Changes
      </button>
    </div>
  </div>
</div>
<app-custom-notifications></app-custom-notifications>
