import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NotificationTextMessage } from '@app/core/enums';
import { JobReminderModel, ReminderModel } from '@app/core/models';
import { CommonService } from '@app/core/services';
import { GetJobReminderList, SendJobReminderEmail } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-job-details-reminder',
  templateUrl: './job-details-reminder.component.html',
  styleUrls: ['./job-details-reminder.component.scss'],
})
export class JobDetailsReminderComponent implements OnInit {
  displayedColumns: string[] = [
    'senton',
    'sentby',
    'sentto',
    'subject',
    'message',
  ];

  reminderForm: FormGroup;
  reminderData: ReminderModel;
  reminderList: JobReminderModel[];

  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  reminderId = this.defaultUniversalId;
  jobId = this.defaultUniversalId;
  displaySentToDetailColumns: string[] = ['name'];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private spinner: NgxSpinnerService,
    private store: Store,
    public commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.setForm();
    this.getJobReminderList();
  }

  dataSubmit(): boolean {
    this.spinner.show();
    try {
      this.reminderData = {
        sentTo: this.reminderForm.controls.sentTo.value.split(','),
        subject: this.reminderForm.controls.subject.value,
        message: this.reminderForm.controls.message.value,
      };
    } catch (error) {
      this.spinner.hide();
      this.commonService.onFailure(NotificationTextMessage.errorMessage);
      return false;
    }
    return true;
  }

  getJobReminderList(): void {
    this.store
      .dispatch(new GetJobReminderList(this.jobId))
      .pipe(
        tap((res) => {
          this.reminderList = res.job.reminderList;
        })
      )
      .subscribe();
  }

  sendReminder(): void {
    if (this.reminderForm.invalid) {
      this.commonService.addValidation(this.reminderForm);
      this.commonService.onFailure(NotificationTextMessage.validationMessage);
    } else {
      if (this.dataSubmit()) {
        this.store
          .dispatch(new SendJobReminderEmail(this.jobId, this.reminderData))
          .pipe()
          .subscribe(
            (res) => {
              if (res.job.isRemiderSend) {
                this.commonService.onSuccess(
                  NotificationTextMessage.successMessage
                );
              } else {
                this.commonService.onFailure(
                  NotificationTextMessage.errorMessage
                );
              }
            },
            (err) => {
              this.commonService.onFailure(err.message);
            }
          );
      }
    }
  }

  setForm(): void {
    this.reminderForm = this.formBuilder.group({
      sentTo: new FormControl<string>('', Validators.required),
      subject: new FormControl<string>('', Validators.required),
      message: new FormControl<string>('', Validators.required),
    });
  }
}
