<div class="mat-dialog-wrapper preview-details">
  <mat-dialog-content>
    <form>
      <div class="main-contain">
        <div class="sidenav-header hr-bottom pb-10 mb-10">
          <div class="d-flex align-items-center justify-space-between">
            <h2 class="m-0">
              <span>Capium Pay</span>
            </h2>
            <div mat-dialog-close>
              <mat-icon class="close cursor-pointer">close</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="pay-details w-636">
        <h2 class="text-gray">Register online payments :</h2>
        <p>
          If you want to accept online payments in Time And Fees, you’ll need to
          register your bank account with Capium Pay.
        </p>
        <p>
          It only takes a few minutes to complete the form on the Capium Pay.
          Once registered you’ll be asked to connect your account to Time And
          Fees and as soon as you’ve done this we’ll be able to help you to
          automatically keep track of payments made via Capium Pay.
        </p>
      </div>
      <div
        class="contact-button d-flex justify-content-end align-items-center hr-top pt-1 mt-2 pb-5"
      >
        <button
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="onRegisterClick()"
        >
          <span>Register with Capium Pay</span>
        </button>
      </div>
    </form>
  </mat-dialog-content>
</div>
