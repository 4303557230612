import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  GlobalComponent,
  InvoiceDynamicDetailGrid,
  InvoiceEmailModel,
  InvoiceHistoryModel,
  InvoicePaymentModel,
  Note,
  RecurringInvoices,
  RecurringSchedule,
  ReminderTypeModel,
  SideListModel,
} from '../../models';
import { RecurringInvoicesService } from '../../services';
import {
  ExportRecurringInvoice,
  GetRecurringInvoiceDetailList,
  GetRecurringInvoiceList,
  SaveInvoiceScheduleSetting,
  SaveRecurringInvoice,
  SaveRecurringInvoiceNotes,
  SetRecurringInvoiceDefaultState,
  SetRecurringInvoiceId,
  UpdateRecurringInvoiceStatus,
} from './recurring-invoice.action';

export class RecurringInvoiceStateInfo {
  sideListModel?: Array<SideListModel>;
  isLastPage?: boolean;
  recurringInvoices: Array<RecurringInvoices>;
  totalRecord?: number;
  exported?: boolean;
  recurringInvoiceId?: Guid;
  paymentId?: number;
  schedule: RecurringSchedule;
  recurringInvoicePayments?: Array<InvoicePaymentModel>;
  invoicePaymentData?: InvoicePaymentModel;
  recurringInvoiceNotes?: Array<Note>;
  dueAmount: number;
  isSendEmail: boolean;
  isSendReminder: boolean;
  isRecordPaymentDelete: boolean;
  emailTemplateData: InvoiceEmailModel;
  invoiceHistoryModel: InvoiceHistoryModel;
  reminderTypeModel: Array<ReminderTypeModel>;
  invoiceDynamicDetailGrid: InvoiceDynamicDetailGrid;
  isSendPayment: boolean;
  isDynamicGridSaved: boolean;
  isSaveReminder: boolean;
  highlightIDs?: Array<string>;
  isRecurringInvoiceUpdated: boolean;
  isDataAvailable?: boolean;
  isSuccess?: boolean;
}

@State<RecurringInvoiceStateInfo>({
  name: 'recurringInvoice',
  defaults: {
    sideListModel: [],
    recurringInvoices: [],
    schedule: new RecurringSchedule(),
    dueAmount: 0,
    isSendEmail: false,
    isSendReminder: false,
    emailTemplateData: new InvoiceEmailModel(),
    invoiceHistoryModel: new InvoiceHistoryModel(),
    reminderTypeModel: [],
    isSendPayment: false,
    isSaveReminder: false,
    isDynamicGridSaved: false,
    isRecordPaymentDelete: false,
    isRecurringInvoiceUpdated: false,
    invoiceDynamicDetailGrid: new InvoiceDynamicDetailGrid(),
    isDataAvailable: true,
  },
})
@Injectable()
export class RecurringInvoiceState {
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  constructor(
    private recurringInvoicesService: RecurringInvoicesService,
    public globalComponent: GlobalComponent
  ) {}

  @Selector()
  static getRecurringInvoices(state: RecurringInvoiceStateInfo): any {
    return state.recurringInvoices;
  }

  @Selector()
  static totalRecord(state: RecurringInvoiceStateInfo): any {
    return state.totalRecord;
  }

  @Selector()
  static getRecurringInvoiceList(state: RecurringInvoiceStateInfo): any {
    return state.sideListModel;
  }

  @Selector()
  static getHighlightedIds(state: RecurringInvoiceStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static isLastPage(state: RecurringInvoiceStateInfo): any {
    return state.isLastPage;
  }

  @Selector()
  static getRecurringInvoiceId(state: RecurringInvoiceStateInfo) {
    return state.recurringInvoiceId;
  }

  @Selector()
  static getRecurringInvoiceSchedule(state: RecurringInvoiceStateInfo) {
    return state.schedule;
  }

  @Selector()
  static isDataAvailable(state: RecurringInvoiceStateInfo) {
    return state.isDataAvailable;
  }

  @Action(GetRecurringInvoiceDetailList, { cancelUncompleted: true })
  getRecurringInvoiceDetailList(
    { getState, patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: GetRecurringInvoiceDetailList
  ): any {
    return this.recurringInvoicesService
      .getRecurringInvoiceDetailList(action.recurringInvoiceParams)
      .pipe(
        tap((res) => {
          const state = getState();
          const headers = JSON.parse(res.headers.get('Pagination')!);

          const totalRecord = headers.TotalItemCount;
          const isDataAvailable = headers.IsBusinessDataFound;
          patchState({
            ...state,
            recurringInvoices: res.body!,
            totalRecord,
            isDataAvailable,
          });
        })
      );
  }

  @Action(GetRecurringInvoiceList, { cancelUncompleted: true })
  GetRecurringInvoiceList(
    { getState, patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: GetRecurringInvoiceList
  ): any {
    return this.recurringInvoicesService
      .getRecurringInvoiceList(action.queryParams)
      .pipe(
        tap((res) => {
          const state = getState();
          let recurringInvoicesData: SideListModel[] | null = [];
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isDataAvailable = headers.IsBusinessDataFound;
          const isLastPage = headers.IsLastPage;
          if (
            action.queryParams.pageNumber &&
            action.queryParams.pageNumber > 1
          ) {
            recurringInvoicesData = state.sideListModel!;
            recurringInvoicesData = recurringInvoicesData.concat(res.body!);
          } else {
            recurringInvoicesData = res.body;
          }

          patchState({
            ...state,
            sideListModel: recurringInvoicesData!,
            isLastPage,
            isDataAvailable,
          });
        })
      );
  }

  @Action(SetRecurringInvoiceDefaultState)
  setRecurringInvoiceDefaultState({
    patchState,
  }: StateContext<RecurringInvoiceStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(UpdateRecurringInvoiceStatus)
  updateInvoiceStatus(
    { getState, patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: UpdateRecurringInvoiceStatus
  ): any {
    return this.recurringInvoicesService
      .updateRecurringInvoiceStatus(action.recurringInvoiceIds, action.status)
      .pipe(
        tap((res) => {
          patchState({
            isRecurringInvoiceUpdated: res.isSuccess!,
          });
        })
      );
  }

  @Action(SaveInvoiceScheduleSetting)
  saveInvoiceScheduleSetting(
    { getState, patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: SaveInvoiceScheduleSetting
  ) {
    return this.recurringInvoicesService
      .saveInvoiceScheduleSetting(action.recurringSchedule)
      .pipe(
        tap(() => {
          const state = getState();
          patchState({
            schedule: state.schedule,
          });
        })
      );
  }

  @Action(SaveRecurringInvoice)
  saveRecurringInvoice(
    { patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: SaveRecurringInvoice
  ) {
    return this.recurringInvoicesService
      .saveRecurringInvoice(action.recurringInvoiceBasicInfo)
      .pipe(
        tap((res) => {
          patchState({
            isSuccess: res.isSuccess,
            recurringInvoiceId: res.data,
          });
        })
      );
  }

  @Action(SetRecurringInvoiceId)
  setRecurringInvoiceId(
    { patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: SetRecurringInvoiceId
  ) {
    patchState({
      recurringInvoiceId: action.recurringInvoiceId,
    });
  }

  @Action(ExportRecurringInvoice)
  exportRecurringInvoice(
    { patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: ExportRecurringInvoice
  ) {
    return this.recurringInvoicesService
      .exportRecurringInvoice(action.exportParams)
      .pipe(
        switchMap(() => {
          patchState({ exported: true });

          return EMPTY;
        })
      );
  }

  @Action(SaveRecurringInvoiceNotes)
  saveRecurringInvoiceNotes(
    { getState, patchState }: StateContext<RecurringInvoiceStateInfo>,
    action: SaveRecurringInvoiceNotes
  ) {
    return this.recurringInvoicesService.saveRecurringInvoiceNotes(action.note);
  }
}
