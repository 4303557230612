export enum DashboardWidget {
  TimesheetSummary = 1,
  MostvsLeastCounts = 2,
  TaskWiseHoursDetails = 3,
  TimerWidget = 4,
  OverallHoursDetails = 5,
  BillableNonBillableHours = 6,
  TimeoffByUser = 7,
  JobCounts = 8,
  FeesSummary = 10,
  InvoiceAmountByInvoiceMonth = 11,
  BalanceAmountByDueMonth = 12,
  InvoiceAmountByCategory = 13,
  TopClientsByInvoiceAmount = 14,
  TopClientsWithBalance = 15,
  IncomeTrend = 16,
  RevenueFlow = 17,
  PaymentMethods = 18,
  EstimatesStatus = 19,
  InvoiceStatus = 20,
}

export enum DashboardFilterTypes {
  ThisWeek = 1,
  LastWeek = 2,
  ThisMonth = 3,
  LastMonth = 4,
  ThisQuarter = 5,
  LastQuarter = 6,
  ThisYear = 7,
  LastYear = 8,
  Custom = 9,
}

export enum DecreaseSummaryMessage {
  ThisWeek = 'Decrease from Last Week',
  LastWeek = 'Decrease from This Week',
  ThisMonth = 'Decrease from Last Month',
  LastMonth = 'Decrease from This Month',
  ThisQuarter = 'Decrease from Last Quarter',
  LastQuarter = 'Decrease from This Quarter',
  ThisYear = 'Decrease from Last Year',
  LastYear = 'Decrease from This Year',
}

export enum IncreaseSummaryMessage {
  ThisWeek = 'Increase from Last Week',
  LastWeek = 'Increase from This Week',
  ThisMonth = 'Increase from Last Month',
  LastMonth = 'Increase from This Month',
  ThisQuarter = 'Increase from Last Quarter',
  LastQuarter = 'Increase from This Quarter',
  ThisYear = 'Increase from Last Year',
  LastYear = 'Increase from This Year',
}
