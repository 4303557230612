export class Projects {
  universalId?: string;
  clientId?: string;
  name: string;
  code: string;
  startDate: Date;
  endDate: Date;
  isArchived?: boolean;
  isSelected: boolean;
  taskIdsToAdd: string;
  userIdsToAdd: string;
  taskIdsToRemove: string;
  userIdsToRemove: string;
  taskManagerIdsToRemove: string;
  taskManagerIdsToAdd: string;
  clientName: string;
  isManager: boolean;
  projectTypeId: number;
}
