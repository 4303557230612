<div class="sidenav-body-content pl-0 pr-0 activity-timeline">
  <div class="content-header">
    <div class="content-header-input">
      <mat-form-field appearance="fill" class="search-field">
        <input
          matInput
          placeholder="Search"
          autocomplete="off"
          (keyup)="onSearch($event)"
        />
        <mat-icon>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="content-right-button">
      <div class="sidenav-content">
        <ng-select
          class="w-150"
          #selectType
          (focus)="selectType.open()"
          (valueChange)="selectType.close()"
          [(ngModel)]="selectedModule"
          panelClass="myPanelClass"
          (change)="onActivitySelectionChange($event)"
          [disableOptionCentering]="true"
        >
          <ng-option [value]="0">All</ng-option>
          <ng-option *ngFor="let al of moduleList" [value]="al.id">
            All {{ al.displayName }}
          </ng-option>
        </ng-select>

        <ng-select
          class="w-120 mr-10"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          [(ngModel)]="filter"
          (change)="onSelectionChange($event)"
          #selectTime
          (focus)="selectTime.open()"
          (valueChange)="selectTime.close()"
        >
          <ng-option *ngFor="let filter of filterTypes" [value]="filter.id">
            {{ filter.name }}
          </ng-option>
        </ng-select>
        <form [formGroup]="calendarForm" *ngIf="showCalendar">
          <div class="d-flex align-center">
            <mat-form-field (click)="picker.open()">
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  matInput
                  [value]="startDate.value"
                  formControlName="start"
                  #start
                  readonly
                />
                <input
                  matEndDate
                  matInput
                  [value]="endDate.value"
                  formControlName="end"
                  #end
                  readonly
                  (dateChange)="dateRangeChange(start.value, end.value)"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matPrefix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <div class="mar-daterange-arrow">
                <button (click)="lessWeek()" type="button" disableRipple>
                  <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <button (click)="addWeek()" type="button" disableRipple>
                  <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
              </div>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div>
    <div class="notification">
      <div class="notification-list">
        <mat-accordion [multi]="true">
          <mat-expansion-panel [expanded]="true" *ngFor="let log of activities">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ log.month }} - {{ log.year }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-activity-details
              [getModuleId]="selectedModule"
              [triggerActivityDetails]="triggerActivityDetails"
              [activityDetails]="log"
            ></app-activity-details>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="text-align-center">
        <span *ngIf="!activities?.length">No records found</span>
      </div>
    </div>
  </div>
</div>
