import { ActivityTaskParameters, JobUpdatesParameters } from '../../models';

export class GetActivityList {
  static readonly type = '[ACTIVITY] Get Activity';

  constructor(public queryParams: ActivityTaskParameters) {}
}

export class GetJobUpdates {
  static readonly type = '[ACTIVITY] Get Job Updates';

  constructor(public queryParams: JobUpdatesParameters) {}
}

export class GetAuthorizedMenus {
  static readonly type = '[ACTIVITY] Get authorised menu';
}
