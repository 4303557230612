import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalComponent } from '@app/core/models';
import { HighlightRow } from '@app/core/services';
import { GetJobTimelogChart } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import {
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from 'ng-apexcharts';
import { Observable } from 'rxjs';

export type ChartOptions = {
  series: any;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-job-details-timelog-chart',
  templateUrl: './job-details-timelog-chart.component.html',
  styleUrls: ['./job-details-timelog-chart.component.scss'],
})
export class JobDetailsTimelogChartComponent implements OnInit {
  @ViewChild('chart') chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  chartColor = this.globalComponent.themecolor();
  jobId = Guid.EMPTY as unknown as Guid;
  @Input()
  triggerJobIdToChart: Observable<any>;
  jobTimelogChartData: any = [];
  constructor(
    public globalComponent: GlobalComponent,
    private highlightRow: HighlightRow,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.triggerJobIdToChart.subscribe((data) => {
      if (data) {
        this.jobId = data;
        this.getJobTimelogChart();
      }
    });
    this.highlightRow.defaultColor.subscribe((x) => {
      this.chartColor = x;
    });
  }

  getJobTimelogChart(): void {
    this.store.dispatch(new GetJobTimelogChart(this.jobId)).subscribe((res) => {
      this.jobTimelogChartData = res.job.jobTimelogChartData;
      if (this.jobTimelogChartData) {
        this.chartData(this.jobTimelogChartData);
      } else {
        this.jobTimelogChartData = { series: [], categories: [] };
        this.chartData({ series: [], categories: [] });
      }
    });
  }

  chartData(data?: any): void {
    this.chartOptions = {
      series: data.series,
      chart: {
        type: 'bar',
        height: 300,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '35%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="custom-tooltip">' +
            '<div class="tooltip-title"> ' +
            w.config.series[seriesIndex].monthDate[dataPointIndex] +
            ' (' +
            w.config.series[seriesIndex].name +
            ') ' +
            '</div>' +
            '<div class="tooltip-detail"><div>Hours spent </div>' +
            '<div>' +
            w.config.series[seriesIndex].data[dataPointIndex] +
            '</div>' +
            '</div>' +
            '<div class="tooltip-detail"><div>Billable hours </div>' +
            '<div>' +
            w.config.series[seriesIndex].billableHours[dataPointIndex] +
            '</div>' +
            '</div>' +
            '</div>'
          );
        },
      },
      legend: {
        show: false,
        markers: {
          fillColors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
        },
      },
      stroke: {
        show: true,
        width: 3,
        colors: ['transparent'],
      },
      xaxis: {
        categories: data.categories,
      },
      fill: {
        opacity: 1,
        colors: ['#7B76D3', '#F4C64D', '#F2816F', '#BA5397'],
      },
    };
  }
}
