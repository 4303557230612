import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[numbersOnly]',
})
export class NumbersOnlyDirective {
  // tslint:disable-next-line:variable-name
  constructor(private _el: ElementRef) {}

  // tslint:disable-next-line:typedef
  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/\D*/g, ''); //'\D' for '[^0-9]'.
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
