import { InvoiceOverviewRequestModel } from '@app/core/models';

export class GetInvoiceOverviewCardData {
  static readonly type = '[Overview] Get Invoice Overview Card Data';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetInvoiceAmountByInvoiceMonth {
  static readonly type = '[Overview] Get Invoice Amount By Invoice Month';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetInvoiceBalanceAmountByDueMonth {
  static readonly type = '[Overview] Get Invoice Balance Amount By Due Month';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetEstimateCountByStatus {
  static readonly type = '[Overview]  Estimate Chart Overview';
  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetInvoiceAmountByCategory {
  static readonly type = '[Overview] Get Invoice Amount By Category';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetClientByInvoiceAmount {
  static readonly type = '[Overview] Get Client By Invoice Amount';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetPaymentAmountByPaymentMethod {
  static readonly type = '[Overview] Get Payment Amount By Payment Method';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetClientBalanceByInvoiceId {
  static readonly type = '[Overview] Get Client Balance By InvoiceId';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetInvoiceIncomeTrend {
  static readonly type = '[Overview] Get Invoice Income Trend';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetRevenueFlow {
  static readonly type = '[Overview] Get Revenue Flow';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}

export class GetInvoiceCountByStatus {
  static readonly type = '[Overview] Get Invoice Count By Status';

  constructor(
    public invoiceOverviewRequestModel: InvoiceOverviewRequestModel
  ) {}
}
