import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  SetDefaultClientId,
  SetDefaultExpenseTypeId,
  SetDefaultTaskId,
  SetSubMenuData,
  SettingsState,
} from '@app/core/store';
import { JobState } from '@app/core/store/job';
import { AddJobsComponent } from '@app/modules/home/jobs/add-jobs/add-jobs.component';
import { Select, Store } from '@ngxs/store';
import { Buffer } from 'buffer';
import { Guid } from 'guid-typescript';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  Modules,
  NotificationHeader,
  NotificationTextMessage,
  PermissionType,
  RoutingPath,
  SortOrdering,
} from '../../enums';
import {
  CompanyModel,
  CompanyParameters,
  Country,
  Currency,
  EmailExistModel,
  FileDownloadRequest,
  FileUploadRequestModel,
  MenuItem,
  MultiFileDownloadRequest,
  NameExistModel,
  ProviderAccountModel,
  QueryParams,
  SaveImportRequestModel,
  SideGridModel,
  TitleModule,
  TshqRequestOptions,
} from '../../models';
import { GlobalComponent } from '../../models/common/global-component';
import { HighlightRow } from '../shared';
import { NotificationService } from './notification-service.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  http: HttpClient;
  highlightRow: HighlightRow;
  notifier: NotificationService;
  clipboardService: ClipboardService;
  router: Router;
  dialog: MatDialog;
  store: Store;

  @Select(SettingsState.getMenus)
  menuList$: Observable<Array<MenuItem>>;

  constructor(
    private injector: Injector,
    private globalComponent: GlobalComponent
  ) {
    this.http = this.injector.get<HttpClient>(HttpClient);
    this.highlightRow = this.injector.get<HighlightRow>(HighlightRow);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.clipboardService =
      this.injector.get<ClipboardService>(ClipboardService);
    this.router = this.injector.get<Router>(Router);
    this.dialog = this.injector.get<MatDialog>(MatDialog);
    this.store = this.injector.get<Store>(Store);
  }

  private tenantName = 'Time And Fee';
  public defaultGuidValue = Guid.EMPTY as unknown as Guid;
  public isEmailValid = true;
  public isPhoneValid = true;
  private selectedSubject = new Subject<any>();
  defaultUniversalId = this.globalComponent.getDefaultUniversalId();

  private showTimer: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showTimer$ = this.showTimer.asObservable();

  private reloadPage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public reloadPage$ = this.reloadPage.asObservable();

  private triggerHeaderLogo: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public triggerHeaderLogo$ = this.triggerHeaderLogo.asObservable();

  headerLogoUpdate(value): void {
    this.triggerHeaderLogo.next(value);
  }

  showTimerPopup(value): void {
    this.showTimer.next(value);
  }

  reloadTimesheetPage(value): void {
    this.reloadPage.next(value);
  }

  setTenantName(name: string): void {
    this.tenantName = name;
  }

  getTenantName(): string {
    return this.tenantName;
  }

  convertStringToGuid(guid: string): Guid {
    return guid as unknown as Guid;
  }

  onSucess(): void {
    this.notifier.success(
      NotificationHeader.success,
      NotificationTextMessage.successMessage
    );
  }

  addValidation(form: any): void {
    (Object as any).values(form.controls).forEach((c) => {
      c.markAsTouched();
    });
  }

  getCountryList(): Observable<Array<Country>> {
    return this.http.get<Array<Country>>(
      `${environment.apiVersionUrl}/Country/list`
    );
  }

  getCompanyList(
    queryParams: CompanyParameters
  ): Observable<Array<CompanyModel>> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Business/getCompany?companyName=${queryParams.companyName}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}`
    );
  }

  getTitleList(): Observable<Array<TitleModule>> {
    return this.http.get<Array<TitleModule>>(
      `${environment.apiVersionUrl}/Business/getTitles`
    );
  }

  GetAllIntegratedMailIds(): Observable<Array<ProviderAccountModel>> {
    return this.http.get<Array<ProviderAccountModel>>(
      `${environment.apiVersionUrl}/ProviderAccount/getAllMails`
    );
  }

  getCurrencyList(): Observable<Array<Currency>> {
    return this.http.get<Array<Currency>>(
      `${environment.apiVersionUrl}/Currency/list`
    );
  }

  sendMessage(message: string) {
    this.selectedSubject.next(message);
  }

  clearMessages() {
    this.selectedSubject.next();
  }

  onMessage(): Observable<any> {
    return this.selectedSubject.asObservable();
  }

  sendThemeMessage(message: string) {
    this.selectedSubject.next(message);
  }

  clearThemeMessages() {
    this.selectedSubject.next();
  }

  onThemeMessage(): Observable<any> {
    return this.selectedSubject.asObservable();
  }

  fileUpload(fileUploadRequestModel: FileUploadRequestModel): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'userId',
      (fileUploadRequestModel.userId ?? this.defaultUniversalId).toString()
    );
    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}/FileUpload/multiFileuploadFiles`,
      formData
    );
  }

  uploadFileAttachements(
    fileUploadRequestModel: FileUploadRequestModel
  ): Observable<any> {
    const formData = new FormData();

    fileUploadRequestModel.file.forEach((x) =>
      formData.append('files', x, x.name)
    );

    formData.append(
      'userId',
      (fileUploadRequestModel.userId ?? this.defaultUniversalId).toString()
    );
    formData.append(
      'attachmentType',
      fileUploadRequestModel.attachmentType.toString()
    );

    return this.http.post<any>(
      `${environment.apiVersionUrl}/FileUpload/uploadFileAttachements`,
      formData
    );
  }

  postExecuteRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<any>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'json',
      observe: 'response',
    });
  }

  postExportRequest(
    endpoint: string,
    params?: any | null,
    options?: TshqRequestOptions
  ): Observable<HttpResponse<Blob>> {
    return this.http.post(endpoint, params, {
      ...options,
      responseType: 'blob',
      observe: 'response',
    });
  }

  download(response: HttpResponse<Blob>): void {
    const fileName = response.headers
      .get('Content-Disposition')
      ?.split(';')
      .map((x) => (x ?? '').trimStart().split('='))
      .find((x) => x[0] === 'filename')
      ?.pop();

    const a = document.createElement('a');
    let navigator: any;
    navigator = window.navigator;

    if (navigator?.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(response, fileName);
    } else {
      a.href = URL.createObjectURL(response.body || new Blob());
      a.download = fileName ?? '';
      a.click();
    }
  }

  downloadFile(fileDownloadRequest: FileDownloadRequest): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}/FileUpload/download`,
      fileDownloadRequest,
      headers
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of();
      })
    );
  }

  print(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const blobUrl = URL.createObjectURL(file);
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrl;
    document.body.appendChild(iframe);
    iframe.contentWindow?.print();
  }

  view(response: HttpResponse<Blob>): void {
    const body: Blob = response.body || new Blob();
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  }

  sendEmail(id: number, message?: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Email/send/${id}/${message}`
    );
  }

  updateThemeColor(themecolor: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/User/updateThemeColor`,
      JSON.stringify(themecolor),
      headers
    );
  }

  IsDataFound(moduleId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.apiVersionUrl}/Business/isBusinessDataFound/${moduleId}`
    );
  }

  isNameExist(reqBody: NameExistModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/Business/isNameExist`,
      JSON.stringify(reqBody),
      headers
    );
  }

  isEmailExist(reqBody: EmailExistModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/User/isEmailExist`,
      JSON.stringify(reqBody),
      headers
    );
  }

  saveModuleRequest(reqBody: SaveImportRequestModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post<any>(
      `${environment.apiVersionUrl}/User/saveModuleRequest`,
      JSON.stringify(reqBody),
      headers
    );
  }

  copyLink(body: Blob): void {
    const file = new Blob([body], {
      type: 'application/pdf',
    });
    const fileURL = URL.createObjectURL(file);
    this.clipboardService.copyFromContent(fileURL);
  }

  onViewRouting(moduleId: number, id: any, isViewMode: string) {
    let params: any;
    params = {
      id: Buffer.from(id).toString('base64'),
      view: Buffer.from(isViewMode).toString('base64'),
    };

    switch (moduleId) {
      case Modules.Invoice:
        this.router.navigate([RoutingPath.addInvoice, params]);
        break;

      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.addRecurringInvoice, params]);
        break;
    }
  }

  onEditRouting(moduleId: number, id: any, tabId?: number): void {
    let params: any;
    if (tabId !== undefined) {
      params = { id: Buffer.from(id).toString('base64'), tabId: tabId };
    } else {
      params = { id: Buffer.from(id).toString('base64') };
    }

    switch (moduleId) {
      case Modules.Clients:
        this.router.navigate([RoutingPath.addClient, params]);
        break;

      case Modules.ExpenseType:
        this.router.navigate([RoutingPath.addExpenseType, params]);
        break;

      case Modules.Tasks:
        this.router.navigate([RoutingPath.addTask, params]);
        break;

      case Modules.Users:
        this.router.navigate([RoutingPath.addUser, params]);
        break;

      case Modules.Expenses:
        this.router.navigate([RoutingPath.addExpense, params]);
        break;

      case Modules.Jobs:
      case Modules.JobsCalendar:
        this.router.navigate([RoutingPath.jobDetails, params]);
        break;

      case Modules.Invoice:
        this.router.navigate([RoutingPath.addInvoice, params]);
        break;

      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.addRecurringInvoice, params]);
        break;

      case Modules.Estimate:
        this.router.navigate([RoutingPath.addEstimate, params]);
        break;
    }
  }

  onAddRouting(moduleId: number): void {
    switch (moduleId) {
      case Modules.Clients:
        this.store.dispatch(SetDefaultClientId);
        this.router.navigate([RoutingPath.addClient]);
        break;

      case Modules.ExpenseType:
        this.store.dispatch(SetDefaultExpenseTypeId);
        this.router.navigate([RoutingPath.addExpenseType]);
        break;

      case Modules.Tasks:
        this.store.dispatch(SetDefaultTaskId);
        this.router.navigate([RoutingPath.addTask]);
        break;

      case Modules.Users:
        this.router.navigate([RoutingPath.addUser]);
        break;

      case Modules.Expenses:
        this.router.navigate([RoutingPath.addExpense]);
        break;

      case Modules.Invoice:
      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.addInvoice]);
        break;

      case Modules.Estimate:
        this.router.navigate([RoutingPath.addEstimate]);
        break;

      case Modules.Jobs:
        this.dialog
          .open(AddJobsComponent, {
            data: {
              moduleId: Modules.Job,
            },
          })
          .afterClosed()
          .subscribe((result) => {
            const jobId = this.store.selectSnapshot(JobState.getJobId);
            this.onEditRouting(moduleId, jobId);
          });
        break;
    }
  }

  onAddRoutingFromSideList(moduleId: number): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.onAddRouting(moduleId);
  }

  onListRouting(moduleId: number): void {
    switch (moduleId) {
      case Modules.Clients:
        this.router.navigate([RoutingPath.clients]);
        break;

      case Modules.ExpenseType:
        this.router.navigate([RoutingPath.expenseTypes]);
        break;

      case Modules.Tasks:
        this.router.navigate([RoutingPath.tasks]);
        break;

      case Modules.Users:
        this.router.navigate([RoutingPath.users]);
        break;

      case Modules.Expenses:
        this.router.navigate([RoutingPath.expenses]);
        break;

      case Modules.Jobs:
        this.router.navigate([RoutingPath.jobs]);
        break;

      case Modules.Setting:
        this.router.navigate([RoutingPath.Dashboard]);
        break;

      case Modules.Invoice:
        this.router.navigate([RoutingPath.invoices]);
        break;

      case Modules.RecurringInvoice:
        this.router.navigate([RoutingPath.recurringInvoices]);
        break;

      case Modules.Estimate:
        this.router.navigate([RoutingPath.estimates]);
        break;
    }
  }

  onFailure(errorMessage: any): void {
    this.notifier.error(NotificationHeader.error, errorMessage);
  }

  setHighlightData(
    id: Guid,
    isExit: boolean,
    moduleId: number,
    routingPath: RoutingPath
  ): void {
    if (!isExit) {
      if (id === this.defaultGuidValue) {
        this.highlightRow.sideListHighlighted.isHighlighted = true;
        this.highlightRow.sideListHighlighted.sortBy = SortOrdering.createdOn;
      } else {
        this.highlightRow.sideListHighlighted.isHighlighted = false;
        this.highlightRow.sideListHighlighted.sortBy = '';
      }
    } else {
      this.highlightRow.mainListHighlighted.isHighlighted = true;
      this.highlightRow.mainListHighlighted.sortBy =
        id === this.defaultGuidValue
          ? SortOrdering.createdOn
          : SortOrdering.updatedOn;
      this.highlightRow.mainListHighlighted.moduleId = moduleId;
    }
  }

  onSuccess(successMessage: any): void {
    this.notifier.success(NotificationHeader.success, successMessage);
  }

  getSideListUrl(moduleId): string {
    let url;

    switch (moduleId) {
      case Modules.Clients:
        url = '/Client/clientsList';
        break;

      case Modules.ExpenseType:
        url = '/ExpenseType/expenseTypesList';
        break;

      case Modules.Tasks:
        url = '/Task/getAllTasksList';
        break;

      case Modules.Users:
      case Modules.TimeUnsubmitted:
      case Modules.TimePFA:
      case Modules.TimeApproved:
      case Modules.TimeRejected:
        url = '/User/getUsersList';
        break;

      case Modules.Jobs:
        url = '/Job/jobList';
        break;

      case Modules.Expenses:
        url = '/Expense/expenseList';
        break;

      case Modules.Invoice:
        url = '/Invoice/invoiceList';
        break;

      case Modules.RecurringInvoice:
        url = '/RecurringInvoice/getRecurringInvoiceList';
        break;

      case Modules.Estimate:
        url = '/Estimate/estimateList';
        break;
    }

    return url;
  }

  getSideList(
    queryParams: QueryParams,
    moduleId: Modules
  ): Observable<HttpResponse<SideGridModel>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}${this.getSideListUrl(moduleId)}`,
      queryParams,
      httpOptions
    );
  }

  multipleFileDownload(
    multiFileDownloadRequest: MultiFileDownloadRequest
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.postExportRequest(
      `${environment.apiVersionUrl}/FileUpload/multiFileDownload`,
      multiFileDownloadRequest,
      headers
    ).pipe(
      switchMap((response) => {
        this.download(response);
        return of();
      })
    );
  }

  getFileAsByte(fileUrl: string): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/FileUpload/getFileAsByte`,
      JSON.stringify(fileUrl),
      headers
    );
  }

  onInfo(infoMessage: any): void {
    this.notifier.error(NotificationHeader.info, infoMessage);
  }

  isViewPermission(moduleId: number) {
    let permissionId = -1;
    const data = this.store.selectSnapshot(SettingsState.getSelectedMenu);

    data?.children.forEach((x) => {
      if (x.ordinal === moduleId) {
        permissionId = x.permissionId;
      }
    });
    return permissionId === PermissionType.View;
  }

  changeOfRoutes(): void {
    this.menuList$.subscribe((data) => {
      let urlIndex = '';
      if (this.router.url.indexOf(';') > 0) {
        urlIndex = ';';
      }
      if (this.router.url.indexOf('?') > 0) {
        urlIndex = '?';
      }
      const currentUrl =
        this.router.url.indexOf(urlIndex) > 0
          ? this.router.url.split(urlIndex)[0]
          : this.router.url;
      this.setData(currentUrl, data);
    });
  }

  setData(currentUrl: any, data: any): void {
    data.forEach((x) => {
      if (x.url === currentUrl || x.addUrl === currentUrl) {
        const filteredMenu = x.children.filter(
          (x) => x.url === currentUrl || x.addUrl === currentUrl
        )[0];
        this.store
          .dispatch(new SetSubMenuData(x.children, filteredMenu))
          .subscribe();
      } else {
        x.children.map((y) => {
          if (y.url === currentUrl) {
            if (y.children?.length > 0 && !currentUrl.includes('job')) {
              this.store
                .dispatch(new SetSubMenuData(y.children, y))
                .subscribe();
            } else {
              this.store
                .dispatch(new SetSubMenuData(x.children, y))
                .subscribe();
            }
          } else if (y.addUrl === currentUrl) {
            this.store.dispatch(new SetSubMenuData(x.children, y)).subscribe();
          }
        });
      }
    });
  }

  setButtonPermissions(buttons, gridActions) {
    buttons.forEach((button: any) => {
      const buttonElement = button._elementRef
        .nativeElement as HTMLButtonElement;
      const buttonName = buttonElement.name;
      gridActions.forEach((element) => {
        if (element.name === buttonName && element.isDisabled) {
          buttonElement.disabled = element.isDisabled;
          buttonElement.classList.add('mat-button-disabled');
        }
      });
    });
  }
}
