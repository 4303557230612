<div class="sidenav-body-content pt-0 pb-0">
  <div class="form-field textarea">
    <div class="notification w-434">
      <div class="notification-list">
        <div class="notification-list-wrapper">
          <app-activity-details
            [getModuleId]="moduleId"
            [triggerLoadTimeLineData]="triggerLoadTimeLineData"
            (triggerActivityCount)="triggerActivityCount($event)"
          ></app-activity-details>
        </div>
      </div>
    </div>
  </div>
</div>
