import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { FlatTreeControl } from '@angular/cdk/tree';
import { MatSort } from '@angular/material/sort';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import {
  CommonNotificationText,
  FeeType,
  TimelogGroupByList,
} from '@app/core/enums';
import {
  JobGroupByTimelogParams,
  JobGroupByTimelogs,
  MainListParameters,
} from '@app/core/models';
import { GetJobSubTaskAndUsersDetails } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { Observable, Subscription } from 'rxjs';

export class JobGroupByTimelogNode extends JobGroupByTimelogs {
  groupByName: string;
  data: Array<JobGroupByTimelogNode>;
}

export class JobGroupByTimelogFlatNode extends JobGroupByTimelogNode {
  expandable: boolean;
  level: number;
}

@Component({
  selector: 'app-job-details-user-roi',
  templateUrl: './job-details-user-roi.component.html',
  styleUrls: ['./job-details-user-roi.component.scss'],
})
export class JobDetailsUserRoiComponent implements OnInit {
  jobId = Guid.EMPTY as unknown as Guid;
  jobROISubscription: Subscription;
  listParameters: MainListParameters = new MainListParameters();
  jobGroupByTimelogParams: JobGroupByTimelogParams;
  selectedGroupById = 1;
  toggleAll = false;
  TimelogGroupByList = TimelogGroupByList;
  commonNotificationText = CommonNotificationText;
  jobFeesTypeId: number;

  @Input()
  isFromJobList: boolean = false;

  @Input()
  triggerJobId: Observable<any>;

  @Input()
  triggerJobFeesType: Observable<any>;

  @ViewChild(MatSort) sort: MatSort;

  timelogGroupByList: any[] = [
    {
      value: TimelogGroupByList.BySubtask,
      name: 'Subtasks',
    },
    {
      value: TimelogGroupByList.ByUser,
      name: 'Users',
    },
  ];

  displayedColumns: string[] = [
    'groupByName',
    'timeSpent',
    'amount',
    'billable',
    'cost',
  ];

  private transformer = (node: JobGroupByTimelogNode, level: number) => {
    return {
      expandable: !!node.data && node.data.length > 0,
      data: node.data,
      groupById: node.groupById,
      groupByName: node.groupByName,
      childGroupById: node.childGroupById,
      childGroupByName: node.childGroupByName,
      timeSpent: node.timeSpent,
      userCostRate: node.userCostRate,
      userBillableRate: node.userBillableRate,
      userBillableAmount: node.userBillableAmount,
      amount: node.amount,
      cost: node.cost,
      isSelected: node.isSelected,
      level,
    };
  };

  treeControl = new FlatTreeControl<JobGroupByTimelogFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.data
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.selectedGroupById = this.isFromJobList
      ? TimelogGroupByList.ByUser
      : TimelogGroupByList.BySubtask;

    this.jobROISubscription = this.triggerJobId.subscribe((data) => {
      if (data) {
        this.jobId = data;
        this.getJobSubTaskAndUsersDetails();
        this.toggleAll = false;
      }
    });

    this.triggerJobFeesType.subscribe((data) => {
      if (data) {
        this.jobFeesTypeId = data;
      }
    });
  }

  ngOnDestroy(): void {
    this.jobROISubscription?.unsubscribe();
  }

  getParams() {
    const groupByParams: JobGroupByTimelogParams = {
      jobId: this.jobId,
      sortBy: this.listParameters.sortBy,
      sortOrder: this.listParameters.sortOrder,
      search: this.listParameters.search,
      groupBy: this.selectedGroupById,
    };

    return groupByParams;
  }

  getJobSubTaskAndUsersDetails(): void {
    this.store
      .dispatch(new GetJobSubTaskAndUsersDetails(this.getParams()))
      .subscribe((res) => {
        this.dataSource.data = res.job.jobGroupByTimelogList;
      });
  }

  onGroupBySelectionChange(val: any): void {
    this.getJobSubTaskAndUsersDetails();
  }

  toggleAllClick(): void {
    this.toggleAll = !this.toggleAll;
    if (this.toggleAll) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  sorting(sortBy: string, sortOrder: string): void {
    this.listParameters.sortOrder = sortOrder === 'asc';
    this.listParameters.sortBy = sortBy;
    this.getJobSubTaskAndUsersDetails();
  }

  setAmountText() {
    const feesTypeId = +this.jobFeesTypeId;
    let amountTooltipText = '';
    if (feesTypeId) {
      switch (feesTypeId) {
        case FeeType.TimeAndFee:
          amountTooltipText = 'Job Price/Hour * Total Billable Hours';
          break;

        case FeeType.FixedPrice:
          amountTooltipText = 'User Billable Rate * Total Billable Hours';
          break;

        case FeeType.NonBillable:
          amountTooltipText = '0.0';
          break;
      }
    }
    return amountTooltipText;
  }
}
