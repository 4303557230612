import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { PaymentDueTerms } from '../../models';
import { PaymentDueService } from '../../services';
import { CreatePaymentDue, GetPaymentDueList } from './payment-due.action';

export class PaymentDueStateInfo {
  paymentDue: Array<PaymentDueTerms>;
  paymentDueId?: number;
}

@State<PaymentDueStateInfo>({
  name: 'paymentDue',
  defaults: {
    paymentDue: [],
  },
})
@Injectable()
export class PaymentDueState {
  constructor(private paymentDueService: PaymentDueService) {}

  @Selector()
  static getPaymentDue(state: PaymentDueStateInfo) {
    return state.paymentDue;
  }

  @Action(GetPaymentDueList)
  getPaymentDueList({ getState, setState }: StateContext<PaymentDueStateInfo>) {
    return this.paymentDueService.getPaymentDueList().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          paymentDue: res,
        });
      })
    );
  }

  @Action(CreatePaymentDue)
  createPaymentDue(
    { getState, patchState }: StateContext<PaymentDueStateInfo>,
    action: CreatePaymentDue
  ) {
    return this.paymentDueService
      .createPaymentDueTerms(action.paymentDueTerms)
      .pipe(
        tap((res) => {
          const state = getState();
          patchState({
            ...state.paymentDue,
            paymentDueId: res,
          });
        })
      );
  }
}
