<div class="add-widget-box">
  <div class="card box-shadow-none">
    <div class="d-flex justify-space-between">
      <div class="wp-100">
        <div class="title d-flex align-items-center">
          <mat-checkbox
            [(ngModel)]="isAllTimeChartsSelected"
            (change)="selectAllTimeCharts($event)"
          >
            <h2>Time Section Charts</h2>
          </mat-checkbox>
        </div>

        <div class="wlist-box">
          <div *ngFor="let widget of timeSectionChartList">
            <mat-checkbox
              (change)="selectTimeSectionChartWidget()"
              [(ngModel)]="widget.isEnable"
            >
              {{ widget.widgetName }}
            </mat-checkbox>
          </div>
        </div>
      </div>
      <div class="wp-100">
        <div class="title d-flex align-items-center">
          <mat-checkbox
            [(ngModel)]="isAllFeesChartsSelected"
            (change)="selectAllFeesCharts($event)"
          >
            <h2>Fees Section Charts</h2>
          </mat-checkbox>
        </div>

        <div class="wlist-box">
          <div *ngFor="let widget of feeSectionChartList">
            <mat-checkbox
              (change)="selectFeeSectionChartWidget()"
              [(ngModel)]="widget.isEnable"
            >
              {{ widget.widgetName }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div
      class="top-switch-button d-flex align-items-center justify-space-between m-0 pb-0"
    >
      <div>
        <button
          type="button"
          class="action-button primary-button"
          mat-button
          disableRipple
          (click)="createDashboard()"
        >
          <span>Save </span>
        </button>
        <button
          type="button"
          class="action-button secondary-button ml-1"
          mat-button
          disableRipple
          (click)="onCancelClick()"
        >
          <span>Cancel</span>
        </button>
      </div>
      <div>
        <a
          href="javascript:void(0)"
          class="d-flex align-items-center gap-5 text-primary"
          (click)="resetToDefaultClick()"
        >
          <mat-icon class="text-primary">replay</mat-icon> Reset to Default
        </a>
      </div>
    </div>
  </div>
</div>
