<div
  class="cust-height"
  *ngIf="invoicedData.length !== 0 && receivedData.length !== 0"
>
  <ejs-chart
    style="display: block"
    class="fees-dash-height"
    [chartArea]="chartArea"
    [width]="width"
    align="center"
    id="chartcontainer"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    (load)="load($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="receivedData"
        type="StackingLine"
        xName="x"
        yName="y"
        name="Received"
        width="2"
        [marker]="marker1"
        opacity="1"
        fill="#F2816F"
        tooltipMappingName="tooltipMappingName"
      >
      </e-series>
      <e-series
        [dataSource]="invoicedData"
        type="StackingLine"
        xName="x"
        yName="y"
        name="Invoiced"
        width="2"
        [marker]="marker"
        opacity="1"
        fill="#7B76D3"
        tooltipMappingName="tooltipMappingName"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<div
  class="norecord-chart"
  *ngIf="invoicedData.length === 0 && receivedData.length === 0"
>
  <img src="assets/images/line-icon.svg" alt="Line Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
