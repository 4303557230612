import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  BudgetType,
  ExportParams,
  FileImportRequestModel,
  HourlyRateType,
  Note,
  ProjectAnalysisReport,
  ProjectBasicInfo,
  ProjectInvoices,
  ProjectList,
  ProjectNotes,
  ProjectParameters,
  Projects,
  ProjectTaskParameters,
  ProjectTasks,
  ProjectTeam,
  ProjectType,
  QueryParams,
  SideListModel,
  TaskModel,
  TaskProjectModel,
  UserModelProject,
} from '../../models';
import { CommonService } from '../common/common.service';
@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  projectTaskList: Subject<boolean> = new Subject();
  projectTeamList: Subject<boolean> = new Subject();

  getProject(
    queryParams: ProjectParameters
  ): Observable<HttpResponse<Array<ProjectList>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/executeProjectList`,
      queryParams,
      httpOptions
    );
  }

  getProjectTasksList(
    projectId: string,
    projectTaskParameters: ProjectTaskParameters
  ): Observable<Array<TaskModel>> {
    return this.http.get<Array<TaskModel>>(
      `${environment.apiVersionUrl}/Project/getProjectTask/${projectId}?search=${projectTaskParameters.search}&sortBy=${projectTaskParameters.sortBy}&sortOrder=${projectTaskParameters.sortOrder}&isProjectTask=${projectTaskParameters.isProjectTask}`
    );
  }

  getProjects(): Observable<Array<Projects>> {
    return this.http.get<Array<Projects>>(
      `${environment.apiVersionUrl}/Project/getAllProjects`
    );
  }

  getProjectTeamList(
    projectId: string,
    searchText: string,
    sortBy: string,
    sortOrder: boolean,
    isProjectUser: boolean
  ): Observable<Array<UserModelProject>> {
    return this.http.get<Array<UserModelProject>>(
      `${environment.apiVersionUrl}/Project/getProjectUser/${projectId}?search=${searchText}&SortBy=${sortBy}&SortOrder=${sortOrder}&isProjectUser=${isProjectUser}`
    );
  }

  saveProjectBasicInfo(projectBasicInfo: ProjectBasicInfo): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/saveProject`,
      JSON.stringify(projectBasicInfo),
      headers
    );
  }

  saveProjectTeams(teamProjectModel: Array<UserModelProject>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/saveProjectUsers`,
      JSON.stringify(teamProjectModel),
      headers
    );
  }

  saveProjectTasks(
    projectId: string,
    taskProjectModel: Array<TaskProjectModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/saveProjectTask/${projectId}`,
      JSON.stringify(taskProjectModel),
      headers
    );
  }

  getProjectDataforBasicInfo(projectId: string): Observable<ProjectBasicInfo> {
    return this.http.get<ProjectBasicInfo>(
      `${environment.apiVersionUrl}/Project/getProjectByUniversalId/${projectId}`
    );
  }

  getTaskDefaultRate(taskId: string): Observable<number> {
    return this.http.get<number>(
      `/Task/projectTask/list/update/task/defaultrate/${taskId}`
    );
  }

  getTeamDefaultRate(teamId: string): Observable<number> {
    return this.http.get<number>(
      `${environment.apiVersionUrl}/User/getDefaultHourlyRate/${teamId}`
    );
  }

  getProjectDataNotes(projectId: string): Observable<Array<Note>> {
    return this.http.get<Array<Note>>(
      `${environment.apiVersionUrl}/Project/list/notes/${projectId}`
    );
  }

  exportProjects(exportParams: ExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Project/exportProject`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getProjectList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Project/projectsList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}`,
      {
        observe: 'response',
      }
    );
  }

  copyProjects(projectIds?: Array<string>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/copyProjectsByUniversalId`,
      JSON.stringify(projectIds),
      headers
    );
  }

  archiveAndRestoreProject(
    projectIds?: Array<string>,
    isArchive?: boolean
  ): Observable<any> {
    const statusModel = {
      ids: [],
      universalIds: projectIds,
      status: isArchive,
    };
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Project/updateProjectStatus`,
      JSON.stringify(statusModel),
      headers
    );
  }

  getProjectTypeList(): Observable<Array<ProjectType>> {
    return this.http.get<Array<ProjectType>>(
      `${environment.apiVersionUrl}/Project/getProjectTypes`
    );
  }

  getBudgetTypeList(projectTypeId: number): Observable<Array<BudgetType>> {
    return this.http.get<Array<BudgetType>>(
      `${environment.apiVersionUrl}/Project/getBudgetTypes/${projectTypeId}`
    );
  }

  getHourlyRateTypeList(
    projectTypeId: number
  ): Observable<Array<HourlyRateType>> {
    return this.http.get<Array<HourlyRateType>>(
      `${environment.apiVersionUrl}/Project/geHourlyRateType/${projectTypeId}`
    );
  }

  saveProjectNotes(projectId: string, notes: Note): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/createProjectNote/${projectId}`,
      JSON.stringify(notes),
      headers
    );
  }

  createProjectBasicInfo(projectBasicInfo: ProjectBasicInfo): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/create`,
      JSON.stringify(projectBasicInfo),
      headers
    );
  }

  updateProjectBasicInfo(projectBasicInfo: ProjectBasicInfo): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${environment.apiVersionUrl}/Project/update`,
      JSON.stringify(projectBasicInfo),
      headers
    );
  }

  createProjectTasks(projectTasks: ProjectTasks): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/createTask`,
      JSON.stringify(projectTasks),
      headers
    );
  }

  createProjectTeam(projectTeam: ProjectTeam): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/createTeam`,
      JSON.stringify(projectTeam),
      headers
    );
  }

  createInvoices(projectInvoices: ProjectInvoices): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/createInvoices`,
      JSON.stringify(projectInvoices),
      headers
    );
  }

  createNotes(projectNotes: ProjectNotes): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Project/createNotes`,
      JSON.stringify(projectNotes),
      headers
    );
  }

  getProjectNoteList(
    projectId: number,
    searchText?: string
  ): Observable<Array<Note>> {
    return this.http.get<Array<Note>>(
      `${environment.apiVersionUrl}/Project/list/notes/${projectId}?searchText=${searchText}`
    );
  }

  deleteProjects(projectIds?: Array<string>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(projectIds),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/Project/deleteProjectsByUniversalId`,
      options
    );
  }

  deleteTaskProjectWise(taskId: string, projectId: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.delete<any>(
      `${environment.apiVersionUrl}/Project/deleteProjectTask?projectId=${projectId}&taskId=${taskId}`,
      options
    );
  }

  downloadProjectNotes(notesId: number): Observable<HttpResponse<Blob>> {
    return this.commonService.postExportRequest(
      `${environment.apiVersionUrl}/Project/downloadNotesAttachement/${notesId}`
    );
  }

  getAnalysisReport(projectId: string): Observable<any> {
    return this.http.get<ProjectAnalysisReport>(
      `${environment.apiVersionUrl}/Project/getProjectAnalysisReport/${projectId}`
    );
  }

  importProject(
    fileImportRequestModel: FileImportRequestModel
  ): Observable<boolean> {
    const formData = new FormData();

    formData.append('file', fileImportRequestModel.file);
    formData.append('step', fileImportRequestModel.step.toString());
    formData.append(
      'isDuplicate',
      fileImportRequestModel.isDuplicate.toString()
    );
    if (fileImportRequestModel.dataFieldList) {
      fileImportRequestModel.dataFieldList.forEach(function (string) {
        formData.append('dataFieldList', string);
      });
    }

    return this.http.post<boolean>(
      `${environment.apiVersionUrl}/Project/importProject`,
      formData
    );
  }

  getAllProjectList(): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Project/getAllProjects`
    );
  }

  downloadProjectTemplateFile(): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Project/getProjectImportTemplate`,
        headers
      )
      .pipe(
        switchMap((response) => {
          this.commonService.download(response);
          return of(true);
        })
      );
  }

  getTeammatesByProjectId(projectId: number): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Project/getProjectUsersByProjectUniversalId/${projectId}`
    );
  }

  getProjectsByUserId(userId: string): Observable<Array<SideListModel>> {
    return this.http.get<Array<SideListModel>>(
      `${environment.apiVersionUrl}/Project/getUserProjectsByUniversalId/${userId}`
    );
  }
}
