export class Profile {
  id?: number;
  userId?: string;
  fullName?: string;
  email?: string;
  capacity?: number;
  billableRate?: number;
  costRate?: number;
  persmission?: string;
  photo?: string;
  projectId?: number;
  isManager?: boolean;
  isAssign?: boolean;
  budgetPerMonth?: number;
  hourlyRate?: number;
  hours?: number;
  isTimeSheetSubmittedNotification?: boolean;
  isTimeSheetApprovedNotification?: boolean;
  isProjectRemovedNotification?: boolean;
  isProjectAddedNotification?: boolean;
}
