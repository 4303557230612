import { Guid } from 'guid-typescript';

export class ExpenseList {
  projectId: number;
  projectName: string;
  clientName: string;
  totalExpense: number;
  isSelected: boolean;
  userId: string;
  fullName: string;
  expenseList: Array<ExpenseTypeListModel>;
}

export class ExpenseTypeListModel {
  expenseTypeId: number;
  isSelected: boolean;
  expenseTypeName: string;
  totalExpenseType: number;
  expenseList: Array<ExpenseDetailListModel>;
}

export class ExpenseDetailListModel {
  expenseId: string;
  expenseNo: string;
  expenseDate: string;
  isBillable: boolean;
  totalPrice: number;
  attachment: string;
  isSelected: boolean;
}

export class ExpenseDataFromHeader {
  startDate: string;
  endDate: string;
  search: string;
  userId: string;
  expenseTypeId: number;
  projectId: number;
  isExpenseSelected: boolean;
}

export class ExpenseSubmitData {
  startDate: string;
  endDate: string;
  userId?: Guid;
  expenseIds: Array<Guid>;
}

export class ExpensesSummary {
  totalExpenseTypes: number;
  totalAmount: number;
  totalBillableAmount: number;
  totalNonBillableAmount: number;
  totalItemCount: number;
}

export class ExpenseUnSubmittedList {
  ProjectName: string;
  ClientName: string;
  ExpenseId: string;
  ExpenseTypeName: string;
  expenseDate?: Date;
  isBillable?: boolean;
  total: number;
  isSelected?: boolean;
}

export class ExpenseListForNonAdmin {
  id: number;
  UserName: string;
  FullName: string;
  TotalExpense: number;
  expenseDate?: Date;
  isBillable?: boolean;
  StartDate: Date;
  EndDate: Date;
}

export class ExpenseTaskUserModel {
  expenseId: Guid;
  clientId: Guid;
  taskId: Guid;
  userId: Guid;
  name: string;
}
