import { Guid } from 'guid-typescript';
import {
  Client,
  ExportParams,
  InvoiceParameters,
  QueryParams,
} from '../../models';
import { FileImportRequestModel } from '../../models/common/import';

export class GetClientOverview {
  static readonly type = '[CLIENT] Get Client Overview';

  constructor(public clientId: Guid, public monthNumber: number) {}
}

export class SetDefaultClientId {
  static readonly type = '[CLIENT] Set Default Client ID';
}

export class SetClientId {
  static readonly type = '[CLIENT] Set Client ID';

  constructor(public clientId: Guid) {}
}

export class GetClientDetailList {
  static readonly type = '[CLIENT] Get Client';

  constructor(public queryParams: QueryParams) {}
}

export class CreateClient {
  static readonly type = '[CLIENT] Create Client';

  constructor(public client: Client) {}
}

export class GetClientDataByClientId {
  static readonly type = '[CLIENT] Get Client Data By Client ID';

  constructor(public clientId: Guid) {}
}

export class UpdateClient {
  static readonly type = '[CLIENT] Update Client';

  constructor(public client: Client) {}
}

export class ExportClient {
  static readonly type = '[CLIENT] Export Client';

  constructor(public exportParams: ExportParams) {}
}

export class DeleteSelectedClient {
  static readonly type = '[CLIENT] Delete Selected Client';

  constructor(public clientIds?: Array<Guid>) {}
}

export class GetClientList {
  static readonly type = '[CLIENT] Get Client Names ';

  constructor(public queryParams: QueryParams) {}
}

export class CopyClient {
  static readonly type = '[CLIENT] Copy Selected Client';

  constructor(public clientIds?: Array<Guid>) {}
}

export class ArchiveAndRestoreClient {
  static readonly type = '[CLIENT] Archive and Restore Selected Client';

  constructor(public clientIds?: Array<string>, public isArchive?: boolean) {}
}

export class GetAllClientList {
  static readonly type = '[CLIENT] Get All Client List ';
}

export class GetClientsByProjectId {
  static readonly type = '[CLIENT] Get Clients By Project ID ';

  constructor(public projectId: number) {}
}

export class GetAllClientsWithContact {
  static readonly type = '[CLIENT] Get All Client Details ';
}

export class ImportClients {
  static readonly type = '[CLIENT] Import Client';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class SetClientDefaultState {
  static readonly type = '[CLIENT] Set Client Default State';
}

export class GetClientTasksDetails {
  static readonly type = '[CLIENT] Get Client Tasks Details';

  constructor(public queryParams: any) {}
}

//Reset
export class GetResetData {
  static readonly type = '[CLIENT] Get Reset Data';

  constructor(public resetParams: Array<any>) {}
}

export class GetClientUsersDetails {
  static readonly type = '[CLIENT] Get Users Tasks Details';

  constructor(public queryParams: any) {}
}

export class SaveClient {
  static readonly type = '[CLIENT] Save Client';

  constructor(public client: Client) {}
}

export class SaveClientUsers {
  static readonly type = '[CLIENT] Save Client Users';

  constructor(public clientId: Guid, public client: Array<any>) {}
}

export class SaveClientTask {
  static readonly type = '[CLIENT] Save Client Task';

  constructor(public clientId: Guid, public client: Array<any>) {}
}

export class DeleteClientTaskByClientId {
  static readonly type = '[CLIENT] Delete Client Task By Client ID';

  constructor(public clientId: Guid) {}
}

export class DeleteClientUserByClientId {
  static readonly type = '[CLIENT] Delete Client Task By Client ID';

  constructor(public clientId: Guid) {}
}

export class GetClientInvoicesByClientId {
  static readonly type = '[CLIENT] Get Client Invoices By ClientId';

  constructor(public clientId: Guid, public invoiceParams: InvoiceParameters) {}
}
