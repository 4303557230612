import { Guid } from 'guid-typescript';
import {
  ExportParams1,
  Note,
  QueryParams,
  RecurringInvoiceBasicInfo,
  RecurringInvoiceParameters,
  RecurringSchedule,
} from '../../models';

export class GetRecurringInvoiceDetailList {
  static readonly type =
    '[RECURRINGINVOICES] Get Recurring Invoice Detail List';

  constructor(public recurringInvoiceParams: RecurringInvoiceParameters) {}
}

export class CopyRecurringInvoices {
  static readonly type = '[RECURRINGINVOICES] Copy Recurring Invoice';

  constructor(public recurringInvoiceIds?: Array<Guid>) {}
}

export class GetRecurringInvoiceList {
  static readonly type = '[RECURRINGINVOICES] Get Recurring Invoice Names ';

  constructor(public queryParams: QueryParams) {}
}

export class SetRecurringInvoiceDefaultState {
  static readonly type =
    '[RECURRINGINVOICES] Set Recurring Invoice Default State';
}

export class UpdateRecurringInvoiceStatus {
  static readonly type = '[RECURRINGINVOICES] Update Recurring Invoice Status';

  constructor(
    public recurringInvoiceIds?: Array<Guid>,
    public status?: number
  ) {}
}

export class SaveInvoiceScheduleSetting {
  static readonly type = '[RECURRINGINVOICES] Save Invoice Schedule Setting';

  constructor(public recurringSchedule: RecurringSchedule) {}
}

export class SaveRecurringInvoiceScheduleSetting {
  static readonly type =
    '[RECURRINGINVOICES] Save Recurring Invoice Schedule Setting';

  constructor(public recurringSchedule: RecurringSchedule) {}
}

export class GetRecurringInvoiceByUniversalId {
  static readonly type =
    '[RECURRINGINVOICES] Get Recurring Invoice By UniversalId';

  constructor(public universalId: Guid) {}
}

export class SaveRecurringInvoice {
  static readonly type = '[RECURRINGINVOICES] Save Recurring Invoice';

  constructor(public recurringInvoiceBasicInfo: RecurringInvoiceBasicInfo) {}
}

export class GetScheduleSettingByRecurringInvoiceId {
  static readonly type =
    '[RECURRINGINVOICES] Get Schedule Setting By Recurring InvoiceId';

  constructor(public recurringInvoiceId: Guid) {}
}

export class SetRecurringInvoiceId {
  static readonly type = '[RECURRINGINVOICES] Set Recurring Invoice ID ';

  constructor(public recurringInvoiceId: Guid) {}
}

export class DeleteSelectedRecurringInvoice {
  static readonly type =
    '[RECURRINGINVOICES] Delete Selected Recurring Invoice';

  constructor(public recurringInvoiceIds?: Array<Guid>) {}
}

export class ExportRecurringInvoice {
  static readonly type = '[RECURRINGINVOICES] Export Recurring Invoice';

  constructor(public exportParams: ExportParams1) {}
}

export class SaveRecurringInvoiceNotes {
  static readonly type = '[RECURRINGINVOICE] Save Recurring Invoice Notes';

  constructor(public note: Note) {}
}
