export enum ModuleName {
  Activity = 'activity',
  Clients = 'client',
  ProjectTask = 'Project Task',
  Tasks = 'task',
  ExpenseType = 'expenses-type',
  Users = 'users',
  Taxes = 'taxes',
  Project = 'project',
  Service = 'service',
  Expenses = 'expenses',
  Jobs = 'job',
  Estimate = 'estimate',
  Invoice = 'invoice',
  RecurringInvoices = 'recurringInvoices',
  InvoiceDelete = 'invoiceDelete',
  InvoiceEdit = 'invoiceEdit',
  Settings = 'settings',
  Time = 'time',
  Mail = 'mail',
  MailDelete = 'mailDelete',
  MailIntegration = 'mailIntegration',
  PaymentMethod = 'paymentMethod',
  InvoicePayment = 'invoicePayment',
  InvoiceMailReAuth = 'invoiceMailReAuth',
  StripePayment = 'StripePayment',
  StripeIntegration = 'stripeIntegration',
  StripeDelete = 'stripeDelete',
  RenameColumn = 'renameColumn',
  RecordPayment = 'recordPayment',
  StripeReauth = 'stripeReauth',
  EndSchedule = 'endSchedule',
}

export enum HeaderTextForSideList {
  Clients = 'Clients',
  ExpenseTypes = 'Expense Types',
  Tasks = 'Tasks',
  Taxes = 'Taxes',
  Users = 'Users',
  Expenses = 'Expenses',
  Jobs = 'Jobs',
  Invoices = 'Invoices',
  RecurringInvoices = 'Recurring Invoices',
  Estimates = 'Estimates',
}

export enum ModuleHeaderText {
  Clients = 'Client',
  Tasks = 'Task',
  Taxes = 'Tax',
  ExpenseTypes = 'Expense Type',
  Users = 'User',
  Jobs = 'Job',
  Expenses = 'Expense',
  Invoices = 'Invoice',
  RecurringInvoices = 'Recurring Invoice',
  Estimates = 'Estimate',
}

export enum ModuleHeaderActivity {
  Dashboard = 1,
  Time = 2,
  Jobs = 3,
  Fees = 4,
  Expense = 5,
  Report = 6,
  Manage = 7,
  Setting = 8,
  Activity = 9,
  Announcement = 10,
  Live = 11,
  DashboardStatistical = 12,
  DashboardGraphical = 13,
  Timelog = 14,
  TimeUnsubmitted = 15,
  TimePFA = 16,
  TimeApproved = 17,
  TimeRejected = 18,
  Job = 19,
  JobsCalendar = 20,
  InvoiceOverView = 21,
  Invoice = 22,
  RecurringInvoice = 23,
  Estimate = 24,
  ReportTime = 25,
  ReportExpense = 26,
  ReportInvoice = 27,
  ReportUser = 28,
  Client = 29,
  Task = 30,
  User = 31,
  ExpenseType = 32,
  MyBusiness = 33,
  Preference = 34,
  DemoData = 35,
  SettingInvoice = 36,
  SettingEstimate = 37,
  Templates = 38,
  Integration = 39,
  Import = 40,
  Export = 41,
  Permission = 42,
  JobDetail = 43,
  JobTimelogs = 44,
  JobEmail = 45,
  JobRecurring = 46,
  JobFiles = 47,
  JobComment = 48,
  JobActivity = 49,

  TaskUsers = 51,
  TaskClient = 52,
  TaskSubtask = 53,
  ClientTask = 54,
  ClientUser = 55,
  UserTask = 56,
  UserClient = 57,
}
