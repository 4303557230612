import { Injectable } from '@angular/core';
import { ActionModel } from '@app/core/models/common/grid-action';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';
import { ExpenseType, SideListModel } from '../../models';
import { ExpenseTypeService } from '../../services';
import {
  ArchiveAndRestoreExpenseType,
  CopyExpenseType,
  DeleteSelectedExpenseType,
  GetAllExpenseType,
  GetExpenseTypeData,
  GetExpenseTypeDetailList,
  GetExpenseTypeList,
  ImportExpenseType,
  SaveExpenseType,
  SetDefaultExpenseTypeId,
  SetExpenseTypeDefaultState,
} from './expense-type.action';

export class ExpenseTypeStateInfo {
  expenseTypeList: Array<ExpenseType>;
  expenseTypeId?: Guid;
  expenseTypeData?: ExpenseType;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  totalRecord?: number;
  isLastPage?: boolean;
  highlightIDs?: Array<string>;
  importData?: any;
  isDataAvailable?: boolean;
  gridActions?: ActionModel[];
  isSuccess?: boolean;
  statusMessage?: string;
}

@State<ExpenseTypeStateInfo>({
  name: 'expenseType',
  defaults: {
    expenseTypeList: [],
    isDataAvailable: true,
  },
})
@Injectable()
export class ExpenseTypeState {
  constructor(private expenseTypeService: ExpenseTypeService) {}

  @Selector()
  static getAllExpenseTypeList(state: ExpenseTypeStateInfo) {
    return state.expenseTypeList;
  }

  @Selector()
  static getExpenseTypeId(state: ExpenseTypeStateInfo) {
    return state.expenseTypeId;
  }

  @Selector()
  static getExpenseTypeData(state: ExpenseTypeStateInfo) {
    return state.expenseTypeData;
  }

  @Selector()
  static getExpenseTypeList(state: ExpenseTypeStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static totalRecord(state: ExpenseTypeStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static isLastPage(state: ExpenseTypeStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getHighlightedIds(state: ExpenseTypeStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: ExpenseTypeStateInfo) {
    return state.importData;
  }

  @Selector()
  static isDataAvailable(state: ExpenseTypeStateInfo) {
    return state.isDataAvailable;
  }

  @Selector()
  static getGridActions(state: ExpenseTypeStateInfo) {
    return state.gridActions;
  }

  @Action(GetExpenseTypeDetailList, { cancelUncompleted: true })
  getExpenseTypeDetailList(
    { getState, setState }: StateContext<ExpenseTypeStateInfo>,
    action: GetExpenseTypeDetailList
  ) {
    return this.expenseTypeService
      .getExpenseTypeDetailList(action.queryParams)
      .pipe(
        tap((res) => {
          const state = getState();
          const headers = JSON.parse(res.headers.get('Pagination')!);
          const isDataAvailable = headers.IsBusinessDataFound;
          const totalRecord = headers.TotalItemCount;
          setState({
            ...state,
            expenseTypeList: res.body!.data,
            gridActions: res.body!.actions,
            isDataAvailable,
            totalRecord,
          });
        })
      );
  }

  @Action(SetDefaultExpenseTypeId)
  setDefaultExpenseTypeId({ patchState }: StateContext<ExpenseTypeStateInfo>) {
    patchState({
      expenseTypeId: Guid.EMPTY as unknown as Guid,
    });
  }

  @Action(SaveExpenseType)
  saveExpenseType(
    { getState, patchState }: StateContext<ExpenseTypeStateInfo>,
    action: SaveExpenseType
  ) {
    return this.expenseTypeService.saveExpenseType(action.expenseType).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.expenseTypeList,
          expenseTypeId: res.data,
          isSuccess: res.isSuccess,
          statusMessage: res.statusMessage,
        });
      })
    );
  }

  @Action(GetExpenseTypeData)
  getExpensesData(
    { getState, setState }: StateContext<ExpenseTypeStateInfo>,
    action: GetExpenseTypeData
  ) {
    return this.expenseTypeService
      .getExpenseTypeById(action.expenseTypeId)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            expenseTypeData: res,
          });
        })
      );
  }

  @Action(DeleteSelectedExpenseType)
  deleteSelectedExpenses(
    { getState, setState }: StateContext<ExpenseTypeStateInfo>,
    action: DeleteSelectedExpenseType
  ) {
    return this.expenseTypeService
      .deleteExpenseTypes(action.expenseTypeIds)
      .pipe(
        tap(() => {
          const state = getState();

          const filteredExpenseTypes = state.expenseTypeList.filter(
            (item) =>
              !action.expenseTypeIds?.includes(
                item.universalId ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          const filteredForSideList = state.sideListModel?.filter(
            (item) =>
              !action.expenseTypeIds?.includes(
                item.universalId ?? (Guid.EMPTY as unknown as Guid)
              )
          );

          setState({
            ...state.expenseTypeList,
            expenseTypeList: filteredExpenseTypes,
            sideListModel: filteredForSideList,
          });
        })
      );
  }

  // @Action(ExportExpenseType)
  // exportExpenses(
  //   { getState, patchState }: StateContext<ExpenseTypeStateInfo>,
  //   action: ExportExpenseType
  // ) {
  //   return this.expenseTypeService.export(action.exportParams).pipe(
  //     switchMap((res) => {
  //       patchState({ exported: true });

  //       return EMPTY;
  //     })
  //   );
  // }

  @Action(GetExpenseTypeList, { cancelUncompleted: true })
  getExpenseTypeList(
    { getState, setState }: StateContext<ExpenseTypeStateInfo>,
    action: GetExpenseTypeList
  ) {
    return this.expenseTypeService.getExpenseTypeList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let expenseTypeDataSideList: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          expenseTypeDataSideList = state.sideListModel!;
          expenseTypeDataSideList = expenseTypeDataSideList.concat(res.body!);
        } else {
          expenseTypeDataSideList = res.body;
        }
        setState({
          ...state,

          sideListModel: expenseTypeDataSideList!,
          isLastPage,
        });
      })
    );
  }

  @Action(CopyExpenseType)
  copyExpenseType(
    { patchState }: StateContext<ExpenseTypeStateInfo>,
    action: CopyExpenseType
  ) {
    return this.expenseTypeService.copy(action.expenseTypeIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreExpenseType)
  archiveRestoreExpenseType(
    { getState, patchState }: StateContext<ExpenseTypeStateInfo>,
    action: ArchiveAndRestoreExpenseType
  ) {
    return this.expenseTypeService
      .archiveAndRestoreExpenseTypes(action.expenseTypeIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          patchState({
            ...state.expenseTypeList,
            expenseTypeList: state.expenseTypeList,
          });
        })
      );
  }

  @Action(ImportExpenseType)
  importExpenseType(
    { patchState }: StateContext<ExpenseTypeStateInfo>,
    action: ImportExpenseType
  ) {
    return this.expenseTypeService
      .importExpenseType(action.fileImportRequestModel)
      .pipe(
        tap((res) => {
          patchState({
            importData: res,
          });
        })
      );
  }

  @Action(GetAllExpenseType)
  getAllExpenseType({ patchState }: StateContext<ExpenseTypeStateInfo>): any {
    return this.expenseTypeService.getALlExpenseType().pipe(
      tap((res) => {
        patchState({
          expenseTypeList: res,
        });
      })
    );
  }

  @Action(SetExpenseTypeDefaultState)
  setExpenseTypeDefaultState({
    patchState,
  }: StateContext<ExpenseTypeStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }
}
