import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { NotificationTimelineRoutingModule } from './notification-timeline-routing.module';
import { NotificationTimelineComponent } from './notification-timeline.component';
import { AngularMaterialModule } from '@app/core/shared/angular-material/angular-material.module';
import { AnnouncementComponent } from './announcement/announcement.component';
import { ActivityComponent } from './activity/activity.component';
import { ComponentsModule } from '@app/core/shared/components/components.module';

@NgModule({
  declarations: [
    NotificationTimelineComponent,
    AnnouncementComponent,
    ActivityComponent,
  ],
  imports: [
    CommonModule,
    NotificationTimelineRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class NotificationTimelineModule {}
