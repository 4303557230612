import { Component, OnInit } from '@angular/core';
import { FeeType } from '@app/core/enums';
import { UserLiveTimeLogModel } from '@app/core/models';
import { GetLiveUpdates } from '@app/core/store';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss'],
})
export class LiveComponent implements OnInit {
  displayedColumns: string[] = ['user', 'job', 'time'];
  liveTimelogList: Array<UserLiveTimeLogModel>;
  feeType = FeeType;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.store.dispatch(new GetLiveUpdates()).subscribe((res) => {
      this.liveTimelogList = res.time.userLiveTimelogList;
    });
  }
}
