<div
  [ngClass]="inputNum === 0 ? 'textarea-container' : 'input-container'"
  class="form-field custom-textarea"
  contenteditable="true"
  (blur)="onMessageChange($event.target)"
  (input)="onInputChange()"
  [innerHTML]="message"
  [(ngModel)]="message"
  [mentionConfig]="{
    triggerChar: ' ',
    maxItems: 10,
    labelKey: 'name',
    mentionSelect: onMentionSelect
  }"
  [mention]="highlightTexts"
  ngDefaultControl
></div>
