<div
  class="alert alert-{{ cssClass(item) }}"
  *ngFor="let item of notifications"
>
  <div class="d-flex">
    <mat-icon class="check-icon text-{{ cssClass(item) }}-100">
      {{ icon }}
    </mat-icon>
    <div>
      <p class="text-{{ cssClass(item) }} mb-0">{{ item.message }}</p>
    </div>
  </div>
  <div class="alert-close" (click)="close()">
    <mat-icon>close</mat-icon>
  </div>
</div>
