import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExportParams1,
  Note,
  QueryParams,
  RecurringInvoiceBasicInfo,
  RecurringInvoiceParameters,
  RecurringSchedule,
  SideListModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class RecurringInvoicesService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'RecurringInvoice';
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getRecurringInvoiceDetailList(
    recurringInvoiceParams: RecurringInvoiceParameters
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService.postExecuteRequest(
      `${this.apiUrl}${this.routeBase}/executeRecurringInvoiceList`,
      JSON.stringify(recurringInvoiceParams),
      headers
    );
  }

  getRecurringInvoiceList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getRecurringInvoiceList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}&isRecurring=${queryParams.isRecurring}`,
      {
        observe: 'response',
      }
    );
  }

  updateRecurringInvoiceStatus(
    recurringInvoiceIds?: Array<Guid>,
    status?: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateRecurringInvoiceStatus?action=${status}`,
      JSON.stringify(recurringInvoiceIds),
      headers
    );
  }

  saveInvoiceScheduleSetting(
    recurringSchedule: RecurringSchedule
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveInvoiceScheduleSetting`,
      JSON.stringify(recurringSchedule),
      headers
    );
  }

  saveRecurringInvoiceScheduleSetting(
    recurringSchedule: RecurringSchedule
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveRecurringInvoiceScheduleSetting`,
      JSON.stringify(recurringSchedule),
      headers
    );
  }

  saveRecurringInvoice(
    recurringInvoiceBasicInfo: RecurringInvoiceBasicInfo
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveRecurringInvoice`,
      JSON.stringify(recurringInvoiceBasicInfo),
      headers
    );
  }

  exportRecurringInvoice(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportRecurringInvoice`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else if (exportParams.isView) {
            this.commonService.view(response);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  saveRecurringInvoiceNotes(notes: Note): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createNote`,
      JSON.stringify(notes),
      headers
    );
  }
}
