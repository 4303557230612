import { Guid } from 'guid-typescript';
import {
  ExportParams1,
  FileImportRequestModel,
  Profile,
  ProjectUserDetail,
  QueryParams,
  UserModel,
} from '../../models';

export class GetUserOverview {
  static readonly type = '[USER] Get User Overview';

  constructor(public userId: Guid, public monthNumber: number) {}
}

export class GetUserDetailList {
  static readonly type = '[USER] Get User';

  constructor(public queryParams: QueryParams) {}
}

export class GetUserDataByUserId {
  static readonly type = '[USER] Get User Data By User ID';

  constructor(public userId: Guid) {}
}

export class SaveUserBasicInfo {
  static readonly type = '[USER] Save Basic Info';

  constructor(public user: UserModel) {}
}

export class SaveCapacityPermission {
  static readonly type = '[USER] Save Capacity Permission';

  constructor(public user: UserModel) {}
}

export class SaveUserProject {
  static readonly type = '[USER] Save Project';

  constructor(public userProject: ProjectUserDetail[]) {}
}

export class SaveUserNotes {
  static readonly type = '[USER] Save Notes';

  constructor(public userId: Guid, public user: UserModel) {}
}

export class GetUserDataNotes {
  static readonly type = '[USER] Get User Data By User ID for notes';

  constructor(public userId: Guid) {}
}

export class ExportUser {
  static readonly type = '[USER] Export User';

  constructor(public exportParams: ExportParams1) {}
}

export class DeleteSelectedUser {
  static readonly type = '[USER] Delete Selected User';

  constructor(public userIds?: Array<Guid>) {}
}

export class GetUserList {
  static readonly type = '[USER] Get User List ';

  constructor(public queryParams: QueryParams) {}
}

export class CopyUser {
  static readonly type = '[USER] Copy Selected User';

  constructor(public userIds?: Array<Guid>) {}
}

export class ArchiveAndRestoreUser {
  static readonly type = '[USER] Archive and Restore Selected User';

  constructor(public userIds?: Array<Guid>, public isArchive?: boolean) {}
}

export class GetProjectDetailList {
  static readonly type = '[USER] Get Project List ';

  constructor(public queryParams: QueryParams, public userId: Guid) {}
}

export class SetUserId {
  static readonly type = '[USER] Set User ID ';

  constructor(public userId: Guid) {}
}

export class GetAllUsers {
  static readonly type = '[USER] Get All Users';
}

//#region Profile
export class SetNotification {
  static readonly type = '[Profile] Set Notification';

  constructor(public profileNotfication: Profile) {}
}

export class GetNotification {
  static readonly type = '[Profile] Get Notification';

  constructor(public userId: Guid) {}
}

export class SetTime {
  static readonly type = '[Profile] Set Time';

  constructor(public userId: any) {}
}

export class GetTime {
  static readonly type = '[Profile] Get Time';

  constructor(public time: any) {}
}

//#endregion

export class ImportUsers {
  static readonly type = '[USER] Import Users';

  constructor(public fileImportRequestModel: FileImportRequestModel) {}
}

export class SetUserDefaultState {
  static readonly type = '[USER] Set User Default State';
}

export class GetUserPermissionsList {
  static readonly type = '[USER] Get User Permissions List';
}

export class InviteUser {
  static readonly type = '[USER] Invite User';

  constructor(public universalId: Guid) {}
}

export class SaveUser {
  static readonly type = '[USER] Save User';

  constructor(public user: UserModel) {}
}

export class GetUsersByPermission {
  static readonly type = '[USER] Get Users By Permission';
}

export class GetUserTasksDetails {
  static readonly type = '[USER] Get User Tasks Details';

  constructor(public queryParams: any) {}
}

export class SaveUserTask {
  static readonly type = '[USER] Save User Task';

  constructor(public userId: Guid, public userTask: Array<any>) {}
}

export class DeleteUserTaskByUserId {
  static readonly type = '[USER] Delete User Task By User ID';

  constructor(public userId: Guid) {}
}

export class GetUserClientsDetails {
  static readonly type = '[USER] Get User Clients Details';

  constructor(public queryParams: any) {}
}

export class SaveUserClients {
  static readonly type = '[USER] Save User Clients';

  constructor(public userId: Guid, public userClients: Array<any>) {}
}

export class DeleteUserClientByUserId {
  static readonly type = '[USER] Delete User Client By User ID';

  constructor(public userId: Guid) {}
}

export class LockUnlockDashboard {
  static readonly type = '[USER] Lock Unlock Dashboard';

  constructor(public isLocked: boolean) {}
}
