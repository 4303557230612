<div class="cust-height" *ngIf="chartData?.length">
  <ejs-chart
    class="fees-dash-height"
    style="display: block"
    [chartArea]="chartArea"
    [width]="width"
    align="center"
    id="invoice-status"
    [primaryXAxis]="primaryXAxis"
    [primaryYAxis]="primaryYAxis"
    [tooltip]="tooltip"
    (load)="load($event)"
    [legendSettings]="legend"
  >
    <e-series-collection>
      <e-series
        [dataSource]="chartData"
        type="Bar"
        [pointColorMapping]="pointColorMapping"
        xName="x"
        yName="y"
        [marker]="marker"
        tooltipMappingName="tooltipMappingName"
        columnSpacing="0.1"
      >
      </e-series>
    </e-series-collection>
  </ejs-chart>
</div>

<div class="norecord-chart" *ngIf="!chartData?.length">
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
