import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  ConfirmationType,
  Modules,
  NotificationDetails,
  NotificationHeader,
  NotificationTextMessage,
  TimeMode,
  TimelogStatus,
} from '@app/core/enums';
import {
  AddTimeLogModel,
  GlobalComponent,
  ListModel,
  SideListGroupModel,
  TimeSubTaskListModel,
  TimelogDetailModel,
} from '@app/core/models';
import { CommonService, NotificationService } from '@app/core/services';
import { ConfirmationBoxComponent } from '@app/core/shared/components';
import {
  GetClients,
  GetSettingsData,
  GetSubTasks,
  GetTasks,
  GetTimeLogDetailsByUniversalId,
  GetTimeLogDetailsByWeek,
  GetUsers,
  SaveTimeLog,
  SettingsState,
  TimeState,
} from '@app/core/store';
import { GetAvailableJobByRole } from '@app/core/store/job';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

export const MY_DATE_FORMATS = {
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const noDuplicateTimesValidator: ValidatorFn = (
  control: AbstractControl
): { [key: string]: any } | null => {
  const times = control.value;
  const startTimes = times.map((t) => t.startTime);
  const endTimes = times.map((t) => t.endTime);
  const duplicateStart = startTimes.some((s, i) => startTimes.indexOf(s) !== i);
  const duplicateEnd = endTimes.some((e, i) => endTimes.indexOf(e) !== i);
  return duplicateStart || duplicateEnd ? { duplicateTimes: true } : null;
};

@Component({
  selector: 'app-add-timelog',
  templateUrl: './add-timelog.component.html',
  styleUrls: ['./add-timelog.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AddTimelogComponent implements OnInit {
  jobList: any[] = [];
  clientList: ListModel[] = [];
  taskList: SideListGroupModel[] = [];
  subTaskList: TimeSubTaskListModel[] = [];
  subTasksList: ListModel[] = [];
  userList: ListModel[] = [];
  defaultGuid = Guid.EMPTY as unknown as Guid;
  jobId = Guid.EMPTY as unknown as Guid;
  taskId = Guid.EMPTY as unknown as Guid;
  clientId = Guid.EMPTY as unknown as Guid;
  subTaskId = Guid.EMPTY as unknown as Guid;
  timeLogForm: FormGroup;
  timelogDetailModel: TimelogDetailModel;
  selectedUserId: Guid;
  defaultUniversalId = Guid.EMPTY as unknown as Guid;
  userId = this.globalComponent.getLoggedInUserId();
  isAddMode = true;
  timeModeId?: number;
  startTime = '00:00';
  endTime = '00:00';
  timeSpent?: string = '00:00';
  totalHours = 0;
  totalMins = 0;
  isInvalidEndTime = false;

  timeLogData: AddTimeLogModel;
  moduleId: number;
  moduleEnum = Modules;
  timeMode = TimeMode;
  isSubTaskBillable: boolean = false;
  isAdmin = this.globalComponent.isAdmin();
  isOwner = this.globalComponent.isOwner();
  isRegularUser = this.globalComponent.isRegularUser();
  store: Store;
  commonService: CommonService;
  spinner: NgxSpinnerService;
  datePipe: DatePipe;
  formBuilder: UntypedFormBuilder;
  isAlreadyApprovedRejectedTimelog = false;
  notifier: NotificationService;
  dialog: MatDialog;
  minimumChargeableTime?: string;
  applyMinimumChargeableTime = false;

  constructor(
    public injector: Injector,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<AddTimelogComponent>,
    public globalComponent: GlobalComponent
  ) {
    this.store = this.injector.get<Store>(Store);
    this.commonService = this.injector.get<CommonService>(CommonService);
    this.spinner = this.injector.get<NgxSpinnerService>(NgxSpinnerService);
    this.datePipe = this.injector.get<DatePipe>(DatePipe);
    this.formBuilder =
      this.injector.get<UntypedFormBuilder>(UntypedFormBuilder);
    this.notifier = this.injector.get<NotificationService>(NotificationService);
    this.dialog = this.injector.get<MatDialog>(MatDialog);
  }

  ngOnInit(): void {
    this.moduleId = this.dialogData.moduleId;
    this.jobId =
      this.dialogData.jobId !== ''
        ? this.dialogData.jobId
        : this.defaultUniversalId;

    if (this.moduleId === this.moduleEnum.Time) {
      this.timelogDetailModel = this.dialogData;
      this.selectedUserId = this.dialogData.userId ?? this.defaultUniversalId;
      this.isAddMode = this.dialogData.isAddMode ?? false;
    }

    this.setForm();
    this.getAvailableJobByRole();
    this.getTimeMode();
    this.setFromCalendarViewData();
  }

  setFromCalendarViewData(): void {
    if (this.timelogDetailModel?.isCalendarView && this.isAddMode) {
      this.startTime =
        this.datePipe.transform(
          this.timelogDetailModel.calendarData?.startTime,
          'HH:mm'
        ) ?? '00:00';
      this.endTime =
        this.datePipe.transform(
          this.timelogDetailModel.calendarData?.endTime,
          'HH:mm'
        ) ?? '00:00';
      this.onTimeChanged();
      this.timeLogForm.patchValue({
        date:
          this.datePipe
            .transform(
              this.timelogDetailModel.calendarData.startTime,
              'yyyy-MM-dd'
            )
            ?.toString() ?? new Date(),
      });
    }
  }

  setForm(): void {
    this.timeLogForm = this.formBuilder.group({
      jobId: new FormControl<Guid | null>(null),
      clientId: new FormControl<string>('', Validators.required),
      taskId: new FormControl<string>('', Validators.required),
      subTaskId: new FormControl<string>(''),
      userId: new FormControl<string>('', Validators.required),
      date: new FormControl<Date>(new Date(), Validators.required),
      description: new FormControl<string>(''),
      users: new FormControl<number | null>({
        value: null,
        disabled: true,
      }),
    });

    if (this.moduleId === this.moduleEnum.Time) {
      this.timeLogForm.patchValue({
        date:
          this.datePipe
            .transform(this.timelogDetailModel.date!, 'yyyy-MM-dd')
            ?.toString() ?? new Date(),
      });
    }
  }

  dataSubmit(): boolean {
    this.spinner.show();

    try {
      this.timeLogData = {
        universalId: this.timelogDetailModel?.universalId ?? this.defaultGuid,
        jobId: this.timeLogForm.controls.jobId.value
          ? this.timeLogForm.controls.jobId.value
          : this.defaultGuid,
        clientId: this.timeLogForm.controls.clientId.value,
        taskId: this.taskId,
        jobSubTaskId: this.timeLogForm.controls.subTaskId.value
          ? this.timeLogForm.controls.subTaskId.value
          : this.defaultGuid,
        userId: this.timeLogForm.controls.userId.value,
        date: this.datePipe
          .transform(this.timeLogForm.controls.date.value, 'yyyy-MM-dd')
          ?.toString(),
        description: this.timeLogForm.controls.description.value,
        taskName: this.timeLogForm.controls.taskId.value,
        startTime: this.startTime ?? '00:00',
        endTime: this.endTime ?? '00:00',
        timeSpent: this.timeSpent ?? '00:00',
        isBillable: this.isSubTaskBillable,
        isFromTimer: false,
        applyMinimumChargeableTime: this.applyMinimumChargeableTime,
      };
    } catch (error) {
      this.spinner.hide();
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.errorMessage
      );
      return false;
    }
    return true;
  }

  addTimeLog() {
    if (
      this.timeLogForm.invalid ||
      this.timeLogForm.controls.clientId.value === this.defaultGuid
    ) {
      if (this.timeLogForm.controls.clientId.value === this.defaultGuid) {
        this.timeLogForm.controls.clientId.setErrors({ invalid: true });
      }
      this.commonService.addValidation(this.timeLogForm);
      this.notifier.error(
        NotificationHeader.error,
        NotificationTextMessage.validationMessage
      );
    } else if (this.minimumChargeableTime !== '00:00') {
      this.checkValidMinimumChargeableTime();
    } else {
      this.saveTimelog();
    }
  }

  saveTimelog(): void {
    if (this.dataSubmit()) {
      this.store
        .dispatch(new SaveTimeLog(this.timeLogData))
        .pipe()
        .subscribe(
          (res) => {
            if (res.time.timeLogAddData.isSuccess) {
              this.dialogRef.close(NotificationTextMessage.successMessage);
              this.commonService.onSuccess(
                NotificationTextMessage.successMessage
              );
            } else {
              this.dialogRef.close();
              this.notifier.error(
                NotificationHeader.error,
                NotificationTextMessage.errorMessage
              );
            }
          },
          (err) => {
            this.dialogRef.close();
            this.notifier.error(
              NotificationHeader.error,
              NotificationTextMessage.errorMessage
            );
          }
        );
    }
  }

  onJobSelectionChange(jobId): void {
    this.jobId = jobId;
    this.timelogDetailModel.jobId = jobId;
    this.getClients(true);
    this.getTasks(true);
    this.getSubTasks();
    this.getUsers();
    this.getTimeLogDetailsByWeek(this.timelogDetailModel);
  }

  onClientSelectionChange(clientId): void {
    this.clientId = clientId;

    this.getTasks();
    this.getUsers();
  }

  onTaskSelectionChange(event): void {
    this.taskList.forEach((element) => {
      element.data.forEach((ele) => {
        if (ele.name === event.option.value) {
          this.timeLogForm.controls.taskId.setValue(ele.name);

          this.taskId = ele.universalId!;
        }
      });
    });

    this.getSubTasks();
  }

  onTaskSearch(): void {
    this.taskId = this.defaultGuid;
    this.getUsers();
  }

  onSubTaskSelectionChange(subTaskId): void {
    this.subTaskId = subTaskId;

    if (subTaskId === this.defaultGuid) {
      this.isSubTaskBillable = false;
    } else {
      this.subTaskList.forEach((element) => {
        if (element.universalId === subTaskId) {
          this.isSubTaskBillable = element.isBillable;
        }
      });
    }

    this.timelogDetailModel.subTaskId = subTaskId;
    this.getTimeLogDetailsByWeek(this.timelogDetailModel);
  }

  onUserSelectionChange(user): void {
    this.userId = user.universalId;
    this.timelogDetailModel.userId = user.universalId;
    this.getTimeLogDetailsByWeek(this.timelogDetailModel);
  }

  getAvailableJobByRole(): void {
    this.store
      .dispatch(new GetAvailableJobByRole())
      .pipe(
        tap((res) => {
          this.jobList = res.job.allAvailableJobByRole;
          if (this.moduleId === this.moduleEnum.Jobs) {
            this.timeLogForm.controls.jobId.setValue(this.jobId);
          }

          this.getClients();
          if (this.moduleId === this.moduleEnum.Time) {
            if (!this.isAddMode) {
              setTimeout(() => {
                if (
                  this.timelogDetailModel.isDayView &&
                  this.timelogDetailModel.universalId !== this.defaultGuid
                ) {
                  this.getTimeLogDetailsByUniversalId(
                    this.timelogDetailModel.universalId
                  );
                } else {
                  this.getTimeLogDetailsByWeek(this.timelogDetailModel, true);
                }
              }, 1000);
            }
          }
        })
      )
      .subscribe();
  }

  getClients(isFromJob?: boolean): void {
    this.store
      .dispatch(new GetClients(this.jobId))
      .pipe(
        tap((res) => {
          this.clientList = res.time.clientList;
          this.timeLogForm.controls.clientId.setValue(this.defaultGuid);
          this.timeLogForm.controls.subTaskId.setValue(this.defaultGuid);

          if (this.jobId !== this.defaultGuid) {
            this.timeLogForm.controls.clientId.setValue(
              this.clientList[0]?.universalId
            );
            this.clientId = this.clientList[0]?.universalId;
            if (!isFromJob) {
              this.getTasks();
              this.getUsers();
            }
          }
        })
      )
      .subscribe();
  }

  getTasks(isFromJob?: boolean): void {
    this.store
      .dispatch(new GetTasks(this.jobId, this.clientId ?? this.defaultGuid))
      .pipe(
        tap((res) => {
          this.taskList = res.time.taskList;
          this.timeLogForm.controls.taskId.setValue(
            this.taskList[0]?.data[0]?.name
          );
          this.taskId = this.taskList[0]?.data[0]?.universalId;
          if (!isFromJob) {
            this.getSubTasks();
          }
        })
      )
      .subscribe();
  }

  getSubTasks(): void {
    this.store
      .dispatch(new GetSubTasks(this.jobId, this.taskId ?? this.defaultGuid))
      .pipe(
        tap((res) => {
          this.subTaskList = res.time.subTaskList;
          this.timeLogForm.controls.subTaskId.setValue(this.defaultGuid);
        })
      )
      .subscribe();
  }

  getUsers(): void {
    this.store
      .dispatch(new GetUsers(this.jobId, this.clientId ?? this.defaultGuid))
      .pipe(
        tap((res) => {
          this.userList = res.time.userList;
          this.timeLogForm.controls.userId.setValue(
            this.userList.length > 0 ? this.userList[0].universalId : ''
          );

          this.timeLogForm.controls.userId.setValue(this.userId);
          this.timeLogForm.controls.users.setValue(
            this.userList
              .filter((x) => x.universalId === this.userId)
              .map((x) => x.name)
          );
        })
      )
      .subscribe();
  }

  getTimeMode(): void {
    this.store
      .dispatch(new GetSettingsData())
      .pipe(
        tap(() => {
          this.timeModeId = this.store.selectSnapshot(
            SettingsState.getSettingData
          ).timeModel;

          this.minimumChargeableTime = this.store.selectSnapshot(
            SettingsState.getSettingData
          ).minimumChargeableTime;
        })
      )
      .subscribe();
  }

  onTimeChanged() {
    let t1 = this.getTimeInSeconds(this.startTime);
    let t2 = this.getTimeInSeconds(this.endTime);

    if (t2 < t1) {
      this.timeSpent = '00:00';
      this.isInvalidEndTime = true;
      return;
    } else {
      this.isInvalidEndTime = false;
    }

    let timeDifference = t1 - t2 < 0 ? t2 - t1 : t1 - t2;

    this.timeSpent = this.convertSecToTime(timeDifference);
  }

  timeToDecimal(time) {
    time = time.split(':');
    return Number(Number(Number(time[0]) + Number(time[1]) / 60).toFixed(2));
  }

  getTimeInSeconds(str) {
    let currentTime: any[] = [];

    currentTime = str.split(':');
    for (let i = 0; i < currentTime.length; i++) {
      currentTime[i] = parseInt(currentTime[i]);
    }

    let time = currentTime[0] * 60 * 60 + currentTime[1] * 60;

    return time;
  }

  convertSecToTime(time) {
    let hours = Math.floor(time / 3600);
    let hh = hours < 10 ? '0' + hours.toString() : hours.toString();
    let min = Math.floor((time % 3600) / 60);
    let mm = min < 10 ? '0' + min.toString() : min.toString();
    let ans = hh + ':' + mm;
    return ans;
  }

  getTimeLogDetailsByWeek(
    timelogDetailModel: TimelogDetailModel,
    isEditTimelog: boolean = false
  ): void {
    this.spinner.show();

    this.store
      .dispatch(new GetTimeLogDetailsByWeek(timelogDetailModel))
      .subscribe(
        (res) => {
          const timeLog = this.store.selectSnapshot(
            TimeState.getTimeLogDetails
          );
          this.setTimelogFormData(timeLog, isEditTimelog);
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getTimeLogDetailsByUniversalId(universalId: any): void {
    this.spinner.show();
    this.store
      .dispatch(new GetTimeLogDetailsByUniversalId(universalId))
      .subscribe(
        (res) => {
          const timeLog = this.store.selectSnapshot(
            TimeState.getTimeLogDetails
          );

          this.setTimelogFormData(timeLog);
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  setTimelogFormData(timeLog: any, isEditTimelog: boolean = false) {
    if (timeLog !== null) {
      if (!isEditTimelog) {
        this.timeLogForm.patchValue({
          description: timeLog.description ?? null,
        });
      } else {
        this.timeLogForm.patchValue({
          jobId: timeLog.jobId ?? null,
          client: timeLog.clientId ?? null,
          subTaskId: timeLog.subTaskId ?? null,
          userId: timeLog.userId ?? null,
          date: timeLog.date ?? new Date(),
          description: timeLog.description ?? null,
        });

        this.taskList?.forEach((element) => {
          this.timeLogForm.controls.taskId.setValue(
            element.data.find((x) => x.universalId === timeLog.taskId)?.name
          );
        });
      }
      this.setStartEndTime(timeLog);
    }

    this.checkApprovedRejectedTimelog(timeLog);
  }

  setStartEndTime(timeLog: any): void {
    this.timeSpent = timeLog.timeSpent === null ? '00:00' : timeLog.timeSpent;
    this.startTime = timeLog.startTime === null ? '00:00' : timeLog.startTime!;
    this.endTime = timeLog.endTime === null ? '00:00' : timeLog.endTime!;
  }

  checkApprovedRejectedTimelog(timeLog: any): void {
    this.isAlreadyApprovedRejectedTimelog =
      timeLog.status === TimelogStatus.Approved ||
      timeLog.status === TimelogStatus.Rejected;
    if (this.isAlreadyApprovedRejectedTimelog) {
      if (timeLog.status === TimelogStatus.Approved) {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.timelogAlreadyApprovedText
        );
      }
      if (timeLog.status === TimelogStatus.Rejected) {
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.timelogAlreadyRejectedText
        );
      }
    }
  }

  onDateChange(date: any): void {
    this.timelogDetailModel.date = moment(date.value).toDate();
    if (!this.timelogDetailModel.isDayView) {
      if (this.timeLogForm.invalid) {
        this.commonService.addValidation(this.timeLogForm);
        this.notifier.error(
          NotificationHeader.error,
          NotificationTextMessage.validationMessage
        );
      } else {
        this.getTimeLogDetailsByWeek(this.timelogDetailModel);
      }
    }
  }

  getGroupName(item) {
    return item.isAssigned ? 'Assigned' : 'Unassigned';
  }

  checkValidMinimumChargeableTime(): any {
    const minimumChargeableMinutes =
      this.minimumChargeableTime?.split(':')[1] ?? '00';
    const currentMinutes = this.timeSpent?.split(':')[1] ?? '00';

    const acceptableRanges: Array<[number, number]> = [];

    for (let i = 1; i <= 59; i += +minimumChargeableMinutes) {
      acceptableRanges.push([i, i + (+minimumChargeableMinutes - 2)]);
    }

    const isInUnacceptableRange = acceptableRanges.some(
      ([start, end]) => +currentMinutes >= start && +currentMinutes <= end
    );

    if (currentMinutes !== '00' && isInUnacceptableRange) {
      let detailText: string = NotificationDetails.minimumChargeableTimeMessage;
      let minutes = '00';
      switch (this.minimumChargeableTime) {
        case '00:15':
          minutes = '15';
          break;

        case '00:30':
          minutes = '30';
          break;

        case '00:45':
          minutes = '45';
          break;

        case '00:59':
          minutes = '59';
          break;

        default:
          break;
      }
      detailText = detailText.replace('#time', minutes);

      this.dialog
        .open(ConfirmationBoxComponent, {
          data: {
            moduleId: Modules.Time,
            type: ConfirmationType.ApplyMinimumChargeableTime,
            headerText:
              NotificationHeader.applyMinimumChargeableTimeConfirmation,
            detailText: detailText,
            totalNumberOfRecordSelected: 1,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          this.applyMinimumChargeableTime = false;
          if (result) {
            this.applyMinimumChargeableTime = true;
          }

          this.saveTimelog();
        });
    } else {
      this.saveTimelog();
    }
  }
}
