<div class="sidenav-body-content">
  <form [formGroup]="reminderForm" class="mt-1">
    <div class="form-field">
      <p class="fw-bold">To</p>
      <mat-form-field appearance="fill" class="w-636">
        <input
          matInput
          autocomplete="off"
          required
          maxlength="100"
          formControlName="sentTo"
        />
      </mat-form-field>
      <h5 class="notes-message">
        Separate multiple email addresses using the comma character
      </h5>
    </div>
    <div class="form-field">
      <p class="fw-bold">Subject</p>
      <mat-form-field appearance="fill" class="w-636">
        <input
          matInput
          autocomplete="off"
          required
          maxlength="100"
          formControlName="subject"
        />
      </mat-form-field>
    </div>
    <div class="form-field textarea mb-2">
      <p>Message</p>
      <mat-form-field class="w-636" appearance="fill">
        <textarea
          matInput
          formControlName="message"
          autocomplete="off"
          required
        ></textarea>
      </mat-form-field>
    </div>
  </form>
  <div class="content-body">
    <div class="sidenav-table sidenav-table-input">
      <div class="mat-table-wrapper contact-detail-table w-636">
        <table
          mat-table
          [dataSource]="reminderList"
          aria-describedby="User Task Table"
        >
          <ng-container matColumnDef="senton">
            <th mat-header-cell *matHeaderCellDef class="w-50">Sent on</th>
            <td mat-cell *matCellDef="let element">
              <span class="white-space-nowrap">
                {{ element.createdOn | date : "dd-MMM-yyyy" }}
              </span>
            </td>
          </ng-container>
          <ng-container matColumnDef="sentby">
            <th mat-header-cell *matHeaderCellDef>Sent by</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.sentFrom }}">{{
                  element.sentFrom
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="sentto">
            <th mat-header-cell *matHeaderCellDef>Sent to</th>
            <td mat-cell *matCellDef="let element">
              <app-show-more-added-data
                [element]="element.sentTo"
                [headerText]="'Sent Email Details'"
              >
              </app-show-more-added-data>
            </td>
          </ng-container>
          <ng-container matColumnDef="subject">
            <th mat-header-cell *matHeaderCellDef>Subject</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.subject }}">{{
                  element.subject
                }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let element">
              <div class="d-flex align-items-center">
                <span class="onelineclamp" title="{{ element.message }}">{{
                  element.message
                }}</span>
              </div>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="action-wrapper">
  <div class="content-body">
    <button
      class="action-button primary-button mr-1"
      type="button"
      mat-button
      disableRipple
      (click)="sendReminder()"
    >
      Send Reminder
    </button>
    <button
      class="action-button secondary-button mr-1"
      type="button"
      mat-button
      disableRipple
    >
      Cancel
    </button>
  </div>
</div>
