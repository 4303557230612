import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  ExpenseReport,
  ExpenseReportSummary,
  ExportParams,
  InvoiceReport,
  InvoiceReportSummary,
  ProjectReport,
  ReportExportParams,
  ReportParameters,
  ReportQueryParams,
  TimeCategoryReport,
  TimeReport,
  TimeReportModel,
  TimeReportParameters,
  TimeReportSummary,
  UserReportModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Report';

  constructor(private http: HttpClient, private commonService: CommonService) {}

  exportProjectReport(exportParams: ExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Report/exportProjectReport`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  exportTeammateReport(exportParams: ExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${environment.apiVersionUrl}/Report/exportTeammatesReport`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getTimeReport(
    reportParams: ReportParameters
  ): Observable<HttpResponse<Array<TimeReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Report/timeReport`,
      reportParams,
      httpOptions
    );
  }

  getProjectReport(
    reportParams: ReportParameters
  ): Observable<HttpResponse<Array<ProjectReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Report/projectReport`,
      reportParams,
      httpOptions
    );
  }

  getExpenseReport(
    reportParams: ReportParameters
  ): Observable<HttpResponse<Array<ExpenseReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Report/expenseReport`,
      reportParams,
      httpOptions
    );
  }

  getTeammateReport(
    reportParams: ReportParameters
  ): Observable<HttpResponse<Array<InvoiceReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Report/teammatesReport`,
      reportParams,
      httpOptions
    );
  }

  getTimeReportSummary(): Observable<TimeReportSummary> {
    return this.http.get<TimeReportSummary>(
      `${environment.apiVersionUrl}/Report/getOverallTimeStatus`
    );
  }

  getExpenseReportSummary(): Observable<ExpenseReportSummary> {
    return this.http.get<ExpenseReportSummary>(
      `${environment.apiVersionUrl}/Report/getOverallExpensesStatus`
    );
  }

  getInvoiceReportSummary(): Observable<InvoiceReportSummary> {
    return this.http.get<InvoiceReportSummary>(
      `${environment.apiVersionUrl}/Report/getOverallInvoiceStatus`
    );
  }

  getTimeReportByCategory(
    reportParams: ReportParameters
  ): Observable<HttpResponse<Array<TimeCategoryReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/Report/timeReportByCategory`,
      reportParams,
      httpOptions
    );
  }
  // 2.0 APIs call
  userReport(
    reportQueryParams: ReportQueryParams
  ): Observable<HttpResponse<Array<UserReportModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/userReport`,
      reportQueryParams,
      httpOptions
    );
  }

  exportUserReport(reportExportParams: ReportExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportUserReport`,
        JSON.stringify(reportExportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (reportExportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  timeReportList(
    reportQueryParams: TimeReportParameters
  ): Observable<HttpResponse<Array<TimeReportModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/timeReport`,
      reportQueryParams,
      httpOptions
    );
  }

  exportTimeReport(reportExportParams: ReportExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportTimeLogReport`,
        JSON.stringify(reportExportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (reportExportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  expenseReportList(
    reportQueryParams: TimeReportParameters
  ): Observable<HttpResponse<Array<TimeReportModel>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/expenseReport`,
      reportQueryParams,
      httpOptions
    );
  }

  exportExpenseReport(reportExportParams: ReportExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportExpenseReport`,
        JSON.stringify(reportExportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (reportExportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  invoiceReportList(
    reportParams: TimeReportParameters
  ): Observable<HttpResponse<Array<InvoiceReport>>> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      observe: 'response' as 'response',
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/invoiceReport`,
      reportParams,
      httpOptions
    );
  }

  exportInvoiceReport(reportExportParams: ReportExportParams): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportInvoiceReport`,
        JSON.stringify(reportExportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (reportExportParams.isPrint) {
            this.commonService.print(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }
}
