import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class GlobalComponent {
  constructor(
    private cookieService: CookieService //, private commonService: CommonService
  ) {}

  public getLoggedInAuthorizedRole(): number {
    const authorizedRole = this.cookieService.get('authorizedRole');
    return +authorizedRole;
  }

  public getLoggedInUserId(): Guid {
    const userId = this.cookieService.get('userId');
    return userId as unknown as Guid;
  }

  public getBusinessId(): number {
    const businessid = this.cookieService.get('businessid');

    return !!businessid ? +businessid : 0;
  }

  public isAdmin(): boolean {
    const isAdmin = this.cookieService.get('isAdmin');
    return !!isAdmin ? isAdmin === 'true' : false;
  }

  public isOwner(): boolean {
    const isOwner = this.cookieService.get('isOwner');
    return !!isOwner ? isOwner === 'true' : false;
  }

  public isManager(): boolean {
    const isManager = this.cookieService.get('isManager');
    return !!isManager ? isManager === 'true' : false;
  }

  public isRegularUser(): boolean {
    const isRegularUser = this.cookieService.get('isRegularUser');
    return !!isRegularUser ? isRegularUser === 'true' : false;
  }

  public themecolor(): string {
    const themecolor = this.cookieService.get('themecolor');
    return themecolor;
  }

  //remove this
  public getDefaultUniversalId(): string {
    return '00000000-0000-0000-0000-000000000000';
  }
}
