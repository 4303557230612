import { UserExpensesDetails } from './user-expenses-details';

export class UserExpenses {
  userId: string;
  name: string;
  clientId: number;
  clientName: string;
  projectId: number;
  projectName: string;
  totalExpense: number;
  expenses: Array<UserExpensesDetails>;
}
