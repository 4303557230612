import {
  BulkJobModel,
  EmailModel,
  ExportParams1,
  InvoiceParameters,
  JobCommentModel,
  JobFilesModel,
  JobGroupByTimelogParams,
  JobModel,
  JobQueryParams,
  JobRecurring,
  ReminderModel,
  ScheduleJobsModel,
  UpdateJobStatusModel,
} from '@app/core/models';
import { Guid } from 'guid-typescript';

export class GetAvailableUsers {
  static readonly type = '[JOB] Get Available Users';

  constructor(public clientId: Guid, public taskId: Guid, public jobId: Guid) {}
}

export class GetFeesType {
  static readonly type = '[JOB] Get Fees Type';

  constructor(public clientId: Guid, public taskId: Guid, public jobId: Guid) {}
}

export class GetJobFiles {
  static readonly type = '[JOB] Get Job File By Job ID';

  constructor(public jobId: Guid) {}
}

export class SaveJobFiles {
  static readonly type = '[JOB] Save Job File';

  constructor(public jobId: Guid, public jobFilesModel: Array<JobFilesModel>) {}
}

export class SaveJob {
  static readonly type = '[JOB] Save Job';

  constructor(public jobModel: JobModel) {}
}

export class GetJobDetailByUniversalId {
  static readonly type = '[JOB] Get Job Detail By UniversalId';

  constructor(public jobId: Guid) {}
}

export class SendJobReminderEmail {
  static readonly type = '[JOB] Send Job Reminder Email';

  constructor(public jobId: Guid, public reminderModel: ReminderModel) {}
}

export class SendJobEmail {
  static readonly type = '[JOB] Send Job Email';

  constructor(public jobId: Guid, public emailModel: EmailModel) {}
}

export class GetJobEmailList {
  static readonly type = '[JOB] Get Job EmailList';

  constructor(public jobId: Guid) {}
}

export class GetJobSubTaskByUniversalId {
  static readonly type = '[JOB] Get Job SubTask By UniversalId';

  constructor(public jobId: Guid) {}
}

export class SaveJobComment {
  static readonly type = '[JOB] Save Job Comment';

  constructor(public jobId: Guid, public emailModel: JobCommentModel) {}
}

export class GetJobCommentList {
  static readonly type = '[JOB] Get Job Comment List';

  constructor(public jobId: Guid) {}
}

export class GetAssignedUsersByJobId {
  static readonly type = '[JOB] Get Assigned Users By JobId';

  constructor(public jobId: Guid) {}
}

export class GetJobReminderList {
  static readonly type = '[JOB] Get Job ReminderList';

  constructor(public jobId: Guid) {}
}

export class GetJobDataByJobId {
  static readonly type = '[JOB] Get Job Data By Job ID';

  constructor(public jobId: Guid) {}
}

export class GetAllJobs {
  static readonly type = '[JOB] Get All Jobs';
}

export class GetAvailableJobByRole {
  static readonly type = '[JOB] get Available Job By Role';
}

export class GetJobSummaryByUniversalId {
  static readonly type = '[JOB] Get Job Summary By Universal ID';

  constructor(public jobId: Guid) {}
}

export class GetJobDetailList {
  static readonly type = '[JOB] Get Job Detail List';

  constructor(public jobQueryParams: JobQueryParams) {}
}

export class GetJobSubTaskDetailList {
  static readonly type = '[JOB] Get Job SubTask Detail List';

  constructor(public jobQueryParams: JobQueryParams) {}
}

export class GetJobSubTaskUserDetailList {
  static readonly type = '[JOB] Get Job SubTask User Detail List';

  constructor(public jobQueryParams: JobQueryParams) {}
}

export class GetJobSubTaskAndUsersDetails {
  static readonly type = '[JOB] Get Job SubTask And Users Details';

  constructor(public groupByParams: JobGroupByTimelogParams) {}
}

export class SaveJobRecurringSetting {
  static readonly type = '[JOB] Save Job Recurring Setting';

  constructor(public jobRecurring: JobRecurring) {}
}

export class UpdateRecurringJobStatus {
  static readonly type = '[JOB] Update Recurring Job Status';

  constructor(public jobId?: Guid) {}
}

export class GetJobRecurring {
  static readonly type = '[JOB] Get Job Recurring​';

  constructor(public jobId: Guid) {}
}

export class CreateScheduledJob {
  static readonly type = '[JOB] Create Scheduled Job';

  constructor(public scheduleJobsModel?: ScheduleJobsModel) {}
}

export class UpdateJobStatus {
  static readonly type = '[JOB] Update Job Status';

  constructor(public updateJobStatusModel?: UpdateJobStatusModel) {}
}

export class CompleteJobSubTask {
  static readonly type = '[JOB] Complete Job SubTask';

  constructor(public ids?: Array<Guid>) {}
}

export class GetJobTimelogChart {
  static readonly type = '[TIME] Get Job Timelog Chart';

  constructor(public jobId: Guid) {}
}

export class DeleteSelectedJob {
  static readonly type = '[JOB] Delete Selected Job';

  constructor(public jobIds?: Array<Guid>) {}
}

export class ExportJob {
  static readonly type = '[JOB] Export Job';

  constructor(public exportParams: ExportParams1) {}
}

export class GetJobByClientId {
  static readonly type = '[JOB] Get Job By ClientId';

  constructor(public universalId: Guid) {}
}

export class GetJobSubTaskByJobId {
  static readonly type = '[JOB] Get Job SubTask By JobId';

  constructor(public jobId: Guid) {}
}

export class GetJobInvoicesByJobId {
  static readonly type = '[JOB] Get Job Invoices By JobId';

  constructor(public jobId: Guid, public invoiceParams: InvoiceParameters) {}
}

export class SetJobId {
  static readonly type = '[JOB] Set Job ID';

  constructor(public jobId: Guid) {}
}

export class DeleteJobByScheduleId {
  static readonly type = '[JOB] Delete Job By Schedule Id';

  constructor(public ids?: Array<Guid>) {}
}

export class SaveBulkJob {
  static readonly type = '[JOB] Save Bulk Job';

  constructor(public bulkJobModel: BulkJobModel) {}
}
