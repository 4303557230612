import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { SideListModel, TaxesModel } from '../../models';
import { TaxesService } from '../../services';
import {
  ArchiveAndRestoreTaxes,
  CopyTaxes,
  CreateTaxes,
  DeleteSelectedTaxes,
  ExportTaxes,
  GetDefaultVatTaxes,
  GetTaxesData,
  GetTaxesDetailList,
  GetTaxesList,
  ImportTaxes,
  MarkTaxesAsDefault,
  SetTaxDefaultState,
  UpdateTaxes,
} from './taxes.action';

export class TaxesStateInfo {
  taxes: Array<TaxesModel>;
  taxesId?: string;
  taxesData?: TaxesModel;
  exported?: boolean;
  sideListModel?: Array<SideListModel>;
  vatTaxesList?: Array<TaxesModel>;
  totalRecord?: number;
  isLastPage?: boolean;
  highlightIDs?: Array<string>;
  importData?: any;
}

@State<TaxesStateInfo>({
  name: 'taxes',
  defaults: {
    taxes: [],
    taxesId: '',
  },
})
@Injectable()
export class TaxesState {
  constructor(private taxesService: TaxesService) {}

  @Selector()
  static getTaxesDetailList(state: TaxesStateInfo) {
    return state.taxes;
  }

  @Selector()
  static getTaxesId(state: TaxesStateInfo) {
    return state.taxesId;
  }

  @Selector()
  static getTaxesData(state: TaxesStateInfo) {
    return state.taxesData;
  }

  @Selector()
  static getTaxesNames(state: TaxesStateInfo) {
    return state.sideListModel;
  }

  @Selector()
  static totalRecord(state: TaxesStateInfo) {
    return state.totalRecord;
  }

  @Selector()
  static isLastPage(state: TaxesStateInfo) {
    return state.isLastPage;
  }

  @Selector()
  static getHighlightedIds(state: TaxesStateInfo) {
    return state.highlightIDs ?? [];
  }

  @Selector()
  static getImportData(state: TaxesStateInfo) {
    return state.importData;
  }

  @Action(GetTaxesDetailList, { cancelUncompleted: true })
  getTaxesDetailList(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: GetTaxesDetailList
  ) {
    return this.taxesService.getTaxesDetailList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const totalRecord = headers.TotalItemCount;
        setState({
          ...state,
          taxes: res.body!,
          totalRecord,
        });
      })
    );
  }

  @Action(CreateTaxes)
  createTaxes(
    { getState, patchState }: StateContext<TaxesStateInfo>,
    action: CreateTaxes
  ) {
    return this.taxesService.createTaxes(action.taxes).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.taxes,
          taxesId: res,
        });
      })
    );
  }

  @Action(GetTaxesData)
  getTaxesData(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: GetTaxesData
  ) {
    return this.taxesService.getTaxById(action.taxesId).pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          taxesData: res,
        });
      })
    );
  }

  @Action(UpdateTaxes)
  updatesTaxe(
    { getState, patchState }: StateContext<TaxesStateInfo>,
    action: UpdateTaxes
  ) {
    return this.taxesService.updateTax(action.taxes).pipe(
      tap((res) => {
        const state = getState();
        patchState({
          ...state.taxes,
          taxes: res,
          taxesId: action.taxes.universalId,
        });
      })
    );
  }

  @Action(DeleteSelectedTaxes)
  deleteSelectedTaxes(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: DeleteSelectedTaxes
  ) {
    return this.taxesService.deleteTaxes(action.taxesIds).pipe(
      tap(() => {
        const state = getState();

        const filteredTaxes = state.taxes.filter(
          (item) => !action.taxesIds?.includes(item.universalId ?? '')
        );

        const filteredTaxesForSideList = [];

        setState({
          ...state.taxes,
          taxes: filteredTaxes,
          sideListModel: filteredTaxesForSideList,
        });
      })
    );
  }

  @Action(ImportTaxes)
  importTaxes(
    { patchState }: StateContext<TaxesStateInfo>,
    action: ImportTaxes
  ) {
    return this.taxesService.importTaxes(action.fileImportRequestModel).pipe(
      tap((res) => {
        patchState({
          importData: res,
        });
      })
    );
  }

  @Action(ExportTaxes)
  exportTaxes(
    { getState, patchState }: StateContext<TaxesStateInfo>,
    action: ExportTaxes
  ) {
    return this.taxesService.exportTax(action.exportParams).pipe(
      switchMap((res) => {
        patchState({ exported: true });

        return EMPTY;
      })
    );
  }

  @Action(GetTaxesList, { cancelUncompleted: true })
  getTaxesList(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: GetTaxesList
  ) {
    return this.taxesService.getTaxesList(action.queryParams).pipe(
      tap((res) => {
        const state = getState();
        let taxDataSideList: SideListModel[] | null = [];
        const headers = JSON.parse(res.headers.get('Pagination')!);
        const isLastPage = headers.IsLastPage;
        if (
          action.queryParams.pageNumber &&
          action.queryParams.pageNumber > 1
        ) {
          taxDataSideList = state.sideListModel!;
          taxDataSideList = taxDataSideList.concat(res.body!);
        } else {
          taxDataSideList = res.body;
        }

        setState({
          ...state,
          sideListModel: taxDataSideList!,
          isLastPage,
        });
      })
    );
  }

  @Action(CopyTaxes)
  copyTaxes({ patchState }: StateContext<TaxesStateInfo>, action: CopyTaxes) {
    return this.taxesService.copyTaxes(action.taxesIds).pipe(
      tap((res) => {
        patchState({
          highlightIDs: res,
        });
      })
    );
  }

  @Action(ArchiveAndRestoreTaxes)
  archiveRestoreTaxes(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: ArchiveAndRestoreTaxes
  ) {
    return this.taxesService
      .archiveAndRestoreTaxes(action.taxesIds, action.isArchive)
      .pipe(
        tap((res) => {
          const state = getState();

          setState({
            ...state.taxes,
            taxes: state.taxes,
          });
        })
      );
  }

  @Action(MarkTaxesAsDefault)
  markTaxesAsDefault(
    { getState, setState }: StateContext<TaxesStateInfo>,
    action: MarkTaxesAsDefault
  ) {
    return this.taxesService
      .markAsDefault(action.taxesId, action.isMarkAsDefault)
      .pipe(
        tap(() => {
          const state = getState();

          const objIndex = state.taxes.findIndex(
            (obj) => obj.universalId === action.taxesId
          );
          const arr = state.taxes;
          Object.freeze(arr);
          const arrstateCopy = [...arr];
          Object.freeze(arrstateCopy[objIndex]);
          const objCopy = { ...arrstateCopy[objIndex] }; // 👈️ create copy
          objCopy.isDefault = action.isMarkAsDefault;
          arrstateCopy[objIndex] = objCopy;

          setState({
            ...state.taxes,
            taxes: arrstateCopy,
          });
        })
      );
  }

  @Action(SetTaxDefaultState)
  setTaxDefaultState({ patchState }: StateContext<TaxesStateInfo>) {
    patchState({
      highlightIDs: [],
    });
  }

  @Action(GetDefaultVatTaxes)
  getDefaultVatTaxes(
    { patchState }: StateContext<TaxesStateInfo>,
    action: GetDefaultVatTaxes
  ) {
    return this.taxesService.getDefaultVatTaxes().pipe(
      tap((res) => {
        patchState({
          vatTaxesList: res,
        });
      })
    );
  }
}
