import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ExportAction } from '../../enums';
import {
  ExportParams1,
  InvoiceBasicInfo,
  InvoiceDetail,
  InvoiceDynamicDetailGrid,
  InvoiceEmailModel,
  InvoiceHistoryModel,
  InvoiceParameters,
  InvoicePaymentModel,
  InvoiceRequest,
  InvoiceResponse,
  InvoiceSendAsLinkModel,
  Invoices,
  Note,
  QueryParams,
  RecurringSchedule,
  ReminderTypeModel,
  ServiceExpenseModel,
  SideListModel,
} from '../../models';
import { CommonService } from '../common/common.service';

@Injectable({
  providedIn: 'root',
})
export class InvoicesService {
  private readonly apiUrl = `${environment.apiVersionUrl}/`;
  private readonly routeBase = 'Invoice';

  paymentEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  paymentDelete: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private http: HttpClient, private commonService: CommonService) {}

  getInvoiceList(
    queryParams: QueryParams
  ): Observable<HttpResponse<Array<SideListModel>>> {
    return this.http.get<Array<SideListModel>>(
      `${this.apiUrl}${this.routeBase}/getInvoiceList?PageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&filter=${queryParams.filter}&sortBy=${queryParams.sortBy}&sortOrder=${queryParams.sortOrder}&search=${queryParams.search}&isRecurring=${queryParams.isRecurring}`,
      {
        observe: 'response',
      }
    );
  }

  getInvoiceDetailList(invoiceParams: InvoiceParameters): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService.postExecuteRequest(
      `${this.apiUrl}${this.routeBase}/executeInvoiceList`,
      JSON.stringify(invoiceParams),
      headers
    );
  }

  getInvoiceByUniversalId(universalId: Guid): Observable<Invoices> {
    return this.http.get<Invoices>(
      `${this.apiUrl}${this.routeBase}/getInvoiceByUniversalId/${universalId}`
    );
  }

  getInvoicePaymentsDetailList(
    queryParams: any
  ): Observable<Array<InvoicePaymentModel>> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<Array<InvoicePaymentModel>>(
      `${this.apiUrl}${this.routeBase}/executeInvoicePaymentList`,
      JSON.stringify(queryParams),
      headers
    );
  }

  ////////////////////////////

  getInvoices(
    invoiceParams: InvoiceParameters
  ): Observable<HttpResponse<Array<Invoices>>> {
    return this.http.get<Array<Invoices>>(
      `${this.apiUrl}${this.routeBase}/list?pageNumber=${invoiceParams.pageNumber}&pageSize=${invoiceParams.pageSize}&sortBy=${invoiceParams.sortBy}&sortOrder=${invoiceParams.sortOrder}&search=${invoiceParams.search}&isRecurring=${invoiceParams.isRecurring}&filter=${invoiceParams.filter}`,
      { observe: 'response', responseType: 'json' }
    );
  }

  deleteInvoice(invoiceId: Guid): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}${this.routeBase}/delete/${invoiceId}`
    );
  }

  deleteInvoicePayment(paymentId: string): Observable<boolean> {
    return this.http.delete<boolean>(
      `${this.apiUrl}${this.routeBase}/deleteInvoicePayment/${paymentId}`
    );
  }

  deleteAllInvoicePayment(universalId: Guid): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}${this.routeBase}/deleteAllPaymentsAndReminders/${universalId}`
    );
  }

  deleteInvoices(invoicesIds?: Array<Guid>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(invoicesIds),
    };
    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteInvoices`,
      options
    );
  }

  updateInvoiceStatus(
    invoiceIds?: Array<Guid>,
    status?: number
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateInvoiceStatus?action=${status}`,
      JSON.stringify(invoiceIds),
      headers
    );
  }

  exportInvoice(exportParams: ExportParams1): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportInvoice`,
        JSON.stringify(exportParams),
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportParams.isPrint) {
            this.commonService.print(body);
          } else if (exportParams.isView) {
            this.commonService.view(response);
          } else if (exportParams.isCopy) {
            this.commonService.copyLink(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  createInvoiceBasicInfo(invoiceBasicInfo: Invoices): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/create`,
      JSON.stringify(invoiceBasicInfo),
      headers
    );
  }

  createInvoicePayment(
    invoicePaymentData: InvoicePaymentModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createInvoicePayment`,
      JSON.stringify(invoicePaymentData),
      headers
    );
  }

  updateInvoicePayment(
    invoicePaymentData: InvoicePaymentModel
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateInvoicePayment`,
      JSON.stringify(invoicePaymentData),
      headers
    );
  }

  getPaymentByInvoiceId(invoiceId: Guid): Observable<InvoicePaymentModel> {
    return this.http.get<InvoicePaymentModel>(
      `${this.apiUrl}${this.routeBase}/getInvoiceProjectDetail/${invoiceId}`
    );
  }

  getAmountDueInvoiceId(invoiceId: Guid): Observable<number> {
    return this.http.get<number>(
      `${this.apiUrl}${this.routeBase}/getInvoiceAmountDue/${invoiceId}`
    );
  }

  getPaymentByPaymentId(paymentId: Guid): Observable<InvoicePaymentModel> {
    return this.http.get<InvoicePaymentModel>(
      `${this.apiUrl}${this.routeBase}/getInvoicePaymentByUniversalid/${paymentId}`
    );
  }

  updateInvoiceBasicInfo(invoiceData: Invoices): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/update`,
      JSON.stringify(invoiceData),
      headers
    );
  }

  createInvoiceDetail(
    invoiceDetailData: Array<InvoiceDetail>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createInvoiceDetail`,
      JSON.stringify(invoiceDetailData),
      headers
    );
  }

  updateInvoiceDetail(invoiceDetailData: InvoiceDetail[]): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put<any>(
      `${this.apiUrl}${this.routeBase}/updateInvoiceDetail`,
      JSON.stringify(invoiceDetailData),
      headers
    );
  }

  deleteInvoiceDetail(ids: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids,
      },
    };

    return this.http.delete<any>(
      `${this.apiUrl}${this.routeBase}/deleteInvoiceDetails`,
      options
    );
  }

  getInvoiceDetailById(id: number): Observable<Array<InvoiceDetail>> {
    return this.http.get<Array<InvoiceDetail>>(
      `${this.apiUrl}${this.routeBase}/getInvoiceDetail/${id}`
    );
  }

  saveInvoiceNotes(notes: Note): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/createNote`,
      JSON.stringify(notes),
      headers
    );
  }

  getInvoiceNoteList(
    invoiceId: Guid,
    searchText?: string
  ): Observable<Array<Note>> {
    return this.http.get<Array<Note>>(
      `${this.apiUrl}${this.routeBase}/list/notes/${invoiceId}?searchText=${searchText}`
    );
  }

  duplicateInvoice(invoiceId: Guid): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}${this.routeBase}/duplicateInvoice/${invoiceId}`,
      null
    );
  }

  exportInvoiceToPDF(
    id: Guid,
    format: number,
    fileName: string,
    exportAction: ExportAction
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportToPdf/${id}?format=${format}&fileName=${fileName}`,
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportAction === ExportAction.Print) {
            this.commonService.print(body);
          } else if (exportAction === ExportAction.View) {
            this.commonService.view(response);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  calulateInvoice(invoiceRequest: InvoiceRequest): Observable<InvoiceResponse> {
    const data = {
      invoiceId: invoiceRequest.invoiceId,
      typeId: invoiceRequest.typeId,
      projectId: invoiceRequest.projectId,
      taskId: invoiceRequest.taskId,
      expenseTypeId: invoiceRequest.expenseTypeId,
    };

    return this.http.post<InvoiceResponse>(
      `${this.apiUrl}${this.routeBase}/calulateInvoice`,
      data
    );
  }

  exportInvoicePaymentToPDF(
    id: number,
    exportAction: ExportAction
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportPaymentToPdf/${id}`,
        headers
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportAction === ExportAction.Print) {
            this.commonService.print(body);
          } else if (exportAction === ExportAction.View) {
            this.commonService.view(response);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  sendPaymentReceipt(paymentId: number): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/sendPaymentReceipt/${paymentId}`,
      null
    );
  }

  sendInvoice(invoiceId: Guid): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}${this.routeBase}/sendInvoiceReceipt/${invoiceId}`,
      null
    );
  }

  sendAsLinkInvoice(
    invoiceSendAsLinkModel: InvoiceSendAsLinkModel
  ): Observable<void> {
    const form = new FormData();
    form.append('invoiceId', invoiceSendAsLinkModel.invoiceId.toString());
    form.append('toEmail', invoiceSendAsLinkModel.toEmail.toString());
    form.append('invoiceLink', invoiceSendAsLinkModel.invoiceLink.toString());
    return this.http.post<void>(
      `${this.apiUrl}${this.routeBase}/sendAsLink`,
      form
    );
  }

  getScheduleSettingByInvoiceId(id: Guid): Observable<RecurringSchedule> {
    return this.http.get<RecurringSchedule>(
      `${environment.apiVersionUrl}/RecurringInvoice/getScheduleSettingByInvoiceId/${id}`
    );
  }

  saveRecurringSchedule(recurringInvoice: RecurringSchedule): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${environment.apiVersionUrl}/RecurringInvoice/saveRecurringSchedule`,
      JSON.stringify(recurringInvoice),
      headers
    );
  }

  copy(invoiceIds?: Array<Guid>): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/duplicateInvoicesByUniversalId`,
      JSON.stringify(invoiceIds),
      headers
    );
  }

  saveInvoice(invoiceBasicInfo: InvoiceBasicInfo): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveInvoice`,
      JSON.stringify(invoiceBasicInfo),
      headers
    );
  }

  sendInvoiceEmail(
    emailModel: InvoiceEmailModel,
    moduleName: string
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/sendInvoiceEmail`,
      JSON.stringify(emailModel),
      headers
    );
  }

  saveInvoicePayment(invoicePaymentData: InvoicePaymentModel): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveInvoicePayment`,
      JSON.stringify(invoicePaymentData),
      headers
    );
  }

  getInvoiceTemplate(
    invoiceId: Guid,
    templateId: number
  ): Observable<InvoiceEmailModel> {
    return this.http.get<InvoiceEmailModel>(
      `${this.apiUrl}${this.routeBase}/getInvoiceEmailTemplateByUniversalId?id=${invoiceId}&templateId=${templateId}`
    );
  }

  getInvoiceRecentHistory(id: Guid): Observable<InvoiceHistoryModel> {
    return this.http.get<InvoiceHistoryModel>(
      `${this.apiUrl}${this.routeBase}/getInvoiceRecentHistory/${id}`
    );
  }

  getReminderType(): Observable<Array<ReminderTypeModel>> {
    return this.http.get<Array<ReminderTypeModel>>(
      `${environment.apiVersionUrl}/Business/getReminderTypes`
    );
  }

  getReminderIds(invoiceId: Guid): Observable<Array<ReminderTypeModel>> {
    return this.http.get<Array<ReminderTypeModel>>(
      `${this.apiUrl}${this.routeBase}/getInvoiceReminders/${invoiceId}`
    );
  }

  saveReminder(
    invoiceId: Guid,
    reminderTypeModel: Array<ReminderTypeModel>
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveReminder/${invoiceId}`,
      JSON.stringify(reminderTypeModel),
      headers
    );
  }

  getUserInvoiceSetting(
    moduleId: number
  ): Observable<InvoiceDynamicDetailGrid> {
    return this.http.get<InvoiceDynamicDetailGrid>(
      `${this.apiUrl}${this.routeBase}/getUserInvoiceSetting/${moduleId}`
    );
  }

  saveInvoiceDyanamicGird(
    dynamicDetailData: InvoiceDynamicDetailGrid
  ): Observable<any> {
    const headers = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.post<any>(
      `${this.apiUrl}${this.routeBase}/saveUserInvoiceSetting`,
      JSON.stringify(dynamicDetailData),
      headers
    );
  }

  exportInvoicesToPDF(
    invoiceIds: Array<Guid>,
    exportAction: ExportAction
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.commonService
      .postExportRequest(
        `${this.apiUrl}${this.routeBase}/exportToPdf`,
        JSON.stringify(invoiceIds),
        options
      )
      .pipe(
        switchMap((response) => {
          const body: Blob = response.body || new Blob();
          if (exportAction === ExportAction.Print) {
            this.commonService.print(body);
          } else if (exportAction === ExportAction.View) {
            this.commonService.view(response);
          } else if (exportAction === ExportAction.CopyLink) {
            this.commonService.copyLink(body);
          } else {
            this.commonService.download(response);
          }
          return of(true);
        })
      );
  }

  getServiceExpenses(invoiceId: Guid): Observable<Array<ServiceExpenseModel>> {
    return this.http.get<Array<ServiceExpenseModel>>(
      `${this.apiUrl}${this.routeBase}/getServiceExpenses/${invoiceId}`
    );
  }

  getInvoiceReceiptDetails(universalId: Guid): Observable<any> {
    return this.http.get(
      `${this.apiUrl}${this.routeBase}/previewInvoiceReceipt/${universalId}`
    );
  }

  calculateInvoiceByClientId(clientId: Guid): Observable<any> {
    return this.http.get(
      `${this.apiUrl}${this.routeBase}/calculateInvoiceByClientId/${clientId}`
    );
  }
}
