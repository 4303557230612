import { Injectable } from '@angular/core';
import { SubscriptionService } from '@app/core/services/subscription';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  GetClientLimit,
  GetModuleRedirectionURL,
  RetrieveSubscription,
} from './subscription.action';

export class SubscriptionStateInfo {
  retrieveSubscriptionList: any;
  clientLimit: string;
  moduleRedirectionURL: string;
}

@State<SubscriptionStateInfo>({
  name: 'subscription',
  defaults: {
    retrieveSubscriptionList: [],
    clientLimit: '0',
    moduleRedirectionURL: '',
  },
})
@Injectable()
export class SubscriptionState {
  constructor(private subscriptionService: SubscriptionService) {}

  @Selector()
  static getModuleRedirectionURL(state: SubscriptionStateInfo) {
    return state.moduleRedirectionURL ?? '';
  }

  @Action(RetrieveSubscription)
  retrieveSubscription(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: RetrieveSubscription
  ) {
    return this.subscriptionService.retrieveSubscription().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          retrieveSubscriptionList: res,
        });
      })
    );
  }

  @Action(GetClientLimit)
  getClientLimit(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: GetClientLimit
  ) {
    return this.subscriptionService.getClientLimit().pipe(
      tap((res) => {
        const state = getState();
        setState({
          ...state,
          clientLimit: res,
        });
      })
    );
  }

  @Action(GetModuleRedirectionURL)
  getModuleRedirectionURL(
    { getState, setState }: StateContext<SubscriptionStateInfo>,
    action: GetModuleRedirectionURL
  ) {
    return this.subscriptionService
      .getModuleRedirectionURL(
        action.moduleId,
        action.userName,
        action.externalUserGuid
      )
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            moduleRedirectionURL: res.valueResult,
          });
        })
      );
  }
}
