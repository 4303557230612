<div class="content-body timelog-list timelog-overview">
  <div class="mat-table-wrapper">
    <table
      mat-table
      [dataSource]="dataSource"
      aria-describedby="User Task Table"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef class="w-230"></th>
        <td mat-cell *matCellDef="let element">
          <div
            class="timelist-view d-flex align-items-center justify-space-between gap-10"
          >
            <div class="wp-100">
              <div class="time-title onelineclamp">
                JI-01 (100%) (Capium India PVT Limited.)
              </div>
              <div class="d-flex pl-10">
                <div class="time-detail onelineclamp">
                  VAT Forms - Filling VAT Return Form A for return
                </div>
              </div>
            </div>
            <div class="time-coment">
              <mat-icon fontSet="material-icons-outlined">comment</mat-icon>
            </div>
          </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center"></td>
      </ng-container>
      <ng-container matColumnDef="weekone">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">3</div>
            <div>
              <div class="week font-size-12 line-height-normal">MON</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weekone }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          6:00
        </td>
      </ng-container>
      <ng-container matColumnDef="weektwo">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">4</div>
            <div>
              <div class="week font-size-12 line-height-normal">TUE</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weektwo }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          13:00
        </td>
      </ng-container>
      <ng-container matColumnDef="weekthree">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">5</div>
            <div>
              <div class="week font-size-12 line-height-normal">WED</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weekthree }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          19:20
        </td>
      </ng-container>
      <ng-container matColumnDef="weekfour">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">6</div>
            <div>
              <div class="week font-size-12 line-height-normal">THU</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weekfour }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          7:00
        </td>
      </ng-container>
      <ng-container matColumnDef="weekfive">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">7</div>
            <div>
              <div class="week font-size-12 line-height-normal">FRI</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weekfive }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          20:00
        </td>
      </ng-container>
      <ng-container matColumnDef="weeksix">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">8</div>
            <div>
              <div class="week font-size-12 line-height-normal">SAT</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weeksix }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          12:55
        </td>
      </ng-container>
      <ng-container matColumnDef="weekseven">
        <th mat-header-cell *matHeaderCellDef class="w-60 text-align-center">
          <div
            class="d-flex align-items-center justify-content-center gap-5 text-gray"
          >
            <div class="days font-size-26">9</div>
            <div>
              <div class="week font-size-12 line-height-normal">SUN</div>
              <div class="month font-size-10 line-height-normal">Apr</div>
            </div>
          </div>
        </th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          {{ element.weekseven }}
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">0</td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="w-60 text-align-center"
        ></th>
        <td mat-cell *matCellDef="let element" class="text-align-center">
          <span class="fw-bold">{{ element.total }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef class="text-align-center">
          67.20
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
  </div>
</div>
