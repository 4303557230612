<div class="sidenav-body-content live-page">
  <div class="content-body">
    <div class="mat-table-wrapper">
      <table
        mat-table
        [dataSource]="liveTimelogList"
        aria-describedby="Live Timelog Table"
      >
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef class="w-200">User</th>
          <td mat-cell *matCellDef="let element">
            {{ element.userName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="job">
          <th mat-header-cell *matHeaderCellDef class="wp-300">Job</th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="element.heading !== '-'"
              class="timelist-view d-flex align-items-center justify-space-between gap-20"
            >
              <div class="wp-100">
                <div class="time-title onelineclamp">
                  <img
                    *ngIf="
                      +element.heading?.split('|')[1] === feeType.TimeAndFee
                    "
                    src="assets/images/timeandfees.svg"
                    width="13px"
                    alt="Time and Fees"
                    matTooltip="Time and Fees"
                  />
                  <img
                    *ngIf="
                      +element.heading?.split('|')[1] === feeType.FixedPrice
                    "
                    src="assets/images/fixedfees.svg"
                    width="15px"
                    alt="Fixed Price"
                    matTooltip="Fixed Price"
                  />
                  <img
                    *ngIf="
                      +element.heading?.split('|')[1] === feeType.NonBillable
                    "
                    src="assets/images/nonbillable.svg"
                    width="14px"
                    alt="Non Billable"
                    matTooltip="Non Billable"
                  />
                  {{
                    element.heading.indexOf("|") > 0
                      ? element.heading?.split("|")[2]
                      : element.heading
                  }}
                  <mat-icon *ngIf="+element.heading.split('|')[3] === 1"
                    >south</mat-icon
                  >
                  <mat-icon
                    class="danger"
                    *ngIf="
                      element.heading.indexOf('|') > 0
                        ? +element.heading?.split('|')[3] === 0
                        : +element.heading === 0
                    "
                    >north</mat-icon
                  >
                  {{
                    element.heading.indexOf("|") > 0
                      ? element.heading?.split("|")[4]
                      : element.heading
                  }}
                </div>
                <div class="d-flex">
                  <div class="time-detail onelineclamp pl-10 ml-10">
                    {{
                      element.heading.indexOf("|") > 0
                        ? element.heading?.split("|")[5]
                        : element.heading
                    }}
                    -
                    {{
                      element.heading.indexOf("|") > 0
                        ? element.heading?.split("|")[7]
                        : element.heading
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              *ngIf="element.heading === '-'"
              class="timelist-view d-flex align-items-center justify-space-between gap-20"
            >
              -
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef class="w-200">Time</th>
          <td mat-cell *matCellDef="let element">
            <span class="success fw-bold">{{ element.timeSpent }}</span>
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </div>
</div>
