<div class="sidenav-body-content">
  <app-job-details-timelog-chart
    [triggerJobIdToChart]="triggerJobIdToChart"
  ></app-job-details-timelog-chart>
  <div class="content-header pt-5 pb-10 mb-5">
    <div class="content-header-input">
      <form [formGroup]="searchForm">
        <mat-form-field appearance="fill" class="search-field w-250">
          <input
            matInput
            placeholder="Search"
            autocomplete="off"
            formControlName="search"
            (keyup)="onSearch($event)"
          />
          <mat-icon>search</mat-icon>
        </mat-form-field>
      </form>
      <ng-select
        class="w-120"
        panelClass="myPanelClass"
        [disableOptionCentering]="true"
        #selectedType
        (focus)="selectedType.open()"
        (valueChange)="selectedType.close()"
        [(ngModel)]="selectedTypeId"
        (change)="onTypeChange($event)"
      >
        <ng-option *ngFor="let type of timelogTypeList" [value]="type.value">
          {{ type.name }}
        </ng-option>
      </ng-select>
      <mat-form-field appearance="fill" class="w-120">
        <mat-select
          panelClass="checkPanelClass"
          [disableOptionCentering]="true"
          [(ngModel)]="selectedUserIds"
          [ngModelOptions]="{ standalone: true }"
          multiple
          placeholder="Users"
          (selectionChange)="onUserSelectionChange()"
        >
          <mat-select-trigger class="trigger">
            Users ({{ selectedUserIds.length }})
          </mat-select-trigger>
          <mat-option
            *ngFor="let user of userList"
            [value]="user.universalId"
            title="{{ user.name }}"
            >{{ user.name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <div class="content-right-button">
      <div class="sidenav-content">
        <ng-select
          class="w-120 mr-10"
          panelClass="myPanelClass"
          [disableOptionCentering]="true"
          #selectStatus
          (focus)="selectStatus.open()"
          (valueChange)="selectStatus.close()"
          [(ngModel)]="selectedStatusId"
          [ngModelOptions]="{ standalone: true }"
          (change)="onStatusChange($event)"
        >
          <ng-option
            *ngFor="let status of timelogStatus | useEnumValue"
            [value]="status.key"
          >
            {{ status.value }}
          </ng-option>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="content-body">
    <div class="sidenav-content-body pt-0 pb-0">
      <div
        class="mat-table-wrapper"
        *ngIf="selectedTypeId === timelogTypes.ByUser"
      >
        <table
          mat-table
          [dataSource]="timelogByUserDataSource"
          matSort
          (matSortChange)="sorting(sort.active, sort.direction)"
          aria-describedby="Timelog by User Table"
        >
          <ng-container matColumnDef="fullName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-270">
              <div (click)="$event.stopPropagation()">
                <button mat-button (click)="toggleAllClick()">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      toggleAll ? "keyboard_arrow_down" : "keyboard_arrow_right"
                    }}
                  </mat-icon>
                </button>
              </div>
              <span [style.paddingLeft.px]="0"> User </span>
            </th>
            <td mat-cell *matCellDef="let data">
              <div class="d-flex align-items-center">
                <button
                  mat-icon-button
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 32"
                  (click)="treeControlByUser.toggle(data)"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControlByUser.isExpanded(data)
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_right"
                    }}
                  </mat-icon>
                </button>
                <span *ngIf="data.weekName; else ShowRange"
                  ><a
                    class="hyperlink"
                    title="{{ data.weekName }}"
                    (click)="overviewClick(data)"
                    >{{ data.weekName }}</a
                  ></span
                >
                <ng-template #ShowRange
                  ><span class="fw-bold" title="{{ data.fullName }}">
                    {{ data.fullName }}
                  </span></ng-template
                >
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="totalHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Hours
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalBillableHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Billable Hour
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalBillableHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalNonBillableHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Non Billable Hour
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalNonBillableHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let data">
              £ {{ data.totalAmount | number : "1.2-2" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalCost">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Cost</th>
            <td mat-cell *matCellDef="let data">
              <div class="d-flex align-items-center justify-space-between">
                <div>£ {{ data.totalCost | number : "1.2-2" }}</div>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedTimelogByUserColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedTimelogByUserColumns"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedTimelogByUserColumns.length"
            >
              <span
                *ngIf="
                  jobTimelogParams &&
                  !(jobTimelogParams.search || jobTimelogParams.status! >= 0)
                "
                >{{ commonNotificationText.NoRecordsFound }}</span
              >
              <span
                *ngIf="
                  jobTimelogParams &&
                  (jobTimelogParams.search || jobTimelogParams.status! >= 0)
                "
                >{{ commonNotificationText.NoRecordsFoundForFilter }}</span
              >
            </td>
          </tr>
        </table>
      </div>
      <div
        class="mat-table-wrapper"
        *ngIf="selectedTypeId === timelogTypes.ByWeek"
      >
        <table
          mat-table
          [dataSource]="timelogByWeekDataSource"
          matSort
          (matSortChange)="sorting(sort.active, sort.direction)"
          aria-describedby="Timelog by Week Table"
        >
          <ng-container matColumnDef="weekName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="w-270">
              <div (click)="$event.stopPropagation()">
                <button mat-button (click)="toggleAllClick()">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      toggleAll ? "keyboard_arrow_right" : "keyboard_arrow_down"
                    }}
                  </mat-icon>
                </button>
              </div>
              <span [style.paddingLeft.px]="0"> Date </span>
            </th>
            <td mat-cell *matCellDef="let data">
              <div class="d-flex align-items-center">
                <button
                  mat-icon-button
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 32"
                  (click)="treeControlByWeek.toggle(data)"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControlByWeek.isExpanded(data)
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_right"
                    }}
                  </mat-icon>
                </button>
                <span *ngIf="data.fullName; else ShowRange"
                  ><a class="hyperlink" title="{{ data.fullName }}">{{
                    data.fullName
                  }}</a></span
                >
                <ng-template #ShowRange
                  ><span
                    class="fw-bold"
                    title="{{ data.weekName }}"
                    (click)="overviewClick()"
                  >
                    {{ data.weekName }}
                  </span></ng-template
                >
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="totalHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Total Hours
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalBillableHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Billable Hour
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalBillableHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalNonBillableHours">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Non Billable Hour
            </th>
            <td mat-cell *matCellDef="let data">
              {{ data.totalNonBillableHours }}
            </td>
          </ng-container>
          <ng-container matColumnDef="totalAmount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Amount</th>
            <td mat-cell *matCellDef="let data">£ {{ data.totalAmount }}</td>
          </ng-container>
          <ng-container matColumnDef="totalCost">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Cost</th>
            <td mat-cell *matCellDef="let data">
              <div class="d-flex align-items-center justify-space-between">
                <div>£ {{ data.totalCost }}</div>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedTimelogByWeekColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedTimelogByWeekColumns"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td
              class="mat-cell"
              [ngClass]="'text-align-center'"
              [attr.colspan]="displayedTimelogByWeekColumns.length"
            >
              <span
                *ngIf="
                  jobTimelogParams &&
                  !(jobTimelogParams.search || jobTimelogParams.status! >= 0)
                "
                >{{ commonNotificationText.NoRecordsFound }}</span
              >
              <span
                *ngIf="
                  jobTimelogParams &&
                  (jobTimelogParams.search || jobTimelogParams.status! >= 0)
                "
                >{{ commonNotificationText.NoRecordsFoundForFilter }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
