<div class="header">
  <div class="d-flex align-items-center justify-space-between wp-100">
    <div class="d-flex align-items-center">
      <div class="logo-container">
        <a [routerLink]="'/'" *ngIf="businessLogo">
          <img
            class="report-landing-hero-image"
            [src]="businessLogo"
            alt="Logo"
          />
        </a>
        <a [routerLink]="'/'" *ngIf="!businessLogo" class="logo-text">
          <span>{{ businessName ? (businessName | shortName : 2) : "" }}</span>
        </a>
      </div>
      <div class="nav-container">
        <div class="navigation-menu">
          <ng-container *ngFor="let menu of menuList">
            <ng-container
              *ngIf="
                menu.ordinal !== modules.Setting &&
                menu.ordinal !== modules.Activity &&
                menu.ordinal !== modules.Announcement &&
                menu.ordinal !== modules.Live &&
                menu.ordinal !== modules.Standard
              "
            >
              <a
                routerLink="{{
                  menu.children && menu.children.length > 0
                    ? menu.children[0].url
                    : menu.url
                }}"
                [ngClass]="isActive(menu.url)"
              >
                <mat-icon>{{ menu.iconName }}</mat-icon>
                <span>{{ menu.name }}</span>
              </a>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="account-container">
      <div class="account-menu">
        <ul class="d-flex align-items-center">
          <li *ngIf="isShowLive">
            <button
              type="button"
              class="live-button primary-button"
              mat-button
              disableRipple
              [routerLink]="'/live'"
            >
              <mat-icon>sensors</mat-icon>
              <span>Live</span>
            </button>
          </li>
          <li *ngIf="manageSubMenuList && manageSubMenuList.length > 0">
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              [matMenuTriggerFor]="menuManages"
              title="Quick Add"
            >
              <mat-icon>add_circle_outline</mat-icon>
            </a>
            <mat-menu
              #menuManages="matMenu"
              class="action-menu quick-menu"
              xPosition="before"
            >
              <ng-container *ngFor="let subMenu of manageSubMenuList">
                <button mat-menu-item (click)="onHeaderPlusMenuClick(subMenu)">
                  <mat-icon fontSet="material-icons-outlined">{{
                    subMenu.iconName
                  }}</mat-icon
                  >{{ subMenu.name }}
                </button>
              </ng-container>
            </mat-menu>
          </li>
          <li *ngIf="isShowActivity">
            <a
              href="javascript:void(0)"
              (click)="getRecentActivity()"
              class="profile-setting-icon p-0"
              [matMenuTriggerFor]="activitySubMenus"
              title="Activity"
            >
              <mat-icon>history</mat-icon>
            </a>
            <mat-menu
              #activitySubMenus="matMenu"
              class="activity-menu"
              xPosition="before"
            >
              <div class="header-activity">
                <div class="notification">
                  <div class="notification-list">
                    <div class="notification-list-wrapper">
                      <p
                        class="text-primary notf-title font-size-16 fw-bold mb-1"
                      >
                        Recent Activity
                      </p>
                      <ul>
                        <ul>
                          <li *ngFor="let details of activityList">
                            <div class="d-flex gap-20">
                              <div class="notification-date d-flex flex-column">
                                <span class="date">{{
                                  details.activityDate
                                    | date : "MMM dd, y"
                                    | slice : 4 : 6
                                }}</span>
                                <span class="month">{{
                                  details.activityDate
                                    | date : "MMM d, y"
                                    | slice : 0 : 4
                                }}</span>
                              </div>
                              <div class="notification-body">
                                <div class="media d-flex">
                                  <div class="main-avatar avatar-md online">
                                    <span
                                      class="rounded-img rounded-img-text"
                                      >{{
                                        details.createdByName | shortName : 2
                                      }}</span
                                    >
                                  </div>
                                  <div class="media-body">
                                    <p class="status">
                                      <span class="fw-bold">{{
                                        details.createdByName
                                      }}</span>
                                      <a href="javascript:void(0)">
                                        <mat-icon
                                          title="add"
                                          *ngIf="
                                            details.entityTypeId ===
                                              entityTypes.Created ||
                                            details.entityTypeId ===
                                              entityTypes.NoteCreated
                                          "
                                          >post_add</mat-icon
                                        >
                                        <mat-icon
                                          title="update"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Updated
                                          "
                                          >update</mat-icon
                                        >
                                        <mat-icon
                                          title="delete"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Deleted
                                          "
                                          >delete</mat-icon
                                        >
                                        <mat-icon
                                          title="copy"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Copy
                                          "
                                          >file_copy</mat-icon
                                        >
                                        <mat-icon
                                          title="archive"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Archived
                                          "
                                          >archive</mat-icon
                                        >
                                        <mat-icon
                                          title="restore"
                                          *ngIf="
                                            details.entityTypeId ===
                                            entityTypes.Restored
                                          "
                                          >restore_from_trash
                                        </mat-icon>
                                        <mat-icon
                                          title="import/export"
                                          *ngIf="
                                            details.entityTypeId ===
                                              entityTypes.Imported ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedCSV ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedEXCEL ||
                                            details.entityTypeId ===
                                              entityTypes.ExportedPDF
                                          "
                                          >import_export</mat-icon
                                        >
                                      </a>
                                      <span [innerHTML]="details.message">
                                      </span>
                                      <span
                                        class="fw-bold"
                                        *ngIf="
                                          entityTypes.Sent !==
                                            details.entityTypeId &&
                                          entityTypes.Reminder !==
                                            details.entityTypeId &&
                                          entityTypes.Recorded !==
                                            details.entityTypeId &&
                                          entityTypes.Submitted !==
                                            details.entityTypeId &&
                                          entityTypes.Approved !==
                                            details.entityTypeId &&
                                          entityTypes.Withdrawn !==
                                            details.entityTypeId &&
                                          entityTypes.Status !==
                                            details.entityTypeId &&
                                          entityTypes.DeletePayment !==
                                            details.entityTypeId &&
                                          entityTypes.UpdatedBasicInfo !==
                                            details.entityTypeId &&
                                          entityTypes.UpdatedReminders !==
                                            details.entityTypeId
                                        "
                                        >{{ details.name }}</span
                                      >
                                    </p>
                                    <div
                                      class="d-flex align-items-center"
                                      *ngIf="details.hasAttachment"
                                    >
                                      <div
                                        class="d-flex gap-10"
                                        *ngFor="
                                          let files of details.attachments;
                                          let i = index
                                        "
                                      >
                                        <div>
                                          <span
                                            class="text-primary-400 fw-normal"
                                            >{{ files.attachmentName }}</span
                                          >
                                          <p class="time">{{ files.size }}</p>
                                        </div>
                                      </div>
                                    </div>
                                    <p class="time">
                                      at
                                      {{
                                        details.activityDate
                                          | date : "shortTime"
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </ul>
                      <div class="pt-10">
                        <a
                          class="hyperlink"
                          href="javascript:void(0)"
                          (click)="onAnnoucementActivity(1)"
                          >View All Activity</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-menu>
          </li>
          <li *ngIf="settingSubMenuList && settingSubMenuList.length > 0">
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              [matMenuTriggerFor]="settingSubMenus"
              title="Settings"
            >
              <mat-icon>settings_outline</mat-icon>
            </a>
            <mat-menu
              #settingSubMenus="matMenu"
              class="action-menu setting-menu"
              xPosition="before"
            >
              <ng-container *ngFor="let subMenu of settingSubMenuList">
                <button
                  mat-menu-item
                  disableRipple
                  (click)="onSubMenuClick(subMenu.url)"
                >
                  <mat-icon
                    fontSet="material-icons-outlined"
                    [ngClass]="
                      subMenu.ordinal === modules.Export ? 'export-icon' : ''
                    "
                    >{{ subMenu.iconName }}</mat-icon
                  >{{ subMenu.name }}
                </button>
              </ng-container>
            </mat-menu>
          </li>
          <li class="module-list separation pl-1">
            <a
              href="javascript:void(0)"
              [matMenuTriggerFor]="capiumMenu"
              title="Modules"
            >
              <div class="m-0">
                <img
                  class="profile-image-thumb"
                  src="assets/images/favicon.png"
                  width="22"
                  height="22"
                  alt="Capium Logo"
                />
              </div>
            </a>
            <mat-menu
              #capiumMenu="matMenu"
              class="action-menu dropdown-module-list"
              xPosition="after"
            >
              <div>
                <ul>
                  <ng-container
                    *ngFor="let subscriptionModule of subscriptionModuleList"
                  >
                    <li
                      class=""
                      [ngClass]="
                        subscriptionModule.isDisabled ? 'disable-box' : ''
                      "
                      *ngIf="subscriptionModule.isDisplay"
                    >
                      <a (click)="redirectToModule(subscriptionModule, $event)">
                        <div class="top-card d-flex gap-10">
                          <img [src]="subscriptionModule.iconSrc" alt="logo" />
                          <div>
                            <h6>{{ subscriptionModule.externalName }}</h6>
                            <p>{{ subscriptionModule.content }}</p>
                          </div>
                        </div>
                      </a>
                    </li>
                  </ng-container>
                </ul>
                <div class="top-switch-button text-align-right">
                  <button
                    type="button"
                    class="action-button primary-button"
                    mat-button
                    disableRipple
                    (click)="redirectToHome()"
                  >
                    <img
                      src="assets/images/capihome.svg"
                      class="pr-5"
                      alt="Icon"
                    />
                    <span>Home </span>
                  </button>
                  <button
                    type="button"
                    class="action-button secondary-button ml-1"
                    mat-button
                    disableRipple
                    (click)="myAdminClick($event)"
                  >
                    <img
                      src="assets/images/myadmin.svg"
                      class="pr-5"
                      alt="Icon"
                    />
                    <span>My Admin</span>
                  </button>
                </div>
              </div>
            </mat-menu>
          </li>
          <li *ngIf="isShowAnnouncement">
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              (click)="onAnnoucementActivity(0)"
              title="Announcement"
            >
              <mat-icon>campaign</mat-icon>
            </a>
            <mat-menu
              #announcementSubMenus="matMenu"
              class="activity-menu"
              xPosition="before"
            >
              <div class="header-activity">
                <div class="notification">
                  <div class="notification-list">
                    <div class="notification-list-wrapper">
                      <p
                        class="text-primary notf-title font-size-16 fw-bold mb-1"
                      >
                        Recent Announcement
                      </p>
                      <ul>
                        <li>
                          <div class="d-flex gap-20">
                            <div class="notification-date d-flex flex-column">
                              <span class="date">12</span>
                              <span class="month">May</span>
                            </div>
                            <div class="notification-body">
                              <div class="media d-flex">
                                <div class="main-avatar avatar-md online">
                                  <img
                                    class="rounded-img"
                                    src="../assets/images/user.jpg"
                                    alt="User"
                                  />
                                </div>
                                <div
                                  class="main-avatar avatar-md online hidden"
                                >
                                  <span class="rounded-img rounded-img-text"
                                    >MP</span
                                  >
                                </div>
                                <div class="media-body">
                                  <p class="status text-primary">
                                    <span class="fw-bold">Arena</span>
                                    <a href="javascript:void(0)">
                                      <mat-icon title="add">post_add</mat-icon>
                                    </a>
                                    <span class="fw-bold"
                                      >Uploaded Receipt RE231.</span
                                    >
                                  </p>
                                  <p
                                    class="time text-primary-400 fw-normal m-0"
                                  >
                                    at 11:20 am
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex gap-20">
                            <div class="notification-date d-flex flex-column">
                              <span class="date">13</span>
                              <span class="month">May</span>
                            </div>
                            <div class="notification-body">
                              <div class="media d-flex">
                                <div
                                  class="main-avatar avatar-md online hidden"
                                >
                                  <img
                                    class="rounded-img"
                                    src="../assets/images/user.jpg"
                                    alt="User"
                                  />
                                </div>
                                <div class="main-avatar avatar-md online">
                                  <span class="rounded-img rounded-img-text"
                                    >MP</span
                                  >
                                </div>
                                <div class="media-body">
                                  <p class="status text-primary">
                                    <span class="fw-bold">Arena</span>
                                    <a href="javascript:void(0)">
                                      <mat-icon title="add">post_add</mat-icon>
                                    </a>
                                    <span class="fw-bold"
                                      >Uploaded Receipt RE231.</span
                                    >
                                  </p>
                                  <p
                                    class="time text-primary-400 fw-normal m-0"
                                  >
                                    at 11:20 am
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="d-flex gap-20">
                            <div class="notification-date d-flex flex-column">
                              <span class="date">14</span>
                              <span class="month">May</span>
                            </div>
                            <div class="notification-body">
                              <div class="media d-flex">
                                <div class="main-avatar avatar-md online">
                                  <img
                                    class="rounded-img"
                                    src="../assets/images/user.jpg"
                                    alt="User"
                                  />
                                </div>
                                <div
                                  class="main-avatar avatar-md online hidden"
                                >
                                  <span class="rounded-img rounded-img-text"
                                    >MP</span
                                  >
                                </div>
                                <div class="media-body">
                                  <p class="status text-primary">
                                    <span class="fw-bold">Arena</span>
                                    <a href="javascript:void(0)">
                                      <mat-icon title="add">post_add</mat-icon>
                                    </a>
                                    <span class="fw-bold"
                                      >Uploaded Receipt RE231.</span
                                    >
                                  </p>
                                  <p
                                    class="time text-primary-400 fw-normal m-0"
                                  >
                                    at 11:20 am
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div class="pt-10">
                        <a
                          class="hyperlink"
                          href="javascript:void(0)"
                          (click)="onAnnoucementActivity(0)"
                          >View All Announcement</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-menu>
          </li>
          <li>
            <a [routerLink]="'/ticket'" title="Ticket">
              <mat-icon class="rotate" fontSet="material-icons-outlined"
                >confirmation_number</mat-icon
              >
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              class="profile-setting-icon p-0"
              title="Help"
              (click)="onHelpClick()"
            >
              <mat-icon>help_outline</mat-icon>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" [matMenuTriggerFor]="profileInfo">
              <div class="rounded-border m-0">
                <img
                  class="profile-image-thumb"
                  [src]="profileUrl"
                  *ngIf="profileUrl"
                  width="35"
                  height="35"
                  alt="User photo"
                  title="User Profile"
                />
                <img
                  class="profile-image-thumb"
                  src="assets/images/profile.svg"
                  *ngIf="!profileUrl"
                  width="35"
                  height="35"
                  alt="User photo"
                  title="User Profile"
                />
              </div>
            </a>
            <mat-menu
              #profileInfo="matMenu"
              class="action-menu profile-setting-menu"
              xPosition="after"
            >
              <div class="text-align-center profile-settings">
                <div class="d-flex justify-content-center">
                  <img
                    class="profile-image rounded-border"
                    [src]="profileUrl"
                    *ngIf="profileUrl"
                    alt="User photo"
                  />
                  <img
                    class="profile-image rounded-border"
                    src="assets/images/profile.svg"
                    *ngIf="!profileUrl"
                    alt="User photo"
                  />
                </div>
                <div>
                  <p
                    class="fw-bold text-transform-uppercase text-gray-100 font-size-16 mb-10 m-0"
                  >
                    {{ loggedInUserName }}
                  </p>
                </div>
                <div>
                  <p class="text-gray-200 mb-10 m-0">
                    User ID: {{ loggedInUserId }}
                  </p>
                </div>
                <div>
                  <p class="text-gray-200 mb-1 m-0">{{ loggedInUserEmail }}</p>
                </div>
                <div class="hr-top pt-1">
                  <a href="javascript:void(0)" (click)="myAdminClick($event)">
                    <span class="text-gray-100 font-size-16">My Account</span>
                  </a>
                  <span class="pl-2 pr-2 text-gray-100">|</span>
                  <a href="javascript:void(0)" (click)="logout()">
                    <span class="text-danger-100 font-size-16">Sign Out</span>
                  </a>
                </div>
                <div
                  class="pt-1 pb-5"
                  *ngIf="isSubscriptionPortalEnabled && isOwnerUser"
                >
                  <a
                    href="javascript:void(0)"
                    (click)="produdctSubscriptionClick()"
                    class="link"
                  >
                    <span class="text-primary font-size-16"
                      >Products & Subcriptions</span
                    >
                  </a>
                </div>
                <div class="theme-colors pt-1">
                  <mat-radio-group class="mt-5">
                    <mat-radio-button
                      *ngFor="let color of colors"
                      [value]="color"
                      [(ngModel)]="colorSelected"
                      ngDefaultControl
                      (change)="onSelectionChange($event.value, true)"
                      [ngClass]="{
                        'mat-radio-checked': colorSelected === color
                      }"
                    >
                      <button
                        class="theme-selector"
                        [ngStyle]="{ 'background-color': color }"
                        type="button"
                        mat-button
                        disableRipple
                      >
                        <mat-icon>check</mat-icon>
                      </button>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </mat-menu>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
