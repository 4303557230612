import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CommonNotificationText,
  Modules,
  NotificationDetails,
  NotificationHeader,
} from '@app/core/enums';
import { CommonService } from '@app/core/services';

@Component({
  selector: 'app-add-close-popup',
  templateUrl: './add-close-popup.component.html',
  styleUrls: ['./add-close-popup.component.scss'],
})
export class AddClosePopupComponent {
  headerText: string = CommonNotificationText.terminateProcessHeaderText;
  bodyText: string = CommonNotificationText.terminateProcessBodyText;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddClosePopupComponent>,
    private commonService: CommonService
  ) {
    if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.moduleId === Modules.Import
    ) {
      this.headerText = NotificationHeader.importHeaderText;
      this.bodyText = NotificationDetails.importBodyText;
    }
  }

  onYesClick(): void {
    if (
      this.data !== null &&
      this.data !== undefined &&
      this.data.moduleId === Modules.Import
    ) {
      this.dialogRef.close(true);
    } else {
      this.commonService.onListRouting(this.data.moduleId);
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
