import { Component, Input } from '@angular/core';
import { Modules } from '@app/core/enums';
import { GetSubTasks, GetUsers } from '@app/core/store';
import { Store } from '@ngxs/store';
import { Guid } from 'guid-typescript';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-show-more-added-data',
  templateUrl: './show-more-added-data.component.html',
  styleUrls: ['./show-more-added-data.component.scss'],
})
export class ShowMoreAddedDataComponent {
  modules = Modules;
  @Input()
  headerText: string;

  @Input()
  element: any;

  @Input()
  jobElement: any;

  @Input()
  moduleId: number;

  @Input()
  colIndex: number;

  constructor(private store: Store) {}

  getMoreDetails(): void {
    if (this.headerText === 'Sub Task Details') {
      this.getSubTasks();
    } else {
      this.getUsers();
    }
  }

  getUsers(): void {
    if (this.moduleId === Modules.Jobs) {
      this.store
        .dispatch(
          new GetUsers(this.jobElement[0], Guid.EMPTY as unknown as Guid)
        )
        .pipe(
          tap((res) => {
            this.element = res.time.userList;
          })
        )
        .subscribe();
    }
  }

  getSubTasks(): void {
    if (this.moduleId === Modules.Jobs) {
      this.store
        .dispatch(
          new GetSubTasks(this.jobElement[0], Guid.EMPTY as unknown as Guid)
        )
        .pipe(
          tap((res) => {
            this.element = res.time.subTaskList;
          })
        )
        .subscribe();
    }
  }
}
