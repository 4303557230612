import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationTextMessage } from '@app/core/enums';
import { UserWidgetModel } from '@app/core/models';
import { CommonService } from '@app/core/services';
import {
  DashboardState,
  GetUserWidgets,
  RevertToDefaultDashboardSetting,
  SaveUserWidgets,
} from '@app/core/store';
import { Store } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-widget-list',
  templateUrl: './widget-list.component.html',
  styleUrls: ['./widget-list.component.scss'],
})
export class WidgetListComponent implements OnInit {
  isAllTimeChartsSelected = false;
  isAllFeesChartsSelected = false;
  timeSectionChartList: UserWidgetModel[];
  feeSectionChartList: UserWidgetModel[];
  userWidgetsList: UserWidgetModel[];
  @Output()
  readonly triggerRefreshDashboard = new EventEmitter<any>();
  @Output()
  readonly triggerOnCancelClick = new EventEmitter<any>();
  @Input()
  triggerWidgetList: any;
  constructor(
    public store: Store,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.triggerWidgetList.subscribe((res) => {
      this.getUserWidgets();
    });
  }

  getUserWidgets(): void {
    this.spinner.show();
    this.store
      .dispatch(new GetUserWidgets())
      .pipe(
        tap((res) => {
          this.userWidgetsList = this.store.selectSnapshot(
            DashboardState.getUserWidgets
          );

          this.timeSectionChartList = this.userWidgetsList.filter(
            (x) => x.widgetTypeId === 1
          );
          this.feeSectionChartList = this.userWidgetsList.filter(
            (x) => x.widgetTypeId === 2
          );

          this.isAllTimeChartsSelected =
            this.timeSectionChartList.length > 0
              ? this.timeSectionChartList.every(
                  (item: any) => item.isEnable === true
                )
              : false;

          this.isAllFeesChartsSelected =
            this.feeSectionChartList.length > 0
              ? this.feeSectionChartList.every(
                  (item: any) => item.isEnable === true
                )
              : false;
        })
      )
      .subscribe(() => {});
  }

  createDashboard(): void {
    this.spinner.show();
    const userWidgetModel = this.userWidgetsList;

    this.store.dispatch(new SaveUserWidgets(userWidgetModel)).subscribe(() => {
      this.triggerRefreshDashboard.emit();
    });
  }

  resetToDefaultClick(): void {
    this.store
      .dispatch(new RevertToDefaultDashboardSetting())
      .pipe(
        tap((res) => {
          if (res.dashboard.isRevertedToDefault) {
            this.triggerRefreshDashboard.emit();
            this.commonService.onSuccess(
              NotificationTextMessage.successMessage
            );
          } else {
            this.commonService.onFailure(NotificationTextMessage.errorMessage);
          }
        })
      )
      .subscribe();
  }

  onCancelClick(): void {
    this.triggerOnCancelClick.emit();
  }

  selectAllTimeCharts(event: any): void {
    this.timeSectionChartList.forEach((x) => (x.isEnable = event.checked));
  }

  selectAllFeesCharts(event: any): void {
    this.feeSectionChartList.forEach((x) => (x.isEnable = event.checked));
  }

  selectTimeSectionChartWidget(): void {
    this.isAllTimeChartsSelected = this.timeSectionChartList.every(
      (item: any) => item.isEnable === true
    );
  }

  selectFeeSectionChartWidget(): void {
    this.isAllFeesChartsSelected = this.feeSectionChartList.every(
      (item: any) => item.isEnable === true
    );
  }
}
