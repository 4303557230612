<ejs-chart
  *ngIf="billableData?.length !== 0 && nonBillableData?.length !== 0"
  style="display: block"
  class="fees-dash-height"
  [primaryXAxis]="primaryXAxis"
  [primaryYAxis]="primaryYAxis"
  [tooltip]="tooltip"
  [legendSettings]="legend"
>
  <e-series-collection>
    <e-series
      fill="#7CB5EC"
      groupName="A"
      [dataSource]="billableData"
      type="Column"
      name="Billable"
      xName="x"
      yName="y"
      width="0"
      tooltipMappingName="tooltipMappingName"
    >
    </e-series>
    <e-series
      fill="#13CD9D"
      groupName="B"
      [dataSource]="nonBillableData"
      name="Non Billable"
      type="Column"
      columnWidth="0.6"
      xName="x"
      yName="y"
      width="0"
      tooltipMappingName="tooltipMappingName"
    >
    </e-series>
  </e-series-collection>
</ejs-chart>

<div
  class="norecord-chart"
  *ngIf="billableData?.length === 0 && nonBillableData?.length === 0"
>
  <img src="assets/images/column-icon.svg" alt="Column Chart" />
  <p class="pt-10">No data available yet!</p>
</div>
