import { Guid } from 'guid-typescript';
import { CalendarDetailParameters } from '../../models';

export class GetCalendarDetail {
  static readonly type = '[CALENDAR] Get Calendar Detail List';

  constructor(public calendarDetailParameters: CalendarDetailParameters) {}
}

export class GetCalendarUsers {
  static readonly type = '[CALENDAR] Get Calendar Users';

  constructor(public calendarDetailParameters: CalendarDetailParameters) {}
}

export class GetDateWiseJobList {
  static readonly type = '[CALENDAR] Get Date Wise JobList';

  constructor(public date: string, public userId: Guid) {}
}
