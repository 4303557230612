import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { GlobalComponent } from '../../models';
import {
  GetClientByInvoiceAmount,
  GetInvoiceAmountByCategory,
  GetEstimateCountByStatus,
  GetInvoiceAmountByInvoiceMonth,
  GetInvoiceBalanceAmountByDueMonth,
  GetInvoiceOverviewCardData,
  GetClientBalanceByInvoiceId,
  GetInvoiceIncomeTrend,
  GetPaymentAmountByPaymentMethod,
  GetRevenueFlow,
  GetInvoiceCountByStatus,
} from './overview.action';
import { OverviewService } from '@app/core/services/overview/overview.service';

export class OverviewStateInfo {
  invoiceOverviewCardData?: any;
  invoiceAmountByInvoiceMonthData?: any;
  estimateStatusData?: any;
  invoiceBalanceAmountByDueMonth?: any;
  invoiceAmountByCategory?: any;
  clientByInvoiceAmount?: any;
  paymentStatusData?: any;
  clientBalanceByInvoiceId?: any;
  invoiceIncomeTrend?: any;
  revenueFlowData?: any;
  invoiceCountByStatus?: any;
}

@State<OverviewStateInfo>({
  name: 'overview',
  defaults: {},
})
@Injectable()
export class OverviewState {
  constructor(
    private overviewService: OverviewService,
    public globalComponent: GlobalComponent
  ) {}

  @Action(GetInvoiceOverviewCardData, { cancelUncompleted: true })
  getInvoiceOverviewCardData(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceOverviewCardData
  ) {
    return this.overviewService
      .getInvoiceOverviewCardData(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceOverviewCardData: res,
          });
        })
      );
  }

  @Action(GetInvoiceAmountByInvoiceMonth, { cancelUncompleted: true })
  getInvoiceAmountByInvoiceMonth(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceAmountByInvoiceMonth
  ) {
    return this.overviewService
      .getInvoiceAmountByInvoiceMonth(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceAmountByInvoiceMonthData: res,
          });
        })
      );
  }

  @Action(GetInvoiceBalanceAmountByDueMonth, { cancelUncompleted: true })
  getInvoiceBalanceAmountByDueMonth(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceBalanceAmountByDueMonth
  ) {
    return this.overviewService
      .getInvoiceBalanceAmountByDueMonth(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceBalanceAmountByDueMonth: res,
          });
        })
      );
  }

  @Action(GetEstimateCountByStatus, { cancelUncompleted: true })
  getEstimateCountByStatus(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetEstimateCountByStatus
  ) {
    return this.overviewService
      .getEstimateCountByStatus(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            estimateStatusData: res,
          });
        })
      );
  }

  @Action(GetInvoiceAmountByCategory, { cancelUncompleted: true })
  getInvoiceAmountByCategory(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceAmountByCategory
  ) {
    return this.overviewService
      .getInvoiceAmountByCategory(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceAmountByCategory: res,
          });
        })
      );
  }

  @Action(GetClientByInvoiceAmount, { cancelUncompleted: true })
  getClientByInvoiceAmount(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetClientByInvoiceAmount
  ) {
    return this.overviewService
      .getClientByInvoiceAmount(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            clientByInvoiceAmount: res,
          });
        })
      );
  }

  @Action(GetClientBalanceByInvoiceId, { cancelUncompleted: true })
  getClientBalanceByInvoiceId(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetClientBalanceByInvoiceId
  ) {
    return this.overviewService
      .getClientBalanceByInvoiceId(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            clientBalanceByInvoiceId: res,
          });
        })
      );
  }

  @Action(GetInvoiceIncomeTrend, { cancelUncompleted: true })
  getInvoiceIncomeTrend(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceIncomeTrend
  ) {
    return this.overviewService
      .getInvoiceIncomeTrend(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceIncomeTrend: res,
          });
        })
      );
  }

  @Action(GetPaymentAmountByPaymentMethod, { cancelUncompleted: true })
  getPaymentAmountByPaymentMethod(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetPaymentAmountByPaymentMethod
  ) {
    return this.overviewService
      .getPaymentAmountByPaymentMethod(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            paymentStatusData: res,
          });
        })
      );
  }

  @Action(GetRevenueFlow, { cancelUncompleted: true })
  getRevenueFlow(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetRevenueFlow
  ) {
    return this.overviewService
      .getRevenueFlow(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            revenueFlowData: res,
          });
        })
      );
  }

  @Action(GetInvoiceCountByStatus, { cancelUncompleted: true })
  getInvoiceCountByStatus(
    { getState, setState }: StateContext<OverviewStateInfo>,
    action: GetInvoiceCountByStatus
  ) {
    return this.overviewService
      .getInvoiceCountByStatus(action.invoiceOverviewRequestModel)
      .pipe(
        tap((res) => {
          const state = getState();
          setState({
            ...state,
            invoiceCountByStatus: res,
          });
        })
      );
  }
}
