<div id="chart" class="cst-tooltip">
  <apx-chart
    [series]="chartOptions.series!"
    [chart]="chartOptions.chart!"
    [dataLabels]="chartOptions.dataLabels!"
    [plotOptions]="chartOptions.plotOptions!"
    [legend]="chartOptions.legend!"
    [responsive]="chartOptions.responsive!"
    [xaxis]="chartOptions.xaxis!"
    [tooltip]="chartOptions.tooltip!"
    [fill]="chartOptions.fill!"
  >
  </apx-chart>
</div>
