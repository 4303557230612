export class ProjectUsers {
  clientId: string;
  clientName: number;
  clientProjects: Array<ProjectUserDetail>;
}

export class ProjectUserDetail {
  budgetPerMonth: number;
  fullName: string;
  hourlyRate: number;
  hours: string;
  isAssign: boolean;
  isManager: boolean;
  userId: string;
  projectId: string;
  photo: string;
}
