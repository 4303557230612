export const FilterTypes: Array<any> = [
  { id: 1, name: 'This Week' },
  { id: 2, name: 'Last Week' },
  { id: 3, name: 'This Month' },
  { id: 4, name: 'Last Month' },
  { id: 5, name: 'This Quarter' },
  { id: 6, name: 'Last Quarter' },
  { id: 7, name: 'This Year' },
  { id: 8, name: 'Last Year' },
  { id: 9, name: 'Custom' },
];

export const JobFilterTypes: Array<any> = [
  { id: 1, name: 'Today' },
  { id: 2, name: 'Yesterday' },
  { id: 3, name: 'This Week' },
  { id: 4, name: 'Last Week' },
  { id: 5, name: 'This Month' },
  { id: 6, name: 'Last Month' },
  { id: 7, name: 'This Quarter' },
  { id: 8, name: 'Last Quarter' },
  { id: 9, name: 'This Year' },
  { id: 10, name: 'Last Year' },
  { id: 11, name: 'Custom' },
];

export const RevenueChartFilterTypes: Array<any> = [
  { id: 1, name: 'Weekly' },
  { id: 2, name: 'Monthly' },
  { id: 3, name: 'Quarterly' },
  { id: 4, name: 'Yearly' },
];
