<div class="d-flex h-100">
  <app-side-list
    [getModuleId]="moduleId"
    (showSideListAction)="showSideListAction($event)"
    (toggleSideList)="toggleSideList()"
    [reloadSideList]="reloadSideList"
  ></app-side-list>
  <div
    class="content-main-box form"
    [ngClass]="[
      istoggleSideList ? 'hide-sidelist' : '',
      !isHideSideList ? 'form' : ''
    ]"
    *ngIf="!isShowSideListAction"
  >
    <div class="sidenav-body">
      <div class="sidenav-body-content" *ngIf="!showOverview">
        <div>
          <div class="mat-dialog-wrapper form-list-content p-0 mb-1">
            <div class="main-contain">
              <div class="sidenav-header">
                <div class="d-flex align-items-center justify-space-between">
                  <h2>
                    <span>{{ headerText }}</span>
                  </h2>
                  <div>
                    <button
                      mat-button
                      class="header-text-close d-flex align-items-center"
                      disableRipple
                      (click)="onCloseClick()"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="alert-message" *ngIf="isViewPermission$ | async">
            <mat-icon class="material-icons-outlined">error_outline</mat-icon>
            {{ commonNotificationText.ViewOnlyAccessMessage }}
          </div>
          <div class="job-detail-card">
            <div class="job-wrapper">
              <div
                class="job-card d-flex align-items-center justify-space-between pt-0 pb-0"
              >
                <div class="job-timer d-flex gap-10">
                  <div
                    class="listview"
                    [ngClass]="(isViewPermission$ | async) ? 'is-readonly' : ''"
                    (click)="
                      jobSummaryData?.status === jobStatusModel.Completed
                        ? updateStatus(jobStatusModel.NotYetStarted)
                        : updateStatus(jobStatusModel.Completed)
                    "
                  >
                    <img
                      [src]="
                        jobSummaryData?.status === jobStatusModel.Completed
                          ? 'assets/images/check.svg'
                          : 'assets/images/uncheck.svg'
                      "
                      [alt]="
                        jobSummaryData?.status === jobStatusModel.Completed
                          ? 'Check'
                          : 'Uncheck'
                      "
                      width="20px"
                      class="cursor-pointer"
                      [matTooltip]="
                        jobSummaryData?.status === jobStatusModel.Completed
                          ? 'Mark as yet to start'
                          : 'Mark as completed'
                      "
                    />
                  </div>
                  <div>
                    <p><strong> Job : </strong> {{ jobDetailText }}</p>
                    <div>
                      {{ jobSummaryData?.startDate | date : "dd-LLL-yyyy" }} -
                      <span
                        [ngClass]="
                          isEndDateGreaterCurrentDate ? '' : 'text-red'
                        "
                        >{{
                          jobSummaryData?.endDate | date : "dd-LLL-yyyy"
                        }}</span
                      >
                      <button
                        class="action-button secondary-button mr-10 ml-10 box-shadow-none"
                        mat-dialog-close
                        disableRipple
                        type="button"
                        mat-button
                        disableRipple
                      >
                        <mat-icon fontSet="material-icons-outlined"
                          >timer</mat-icon
                        >
                        Est: {{ jobSummaryData?.estimatedTime }}
                      </button>
                      <button
                        class="action-button secondary-button box-shadow-none"
                        mat-dialog-close
                        disableRipple
                        type="button"
                        mat-button
                        disableRipple
                      >
                        <mat-icon fontSet="material-icons-outlined"
                          >watch_later</mat-icon
                        >
                        Log: {{ jobSummaryData?.loggedTime }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div id="chart">
                    <apx-chart
                      [series]="chartOptions.series!"
                      [chart]="chartOptions.chart!"
                      [plotOptions]="chartOptions.plotOptions!"
                      [labels]="chartOptions.labels!"
                      [fill]="chartOptions.fill!"
                    ></apx-chart>
                  </div>
                  <div class="progress-status text-primary font-size-14 pr-1">
                    <div>
                      {{
                        jobSummaryData?.totalCompleted +
                          "/" +
                          jobSummaryData?.totalSubTask
                      }}
                    </div>
                    Completed
                  </div>
                  <button
                    class="action-button secondary-button mr-1"
                    mat-dialog-close
                    disableRipple
                    type="button"
                    mat-button
                    disableRipple
                    *ngIf="jobSummaryData?.status !== jobStatusModel.Completed"
                    (click)="openTimelogDialog()"
                  >
                    <mat-icon fontSet="material-icons-outlined"
                      >watch_later</mat-icon
                    >
                    Log Time
                  </button>
                  <button
                    class="action-button secondary-button"
                    mat-dialog-close
                    disableRipple
                    type="button"
                    mat-button
                    disableRipple
                    (click)="startTimer()"
                    *ngIf="jobSummaryData?.status !== jobStatusModel.Completed"
                  >
                    <mat-icon fontSet="material-icons-outlined">timer</mat-icon>
                    Start Timer
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="job-detail-tab">
            <mat-tab-group
              mat-align-tabs="start"
              disableRipple
              animationDuration="0ms"
              [selectedIndex]="selectedIndex"
              (selectedTabChange)="tabClick($event)"
            >
              <ng-container *ngFor="let subMenu of subMenusChildren$ | async">
                <mat-tab
                  *ngIf="
                    subMenu.ordinal === modules.JobDetail ||
                    subMenu.ordinal === modules.JobTimelogs ||
                    subMenu.ordinal === modules.JobEmail ||
                    subMenu.ordinal === modules.JobRecurring ||
                    subMenu.ordinal === modules.JobFiles ||
                    subMenu.ordinal === modules.JobComment ||
                    subMenu.ordinal === modules.JobActivity ||
                    subMenu.ordinal === modules.JobInvoice
                  "
                  label="{{ subMenu.name }}"
                >
                  <ng-container *ngIf="subMenu.ordinal === modules.JobDetail">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >task_alt</mat-icon
                      >
                      {{ subMenu.name }}
                    </ng-template>
                    <app-job-details-subtask
                      [triggerJobId]="triggerJobId"
                      [triggerJobSubtaskData]="triggerJobSubtaskData"
                      (reloadJobDetails)="editJob()"
                    ></app-job-details-subtask>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobTimelogs">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >watch_later</mat-icon
                      >
                      {{ subMenu.name }}
                    </ng-template>
                    <app-job-details-timelog
                      [triggerJobTimelogData]="triggerJobTimelogData"
                      [triggerJobTimelogs]="triggerJobTimelogs"
                      (overviewListClick)="overviewClick($event)"
                    ></app-job-details-timelog>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobEmail">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >alternate_email</mat-icon
                      >
                      {{ subMenu.name }}
                      <span>{{ emailCount }}</span>
                    </ng-template>
                    <app-job-details-email
                      [triggerJobId]="triggerJobId"
                      (triggerEmailCount)="triggerEmailCount($event)"
                    ></app-job-details-email>
                  </ng-container>
                  <ng-container
                    *ngIf="subMenu.ordinal === modules.JobRecurring"
                  >
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >library_books</mat-icon
                      >
                      {{ subMenu.name }}
                      <span class="dot" *ngIf="isRecurringExist"></span>
                    </ng-template>
                    <app-job-details-recurring
                      [triggerJobId]="triggerJobId"
                      (triggerIsRecurringExist)="
                        triggerIsRecurringExist($event)
                      "
                    ></app-job-details-recurring>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobFiles">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >attach_file</mat-icon
                      >
                      Files
                      <span>{{ filesCount }}</span>
                    </ng-template>
                    <app-job-details-files
                      [triggerJobId]="triggerJobId"
                      (triggerFilesCount)="triggerFilesCount($event)"
                    ></app-job-details-files>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobComment">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >comment</mat-icon
                      >
                      Comments
                      <span>{{ commentCount }}</span>
                    </ng-template>
                    <app-job-details-comments
                      [triggerJobId]="triggerJobId"
                      (triggerCommentCount)="triggerCommentCount($event)"
                    ></app-job-details-comments>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobActivity">
                    <ng-template mat-tab-label (click)="goToActivity()">
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >task_alt</mat-icon
                      >
                      Activity
                      <span>{{ activityCount }}</span>
                    </ng-template>
                    <app-job-details-activity
                      [triggerJobId]="triggerJobId"
                      (triggerActivityCountToTab)="
                        triggerActivityCountToTab($event)
                      "
                    ></app-job-details-activity>
                  </ng-container>
                  <ng-container *ngIf="subMenu.ordinal === modules.JobInvoice">
                    <ng-template mat-tab-label>
                      <mat-icon
                        class="example-tab-icon"
                        fontSet="material-icons-outlined"
                        >sticky_note_2</mat-icon
                      >
                      Invoice
                    </ng-template>
                    <app-job-details-invoice
                      [triggerJobInvoiceData]="triggerJobInvoiceData"
                    ></app-job-details-invoice>
                  </ng-container>
                </mat-tab>
              </ng-container>
            </mat-tab-group>
          </div>
        </div>
      </div>
      <div *ngIf="showOverview">
        <app-timelog-overview
          [moduleId]="moduleId"
          [status]="selectedStatus"
          [triggerTimelogOverviewData]="triggerTimelogOverviewData"
          (closeClick)="closeClick()"
        ></app-timelog-overview>
      </div>
    </div>
  </div>
</div>
