<div class="side-list-actions footer-list-action">
  <div class="sidenav-body-content">
    <button
      mat-button
      class="close-button"
      disableRipple
      (click)="cancelSelection()"
    >
      <mat-icon>highlight_off</mat-icon>
    </button>
    <div class="d-flex align-items-center">
      <div class="mr-3">
        <div class="mb-1">
          <p class="text-muted fw-bold m-0">Selected Items</p>
        </div>
        <div class="d-flex align-items-center justify-space-between">
          <p class="font-size-16 fw-bold m-0 mr-2">
            <span class="text-muted font-size-18">Records:</span>
            {{ totalSelectedRecords }}
          </p>
          <a
            class="link hyperlink"
            href="javascript:void(0)"
            (click)="cancelSelection()"
            >Cancel Selection</a
          >
        </div>
      </div>
      <div class="d-flex align-items-center action-list">
        <div
          class="mr-2"
          *ngIf="
            !(
              (getModuleId === modules.TimePFA ||
                getModuleId === modules.TimeApproved ||
                getModuleId === modules.TimeRejected) &&
              isRegularUser
            )
          "
        >
          <p class="font-size-16 fw-bold">Edit</p>
          <div
            class="d-flex align-items-center"
            *ngIf="
              getModuleId !== modules.TimeUnsubmitted &&
              getModuleId !== modules.TimePFA &&
              getModuleId !== modules.TimeApproved &&
              getModuleId !== modules.TimeRejected
            "
          >
            <button
              class="button-icon copy-archive-button"
              type="button"
              mat-button
              #button
              disableRipple
              *ngIf="
                getModuleId !== modules.Expenses &&
                getModuleId !== modules.Users &&
                getModuleId !== modules.RecurringInvoice &&
                getModuleId !== modules.Jobs
              "
              (click)="copy()"
              name="{{ gridActionsText.Copy }}"
            >
              <mat-icon>content_copy</mat-icon>
              <p>Copy</p>
            </button>
            <button
              class="button-icon copy-archive-button"
              type="button"
              mat-button
              #button
              disableRipple
              *ngIf="getModuleId === modules.Jobs"
              (click)="updateJobStatus(true, false)"
              name="{{ gridActionsText.MarkAsCompleted }}"
            >
              <mat-icon>check_circle</mat-icon>
              <p>Mark as Completed</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              *ngIf="getModuleId === modules.Jobs"
              (click)="updateJobStatus(false, true)"
              name="{{ gridActionsText.MarkAsYetToStart }}"
            >
              <mat-icon fontSet="material-icons-outlined"
                >check_circle</mat-icon
              >
              <p>Mark As Yet to Start</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              (click)="delete()"
              name="{{ gridActionsText.Delete }}"
            >
              <mat-icon>delete_outline</mat-icon>
              <p>Delete</p>
            </button>
          </div>
          <div
            class="d-flex align-items-center"
            *ngIf="
              getModuleId === modules.TimeUnsubmitted ||
              getModuleId === modules.TimePFA ||
              getModuleId === modules.TimeApproved ||
              getModuleId === modules.TimeRejected
            "
          >
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimeUnsubmitted && !isRegularUser"
              (click)="reminder()"
            >
              <mat-icon fontSet="material-icons-outlined">email</mat-icon>
              <p>Send Reminder</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimeUnsubmitted"
              (click)="submit()"
            >
              <mat-icon fontSet="material-icons-outlined"
                >check_circle</mat-icon
              >
              <p>Submit for Approval</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimePFA"
              (click)="approve()"
            >
              <mat-icon fontSet="material-icons-outlined"
                >check_circle</mat-icon
              >
              <p>Approve</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimePFA"
              (click)="reject()"
            >
              <mat-icon fontSet="material-icons-outlined"
                >playlist_remove</mat-icon
              >
              <p>Reject</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimePFA"
              (click)="sendNote()"
            >
              <mat-icon fontSet="material-icons-outlined">edit_note</mat-icon>
              <p>Send Note</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimeApproved"
              (click)="withdraw()"
            >
              <mat-icon fontSet="material-icons-outlined"
                >drive_file_move_rtl</mat-icon
              >
              <p>Withdraw Approval</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.TimeRejected"
              (click)="withdraw()"
            >
              <mat-icon fontSet="material-icons-outlined"
                >assignment_returned</mat-icon
              >
              <p>Withdraw Rejection</p>
            </button>
          </div>
        </div>
        <div class="mr-2">
          <p class="font-size-16 fw-bold">Export</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              (click)="export(exportType.Excel)"
              name="{{ gridActionsText.Excel }}"
            >
              <mat-icon>border_all</mat-icon>
              <p>Excel</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              (click)="export(exportType.CSV)"
              name="{{ gridActionsText.CSV }}"
            >
              <mat-icon fontSet="material-icons-outlined">description</mat-icon>
              <p>CSV</p>
            </button>
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              (click)="export(exportType.PDF)"
              name="{{ gridActionsText.PDF }}"
            >
              <mat-icon fontSet="material-icons-outlined"
                >picture_as_pdf</mat-icon
              >
              <p>PDF</p>
            </button>
          </div>
        </div>
        <div class="mr-2 end-list">
          <p class="font-size-16 fw-bold">Options</p>
          <div class="d-flex align-items-center">
            <button
              class="button-icon"
              type="button"
              mat-button
              #button
              disableRipple
              (click)="print()"
              *ngIf="getModuleId !== modules.RecurringInvoice"
              name="{{ gridActionsText.Print }}"
            >
              <mat-icon fontSet="material-icons-outlined">print</mat-icon>
              <p>Print</p>
            </button>
            <button
              class="button-icon copy-archive-button"
              type="button"
              mat-button
              #button
              disableRipple
              *ngIf="
                getModuleId !== modules.Invoice &&
                getModuleId !== modules.RecurringInvoice &&
                getModuleId !== modules.Estimate &&
                getModuleId !== modules.Expenses &&
                getModuleId !== modules.Jobs &&
                getModuleId !== modules.TimeUnsubmitted &&
                getModuleId !== modules.TimePFA &&
                getModuleId !== modules.TimeApproved &&
                getModuleId !== modules.TimeRejected
              "
              (click)="archiveAndRestore(true)"
              name="{{ gridActionsText.Archive }}"
            >
              <mat-icon fontSet="material-icons-outlined">archive</mat-icon>
              <p>Archive</p>
            </button>
            <button
              class="button-icon restore-button"
              type="button"
              mat-button
              #button
              disableRipple
              *ngIf="
                getModuleId !== modules.Invoice &&
                getModuleId !== modules.RecurringInvoice &&
                getModuleId !== modules.Estimate &&
                getModuleId !== modules.Expenses &&
                getModuleId !== modules.Jobs &&
                getModuleId !== modules.TimeUnsubmitted &&
                getModuleId !== modules.TimePFA &&
                getModuleId !== modules.TimeApproved &&
                getModuleId !== modules.TimeRejected
              "
              (click)="archiveAndRestore(false)"
              name="{{ gridActionsText.Restore }}"
            >
              <mat-icon fontSet="material-icons-outlined">unarchive</mat-icon>
              <p>Restore</p>
            </button>
            <button
              *ngIf="getModuleId === modules.Estimate"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="statusChangedClick(feeStatus.Sent)"
            >
              <mat-icon fontSet="material-icons-outlined"
                >check_circle_outline</mat-icon
              >
              <p>Mark As Sent</p>
            </button>
            <button
              *ngIf="getModuleId === modules.Estimate"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="statusChangedClick(feeStatus.Declined)"
            >
              <mat-icon fontSet="material-icons-outlined"
                >highlight_off</mat-icon
              >
              <p>Mark As Decline</p>
            </button>
            <button
              *ngIf="getModuleId === modules.Estimate"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="statusChangedClick(feeStatus.Accepted)"
            >
              <mat-icon fontSet="material-icons-outlined">beenhere</mat-icon>
              <p>Mark As Accepted</p>
            </button>
            <button
              *ngIf="getModuleId === modules.Estimate"
              class="button-icon"
              type="button"
              mat-button
              disableRipple
              (click)="statusChangedClick(feeStatus.Draft)"
            >
              <mat-icon fontSet="material-icons-outlined">access_time</mat-icon>
              <p>Revert Status</p>
            </button>
            <button
              class="button-icon copy-archive-button"
              type="button"
              mat-button
              disableRipple
              *ngIf="getModuleId === modules.RecurringInvoice"
              (click)="endRecurringInvoice()"
            >
              <mat-icon fontSet="material-icons-outlined">archive</mat-icon>
              <p>End</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
